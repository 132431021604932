import { createContext } from 'react'
import {
  IQueryParamsWorkersList,
  IWorkerInfoAdminPage,
} from '../../../models/AdminWorkes'
import { IDatePeriod } from '../../atoms'

interface IProps {
  workersList: IWorkerInfoAdminPage[]
  setWorkersList: React.Dispatch<React.SetStateAction<IWorkerInfoAdminPage[]>>
  queryParams: IQueryParamsWorkersList | null
  setQueryParams: React.Dispatch<React.SetStateAction<IQueryParamsWorkersList>>
  period: IDatePeriod
  setPeriod: React.Dispatch<React.SetStateAction<IDatePeriod>>
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}

export const WorkersDataForAdminContext = createContext<IProps>({
  workersList: [],
  setWorkersList: () => [],
  queryParams: null,
  setQueryParams: () => {},
  period: { date_min: null, date_max: null },
  setPeriod: () => {},
  setCurrentPage: () => {},
})
