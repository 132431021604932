import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { Button } from '../../../components/atoms'
import { SliderWithArrows } from '../../../components/atoms/sliders/SliderWithArrows'
import {
  NavigationFooter,
  ProgressBarLinear,
} from '../../../components/molecules'
import { ISkills, ISkillsGroup } from '../../../models'
import {
  EPageTitleOB,
  listBlocksPBLinear,
} from '../../../utils/constants/obConstants'
import { useTranslation } from 'react-i18next'
import './obExperience.scss'

type TProps = {
  onClickNext: () => void
  onClickBack: () => void
  skills: ISkills[]
  setSkills: React.Dispatch<React.SetStateAction<ISkills[]>>
  isWaitingResponse: boolean
  skillsGroups: ISkillsGroup[]
}

export const OBExperienceUI = (props: TProps) => {
  const {
    onClickBack,
    onClickNext,
    skills,
    setSkills,
    isWaitingResponse,
    skillsGroups,
  } = props
  const { t } = useTranslation()

  const setValueInSlider = (value: number, title: string) => {
    setSkills(prev => {
      const newArr = [...prev]
      const findSkillIndex = newArr.findIndex(el => el.choice === title)
      newArr[findSkillIndex].level = value
      return newArr
    })
  }

  const getValueTitle = (number: number) => {
    switch (number) {
      case 0:
        return t('experience.None').toString()
      case 1:
        return `1 ${t('experience.year')}`
      default:
        return `${number} ${t('experience.years')}`
    }
  }

  const notEnteredValue = !skills.some(skillsObj => skillsObj.level)

  return (
    <div className="ob-experience ob-container">
      <Helmet>
        <title> {EPageTitleOB.experience} </title>
      </Helmet>

      <ProgressBarLinear
        className="ob-experience__progress-bar"
        initData={listBlocksPBLinear}
        currentPage={4}
      />

      <div className="ob-experience__content">
        <p className="ob-experience__question ob-question">
          {t('experience.Tell us about your skills')}
        </p>

        <p className="ob-experience__description ob-description">
          {t(
            'experience.Telling us more about your specific areas of experience will help us identify the opportunities for which you are best suited.'
          )}
        </p>

        <div className="ob-experience__groups-wrapper">
          {skillsGroups.map(el => (
            <Fragment key={el.nameGroup}>
              <p className="ob-experience__groups-title">
                {t([
                  `experience.${el.nameGroup}`,
                  `${el.nameGroup}`,
                ]).toString()}
              </p>
              {el.skills.map(({ choice, level }) => (
                <SliderWithArrows
                  key={choice}
                  title={t([`experience.${choice}`, `${choice}`]).toString()}
                  defaultValue={level}
                  rangeProcessing={number => setValueInSlider(number, choice)}
                  valueTitle={getValueTitle(level)}
                  max={50}
                />
              ))}
            </Fragment>
          ))}
        </div>
      </div>

      <NavigationFooter actionBack={onClickBack}>
        <Button
          onClick={onClickNext}
          disabled={notEnteredValue || isWaitingResponse}
        >
          {t('Shared_Header.Next')}
        </Button>
      </NavigationFooter>
    </div>
  )
}
