import './errorMessageWithCross.scss'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  textError: string
}

export const ErrorMessageWithCross = ({ textError, className }: IProps) => (
  <div
    className={`error-message-with-cross ${className ?? ''}`}
    data-testid="error-message-with-cross"
  >
    <span className="error-message-with-cross__icon">X</span>
    <span className="error-message-with-cross__text">{textError}</span>
  </div>
)
