import { useMemo } from 'react'
import './navMenu.scss'
import dashboard from '../../../assets/icons/active-icons/home-icon.png'
// import challenge from '../../../assets/icons/active-icons/award-icon.png'
import training from '../../../assets/icons/active-icons/training-icon.png'
// import jobs from '../../../assets/icons/active-icons/jobs-icon.png'
import about from '../../../assets/icons/active-icons/info-icon.png'
// import support from '../../../assets/icons/active-icons/support-icon.png'
import profile from '../../../assets/icons/active-icons/profile-icon.png'
import message from '../../../assets/icons/social/inbox.svg'
import homeActive from '../../../assets/icons/active-icons/home-active-icon.png'
// import challengeActive from '../../../assets/icons/active-icons/award-active-icon.png'
import trainingActive from '../../../assets/icons/active-icons/training-active-icon.png'
// import jobsActive from '../../../assets/icons/active-icons/jobs-active-icon.png'
import aboutActive from '../../../assets/icons/active-icons/info-active-icon.png'
// import supportActive from '../../../assets/icons/active-icons/support-active-icon.png'
import profileActive from '../../../assets/icons/active-icons/profile-active-icon.png'
import { useLocation, Link } from 'react-router-dom'
import * as route from '../../../services/route'
import { v4 as uuidv4 } from 'uuid'
import { NavItem } from './NavItem'
import { useTypedSelector, useMediaContext } from '../../../utils/hooks'
interface Props {
  onClickItem?: () => void
  onClickLogout?: () => void
}

export enum NavItemType {
  Dashboard = 'Dashboard',
  ContactUs = 'Contact Us',
  //   Challenges = 'Challenges', //TODO: We will uncomment challenge feature later
  Training = 'Training',
  // Jobs = 'Jobs',  //TODO: We will uncomment jobs feature later
  About = 'About',
  // Support = 'Support',
  Profile = 'Profile',
  Logout = 'Sign out',
  Customer = 'Customer',
  WSAdmin = 'WS Admin',
  Inbox = 'Inbox',
}

export type NavMenuItem = {
  type: NavItemType
  icon?: string
  activeIcon?: string
  pathName?: string
}

export const NavMenu = (props: Props) => {
  const { onClickLogout, onClickItem } = props
  const isMobile = useMediaContext()
  const location = useLocation()
  const isEmployer = useTypedSelector(state => state.auth.isEmployer)
  const isAdminWorker = useTypedSelector(state => state.auth.isSkillitAdmin)
  const isAdminPage = isAdminWorker && !isEmployer

  const list: NavMenuItem[][] = useMemo(() => {
    let result: NavMenuItem[][] =
      isEmployer || isAdminWorker
        ? [
            [
              {
                type: NavItemType.Dashboard,
                icon: dashboard,
                activeIcon: homeActive,
                pathName: isEmployer
                  ? route.employerDashboard
                  : route.adminWorkers,
              },
              {
                type: NavItemType.ContactUs,
              },
            ],
          ]
        : [
            [
              {
                type: NavItemType.Dashboard,
                icon: dashboard,
                activeIcon: homeActive,
                pathName: route.dashboard,
              },
              // { type: NavItemType.Challenges, icon: challenge, activeIcon: challengeActive, pathName: route.challenges },
              {
                type: NavItemType.Training,
                icon: training,
                activeIcon: trainingActive,
                pathName: route.training,
              },
              // { type: NavItemType.Jobs, icon: jobs, activeIcon: jobsActive, pathName: route.jobsPage, },
            ],
            [
              {
                type: NavItemType.About,
                icon: about,
                activeIcon: aboutActive,
              },
              // {
              //   type: NavItemType.Support,
              //   icon: support,
              //   activeIcon: supportActive,
              // },
              {
                type: NavItemType.Profile,
                icon: profile,
                activeIcon: profileActive,
                pathName: route.profile,
              },
              {
                type: NavItemType.Inbox,
                icon: message,
                activeIcon: profileActive,
              },
            ],
          ]

    if (isAdminWorker) {
      result.push([
        {
          type: isAdminPage ? NavItemType.Customer : NavItemType.WSAdmin,
          pathName: isAdminPage ? route.employerDashboard : route.adminWorkers,
        },
      ])
    }
    if (isMobile) {
      result.push([{ type: NavItemType.Logout }])
    }

    return result
  }, [isMobile, isEmployer, isAdminWorker, isAdminPage])

  const currentIndexPath = useMemo((): IndexPath | undefined => {
    let indexPath: IndexPath | undefined
    list.forEach((section, sectionIndex) => {
      section.forEach((row, rowIndex) => {
        const mainRoutePath = `/${location.pathname.split('/')[1]}`
        if (row.pathName === mainRoutePath) {
          indexPath = { sectionIndex, rowIndex }
        }
      })
    })
    return indexPath
  }, [list, location.pathname])

  return (
    <div className="nav-menu" data-testid="nav-menu">
      {list.map((section, sectionIndex) => {
        return (
          <section key={uuidv4()} className="nav-menu__section">
            <ul className="nav-menu__table">
              {section.map((el, rowIndex) => {
                const isCurrentSelected =
                  currentIndexPath?.sectionIndex === sectionIndex &&
                  currentIndexPath?.rowIndex === rowIndex

                switch (el.type) {
                  case NavItemType.Logout:
                    return (
                      <NavItem
                        key={el.type}
                        el={el}
                        active={isCurrentSelected}
                        onClickItem={onClickItem}
                        onClickLogout={onClickLogout}
                      />
                    )
                  case NavItemType.Inbox:
                    return (
                      <a
                        key={uuidv4()}
                        className="nav-menu__link"
                        href={`${window.location.origin}/beta/inbox`}
                        target="_self"
                        rel="noreferrer"
                      >
                        <NavItem
                          el={el}
                          active={isCurrentSelected}
                          onClickItem={onClickItem}
                        />
                      </a>
                    )
                  // case NavItemType.Support:
                  case NavItemType.ContactUs:
                  case NavItemType.About:
                    return (
                      <a
                        key={uuidv4()}
                        className="nav-menu__link"
                        data-testid={`nav-menu__link-${el.type}`}
                        href={
                          el.type === NavItemType.About
                            ? 'https://www.skillit.com/about'
                            : 'https://www.skillit.com/contact'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <NavItem
                          el={el}
                          active={isCurrentSelected}
                          onClickItem={onClickItem}
                        />
                      </a>
                    )
                  default:
                    return (
                      <Link
                        key={uuidv4()}
                        className="nav-menu__link"
                        to={el.pathName ?? '/'}
                      >
                        <NavItem
                          el={el}
                          active={isCurrentSelected}
                          onClickItem={onClickItem}
                        />
                      </Link>
                    )
                }
              })}
            </ul>
          </section>
        )
      })}
    </div>
  )
}

type IndexPath = {
  sectionIndex: number
  rowIndex: number
}
