import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import * as route from '../../../../services/route'
import { LoginForm } from '../../../../components/organism'
import { LoginType } from '../../../../components/organism/General/LoginForm'
import { EPageTitle } from '../SignIn/SignInPage'
import { BannerVertical } from '../../../../components/atoms/BannerVertical/BannerVertical'
import bannerLoginImage from '../../../../assets/img/worker/email-login-banner.png'
import bannerCheckEmailImage from '../../../../assets/img/worker/check-email-banner.png'

interface Props {
  email: string
  decodeEmail: string
  rememberMe: boolean
  fpModalDisplay: boolean
  onCloseModal: () => void
  setRememberMe: React.Dispatch<React.SetStateAction<boolean>>
  setFpModalDisplay: React.Dispatch<React.SetStateAction<boolean>>
  onConfirmEmailLogin: (email: string, password: string) => Promise<void>
  sendEmail: {
    isSendEmail: boolean
    messageTitle: string
    messageDescription: string
  }
}

export const SignInEmailPageUI = (props: Props) => {
  const { email, decodeEmail, onConfirmEmailLogin, sendEmail } = props

  return (
    <>
      <Helmet>
        <title>{EPageTitle.sign_in}</title>
      </Helmet>

      {!sendEmail.isSendEmail ? (
        <BannerVertical bannerImage={bannerLoginImage}>
          <div className="email-login-wrapper">
            <div className="email-login__title-block">
              <h1 className="email-login__title-block--title">Welcome!</h1>
              <p className="email-login__title-block--description">
                Enter your phone number or email address to get a magic link
                that will sign you in instantly
              </p>
            </div>

            <LoginForm
              defaultEmail={email ? decodeEmail : ''}
              onConfirmEmailLogin={onConfirmEmailLogin}
              type={LoginType.SignInEmail}
            ></LoginForm>
          </div>
        </BannerVertical>
      ) : (
        <BannerVertical bannerImage={bannerCheckEmailImage}>
          <div className="email-login__title-block">
            <h1 className="email-login__title-block--title">
              {sendEmail.messageTitle}
            </h1>
            <p className="email-login__title-block--description">
              {sendEmail.messageDescription}
            </p>
            <Link className="link" to={route.signInPasswordLogin}>
              <p className="email-login-page__signin-link-wrapper">
                Or log in with your email and password instead
              </p>
            </Link>
          </div>
        </BannerVertical>
      )}
    </>
  )
}
