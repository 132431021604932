import React, { useMemo } from 'react'
import { ECompletionStatusOB } from '../../../../../../utils/constants'
import { WorkerStatusesUI } from './WorkerStatusesUI'

interface Props extends React.HTMLAttributes<HTMLElement> {
  workerStatus: ECompletionStatusOB
}

export const WorkerStatuses = ({ workerStatus }: Props) => {
  const workerProgressStatus = useMemo(
    () => [
      { status: ECompletionStatusOB.registered, numberOfStatus: 1 },
      { status: ECompletionStatusOB.applied, numberOfStatus: 2 },
      {
        status:
          workerStatus === ECompletionStatusOB.rejected
            ? ECompletionStatusOB.rejected
            : ECompletionStatusOB.approved,
        numberOfStatus: 3,
      },
      { status: ECompletionStatusOB.verified, numberOfStatus: 4 },
    ],
    [workerStatus]
  )

  const activeStatus = useMemo(
    () =>
      workerProgressStatus.find(
        status =>
          status.status.toLocaleLowerCase() === workerStatus.toLocaleLowerCase()
      )?.numberOfStatus ?? 0,
    [workerProgressStatus, workerStatus]
  )

  return (
    <WorkerStatusesUI
      activeStatus={activeStatus}
      statusList={workerProgressStatus}
    />
  )
}
