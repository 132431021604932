export const getVisiblePreviously = (
  initPreviously: string[],
  wrapperWidth: number,
  childWidth: number
): { visiblePreviously: string; remainingElems: number } => {
  let visiblePreviously = initPreviously.join(', ')
  let remainingElems = 0
  const charWidth = childWidth / visiblePreviously.length
  if (wrapperWidth < childWidth) {
    const maxLetterContains = Math.floor(wrapperWidth / charWidth)
    remainingElems =
      visiblePreviously.slice(maxLetterContains - 4).split(', ').length - 1
    visiblePreviously =
      visiblePreviously.slice(
        0,
        remainingElems ? maxLetterContains - 7 : maxLetterContains - 4
      ) + '...'
  }
  return {
    visiblePreviously,
    remainingElems,
  }
}
