import './validator.scss'

interface ValidatorProps extends React.HTMLAttributes<HTMLDivElement> {
  isValid: boolean
  text?: string
}

export const Validator = (props: ValidatorProps) => {
  const { text, isValid, className } = props
  return (
    <div className={`validator-field ${className}`}>
      <div
        className={`validator-field__icon ${
          isValid
            ? 'validator-field__icon_valid'
            : 'validator-field__icon_not-valid'
        }`}
        data-testid="validator-field__icon"
      />
      <p
        className={`validator-field__text ${
          isValid
            ? 'validator-field__text_valid'
            : 'validator-field__text_not-valid'
        }`}
      >
        {text}
      </p>
    </div>
  )
}
