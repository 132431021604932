export enum ETravelDistance {
  upToFiveMiles = '1-5 miles',
  upToTenMiles = '5-10 miles',
  upToFifteenMiles = '10-15 miles',
  upTo25Miles = '15-25 miles',
  over25Miles = '25+ miles',
}

export const travel_distance = {
  upToFiveMiles: {
    name: '5 miles',
    value: ETravelDistance.upToFiveMiles,
  },
  upToTenMiles: {
    name: '10 miles',
    value: ETravelDistance.upToTenMiles,
  },
  upTofifteenMiles: {
    name: '15 miles',
    value: ETravelDistance.upToFifteenMiles,
  },
  upTo25Miles: {
    name: '25 miles',
    value: ETravelDistance.upTo25Miles,
  },
  over25Miles: {
    name: '25+ miles',
    value: ETravelDistance.over25Miles,
  },
}

export const interviewStatus = {
  requested: 'requested',
  scheduled: 'scheduled',
  rescheduling: 'rescheduling',
  rescheduled: 'rescheduled',
  no_showed: 'no_showed',
  declined: 'declined',
  canceled: 'canceled',
  completed: 'completed',
  unreachable: 'unreachable',
}
