import React, { forwardRef } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Button, Input } from '../../../components/atoms'
import {
  NavigationFooter,
  ProgressBarLinear,
} from '../../../components/molecules'
import {
  EPageTitleOB,
  listBlocksPBLinear,
} from '../../../utils/constants/obConstants'
import { inputFormatting } from '../../../utils/scripts'
import './obName.scss'

interface Props {
  firstName: string
  setFirstName: React.Dispatch<React.SetStateAction<string>>
  lastName: string
  setLastName: React.Dispatch<React.SetStateAction<string>>
  onClickNext: () => void
}

export const OBNameUI = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { firstName, setFirstName, lastName, setLastName, onClickNext } = props
  const { t } = useTranslation()

  return (
    <div className="name-page ob-container">
      <Helmet>
        <title> {EPageTitleOB.name} </title>
      </Helmet>

      <ProgressBarLinear
        className="name-page__progress-bar"
        initData={listBlocksPBLinear}
        currentPage={1}
      />

      <div className="name-page__form">
        <p className="name-page__form-greetings ob-question">
          {t('name.Thank you for your interest in Skillit.')}
        </p>
        <p className="name-page__form__description ob-description">
          {t(
            'name.Skillit is different. Our builders have high standards and expect the best from our members. We maintain the quality of our community by getting to know every worker that joins.'
          )}
        </p>
        <p className="name-page__form-description ob-description">
          {t(
            'name.Skillit membership is FREE. The more information you provide the easier it will be to evaluate your application.'
          )}
        </p>

        <div className="name-page__form-inputs-wrapper">
          <Input
            className={`name-page__form-input ${
              firstName ? 'filled-input' : ''
            }`}
            value={firstName}
            onChange={e => setFirstName(inputFormatting(e.target.value, 50))}
            placeholder={t('name.First Name').toString()}
            ref={ref}
          />

          <Input
            className={`name-page__form-input ${
              lastName ? 'filled-input' : ''
            }`}
            value={lastName}
            onChange={e => setLastName(inputFormatting(e.target.value, 50))}
            placeholder={t('name.Last Name').toString()}
          />
        </div>
      </div>

      <NavigationFooter isInitializationPage>
        <Button
          onClick={onClickNext}
          disabled={!(firstName.trim() && lastName.trim())}
        >
          {t('Shared_Header.Next')}
        </Button>
      </NavigationFooter>
    </div>
  )
})
