import React from 'react'
import { Helmet } from 'react-helmet'
import { Button } from '../../../components/atoms'
import {
  NavigationFooter,
  ProgressBarLinear,
} from '../../../components/molecules'
import {
  EPageTitleOB,
  listBlocksPBLinear,
} from '../../../utils/constants/obConstants'
import GoogleMapReact from 'google-map-react'
import './obTravelToWork.scss'
import { SliderWithArrows } from '../../../components/atoms/sliders/SliderWithArrows'
import { useTranslation } from 'react-i18next'
import { TravelDistance } from '../../../models'

interface Props {
  onClickBack: () => void
  onClickNext: (
    initDistanceValue: TravelDistance,
    initSetDistanceValue: React.Dispatch<React.SetStateAction<TravelDistance>>
  ) => void
  distanceValue: TravelDistance
  homeLocationLng: number
  homeLocationLat: number
  zoomLevel: number
  onRangeProcessingTravelDistance: (initValue: number) => void
  setDistanceValue: React.Dispatch<React.SetStateAction<TravelDistance>>
}

export const OBTravelToWorkUI = (props: Props) => {
  const {
    onClickBack,
    onClickNext,
    distanceValue,
    homeLocationLng,
    homeLocationLat,
    zoomLevel,
    onRangeProcessingTravelDistance,
    setDistanceValue,
  } = props
  const { t } = useTranslation()

  return (
    <div className="ob-travel-to-work">
      <Helmet>
        <title> {EPageTitleOB.travel_to_work} </title>
      </Helmet>
      <div className="ob-travel-to-work__wrapper ob-container">
        <ProgressBarLinear
          className="ob-travel-to-work__progress-bar"
          initData={listBlocksPBLinear}
          currentPage={9}
        />
        <p className="ob-travel-to-work__question ob-question">
          {t(
            'travel_to_work.How far from home are you willing to travel each day for work?'
          )}
        </p>
        <p className="ob-travel-to-work__description ob-description">
          {t(
            'travel_to_work.It’s helpful to know what kind of commute you can stomach. There’s no wrong answer, just your preference.'
          )}
        </p>
        <div className="ob-travel-to-work__map">
          <GoogleMapReact
            defaultCenter={{
              lat: homeLocationLat,
              lng: homeLocationLng,
            }}
            zoom={zoomLevel}
            options={{
              disableDefaultUI: true,
              draggable: false,
              keyboardShortcuts: false,
              mapTypeControl: false,
              panControl: false,
              rotateControl: false,
              scaleControl: false,
              clickableIcons: false,
            }}
          ></GoogleMapReact>
        </div>
        <SliderWithArrows
          defaultValue={distanceValue.travel_distance}
          rangeProcessing={onRangeProcessingTravelDistance}
          valueTitle={`${distanceValue.travel_distance} ${t(
            'travel_to_work.miles'
          )}`}
          max={100}
          min={2}
        />
      </div>

      <NavigationFooter actionBack={() => onClickBack()}>
        <Button
          onClick={() => onClickNext(distanceValue, setDistanceValue)}
          disabled={false}
        >
          {t('Shared_Header.Next')}
        </Button>
      </NavigationFooter>
    </div>
  )
}
