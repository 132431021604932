import { Helmet } from 'react-helmet'
import { EWorkerPageTitle } from '../../../../utils/constants'
import { LoadingScreen } from '../../General'

interface IProps {
  title: string | null
  link: string | null
  isLoading: boolean
}

export const AssessmentPageUI = (props: IProps) => {
  const { title, link, isLoading } = props

  if (isLoading) return <LoadingScreen />

  return (
    <div className="assessment">
      <Helmet>
        <title>{EWorkerPageTitle.assessment}</title>
      </Helmet>

      <h2 className="assessment__title">{title}</h2>

      {link && (
        <iframe
          title={title ?? undefined}
          src={link}
          className="assessment__iframe"
          data-testid="assessment__iframe"
        />
      )}
    </div>
  )
}
