import React from 'react'
import completeIcon from '../../../../assets/icons/checkmarks/checkmark-green.svg'
import { Button } from '../../../atoms'
import './completionCard.scss'

interface ActionBtn {
  text: string
  action: () => void
}

interface Props extends React.HTMLAttributes<HTMLElement> {
  completed: boolean
  icon: string
  title: string
  actionBtn?: ActionBtn
}

export const CompletionCard = (props: Props) => {
  const { completed, icon, title, actionBtn, children, className } = props
  return (
    <div
      className={`completion-card ${className ?? ''}`}
      data-testid="completion-card"
    >
      <img
        src={!completed ? icon : completeIcon}
        alt="action_image"
        className="completion-card__icon"
      />
      <h3 className="completion-card__title">{title}</h3>
      <div
        className="completion-card__content"
        data-testid="completion-card__content"
      >
        {!completed && children}
      </div>
      {actionBtn && (
        <Button
          onClick={actionBtn?.action}
          className={`${
            !completed
              ? 'completion-card__btn profile_white-btn profile_orange-btn'
              : 'completion-card__btn_link'
          }`}
        >
          {actionBtn?.text}
        </Button>
      )}
    </div>
  )
}
