export const experienceFormatting = (experience: number) => {
  const splitExp = experience.toString().split('.')

  let expYears = +splitExp[0]
  let expMonths = +splitExp[1]

  let wordMonth: string
  let wordYear: string

  if (expMonths && expMonths > 12) {
    expYears += Math.floor(expMonths / 12)
    expMonths = expMonths - Math.floor(expMonths / 12) * 12
  }

  if (expMonths === 1) {
    wordMonth = 'month'
  } else wordMonth = 'months'

  if (expYears === 1) wordYear = 'year'
  else if (expYears > 1) {
    wordYear = 'years'
  } else wordYear = ''

  const totalMonths = `${expMonths ? expMonths : ''} ${
    expMonths ? wordMonth : ''
  }`
  const totalYears = `${expYears ? expYears : ''} ${wordYear}`

  return `${totalYears} ${totalMonths}`.trim()
}
