import React from 'react'
import './carusel.scss'
import Slider, { Settings } from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useMediaContext } from '../../../../utils/hooks'

interface Props extends React.HtmlHTMLAttributes<HTMLElement> {
  numberOfItems: number
  itemAtIndex: (index: number) => React.ReactElement
}

export const Carusel = (props: Props) => {
  const isMobile = useMediaContext()

  const settings: Settings = {
    dots: false,
    infinite: props.numberOfItems >= 3 ? true : false,
    speed: 500,
    slidesToShow:
      props.numberOfItems >= 3 ? 1 : props.numberOfItems >= 2 ? 0.7 : 1.1,
    slidesToScroll: 1,
    className: 'center',
    centerMode: true,
    variableWidth: props.numberOfItems === 1 ? false : true,
    adaptiveHeight: true,
    centerPadding: props.numberOfItems === 1 ? '10px' : '50px',
  }

  const settingsDesktop: Settings = {
    dots: false,
    infinite: props.numberOfItems >= 3 ? true : false,
    speed: 500,
    slidesToShow: window.innerWidth <= 1200 ? 2 : 3,
    slidesToScroll: 1,
    centerPadding: '50px',
  }

  return (
    <section
      className={`carusel ${props.numberOfItems <= 2 && 'carusel_desktop'} ${
        props.className ?? ''
      } ${!isMobile && 'container'}`}
    >
      <Slider {...(isMobile ? settings : settingsDesktop)}>
        {Array(props.numberOfItems)
          .fill('')
          .map((_, index) => props.itemAtIndex(index))}
      </Slider>
    </section>
  )
}
