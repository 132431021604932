import React from 'react'
import './thirdBlog.scss'

import img1 from '../../../../../assets/img/worker/blog/Top3skills.png'
import img2 from '../../../../../assets/img/other/AverageCarpenter.png'
import img3 from '../../../../../assets/img/other/AverageCarpenter2.png'
import img4 from '../../../../../assets/img/other/LeadCarpenterJohn.png'
import img5 from '../../../../../assets/img/employer/blob.png'

export const ThirdBlog = () => {
  return (
    <div className="third-blog">
      <h3 className="third-blog__blog-title">
        Introducing Skills Premiums for carpenters
      </h3>
      <p>
        <strong>
          The nation’s top construction firms are willing to pay skills-assessed
          carpenters a premium for their personal mix of carpentry skills and
          knowledge.
        </strong>
      </p>
      <p>
        At Skillit, we believe carpenters should be rewarded for their unique
        mix of skills and knowledge and the value they provide to their
        employers.
      </p>
      <p>
        Traditionally however, because carpentry skills and knowledge are hard
        to measure, a carpenter’s salary is based upon job level and years of
        experience.
      </p>
      <p>
        This blunt approach keeps carpenters from being paid a rate proportional
        to the value their unique strengths provide their employer and
        subsequently often sees them undervalued at work. A firm’s productivity
        also suffers because their workforce’s skills, and the skills that drive
        their business forward, are poorly matched.
      </p>
      <p>
        To see this in action, take two Skillit customers, one a 1,000-employee
        commercial contractor and the other a 100-person design-build firm. Both
        hire lead carpenters and pay a similar rate however they have very
        different priorities when it comes to the skills that drive the most
        value for their firms.
      </p>
      <p>
        Here are their top three desired skills in a Lead Carpenter in order of
        importance:
      </p>
      <img src={img1} alt="top-3" className="third-blog__img" />
      <p>
        Now, compare these to the top three skills possessed by an average Lead
        Carpenter (surveyed from hundreds of lead carpenters across the nation):
      </p>
      <img src={img2} alt="Average-carpenter" className="third-blog__img" />
      <img src={img3} alt="Average-carpenter-2" className="third-blog__img" />
      <p>
        Notice that for both firms, your typical Lead Carpenter is a bit of a
        disappointment - Big Commercial Firm lacks a candidate with high-ranking
        safety knowledge (which is important to them given their dangerous and
        regulatory environment) and Small Residential Firm lacks a candidate
        with high-ranking Finish Carpentry skills (which is important to them
        given the demanding nature of their homeowner customers).
      </p>
      <p>
        It probably comes as no surprise that just like thousands of other
        firms, both Big Commercial Firm and Small Residential Firm are paying an
        average rate to their carpenters based upon role and experience only.
        Also, because their employee’s skills are not assessed properly, they're
        also finding it harder to offer training and successful retention
        programs.
      </p>
      <p>
        Skillit solves this problem by only matching our skills-assessed
        carpenters with firms that have the same Skills Rank. See how this
        worked out for recent placement Lead Carpenter John:
      </p>
      <img src={img4} alt="Lead-Carpenter-John" className="third-blog__img" />
      <p>
        Because of his identical Skills Rank, Big Commercial Firm agreed to pay
        John 8% above their typical salary for the same role and agreed to
        provide him with targeted training to upskill him where his Skillit
        assessment showed he needed improvement. He is also enjoying his new job
        at Big Commercial Firm because his skills are perfectly aligned with
        their most important project and business goals.
      </p>
      <br />
      <img src={img5} alt="person" className="third-blog__person-img" />
    </div>
  )
}
