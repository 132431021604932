import React, { useCallback, useContext, useMemo, useState } from 'react'
import { TWorkExperience } from '../../../../../../models'
import { OBAnalyticsName } from '../../../../../../utils/constants'
import { getDuration, onTrackingActions } from '../../../../../../utils/scripts'
import { LastJobsContext } from '../../../../../Context'
import { TEditableJob } from '../BestJobsOB'
import { BestJobUI } from './BestJobUI'
import {
  TCombinedFormType,
  TEditIncompleteExp,
} from '../../../../../../pages/Onboarding/OBLastJobs/OBLastJobs'

export interface IBestJobProps extends React.HTMLAttributes<HTMLElement> {
  isFormOpen: false | 'add' | number
  setIsFormOpen: React.Dispatch<React.SetStateAction<false | 'add' | number>>
  index: number
  data?: TWorkExperience
  isPromptOpen: boolean
  setIsPromptOpen: React.Dispatch<React.SetStateAction<boolean>>
  onCloseModal: (value: boolean, setIsFormOpen: TCombinedFormType) => void
  isClickCloseBtn: boolean
  setIsClickCloseBtn: React.Dispatch<React.SetStateAction<boolean>>
  setJobEditModal: React.Dispatch<
    React.SetStateAction<false | TEditIncompleteExp>
  >
}

export const BestJob = (props: IBestJobProps) => {
  const { index, isFormOpen, setIsFormOpen } = props

  const jobs = useContext(LastJobsContext).jobs
  const setJobs = useContext(LastJobsContext).setJobs
  const [isRemovedPromptOpen, setIsRemovedPromptOpen] = useState(false)

  const data = useMemo(() => jobs[index], [jobs, index])

  const onAdd = useCallback(() => {
    setIsFormOpen('add')
  }, [setIsFormOpen])

  const duration: string = useMemo(
    () => getDuration(data?.start_date, data?.end_date),
    [data?.start_date, data?.end_date]
  )

  const onRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, i: number) => {
      e.stopPropagation()
      onTrackingActions(OBAnalyticsName.work_experience_job_removed)
      setJobs(prev => prev.filter((_, index) => index !== i))
      setIsRemovedPromptOpen(false)
    },
    [setJobs]
  )

  const onEdit = useCallback(
    (index: TEditableJob) => {
      onTrackingActions(OBAnalyticsName.work_experience_job_edited)
      setIsFormOpen(index)
    },
    [setIsFormOpen]
  )

  return (
    <BestJobUI
      {...props}
      jobs={jobs}
      onRemove={onRemove}
      onAdd={onAdd}
      onEdit={onEdit}
      duration={duration}
      isFormOpen={isFormOpen}
      setIsFormOpen={setIsFormOpen}
      isRemovedPromptOpen={isRemovedPromptOpen}
      setIsRemovedPromptOpen={setIsRemovedPromptOpen}
    />
  )
}
