import React, { ErrorInfo } from 'react'

export class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  }
  static getDeriveredStateFromError(error: Error) {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.debug(`Did Catch - ${error.message}`)
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong</h1>
    }
    return this.props.children
  }
}
