import { ButtonBack } from '../../../../../components/atoms'
import { LoadingContent, ModalBox } from '../../../../../components/molecules'
import { PopupLoader } from '../../../../../components/organism'
import { BasicInfoContent } from './BasicInfoContent/BasicInfoContent'
import { BasicInfoForm } from './BasicInfoForm/BasicInfoForm'
import * as route from '../../../../../services/route'
import {
  BasicInfoModel,
  ILocationInfoOB,
  IWorkerInfoOB,
} from '../../../../../models'
interface Props {
  minTravelDistance: number
  communicationData: string[]
  homeLocationDescription: string
  isLoading: boolean
  phoneNumber: string
  savingLoader: boolean
  isModalVisible: boolean
  isConfirmationModalVisible: boolean
  handleSubmit: (
    e: React.SyntheticEvent<HTMLFormElement>,
    movingPlanning: ILocationInfoOB
  ) => void
  locationInfo: ILocationInfoOB
  stateForm: BasicInfoModel
  basicInfo?: BasicInfoModel
  stateInfo?: BasicInfoModel
  userInfo: IWorkerInfoOB
  isDataNotChanged: boolean | null
  defaultLocationInfo: ILocationInfoOB
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  setStateForm: React.Dispatch<React.SetStateAction<BasicInfoModel>>
  setLocationInfo: React.Dispatch<React.SetStateAction<ILocationInfoOB>>
  setIsConfirmationModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const BasicInfoUI = (props: Props) => {
  const {
    userInfo,
    isLoading,
    stateForm,
    basicInfo,
    stateInfo,
    phoneNumber,
    locationInfo,
    savingLoader,
    handleSubmit,
    setStateForm,
    isModalVisible,
    setLocationInfo,
    isDataNotChanged,
    setIsModalVisible,
    communicationData,
    minTravelDistance,
    defaultLocationInfo,
    homeLocationDescription,
    isConfirmationModalVisible,
    setIsConfirmationModalVisible,
  } = props
  return (
    <div className="basic-info">
      <ButtonBack route={route.profile} title="Profile" />
      {savingLoader && <PopupLoader>Saving...</PopupLoader>}

      {isModalVisible && (
        <ModalBox
          visibleCloseIcon
          onCloseModal={() => {
            isDataNotChanged
              ? setIsModalVisible(false)
              : setIsConfirmationModalVisible(true)
          }}
        >
          <BasicInfoForm
            basicInfo={basicInfo}
            stateForm={stateForm}
            stateInfo={stateInfo}
            handleSubmit={handleSubmit}
            setStateForm={setStateForm}
            locationInfo={locationInfo}
            setLocationInfo={setLocationInfo}
            communicationData={communicationData}
            setIsModalVisible={setIsModalVisible}
            minTravelDistance={minTravelDistance}
            defaultLocationInfo={defaultLocationInfo}
            isConfirmationModalVisible={isConfirmationModalVisible}
            setIsConfirmationModalVisible={setIsConfirmationModalVisible}
          />
        </ModalBox>
      )}
      <div className="container">
        <LoadingContent isLoading={isLoading}>
          <BasicInfoContent
            userInfo={userInfo}
            basicInfo={stateInfo}
            phoneNumber={phoneNumber}
            openModal={setIsModalVisible}
            homeLocationDescription={homeLocationDescription}
          />
        </LoadingContent>
      </div>
    </div>
  )
}
