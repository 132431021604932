import React from 'react'
import { uploadResumeOfInvitedWorker } from '../../../../../services/adminWorkersAPI'
import { workWithTypeData, UploadResume, TUploadResume } from '../../../General'
import { ISetStateAction } from './AddResumeModal'
import './addResumeModal.scss'

interface Props extends ISetStateAction {
  classDragEnter: boolean
  file: File | null
  uploadResumeRef: React.ForwardedRef<TUploadResume>
}

export const AddResumeModalIU = (props: Props) => {
  const {
    setIsInviteOpen,
    setIsResumeModalOpen,
    setNewWorker,
    classDragEnter,
    file,
    setClassDragEnter,
    setFile,
    setUploadedResume,
    uploadResumeRef,
  } = props
  return (
    <div className="add-resume-modal">
      <h1 className="add-resume-modal__title">Invite New Worker</h1>
      <UploadResume
        className="add-resume-modal__content"
        accept={workWithTypeData.resumeSupportTypes().toString()}
        updatedFile={undefined}
        description="drag & drop (or click here) to upload the worker’s resume"
        isHandleClick
        classDragEnter={classDragEnter}
        file={file}
        uploadAction={uploadResumeOfInvitedWorker}
        setIsResumeModalOpen={setIsResumeModalOpen}
        setIsInviteOpen={setIsInviteOpen}
        setNewWorker={setNewWorker}
        setUploadedResume={setUploadedResume}
        setFile={setFile}
        setClassDragEnter={setClassDragEnter}
        ref={uploadResumeRef}
      />
      <p
        className="add-resume-modal__pass"
        onClick={() => {
          setIsResumeModalOpen(false)
          setIsInviteOpen(true)
          setNewWorker(null)
        }}
      >
        skip this step &gt;
      </p>
    </div>
  )
}
