import moment from 'moment'
import { forwardRef } from 'react'
import Calendar from 'react-calendar'
import polygonIcon from '../../../assets/icons/other/polygon-icon.svg'
import { Button } from '../buttons'
import { IDatePeriod } from './DoubleCalendar'

interface IProps {
  isOpen: boolean
  onBtnClick: () => void
  period: IDatePeriod
  startDate: Date
  handleClickDay: (currentDate: Date) => void
  onArrowClick: (event: React.MouseEvent, action: 'prev' | 'next') => void
  isOneMonth: boolean
}

export const DoubleCalendarUI = forwardRef<HTMLDivElement, IProps>(
  (props, ref) => {
    const {
      period,
      startDate,
      isOpen,
      handleClickDay,
      onArrowClick,
      onBtnClick,
      isOneMonth,
    } = props

    return (
      <div className="double-calendar" ref={ref}>
        <Button
          className={`double-calendar__btn ${
            isOpen ? 'double-calendar__btn_active' : ''
          }${
            !isOpen && period.date_min && period.date_max
              ? 'double-calendar__btn_selected'
              : ''
          }`}
          onClick={() => onBtnClick()}
          data-testid="double-calendar__btn"
        >
          {period.date_min && period.date_max
            ? `${moment(period.date_min).format('M/D')} - ${moment(
                period.date_max
              ).format('M/D')}`
            : 'Dates'}
          <img src={polygonIcon} alt="polygon icon" />
        </Button>

        <div
          className={`double-calendar__calendar-wrapper ${
            isOneMonth ? 'double-calendar__calendar-wrapper_one-month' : ''
          }`}
          data-testid="double-calendar__calendar-wrapper"
        >
          {isOpen && period.date_min && (
            <div
              className={`double-calendar__period ${
                isOneMonth ? 'double-calendar__period_one-month' : ''
              }`}
              data-testid="double-calendar__period"
            >
              <span>
                {period.date_min ? moment(period.date_min).format('M/D') : ''}
              </span>
              <span> - </span>
              <span>
                {period.date_max ? moment(period.date_max).format('M/D') : ''}
              </span>
            </div>
          )}

          {isOpen && (
            <Calendar
              className="double-calendar__calendar schedule__calendar"
              activeStartDate={startDate}
              maxDate={new Date()}
              showDoubleView={isOneMonth ? false : true}
              showNeighboringMonth={false}
              calendarType="US"
              minDetail="month"
              nextLabel={
                <div
                  onClick={event => onArrowClick(event, 'next')}
                  className="double-calendar__calendar-arrow schedule__calendar-arrow-next"
                  data-testid="schedule__calendar-arrow-next"
                ></div>
              }
              prevLabel={
                <div
                  onClick={event => onArrowClick(event, 'prev')}
                  className="double-calendar__calendar-arrow schedule__calendar-arrow-prev"
                  data-testid="schedule__calendar-arrow-prev"
                ></div>
              }
              next2Label={null}
              prev2Label={null}
              formatShortWeekday={(locale, date) =>
                moment(date).format('dd')[0]
              }
              onClickDay={handleClickDay}
              value={
                period.date_min && period.date_max
                  ? [period.date_min, period.date_max]
                  : period.date_min
              }
              tileClassName={'double-calendar__calendar-tile'}
            />
          )}
        </div>
      </div>
    )
  }
)
