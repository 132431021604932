import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Button } from '../../../../atoms'
import closeIcon from '../../../../../assets/icons/close/white_close-btn.svg'
import yellowCloseIcon from '../../../../../assets/icons/close/yellow_close.svg'
import roleIcon from '../../../../../assets/icons/employer/RoleCandidate.svg'
import locationIcon from '../../../../../assets/icons/employer/LocationZipCode.svg'
import skillsIcon from '../../../../../assets/icons/employer/OneMoreSkills.svg'
import './topFilterBar.scss'
import {
  getInstantLocation,
  getInstantSearch,
  getInstantSkills,
} from '../../../../../services/employerAPI'
import { SearchInput } from '../../../../molecules/Employer/Search/SearchInput'
import { FilterType, EFields } from '../../../../../models'
import {
  useAppDispatch,
  setFilters,
  setIsTopFilterVisible,
  defaultFilter,
  setDetailsSearched,
  setEnteredZipCode,
} from '../../../../../redux'
import { useHistory, useLocation } from 'react-router-dom'
import * as route from '../../../../../services/route'
import { useTypedSelector } from '../../../../../utils/hooks'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {}

export interface SearchInputState {
  value: string
  selected: string[]
}

export const zipFromSearch = 'zipcode_from_search'

export const TopFilterBar = forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const dispatch = useAppDispatch()
  const filterParams = useTypedSelector(s => s.variables.choiceListForEmployer)
  const isTopFilterVisible = useTypedSelector(
    state => state.employer.isTopFilterVisible
  )
  const history = useHistory()
  const initialInput = useMemo(
    () => ({
      value: '',
      selected: [],
    }),
    []
  )
  const [activeField, setActiveField] = useState<EFields>()
  const [roleInput, setRoleInput] = useState<SearchInputState>(initialInput)
  const [locationInput, setLocationInput] =
    useState<SearchInputState>(initialInput)
  const [skillsInput, setSkillsInput] = useState<SearchInputState>(initialInput)
  const [clearedInput, setCleared] = useState(false)
  const location = useLocation()
  const path = `/${location.pathname.split('/')[1]}`
  const isFieldsfilled: boolean = useMemo(
    () =>
      roleInput.value ||
      roleInput.selected.length ||
      locationInput.value ||
      locationInput.selected.length ||
      skillsInput.value ||
      skillsInput.selected.length
        ? true
        : false,
    [roleInput, locationInput, skillsInput]
  )
  const itemRegex = useMemo(() => /^\d+$/, [])
  const isEnteredZipCode = useMemo(
    () =>
      !!locationInput.value &&
      locationInput.value.length === 5 &&
      itemRegex.test(locationInput.value),
    [locationInput.value, itemRegex]
  )

  const handlingBlur = (e: React.SyntheticEvent) => {
    if (e.target instanceof Element) {
      if (
        e.target.className === 'top-filter-bar' ||
        e.target.className === 'top-filter-bar__title'
      ) {
        setActiveField(undefined)
      }
    }
  }

  const onClearInputs = useCallback(() => {
    setRoleInput(initialInput)
    setLocationInput(initialInput)
    setSkillsInput(initialInput)
    setCleared(true)
  }, [initialInput])

  const onSubmit = useCallback(() => {
    const isRoleInputFilled = !!roleInput.selected.length || !!roleInput.value
    const getRole =
      roleInput.selected.length && filterParams
        ? [
            filterParams.roles?.find(
              role => role.choice === roleInput.selected[0]
            ),
          ]
        : null

    const getSkills =
      skillsInput.selected.length && filterParams
        ? skillsInput?.selected.map(select =>
            filterParams.skills?.find(skill => select === skill.choice)
          )
        : null

    const locationList = [...locationInput.selected]
    locationInput.value && locationList.push(locationInput.value)

    const preparedSkills: {
      exact: boolean
      value:
        | {
            id: string | number
            choice: string
          }
        | undefined
    }[] = skillsInput.selected.map((elem, i) => ({
      exact: true,
      value: getSkills?.[i],
    }))
    skillsInput.value &&
      preparedSkills.push({
        exact: false,
        value: { id: skillsInput.value, choice: skillsInput.value },
      })

    if (isFieldsfilled) {
      dispatch(
        setFilters({
          ...defaultFilter,
          type: {
            type: FilterType.search,
            properties: isRoleInputFilled
              ? roleInput.selected.length
                ? 'regular'
                : 'name'
              : null,
          },
          role: getRole,
          name: roleInput.value ? roleInput.value : null,
          location: locationList.length ? locationList : null,
          skills: preparedSkills,
        })
      )
      dispatch(setIsTopFilterVisible(false))
      onClearInputs()

      if (isEnteredZipCode) {
        dispatch(setEnteredZipCode(locationInput.value))
        sessionStorage.setItem(zipFromSearch, locationInput.value)
      }

      if (path === route.workerDetails) {
        dispatch(setDetailsSearched(true))
        history.push(`${route.employerDashboard}`)
      } else dispatch(setDetailsSearched(false))
    }
  }, [
    dispatch,
    roleInput,
    filterParams,
    locationInput,
    skillsInput,
    isFieldsfilled,
    onClearInputs,
    path,
    history,
    isEnteredZipCode,
  ])

  useEffect(() => {
    isFieldsfilled && setCleared(false)
  }, [isFieldsfilled])

  useEffect(() => {
    !isTopFilterVisible && setActiveField(undefined)
  }, [isTopFilterVisible])

  return (
    <div onClick={e => handlingBlur(e)} ref={ref} className="top-filter-bar">
      <button
        className="top-filter-bar__close-btn"
        onClick={() => {
          dispatch(setIsTopFilterVisible(false))
        }}
      >
        <img src={closeIcon} alt="close button" />
      </button>
      <h4 className="top-filter-bar__title">Describe your ideal worker</h4>
      <div className="top-filter-bar__search-panel">
        <div className="top-filter-bar__search-list">
          <SearchInput
            className="top-filter-bar__input-radius"
            inputData={{
              placeholder: 'Enter Role or Candidate',
              name: EFields.role,
              icon: roleIcon,
            }}
            separator
            getListFromSearch={getInstantSearch}
            iType={EFields.role}
            setSelectedValue={setRoleInput}
            selectedValue={roleInput}
            clearedInput={clearedInput}
            activeField={activeField}
            setActiveField={setActiveField}
            onFocus={() => setActiveField(EFields.role)}
          />

          <SearchInput
            getListFromSearch={getInstantLocation}
            inputData={{
              placeholder: 'Enter Location or Zip Code',
              name: EFields.location,
              icon: locationIcon,
            }}
            separator
            iType={EFields.location}
            setSelectedValue={setLocationInput}
            selectedValue={locationInput}
            clearedInput={clearedInput}
            activeField={activeField}
            setActiveField={setActiveField}
            onFocus={() => setActiveField(EFields.location)}
          />

          <SearchInput
            getListFromSearch={getInstantSkills}
            inputData={{
              placeholder: 'Enter One or More Skills',
              name: EFields.skills,
              icon: skillsIcon,
            }}
            isMultiple
            iType={EFields.skills}
            setSelectedValue={setSkillsInput}
            selectedValue={skillsInput}
            clearedInput={clearedInput}
            activeField={activeField}
            setActiveField={setActiveField}
            onFocus={() => setActiveField(EFields.skills)}
          />

          {isFieldsfilled && (
            <button
              className="top-filter-bar__search-clear-btn"
              onClick={onClearInputs}
            >
              <img src={yellowCloseIcon} alt="clear new search" />
            </button>
          )}
          <Button className="top-filter-bar__search-btn" onClick={onSubmit}>
            GO
          </Button>
        </div>
      </div>
    </div>
  )
})
