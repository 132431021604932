import React from 'react'
import workerImg from '../../../../../assets/img/worker/blog/worker.png'
import './secondBlog.scss'

export const SecondBlog = () => {
  return (
    <div className="second-blog">
      <h3 className="second-blog__blog-title">
        5 tips for becoming a better carpenter
      </h3>
      <p className="second-blog__foreword">
        Whether you are first starting out or continuing your career path as a
        carpenter I have 5 tips to share with you for how to be a better
        carpenter.
      </p>
      <div className="second-blog__block">
        <h4 className="second-blog__block-title">Never stop learning</h4>
        <p>
          On site whether it’s something you’ve done a dozen times or something
          completely new to you there’s always a chance to learn, improve upon a
          technique, or maybe figure out a different way to do it that’s better
          or faster.
        </p>
        <p>
          My favorite way to learn is exchanging tips and tricks with other
          carpenters on site which encourages camaraderie. When not on site I’ve
          found lots of resources online for learning. One great source is
          Instagram (here’s mine) as there are many very skilled and talented
          carpenters that post content and share their knowledge and plus the
          video content is short and to the point.
        </p>
        <p>
          The content ranges from people reviewing the latest tools available,
          demonstrating tool hacks that make doing something easier, or
          explaining their own techniques for accomplishing a task. One more
          note, although learning skills and techniques is important don’t
          forget to hone your plan reading skills - that includes the
          architectural plans but also how other trade’s plans might effect how
          you build something.
        </p>
        <img src={workerImg} className="second-blog__img" alt="worker" />
        <p>This is not as ‘boring’ as it looks :) </p>
      </div>
      <div className="second-blog__block">
        <h4 className="second-blog__block-title">Ask for help</h4>
        <p>
          I have found that sometimes when I’m working I get lost in my own
          head, focused on finishing a project and maybe so focused on figuring
          something out or doing it myself that I realize afterwards it would
          have been easier and faster if I had just asked for help or figured
          something out with someone else’s perspective. This kind of ties back
          to the first tip, you can usually learn something when you ask for
          help. But also, while being able to work independent is often
          necessary, when there’s something that would be easier done with help
          than you are better asking for help.
        </p>
      </div>
      <div className="second-blog__block">
        <h4 className="second-blog__block-title">Plan ahead</h4>
        <p>
          Be mindful of all the steps that come after what you’re doing to
          achieve the finish project. The best carpenters build in a way that
          makes all the next steps smoother do accomplish.
        </p>
        <p>
          Whether that means adding blocking to receive hardware or cabinets or
          making sure a bathroom wall that is receiving tile framed with tile
          layout in mind. Sometimes the goal is not just to do a good job, to be
          a better carpenter think about the end goal and try to work out the
          details early in the building process.
        </p>
      </div>
      <div className="second-blog__block">
        <h4 className="second-blog__block-title">Get organized</h4>
        <p>
          Once you have a little bit of experience you realize there are certain
          tools that you reach for more often than others. Those are the tools
          carpenters keep in their tool belt. Beyond keeping your tool belt
          organized it’s a good idea to invest in tool containers that allow you
          to make it easy for you to find other tools you may need and have a
          spot to return your tools at the end of the day.
        </p>
        <p>
          You may also want to help keep the supplies organized so that it
          doesn’t take 10 minutes to find the screws that you need. Having a
          clean and organized work space not only helps keep you more focused on
          the project but it also reassures those around you of your
          professionalism.
        </p>
      </div>
      <div className="second-blog__block">
        <h4 className="second-blog__block-title">Be well rounded</h4>
        <p>
          Its definitely great when you have a specialty that you’re really good
          at and enjoy doing. Some carpenters like framing and dislike doing
          finish or vice versa. But if you want to be a better carpenter and one
          that your foreman will want to keep around it’s advantageous to learn
          all phases of building which will make you a more flexible worker and
          a Carpenter that can solve more problems.
        </p>
      </div>
    </div>
  )
}
