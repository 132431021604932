import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Button, OBInput } from '../../../components/atoms'
import {
  NavigationFooter,
  ProgressBarLinear,
} from '../../../components/molecules'
import {
  EPageTitleOB,
  listBlocksPBLinear,
} from '../../../utils/constants/obConstants'
import * as route from '../../../services/route'
import './obReferences.scss'
import { EObRefInputType } from './OBReferences'
import { IObRefCompanyDataType } from '../../../models'
import { useTranslation } from 'react-i18next'
import { forwardRef } from 'react'
import { inputFormatting, formatPhoneNumber } from '../../../utils/scripts'

interface IProps {
  onClickNext: (isButton: boolean) => void
  onClickBack: () => void
  addDataFirstCompany: (data: string, inputType?: string) => void
  addDataSecondCompany: (data: string, inputType?: string) => void
  firstCompanyData: IObRefCompanyDataType
  secondCompanyData: IObRefCompanyDataType
  isWaitingResponse: boolean
}

export const OBReferencesUI = forwardRef<HTMLInputElement, IProps>(
  (props, ref) => {
    const {
      onClickNext,
      onClickBack,
      addDataFirstCompany,
      firstCompanyData,
      secondCompanyData,
      addDataSecondCompany,
      isWaitingResponse,
    } = props
    const { t } = useTranslation()

    return (
      <div className="ob-references ob-container">
        <Helmet>
          <title>{EPageTitleOB.reference}</title>
        </Helmet>

        <ProgressBarLinear initData={listBlocksPBLinear} currentPage={12} />

        <div className="ob-references__content">
          <p className="ob-references__question ob-question">
            {t('references.Who can tell us how great you are?')}
          </p>

          <p className="ob-references__description ob-description">
            {t(
              'references.We would love to reach out to two people with whom you’ve previously worked (ideally who can vouch for the quality of your work!)'
            )}
          </p>

          <div className="ob-references__inputs-wrapper">
            <OBInput
              placeholder={t('name.First Name').toString()}
              value={firstCompanyData.first_name}
              onChange={e =>
                addDataFirstCompany(
                  inputFormatting(e.target.value, 50),
                  EObRefInputType.firstName
                )
              }
              ref={ref}
            />
            <OBInput
              placeholder={t('name.Last Name').toString()}
              value={firstCompanyData.last_name}
              onChange={e =>
                addDataFirstCompany(
                  inputFormatting(e.target.value, 50),
                  EObRefInputType.lastName
                )
              }
            />
            <OBInput
              placeholder={t('references.Phone Number').toString()}
              value={
                formatPhoneNumber(firstCompanyData.phone_number, false, true) ??
                ''
              }
              onChange={e =>
                addDataFirstCompany(e.target.value, EObRefInputType.phone)
              }
            />
            <OBInput
              placeholder={t('email.Email').toString()}
              value={firstCompanyData.email_address}
              onChange={e =>
                addDataFirstCompany(e.target.value, EObRefInputType.email)
              }
            />
          </div>

          <div className="ob-references__split-line"></div>

          <div className="ob-references__inputs-wrapper">
            <OBInput
              placeholder={t('name.First Name').toString()}
              value={secondCompanyData.first_name}
              onChange={e =>
                addDataSecondCompany(
                  inputFormatting(e.target.value, 50),
                  EObRefInputType.firstName
                )
              }
            />
            <OBInput
              placeholder={t('name.Last Name').toString()}
              value={secondCompanyData.last_name}
              onChange={e =>
                addDataSecondCompany(
                  inputFormatting(e.target.value, 50),
                  EObRefInputType.lastName
                )
              }
            />
            <OBInput
              placeholder={t('references.Phone Number').toString()}
              value={
                formatPhoneNumber(
                  secondCompanyData.phone_number,
                  false,
                  true
                ) ?? ''
              }
              onChange={e =>
                addDataSecondCompany(e.target.value, EObRefInputType.phone)
              }
            />

            <OBInput
              placeholder={t('email.Email').toString()}
              value={secondCompanyData.email_address}
              onChange={e =>
                addDataSecondCompany(e.target.value, EObRefInputType.email)
              }
            />
          </div>
        </div>

        <Link
          to={route.OBPath.onboardingReferences}
          className="ob-references__link"
          onClick={() => onClickNext(false)}
        >
          {t('references.I’d rather do this later')}
        </Link>
        <NavigationFooter actionBack={onClickBack}>
          <Button
            onClick={() => onClickNext(true)}
            disabled={
              ((!firstCompanyData.first_name ||
                !firstCompanyData.last_name ||
                !firstCompanyData.email_address ||
                !firstCompanyData.phone_number) &&
                (!secondCompanyData.first_name ||
                  !secondCompanyData.last_name ||
                  !secondCompanyData.email_address ||
                  !secondCompanyData.phone_number)) ||
              isWaitingResponse
            }
          >
            {t('Shared_Header.Next')}
          </Button>
        </NavigationFooter>
      </div>
    )
  }
)
