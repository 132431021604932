import React, { ReactElement } from 'react'
import { Link as RouteLink } from 'react-router-dom'
import { Button } from '../../../atoms'

import './secondaryCard.scss'

type Link = string
type ClickAction = () => void

type Action = Link | ClickAction

interface Props extends React.HTMLAttributes<HTMLElement> {
  imgUrl?: string
  title?: string
  description: string
  buttonText: string
  titleClassName?: string
  descriptionClassName?: string
  action: Action
  additionalChild?: ReactElement
}

export const SecondaryCard = (props: Props) => {
  const {
    className,
    imgUrl,
    title,
    description,
    buttonText,
    titleClassName,
    descriptionClassName,
    action,
    additionalChild,
    children,
  } = props
  return (
    <div className={`secondary-card ${className}`}>
      {imgUrl && (
        <div
          className="secondary-card__img"
          style={{ backgroundImage: `url(${imgUrl})` }}
          data-testid="secondary-card__img"
        ></div>
      )}
      <div className="secondary-card__content">
        <div className="secondary-card__children">{children}</div>
        <h3 className={`secondary-card__title ${titleClassName ?? ''}`}>
          {title ?? ''}
        </h3>
        <p
          className={`secondary-card__description ${
            descriptionClassName ?? ''
          }`}
        >
          {description}
        </p>
        {typeof action === 'string' ? (
          <RouteLink className="secondary-card__link" to={action as Link}>
            <Button className="secondary-card__button">{buttonText}</Button>
          </RouteLink>
        ) : (
          <Button
            className="secondary-card__button"
            onClick={action as ClickAction}
          >
            {buttonText}
          </Button>
        )}
      </div>
      <div className="secondary-card__additional-block">{additionalChild}</div>
    </div>
  )
}
