import React, { ReactElement, useEffect, useState } from 'react'
import closingArrow from '../../../assets/icons/arrows/black_arrow_right.svg'
import './hidingFilter.scss'

interface Props extends React.HTMLAttributes<HTMLElement> {
  filterTitle: ReactElement | string
  opened?: true
  isLoading?: boolean
  dynamicList?: true
}

export const HidingFilter = (props: Props) => {
  const { opened, isLoading, dynamicList } = props
  const filterContent = React.useRef<HTMLDivElement>(null)
  const [isOpened, setIsOpened] = useState<boolean>(opened ?? false)

  useEffect(() => {
    if (filterContent.current && !!props.children?.toLocaleString()) {
      const height = filterContent.current?.offsetHeight ?? 0
      if (!dynamicList) {
        filterContent.current.style.marginTop = !isOpened ? `-${height}px` : '0'
      } else {
        if (isOpened) filterContent.current.style.marginTop = `0`
        else {
          filterContent.current.style.marginTop = `-${
            isLoading ? height : 9999
          }px`
        }
      }
    }
  }, [isOpened, props.children, dynamicList, isLoading])

  return (
    <div className={`hiding-filter ${props.className ?? ''}`}>
      <div
        className="hiding-filter__title-wrapper"
        onClick={() => setIsOpened(!isOpened)}
        data-testid="hiding-filter-title-wrapper"
      >
        <h4 className="hiding-filter__title">{props.filterTitle}</h4>
        <img
          src={closingArrow}
          alt="closing arrow"
          className={`hiding-filter__closing-arrow ${
            isOpened ? 'hiding-filter__closing-arrow_opened' : ''
          }`}
        />
      </div>
      <div className="hiding-filter__filter-wrapper">
        <div
          ref={filterContent}
          className="hiding-filter__filter"
          data-testid="hiding-filter-content"
        >
          {props.children ?? ''}
        </div>
      </div>
    </div>
  )
}
