import {
  SignInResponse,
  User,
  DetailsResponse,
  QualificationsUser,
  UserStatus,
} from '../models/ProfileModel'
import { API, API_NO_TOKEN } from './API'
import { ChallengeModel } from '../models/ChallengeModel'
import {
  ChallengeResultModel,
  MetricsModel,
  UpcomingInterview,
} from '../models/ChallengeResultModel'
import { responseHandler } from './responseHandlers'

export const ERROR_SOMETHING = 'Something went wrong, try again later'

export const login = (
  username: string,
  password: string
): Promise<SignInResponse> => {
  return responseHandler(
    API_NO_TOKEN.post('api/api-token-auth/', {
      username: username,
      password: password,
    })
  )
}

export const signOut = async (token: string): Promise<{}> => {
  return await responseHandler(API.post('api/users/logout/', { token }))
}

export const getUserStatus = async (): Promise<UserStatus> => {
  return responseHandler(API.get('api/users/status/'))
}

export const putUserData = async (user: FormData): Promise<User> => {
  return responseHandler(
    API.put(`api/users/update_me/`, user).then(response => response.data),
    true
  )
}

export const putUserQualifications = async (
  user: FormData
): Promise<QualificationsUser> => {
  return responseHandler(API.put(`api/users/update_info/`, user), true)
}

export const register = (username: string, password: string): Promise<User> => {
  return responseHandler(
    API_NO_TOKEN.post('api/users/register/', { username, password })
  )
}

export const getAccessNewLoginPage = (): Promise<{
  allow_email_login: boolean
}> => {
  return responseHandler(API_NO_TOKEN.get('api/email-login/allow-email-login/'))
}
export const sendLoginLink = (data: string, from?: string): Promise<any> => {
  return responseHandler(
    API_NO_TOKEN.post('api/email-login/one-time-token/', { data, from })
  )
}

export const getLoginToken = (data: string): Promise<SignInResponse> => {
  return responseHandler(
    API_NO_TOKEN.get(`api/email-login/login/?token=${data}`)
  )
}

export const registerOB = (
  username: string,
  phone: string,
  first_name: string,
  last_name: string,
  source?: string
): Promise<{ token: string }> => {
  return responseHandler(
    API_NO_TOKEN.post('api/onboarding/start/', {
      username,
      phone,
      first_name,
      last_name,
      source,
    })
  )
}

export const forgotPasswordRequest = async (
  username: String
): Promise<DetailsResponse> => {
  return responseHandler(
    API_NO_TOKEN.post('api/forgot_password/', { email: username })
  )
}

export const resetPassword = async (
  token: String,
  uid: String,
  password: String
): Promise<DetailsResponse> => {
  return responseHandler(
    API_NO_TOKEN.post('api/change_password/', {
      new_password: password,
      token: token,
      uidb64: uid,
    })
  )
}

export const setPassword = async (
  oldPass: string,
  newPass: string
): Promise<void> => {
  return responseHandler(
    API.post('api/set_password/', {
      old_password: oldPass,
      new_password: newPass,
    })
  ).then(_ => Promise.resolve())
}

export const getChallenges = async (): Promise<ChallengeModel[]> => {
  return responseHandler(API.get('api/forms/'))
}

export const getChallengeResult = async (
  id: number
): Promise<ChallengeResultModel> => {
  return responseHandler(API.get(`api/forms/${id}/last_result/`))
}

export const getLatestChallengeResult =
  async (): Promise<ChallengeResultModel> => {
    return responseHandler(API.get('api/forms/last_result/'))
  }

export const getMetrics = async (id: number): Promise<MetricsModel> => {
  return responseHandler(API.get(`api/forms/${id}/metrics/`))
}

export const getUpcommingPreInterview = async (
  tz: string
): Promise<UpcomingInterview | null> => {
  return responseHandler<{ 'upcoming-interviews': UpcomingInterview }>(
    API.get(`api/users/upcoming-interviews/?timezone=${tz}`)
  ).then(obj => {
    const res =
      obj['upcoming-interviews'] === null ? null : obj['upcoming-interviews']
    return res
  })
}

export const resendEmail = async (email: string) => {
  return responseHandler(
    API_NO_TOKEN.post('api/resend-verification/', { email })
  )
}
