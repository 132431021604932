import { useQuery } from 'react-query'
import {
  ELandingPagesSStoreKeys,
  EmployerQueries,
} from '../../../../utils/constants'
import { getWorkerInfo } from '../../../../services/employerAPI'
import { InviteInterviewPageUI } from './InviteInterviewPageUI'
import { useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { IInterviewUrlData } from '../../../../models'

export const InviteInterviewPage = () => {
  const location = useLocation()
  const { unique_url } = useParams<{ unique_url: string }>()
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get('token')
  const action_uuid = searchParams.get('action')
  const isInterviewInvited = sessionStorage.getItem(
    ELandingPagesSStoreKeys.isInterviewInvited
  )

  const [isSuccess, setIsSuccess] = useState<boolean>(!!isInterviewInvited)
  const interviewUrlData: IInterviewUrlData = useMemo(
    () => ({
      token,
      action: action_uuid ?? undefined,
    }),
    [token, action_uuid]
  )

  const { data: workerData, isLoading } = useQuery(
    [EmployerQueries.workerData],
    () => getWorkerInfo(unique_url, false, token ?? undefined),
    {
      enabled: !!unique_url,
    }
  )

  return (
    <InviteInterviewPageUI
      isLoading={isLoading}
      isSuccess={isSuccess}
      setIsSuccess={setIsSuccess}
      workerData={workerData?.user_card}
      interviewUrlData={interviewUrlData}
    />
  )
}
