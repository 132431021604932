import React, {
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { toast } from 'react-toastify'
import {
  IInterviewUrlData,
  InterviewFormatTypes,
  IInterviewRequestData,
  UserCard,
} from '../../../../../models'
import { ModalData } from '../../../../../pages/InAppFlow'
import {
  getInterviewTypes,
  immediatelySetInterview,
} from '../../../../../services/employerAPI'
import { EmployeeInfoContext } from '../../../../Context/EmployeeInfoContext'
import { sendMetriks } from '../../../../../GoogleAnalytics/GoogleAnalytics'
import { RadioBtnElement } from '../../../../molecules'
import { useStateWithDep } from '../../../../../utils/hooks'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  ELandingPagesSStoreKeys,
  EmployerQueries,
} from '../../../../../utils/constants'
import { ElemObj } from '../../../../atoms'
import { InterviewFormatUI } from './InterviewFormatUI'
import { EEmployerLStoreKeys } from '../../../../../utils/constants/localStorageKeys'
import { useParams } from 'react-router-dom'

interface IImmedSetInterview {
  userId: number
  interviewData: IInterviewRequestData
  interviewUrlData?: IInterviewUrlData
}

export interface IInterviewData {
  role: string
  location: ElemObj | null
  availability: string
  trade: ElemObj | null
}

interface Props {
  userData: UserCard | null
  setActiveModal?: React.Dispatch<SetStateAction<ModalData | undefined>>
  setIsSuccessModal?: React.Dispatch<React.SetStateAction<boolean>>
  setInvitedRequests?: React.Dispatch<React.SetStateAction<number[]>>
  interviewUrlData?: IInterviewUrlData
}

export const InterviewFormat = (props: Props) => {
  const {
    userData,
    setActiveModal,
    setIsSuccessModal,
    setInvitedRequests,
    interviewUrlData,
  } = props

  const queryClient = useQueryClient()
  const { unique_url } = useParams<{ unique_url: string }>()
  const worker = useContext(EmployeeInfoContext)
  const workerData = userData ?? worker.workerData?.user_card
  const interviewStoreData: null | IInterviewRequestData = JSON.parse(
    `${localStorage.getItem(EEmployerLStoreKeys.interviewData)}`
  )

  const [interviewTypes, setinterviewTypes] = useState<RadioBtnElement[]>()
  const [locationsList, setLocationsList] = useState<ElemObj[]>([])
  const [tradesList, setTradesList] = useState<ElemObj[]>([])

  const defaultInterviewData: IInterviewData = useMemo(
    () => ({
      role: interviewStoreData?.role ?? '',
      location:
        locationsList.find(
          place => place.id === interviewStoreData?.project_id
        ) ?? null,
      availability: interviewStoreData?.availability ?? '',
      trade:
        tradesList.find(trade => trade.id === interviewStoreData?.trade_id) ??
        null,
    }),
    [
      interviewStoreData?.availability,
      interviewStoreData?.project_id,
      interviewStoreData?.role,
      locationsList,
      tradesList,
      interviewStoreData?.trade_id,
    ]
  )

  const defaultActiveBtn = useMemo(() => {
    const activeStoreBtn = interviewTypes?.find(
      btn => !btn?.isDisabled && btn.value === interviewStoreData?.type
    )?.id

    const activeBtn = interviewTypes?.find(btn => !btn?.isDisabled)?.id

    return activeStoreBtn ?? activeBtn ?? 1
  }, [interviewStoreData?.type, interviewTypes])

  const [interviewData, setInterviewData] =
    useStateWithDep<IInterviewData>(defaultInterviewData)

  const [selectedFormat, setSelectedFormat] =
    useStateWithDep<number>(defaultActiveBtn)

  const { data: interviewTypesData } = useQuery(
    [EmployerQueries.interviewTypes, workerData?.id],
    () =>
      workerData &&
      getInterviewTypes(workerData.id, interviewUrlData?.token ?? undefined)
  )

  const immedSetInterviewMutation = useMutation(
    (data: IImmedSetInterview) =>
      immediatelySetInterview(
        data.userId,
        data.interviewData,
        data.interviewUrlData
      ),
    {
      onError: err => {
        if (err instanceof Error)
          toast.error(err.message, {
            toastId: err.message,
          })
      },
    }
  )

  const textboxHandler = useCallback(
    (value: string, name: keyof IInterviewData) => {
      setInterviewData(prevData => ({
        ...prevData,
        [name]: value,
      }))
    },
    [setInterviewData]
  )

  const checkInterviewData = useCallback(() => {
    if (interviewUrlData) {
      if (!interviewUrlData.token) {
        toast.error('Something went wrong, check the link')
        return false
      }
    }

    return true
  }, [interviewUrlData])

  const storeSaving = useCallback(
    (data: IInterviewRequestData) => {
      localStorage.setItem(
        EEmployerLStoreKeys.interviewData,
        JSON.stringify(data)
      )

      if (interviewUrlData) {
        sessionStorage.setItem(
          ELandingPagesSStoreKeys.isInterviewInvited,
          JSON.stringify(true)
        )
      }
    },
    [interviewUrlData]
  )

  const sendInterview = useCallback(() => {
    const preparedData: IInterviewRequestData = {
      type: interviewTypes?.[selectedFormat - 1]?.value as InterviewFormatTypes,
      role: interviewData.role,
      project_id: +interviewData.location!.id,
      availability: interviewData.availability,
      trade_id: +(interviewData.trade?.id ?? 0),
    }

    if (!checkInterviewData()) {
      return
    }

    userData &&
      immedSetInterviewMutation.mutate(
        {
          userId: userData.id,
          interviewData: preparedData,
          interviewUrlData: interviewUrlData,
        },
        {
          onSuccess: () => {
            storeSaving(preparedData)
            if (setActiveModal) setActiveModal(undefined)
            if (setIsSuccessModal) setIsSuccessModal(true)
            if (!interviewUrlData) {
              sendMetriks(
                'interview_request',
                'interview_request',
                `${workerData?.first_name} ${workerData?.last_name}`
              )
              if (setInvitedRequests) {
                setInvitedRequests(prev => [...prev, userData.id])
              }
            }
            queryClient.refetchQueries([
              EmployerQueries.workerData,
              { unique_url },
            ])
          },
          onError: () => {
            if (setActiveModal) setActiveModal(undefined)
          },
        }
      )
  }, [
    queryClient,
    unique_url,
    checkInterviewData,
    immedSetInterviewMutation,
    interviewData.availability,
    interviewData.location,
    interviewData.role,
    interviewTypes,
    interviewUrlData,
    selectedFormat,
    setActiveModal,
    setInvitedRequests,
    setIsSuccessModal,
    userData,
    workerData?.first_name,
    workerData?.last_name,
    storeSaving,
    interviewData.trade?.id,
  ])

  useEffect(() => {
    if (interviewTypesData) {
      setinterviewTypes(
        interviewTypesData.available_interview_types.map((type, i) => ({
          id: i + 1,
          value: type.type,
          isDisabled: !type.available,
        }))
      )

      setLocationsList(
        interviewTypesData.projects.map(project => ({
          id: project.id,
          element: project.name ?? '',
        }))
      )

      setTradesList(
        interviewTypesData.trades.map(trade => ({
          id: trade.id,
          element: trade.name ?? '',
        }))
      )
    }
  }, [interviewTypesData])

  return (
    <InterviewFormatUI
      isLoading={immedSetInterviewMutation.isLoading}
      locationsList={locationsList}
      interviewData={interviewData}
      setInterviewData={setInterviewData}
      selectedFormat={selectedFormat}
      setSelectedFormat={setSelectedFormat}
      sendInterview={sendInterview}
      workerData={workerData}
      interviewTypes={interviewTypes}
      tradesList={tradesList}
      textboxHandler={textboxHandler}
    />
  )
}
