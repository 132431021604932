import React, { useState, ReactElement, useMemo, useEffect } from 'react'
import lodash from 'lodash'
import './slider.scss'

interface Props extends React.HTMLAttributes<HTMLElement> {
  sliderTitle: ReactElement | string
  isRangeOpened: boolean
  defaultValue?: string
  rangeProcessing: (value: string) => void
  minValue?: string
}

export const Slider = React.memo((props: Props) => {
  const {
    isRangeOpened,
    defaultValue,
    sliderTitle,
    rangeProcessing,
    minValue,
  } = props

  const [value, setValue] = useState<string | undefined>(defaultValue)
  const rangeValue = useMemo(() => React.createRef<HTMLInputElement>(), [])
  const [isSliderOpen, setIsSliderOpen] = useState<boolean>(isRangeOpened)

  const debounceFunc = useMemo(
    () => lodash.debounce(rangeProcessing, 500),
    [rangeProcessing]
  )

  useEffect(() => {
    if (value) setIsSliderOpen(true)
    if (rangeValue.current) {
      if (minValue) {
        rangeValue.current.value = minValue
        rangeValue.current.style.setProperty('--value', value ?? minValue)
        rangeValue.current.style.setProperty('--min', minValue)
      } else {
        rangeValue.current.value = '0'
        rangeValue.current.style.setProperty('--value', `${value ?? 0}`)
      }
    }
  }, [rangeValue, value, setIsSliderOpen, minValue])

  const rangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounceFunc(e.target.value)
    setValue(e.target.value)
    e.target.style.setProperty('--value', e.target.value)
    e.target.style.setProperty(
      '--min',
      e.target.min === '' ? minValue ?? '0' : e.target.min
    )
    e.target.style.setProperty(
      '--max',
      e.target.max === '' ? '100' : e.target.max
    )
  }

  useEffect(() => {
    if (!isRangeOpened && !defaultValue) {
      setIsSliderOpen(false)
      setValue(undefined)
    }
  }, [isRangeOpened, defaultValue])

  useEffect(() => {
    if ((minValue && value === minValue) || (!minValue && value === '0')) {
      setValue(undefined)
      setIsSliderOpen(false)
    }
  }, [value, isRangeOpened, minValue])

  return (
    <div
      className={`slider-container ${props.className ? props.className : ''}`}
    >
      <p className="slider-container__title">{sliderTitle}</p>
      <div className="slider-container__input-wrapper">
        <input
          ref={rangeValue}
          min={minValue ?? '0'}
          max="100"
          value={value}
          type="range"
          className={`slider-container__input slider-container__input_progress ${
            !isSliderOpen ? 'slider-container__input_disabled' : ''
          }`}
          onChange={rangeHandler}
          onMouseDown={() => setIsSliderOpen(true)}
        />
        <div className="slider-container__percent-block-wrapper">
          {isSliderOpen && (
            <div
              className="slider-container__percent-block"
              style={{
                marginLeft: `calc(${value ?? minValue ?? 0}% - ${
                  (value && !parseInt(value)) || !value
                    ? 25
                    : value === '100'
                    ? 33
                    : 29
                }px)`,
              }}
            >
              {minValue ? `$${value ?? minValue}` : `${value ?? 0}%+`}
            </div>
          )}
        </div>
      </div>
    </div>
  )
})
