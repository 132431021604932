import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react'
import {
  IConvertedFilterItem,
  IConvertedFilterList,
  IFilterDate,
} from '../../../../models/AdminWorkes'
import { useTypedSelector } from '../../../../utils/hooks'
import { EAdminWorkersSStoreKeys } from '../../../../utils/constants'
import { WorkersDataForAdminContext } from '../../../Context/adminContext/WorkersDataForAdminContext'
import { WorkerFiltersUI } from './WorkerFiltersUI'

export const fieldsName = {
  LOCATIONS: 'locations',
  ROLES: 'roles',
  STATUS: 'status',
  DATE: 'date',
}

function convertFilterList(list: any) {
  const result: IConvertedFilterList = {}
  for (const key in list) {
    const filterItems: IConvertedFilterItem[] = list[key].map((item: any) => {
      return {
        key: Object.values(item)[0],
        value: Object.values(item)[1],
        isChecked: false,
      }
    })
    result[key] = filterItems
  }
  return result
}

export const WorkerFilters = () => {
  const filterRef = useRef<HTMLDivElement>(null)
  const filtersOptions = useTypedSelector(
    state => state.variables.filterOptionsForAdmin
  )
  const { setQueryParams, period, setPeriod, setCurrentPage } = useContext(
    WorkersDataForAdminContext
  )
  const convertedFilterList = convertFilterList(filtersOptions)
  const filterListFromStorage = JSON.parse(
    `${sessionStorage.getItem(EAdminWorkersSStoreKeys.filterList)}`
  )
  const initialFiltersState: Record<string, boolean> = useMemo(
    () => ({
      locations: false,
      roles: false,
      status: false,
      date: false,
    }),
    []
  )

  const [checkedFilterList, setCheckedFilterList] =
    useState<IConvertedFilterList>(filterListFromStorage ?? convertedFilterList)

  const [filterIsOpen, setFilterIsOpen] =
    useState<Record<string, boolean>>(initialFiltersState)

  const filtersTogler = useCallback(
    (filterName: string, isOpen: boolean) => {
      const newState: Record<string, boolean> = {}
      Object.keys(filterIsOpen).forEach(key => {
        newState[key] = key === filterName ? isOpen : false
      })
      setFilterIsOpen(newState)
    },
    [filterIsOpen]
  )

  const handlerQueryParamLocation = useCallback(
    (key: string, isChecked: boolean) => {
      setQueryParams(prevQueryParams => {
        setCurrentPage(0)
        if (!isChecked) {
          const removedQuery = prevQueryParams.locations?.filter(
            location => location !== key
          )
          return {
            ...prevQueryParams,
            locations: removedQuery ?? prevQueryParams.locations,
          }
        }
        return prevQueryParams.locations === null
          ? { ...prevQueryParams, locations: [key] }
          : {
              ...prevQueryParams,
              locations: [...prevQueryParams.locations, key],
            }
      })
    },
    [setQueryParams, setCurrentPage]
  )

  const handlerQueryParamStatus = useCallback(
    (key: string, isChecked: boolean) => {
      setQueryParams(prevQueryParams => {
        setCurrentPage(0)
        if (!isChecked) {
          const removedQuery = prevQueryParams.status?.filter(
            status => status !== key
          )
          return {
            ...prevQueryParams,
            status: removedQuery ?? prevQueryParams.status,
          }
        }

        return prevQueryParams.status === null
          ? { ...prevQueryParams, status: [key] }
          : { ...prevQueryParams, status: [...prevQueryParams.status, key] }
      })
    },
    [setQueryParams, setCurrentPage]
  )

  const handlerQueryParamRoles = useCallback(
    (key: string, isChecked: boolean) => {
      setQueryParams(prevQueryParams => {
        setCurrentPage(0)
        if (!isChecked) {
          const removedQuery = prevQueryParams.roles?.filter(roles => {
            return roles !== key
          })

          return {
            ...prevQueryParams,
            roles: removedQuery ?? prevQueryParams.roles,
          }
        }

        return prevQueryParams.roles === null
          ? { ...prevQueryParams, roles: [key] }
          : { ...prevQueryParams, roles: [...prevQueryParams.roles, key] }
      })
    },
    [setQueryParams, setCurrentPage]
  )

  const handlerQueryParamDates = useCallback(
    (period: IFilterDate) => {
      setCurrentPage(0)

      setQueryParams(prevQueryParams => ({
        ...prevQueryParams,
        page_number: 0,
        date_min: period.date_min,
        date_max: period.date_max,
      }))
    },
    [setQueryParams, setCurrentPage]
  )

  const checkedFilter = useCallback(
    (listName: string, key: string, isChecked: boolean) => {
      setCheckedFilterList(prevState => {
        const updatedListData = prevState[listName].map(item =>
          item.key === key ? { ...item, isChecked: isChecked } : item
        )
        sessionStorage.setItem(
          EAdminWorkersSStoreKeys.filterList,
          JSON.stringify({ ...prevState, [listName]: updatedListData })
        )
        return { ...prevState, [listName]: updatedListData }
      })

      switch (listName) {
        case fieldsName.LOCATIONS:
          handlerQueryParamLocation(key, isChecked)
          break

        case fieldsName.STATUS:
          handlerQueryParamStatus(key, isChecked)
          break

        case fieldsName.ROLES:
          handlerQueryParamRoles(key, isChecked)
      }
    },
    [handlerQueryParamLocation, handlerQueryParamRoles, handlerQueryParamStatus]
  )

  const clickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        filterRef.current &&
        (!filterRef.current.contains(event.target as Node) ||
          (event.target as Element).matches('#worker-filters'))
      ) {
        setFilterIsOpen(initialFiltersState)
      }
    },
    [initialFiltersState]
  )

  useEffect(() => {
    document.addEventListener('mousedown', clickOutside)

    return () => {
      document.removeEventListener('mousedown', clickOutside)
    }
  }, [clickOutside])

  return (
    <WorkerFiltersUI
      convertedFilterList={checkedFilterList}
      checkedFilter={checkedFilter}
      filterIsOpen={filterIsOpen}
      filtersTogler={filtersTogler}
      period={period}
      setPeriod={setPeriod}
      handlerQueryParamDates={handlerQueryParamDates}
      filterRef={filterRef}
    />
  )
}
