import React, { useCallback, useMemo, useState } from 'react'
import {
  BasicInfoModel,
  PublicInfo,
  WorkPreferencesInfo,
} from '../../../../../models'
import { UploadPhoto } from '../../../General/UploadPhoto'
import zipcodes from 'zipcodes'
import basicPhoto from '../../../../../assets/icons/other/profile-public-icon.svg'
import { workWithTypeData } from '../../../General/UploadFiles/DataTypes/DataTypes'
import bckDefPhoto from '../../../../../assets/img/worker/profile/backgroundDefault.png'
import {
  getBasicInfo,
  getWorkPreferencesInfo,
} from '../../../../../services/workerApi/profileAPI'
import { useQuery, useQueryClient } from 'react-query'
import { WorkerQueries } from '../../../../../utils/constants'
import { QueryState } from 'react-query/types/core/query'
import { PublicProfileHeaderUI } from './PublicProfileHeaderUI'

export interface INames {
  first_name: string
  last_name: string
  nickname: string
}

interface Props extends React.HTMLAttributes<HTMLElement> {
  publicInfoData: PublicInfo
  onTrackingUploadFile: () => void
}

export const PublicProfileHeader = (props: Props) => {
  const { publicInfoData, onTrackingUploadFile } = props
  const queryClient = useQueryClient()

  const basicInfoQuery: QueryState<BasicInfoModel | undefined> | undefined =
    queryClient.getQueryState(WorkerQueries.basicInfo)

  const { data: basicInfo } = useQuery<BasicInfoModel>(
    WorkerQueries.basicInfo,
    () => getBasicInfo(),
    { enabled: !basicInfoQuery?.data }
  )
  const workPrefQuery: QueryState<WorkPreferencesInfo, undefined> | undefined =
    queryClient.getQueryState(WorkerQueries.workPreferencesInfo)

  const { data: workPreferences } = useQuery(
    WorkerQueries.workPreferencesInfo,
    () => getWorkPreferencesInfo(),
    { enabled: !workPrefQuery?.data }
  )
  const [publicInfo, setPublicInfo] = useState<PublicInfo>(publicInfoData)
  const [isEditNameModalVisible, setIsEditNameModalVisible] =
    useState<boolean>(false)
  const [isBckImgModalVisible, setIsBckImgModalVisible] =
    useState<boolean>(false)
  const [isUserImgModalVisible, setIsUserImgModalVisible] =
    useState<boolean>(false)
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false)
  const [names, setNames] = useState<INames>({
    first_name: publicInfo.first_name ?? '',
    last_name: publicInfo.last_name ?? '',
    nickname: publicInfo.nickname ?? '',
  })

  const location = zipcodes.lookup(basicInfo?.zip_code ?? '')

  const userPhoto = useMemo(
    () => (
      <UploadPhoto
        className="public-profile-header__user-photo"
        updatedPhoto={publicInfo?.profile_picture ?? undefined}
        basicPhoto={basicPhoto}
        accept={workWithTypeData.photoSupportTypes().toString()}
        defaultValue={publicInfo?.profile_picture ?? undefined}
        withoutBtns
        isModalVisible={isUserImgModalVisible}
        setIsModalVisible={setIsUserImgModalVisible}
        onTrackingUploadFile={onTrackingUploadFile}
      />
    ),
    [publicInfo?.profile_picture, isUserImgModalVisible, onTrackingUploadFile]
  )

  const backImg = useMemo(
    () => (
      <UploadPhoto
        updatedPhoto={publicInfo?.background_image ?? bckDefPhoto}
        basicPhoto={basicPhoto}
        accept={workWithTypeData.photoSupportTypes().toString()}
        defaultValue={publicInfo?.background_image ?? bckDefPhoto}
        withoutBtns
        bckImage
        isModalVisible={isBckImgModalVisible}
        setIsModalVisible={setIsBckImgModalVisible}
        onTrackingUploadFile={onTrackingUploadFile}
      />
    ),
    [publicInfo?.background_image, isBckImgModalVisible, onTrackingUploadFile]
  )

  const onCloseEditNameModal = useCallback(() => {
    if (
      publicInfo?.first_name === names.first_name &&
      publicInfo?.last_name === names.last_name &&
      (publicInfo?.nickname === names.nickname ||
        (!publicInfo?.nickname && !names.nickname))
    ) {
      setIsEditNameModalVisible(false)
    } else {
      setIsConfirmationModalVisible(true)
    }
  }, [
    names.first_name,
    names.last_name,
    names.nickname,
    publicInfo?.first_name,
    publicInfo?.last_name,
    publicInfo?.nickname,
  ])

  return (
    <PublicProfileHeaderUI
      workPreferences={workPreferences}
      publicInfo={publicInfo}
      setPublicInfo={setPublicInfo}
      isEditNameModalVisible={isEditNameModalVisible}
      setIsEditNameModalVisible={setIsEditNameModalVisible}
      isConfirmationModalVisible={isConfirmationModalVisible}
      setIsConfirmationModalVisible={setIsConfirmationModalVisible}
      setIsUserImgModalVisible={setIsUserImgModalVisible}
      setIsBckImgModalVisible={setIsBckImgModalVisible}
      names={names}
      setNames={setNames}
      location={location}
      userPhoto={userPhoto}
      backImg={backImg}
      onCloseEditNameModal={onCloseEditNameModal}
    />
  )
}
