import React from 'react'
import './confirmationUpdateModal.scss'

interface Props {
  title: string
  uploadText: string
  removeText?: string | null
  uploadAction?: (e: React.MouseEvent<HTMLElement>) => void
  remove?: (e: React.MouseEvent<HTMLElement>) => void
  closeAction?: () => void
  updateData?: (e: React.SyntheticEvent<HTMLFormElement>) => void
}

export const ConfirmationUpdateModal = (props: Props) => {
  return (
    <div className="confirmation-update-modal">
      <h3>{props.title}</h3>
      {props.updateData && (
        <p className="confirmation-update-modal__description">
          Are you sure you want to discard the changes you made?
        </p>
      )}
      <hr />
      {!props.updateData ? (
        <span
          onClick={props.uploadAction}
          className="confirmation-update-modal__upload-btn"
        >
          {props.uploadText}
        </span>
      ) : (
        <form
          onClick={props.updateData}
          className="confirmation-update-modal__upload-btn"
        >
          {props.uploadText}
        </form>
      )}
      <hr />
      {props.removeText && (
        <span
          onClick={props.remove}
          className="confirmation-update-modal__remove-btn"
        >
          {props.removeText}
        </span>
      )}
      {props.updateData ? null : (
        <>
          {props.removeText && <hr />}
          {props.closeAction && (
            <span
              onClick={props.closeAction}
              className="confirmation-update-modal__cancel-btn"
            >
              Cancel
            </span>
          )}
        </>
      )}
    </div>
  )
}
