import './WorkExperienceBlock'
import {
  AnswerWorkExperience,
  ColorElem,
  ResultWorkExperience,
} from '../../../../../models/ProfileModel'
import moment from 'moment'
import { EDate } from '../../../../../utils/constants'
import { getEndDate, getStartDate } from '../../../../../utils/scripts'

export interface FormatedExpList {
  job_number: number
  questions_work_experience: AnswerWorkExperience
  color: ColorElem
  isCurrentDate?: boolean
}

export const greenColor: ColorElem = {
  backgroundColor: '#397E44',
}
export const blueColor: ColorElem = {
  backgroundColor: '#3A758C',
}

export enum EJobTitle {
  company_name = 'Company Name',
  job_title = 'Job Title',
  location = 'Location',
  start_date = 'Start Year',
  end_date = 'Length of Employment (months)',
}

export enum EJobPlaceholders {
  company = 'Ce.x. The Fox Group',
  job_title = 'e.x. Carpenter',
  location = 'Where did you work?',
  start_date = 'What year did you start?',
  end_date = 'How long were you at this job (months)?',
}

export const jobNames = [
  'company_name',
  'job_title',
  'location',
  'start_date',
  'end_date',
  'is_current',
  'description',
]

export const publicWorkExperience = (workExp: AnswerWorkExperience[]) => {
  if (workExp?.length === 0) {
    return [
      {
        job_number: 1,
        questions_work_experience: {
          company_name: '',
          job_title: '',
          location: '',
          start_date: '',
          end_date: '',
          is_current: '',
          description: '',
        },
        color: greenColor,
      },
    ]
  } else {
    const data = workExp?.map((job, index) => {
      const newJob: AnswerWorkExperience = {}

      jobNames.forEach(name => {
        Object.keys(job).forEach(key => {
          name === key && (newJob[name] = job[key])
        })
      })

      return {
        job_number: index + 1,
        questions_work_experience: newJob,
        color: (index + 1) % 2 !== 0 ? greenColor : blueColor,
      }
    })
    return data
  }
}

export const changeFormatDates = (workExp: AnswerWorkExperience[]) => {
  if (workExp?.length === 0) return workExp
  const data = workExp?.map(job => {
    const newJob: AnswerWorkExperience = {}

    jobNames.forEach(name => {
      Object.keys(job).forEach(key => {
        name === key && (newJob[name] = job[key])
      })
    })
    newJob.is_current = newJob.end_date === EDate.present ? 'true' : ''
    const startDate = new Date(newJob.start_date)
    const endDate =
      newJob.end_date === EDate.present ? new Date() : new Date(newJob.end_date)
    const startYears: string = startDate.getFullYear()
      ? startDate.getFullYear().toString()
      : ''
    const monthsDuration =
      newJob.start_date && newJob.end_date
        ? endDate.getMonth() -
          startDate.getMonth() +
          12 * (endDate.getFullYear() - startDate.getFullYear())
        : 0

    newJob.start_date = startYears
    newJob.end_date = monthsDuration.toString()
    return newJob
  })

  return data
}

export const filterEmptyExp = (modalWorkExp: ResultWorkExperience[]) => {
  return modalWorkExp.filter(work => {
    const empty = Object.keys(work.questions_work_experience).every(
      key => work.questions_work_experience[key].length === 0
    )
    if (empty) return false
    else return work
  })
}

const getDateErrorMessage = (
  startDate: string,
  endDate: string,
  isCurrentWork?: boolean
): string | undefined => {
  const start = moment(startDate, 'yyyy-MM-DD', true)
  const end = moment(endDate, 'yyyy-MM-DD', true)
  switch (true) {
    case !start.isValid():
      return `Please enter a start year`
    case !end.isValid():
      return `Please enter a Length of Employment or check “I currently work here”`
    case start.toDate().getFullYear() < 1900:
    case start.toDate() > new Date():
      return 'Please enter a valid start year'
    case end.toDate() > new Date():
    case start.toDate() > end.toDate():
      return 'can not end in the future, please choose a smaller Length of Employment or check “I currently work here”'
  }
}

export const handlingErrors = (modalWorkExp: ResultWorkExperience[]) => {
  const errors: string[] = []
  const fields: string[] = []
  const preparedlWorkExp: ResultWorkExperience[] = filterEmptyExp(
    modalWorkExp
  ).map(el => {
    const startDateFormatted = moment(
      getStartDate(
        el.questions_work_experience.start_date,
        el.questions_work_experience.end_date
      )
    ).format('yyyy-MM-DD')

    const endDateFormatted = moment(
      getEndDate(
        el.questions_work_experience.start_date,
        el.questions_work_experience.end_date
      )
    ).format('yyyy-MM-DD')

    return {
      ...el,
      questions_work_experience: {
        ...el.questions_work_experience,
        start_date:
          startDateFormatted === 'Invalid date' ? '' : startDateFormatted,
        end_date: endDateFormatted === 'Invalid date' ? '' : endDateFormatted,
      },
    }
  })

  preparedlWorkExp.forEach(question => {
    const q_work_exp = question.questions_work_experience
    const dateErrorMessage = getDateErrorMessage(
      q_work_exp.start_date,
      q_work_exp.end_date,
      !!q_work_exp.is_current
    )

    Object.keys(q_work_exp).forEach(input => {
      ;(!q_work_exp[input] || q_work_exp[input]?.length === 0) &&
        !fields.includes(` ${input}`) &&
        !input.includes('is_current') &&
        !input.includes('description') &&
        !input.includes('start_date') &&
        !input.includes('end_date') &&
        fields.push(` ${input}`)
    })
    fields.length &&
      fields.length % 5 !== 0 &&
      errors.push(
        `Please enter a valid ${fields.map(name => {
          return name === ' start_date'
            ? ' start year'
            : name.split('_').join(' ')
        })} in job #${question.job_number}`
      )
    dateErrorMessage &&
      errors.push(
        dateErrorMessage.split(' ')[0] === 'Please'
          ? `${dateErrorMessage} in job #${question.job_number}`
          : `Job #${question.job_number} ${dateErrorMessage}`
      )
  })

  return errors
}

//TODO:Not used. Previously used in profile processing to validate work experience fields
// export const responseExperienceError = (err: any) => {
//   if (err.request.status === 0 || err.request.status >= 500) {
//     toast.error(ERROR_SOMETHING, {
//       toastId: ERROR_SOMETHING,
//     })
//   } else {
//     Object.keys(err.response.data.work_experience[0]).length > 1
//       ? toast.error('Please fill in all the fields correctly')
//       : toast.error(
//           `${Object.keys(err.response.data.work_experience[0])[0]
//             .split('_')
//             .map(word => word[0].toUpperCase() + word.slice(1))
//             .join(' ')} is required`
//         )
//   }
// }
