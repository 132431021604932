export const getFilteredOtherRole = (
  otherRole?: string | null,
  rolesList?: string[]
): string | null => {
  if (!otherRole || !rolesList) return null

  const result = otherRole
    .split(', ')
    .filter(
      role =>
        !rolesList.find(
          existRole => existRole.toLowerCase() === role.toLowerCase()
        )
    )
    .join(', ')

  return result
}

export const getExistingRoles = (
  otherRole: string,
  selectedRoles: string[] | null,
  rolesList?: string[]
): string[] => {
  const existingRoles: string[] = []

  rolesList?.forEach(prepRole => {
    const finedRole = otherRole
      ?.split(', ')
      ?.find(
        role =>
          role.toLowerCase() === prepRole.toLowerCase() &&
          !selectedRoles?.includes(prepRole)
      )

    if (finedRole) {
      existingRoles.push(prepRole)
    }
  })

  return existingRoles
}
