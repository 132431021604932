import { useMemo } from 'react'
import { EmployerInfo } from '../../../../../models'
import { CoWorkerLogo } from '../CoWorkerLogo'
import { splitCoworkersList } from '../scripts'

import './navbarList.scss'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setIsEmployer } from '../../../../../redux'
import { EAdminWorkersSStoreKeys } from '../../../../../utils/constants'

interface IProps extends React.HTMLAttributes<HTMLUListElement> {
  isEmployer: boolean
  isAdminWorkers: boolean
  onClickLogout: () => Promise<void>
  employerInfo?: EmployerInfo
}

export const NavbarList = (props: IProps) => {
  const { isEmployer, isAdminWorkers, employerInfo, onClickLogout, className } =
    props
  const dispatch = useDispatch()
  const coworkers = useMemo(
    () => splitCoworkersList(employerInfo?.co_workers ?? []),
    [employerInfo]
  )

  const isAdminPage = isAdminWorkers && !isEmployer

  return (
    <ul
      className={`navbar-list ${className ? className : ''}`}
      data-testid="navbar-list"
    >
      {isAdminWorkers && (
        <li
          onClick={() => {
            if (isAdminPage) {
              dispatch(setIsEmployer(true))
              sessionStorage.removeItem(EAdminWorkersSStoreKeys.isAdmin)
            } else {
              dispatch(setIsEmployer(false))
              sessionStorage.setItem(
                EAdminWorkersSStoreKeys.isAdmin,
                JSON.stringify('true')
              )
            }
          }}
        >
          <Link className="navbar-list__item-link" to={'/'}>
            {isAdminPage ? 'Customer' : 'WS Admin'}
          </Link>
        </li>
      )}

      {isEmployer && (
        <>
          {employerInfo?.company_info.logo && (
            <li className="navbar-list__item navbar-list__item_company-logo">
              <div className="navbar-list__company-logo-square">
                <img
                  className="navbar-list__company-logo"
                  src={employerInfo?.company_info.logo}
                  alt="company logo"
                />
              </div>
            </li>
          )}

          {employerInfo?.company_info && (
            <li className="navbar-list__item navbar-list__item-employer-company">
              {employerInfo.company_info.name}
              <div className="navbar-list__item-employer-co-workers">
                <CoWorkerLogo info={employerInfo.basic_info} owner />

                {coworkers.preparedList.map((coworker, i) => (
                  <CoWorkerLogo
                    key={coworker.first_name}
                    style={{
                      zIndex: coworkers.preparedList.length - i,
                    }}
                    info={coworker}
                  />
                ))}

                {!!coworkers.remainingElCount && (
                  <div className="navbar-list__logo navbar-list__logo_counter">
                    +{coworkers.remainingElCount}
                  </div>
                )}
              </div>
            </li>
          )}
        </>
      )}

      {isAdminPage && (
        <li>
          <a
            className="navbar-list__item-link"
            href={'https://www.skillit.com/contact'}
            target="_blank"
            rel="noreferrer"
            data-testid="navbar-list__item-link"
          >
            Contact Us
          </a>
        </li>
      )}

      <li onClick={onClickLogout} className="navbar-list__item">
        Sign out
      </li>

      {isAdminPage && (
        <li className="navbar-list__item">
          <CoWorkerLogo
            info={{
              first_name: 'Bill', ///TODO it's will be dynamic
              profile_picture: null,
            }}
            owner
          />
        </li>
      )}
    </ul>
  )
}
