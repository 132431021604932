import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { ILastPageOB } from '../../../../models'
import { OBpageNames } from '../../../../services/route'
import { updatePageStateOB } from '../../../../services/workerApi'

export const getLastPageState = (page: number): ILastPageOB => ({
  last_page: page,
  last_location: `/${OBpageNames[page]}`,
})

export const usePageNumUpdateOB = () => {
  return useMutation((data: ILastPageOB) => updatePageStateOB(data), {
    onError: err => {
      if (err instanceof Error) {
        toast.error(err.message)
      }
    },
  })
}
