import { useHistory } from 'react-router-dom'
import { useCallback, useState, useMemo } from 'react'
import {
  IWorkerInfoOB,
  SkillsInfo,
  SkillsInfoOnlyAdditionalInfo,
} from '../../../models'
import * as route from '../../../services/route'
import { OBAdditionalInfoUI } from './OBAdditionalInfoUI'
import {
  EWorkerSStoreKeys,
  OBAnalyticsName,
  WorkerQueries,
} from '../../../utils/constants'
import { usePageNumUpdateOB, useTypedSelector } from '../../../utils/hooks'
import { setWorkerInfo, useAppDispatch } from '../../../redux'
import { useMutation, useQueryClient } from 'react-query'
import { updateSkillsInfo } from '../../../services/workerApi'
import { toast } from 'react-toastify'
import {
  getUserInfoFromStorage,
  onTrackingActions,
} from '../../../utils/scripts'
import { checkDataChange } from './OBAdditionalInfoFunctions'
import { getLastPageState } from '../../../utils/hooks/obHooks/usePageNumUpdateOB'
import { getYesNoList } from '../../InAppFlow/Worker/ProfileCompletion/handleProfileInputs'

export const OBAdditionalInfo = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const userInfoFromStorage = useMemo(() => getUserInfoFromStorage(), [])
  const queryClient = useQueryClient()
  const pageStateOBMutation = usePageNumUpdateOB()
  const userInfo = useTypedSelector(s => s.obInfo.workerInfo)
  const choiceLists = useTypedSelector(s => s.variables.choiceListForWorker)
  const lastPage = userInfo.last_page < 11 ? 11 : userInfo.last_page

  const lastPageState = useMemo(() => getLastPageState(lastPage), [lastPage])

  const [additionalInfo, setAdditionalInfo] =
    useState<SkillsInfoOnlyAdditionalInfo>({
      training_certifications: userInfoFromStorage?.user_data
        .training_certifications
        ? userInfoFromStorage.user_data.training_certifications
        : userInfo.user_data.training_certifications ?? [],
      unions: userInfoFromStorage?.user_data.unions
        ? userInfoFromStorage?.user_data.unions
        : userInfo.user_data.unions ?? [],
      eligible_work_us: userInfoFromStorage?.user_data.eligible_work_us
        ? userInfoFromStorage?.user_data.eligible_work_us
        : userInfo.user_data.eligible_work_us ?? null,
    })

  const obAdditionalInfoMutation = useMutation(
    (data: SkillsInfoOnlyAdditionalInfo) => updateSkillsInfo(data)
  )

  const getNewData = useCallback(
    (data: IWorkerInfoOB) => ({
      ...data,
      last_page: lastPage,
      last_location: lastPageState.last_location,
      user_data: {
        ...data.user_data,
        training_certifications: additionalInfo.training_certifications,
        unions: additionalInfo.unions,
        eligible_work_us: additionalInfo.eligible_work_us,
      },
    }),
    [lastPage, lastPageState.last_location, additionalInfo]
  )

  const answerList = useMemo(() => {
    return getYesNoList(1, 2, true)
  }, [])

  const saveSessionStorage = useCallback(() => {
    sessionStorage.setItem(
      EWorkerSStoreKeys.obData,
      JSON.stringify(getNewData(userInfoFromStorage ?? userInfo))
    )
  }, [getNewData, userInfoFromStorage, userInfo])

  const onClickNext = useCallback(async () => {
    if (checkDataChange(userInfo, additionalInfo)) {
      await onTrackingActions(OBAnalyticsName.certifications_submitted)
      obAdditionalInfoMutation.mutate(additionalInfo, {
        onSuccess(newData: SkillsInfo) {
          queryClient.setQueryData(WorkerQueries.skillsInfo, newData)
          pageStateOBMutation.mutate(lastPageState, {
            onSuccess() {
              saveSessionStorage()
              dispatch(setWorkerInfo(getNewData(userInfo)))
              history.push(route.OBPath.onboardingTravelToWork)
            },
            onError: err => {
              if (err instanceof Error) toast.error(err.message)
            },
          })
        },
        onError: err => {
          if (err instanceof Error) toast.error(err.message)
        },
      })
    } else {
      saveSessionStorage()
      history.push(route.OBPath.onboardingTravelToWork)
    }
  }, [
    additionalInfo,
    dispatch,
    history,
    saveSessionStorage,
    obAdditionalInfoMutation,
    pageStateOBMutation,
    queryClient,
    lastPageState,
    getNewData,
    userInfo,
  ])

  const onClickBack = useCallback(() => {
    saveSessionStorage()
    history.push(route.OBPath.onboardingLanguages)
  }, [history, saveSessionStorage])

  return (
    <OBAdditionalInfoUI
      onClickNext={onClickNext}
      onClickBack={onClickBack}
      choiceLists={choiceLists}
      additionalInfo={additionalInfo}
      setAdditionalInfo={setAdditionalInfo}
      isWaitingResponse={
        obAdditionalInfoMutation.isIdle
          ? false
          : !obAdditionalInfoMutation.error
      }
      answerList={answerList}
    />
  )
}
