import { useTypedSelector } from '../../../utils/hooks'
import './backBlur.scss'

interface IProps {
  isActive: boolean
  setIsActive?: () => void
}

export const BackBlur = (props: IProps) => {
  const { isActive, setIsActive } = props
  const additionalTopMargin = useTypedSelector(
    state => state.variables.topFilterHeight
  )
  const navbarHeight = useTypedSelector(state => state.variables.navbarHeight)
  return (
    <div
      style={{ top: `${navbarHeight + additionalTopMargin}px` }}
      className={`${isActive ? 'back-blur_focused' : ''} back-blur`}
      onClick={setIsActive}
      data-testid="back-blur"
    ></div>
  )
}
