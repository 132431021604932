import React from 'react'
import './simpleCard.scss'
import zipcodes from 'zipcodes'

interface Props extends React.HTMLAttributes<HTMLElement> {
  photo: string | null
  name: string
  full_role: string
  zipcode: string
}

export const SimpleCard = ({
  className,
  photo,
  name,
  full_role,
  zipcode,
}: Props) => {
  return (
    <div className={`simple-card ${className ?? ''}`} data-testid="simple-card">
      <div className="simple-card__wrapper">
        {photo ? (
          <img className="simple-card__photo" src={photo} alt="user" />
        ) : (
          <div className="simple-card__default-photo">{name[0]}</div>
        )}

        <div className="simple-card__info">
          <p className="simple-card__info-name">{name}</p>
          <p className="simple-card__info-title">{full_role}</p>
          <p className="simple-card__info-zipcode">
            {zipcodes.lookup(zipcode)?.city
              ? zipcodes.lookup(zipcode)?.city + ', '
              : ''}
            {zipcodes.lookup(zipcode)?.state}
          </p>
        </div>
      </div>
    </div>
  )
}
