import React from 'react'
import { DrivingInfo } from '../../../../../../../models'
import { getYesNoList } from '../../../handleProfileInputs'
import { DrivingModalUI } from './DrivingModalUI'

interface Props {
  handleSubmit: () => void
  drivingInfoForm: DrivingInfo
  setDrivingInfoForm: React.Dispatch<React.SetStateAction<DrivingInfo>>
  isConfirmationModalVisible: boolean
  setIsConfirmationModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  drivingInfoState: DrivingInfo
  isDrivingInfoChanged: boolean
}

export const DrivingModal = (props: Props) => {
  const vehicleData = getYesNoList(1, 2)
  const licenseData = getYesNoList(3, 4)

  return (
    <DrivingModalUI
      {...props}
      vehicleData={vehicleData}
      licenseData={licenseData}
    />
  )
}
