import React from 'react'
import { TWorkExperience } from '../../../../../../models'
import { ModalBox } from '../../../../../molecules'
import { AddJobForm } from '../AddJobForm'
import { TEditableJob } from '../BestJobsOB'
import { IBestJobProps } from './BestJob'
import removeLastJobIcon from '../../../../../../assets/icons/close/remove_last_jobOB.svg'
import { useTranslation } from 'react-i18next'
import './bestJob.scss'
import { Button } from '../../../../../atoms'
import {
  TCombinedFormType,
  TEditIncompleteExp,
} from '../../../../../../pages/Onboarding/OBLastJobs/OBLastJobs'

interface IBestJobUIProps extends IBestJobProps {
  jobs: TWorkExperience[]
  onRemove: (e: React.MouseEvent<HTMLButtonElement>, id: number) => void
  duration: string
  isFormOpen: false | 'add' | number
  setIsFormOpen: React.Dispatch<React.SetStateAction<false | 'add' | number>>
  onAdd: () => void
  onEdit: (index: TEditableJob) => void
  isRemovedPromptOpen: boolean
  setIsRemovedPromptOpen: React.Dispatch<React.SetStateAction<boolean>>
  isPromptOpen: boolean
  setIsPromptOpen: React.Dispatch<React.SetStateAction<boolean>>
  onCloseModal: (value: boolean, setIsFormOpen: TCombinedFormType) => void
  isClickCloseBtn: boolean
  setIsClickCloseBtn: React.Dispatch<React.SetStateAction<boolean>>
  setJobEditModal: React.Dispatch<
    React.SetStateAction<false | TEditIncompleteExp>
  >
}

export const BestJobUI = (props: IBestJobUIProps) => {
  const {
    isFormOpen,
    setIsFormOpen,
    index,
    duration,
    onRemove,
    jobs,
    onAdd,
    onEdit,
    isRemovedPromptOpen,
    setIsRemovedPromptOpen,
    setIsClickCloseBtn,
  } = props
  const { t } = useTranslation()

  return (
    <div className="best-job" data-testid="best-job">
      {isFormOpen === index && (
        <ModalBox
          className="three-best-jobs__form"
          onCloseModal={() => {
            setIsClickCloseBtn(true)
          }}
          visibleCloseIcon
          bgClickDisabled
        >
          <AddJobForm
            {...props}
            isFormOpen={isFormOpen}
            setIsFormOpen={setIsFormOpen}
          />
        </ModalBox>
      )}
      {isRemovedPromptOpen && (
        <ModalBox
          className="modal-box_external-close-Icon"
          onCloseModal={() => setIsRemovedPromptOpen(false)}
          visibleCloseIcon
        >
          <div className="best-job__prompt">
            <p className="best-job__prompt-question">
              {t('last_jobs.Are you sure you want to delete this job?')}
            </p>
            <div className="best-job__prompt-choice">
              <Button
                className="best-job__prompt-btn best-job__prompt-btn_no"
                onClick={() => setIsRemovedPromptOpen(false)}
              >
                {t('last_jobs.No')}
              </Button>
              <Button
                className="best-job__prompt-btn best-job__prompt-btn_yes"
                onClick={e => onRemove(e, index)}
              >
                {t('last_jobs.Yes')}
              </Button>
            </div>
          </div>
        </ModalBox>
      )}
      {jobs[index] ? (
        <div
          className="best-job__filled"
          onClick={() => onEdit(index)}
          data-testid="best-job__filled"
        >
          <h4 className="best-job__title">
            {`${jobs[index].job_title ?? 'none provided'} at ${
              jobs[index].company_name ?? 'none provided'
            }`}
          </h4>

          <span className="best-job__duration">{duration}</span>
          {jobs[index].location && (
            <>
              <span className="best-job__duration best-job__duration-separator">
                |
              </span>
              <span className="best-job__duration">{jobs[index].location}</span>
            </>
          )}
          <p className="best-job__description">
            {jobs[index].description ?? 'none provided'}
          </p>
          <button
            className="best-job__remove-btn"
            onClick={e => {
              e.stopPropagation()
              setIsRemovedPromptOpen(true)
            }}
          >
            <img src={removeLastJobIcon} alt="remove" />
          </button>
        </div>
      ) : (
        <div onClick={onAdd} className="best-job__empty">
          {t('last_jobs.Click to add a job')}
        </div>
      )}
    </div>
  )
}
