import React from 'react'
import { CheckboxSelect, DoubleCalendar, IDatePeriod } from '../../../atoms'
import './workerFilters.scss'
import {
  IConvertedFilterList,
  IFilterDate,
} from '../../../../models/AdminWorkes'
import { fieldsName } from './WorkerFilters'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  convertedFilterList: IConvertedFilterList
  checkedFilter: (listName: string, key: string, isChecked: boolean) => void
  filterIsOpen: Record<string, boolean>
  filtersTogler: (listName: string, isOpen: boolean) => void
  period: IDatePeriod
  setPeriod: React.Dispatch<React.SetStateAction<IDatePeriod>>
  handlerQueryParamDates: (period: IFilterDate) => void
  filterRef: React.RefObject<HTMLDivElement>
}

export const WorkerFiltersUI = (props: Props) => {
  const {
    convertedFilterList,
    checkedFilter,
    period,
    setPeriod,
    handlerQueryParamDates,
    filterIsOpen,
    filtersTogler,
    filterRef,
  } = props

  return (
    <div ref={filterRef} className="worker-filter" data-testid="worker-filter">
      <div id="worker-filters" className="worker-filter__filters">
        <p className="worker-filter__filters-title">Filters:</p>

        <CheckboxSelect
          className="worker-filter__filters-select"
          listName="Region"
          list={convertedFilterList.locations}
          checkboxHandler={(key, isChecked) => {
            checkedFilter(`${fieldsName.LOCATIONS}`, key, isChecked)
          }}
          filterName={fieldsName.LOCATIONS}
          filterIsOpen={filterIsOpen}
          filterOpeningTogler={(filterName, isOpen) => {
            filtersTogler(filterName, isOpen)
          }}
        />
        <CheckboxSelect
          className="worker-filter__filters-select"
          listName="Role"
          list={convertedFilterList.roles}
          checkboxHandler={(key, isChecked) => {
            checkedFilter(`${fieldsName.ROLES}`, key, isChecked)
          }}
          filterName={fieldsName.ROLES}
          filterIsOpen={filterIsOpen}
          filterOpeningTogler={(filterName, isOpen) => {
            filtersTogler(filterName, isOpen)
          }}
        />
        <CheckboxSelect
          className="worker-filter__filters-select"
          listName="Status"
          list={convertedFilterList.status}
          checkboxHandler={(key, isChecked) => {
            checkedFilter(`${fieldsName.STATUS}`, key, isChecked)
          }}
          filterName={fieldsName.STATUS}
          filterIsOpen={filterIsOpen}
          filterOpeningTogler={(filterName, isOpen) => {
            filtersTogler(filterName, isOpen)
          }}
        />
        <DoubleCalendar
          period={period}
          setPeriod={setPeriod}
          handleDatePeriodChanges={handlerQueryParamDates}
          filterName={fieldsName.DATE}
          filterIsOpen={filterIsOpen}
          filterOpeningTogler={(filterName, isOpen) => {
            filtersTogler(filterName, isOpen)
          }}
        />
      </div>
    </div>
  )
}
