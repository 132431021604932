import React from 'react'
import { Button } from '../../../atoms'
import './dashboardAssessmentModal.scss'
import { AssessmentForms } from '../../../../models'
import { useHistory } from 'react-router-dom'
import * as route from '../../../../services/route'

interface ModalProps {
  availableForms: AssessmentForms[]
}

export const DashboardAssessmentModal = (props: ModalProps) => {
  return (
    <div className="dashboard-assessment-modal">
      <h1 className="dashboard-assessment-modal__title">Choose Assessment</h1>
      {props.availableForms?.map(availableForm => {
        return (
          <AssessmentOption
            key={availableForm.key}
            title={availableForm.name}
            buttonText={`Take ${availableForm.name}`}
            link={`${route.assessment}/${availableForm.key}`}
            description={availableForm.description}
          />
        )
      })}
    </div>
  )
}

interface Props {
  title: string | null
  buttonText: string | null
  link: string
  description: string | null
}

const AssessmentOption = (props: Props) => {
  const { title, buttonText, link, description } = props
  const history = useHistory()

  return (
    <div className="assessment-option">
      <div className="assessment-option__wrapper">
        <h2 className="assessment-option__title">{title}</h2>
        <p className="assessment-option__text">{description}</p>
        <Button
          className="assessment-option__button"
          onClick={() => history.push(link)}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  )
}
