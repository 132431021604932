import React from 'react'
import { useHistory } from 'react-router'
import { TemplateJob } from '../../../../pages/InAppFlow/Worker/JobsPage'
import { Button } from '../../../atoms'
import { v4 as uidv4 } from 'uuid'

import './jobCardComponent.scss'
interface Props {
  templateJob: TemplateJob
  onClickLearnMore: () => void
}

export const JobCardComponent = (props: Props) => {
  const history = useHistory()
  const onClickQualify = () => {
    history.push(`take-challenge/${props.templateJob.job.key}`)
  }
  return (
    <div className="job-card">
      <div className="job-card__logo"></div>
      <div className="job-card__content">
        <div className="job-card__title">
          <span className="job-card__title-position">
            {props.templateJob.position}
          </span>
          &nbsp;at&nbsp;
          <span className="job-card__title-company">
            {props.templateJob.company}
          </span>
        </div>
        <ul className="job-card__sub-title">
          {props.templateJob.requiredQualify.map(el => (
            <li key={uidv4()} className="job-card__sub-title-item">
              {el}
            </li>
          ))}
        </ul>
        <p className="job-card__description">
          Messer is a company of builders and champions of craftsmanship
          specializing in managing, developing and performing complex commercial
          construction in the communities in which they live and work.
        </p>
        <ul className="job-card__requirements">
          <li className="job-card__requirements-item">Nashville, TN</li>
          <li className="job-card__requirements-item">
            Must have or be willing to obtain OSHA-30
          </li>
          <li className="job-card__requirements-item">
            Skills Premium for Safety Knowledge • Building Knowledge • Plan
            Reading • Construction Math
          </li>
        </ul>
      </div>
      <div className="job-card__links">
        <Button className="job-card__button" onClick={onClickQualify}>
          Qualify now
        </Button>
        <p
          onClick={props.onClickLearnMore}
          className="job-card__learn-more-link"
        >
          Learn More
        </p>
      </div>
    </div>
  )
}
