import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from '../../../components/atoms'
import { ModalBox, NavigationFooter } from '../../../components/molecules'
import {
  FormAnswers,
  FormAnswersList,
  Forms,
  MatchingAnswers,
  Page,
  ValuesForm,
  Option,
} from '../../../models/PreInterviewForms'
import {
  getFormsList,
  getFormsToken,
  sendFirstBlockNotefication,
} from '../../../services/workerApi'
import { FormContent } from './FormContent'
import './formsContainer.scss'
import * as route from '../../../services/route'
import { LoadingContent } from '../../../components/molecules/General/LoadingContent'
import { useHistory } from 'react-router-dom'
import { onboardingQualification } from '../../../redux/qualify-reducer'
import { useAppDispatch } from '../../../redux/store'
import { unwrapResult } from '@reduxjs/toolkit'
import { PassedForms } from '../PassedForms'
import { NotPassedForms } from '../NotPassedForms'
import { validationInput } from './formsFunc'
import { toast } from 'react-toastify'
import {
  sumLengthQuest,
  pageOptionsAndAnswers,
  answersFilter,
} from './formsFunc'
import { inputFormatting, formatPhoneNumber } from '../../../utils/scripts'
import { ERROR_SOMETHING } from '../../../services/auth-service'
import { syncUser } from '../../../redux/auth-reducer'
import { Helmet } from 'react-helmet'

export enum TypeQ {
  radio = 'radio_button',
  checkbox = 'checkbox',
  input = 'input',
  select = 'select',
  buttonsGrid = 'buttons_grid',
  singleButtonsGrid = 'single_choice_buttons_grid',
  filterButtons = 'trade',
  selectAndButtonsGrid = 'select_with_dropdown',
}

export const ANSWERS = 'answers'
export const PHONE = 'phone'
export const EMAIL = 'email'
export const F_NAME = 'first_name'
export const L_NAME = 'last_name'
export const ZIP_CODE = 'zip_code'
export const FORM_TOKEN = 'form_token'

export const FormsContainer = () => {
  const localToken = localStorage.getItem(FORM_TOKEN)
  const [formsData, setFormsData] = useState<Forms | null>()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const answersFromLocalStore = localStorage.getItem(ANSWERS)
  const [countPage, setCountPage] = useState(1)
  const [isLoading, setIsloading] = useState(false)
  const [answersList, setAnswersList] = useState<FormAnswers>({
    id: formsData?.id,
    survey_token: formsData?.survey_token,
    answers_list: answersFromLocalStore
      ? JSON.parse(answersFromLocalStore).answers_list
      : [],
  })

  const [isQualify, setIsQualify] = useState<boolean | undefined>(undefined)
  const pageOptionsId = useMemo(
    () =>
      formsData?.page
        .find(page => page.number === countPage)
        ?.content.map(question => question.options.map(option => option.id))
        .flat() ?? [],
    [countPage, formsData]
  )
  const [values, setValues] = useState<ValuesForm>({
    currOptionsId: pageOptionsId,
  })
  const [matchingAnswers, setMatchingAnswers] = useState<MatchingAnswers>({
    optionsCounter: 0,
    answersCounter: 0,
  })
  const [filterOptions, setFilterOptions] = useState<string[]>()
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const errToken = 'Incorrect token'

  const addCheckpoint = useCallback(
    (blockId, lengthData) => {
      formsData?.page.map(pageEl => {
        if (formsData?.page.indexOf(pageEl) === formsData?.page?.length - 1) {
          if (lengthData || lengthData === 0) pageEl.blockLength = lengthData
          pageEl.blockId = blockId
          return (pageEl.checkpoint = true)
        }
        return pageEl
      })
    },
    [formsData?.page]
  )
  const optionsName = formsData?.page[countPage - 1]?.content?.map(question =>
    question?.options?.map(option => {
      if (question.type === 'input' && option.name === null) return TypeQ.input
      return option?.name
    })
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [countPage])

  useEffect(() => {
    setValues(prevValues => ({
      ...prevValues,
      currOptionsId: pageOptionsId,
    }))
  }, [pageOptionsId])

  useEffect(() => {
    if (localToken) {
      getFormsList(localToken)
        .then(res => {
          setAnswersList(prev => ({
            ...prev,
            survey_token: res.survey_token,
            id: res.id,
          }))
          setFormsData(res)
        })
        .catch(error => {
          if (error.message === errToken) {
            setIsloading(false)
            setIsModalVisible(true)
            localStorage.removeItem(FORM_TOKEN)
          }
        })
    } else {
      getFormsToken().then(resToken => {
        localStorage.setItem(FORM_TOKEN, resToken.survey_token)
        getFormsList(resToken.survey_token).then(res => {
          setFormsData(res)
        })
      })
    }
  }, [localToken])

  useEffect(() => {
    formsData &&
      pageOptionsAndAnswers(
        formsData.page[countPage - 1],
        answersList,
        setMatchingAnswers,
        filterOptions
      )
  }, [formsData, answersList, countPage, filterOptions])

  useEffect(() => {
    if (formsData) {
      const optionalOptions: Option[] = []
      const arrWithOptionalQuestions: FormAnswersList[] = []

      formsData.page.forEach(page => {
        page.content.forEach(question => {
          question.options.forEach(option => {
            if (option.is_optional) {
              optionalOptions.push({
                id: option.id,
                name: option.name,
                is_optional: option.is_optional,
                answers: [],
              })

              const existInOptionalArray = arrWithOptionalQuestions.some(
                ques => ques.id === question.id
              )

              if (!existInOptionalArray) {
                arrWithOptionalQuestions.push({
                  id: question.id,
                  type: question.type,
                  options: [],
                })
              }

              const elemToPush = optionalOptions.find(
                optionalOpt => option.id === optionalOpt.id
              )
              if (elemToPush) {
                arrWithOptionalQuestions.forEach(arrOpt => {
                  if (question.id === arrOpt.id) {
                    arrOpt.options.push(elemToPush)
                  }
                })
              }
            }
          })
        })
      })

      const optionalArrInAnswerList = answersList.answers_list.some(
        ques => ques.id === arrWithOptionalQuestions[0]?.id
      )
      if (!optionalArrInAnswerList) {
        answersList.answers_list.push(...arrWithOptionalQuestions)
      }
    }
  }, [formsData, answersFromLocalStore, answersList.answers_list])

  useEffect(() => {
    const currentPage = formsData?.page.find(
      page => page.number === countPage - 1
    )
    currentPage?.content.forEach(question => {
      if (
        question.options.find(option => option.name === TypeQ.filterButtons)
      ) {
        answersList?.answers_list?.forEach(savedQuestion =>
          savedQuestion.options.forEach(option => {
            if (option.name === TypeQ.filterButtons) {
              const answerValue = option.answers.map(answer => answer.value)
              setFilterOptions(answerValue)
            }
          })
        )
      } else {
        setFilterOptions(undefined)
      }
    })
  }, [answersList.answers_list, countPage, formsData?.page])

  useEffect(() => {
    if (formsData && countPage === 1) {
      addCheckpoint(formsData?.id, sumLengthQuest(formsData.page))
    }
  }, [addCheckpoint, formsData, countPage])

  useEffect(() => {
    if (formsData?.page[countPage - 1]?.blockId) {
      setAnswersList(prev => ({
        ...prev,
        id: formsData?.page[countPage - 1].blockId,
      }))
    }
  }, [countPage, formsData, isQualify])

  const saveAnswers = (answers: FormAnswers) => {
    localStorage.setItem(ANSWERS, JSON.stringify(answers))
  }

  const checkpointCheck = () => {
    const errOnPage = optionsName
      ?.flat()
      .find(optName => validationInput(values)?.hasOwnProperty(optName))
    if (!errOnPage) {
      const email = answersList.answers_list
        .find(question =>
          question.options.find(option => option.name === EMAIL)
        )
        ?.options.find(option => option.name === EMAIL)?.answers[0].value

      if (isQualify === undefined) {
        setIsloading(true)
        const answersLastBlock = {
          id: answersList?.id,
          survey_token: answersList?.survey_token,
          answers_list: answersList.answers_list.slice(
            answersList.answers_list.length -
              formsData?.page?.[countPage - 1]?.blockLength!,
            answersList.answers_list.length
          ),
        }

        return dispatch(onboardingQualification(answersLastBlock))
          .then(unwrapResult)
          .then(res => {
            if (res?.data?.page) {
              const found = res.data.page.find((newPage: Page) =>
                formsData?.page.find(
                  oldPage => oldPage.number === newPage.number
                )
              )
              if (!found) {
                res.data.page.forEach((page: Page) => {
                  const newPages = formsData
                  newPages?.page.push(page)
                  setFormsData(newPages)
                })
              }
              setCountPage(prev => prev + 1)
              addCheckpoint(res.data.id, sumLengthQuest(res.data.page))
              localToken && sendFirstBlockNotefication(localToken)
              setIsloading(false)
            } else {
              if (res?.data?.if_qualified) {
                setIsloading(false)
                setIsQualify(true)
              } else {
                setIsloading(false)
                setIsQualify(false)
              }
            }
          })
          .catch(err => {
            setIsloading(false)
            toast.error(err.message, { toastId: ERROR_SOMETHING })
          })
      } else {
        dispatch(syncUser())
        if (isQualify) {
          history.push(`${route.preInterviewOnboardingFormat}/${email}`)
        } else {
          history.push(`${route.signUp}/${email}?pre-interview="true"`)
          localStorage.removeItem(FORM_TOKEN)
          localStorage.removeItem(ANSWERS)
        }
      }
    } else {
      handlingValidation()
    }
  }

  const actionBack = () => {
    setIsQualify(undefined)
    isQualify === undefined && countPage > 1 && setCountPage(prev => prev - 1)
    countPage === 1 &&
      isQualify === undefined &&
      history.push(route.preInterviewOnboarding)
    const buttonBack: boolean = true
    if (filterOptions && filterOptions?.length > 0) {
      answersFilter(filterOptions, setAnswersList, saveAnswers, buttonBack)
    }
  }

  const actionNext = () => {
    const errOnPage = optionsName
      ?.flat()
      .find(optName => validationInput(values)?.hasOwnProperty(optName))
    if (!errOnPage) {
      formsData?.page?.length &&
        countPage < formsData?.page?.length &&
        countPage < formsData?.total_pages &&
        setCountPage(prev => prev + 1)

      if (filterOptions && filterOptions?.length > 0) {
        answersFilter(filterOptions, setAnswersList, saveAnswers)
      } else {
        saveAnswers(answersList)
      }
    } else {
      handlingValidation()
    }
  }

  const errors = validationInput(values, countPage)

  const handlingValidation = () => {
    if (errors) {
      let err: keyof typeof errors
      for (err in errors) {
        if (optionsName?.flat().includes(err)) {
          if (err === TypeQ.input) {
            const findInputIndex =
              errors[err]?.findIndex(inputError =>
                pageOptionsId.includes(inputError.optionId)
              ) ?? -1
            findInputIndex + 1 &&
              toast.error(errors[err]?.[findInputIndex].value, {
                toastId: err,
              })
          } else {
            toast.error(errors[err], {
              toastId: err,
            })
          }
        }
      }
    }
  }

  const handlingInputsValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist()
    if (event.target.name !== TypeQ.input) {
      setValues(values => ({
        ...values,
        currOptionsId: pageOptionsId,
        [event.target.name]:
          event.target.name === PHONE
            ? formatPhoneNumber(event.target.value)
            : event.target.name === ZIP_CODE
            ? inputFormatting(event.target.value, 5)?.match(/\d/g)?.join('') ??
              ''
            : event.target.value,
      }))
    } else {
      setValues(prevValues => {
        const newInputObj = {
          optionId: +event.target.id,
          value: event.target.value,
        }
        const findInputIndex =
          prevValues.input?.findIndex(
            input => input.optionId === +event.target.id
          ) ?? -1
        if (prevValues.input) {
          const newInputsArr = [...prevValues.input]
          if (findInputIndex + 1) {
            newInputsArr[findInputIndex] = newInputObj
            return {
              ...prevValues,
              currOptionsId: pageOptionsId,
              input: newInputsArr,
            }
          } else {
            newInputsArr.push(newInputObj)
            return {
              ...prevValues,
              currOptionsId: pageOptionsId,
              input: newInputsArr,
            }
          }
        }
        return {
          ...prevValues,
          currOptionsId: pageOptionsId,
          input: [newInputObj],
        }
      })
    }
  }

  const closeModal = () => {
    history.push(route.preInterviewOnboarding)
  }

  useEffect(() => {
    history.push({
      search: `?${
        isQualify === undefined
          ? countPage
          : isQualify
          ? 'Onboarding Form Passed'
          : 'Onboarding Form Not Passed'
      }`,
    })
  }, [countPage, isQualify, history])

  return (
    <div className="forms-container preinterview-container">
      <Helmet>
        <title>
          {isQualify === undefined
            ? `Skillit - Onboarding Page ${countPage.toString()}`
            : isQualify
            ? 'Skillit - Onboarding Form Passed '
            : 'Skillit - Onboarding Form Not Passed'}
        </title>
      </Helmet>

      {isModalVisible && (
        <ModalBox onCloseModal={() => closeModal()}>
          <p>Something went wrong. Please try to fill out the form again</p>
          <Button
            className="forms-container__modal-button"
            onClick={closeModal}
          >
            Start again
          </Button>
        </ModalBox>
      )}

      {isQualify === undefined && (
        <div className="forms-container__header">
          <h2>{formsData?.page[countPage - 1]?.title}</h2>
          <span>
            {countPage}/{formsData?.total_pages}
          </span>
        </div>
      )}

      <LoadingContent
        className="forms-container__loading-content"
        isLoading={isLoading}
      >
        {isQualify === undefined ? (
          <FormContent
            page={formsData?.page[countPage - 1]}
            setAnswersList={setAnswersList}
            answersList={answersList}
            filterOptions={filterOptions}
            handlingInputsValue={handlingInputsValue}
            inputsValue={values}
            setValues={setValues}
          />
        ) : isQualify ? (
          <PassedForms />
        ) : (
          <NotPassedForms />
        )}
      </LoadingContent>

      <NavigationFooter
        actionBack={actionBack}
        className="forms-container__nav-footer"
      >
        <Button
          onClick={() => {
            !formsData?.page[countPage - 1]?.checkpoint && actionNext()
            formsData?.page[countPage - 1]?.checkpoint && checkpointCheck()
          }}
          className="forms-container__nav-button"
          disabled={
            matchingAnswers.answersCounter !== matchingAnswers.optionsCounter
          }
        >
          Next
        </Button>
      </NavigationFooter>
    </div>
  )
}
