import React, { useCallback } from 'react'
import './App.scss'
import './global.scss'
import * as Sentry from '@sentry/react'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import { MediaQueryContextProvider } from './utils/hooks'
import { AppRouter } from './AppRouter/AppRouter'
import './utils/extensions/Array'
import './utils/extensions/Set'
import { ErrorBoundary } from './components/ErrorBoundary'
import { ToastContainer } from 'react-toastify'
import { QueryClient, QueryClientProvider } from 'react-query'
import './i18n'

declare global {
  interface Window {
    Intercom: any
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})
const App = () => {
  const fallback = useCallback(
    ({ error, componentStack, resetError }) => (
      <React.Fragment>
        <div>You have encountered an error</div>
        <div>{error.toString()}</div>
        <div>{componentStack}</div>
        <button onClick={() => resetError()}>Click here to reset!</button>
      </React.Fragment>
    ),
    []
  )

  return (
    <Sentry.ErrorBoundary fallback={fallback}>
      <QueryClientProvider client={queryClient}>
        <MediaQueryContextProvider>
          <ToastContainer
            limit={3}
            newestOnTop={true}
            autoClose={4000}
            theme="colored"
          />
          <ErrorBoundary>
            <Provider store={store}>
              <AppRouter />
            </Provider>
          </ErrorBoundary>
        </MediaQueryContextProvider>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  )
}

export default App
