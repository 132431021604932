import { useCallback, useEffect, useState } from 'react'
import { FeedbackPageUI } from './FeedbackPageUI'
import { useLocation, useParams } from 'react-router-dom'
import { IFeedback } from '../../../../models'
import { useMutation } from 'react-query'
import { shareFeedback } from '../../../../services/employerAPI'
import { toast } from 'react-toastify'
import { ELandingPagesSStoreKeys } from '../../../../utils/constants'

export const FeedbackPage = () => {
  const location = useLocation()
  const maxLength = 400
  const { action_uuid } = useParams<{ action_uuid: string }>()
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get('token')
  const isInterested = searchParams.get('interested') === 'true'
  const isFeedbackSent = sessionStorage.getItem(
    ELandingPagesSStoreKeys.isFeedbackSent
  )
  const isFeedbackNotesSent = sessionStorage.getItem(
    ELandingPagesSStoreKeys.isFeedbackNotesSent
  )

  const [notes, setNotes] = useState('')

  const { mutate, isLoading } = useMutation(
    (data: { token: string; feedback: IFeedback }) =>
      shareFeedback(data.token, data.feedback)
  )

  const onClickBtn = useCallback(() => {
    if (!token) {
      toast.error('Something went wrong, check the link')
      return
    }

    const feedbackWithNotes: IFeedback = {
      action: action_uuid,
      interested: isInterested,
      feedback: notes,
    }

    mutate(
      { token, feedback: feedbackWithNotes },
      {
        onSuccess: () => {
          sessionStorage.setItem(
            ELandingPagesSStoreKeys.isFeedbackNotesSent,
            JSON.stringify(true)
          )
        },
      }
    )
  }, [action_uuid, mutate, isInterested, notes, token])

  useEffect(() => {
    const feedback: IFeedback = {
      action: action_uuid,
      interested: isInterested,
    }

    if (token && !isFeedbackSent) {
      mutate(
        { token, feedback },
        {
          onSuccess: () => {
            sessionStorage.setItem(
              ELandingPagesSStoreKeys.isFeedbackSent,
              JSON.stringify(true)
            )
          },
        }
      )
    }
  }, [action_uuid, isFeedbackSent, isInterested, mutate, token])

  return (
    <FeedbackPageUI
      maxLength={maxLength}
      notes={notes}
      setNotes={setNotes}
      isSuccess={!!isFeedbackNotesSent}
      isLoading={isLoading}
      onClickBtn={onClickBtn}
    />
  )
}
