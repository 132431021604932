import checkedIcon from '../../../../../assets/icons/checkmarks/yellow_circle_checkmark.svg'
import { v4 as uuidv4 } from 'uuid'
import './workerCertificationsBlock.scss'
import { ICertificationGroup } from '../../../../../models'

interface Props {
  certificationList: ICertificationGroup[]
}

export const WorkerCertificationsBlockUI = (props: Props) => {
  const { certificationList } = props

  return (
    <div className="certifications">
      <div className="worker-detail-section-header">
        <h4>Certifications</h4>
      </div>

      <div className={`certifications__lists`}>
        {certificationList.map(certificate => {
          return (
            <div className="certifications__list" key={certificate.group}>
              <div className="certifications__list-title">
                {certificate.group}
              </div>
              {certificate.certifications?.map(skill => (
                <div className="certifications__list-item" key={uuidv4()}>
                  <img
                    className="certifications__list-item-arrow-checked"
                    src={checkedIcon}
                    alt="is-checked-icon"
                  />
                  <p className="certifications__list-item-text">{skill}</p>
                </div>
              ))}
            </div>
          )
        })}
      </div>
    </div>
  )
}
