import React from 'react'
import { useHistory } from 'react-router-dom'
import { AssessmentForms } from '../../../../../../models'
import { Button } from '../../../../../atoms'
import * as route from '../../../../../../services/route'

import './profileAssessmentModal.scss'

interface Props {
  skillitForms: AssessmentForms[]
}

export const ProfileAssessmentModal = (props: Props) => {
  const history = useHistory()

  return (
    <div className="assessment-modal">
      <p className="assessment-modal__title">
        Take the Skillit Assessment that Fits You
      </p>
      <div className="assessment-modal__option">
        <p className="assessment-modal__option-title">
          Take this assessment to determine what aspects of construction work
          your are proficient at and which need some improvement.
        </p>
        {props.skillitForms.map(skillitForm => {
          return (
            <Button
              key={skillitForm.key}
              className="profile_white-btn"
              onClick={() => {
                history.push(`${route.assessment}/${skillitForm.key}`)
              }}
            >
              {`Take ${skillitForm.name}`}
            </Button>
          )
        })}
      </div>
    </div>
  )
}
