/* eslint-disable no-extend-native */

export {}

declare global {
  interface Array<T> {
    indexesOfElements(elements: T[]): number[]
    elementsByIndex(indexes: number[]): T[]
    asSet(): Set<T>
    indexBy(predicate: (element: T) => boolean): number | undefined
  }
}

Array.prototype.indexBy = function <T>(
  this: T[],
  predicate: (element: T) => boolean
): number | undefined {
  let resIndex: number | undefined
  this.forEach((el, index) => {
    const isValid = predicate(el)
    if (isValid) {
      resIndex = index
      return
    }
  })
  return resIndex
}

Array.prototype.indexesOfElements = function <T>(
  this: T[],
  elements: T[]
): number[] {
  let result: number[] = []
  elements.forEach((_el, index) => {
    if (this.includes(_el)) {
      result.push(index)
    }
  })
  return result
}

Array.prototype.asSet = function <T>(this: T[]): Set<T> {
  return new Set(this)
}

Array.prototype.elementsByIndex = function <T>(
  this: T[],
  indexes: number[]
): T[] {
  return indexes.map(i => this[i])
}
