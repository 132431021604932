export const getZoomLevel = (distance: number) => {
  if (distance <= 2) {
    return 13 - distance * 0.06
  } else if (distance <= 8) {
    return 13 - distance * 0.25
  } else {
    switch (distance) {
      case 9:
      case 10:
      case 11:
        return 13 - distance * 0.26
      case 12:
        return 13 - distance * 0.25
      case 13:
        return 13 - distance * 0.24
      case 14:
        return 13 - distance * 0.23
      case 15:
        return 13 - distance * 0.217
      case 16:
        return 13 - distance * 0.21
      case 17:
        return 13 - distance * 0.2
      case 18:
        return 13 - distance * 0.195
      case 19:
        return 13 - distance * 0.189
      case 20:
        return 13 - distance * 0.185
      case 21:
        return 13 - distance * 0.183
      case 22:
        return 13 - distance * 0.176
      case 23:
        return 13 - distance * 0.17
      case 24:
        return 13 - distance * 0.166
      case 25:
        return 13 - distance * 0.163
      case 26:
        return 13 - distance * 0.159
      case 27:
        return 13 - distance * 0.155
      case 28:
        return 13 - distance * 0.152
      case 29:
        return 13 - distance * 0.148
      case 30:
        return 13 - distance * 0.145
      case 31:
        return 13 - distance * 0.141
      case 32:
        return 13 - distance * 0.137
      case 33:
        return 13 - distance * 0.134
      case 34:
        return 13 - distance * 0.132
      case 35:
        return 13 - distance * 0.13
      case 36:
        return 13 - distance * 0.127
      case 37:
        return 13 - distance * 0.124
      case 38:
        return 13 - distance * 0.121
      case 39:
        return 13 - distance * 0.118
      case 40:
        return 13 - distance * 0.116
      case 41:
        return 13 - distance * 0.115
      case 42:
        return 13 - distance * 0.113
      case 43:
        return 13 - distance * 0.111
      case 44:
        return 13 - distance * 0.109
      case 45:
        return 13 - distance * 0.107
      case 46:
        return 13 - distance * 0.105
      case 47:
        return 13 - distance * 0.1035
      case 48:
        return 13 - distance * 0.1025
      case 49:
        return 13 - distance * 0.101
      case 50:
        return 13 - distance * 0.1
      case 51:
        return 13 - distance * 0.099
      case 52:
        return 13 - distance * 0.098
      case 53:
        return 13 - distance * 0.0965
      case 54:
        return 13 - distance * 0.0955
      case 55:
        return 13 - distance * 0.094
      case 56:
        return 13 - distance * 0.093
      case 57:
        return 13 - distance * 0.092
      case 58:
        return 13 - distance * 0.091
      case 59:
        return 13 - distance * 0.09
      case 60:
        return 13 - distance * 0.089
      case 61:
        return 13 - distance * 0.088
      case 62:
        return 13 - distance * 0.087
      case 63:
        return 13 - distance * 0.086
      case 64:
        return 13 - distance * 0.085
      case 65:
        return 13 - distance * 0.084
      case 66:
        return 13 - distance * 0.083
      case 67:
        return 13 - distance * 0.082
      case 68:
        return 13 - distance * 0.081
      case 69:
        return 13 - distance * 0.08
      case 70:
        return 13 - distance * 0.079
      case 71:
        return 13 - distance * 0.078
      case 72:
        return 13 - distance * 0.07711111
      case 73:
        return 13 - distance * 0.07711112
      case 74:
        return 13 - distance * 0.0763
      case 75:
        return 13 - distance * 0.0755
      case 76:
        return 13 - distance * 0.075
      case 77:
        return 13 - distance * 0.074
      case 78:
        return 13 - distance * 0.0735
      case 79:
        return 13 - distance * 0.073
      case 80:
        return 13 - distance * 0.0725
      case 81:
        return 13 - distance * 0.072
      case 82:
        return 13 - distance * 0.0715
      case 83:
        return 13 - distance * 0.071
      case 84:
        return 13 - distance * 0.0705
      case 85:
        return 13 - distance * 0.07
      case 86:
        return 13 - distance * 0.0695
      case 87:
        return 13 - distance * 0.069
      case 88:
        return 13 - distance * 0.0685
      case 89:
        return 13 - distance * 0.068
      case 90:
        return 13 - distance * 0.0675
      case 91:
        return 13 - distance * 0.067
      case 92:
        return 13 - distance * 0.0665
      case 93:
        return 13 - distance * 0.066
      case 94:
        return 13 - distance * 0.0655
      case 95:
        return 13 - distance * 0.065
      case 96:
        return 13 - distance * 0.0645
      case 97:
        return 13 - distance * 0.064
      case 98:
        return 13 - distance * 0.0635
      case 99:
        return 13 - distance * 0.063
      case 100:
        return 13 - distance * 0.0625
      default:
        return 13 - distance * 0.1
    }
  }
}
