import React, { ChangeEventHandler } from 'react'
import { Helmet } from 'react-helmet'
import { Button } from '../../../components/atoms'
import {
  ProgressBarLinear,
  NavigationFooter,
  ReadingResume,
} from '../../../components/molecules'
import {
  EPageTitleOB,
  listBlocksPBLinear,
} from '../../../utils/constants/obConstants'
import { useTranslation } from 'react-i18next'
import './obUploadResume.scss'

interface IProps {
  uploadResume: ChangeEventHandler<HTMLInputElement>
  customJobsHandler: () => void
  isLoading: boolean
  onClickBack: () => void
  onClickNext: () => void
  isNextBtnDisable: boolean
}

export const OBUploadResumeUI = (props: IProps) => {
  const {
    uploadResume,
    isLoading,
    onClickBack,
    onClickNext,
    isNextBtnDisable,
    customJobsHandler,
  } = props
  const { t } = useTranslation()

  return (
    <div className="ob-upload-resume ob-container">
      <Helmet>
        <title> {EPageTitleOB.upload_resume} </title>
      </Helmet>

      {isLoading ? (
        <ReadingResume />
      ) : (
        <>
          <ProgressBarLinear
            className="name-page__progress-bar"
            initData={listBlocksPBLinear}
            currentPage={6}
          />

          <div className="ob-upload-resume__content">
            <p className="ob-upload-resume__title ob-question">
              {t("upload_resume.Let's make this as quick & easy as possible")}
            </p>
            <p className="ob-upload-resume__description ob-description">
              {t(
                'upload_resume.We want to get to know you and your work experience but we know that typing everything out can be a real pain. If you have a resume handy, we can speed the process up by prepopulating some data going forward.'
              )}
            </p>
            <div className="ob-upload-resume__buttons-block">
              <Button className="ob-upload-resume__btn">
                <input
                  className="ob-upload-resume__btn-input"
                  type="file"
                  onChange={uploadResume}
                  data-testid="ob-upload-resume__btn-input"
                />
                {t('upload_resume.UPLOAD YOUR RESUME')}
              </Button>
              <Button
                className="ob-upload-resume__btn-skipping"
                onClick={customJobsHandler}
              >
                {t('upload_resume.I do not have a resume to upload')}
              </Button>
            </div>
          </div>

          <NavigationFooter actionBack={onClickBack}>
            <Button onClick={onClickNext} disabled={isNextBtnDisable}>
              {t('Shared_Header.Next')}
            </Button>
          </NavigationFooter>
        </>
      )}
    </div>
  )
}
