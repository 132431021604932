export const ValidateInput = () => {
  const errors: UpdateProfileError = {
    phone: undefined,
    first_name: undefined,
    last_name: undefined,
  }

  const validate = (allError: string[]) => {
    allError.forEach(error => {
      switch (error) {
        case 'phone_Enter valid phone number':
          errors.phone = 'Please enter a valid phone number'
          break

        case 'first_name_format':
          errors.first_name = 'Please use only letters'
          break

        case 'first_name_length':
          errors.first_name = 'maximum 50 letters'
          break

        case 'last_name_format':
          errors.last_name = 'Please use only letters'
          break

        case 'last_name_length':
          errors.last_name = 'maximum 50 letters'
          break

        default:
          return error
      }
      return allError
    })
    return errors
  }

  return {
    errors,
    validate,
  }
}

export interface UpdateProfileError {
  phone: string | undefined
  first_name: string | undefined
  last_name: string | undefined
}

export const inputFormatting = (value: string, maxLength: number) => {
  return value?.slice(0, maxLength)
}
