import React from 'react'
import { SearchInputState } from '../../organism'
import './searchItem.scss'
import zipcodes from 'zipcodes'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  item: string
  setSelectedElement?: React.Dispatch<React.SetStateAction<SearchInputState>>
  onRemove?: () => void
  renderAsZipcode?: boolean
}

export const SearchItem = (props: Props) => {
  const { onRemove, item, setSelectedElement, renderAsZipcode } = props

  const removeItem = () => {
    setSelectedElement &&
      setSelectedElement(prev => {
        return { ...prev, selected: prev.selected.filter(el => el !== item) }
      })
  }

  return (
    <div
      className={`search-item ${props.className ?? ''}`}
      data-testid={`search-item_${item}`}
    >
      {!renderAsZipcode
        ? item
        : (zipcodes.lookup(item)?.city &&
            `${zipcodes.lookup(item)?.city}, ${
              zipcodes.lookup(item)?.state
            }`) ??
          item}
      <div
        onClick={onRemove ? onRemove : removeItem}
        className="search-item__close"
        data-testid={`search-item__close_${item}`}
      ></div>
    </div>
  )
}
