import {
  ScheduleCurrentType,
  ScheduleType,
  ScheduleTypeEnum,
} from '../../models'

export const scheduleType: ScheduleType = {
  phone: ScheduleTypeEnum.phone,
  inPerson: ScheduleTypeEnum.inPerson,
  videoCall: ScheduleTypeEnum.videoCall,
}

export const scheduleCurrentType: ScheduleCurrentType = {
  onboarding: 'onboarding',
  specific_job: 'specific_job',
}

export const IS_QUALIFY = 'isQualify'
