import peopleIcon from '../../../assets/icons/employer/peopleIcon.svg'
import './candidatesCounter.scss'

interface Props {
  count?: number
}

export const CandidatesCounter = (props: Props) => {
  const { count } = props
  return (
    <div className="candidates-counter">
      <div className="candidates-counter__text">Candidates</div>
      <div className="candidates-counter__counter">
        <img
          src={peopleIcon}
          alt="people counter"
          className="candidates-counter__counter-img"
        />
        <span
          className="candidates-counter__counter-quantity"
          data-testid="candidates-counter__counter-quantity"
        >
          {count ?? '--'}
        </span>
      </div>
    </div>
  )
}
