import { useCallback, useEffect, useMemo, useState } from 'react'
import { ElemObj } from '../../../../../components/atoms'
import { WorkPreferencesInfo } from '../../../../../models'
import { getObjWorkPref } from './WorkPreferencesModal/workPrefVarsAndFunc'
import {
  getWorkPreferencesInfo,
  updateWorkPreferencesInfo,
} from '../../../../../services/workerApi/profileAPI'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  ProfileAnalyticsName,
  RoleNames,
  WorkerQueries,
} from '../../../../../utils/constants'
import { compareTwoArrayOfString } from '../../../../../utils/scripts/compareTwoArrayOfString'
import { useTypedSelector } from '../../../../../utils/hooks'
import { WorkPreferencesUI } from './WorkPreferencesUI'
import {
  extraSpacesFormatting,
  getExistingRoles,
  getFilteredOtherRole,
  onTrackingActions,
} from '../../../../../utils/scripts'
import { toast } from 'react-toastify'
import { QueryState } from 'react-query/types/core/query'
import { useParams } from 'react-router-dom'

export interface WorkPrefForModal {
  most_recent_pay_rate: ElemObj | null
  desired_pay_rate: ElemObj | null
  work_preferences: string[]
  ideal_employer: string[]
  roles: string[] | null
  other_role?: string | null
}

export const WorkPreferences = () => {
  const queryClient = useQueryClient()
  const { modal } = useParams<{ modal: string }>()
  const isOpenModalUrl = modal === 'edit'
  const { roles } = useTypedSelector(s => s.variables.choiceListForWorker)

  const workPrefQuery: QueryState<WorkPreferencesInfo, undefined> | undefined =
    queryClient.getQueryState(WorkerQueries.workPreferencesInfo)

  const { data: workPrefValue, isLoading: isPrefLoading } = useQuery(
    WorkerQueries.workPreferencesInfo,
    () => getWorkPreferencesInfo(),
    {
      enabled: !workPrefQuery?.data,
    }
  )

  const workPrefQueryMutation = useMutation((data: WorkPreferencesInfo) =>
    updateWorkPreferencesInfo(data)
  )
  const [isModalVisible, setIsModalVisible] = useState<boolean>(
    isOpenModalUrl ?? false
  )
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false)
  const [workPreferences, setWorkPreferences] =
    useState<WorkPreferencesInfo | null>(workPrefValue ?? null)

  const [editableWorkPreferences, setEditableWorkPreferences] =
    useState<WorkPrefForModal>({
      most_recent_pay_rate: null,
      desired_pay_rate: null,
      work_preferences: [],
      ideal_employer: [],
      roles: [],
      other_role: null,
    })

  const hourly_pay = useMemo(
    () =>
      editableWorkPreferences.most_recent_pay_rate?.element === '-'
        ? '$0'
        : editableWorkPreferences.most_recent_pay_rate?.element,
    [editableWorkPreferences.most_recent_pay_rate?.element]
  )

  const desired_pay_rate = useMemo(
    () =>
      editableWorkPreferences.desired_pay_rate?.element === '-'
        ? '$0'
        : editableWorkPreferences.desired_pay_rate?.element,
    [editableWorkPreferences.desired_pay_rate?.element]
  )

  const preparedRolesData = useMemo(
    () => roles.supported_roles?.concat(roles.unsupported_roles ?? []),
    [roles.supported_roles, roles.unsupported_roles]
  )

  const isDataNotChanged = useMemo(() => {
    const didChangeMostRecentPay =
      workPreferences?.most_recent_pay_rate === hourly_pay

    const didChangeDesiredPay =
      workPreferences?.desired_pay_rate === desired_pay_rate

    const didIdealChange = compareTwoArrayOfString(
      editableWorkPreferences.ideal_employer,
      workPreferences?.ideal_employer
    )

    const didRoleChange = compareTwoArrayOfString(
      editableWorkPreferences?.roles,
      workPreferences?.roles
    )

    const didChangeOtherRole =
      editableWorkPreferences?.other_role === workPreferences?.other_role

    const didWorkPrefChange = compareTwoArrayOfString(
      editableWorkPreferences.work_preferences,
      workPreferences?.work_preferences
    )

    return (
      didChangeMostRecentPay &&
      didChangeDesiredPay &&
      didIdealChange &&
      didRoleChange &&
      didChangeOtherRole &&
      didWorkPrefChange
    )
  }, [workPreferences, editableWorkPreferences, hourly_pay, desired_pay_rate])

  const handleSubmit = () => {
    const includesOther = editableWorkPreferences?.roles?.includes(
      RoleNames.other_trade
    )

    if (includesOther && !editableWorkPreferences?.other_role) {
      toast.error('You clicked on Other, please enter role')
      return
    }

    const preparedOtherRole = includesOther
      ? getFilteredOtherRole(
          editableWorkPreferences.other_role,
          preparedRolesData
        )
      : null

    const prepareRoles =
      includesOther && !preparedOtherRole?.length
        ? editableWorkPreferences?.roles?.filter(
            role => role !== RoleNames.other_trade
          )
        : editableWorkPreferences?.roles

    if (preparedOtherRole && preparedOtherRole.length >= 100) {
      toast.error(
        'Please, enter less than 100 characters in the "other role" field'
      )
      return
    }

    if (isDataNotChanged) {
      setIsModalVisible(false)
    } else {
      const preparedData: WorkPreferencesInfo = {
        most_recent_pay_rate: hourly_pay ?? null,
        desired_pay_rate: desired_pay_rate ?? null,
        work_preferences: editableWorkPreferences.work_preferences,
        ideal_employer: editableWorkPreferences.ideal_employer,
        roles: prepareRoles ?? null,
        other_role: preparedOtherRole,
      }

      workPrefQueryMutation.mutate(preparedData, {
        onSuccess: () => {
          queryClient.setQueryData(
            WorkerQueries.workPreferencesInfo,
            preparedData
          )
          onTrackingActions(ProfileAnalyticsName.profile_edited)
          setIsModalVisible(false)
        },
        onError: err => {
          if (err instanceof Error) toast.error(err.message)
        },
      })
    }
  }

  const onChangeOtherInput = useCallback(
    (value: string) => {
      const existingRoles: string[] = getExistingRoles(
        value,
        editableWorkPreferences?.roles,
        preparedRolesData
      )

      setEditableWorkPreferences(prevWorkPref => {
        return {
          ...prevWorkPref,
          roles: existingRoles.length
            ? [...(prevWorkPref.roles ?? []), ...existingRoles]
            : [...(prevWorkPref.roles ?? [])],
          other_role: extraSpacesFormatting(value),
        }
      })
    },
    [editableWorkPreferences?.roles, preparedRolesData]
  )

  useEffect(() => {
    if (workPrefValue) {
      setWorkPreferences(workPrefValue)
      setEditableWorkPreferences(getObjWorkPref(workPrefValue))
    }
  }, [workPrefValue])

  return (
    <WorkPreferencesUI
      handleSubmit={handleSubmit}
      isPrefLoading={isPrefLoading}
      isModalVisible={isModalVisible}
      workPreferences={workPreferences}
      isDataNotChanged={isDataNotChanged}
      setIsModalVisible={setIsModalVisible}
      preparedRolesData={preparedRolesData}
      setWorkPreferences={setWorkPreferences}
      isSaving={workPrefQueryMutation.isLoading}
      editableWorkPreferences={editableWorkPreferences}
      setEditableWorkPreferences={setEditableWorkPreferences}
      isConfirmationModalVisible={isConfirmationModalVisible}
      setIsConfirmationModalVisible={setIsConfirmationModalVisible}
      onChangeOtherInput={onChangeOtherInput}
    />
  )
}
