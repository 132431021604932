import React from 'react'
import './workerFromSearchList.scss'
import { changeColorOfPartText } from '../../../../../utils/scripts'

interface Props extends React.HTMLAttributes<HTMLElement> {
  photo?: string | null
  name: string
  secondName?: string
  full_role?: string
  value?: string
  withioutPhoto?: true
}

export const WorkerFromSearchList = (props: Props) => {
  const { photo, name, secondName, full_role, value, withioutPhoto } = props
  return (
    <div className={`worker-data ${props.className ?? ''}`}>
      <div className="worker-data__wrapper">
        {photo ? (
          <img className="worker-data__photo" src={photo} alt="user" />
        ) : withioutPhoto ? (
          ''
        ) : (
          <div className="worker-data__default-photo">{name[0]}</div>
        )}

        <div className="worker-data__info">
          <p className="worker-data__info-name">
            {changeColorOfPartText(name, value)}
            {secondName ? `, ${secondName}` : ''}
          </p>
          <p className="worker-data__info-title">{full_role}</p>
        </div>
      </div>
    </div>
  )
}
