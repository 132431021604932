export type Phone = 'phone'
export type InPerson = 'in_person'
export type VideoCall = 'video_call'
export type Onboarding = 'onboarding'
export type SpecificJob = 'specific_job'

export enum ScheduleTypeEnum {
  phone = 'phone',
  inPerson = 'in_person',
  videoCall = 'video_call',
}

export interface ScheduleType {
  phone: ScheduleTypeEnum.phone
  inPerson: ScheduleTypeEnum.inPerson
  videoCall: ScheduleTypeEnum.videoCall
}

export interface ScheduleCurrentType {
  onboarding: Onboarding
  specific_job: SpecificJob
}

export interface CallendarsList {
  id: number
  title: string
  is_selected: boolean
}

export interface DailySlots {
  date: string
  slots: FreeTimes[]
}

export interface DaySlot {
  date: string
  slot: FreeTimes
}

export interface MonthSlots {
  duration: number
  slots: DailySlots[]
}

export interface FreeTimes {
  id: number
  timezone: string
  starts_at: string
  ends_at: string
}

export interface IUserDataSlots {
  slots: MonthSlots
  phone: boolean
  representative_info: InterviewerModel
}

export interface UserReservation {
  uuid: string
  date: string
  starts_at: string
  ends_at: string
  event_type: Phone | InPerson | null
  type: Onboarding | SpecificJob | null
  timezone: string
  job_id: string
  phone?: string
}

export interface AvaliavleNow {
  is_allowed: boolean
}

export interface ImmediateCall {
  email: string
  timezone: string
  type: SpecificJob
  event_type: string | null
  job_id: string | null
}

export interface ImmediateCallOnboarding {
  email: string
  timezone: string
  type: Onboarding
}

export interface IntervewData {
  startDateInterview?: Date
  endDateInterview?: Date
  communicationData?: string
  typeInterview?: ScheduleTypeEnum
}

export interface AddAddress {
  address: string
  address_name: string | null
}

export interface Address {
  id: number
  address: string
  address_name: string | null
}

export interface InterviewerModel {
  email: string
  name: string
  phone: string
  profile_image: string
}
