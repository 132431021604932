import { WelcomePageUI } from './WelcomePageUI'
import './WelcomePage.scss'
import { useCallback, useMemo, useState } from 'react'
import { WorkerQueries } from '../../../../utils/constants'
import { useStateWithDep } from '../../../../utils/hooks'
import { useHistory } from 'react-router-dom'
import * as route from '../../../../../src/services/route'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { PublicInfo } from '../../../../models'
import { UploadPhoto } from '../../../../components/organism/General/UploadPhoto'
import { workWithTypeData } from '../../../../components/organism/General/UploadFiles/DataTypes/DataTypes'
import { getPublicInfo, updatePublicInfo } from '../../../../services/workerApi'
import { QueryState } from 'react-query/types/core/query'
import { toast } from 'react-toastify'

export const WelcomePage = () => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const maxLengthAbout = 400

  const publicInfoQuery: QueryState<PublicInfo, undefined> | undefined =
    queryClient.getQueryState(WorkerQueries.publicInfo)

  const { data: publicInfo } = useQuery(
    WorkerQueries.publicInfo,
    () => getPublicInfo(),
    { enabled: !publicInfoQuery?.data }
  )

  const publicInfoMutation = useMutation(
    (data: PublicInfo) => updatePublicInfo(data),
    {
      onError: err => {
        if (err instanceof Error) toast.error(err.message)
      },
    }
  )

  const [isProfilePhoto, setIsProfilePhoto] = useState(
    !!publicInfo?.profile_picture
  )
  const [isPhotoModalVisible, setIsPhotoModalVisible] = useState(false)
  const [isExampleModalVisible, setIsExampleModalVisible] = useState(false)
  const [about, setAbout] = useStateWithDep(publicInfo?.about_me ?? '')

  const profilePhoto = useMemo(
    () => (
      <UploadPhoto
        className={`profile-photo__img${isProfilePhoto ? '' : '-none'}`}
        updatedPhoto={publicInfo?.profile_picture ?? undefined}
        updateUploadedPhoto={(file: File) => setIsProfilePhoto(!!file)}
        accept={workWithTypeData.photoSupportTypes().toString()}
        defaultValue={publicInfo?.profile_picture ?? undefined}
        withoutBtns
        isModalVisible={isPhotoModalVisible}
        setIsModalVisible={setIsPhotoModalVisible}
      />
    ),
    [isPhotoModalVisible, isProfilePhoto, publicInfo?.profile_picture]
  )

  const onUploadPhoto = useCallback(() => setIsPhotoModalVisible(true), [])

  const onClickNext = useCallback(() => {
    const newPublicInfo: PublicInfo = {
      ...publicInfo,
      work_experience: publicInfo?.work_experience ?? [],
      about_me: about,
    }

    publicInfoMutation.mutate(newPublicInfo, {
      onSuccess: () => {
        queryClient.setQueryData(WorkerQueries.publicInfo, newPublicInfo)
        history.push(route.dashboard)
      },
    })
  }, [about, history, publicInfo, publicInfoMutation, queryClient])

  return (
    <WelcomePageUI
      name={publicInfo?.first_name ?? ''}
      isProfilePhoto={isProfilePhoto}
      about={about}
      setAbout={setAbout}
      maxLengthAbout={maxLengthAbout}
      onUploadPhoto={onUploadPhoto}
      onClickNext={onClickNext}
      isWaitingResponse={
        publicInfoMutation.isIdle ? false : !publicInfoMutation.error
      }
      isExampleModalVisible={isExampleModalVisible}
      setIsExampleModalVisible={setIsExampleModalVisible}
    >
      {profilePhoto}
    </WelcomePageUI>
  )
}
