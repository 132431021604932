import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { signUp } from '../../../../services/route'
import Cookies from 'js-cookie'
import { useAppDispatch } from '../../../../redux/store'
import { syncUser } from '../../../../redux/auth-reducer'
import { unwrapResult } from '@reduxjs/toolkit'
import { FACEBOOK_KEY, TokenHolder } from '../../../../services/TokenHolder'

export const FacebookLogin = () => {
  const history = useHistory()
  const token = Cookies.get('auth_token')!
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (token) {
      TokenHolder.set(token, false)
      dispatch(syncUser())
        .then(unwrapResult)
        .then(() => {
          Cookies.set(FACEBOOK_KEY, 'true')
        })
        .catch(e => {
          TokenHolder.remove()
          history.replace(signUp)
        })
    } else {
      history.replace(signUp)
    }
  }, [dispatch, history, token])

  return <div></div>
}
