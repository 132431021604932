import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import { Detail } from 'react-calendar'
import {
  bookSlot,
  getFreeSlots,
} from '../../../../../services/workerApi/getApi'
import {
  DaySlot,
  FreeTimes,
  InPerson,
  InterviewerModel,
  Onboarding,
  Phone,
  SpecificJob,
  UserReservation,
} from '../../../../../models'
import { toast } from 'react-toastify'
import { WorkerQueries, scheduleType } from '../../../../../utils/constants'
import { useQuery, useQueryClient } from 'react-query'
import { BookingCalendarUI } from './BookingCalendarUI'
import {
  formatPhoneNumber,
  getDaySlotArray,
} from '../../../../../utils/scripts'

interface Props {
  uuid: string
  event_type: Phone | InPerson | null
  date: Date
  setDate: React.Dispatch<React.SetStateAction<Date>>
  slots?: DaySlot[]
  setSlots: React.Dispatch<React.SetStateAction<DaySlot[] | undefined>>
  type: Onboarding | SpecificJob | null
  setIsModalBooking: React.Dispatch<React.SetStateAction<boolean>>
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  setErrorBooking: React.Dispatch<React.SetStateAction<string | undefined>>
  setInterviewer: React.Dispatch<React.SetStateAction<InterviewerModel | null>>
  isNavFooterShowed?: true
}

export const BookingCalendar = (props: Props) => {
  const {
    uuid,
    event_type,
    setIsModalVisible,
    setIsModalBooking,
    setErrorBooking,
    setInterviewer,
    type,
    date,
    setDate,
    slots,
    setSlots,
    isNavFooterShowed,
  } = props

  const [phone, setPhone] = useState<string>('')

  const queryClient = useQueryClient()
  const basicInfoQuery = queryClient.getQueryState(WorkerQueries.basicInfo)

  const onChangePhone = (e: React.FormEvent<HTMLInputElement>) => {
    const formatedPhoneNumber = formatPhoneNumber(
      e.currentTarget.value,
      false,
      true
    )
    if (formatedPhoneNumber) {
      setPhone(formatedPhoneNumber)
    }
  }

  const job = JSON.parse(localStorage.getItem('job') ?? 'null')
  const interviewType =
    event_type === scheduleType.inPerson ? 'In-Person' : 'Phone'

  const [currMonthAndYear, setCurrMonthAndYear] = useState({
    month: moment(date).format('MM'),
    year: date.getFullYear(),
  })

  const action = useCallback(() => {
    setSlots(undefined)
    const isCurrentMonth =
      currMonthAndYear.month === moment(new Date()).format('MM')
    return getFreeSlots(
      job?.id,
      isCurrentMonth
        ? moment(new Date()).format('YYYY-MM')
        : `${currMonthAndYear.year}-${currMonthAndYear.month}`,
      Intl.DateTimeFormat().resolvedOptions().timeZone,
      uuid,
      type
    )
  }, [uuid, type, currMonthAndYear, job?.id, setSlots])

  const interviewerName = useRef('...')

  const {
    data: slotsData,
    refetch,
    isLoading,
  } = useQuery(
    [WorkerQueries.freeSlots, currMonthAndYear.year, currMonthAndYear.month],
    () => action(),
    {
      cacheTime: 0,
      onSuccess(data) {
        interviewerName.current = data.representative_info.name.split(' ')[0]
      },
    }
  )

  const [activeButtonIndex, setActiveButtonIndex] = useState(-1)
  const [isPopupLoaderShowed, setIsPopupLoaderShowed] = useState(false)
  const [selectedTime, setSelectedTime] = useState<
    UserReservation | undefined
  >()

  const isPhoneFromServer = slotsData?.phone
  const minDateFromStorage = sessionStorage.getItem('minDate')

  const getSlots = useMemo(() => {
    if (!slotsData) return
    const daysWithSlots = slotsData.slots.slots.filter(
      day => !!day.slots.length
    )
    const slotsWithDays: DaySlot[] = getDaySlotArray(slotsData.slots.slots)
    return {
      daysWithSlots: daysWithSlots,
      slotsWithDays: slotsWithDays,
    }
  }, [slotsData])

  const reActiveTimeSlot = useCallback(() => {
    setSelectedTime(undefined)
    setActiveButtonIndex(-1)
  }, [])

  const convertDate = useCallback((str: Date) => {
    const date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2)
    return [date.getFullYear(), mnth, day].join('-')
  }, [])

  const addSlots = useCallback(
    (mainArr: DaySlot[] | undefined, quantity: number, newDate?: Date) => {
      if (mainArr) {
        setSlots(currSlots => {
          if (newDate) {
            const findIndexOfDate = mainArr.findIndex(
              day => day.date === convertDate(newDate)
            )
            const newPart = mainArr.slice(
              findIndexOfDate,
              findIndexOfDate + quantity
            )
            return [...newPart]
          }
          if (currSlots) {
            const lastSlotId = currSlots[currSlots.length - 1]?.slot.id
            const findIndex = mainArr.findIndex(
              slot => slot.slot.id === lastSlotId
            )
            const newPart = mainArr.slice(
              findIndex + 1,
              findIndex + 1 + quantity
            )
            return [...currSlots].concat(newPart)
          } else if (getSlots && getSlots.daysWithSlots.length) {
            ;`${moment(getSlots.daysWithSlots[0].date).toDate()}` !==
              `${date}` &&
              setDate(moment(getSlots.daysWithSlots[0].date).toDate())
            return mainArr.slice(0, quantity)
          }
        })
      }
    },
    [setSlots, convertDate, getSlots, date, setDate]
  )

  const onArrowClick = useCallback(
    (
      event: React.MouseEvent | React.KeyboardEvent,
      action: 'prev' | 'next'
    ) => {
      const parentBtn: Element | null = event.currentTarget.parentElement
      const isParentBtnDisabled = parentBtn
        ? parentBtn.hasAttribute('disabled')
        : true
      const newDate = new Date(date)
      if (!isParentBtnDisabled) {
        switch (action) {
          case 'next':
            if (date.getMonth() === 11) {
              newDate.setFullYear(date.getFullYear() + 1)
              newDate.setMonth(0)
            } else {
              newDate.setMonth(date.getMonth() + 1)
            }
            setDate(newDate)

            setCurrMonthAndYear({
              month: moment(newDate).format('MM'),
              year: newDate.getFullYear(),
            })
            reActiveTimeSlot()
            break

          case 'prev':
            if (date.getMonth() === 0) {
              newDate.setFullYear(date.getFullYear() - 1)
              newDate.setMonth(11)
            } else {
              newDate.setMonth(date.getMonth() - 1)
            }
            setDate(newDate)
            setCurrMonthAndYear({
              month: moment(newDate).format('MM'),
              year: newDate.getFullYear(),
            })
            reActiveTimeSlot()
            break
        }
      }
    },
    [date, reActiveTimeSlot, setDate]
  )

  const onChangeDate = useCallback(
    (selectedDate: Date): void => {
      addSlots(getSlots?.slotsWithDays, 6, selectedDate)
      setDate(selectedDate)
      reActiveTimeSlot()
    },
    [addSlots, getSlots?.slotsWithDays, reActiveTimeSlot, setDate]
  )

  const onClickMonth = useCallback(
    (date: Date) => {
      setDate(date)
      setCurrMonthAndYear({
        month: moment(date).format('MM'),
        year: date.getFullYear(),
      })
      reActiveTimeSlot()
    },
    [reActiveTimeSlot, setDate]
  )

  const setTime = useCallback(() => {
    if (selectedTime) {
      setErrorBooking(undefined)
      setIsPopupLoaderShowed(true)
      bookSlot({ ...selectedTime, ...(!isPhoneFromServer && { phone }) })
        .then(res => {
          const preparedStateForm = typeof basicInfoQuery?.data ===
            'object' && { ...basicInfoQuery.data, phone: phone }

          queryClient.setQueryData(WorkerQueries.basicInfo, preparedStateForm)
          setInterviewer(res)
          setIsPopupLoaderShowed(false)
          setIsModalVisible(true)
        })

        .catch(error => {
          if (error.message === 'Enter valid phone number') {
            setIsPopupLoaderShowed(false)
            toast.error(error.message)
          } else {
            setIsPopupLoaderShowed(false)
            if (!window.navigator.onLine) return
            setIsModalBooking(true)
            setErrorBooking(error?.message)
            refetch()
            reActiveTimeSlot()
          }
        })
    }
  }, [
    reActiveTimeSlot,
    selectedTime,
    setErrorBooking,
    setInterviewer,
    setIsModalBooking,
    setIsModalVisible,
    phone,
    isPhoneFromServer,
    basicInfoQuery,
    queryClient,
    refetch,
  ])

  const handleActiveDate = useCallback(
    (slot: FreeTimes, date: string) => {
      setActiveButtonIndex(slot.id)
      setDate(moment(slot.starts_at, 'YYYY-MM-DD hh:mm:ss a').toDate())

      const selectedDate = {
        uuid: uuid,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        starts_at: slot.starts_at,
        ends_at: slot.ends_at,
        event_type: event_type,
        type: type,
        date: date,
        job_id: job?.id,
      }
      setSelectedTime(selectedDate)
    },
    [event_type, job?.id, setDate, type, uuid]
  )

  const getTime = useCallback(
    (time: string) =>
      `${moment(time, 'YYYY-MM-DD hh:mm:ss a').format('h:mm a')}`,
    []
  )

  const getCalendarLabel = useCallback(
    (label: 'prev' | 'next') => (
      <div
        onClick={event => onArrowClick(event, label)}
        className={`schedule__calendar-arrow-${label}`}
        data-testid={`schedule__calendar-arrow-${label}`}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            onArrowClick(event, label)
          }
        }}
      ></div>
    ),
    [onArrowClick]
  )

  const getTileDisabled = useCallback(
    (date: Date, view: Detail) => {
      if (!getSlots) return false
      return (
        !(
          getSlots.daysWithSlots.findIndex(
            day => +moment(day.date).format('DD') === date.getDate()
          ) + 1
        ) && view === 'month'
      )
    },
    [getSlots]
  )

  useEffect(() => {
    const slotDate = slotsData?.slots.slots[0].date
    const minDate = slotDate && new Date(slotDate)

    if (!minDateFromStorage || minDateFromStorage === 'undefined')
      sessionStorage.setItem('minDate', `${minDate}`)
  }, [minDateFromStorage, slotsData?.slots.slots])

  useEffect(() => {
    if ((!slots || !!(slots && !slots.length)) && getSlots)
      addSlots(getSlots.slotsWithDays, 6)
  }, [slots, getSlots, addSlots])

  return (
    <BookingCalendarUI
      interviewType={interviewType}
      activeButtonIndex={activeButtonIndex}
      isPopupLoaderShowed={isPopupLoaderShowed}
      date={date}
      type={type}
      slotsData={slotsData}
      setTime={setTime}
      onClickMonth={onClickMonth}
      onChangeDate={onChangeDate}
      handleActiveDate={handleActiveDate}
      addSlots={addSlots}
      getTime={getTime}
      getCalendarLabel={getCalendarLabel}
      getTileDisabled={getTileDisabled}
      isLoading={isLoading}
      slots={slots}
      selectedTime={selectedTime}
      getSlots={getSlots}
      isNavFooterShowed={isNavFooterShowed}
      isPhoneFromServer={isPhoneFromServer}
      phone={phone}
      onChangePhone={onChangePhone}
      interviewerName={interviewerName.current}
      minDateFromStorage={minDateFromStorage}
    />
  )
}
