import React, { useEffect } from 'react'
import { TitleBlogModel } from '../../../../models/TitleBlogModel'
import { scrolling } from '../../../../utils/scripts/scrolling'
import { isPartiallyVisible } from '../../../../utils/scripts'

import './titleBlog.scss'

interface Props extends React.HTMLAttributes<HTMLElement> {
  onClickShowBlog: () => void
  model: TitleBlogModel
}

export const TitleBlog = (props: Props) => {
  let timeouts: (NodeJS.Timeout | undefined)[] = []

  const appear = (elements: HTMLCollection) => {
    disappear()
    const array = Array.from(elements)

    timeouts = array
      .map(el => {
        if (isPartiallyVisible(el)) {
          el.classList.add('visible')
          return setTimeout(() => {
            el.classList.remove('unvisible')
          }, 1000)
        }
        return undefined
      })
      .filter(el => el !== undefined)
  }

  const disappear = () => {
    timeouts.forEach(el => {
      clearTimeout(el!)
    })
  }

  useEffect(() => {
    const blogList = document.getElementsByClassName('title-blog')!
    appear(blogList)
    scrolling(appear.bind(this, blogList))
    return () => disappear()
  })

  return (
    <div id={`title-blog_${props.id}`} className="title-blog unvisible">
      <div
        className="title-blog__img"
        style={{ backgroundImage: `url(${props.model.imgUrl})` }}
      ></div>
      <div className="title-blog__content">
        <p className="title-blog__num">6/3/19</p>
        <h2 className="title-blog__title">
          <a href={props.model.link}>{props.model.title}</a>
        </h2>
        <p className="title-blog__description">{props.model.description}</p>
        <button className="title-blog__link" onClick={props.onClickShowBlog}>
          Read More
        </button>
      </div>
    </div>
  )
}
