import React from 'react'
import { ModalBox } from '../../../../molecules'
import './linksBlock.scss'
import { LinksModal } from './LinksModal'
import edit_pensil from '../../../../../assets/icons/other/edit-pencil.svg'
import { Link } from '../../../../../models'
import { PopupLoader } from '../../../General'

interface Props extends React.HTMLAttributes<HTMLElement> {
  isModalVisible: boolean
  modalActions: () => void
  links: Link[]
  setLinks: React.Dispatch<React.SetStateAction<Link[]>>
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  handleSubmit: () => void
  filledLinks: Link[]
  isConfirmationModalVisible: boolean
  setIsConfirmationModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  savingLoader: boolean
  addLinkIcon: (linkTitle: string) => string
}

export const LinksBlockUI = (props: Props) => {
  const {
    className,
    isModalVisible,
    modalActions,
    links,
    setLinks,
    setIsModalVisible,
    handleSubmit,
    filledLinks,
    isConfirmationModalVisible,
    setIsConfirmationModalVisible,
    savingLoader,
    addLinkIcon,
  } = props

  return (
    <div className={`links-block ${className ?? ''}`}>
      {savingLoader && <PopupLoader>Saving...</PopupLoader>}
      {isModalVisible && (
        <ModalBox
          className="links-block__modal-box"
          onCloseModal={() => modalActions()}
          visibleCloseIcon={true}
        >
          <LinksModal
            className="container"
            links={links}
            setLinks={setLinks}
            setIsModalVisible={setIsModalVisible}
            handleSubmit={handleSubmit}
            filledLinks={filledLinks}
            isConfirmationModalVisible={isConfirmationModalVisible}
            setIsConfirmationModalVisible={setIsConfirmationModalVisible}
          />
        </ModalBox>
      )}
      <div className="links-block__header">
        <p className="links-block__header-title">Links</p>
        <img
          className="links-block__header-edit-pensil"
          src={edit_pensil}
          alt="edit-pensil"
          onClick={() => setIsModalVisible(true)}
        />
      </div>
      {filledLinks.some(link => link.value) ? (
        <div className="links-block__links-list">
          {filledLinks
            .filter(link => link.value)
            .map(link => {
              return (
                <div key={link.title} className="links-block__link">
                  <img
                    className="links-block__link-icon"
                    src={addLinkIcon(link.title)}
                    alt={`${link.title}`}
                  />
                  <p className="links-block__link-description">{link.value}</p>
                </div>
              )
            })}
        </div>
      ) : (
        <div className="links-block__wrapper">
          <p className="links-block__wrapper-description">
            Have work to share from other platforms? Add links here.
          </p>
        </div>
      )}
    </div>
  )
}
