import React from 'react'

export const scrolling = (
  callback: (event: React.MouseEvent<HTMLElement>) => void
) => {
  let isScrolling = false

  window.addEventListener('scroll', throttleScroll, false)

  function throttleScroll(event: any) {
    if (isScrolling === false) {
      window.requestAnimationFrame(() => {
        callback(event)
        isScrolling = false
      })
    }
    isScrolling = true
  }
}
