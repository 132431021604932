import React, { Fragment } from 'react'
import { v4 as uuidv4 } from 'uuid'
import './workerStatuses.scss'

interface Props extends React.HTMLAttributes<HTMLElement> {
  statusList: { status: string; numberOfStatus: number }[]
  activeStatus: number
}

export const WorkerStatusesUI = (props: Props) => {
  const { activeStatus, statusList } = props

  return (
    <div className={`worker-progress ${props.className ?? ''}`}>
      {statusList.map((status, index) => {
        const isLastStatus = index === statusList.length - 1
        return (
          <Fragment key={uuidv4()}>
            <p
              className={`worker-progress__status ${
                activeStatus >= status.numberOfStatus ? 'active-status' : ''
              }`}
            >
              {status.status}
            </p>
            {!isLastStatus && (
              <span className="worker-progress__status">&gt;</span>
            )}
          </Fragment>
        )
      })}
    </div>
  )
}
