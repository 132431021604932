import React from 'react'
import './tabsContainer.scss'
export interface TabData {
  id: number
  tab: string
  component: JSX.Element
}

interface Props extends React.HTMLAttributes<HTMLElement> {
  tabData: TabData[]
  disableTabNames?: true
  activeTab?: number
}

export const TabsContainer = (props: Props) => {
  return (
    <div
      style={props.style}
      className={`${props.className ?? ''} tabs-container`}
    >
      {props.tabData.map(tablist => {
        if (props.activeTab === tablist.id) {
          return (
            <React.Fragment key={tablist.id}>
              {tablist.component}
            </React.Fragment>
          )
        }
        return undefined
      })}
    </div>
  )
}
