export const gridItemsPlacement = (
  containerRef: React.RefObject<HTMLDivElement>,
  elemWidth: number,
  gap: number,
  listLength: number
): 'center' | 'left' => {
  const containerWidth = containerRef.current?.offsetWidth ?? 0
  const contentWidth = (gap + elemWidth) * listLength - gap
  return contentWidth < containerWidth ? 'left' : 'center'
}
