import React from 'react'
import './passedForms.scss'
import { PreInterviewTemp } from '../../Templates/PreInterviewTemplate'
import succsessImg from '../../../assets/icons/checkmarks/succsess-int.svg'

export const PassedForms = () => {
  return (
    <div className="passed-forms preinterview-container">
      <PreInterviewTemp
        imgUrl={succsessImg}
        title="Congrats! It’s a great fit."
        progressBar={false}
      >
        Skillit matches you with a (free) career guide who works to make sure
        you find the pay and work you want. The next step is to schedule a 10
        minute welcome call so that s/he can get started working for you.
      </PreInterviewTemp>
    </div>
  )
}
