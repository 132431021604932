import closeIcon from '../../../../../../assets/icons/close/criss-cross.svg'
import resultIcon from '../../../../../../assets/icons/employer/search-result.svg'
import { FilterType } from '../../../../../../models/EmployeeParams'
import {
  defaultFilter,
  useAppDispatch,
  setFilters,
} from '../../../../../../redux'
import './searchResult.scss'

export const SearchResult = () => {
  const dispatch = useAppDispatch()
  return (
    <div className="search-result search-result_active">
      <img src={resultIcon} alt="" className="search-result__icon" />
      <span className="search-result__text">Search results</span>
      <button
        onClick={() =>
          dispatch(
            setFilters({
              ...defaultFilter,
              type: { properties: null, type: FilterType.all },
            })
          )
        }
        className="search-result__btn-close"
      >
        <img
          src={closeIcon}
          alt="clear search"
          className="search-result__btn-close-icon"
        />
      </button>
    </div>
  )
}
