import React from 'react'
import './obLanguages.scss'
import {
  NavigationFooter,
  ProgressBarLinear,
} from '../../../components/molecules'
import { Button, Checkbox, RadioBtn } from '../../../components/atoms'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { EPageTitleOB, listBlocksPBLinear } from '../../../utils/constants'
import { handleCheckboxProfile } from '../../InAppFlow/Worker/ProfileCompletion/handleProfileInputs'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  onClickBack: () => void
  onClickNext: (e: React.MouseEvent<HTMLButtonElement>) => void
  languagesList: {
    choice: string
    is_supported: boolean
  }[]
  selectedLanguages: string[]
  setSelectedLanguages: React.Dispatch<React.SetStateAction<string[]>>
  preferredLanguage?: { id: number; value: string }
  setPreferredLanguage: React.Dispatch<
    React.SetStateAction<{ id: number; value: string } | undefined>
  >
  preparedSelectedLanguage: { id: number; value: string }[]
  isNotSupportedSelectedLanguage: boolean
  isDisabled: boolean
  supportedLanguages: string
}

export const OBLanguagesUI = (props: IProps) => {
  const {
    onClickBack,
    onClickNext,
    languagesList,
    selectedLanguages,
    setSelectedLanguages,
    preferredLanguage,
    setPreferredLanguage,
    preparedSelectedLanguage,
    isNotSupportedSelectedLanguage,
    isDisabled,
    supportedLanguages,
  } = props
  const { t } = useTranslation()
  return (
    <div className="ob-languages ob-container">
      <Helmet>
        <title> {EPageTitleOB.language} </title>
      </Helmet>
      <ProgressBarLinear initData={listBlocksPBLinear} currentPage={7} />
      <p className="ob-languages__question ob-question">
        {t('preffered_languages.What language(s) do you speak?')}
      </p>
      <p className="ob-languages__description ob-description">
        {t(
          'preffered_languages.Communication is key! Knowing what language(s) you speak will help us find a work environment where you are most comfortable.'
        )}
      </p>
      <div className="ob-languages__choise-info-block">
        {languagesList.map((language, languageIndex) => {
          const checked = selectedLanguages.includes(language.choice)
          return (
            <Checkbox
              key={language.choice}
              buttonsGrid
              className={`${
                checked ? 'ob__checkbox_checked' : ''
              } ob-languages__choise-info-block_name`}
              name={t([
                `preffered_languages.${language.choice}`,
                `${language.choice}`,
              ]).toString()}
              idCheckbox={languageIndex}
              checked={checked ? true : undefined}
              toggleCheckbox={(_, isChecked) => {
                handleCheckboxProfile(
                  isChecked,
                  setSelectedLanguages,
                  language.choice
                )
              }}
            />
          )
        })}
      </div>
      {selectedLanguages.length > 1 && (
        <div className="ob-languages__selected-languages">
          <p className="ob-languages__description ob-description">
            {t('preffered_languages.Which is your preferred language?')}
          </p>
          <div className="ob-languages__selected-languages-list">
            {preparedSelectedLanguage
              .filter(el => el.value)
              .map(language => {
                return (
                  <RadioBtn
                    key={language.id}
                    radioId={language.id}
                    value={t([
                      `preffered_languages.${language.value}`,
                      `${language.value}`,
                    ]).toString()}
                    selected={
                      preferredLanguage?.value === language.value
                        ? language.id
                        : undefined
                    }
                    onChangeBtn={id =>
                      setPreferredLanguage({
                        id: id,
                        value: language.value,
                      })
                    }
                    singleButtonsGrid
                    className={`ob-languages__selected-languages-list_name ${
                      preferredLanguage?.value === language.value
                        ? 'is_checked'
                        : ''
                    }`}
                  />
                )
              })}
          </div>

          {preferredLanguage && isNotSupportedSelectedLanguage && (
            <p className="ob-languages__description ob-description">
              {t('preffered_languages.Please note', {
                language: `${supportedLanguages}`,
              })}
            </p>
          )}
        </div>
      )}
      <NavigationFooter actionBack={onClickBack}>
        <Button onClick={onClickNext} disabled={isDisabled}>
          {t('Shared_Header.Next')}
        </Button>
      </NavigationFooter>
    </div>
  )
}
