import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { appReducer, authReducer, obReducer, variablesReducer } from './'
import { employerSlice } from './employer-reducer'

export type RootState = ReturnType<typeof store.getState>

export type AsyncThunkConfig = {
  state: RootState
}

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    obInfo: obReducer,
    employer: employerSlice.reducer,
    variables: variablesReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
