import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ICertificationGroup, IFilterParams, IRolesOB } from '../models'
import { IFiltersOptionsAdminPage } from '../models/AdminWorkes'
import { getFiltersOptionsAdminPage } from '../services/adminWorkersAPI'
import { getFilterParams } from '../services/employerAPI'
import { getRoles } from '../services/generalAPI'
import { getChoiceListsForWorker } from '../services/workerApi'
import { roleSorting } from '../utils/scripts/general'

export interface IInitialState {
  navbarHeight: number
  topFilterHeight: number
  choiceListForWorker: {
    unions: string[] | null
    languages:
      | {
          choice: string
          is_supported: boolean
        }[]
      | null
    training_certifications: ICertificationGroup[] | null
    roles: IRolesOB
    skills: string[] | null
    rolesWithSkills: {
      [key: string]: string[]
    } | null
    skillsGroups: {
      [key: string]: string[]
    } | null
  }
  choiceListForEmployer: IFilterParams
  filterOptionsForAdmin: IFiltersOptionsAdminPage
}

const initialState: IInitialState = {
  navbarHeight: 56,
  topFilterHeight: 0,
  choiceListForWorker: {
    unions: null,
    languages: null,
    training_certifications: null,
    roles: {
      supported_roles: null,
      unsupported_roles: null,
    },
    skills: null,
    rolesWithSkills: null,
    skillsGroups: null,
  },
  choiceListForEmployer: {
    assessment: null,
    interview: {
      skillit_company_id: null,
    },
    skills: null,
    qualifications: null,
    languages: null,
    roles: null,
    location: null,
  },
  filterOptionsForAdmin: {
    locations: null,
    status: null,
    roles: null,
  },
}

export const choiceListsForWorker = createAsyncThunk(
  'VARIABLES/CHOICE-LISTS-FOR-WORKER',
  async () => {
    const additionalChoiceList = await getChoiceListsForWorker()
    const getRolesAndSkillsChoiceList = await getRoles()

    const rolesChoiceList: IRolesOB = {
      supported_roles: Object.keys(
        getRolesAndSkillsChoiceList.supported_roles ?? {}
      ),

      unsupported_roles: getRolesAndSkillsChoiceList.unsupported_roles,
    }

    const skillsChoiceList = Object.values(
      getRolesAndSkillsChoiceList.supported_roles ?? {}
    ).flatMap(el => el)

    return {
      unions: additionalChoiceList.unions,
      languages: additionalChoiceList.languages,
      training_certifications: additionalChoiceList.training_certifications,
      roles: rolesChoiceList,
      skills: Array.from(new Set(skillsChoiceList)),
      rolesWithSkills: getRolesAndSkillsChoiceList.supported_roles,
      skillsGroups: additionalChoiceList.groups,
    }
  }
)

export const filterOptionsForAdmin = createAsyncThunk(
  'VARIABLES/FILTER-OPTIONS-FOR-ADMIN',
  async () => {
    const filterOptions = await getFiltersOptionsAdminPage()

    return {
      locations: filterOptions.locations,
      status: filterOptions.status,
      roles: filterOptions.roles,
    }
  }
)

export const choiceListsForEmployer = createAsyncThunk(
  'VARIABLES/CHOICE-LISTS-FOR-EMPLOYER',
  async () => {
    const choiceList = await getFilterParams()

    return choiceList
  }
)

export const variablesSlice = createSlice({
  name: 'VARIABLES',
  initialState,
  reducers: {
    setNavbarHeight: (state, action) => {
      state.navbarHeight = action.payload
    },
    setTopFilterHeight: (state, action) => {
      state.topFilterHeight = action.payload
    },
    setChoiceListForWorker: (state, action) => {
      state.choiceListForWorker = action.payload
    },
    setFilterOptionsForAdmin: (state, action) => {
      state.filterOptionsForAdmin = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(choiceListsForWorker.fulfilled, (state, action) => {
      state.choiceListForWorker.languages = action.payload.languages
      state.choiceListForWorker.roles.supported_roles =
        action.payload.roles.supported_roles?.sort(roleSorting) ?? null
      state.choiceListForWorker.roles.unsupported_roles =
        action.payload.roles.unsupported_roles?.sort(roleSorting) ?? null
      state.choiceListForWorker.skills =
        action.payload.skills?.sort(roleSorting) ?? null
      state.choiceListForWorker.training_certifications =
        action.payload.training_certifications
      state.choiceListForWorker.unions = action.payload.unions
      state.choiceListForWorker.rolesWithSkills = action.payload.rolesWithSkills
      state.choiceListForWorker.skillsGroups = action.payload.skillsGroups
    })
    builder.addCase(choiceListsForEmployer.fulfilled, (state, action) => {
      state.choiceListForEmployer.assessment = action.payload.assessment
      state.choiceListForEmployer.interview = action.payload.interview
      state.choiceListForEmployer.languages = action.payload.languages
      state.choiceListForEmployer.location = action.payload.location
      state.choiceListForEmployer.qualifications = action.payload.qualifications
      state.choiceListForEmployer.roles =
        action.payload.roles?.sort((a, b) => roleSorting(a.choice, b.choice)) ??
        null
      state.choiceListForEmployer.skills =
        action.payload.skills?.sort((a, b) =>
          roleSorting(a.choice, b.choice)
        ) ?? null
    })
    builder.addCase(filterOptionsForAdmin.fulfilled, (state, action) => {
      state.filterOptionsForAdmin.locations = action.payload.locations
      state.filterOptionsForAdmin.status = action.payload.status
      state.filterOptionsForAdmin.roles =
        action.payload.roles?.sort((a, b) => roleSorting(a.choice, b.choice)) ??
        null
    })
  },
})

export const {
  setNavbarHeight,
  setTopFilterHeight,
  setChoiceListForWorker,
  setFilterOptionsForAdmin,
} = variablesSlice.actions

export const variablesReducer = variablesSlice.reducer
