import Cookies from 'js-cookie'

const TOKEN_KEY = 'token'
export const FACEBOOK_KEY = 'facebook'
export namespace TokenHolder {
  export const get = () => {
    const localToken = localStorage.getItem(TOKEN_KEY)
    const cookieToken = Cookies.get(TOKEN_KEY)
    if (!cookieToken && localToken) {
      Cookies.set(TOKEN_KEY, localToken)
    }
    const syncToken = Cookies.get(TOKEN_KEY)
    return syncToken
  }

  export const set = (token: string, keepAlive: boolean) => {
    Cookies.set(TOKEN_KEY, token)
    if (keepAlive) {
      localStorage.setItem(TOKEN_KEY, token)
    }
  }

  export const remove = () => {
    Cookies.remove(TOKEN_KEY)
    localStorage.removeItem(TOKEN_KEY)
  }
}
