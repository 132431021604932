import React, { useCallback } from 'react'
import { OshaInfo as OshaInfoModel } from '../../../../../../models/ProfileModel'
import { Config } from '../../../../../../components/organism/General/UploadFiles'
import {
  getOshaInfo,
  updateOshaInfo,
} from '../../../../../../services/workerApi/profileAPI'
import {
  ProfileAnalyticsName,
  WorkerQueries,
} from '../../../../../../utils/constants'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { onTrackingActions } from '../../../../../../utils/scripts'
import { QueryState } from 'react-query/types/core/query'
import { OshaInfoUI } from './OshaInfoUI'
import { toast } from 'react-toastify'
import { ERROR_SOMETHING } from '../../../../../../services/auth-service'

interface Props extends React.HTMLAttributes<HTMLElement> {}

export const OshaInfo = (props: Props) => {
  const queryClient = useQueryClient()

  const oshaInfoQuery: QueryState<OshaInfoModel, undefined> | undefined =
    queryClient.getQueryState(WorkerQueries.oshaInfo)

  const { data: oshaInfo, isLoading } = useQuery(
    WorkerQueries.oshaInfo,
    () => getOshaInfo(),
    { enabled: !oshaInfoQuery?.data }
  )

  const oshaInfoMutation = useMutation(
    (newData: { formData: FormData; config?: Config }) =>
      updateOshaInfo(newData.formData, newData.config)
  )

  const oshaInfoMutate = useCallback(
    (formData: FormData, config?: Config) => {
      let response: OshaInfoModel | Error
      oshaInfoMutation.mutate(
        { formData, config },
        {
          onSuccess: (updatedData: OshaInfoModel) => {
            onTrackingActions(ProfileAnalyticsName.profile_edited)
            queryClient.setQueryData(WorkerQueries.oshaInfo, updatedData)
            response = updatedData
          },
          onError: err => {
            if (err instanceof Error) {
              response = err
              toast.error(ERROR_SOMETHING)
            }
          },
        }
      )
      return new Promise(_ => response)
    },
    [queryClient, oshaInfoMutation]
  )

  return (
    <OshaInfoUI
      {...props}
      oshaInfo={oshaInfo}
      isLoading={isLoading}
      oshaInfoMutate={oshaInfoMutate}
    />
  )
}
