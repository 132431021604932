import React, { useEffect, useMemo } from 'react'
import { SkillsInfo } from '../../../../../../../models/ProfileModel'
import { getYesNoList } from '../../../handleProfileInputs'
import { useTypedSelector } from '../../../../../../../utils/hooks'
import { IInitialState } from '../../../../../../../redux'
import { BasicSkillsModalUI } from './BasicSkillsModalUI'

export interface Props extends React.HTMLAttributes<HTMLElement> {
  skillsInfoForm: SkillsInfo
  setSkillsInfoForm: React.Dispatch<React.SetStateAction<SkillsInfo>>
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  handleSubmit: (newData: SkillsInfo) => void
  isConfirmationModalVisible: boolean
  setIsConfirmationModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  skillsInfoState: SkillsInfo
  choiceLists: IInitialState
  roles: string[]
  listForPreferredLang: { id: number; value: string }[] | null
}

export const BasicSkillsModal = (props: Props) => {
  const {
    skillsInfoState,
    roles,
    listForPreferredLang,
    choiceLists,
    skillsInfoForm,
    setSkillsInfoForm,
  } = props

  const supportedRoles = useTypedSelector(
    state => state.variables.choiceListForWorker.roles.supported_roles
  )
  const rolesWithSkills = useTypedSelector(
    state => state.variables.choiceListForWorker.rolesWithSkills
  )

  const specificSkills: string[] = useMemo(() => {
    const selectedSkills = skillsInfoState.skills.map(skill => skill.choice)

    const skillsByRoles = roles
      .filter(el => supportedRoles && supportedRoles.includes(el))
      .flatMap((role: string) => (rolesWithSkills ? rolesWithSkills[role] : []))

    const uniqueSkills = Array.from(
      new Set(selectedSkills.concat(skillsByRoles))
    )

    return uniqueSkills.sort()
  }, [skillsInfoState.skills, supportedRoles, rolesWithSkills, roles])

  const usersSkillsDataWithId = useMemo(
    () =>
      specificSkills?.map((skill, index) => {
        return {
          id: index,
          choice: skill,
        }
      }),
    [specificSkills]
  )

  const supportedLanguages = useMemo(
    () =>
      choiceLists.choiceListForWorker.languages
        ?.filter(el => el.is_supported)
        .map(lang => lang.choice),
    [choiceLists.choiceListForWorker.languages]
  )

  const isNotSupporedLang = useMemo(
    () =>
      !supportedLanguages?.includes(skillsInfoForm.preferred_language ?? ''),
    [skillsInfoForm.preferred_language, supportedLanguages]
  )

  const eligibleWork = getYesNoList(1, 2)
  const upskilling = getYesNoList(3, 4)

  const isDisabledSaveBtn = useMemo(() => {
    if (!skillsInfoForm.language?.length) return true
    if (
      skillsInfoForm.language.length > 1 &&
      !skillsInfoForm.preferred_language
    )
      return true

    return false
  }, [skillsInfoForm])

  useEffect(() => {
    if (
      skillsInfoForm.preferred_language &&
      !skillsInfoForm.language?.includes(skillsInfoForm.preferred_language)
    ) {
      setSkillsInfoForm(prev => ({
        ...prev,
        preferred_language: null,
      }))
    }
  }, [setSkillsInfoForm, skillsInfoForm])

  return (
    <BasicSkillsModalUI
      {...props}
      eligibleWork={eligibleWork}
      upskilling={upskilling}
      usersSkillsDataWithId={usersSkillsDataWithId}
      listForPreferredLang={listForPreferredLang ?? []}
      isNotSupporedLang={isNotSupporedLang}
      isDisabledSaveBtn={isDisabledSaveBtn}
    />
  )
}
