import { ZIP_CODE } from './FormsContainer'
import { formatPhoneNumber, inputFormatting } from './../../../utils/scripts'
import {
  Content,
  ErrorForms,
  FormAnswers,
  ValuesForm,
  MatchingAnswers,
  Page,
} from '../../../models/PreInterviewForms'
import _ from 'lodash'
import { EMAIL, F_NAME, L_NAME, PHONE } from '.'

export const radioButtonAnswer = (
  questionId: number,
  questionType: string,
  optionName: string,
  optionId: number,
  isOptional: boolean,
  answId: number,
  answValue: string,
  setAnswersList: React.Dispatch<React.SetStateAction<FormAnswers>>,
  answersList: FormAnswers
) => {
  setAnswersList(prev => {
    const newAnswer = { ...prev }
    const foundQuestion = newAnswer.answers_list.find(
      question => question.id === questionId
    )

    if (answersList.answers_list.length === 0 || !foundQuestion) {
      newAnswer.answers_list = [
        ...newAnswer.answers_list,
        {
          id: questionId,
          type: questionType,
          options: [
            {
              id: optionId,
              is_optional: isOptional,
              name: optionName,
              answers: [
                {
                  id: answId,
                  value: answValue,
                },
              ],
            },
          ],
        },
      ]
    } else {
      const foundOption = foundQuestion.options.find(
        option => option.id === optionId
      )
      if (foundOption) {
        foundOption.answers = [
          {
            id: answId,
            value: answValue,
          },
        ]
      } else {
        foundQuestion.options = [
          ...foundQuestion.options,
          {
            id: optionId,
            is_optional: isOptional,
            name: optionName,
            answers: [
              {
                id: answId,
                value: answValue,
              },
            ],
          },
        ]
      }
    }
    return newAnswer
  })
}

export const checkboxButtonsAnswer = (
  questionId: number,
  questionType: string,
  optionName: string,
  optionId: number,
  isOptional: boolean,
  answId: number,
  answValue: string,
  setAnswersList: React.Dispatch<React.SetStateAction<FormAnswers>>,
  answerListId: number[],
  questions: Content[],
  isChecked: boolean
) => {
  if (!isChecked) {
    setAnswersList(prev => {
      const newAnswer = { ...prev }
      const foundQuestion = newAnswer.answers_list.find(
        question => question.id === questionId
      )

      if (!newAnswer.answers_list.length || !foundQuestion) {
        newAnswer.answers_list = [
          ...newAnswer.answers_list,
          {
            id: questionId,
            type: questionType,
            options: [
              {
                id: optionId,
                is_optional: isOptional,
                name: optionName,
                answers: [
                  {
                    id: answId,
                    value: answValue,
                  },
                ],
              },
            ],
          },
        ]
      } else {
        questions.forEach(ques => {
          ques.options.forEach(opt => {
            if (opt.id === optionId) {
              const foundQuestion = newAnswer.answers_list.find(
                el => el.id === questionId
              )
              if (!foundQuestion) {
                newAnswer.answers_list = [
                  ...newAnswer.answers_list,
                  {
                    id: questionId,
                    type: questionType,
                    options: [
                      {
                        id: optionId,
                        is_optional: isOptional,
                        name: optionName,
                        answers: [
                          {
                            id: answId,
                            value: answValue,
                          },
                        ],
                      },
                    ],
                  },
                ]
              } else {
                answerListId.forEach(elemId => {
                  newAnswer.answers_list.forEach(el => {
                    const findOpt = foundQuestion.options.find(
                      opt => opt.id === optionId
                    )
                    if (!findOpt) {
                      if (el.id === questionId) {
                        el.options = [
                          ...el.options,
                          {
                            id: optionId,
                            is_optional: isOptional,
                            name: optionName,
                            answers: [
                              {
                                id: answId,
                                value: answValue,
                              },
                            ],
                          },
                        ]
                      }
                    } else {
                      const findAnsw = findOpt.answers.find(
                        el => el.id === answId
                      )
                      if (!findAnsw) {
                        if (elemId === answId) {
                          return (findOpt.answers = [
                            ...findOpt.answers,
                            {
                              id: answId,
                              value: answValue,
                            },
                          ])
                        }
                      } else {
                        findAnsw.value = answValue
                      }
                    }
                  })
                })
              }
            }
          })
        })
      }
      return newAnswer
    })
  } else {
    setAnswersList(prev => {
      const newAnswer = { ...prev }
      let foundOption = newAnswer.answers_list
        .find(question => question.id === questionId)
        ?.options.find(option => option.id === optionId)
      if (foundOption) {
        const foundAnswers = foundOption.answers.filter(
          answer => answer.id !== answId
        )
        foundOption.answers = foundAnswers
      }
      return newAnswer
    })
  }
}

export const inputAnswer = (
  value: string,
  questionId: number,
  questionType: string,
  optionId: number,
  isOptional: boolean,
  optionName: string,
  answId: number,
  setAnswersList: React.Dispatch<React.SetStateAction<FormAnswers>>,
  answersList: FormAnswers
) => {
  if (optionName === PHONE) value = formatPhoneNumber(value) ?? ''
  if (optionName === ZIP_CODE)
    value = inputFormatting(value, 5)?.match(/\d/g)?.join('') ?? ''

  setAnswersList(prev => {
    const newAnswer = { ...prev }
    const foundQuestion = answersList.answers_list.find(
      question => question.id === questionId
    )
    if (answersList.answers_list.length === 0 || !foundQuestion) {
      newAnswer.answers_list = [
        ...newAnswer.answers_list,
        {
          id: questionId,
          type: questionType,
          options: [
            {
              id: optionId,
              is_optional: isOptional,
              name: optionName,
              answers: [
                {
                  id: answId,
                  value: value,
                },
              ],
            },
          ],
        },
      ]
    } else {
      const foundOption = foundQuestion.options.find(
        option => option.id === optionId
      )
      if (foundOption) {
        const foundAnswer = foundOption.answers.find(
          answer => answer.id === answId
        )
        if (foundAnswer) {
          foundAnswer.value = value
        } else {
          foundOption.answers = [
            {
              id: answId,
              value: value,
            },
          ]
        }
      } else {
        foundQuestion.options = [
          ...foundQuestion.options,
          {
            id: optionId,
            is_optional: isOptional,
            name: optionName,
            answers: [
              {
                id: answId,
                value: value,
              },
            ],
          },
        ]
      }
    }
    return newAnswer
  })
}

export const sumLengthQuest = (data: Page[]) => {
  const sum = (prev: number, curr: number) => prev + curr

  const ques = data.map((page: Page) => page.content.length)
  return ques.reduce(sum, 0)
}

export const pageOptionsAndAnswers = (
  page: Page,
  answers: FormAnswers,
  setMatching: React.Dispatch<React.SetStateAction<MatchingAnswers>>,
  filteredOptions?: string[]
) => {
  const optionsCount = page?.content
    .map(question => {
      let counter = 0
      question.options.forEach(option => {
        if (!option.is_optional)
          filteredOptions?.length
            ? filteredOptions.includes(option.name) && counter++
            : counter++
      })
      return counter
    })
    .reduce((sum, answer) => sum + answer, 0)

  const answersCount = answers.answers_list
    ?.filter(savedQuestion =>
      page.content.find(question => question.id === savedQuestion.id)
    )
    ?.map(question => {
      let optionCount = 0
      question.options.forEach(option => {
        filteredOptions?.length &&
          !filteredOptions?.includes(option.name) &&
          optionCount--
        if (!option.is_optional) {
          question.type === 'input'
            ? !!option?.answers[0].value && optionCount++
            : option?.answers.length && optionCount++
        }
      })
      return optionCount
    })
    .reduce((sum, answer) => sum + answer, 0)

  setMatching({
    optionsCounter: optionsCount,
    answersCounter: answersCount,
  })
}

export const debounceFunc = _.debounce(inputAnswer, 200)

export const validationInput = (value: ValuesForm, page?: number) => {
  const regexEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const regexPhone = /^(?:\+?1)?\(?(\d{3})\)?[ -]?(\d{3})[ -]?(\d{4})$/
  const trimFirstName = value?.first_name?.trim()
  const trimLastName = value?.last_name?.trim()
  const firstNameLength = trimFirstName?.length
  const lastNameLength = trimLastName?.length
  if (Object.keys(value).length) {
    const errors: ErrorForms = {}
    if (
      !regexEmail.test(String(value.email).toLowerCase()) &&
      value.email?.length
    ) {
      errors[EMAIL] = 'Please enter a valid email'
    }
    if (
      !regexPhone.test(String(value.phone).toLowerCase()) &&
      value.phone?.length
    ) {
      errors[PHONE] = 'Please enter a valid phone number'
    }
    if (firstNameLength !== undefined && value?.first_name) {
      if (
        (firstNameLength && firstNameLength > 50) ||
        (!trimFirstName && firstNameLength < 50)
      ) {
        errors[F_NAME] = 'Please enter a valid first name, maximum 50 letters'
      }
    }
    if (lastNameLength !== undefined && value?.last_name) {
      if (
        (lastNameLength && lastNameLength > 50) ||
        (!trimLastName && lastNameLength < 50)
      ) {
        errors[L_NAME] = 'Please enter a valid last name, maximum 50 letters'
      }
    }
    if (value.zip_code) {
      if (value?.zip_code.length !== 5) {
        errors[ZIP_CODE] = 'Please enter a valid zip code'
      }
    }

    if (value.input) {
      const findOption = value.input.find(input =>
        value.currOptionsId.includes(input.optionId)
      )
      if (findOption && findOption.value.length > 200) {
        const newError = {
          optionId: findOption.optionId ?? -1,
          value: 'Please, enter maximum 200 letters',
        }
        if (errors.input) {
          errors.input.push(newError)
        } else errors.input = [newError]
      }
    }
    return errors
  }
}

export const answersFilter = (
  filterOptions: string[] | undefined,
  setAnswersList: React.Dispatch<React.SetStateAction<FormAnswers>>,
  saveAnswers: (answers: FormAnswers) => void,
  buttonActive?: boolean
) => {
  setAnswersList(prev => {
    const filteredQuestion = prev.answers_list.map(savedAnswers => {
      const filteredSavedOptions = savedAnswers.options.filter(option => {
        return buttonActive
          ? option.answers.length > 0
          : filterOptions?.includes(option.name)
      })
      return {
        ...savedAnswers,
        options:
          filteredSavedOptions.length > 0
            ? filteredSavedOptions
            : savedAnswers.options,
      }
    })
    saveAnswers({
      ...prev,
      answers_list: filteredQuestion,
    })
    return {
      ...prev,
      answers_list: filteredQuestion,
    }
  })
}
