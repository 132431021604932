import React from 'react'
import './buttonAsText.scss'

export const ButtonAsText = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
) => {
  return (
    <button
      onClick={props.onClick}
      className="button-as-text"
      data-testid="button-as-text"
    >
      {props.children}
    </button>
  )
}
