import React, { useContext } from 'react'
import { ChartStatistic } from '../../../atoms/ChartStatistic/ChartStatistic'
import { ProgressCircle } from '../../../atoms/ProgressCircles/ProgressCircle'
// import { MissedQestions } from '../Quiz-result-missed-questions' ////TODO  await API
import './quiz-result-progress.scss'
import { Context } from '../../../Context'
import { colorCalculator } from '../../../../utils/scripts/colorCalculator'

interface Props extends React.HTMLAttributes<HTMLElement> {}

export const QuizResultProgress = (props: Props) => {
  const context = useContext(Context)
  const color = context.challengeResult
    ? colorCalculator.color(context.challengeResult.score, false)
    : null
  const better = context.metrics ? context.metrics.better_score : 0

  return (
    <>
      <div className={`quiz-container ${props.className ?? ''}`}>
        <p className="quiz-container__title-status">Congrats, you passed!</p>
        <ProgressCircle
          className="quiz-container__progress"
          result={context.challengeResult}
        />
      </div>

      <div
        className={`quiz-container__compare-wrapper ${props.className ?? ''}`}
      >
        <h3 className="quiz-container__compare-title">How you compare</h3>
        <p className="quiz-container__compare-description">
          You scored higher than
          <span className={`quiz-container__percient ${color?.classScore}`}>
            {' '}
            {Math.round(better) + '%'}
          </span>{' '}
          of the people who took the quiz.
        </p>
        <ChartStatistic className="quiz-container__graph-wrapper" />
      </div>
    </>
  )
}
