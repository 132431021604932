import React, { useEffect, useState } from 'react'
import {
  TabsContainer,
  DatabaseGroup,
  SearchResult,
} from '../../../../components/organism'
import { getListOfWorkers } from '../../../../services/employerAPI/employer'
import { useTypedSelector } from '../../../../utils/hooks'
import {
  DASHBOARD_PAGE,
  FilterType,
  IntervewData,
  ListOfWorkers,
  UserCard,
} from '../../../../models'
import { gridItemsPlacement } from './scripts'
import { EmployeesListContext } from '../../../../components/Context/EmployeesListContext'
import {
  useAppDispatch,
  setIsBackBlur,
  setIsNavSearchHover,
  setIsTopFilterVisible,
  defaultFilter,
  setFilters,
  setTopFilterHeight,
} from '../../../../redux'
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
} from 'react-query'
import { EmployerQueries } from '../../../../utils/constants'
import { EmployerDashboardUI } from './EmployerDashboardUI'
import { BetaToggle } from '../../../../components/organism/Employer/BetaToggle/BetaToggle'

export interface ModalData {
  user: UserCard
  modalName: string
}

const gridProps = {
  gap: 10,
  cardWidth: 302,
}

export const EmployerDashboard = () => {
  const dispatch = useAppDispatch()
  const cardsListRef = React.useRef<HTMLDivElement>(null)
  const filters = useTypedSelector(state => state.employer.filters)
  const filterType = useTypedSelector(
    state => state.employer.employerFilterType
  )
  const navbarHeight = useTypedSelector(state => state.variables.navbarHeight)
  const topFilterHeight = useTypedSelector(
    state => state.variables.topFilterHeight
  )
  const isNavSearchHover = useTypedSelector(
    state => state.employer.isNavSearchHover
  )
  const isSearchHistoryOpen = useTypedSelector(
    state => state.employer.isBackBlur
  )
  const isTopFilterVisible = useTypedSelector(
    state => state.employer.isTopFilterVisible
  )

  const [isBetaToggle, setIsBetaToggle] = useState<boolean>(true)
  const [activeModal, setActiveModal] = useState<ModalData>()
  const [successActiveModal, setSuccessActiveModal] = useState<ModalData>()
  const [isSuccessModal, setIsSuccessModal] = useState<boolean>(false)
  const [isLastCardLoading, setCardLoading] = useState(false)
  const [currentInterviewData, setCurrentInterviewData] =
    useState<IntervewData>()
  const requiredParams = 'You have not provided all required parameters'
  const roles = filters.role?.map(role => role.id) ?? null
  const language = filters.language?.map(language => language.id) ?? null
  const skills =
    filters?.skills?.map(skill => {
      if (skill.exact) {
        return { ...skill, value: skill.value.id }
      } else return { ...skill, value: skill.value.id }
    }) ?? null
  const trainingCertifications =
    filters?.training_certifications?.map(training => training.id) ?? null
  const osha = filters?.osha_status?.map(osha => osha.id) ?? null
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const {
    data: listOfWorkers,
    refetch: refetchWorkerList,
    isLoading,
    error: listOfWorkersError,
  }: {
    data: ListOfWorkers | undefined
    refetch: <TPageData>(
      options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<ListOfWorkers | undefined, Error | null>>
    isLoading: boolean
    error: Error | null
  } = useQuery([EmployerQueries.ListOfWorkers, filters], () =>
    getListOfWorkers(
      {
        ...filters,
        role: roles,
        skills: skills,
        language: language,
        training_certifications: trainingCertifications,
        osha_status: osha,
      },
      currentTimezone
    )
  )

  const [listCounter, setListCounter] = useState<number | null>(null)
  const [invitedRequests, setInvitedRequests] = useState<number[]>([])
  const [totalPages, setTotalPages] = useState(listOfWorkers?.total_pages)
  const tabData = [
    {
      id: 1,
      tab: 'dbGroup',
      component: <DatabaseGroup activeItem={filters.type.type} />,
    },
    {
      id: 2,
      tab: 'searchResult',
      component: <SearchResult />,
    },
  ]

  const betaToggleHeight = isBetaToggle ? 50 : 0

  const handlePageChange = (page: number) => {
    dispatch(setFilters({ ...filters, page_number: page }))
  }

  const modalAction = (modal: ModalData) => {
    setActiveModal(modal)
    setSuccessActiveModal(modal)
  }

  const backBlurAction = () => {
    dispatch(setIsNavSearchHover(false))
    dispatch(setIsTopFilterVisible(false))
    dispatch(setIsBackBlur(false))
  }

  useEffect(() => {
    localStorage.setItem(
      DASHBOARD_PAGE,
      JSON.stringify({ page: filters.page_number })
    )
    sessionStorage.setItem('filters', JSON.stringify(filters))
  }, [filters])

  useEffect(() => {
    if (filters.type.type === FilterType.search) {
      window.scrollTo(0, 0)
    }
    window.scrollTo(0, 0)
  }, [filters.page_number, filters.type.type])

  useEffect(() => {
    listOfWorkers && setTotalPages(listOfWorkers.total_pages)

    const resize = () => {
      if (cardsListRef.current) {
        const gridStyles = gridItemsPlacement(
          cardsListRef,
          gridProps.cardWidth,
          gridProps.gap,
          listCounter ?? 0
        )
        cardsListRef.current.style.justifyContent = gridStyles
      }
    }
    window.addEventListener('resize', resize)
    if (cardsListRef.current && listOfWorkers) {
      cardsListRef.current.style.justifyContent = gridItemsPlacement(
        cardsListRef,
        gridProps.cardWidth,
        gridProps.gap,
        listCounter ?? 0
      )
    }
    return () => window.removeEventListener('resize', resize)
  }, [listOfWorkers, listCounter, isLoading])

  useEffect(() => {
    if (listOfWorkersError?.message === requiredParams) {
      sessionStorage.removeItem('filters')
      sessionStorage.setItem('filters', JSON.stringify(defaultFilter))
      dispatch(setFilters(defaultFilter))
    }
  }, [listOfWorkersError?.message, dispatch])

  useEffect(() => {
    listOfWorkers &&
      listOfWorkers.employees_list.length > 0 &&
      setListCounter(listOfWorkers.employees_list.length)
  }, [listOfWorkers])

  useEffect(() => {
    return () => {
      dispatch(setIsNavSearchHover(false))
      dispatch(setIsTopFilterVisible(false))
      dispatch(setIsBackBlur(false))
      dispatch(setTopFilterHeight(0))
    }
  }, [dispatch])

  return (
    <EmployeesListContext.Provider
      value={{
        workersList: listOfWorkers ?? null,
        counter: listCounter,
        setCounter: setListCounter,
        totalPages,
        refreshList: refetchWorkerList,
        setCardLoading,
      }}
    >
      {isBetaToggle && <BetaToggle setIsBetaToggle={setIsBetaToggle} />}
      <TabsContainer
        disableTabNames
        tabData={tabData}
        activeTab={filters.type.type === FilterType.search ? 2 : 1}
        className={'employer-dashboard__tab-container'}
        style={{
          top: `${topFilterHeight + navbarHeight + betaToggleHeight}px`,
        }}
      />

      <EmployerDashboardUI
        activeModal={activeModal}
        setActiveModal={setActiveModal}
        setIsSuccessModal={setIsSuccessModal}
        setInvitedRequests={setInvitedRequests}
        isSuccessModal={isSuccessModal}
        successActiveModal={successActiveModal}
        currentInterviewData={currentInterviewData}
        setCurrentInterviewData={setCurrentInterviewData}
        filters={filters}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        listOfWorkers={listOfWorkers}
        cardsListRef={cardsListRef}
        gridProps={gridProps}
        modalAction={modalAction}
        invitedRequests={invitedRequests}
        filterType={filterType}
        isLoading={isLoading}
        isLastCardLoading={isLastCardLoading}
        backBlurAction={backBlurAction}
        isActiveBackBlur={
          isNavSearchHover || isSearchHistoryOpen || isTopFilterVisible
        }
        isBetaToggle={isBetaToggle}
      />
    </EmployeesListContext.Provider>
  )
}
