import {
  FormAnswers,
  Forms,
  FormToken,
  IChoiceListForWorker,
  ILocationInfoOB,
  ImmediateCall,
  ImmediateCallOnboarding,
  InterviewerModel,
  IObRefCompanyDataType,
  ILastPageOB,
  ITradesOB,
  IWorkerInfoOB,
  TUploadResume,
  TRequestHomeLocation,
} from '../../models'
import { Config } from '../../components/organism/General/UploadFiles'
import { API, API_NO_TOKEN } from '../API'
import { BACKEND_URL } from '../config'
import { responseHandler } from '../responseHandlers'

export const getFormsList = async (token: string): Promise<Forms> => {
  return responseHandler(
    API_NO_TOKEN.post(`${BACKEND_URL}api/survey/start-onboarding-survey/`, {
      survey_token: token,
    })
  )
}

export const postAsnwers = async (data: FormAnswers): Promise<any> => {
  return responseHandler(
    API_NO_TOKEN.post(`${BACKEND_URL}api/survey/send-block/`, data)
  )
}

export const getFormsToken = async (): Promise<FormToken> => {
  return responseHandler(
    API_NO_TOKEN.get(`${BACKEND_URL}api/survey/initialize-survey/?survey_id=1`)
  )
}

export const getImmediateCall = async (
  uuid: string,
  timezone: string,
  event_type: 'phone' | 'inperson' | null,
  type: 'specific_job' | null,
  job_id: string | null
): Promise<ImmediateCall> => {
  return API_NO_TOKEN.get(
    `${BACKEND_URL}api/slots/immediate-call${type ? '?type=' + type : ''}${
      event_type ? '?event_type=' + event_type : ''
    }${job_id ? '?job_id=' + job_id : ''}&timezone=${timezone}&email=${uuid}`
  )
}

export const getImmediateCallOnboarding = async (
  uuid: string,
  timezone: string,
  type: 'onboarding' | null
): Promise<ImmediateCallOnboarding> => {
  return API_NO_TOKEN.get(
    `${BACKEND_URL}api/slots/immediate-call${
      type ? '?type=' + type : ''
    }&timezone=${timezone}&email=${uuid}`
  )
}

export const getInterviewerData = async (): Promise<InterviewerModel> => {
  return responseHandler(API.get(`${BACKEND_URL}api/users/skillit-info/`))
}

export const sendFirstBlockNotefication = async (
  survey_token: string | null
): Promise<any> => {
  return responseHandler(
    API_NO_TOKEN.get(
      `${BACKEND_URL}api/survey/send-first-block-notification/?survey_token=${survey_token}`
    )
  )
}

export const startAuthenticatedOB = () => {
  return responseHandler(API.post('api/onboarding/start-authenticated/'))
}

export const getWorkerInfoOB = (): Promise<IWorkerInfoOB> => {
  return responseHandler(API.get('api/onboarding/state/'))
}

export const updatePageStateOB = (data: ILastPageOB) => {
  return responseHandler(API.post('api/onboarding/update-state/', data))
}

export const completedOnboarding = () => {
  return responseHandler(API.get('api/onboarding/complete/'))
}

export const appliedOnboarding = () => {
  return responseHandler(API.get('api/onboarding/complete-application/'))
}

export const uploadResumeWithSovren = async (
  publicData: FormData,
  config?: Config
): Promise<TUploadResume> => {
  return responseHandler(
    API.post('api/users/upload-resume/', publicData, config ?? undefined),
    true
  )
}

export const clearIncompleteExperience = () => {
  return responseHandler(
    API.get('api/onboarding/delete-incomplete-experience/')
  )
}

export const updateLocationInfoOB = async (
  data: ILocationInfoOB | TRequestHomeLocation
): Promise<ILocationInfoOB> => {
  return responseHandler(API.put('api/profile/update-location-info/', data))
}

export const updateReferencesInfoOB = async (
  data: IObRefCompanyDataType[]
): Promise<IObRefCompanyDataType[]> => {
  return responseHandler(API.put('api/profile/update-referees-info/', data))
}

export const updateRolesOB = async (data: ITradesOB): Promise<any> => {
  return responseHandler(API.post('api/onboarding/roles/', data))
}

export const companiesAutocompleteOB = async (
  value: string
): Promise<string[]> => {
  return responseHandler(
    API.post('api/onboarding/companies-autocomplete/', { value })
  )
}

export const updateCustomJobs = () => {
  return responseHandler(API.get('api/onboarding/custom-jobs/'))
}

export const getChoiceListsForWorker = (): Promise<IChoiceListForWorker> => {
  return responseHandler(API.get('api/onboarding/choices/'))
}

export const getTokenByInviteUrl = async (
  token: string
): Promise<{ token: string }> => {
  return responseHandler(
    API_NO_TOKEN.post('api/admin-portal/invite/validate-token/', {
      token: token,
    })
  )
}
