import React, { useState } from 'react'
import { useStateWithDep } from '../../../../utils/hooks'
import { Input } from '../../inputs/Input'
import plus from '../../../../assets/icons/active-icons/plus.svg'
import save from '../../../../assets/icons/active-icons/save.svg'
import remove from '../../../../assets/icons/active-icons/remove.svg'
import { v4 as uuidv4 } from 'uuid'
import './customOptSelect.scss'
import {
  deletePosition,
  savePosition,
} from '../../../../services/employerAPI/employer'
import { Loader } from '../../Loader'
import { ErrorMessageWithCross } from '../../ErrorMessages'

export interface CustomOption {
  id: number | string
  position: string
}

interface Props extends React.HTMLAttributes<HTMLElement> {
  elements: CustomOption[]
  selectedElement: CustomOption | null
  getSelectedElement: (element: CustomOption | null) => void
  required?: true
  error?: string
  addButtonName: string
  setElements: React.Dispatch<React.SetStateAction<CustomOption[]>>
}

export const CustomOptSelect = (props: Props) => {
  const {
    placeholder,
    elements,
    selectedElement = props.selectedElement,
    getSelectedElement = props.getSelectedElement,
    required,
    error,
    addButtonName,
    setElements,
  } = { ...props }

  const [opened, setOpened] = useState(false)
  const [fieldToAdd, setFieldToAdd] = useState(false)
  const [newOption, setNewOption] = useState('')
  const [deletePrompt, setDeletePrompt] = useState<CustomOption>()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedItemState, setSelectedItemState] =
    useStateWithDep<CustomOption | null>(selectedElement)

  const isLimitReached = elements.length === 11

  const onClickDropDown = () => {
    setOpened(!opened)
    setFieldToAdd(false)
    setDeletePrompt(undefined)
  }

  const onSelect = (event: any, id: number | string, element: string) => {
    event.stopPropagation()
    setSelectedItemState({ id: id, position: element })
    setOpened(false)
    setFieldToAdd(false)
    setDeletePrompt(undefined)
    getSelectedElement({ id: id, position: element })
  }

  const addToList = () => {
    if (newOption.trim()) {
      setIsLoading(true)
      savePosition({
        id: `${uuidv4()}${Math.random}`,
        position: newOption,
      }).then(res => {
        setIsLoading(false)
        setElements(prev => [...prev, { id: res.id, position: res.position }])
      })
      setNewOption('')
    }
  }

  const openPropmpt = (opt: CustomOption) => {
    setDeletePrompt(opt)
  }

  const removeFromList = () => {
    if (deletePrompt?.id === selectedItemState?.id) {
      setSelectedItemState(null)
    }
    setElements(props.elements.filter(item => item.id !== deletePrompt?.id))
    changeForcus()
    deletePosition(deletePrompt!)
  }

  const keepAction = () => {
    setDeletePrompt(undefined)
    changeForcus()
  }

  const changeForcus = () => {
    const input = document.querySelector('#custom-opt-select__inp')
    const addBtn = document.querySelector('.custom-opt-select__add-new-opt')
    if (fieldToAdd) {
      input instanceof HTMLElement && input?.focus()
    } else {
      addBtn instanceof HTMLElement && addBtn?.focus()
    }
  }

  return (
    <div className={`custom-opt-select ${props.className ?? ''}`}>
      <div className="custom-opt-select__label"></div>
      <div
        tabIndex={0}
        onBlur={() => setOpened(false)}
        className="custom-opt-select__wrapper"
      >
        <div onClick={onClickDropDown} className="custom-opt-select__input">
          <div className="custom-opt-select__placeholder">
            {!selectedItemState && placeholder}
          </div>
          <div className="custom-opt-select__selected-list-wrapper">
            <ul className="custom-opt-select__selected-list">
              <li className="custom-opt-select__selected-item">
                {selectedItemState && selectedItemState.position}
              </li>
            </ul>
          </div>
          <div
            className={`custom-opt-select__input-drop-down-icon${
              opened ? ' custom-opt-select__input-drop-down-icon_rotate' : ''
            }`}
          ></div>
        </div>
        <div
          id="custom-opt-select__list-wrapper"
          className={`custom-opt-select__list-wrapper${
            !opened ? ' custom-opt-select__list_hidden' : ''
          }`}
        >
          <ul className="custom-opt-select__list">
            {elements.map(item => {
              return (
                <li
                  id={`${item.id}`}
                  key={item.id}
                  className={`custom-opt-select__item${
                    selectedItemState && selectedItemState.id === item.id
                      ? ' custom-opt-select__item_selected'
                      : ''
                  }`}
                  onClick={event => onSelect(event, item.id, item.position)}
                >
                  <p>{item.position}</p>
                  <img
                    className={`custom-opt-select__item-img ${
                      deletePrompt?.id === item.id
                        ? 'custom-opt-select__item_active'
                        : ''
                    }`}
                    onClick={e => {
                      openPropmpt(item)
                      e.stopPropagation()
                    }}
                    tabIndex={0}
                    onFocus={() => setOpened(true)}
                    src={remove}
                    alt="remove-icon"
                  />

                  {deletePrompt?.id === item.id && (
                    <div
                      tabIndex={0}
                      onFocus={() => setOpened(true)}
                      onClick={e => e.stopPropagation()}
                      className="custom-opt-select__delete-prompt"
                    >
                      <p> Are you sure you want to delete this position?</p>
                      <span
                        onClick={removeFromList}
                        className="custom-opt-select__delete-btn"
                      >
                        Delete
                      </span>
                      <span
                        onClick={keepAction}
                        className="custom-opt-select__keep-btn"
                      >
                        Keep
                      </span>
                    </div>
                  )}
                </li>
              )
            })}

            {isLoading && <Loader />}
            {!fieldToAdd || isLimitReached ? (
              <div
                tabIndex={0}
                onFocus={() => setOpened(true)}
                onClick={() => {
                  !isLimitReached && setFieldToAdd(true)
                }}
                className={`custom-opt-select__add-new-opt ${
                  isLimitReached ? 'custom-opt-select__add-new-opt_disable' : ''
                }`}
              >
                <img src={plus} alt="add-icon" /> {addButtonName}
              </div>
            ) : (
              <div className="custom-opt-select__add-input">
                <Input
                  id="custom-opt-select__inp"
                  onFocus={() => setOpened(true)}
                  tabIndex={0}
                  value={newOption}
                  onChange={e => setNewOption(e.target.value)}
                  placeholder="Enter Position Name"
                />
                <div
                  tabIndex={0}
                  onFocus={() => setOpened(true)}
                  onClick={addToList}
                  className="custom-opt-select__add-btn"
                >
                  <img src={save} alt="save-icon" />
                  Save
                </div>
              </div>
            )}
          </ul>
        </div>

        {!selectedItemState && required && error && (
          <ErrorMessageWithCross
            className="custom-opt-select__error-message"
            textError={error}
          />
        )}
      </div>
    </div>
  )
}
