import { useCallback } from 'react'
import {
  Config,
  UploadFiles,
} from '../../../../../../../components/organism/General/UploadFiles'
import {
  TypeResume,
  workWithTypeData,
} from '../../../../../../../components/organism/General/UploadFiles/DataTypes/DataTypes'
import imgEye from '../../../../../../../assets/icons/active-icons/eye.svg'
import './driversLicense.scss'
import { useQueryClient, UseMutationResult } from 'react-query'
import { DrivingInfo } from '../../../../../../../models'
import {
  ProfileAnalyticsName,
  WorkerQueries,
} from '../../../../../../../utils/constants'
import { onTrackingActions } from '../../../../../../../utils/scripts'
import { toast } from 'react-toastify'
import { ERROR_SOMETHING } from '../../../../../../../services/auth-service'

interface Props {
  license?: string | null
  drivingInfoMutation: UseMutationResult<
    DrivingInfo,
    unknown,
    {
      formData: FormData
      config?: Config
    },
    unknown
  >
}

export const DriversLicense = (props: Props) => {
  const { license, drivingInfoMutation } = props
  const queryClient = useQueryClient()

  const drivingInfoMutate = useCallback(
    (formData: FormData, config?: Config) => {
      let response: DrivingInfo | Error
      drivingInfoMutation.mutate(
        { formData, config },
        {
          onSuccess: (updatedData: DrivingInfo) => {
            onTrackingActions(ProfileAnalyticsName.profile_edited)
            queryClient.setQueryData(WorkerQueries.drivingInfo, updatedData)
            response = updatedData
          },
          onError: err => {
            if (err instanceof Error) {
              response = err
              toast.error(ERROR_SOMETHING)
            }
          },
        }
      )
      return new Promise(_ => response)
    },
    [drivingInfoMutation, queryClient]
  )

  return (
    <div className="container drivers-license" data-testid="drivers-license">
      <h3>Drivers License</h3>
      <div className="drivers-license__prompt">
        <img src={imgEye} alt="eye" />
        <p>
          Visible only to you and Skillit until an offer is made. This is only
          used for employment verification.
        </p>
      </div>
      <UploadFiles
        className="public-profile__resume"
        accept={workWithTypeData.resumeSupportTypes().toString()}
        updatedFile={license ?? undefined}
        description=""
        buttonName="Upload Drivers License"
        typeData={TypeResume}
        uploadButtonText="Change/Delete File"
        modalTitleTxt="File"
        modalUploadTxt="File"
        modalRemoveTxt="Current File"
        formDataName="drivers_license"
        updateAction={drivingInfoMutate}
      />
    </div>
  )
}
