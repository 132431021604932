import React from 'react'
import { Helmet } from 'react-helmet'
import { BackBlur, CandidatesCounter } from '../../../../components/atoms'
import {
  LoadingContent,
  ModalBox,
  Pagination,
  SearchResultlist,
  WorkerCard,
} from '../../../../components/molecules'
import {
  EmployeeFilter,
  InterviewFormat,
  SuccessMessageModal,
} from '../../../../components/organism'
import {
  EmpActionType,
  FilterType,
  IntervewData,
  ListOfWorkers,
  UserCard,
  WorkersFilter,
} from '../../../../models'
import { EFilterTypes } from '../../../../redux'
import { ModalData } from './EmployerDashboard'
import emptyList from '../../../../assets/icons/other/buble_img.svg'

import './employerDashboard.scss'

interface Props {
  activeModal?: ModalData
  setActiveModal: React.Dispatch<React.SetStateAction<ModalData | undefined>>
  setIsSuccessModal: React.Dispatch<React.SetStateAction<boolean>>
  setInvitedRequests: React.Dispatch<React.SetStateAction<number[]>>
  isSuccessModal: boolean
  successActiveModal?: ModalData
  currentInterviewData?: IntervewData
  setCurrentInterviewData: React.Dispatch<
    React.SetStateAction<IntervewData | undefined>
  >
  filters: WorkersFilter
  totalPages?: number
  handlePageChange: (page: number) => void
  listOfWorkers?: ListOfWorkers
  cardsListRef: React.RefObject<HTMLDivElement>
  gridProps: {
    gap: number
    cardWidth: number
  }
  modalAction: (modal: ModalData) => void
  invitedRequests: number[]
  filterType: EFilterTypes.groupFilterType
  isLoading: boolean
  isLastCardLoading: boolean
  backBlurAction: () => void
  isActiveBackBlur: boolean
  isBetaToggle: boolean
}

export const EmployerDashboardUI = (props: Props) => {
  const {
    activeModal,
    setActiveModal,
    setIsSuccessModal,
    setInvitedRequests,
    isSuccessModal,
    successActiveModal,
    currentInterviewData,
    setCurrentInterviewData,
    filters,
    totalPages,
    handlePageChange,
    listOfWorkers,
    cardsListRef,
    gridProps,
    modalAction,
    invitedRequests,
    filterType,
    isLoading,
    isLastCardLoading,
    backBlurAction,
    isActiveBackBlur,
    isBetaToggle,
  } = props
  return (
    <div
      className={`employer-dashboard employer-container ${
        isBetaToggle ? 'employer-dashboard--is-beta-toggle' : ''
      }`}
    >
      <Helmet>
        <title>Skillit - Employer Dashboard</title>
      </Helmet>
      {/* ///TODO this code will be needed next build */}

      {/* {activeModal?.modalName === EmpActionType.assessment && (
        <ModalBox
          onCloseModal={() => setActiveModal(undefined)}
          visibleCloseIcon
        >
          <AssessmentModal
            closeModal={setActiveModal}
            userData={activeModal?.user}
          />
        </ModalBox>
      )} */}

      {activeModal?.modalName === EmpActionType.interview && (
        <ModalBox
          onCloseModal={() => setActiveModal(undefined)}
          visibleCloseIcon
        >
          <InterviewFormat
            userData={activeModal?.user}
            setActiveModal={setActiveModal}
            setIsSuccessModal={setIsSuccessModal}
            setInvitedRequests={setInvitedRequests}
          />
        </ModalBox>
      )}

      {/* {activeModal?.modalName === EmpActionType.makeOffer && (
        <ModalBox
          onCloseModal={() => setActiveModal(undefined)}
          visibleCloseIcon
        >
          <MakeOfferModal
            userData={activeModal?.user}
            setActiveModal={setActiveModal}
            setIsSuccessModal={setIsSuccessModal}
            selectData={selectData}
            setSelectData={setSelectData}
            benchmark={benchmark ?? []}
            companyName={employerInfo?.company_info.name ?? ''}
          />
        </ModalBox>
      )} */}

      {isSuccessModal && (
        <ModalBox
          className="employer-dashboard__success-modal"
          onCloseModal={() => setIsSuccessModal(false)}
          visibleCloseIcon
        >
          <SuccessMessageModal
            workerData={successActiveModal?.user ?? null}
            employerDashboardPage
            onCloseSuccessModal={() => {
              setIsSuccessModal(false)
              currentInterviewData && setCurrentInterviewData(undefined)
            }}
            interviewData={currentInterviewData}
          />
        </ModalBox>
      )}

      <div className="employer-dashboard__search-filter-result">
        {filters.type.type === FilterType.search && <SearchResultlist />}
      </div>
      <main className="employer-dashboard__main">
        <BackBlur
          isActive={isActiveBackBlur}
          setIsActive={() => backBlurAction()}
        />
        <aside className="employer-dashboard__sidebar">
          <EmployeeFilter />
        </aside>
        <section className="employer-dashboard__content">
          <LoadingContent isLoading={isLoading || isLastCardLoading}>
            {!!totalPages ? (
              <>
                <div className="employer-dashboard__pagination-wrapper">
                  <Pagination
                    currentPage={filters.page_number ?? 0}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    className={'employer-dashboard__pagination-top'}
                  />
                  <CandidatesCounter count={listOfWorkers?.total_employees} />
                </div>
                <div
                  className="employer-dashboard__worker-list"
                  ref={cardsListRef}
                  style={{
                    gap: `${gridProps.gap}px`,
                    justifyContent: 'center',
                    gridTemplateColumns: `repeat(auto-fit, ${gridProps.cardWidth}px)`,
                  }}
                >
                  {listOfWorkers &&
                    listOfWorkers?.employees_list.map((card: UserCard) => (
                      <WorkerCard
                        key={card.id}
                        isHideFilterPressed={
                          filters.type.type === FilterType.hidden ? true : false
                        }
                        modalAction={modalAction}
                        userData={card}
                        invitedRequests={invitedRequests}
                        showHiddenUsers={
                          filters.type.type === FilterType.search ||
                          filters.type.type === FilterType.all
                        }
                      />
                    ))}
                </div>
              </>
            ) : (
              <div className="employer-dashboard__empty-list">
                <span className="employer-dashboard__empty-list-text">
                  {filterType === EFilterTypes.groupFilterType
                    ? 'Nothing here yet...'
                    : 'No results matching filter criteria...'}
                </span>
                <img
                  className="employer-dashboard__empty-list-img"
                  src={emptyList}
                  alt="no-result"
                />
              </div>
            )}
          </LoadingContent>

          {!!totalPages && !isLoading && (
            <Pagination
              currentPage={filters.page_number ?? 0}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              className={'employer-dashboard__pagination-bottom'}
            />
          )}
        </section>
      </main>
    </div>
  )
}
