import { useState } from 'react'
import photo1 from '../../../../../assets/img/worker/profile/example-profile-photo-1.png'
import photo2 from '../../../../../assets/img/worker/profile/example-profile-photo-2.png'
import photo3 from '../../../../../assets/img/worker/profile/example-profile-photo-3.png'
import './welcomePageModal.scss'

interface IExamples {
  imgSrc: string
  text: string
}

export const examples: IExamples[] = [
  {
    imgSrc: photo1,
    text: 'I moved into the area and would like to land full time employment with a local contractor. I have been working in rough carpentry for over 15 years. I show up on time, and always do what it takes to get the job done.',
  },
  {
    imgSrc: photo2,
    text: 'Recently I have been working temp work and 1099. I would prefer to be working full time W2 jobs, with benefits for my family. I pay close attention to detail and have been doing that for the 20 years I have worked in finish carpentry.',
  },
  {
    imgSrc: photo3,
    text: 'I have been a supervisor on job sites for 10 years. I started in general labor work 20 years ago and have moved to pipe laying, then started HEO and now I weld as well. Safety is most important to me.',
  },
]

export const WelcomePageModal = () => {
  const [exampleIndex, setExampleIndex] = useState(0)

  return (
    <div className="welcome-modal" data-testid="welcome-modal">
      <div className="welcome-modal__info">
        <div className="welcome-modal__info-photo profile-photo">
          <img
            className="profile-photo__img"
            src={examples[exampleIndex].imgSrc}
            alt="Profile_photo"
          />
        </div>

        <div className="welcome-modal__info-about about">
          <p className="about__quotes">”</p>

          <div className="about__textarea-wrapper">
            <p className="about__textarea">{examples[exampleIndex].text}</p>
          </div>
        </div>
      </div>

      <div className="welcome-modal__examples modal-examples">
        <p className="modal-examples__title">
          Example &nbsp;
          <button
            type="button"
            className="modal-examples__btn"
            hidden={exampleIndex === 0}
            onClick={() => setExampleIndex(prev => prev - 1)}
          >
            {'<'}
          </button>
          &nbsp; {exampleIndex + 1} / {examples.length} &nbsp;
          <button
            type="button"
            className="modal-examples__btn"
            hidden={exampleIndex === 2}
            onClick={() => setExampleIndex(prev => prev + 1)}
          >
            {'>'}
          </button>
        </p>
      </div>
    </div>
  )
}
