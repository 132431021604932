import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import './search.scss'
import {
  clearSearchHistory,
  getRecentSearches,
} from '../../../../services/employerAPI'
import { useTypedSelector } from '../../../../utils/hooks'
import { EFieldProperies, FilterType, RecentSearches } from '../../../../models'
import { useLocation } from 'react-router-dom'
import * as route from '../../../../services/route'
import { LastSearch } from './LastSearch'
import searchIcon from '../../../../assets/icons/active-icons/SearchIcon.svg'
import {
  useAppDispatch,
  setFilters,
  defaultFilter,
  setIsTopFilterVisible,
  setDetailsSearched,
  setIsNavSearchHover,
  setIsBackBlur,
} from '../../../../redux'
import { toast } from 'react-toastify'
import { ERROR_SOMETHING } from '../../../../services/auth-service'
import { useQuery } from 'react-query'
import { EmployerQueries } from '../../../../utils/constants'

export const Search = () => {
  const dispatch = useAppDispatch()
  const searchRef = useRef<HTMLDivElement>(null)
  const isNavSearchHover = useTypedSelector(
    state => state.employer.isNavSearchHover
  )
  const isBackBlur = useTypedSelector(state => state.employer.isBackBlur)
  const filterParams = useTypedSelector(
    state => state.variables.choiceListForEmployer
  )
  const isTopFilterVisible = useTypedSelector(
    state => state.employer.isTopFilterVisible
  )
  const { data: lastSearchData, refetch: lastSearchRefetch } = useQuery(
    EmployerQueries.lastSearch,
    () => getRecentSearches(),
    {
      initialData: [],
      enabled: false,
    }
  )
  const location = useLocation()
  const [isEmpty, setIsEmpty] = useState(false)
  const path = `/${location.pathname.split('/')[1]}`
  const onSubmit = (dataHistory: RecentSearches) => {
    const isRolesFilled: boolean = !!dataHistory.roles?.length
    const rolesFromSearch = dataHistory.roles?.map(role =>
      filterParams.roles?.find(filterRole => filterRole.id === role)
    )

    const skillsFromSearch = dataHistory.skills?.map(skill => ({
      exact: skill.exact,
      value: {
        id: skill.value,
        choice: filterParams.skills?.find(
          skillFromFilters => skillFromFilters.id === skill.value
        )?.choice,
      },
    }))

    dispatch(
      setFilters({
        ...defaultFilter,
        type: {
          type: FilterType.search,
          properties: dataHistory.search_string
            ? EFieldProperies.name
            : isRolesFilled
            ? EFieldProperies.regular
            : null,
        },
        name: dataHistory.search_string,
        role: rolesFromSearch,
        location: dataHistory.zip_code,
        skills: skillsFromSearch,
      })
    )

    if (path === route.workerDetails) {
      dispatch(setDetailsSearched(true))
    } else dispatch(setDetailsSearched(false))
  }

  useEffect(() => {
    if (path !== route.workerDetails) {
      dispatch(setDetailsSearched(false))
    }
  }, [location, dispatch, path])

  useEffect(() => {
    lastSearchData && lastSearchData.length && setIsEmpty(false)
  }, [lastSearchData])

  const onClearSearchHistory = (event: SyntheticEvent) => {
    event.stopPropagation()
    clearSearchHistory().catch(err => toast.error(ERROR_SOMETHING))
    setIsEmpty(true)
  }

  return (
    <div
      className="search"
      ref={searchRef}
      onMouseEnter={() => {
        dispatch(setIsNavSearchHover(true))
        !isTopFilterVisible && lastSearchRefetch()
      }}
      onClick={() => {
        dispatch(setIsNavSearchHover(false))
        dispatch(setIsTopFilterVisible(true))
      }}
      onMouseLeave={() => dispatch(setIsBackBlur(false))}
    >
      <input
        placeholder={
          !isBackBlur && !isTopFilterVisible
            ? 'Start a New Search'
            : isBackBlur
            ? 'Start a New Search'
            : 'Search'
        }
        className="search__input"
        type="text"
        disabled={isTopFilterVisible ? true : false}
      />

      <button className="search__button" type="submit">
        <img src={searchIcon} alt="search" />
      </button>
      <div className="search__footer"></div>

      {isNavSearchHover && !isTopFilterVisible && (
        <LastSearch
          onSubmitFromRecent={onSubmit}
          lastSearch={lastSearchData ?? []}
          parentHeight={searchRef.current?.offsetHeight ?? 0}
          isEmpty={isEmpty}
          setIsEmpty={setIsEmpty}
          onClearSearchHistory={onClearSearchHistory}
          filterParams={filterParams}
        />
      )}
    </div>
  )
}
