import { useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { ModalData } from '..'
import {
  getLastShared,
  getWorkerInfo,
} from '../../../../services/employerAPI/employer'
import { useTypedSelector } from '../../../../utils/hooks'
import './workerDetails.scss'
import {
  AssessmentModal as AssessmentStatuses,
  AssmntStatus,
  LastSharedCoworkerList,
} from '../../../../models/Employer'
import { EmployeeInfoContext } from '../../../../components/Context/EmployeeInfoContext'
import { ICertificationGroup, IntervewData } from '../../../../models'
import {
  useAppDispatch,
  setIsTopFilterVisible,
  setIsNavSearchHover,
  setIsBackBlur,
} from '../../../../redux'
import { EmployerQueries, interviewStatus } from '../../../../utils/constants'
import { useQuery, useQueryClient } from 'react-query'
import { WorkerDetailsUI } from './WorkerDetailsUI'
import { capitalizeFirstLetter } from '../../../../utils/scripts/capitalizeFirstLetter/capitalizeFirstLetter'
import moment from 'moment'
import 'moment-timezone'

export const WorkerDetails = () => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const isNavSearchHover = useTypedSelector(
    state => state.employer.isNavSearchHover
  )
  const isSearchHistoryOpen = useTypedSelector(
    state => state.employer.isBackBlur
  )
  const isTopFilterVisible = useTypedSelector(
    state => state.employer.isTopFilterVisible
  )
  const { unique_url } = useParams<{ unique_url: string }>()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const isInstantSearch = params.has('instant_search')
  const [activeModal, setActiveModal] = useState<ModalData>()
  const [isSuccessModal, setIsSuccessModal] = useState<boolean>(false)
  // eslint-disable-next-line
  const [currentInterviewData, setCurrentInterviewData] =
    useState<IntervewData>()
  const [statusAssmnt, setStatusAssmnt] = useState<AssessmentStatuses[]>([])
  const [invitedRequests, setInvitedRequests] = useState<number[]>([])
  const employerInfo = useTypedSelector(s => s.auth.user)?.employer_info
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const timeZoneAbr = moment.tz(userTimeZone).zoneAbbr()
  const {
    data: workerData,
    isLoading,
    refetch: workerInfoRefetch,
  } = useQuery(
    [EmployerQueries.workerData, { unique_url, isInstantSearch }],
    () => getWorkerInfo(unique_url, isInstantSearch, undefined, currentTimezone)
  )

  const coworkerListCash: LastSharedCoworkerList | undefined =
    queryClient.getQueryState<LastSharedCoworkerList, undefined>(
      EmployerQueries.lastSharedList
    )?.data

  useQuery([EmployerQueries.lastSharedList], () => getLastShared(), {
    enabled: !coworkerListCash,
  })

  const interviewScores = {
    'attendance_&_punctuality':
      workerData?.interview_scores.skillit_interview.details
        ?.attendance_punctuality_score ?? undefined,
    professionalism:
      workerData?.interview_scores.skillit_interview.details
        ?.professionalism_score ?? undefined,
    attitude:
      workerData?.interview_scores.skillit_interview.details?.attitude_score ??
      undefined,
  }

  const modalAction = (modal: ModalData) => {
    setActiveModal(modal)
  }

  const backBlurAction = () => {
    dispatch(setIsNavSearchHover(false))
    dispatch(setIsTopFilterVisible(false))
    dispatch(setIsBackBlur(false))
  }

  const preparedCertifications = (): ICertificationGroup[] => {
    const result: ICertificationGroup[] = []

    for (const group in workerData?.details.certifications) {
      const certArray = workerData?.details.certifications[group]
      if (certArray && certArray.length > 0) {
        result.push({ group, certifications: certArray })
      }
    }

    return result
  }

  const interviewerInfo = useMemo(() => {
    const isInterviewData =
      workerData?.user_card?.interview_status_info?.date &&
      workerData?.user_card?.interview_status_info?.status &&
      workerData?.user_card?.interview_status_info?.requested_by?.first_name &&
      workerData?.user_card?.interview_status_info?.requested_by?.last_name

    if (!isInterviewData) {
      return null
    }

    const interviewCardData = workerData?.user_card?.interview_status_info

    const name = interviewCardData
      ? `${capitalizeFirstLetter(interviewCardData.requested_by?.first_name)}
        ${capitalizeFirstLetter(interviewCardData.requested_by?.last_name)}`
      : `${capitalizeFirstLetter(employerInfo?.basic_info.first_name)}
        ${capitalizeFirstLetter(employerInfo?.basic_info.last_name)}`

    const photo = interviewCardData
      ? interviewCardData.requested_by?.profile_picture
      : employerInfo?.basic_info.profile_picture

    let title = `Interview ${capitalizeFirstLetter(interviewCardData?.status)}!`

    let titleColor = ''

    let date = interviewCardData?.date

    const workerName = capitalizeFirstLetter(workerData?.user_card.first_name)

    let description = ''

    switch (workerData?.user_card?.interview_status_info?.status) {
      case interviewStatus.scheduled:
      case interviewStatus.rescheduled:
        date = `${interviewCardData?.date} ${timeZoneAbr}`
        description = `will be interviewing ${workerName} on ${date}`
        titleColor = 'green'
        break

      case interviewStatus.requested:
        description = `sent an interview request on ${date}`
        titleColor = 'green'
        break

      case interviewStatus.rescheduling:
        description = `We are coordinating a new time for ${name} to interview ${workerName}`
        titleColor = 'green'
        break

      case interviewStatus.no_showed:
        description = `${workerName} failed to show up for an interview with ${capitalizeFirstLetter(
          interviewCardData?.requested_by?.first_name
        )} on ${interviewCardData?.date}`
        title = 'No Showed!'
        titleColor = 'red'
        break

      case interviewStatus.declined:
        description = `${workerName} declined an interview request on ${date}`
        titleColor = 'red'
        break

      case interviewStatus.canceled:
        description = `canceled an interview with ${workerName} on ${date}`
        titleColor = 'red'
        break

      case interviewStatus.completed:
        description = `interviewed ${workerName} on ${date}`
        titleColor = 'green'
        break

      case interviewStatus.unreachable:
        title = 'Interview status: unreachable'
        titleColor = 'red'
        description = `${workerName} is unreachable on ${date}`
        break
    }

    return {
      name,
      photo,
      description,
      title,
      titleColor,
    }
  }, [
    workerData?.user_card.first_name,
    workerData?.user_card?.interview_status_info,
    employerInfo?.basic_info,
    timeZoneAbr,
  ])

  const isDisabletInterviewButton = useMemo(() => {
    const interviewStatusInfo = workerData?.user_card.interview_status_info
    const requestedBy = interviewStatusInfo?.requested_by

    if (interviewStatusInfo === null) return false

    if (
      !interviewerInfo ||
      (interviewStatusInfo?.date &&
        interviewStatusInfo?.status &&
        requestedBy?.first_name &&
        requestedBy?.last_name)
    ) {
      return true
    }

    return false
  }, [workerData?.user_card.interview_status_info, interviewerInfo])

  useEffect(() => {
    setStatusAssmnt(
      workerData?.assessment_scores?.filter(
        assmnt => assmnt.status.status === AssmntStatus.sent
      ) ?? []
    )
  }, [workerData])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <EmployeeInfoContext.Provider
      value={{
        workerData: workerData ?? null,
        action: workerInfoRefetch,
      }}
    >
      <WorkerDetailsUI
        workerData={workerData}
        isLoading={isLoading}
        activeModal={activeModal}
        setActiveModal={setActiveModal}
        isSuccessModal={isSuccessModal}
        setIsSuccessModal={setIsSuccessModal}
        setInvitedRequests={setInvitedRequests}
        currentInterviewData={currentInterviewData}
        backBlurAction={backBlurAction}
        modalAction={modalAction}
        invitedRequests={invitedRequests}
        interviewScores={interviewScores}
        statusAssmnt={statusAssmnt}
        isActiveBackBlur={
          isNavSearchHover || isSearchHistoryOpen || isTopFilterVisible
        }
        uniqueUrl={unique_url}
        interviewer={interviewerInfo}
        preparedCertifications={preparedCertifications()}
        isDisabletInterviewButton={isDisabletInterviewButton}
      />
    </EmployeeInfoContext.Provider>
  )
}
