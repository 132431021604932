import React, { useState } from 'react'
import { Content, FormAnswers, List, Options, Page } from '../../../../models'
import { checkboxButtonsAnswer } from '../../../../pages/PreInterviewOnboarding/FormsContainer/formsFunc'
import { Checkbox, ElemObj, Multiselect } from '../../../atoms'

export const optionsSelectAndBG = new Array(50).fill(1).map((el, i) => {
  return {
    id: i,
    element: `${i + 1} ${i ? 'Years' : 'Year'}`,
  }
})
interface Props {
  item: List
  questionElem: Content
  optionElem: Options
  setAnswersList: React.Dispatch<React.SetStateAction<FormAnswers>>
  page: Page
  selectedElement: ElemObj | null
}

export const SelectAndBG = (props: Props) => {
  const {
    item,
    questionElem,
    optionElem,
    setAnswersList,
    page,
    selectedElement,
  } = props
  const [isChecked, setChecked] = useState(selectedElement?.id === item.id)

  return (
    <div className="form-content__select-bg-type">
      <Checkbox
        buttonsGrid
        key={item.id}
        name={item.value}
        idCheckbox={item.id}
        checked={isChecked ? true : undefined}
        toggleCheckbox={(id: number, isChecked: boolean) =>
          setChecked(!isChecked)
        }
      />
      {isChecked && (
        <Multiselect
          className="form-content__option-select"
          placeholder="Select Experience"
          multiSelect={false}
          elements={optionsSelectAndBG}
          selectedElement={selectedElement}
          getSelectedElement={element => {
            element &&
              checkboxButtonsAnswer(
                questionElem.id,
                questionElem.type,
                optionElem.name,
                optionElem.id,
                optionElem.is_optional,
                item.id,
                element.element,
                setAnswersList,
                optionElem.list.map(el => el.id),
                page.content,
                !isChecked
              )
          }}
        />
      )}
    </div>
  )
}
