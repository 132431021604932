export namespace colorCalculator {
  export const percentage = (score: number) => {
    return (score / 10) * 100
  }

  export const color = (score: number, inPercentagesScore: boolean) => {
    const percentageData = inPercentagesScore
      ? score
      : colorCalculator.percentage(score)
    let colorData

    if (percentageData > 39) {
      colorData = {
        first: '#b9a400',
        second: '#e6cd07',
        classScore: 'score_yellow',
      }
      if (percentageData > 69) {
        colorData = {
          first: '#387242',
          second: '#4AAD5B',
          classScore: 'score_green',
        }
      }
    } else {
      colorData = {
        first: '#790000',
        second: '#ef1616',
        classScore: 'score_red',
      }
    }
    return colorData
  }
}
