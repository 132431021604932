import React from 'react'
import { useHistory } from 'react-router-dom'
import * as route from '../../../../../services/route'
import { useTypedSelector } from '../../../../../utils/hooks'
import { Button } from '../../../../atoms'
import { ModalBox } from '../../../../molecules'
import './notBookedModal.scss'

interface Props {
  textError: string
  onboarding?: true
  uuid: string
  isModalBooking: boolean
  setIsModalBooking: React.Dispatch<React.SetStateAction<boolean>>
}

//TODO: Move validations (isModalBooking  && textError.length) to an external component
export const NotBookedModal = (props: Props) => {
  const { textError, onboarding, uuid, isModalBooking, setIsModalBooking } =
    props
  const history = useHistory()
  const isOnboardingApproved = useTypedSelector(
    state => state.auth.isOnboardingApproved
  )
  const errorIsBooking = 'You already have active interview booking.'

  return (
    <>
      {isModalBooking && !!textError && (
        <ModalBox
          onCloseModal={() => setIsModalBooking(false)}
          visibleCloseIcon={true}
        >
          <div className="not-booked-modal">
            {textError === errorIsBooking ? (
              <p className="not-booked-modal__text">
                {`Sorry, you can not schedule more than one interview.${
                  onboarding
                    ? ' Please Sign up to see your Scheduled interview details'
                    : ''
                }`}
              </p>
            ) : (
              <p className="not-booked-modal__text">{textError}</p>
            )}
            {!isOnboardingApproved && (
              <Button
                className="not-booked-modal__button"
                onClick={() => {
                  textError === errorIsBooking &&
                    onboarding &&
                    history.push(`${route.signUp}/${uuid}`)
                  setIsModalBooking(false)
                }}
              >
                {textError === errorIsBooking && onboarding
                  ? 'Sign up'
                  : 'Close'}
              </Button>
            )}
          </div>
        </ModalBox>
      )}
    </>
  )
}
