import React from 'react'

import './navigationFooter.scss'
import { useTranslation } from 'react-i18next'

interface Props extends React.HTMLAttributes<HTMLElement> {
  actionBack?: () => void
  isInitializationPage?: true
}

export const NavigationFooter = (props: Props) => {
  const { t } = useTranslation()
  return (
    <div
      className={`nav-footer ${props.className ?? ''}`}
      data-testid="nav-footer"
    >
      <div className="nav-footer__container ob-container">
        {props.isInitializationPage ? (
          <div className="nav-footer__text" />
        ) : (
          <button
            className="nav-footer__btn-back"
            onClick={() =>
              props.actionBack ? props.actionBack() : window.history.back()
            }
          >
            &nbsp;{t('Shared_Header.Back')}
          </button>
        )}
        <div className="nav-footer__children-wrapper">
          {props.children ?? ''}
        </div>
      </div>
    </div>
  )
}
