import React, { HTMLAttributes } from 'react'
import './radioButtonsList.scss'

export interface RadioBtnElement {
  id: number
  value: string
  isDisabled?: boolean
}
interface Props extends React.InputHTMLAttributes<HTMLElement> {
  radioId: number
  selected: number | undefined
  onChangeBtn: (id: number) => void
  singleButtonsGrid?: true | undefined
  isDisabled?: boolean
}
interface RadioBtnList extends HTMLAttributes<HTMLElement> {
  list: RadioBtnElement[]
  selected?: number
  onChangeBtn: (id: number) => void
  singleButtonsGrid?: true
}

const RadioButton = (props: Props) => {
  const {
    radioId,
    selected,
    value,
    onChangeBtn,
    isDisabled,
    singleButtonsGrid,
  } = { ...props }

  const isChecked = selected === radioId

  return (
    <div
      className={`radio-btn ${
        singleButtonsGrid ? 'radio-btns-list_grid' : ''
      } ${isDisabled ? 'radio-btn__disabled' : ''}`}
    >
      <input
        id={`${radioId}`}
        type="radio"
        className="radio-btn__input"
        value={value}
        checked={isChecked}
        onChange={() => onChangeBtn(radioId)}
        disabled={isDisabled}
      />
      <label htmlFor={`${radioId}`} className="radio-btn__label">
        {value}
      </label>
    </div>
  )
}

export const RadioButtonsList = (props: RadioBtnList) => {
  const { className, list, selected, onChangeBtn, singleButtonsGrid } = {
    ...props,
  }

  return (
    <div
      className={`radio-btns-list ${
        singleButtonsGrid ? 'radio-btns-list_flex' : ''
      } ${className ?? ''}`}
    >
      {list.map(elem => (
        <RadioButton
          key={elem.id}
          radioId={elem.id}
          value={elem.value}
          selected={selected ? selected : undefined}
          onChangeBtn={onChangeBtn}
          singleButtonsGrid={singleButtonsGrid}
          isDisabled={elem.isDisabled}
        />
      ))}
    </div>
  )
}
