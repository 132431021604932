import React from 'react'
import { useLocation } from 'react-router-dom'
import { useTypedSelector } from '../../../../utils/hooks/UseTypedSelector'
import './takeChallenge.scss'
import { Helmet } from 'react-helmet'

export const TakeChallenge = () => {
  const location = useLocation()
  const urlParts = location.pathname.split('/')
  const formId = urlParts[urlParts.length - 1]
  const userName = useTypedSelector(s => s.auth.user?.username)
  return (
    <div className="take-challenge">
      <Helmet>
        <title>Skillit - Take challenge</title>
      </Helmet>
      <iframe
        title={formId}
        className="take-challenge__iframe"
        src={`https://fraserpatterson.typeform.com/to/${formId}?typeform-medium=embed-snippet#email=${userName}`}
      ></iframe>
    </div>
  )
}
