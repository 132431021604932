import React, { useCallback, useEffect, useState } from 'react'
import { ModalBox } from '../../../molecules'
import { Title, Button, Input, ErrorMessage } from '../../../atoms'
import './forgotPasswordModal.scss'
import { InputRef } from '../../../atoms/inputs/Input'
import { AsyncStatus, useAsyncAction } from '../../../../utils/hooks'
import { forgotPasswordRequest } from '../../../../services/auth-service'
import { toast } from 'react-toastify'

interface Props {
  onCloseModal: () => void
}

export const ForgotPasswordModal = ({ onCloseModal }: Props): JSX.Element => {
  const inputRef = React.createRef<InputRef>()
  const [disabledModal, setDisabledModal] = useState(false)
  const successMessage = 'Please check your email to reset your password'

  const forgotRequest = useCallback(
    () => forgotPasswordRequest(inputRef.current?.value ?? ''),
    [inputRef]
  )
  const { memorizedAction, error, status } = useAsyncAction(
    forgotRequest,
    false
  )

  const sendForgotRequest = () => {
    setDisabledModal(true)
    memorizedAction()
  }

  useEffect(() => {
    if (status === AsyncStatus.Success) {
      onCloseModal()
      toast.success(successMessage)
    }
    if (status === AsyncStatus.Error) {
      setDisabledModal(false)
    }
  }, [status, onCloseModal])

  return (
    <ModalBox onCloseModal={onCloseModal}>
      <form onSubmit={sendForgotRequest}>
        <Title
          text={'Please enter your email address'}
          closeAvailable={false}
          textClassName={'fpText'}
          className={'fpContainer'}
        />
        {error?.message && <ErrorMessage>{error.message}</ErrorMessage>}
        <Input
          containerClassName={'fbInputContainer'}
          placeholder={'Enter your email address'}
          inputClassName={'fbInput'}
          disabled={disabledModal}
          ref={inputRef}
        />
        <Button
          className={'fpButtonSubmit'}
          type="submit"
          onClick={sendForgotRequest}
          disabled={disabledModal}
        >
          Submit
        </Button>
        <Button
          className={'fpButtonClose button_secondary'}
          onClick={onCloseModal}
        >
          Close
        </Button>
      </form>
    </ModalBox>
  )
}
