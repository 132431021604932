import React, { useEffect } from 'react'
import {
  OmitNative,
  Redirect,
  Route,
  RouteProps,
  useLocation,
  useHistory,
} from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import * as Page from '../pages'
import * as route from '../services/route'
import ScrollRestoration from 'react-scroll-restoration'
import { useAppDispatch } from '../redux/store'
import { initializeApp } from '../redux/app-reducer'
import { useTypedSelector } from '../utils/hooks/UseTypedSelector'
import { Footer } from '../components/organism'
import { GoogleAnalytics } from '../GoogleAnalytics/GoogleAnalytics'
import './styles.scss'
import { useAuthUserByToken, useMediaContext } from '../utils/hooks'
import { setTopFilterHeight } from '../redux/variables-reducer'
import { TokenHolder } from '../services/TokenHolder'
import {
  EAdminWorkersSStoreKeys,
  ELandingPagesSStoreKeys,
} from '../utils/constants'
import { useDispatch } from 'react-redux'
import { setIsEmployer } from '../redux'
import { toast } from 'react-toastify'
import { suspenseWrapper } from '../utils/scripts/suspenseWrapper'
import Cookies from 'js-cookie'

const EmployerRouter = React.lazy(() => import('./EmployerRouter'))
const AdminWorkersRoute = React.lazy(() => import('./AdminWorkersRouter'))
const WorkerInAppRouter = React.lazy(() => import('./WorkerInAppRouter'))
const AuthRouter = React.lazy(() => import('./AuthRouter'))
const OnboardingRouter = React.lazy(() => import('./OnboardingRouter'))

export const ProtectedRoute = <T extends {} = {}, Path extends string = string>(
  props: RouteProps<Path> & OmitNative<T, keyof RouteProps>
) => {
  const location = useLocation()

  const isSignedIn = useTypedSelector(state => state.auth.isAuth)
  const isEmployer = useTypedSelector(state => state.auth.isEmployer)
  const isAdminWorkers = useTypedSelector(state => state.auth.isSkillitAdmin)
  const isLandingPages =
    location.pathname.includes(route.inviteInterview) ||
    location.pathname.includes(route.feedback)

  if (isSignedIn) {
    return isEmployer || isAdminWorkers ? (
      <>
        <Route {...props} />
        {isEmployer && !isLandingPages && <Footer isEmployer />}
      </>
    ) : (
      <Page.SideBarWrapper>
        <>
          <Route {...props} />
          <Footer />
        </>
      </Page.SideBarWrapper>
    )
  }
  return <Redirect to={route.OBPath.onboardingName} />
}

export const AppRouter = () => {
  const isMobile = useMediaContext()
  const initialized = useTypedSelector(state => state.app.initialized)
  const dispatch = useAppDispatch()
  const urlParams = new URLSearchParams(window.location.search)
  const tokenFromUrl = urlParams.get('verified')
  const history = useHistory()
  const isLoading = useTypedSelector(state => state.auth.isLoading)
  window.Intercom('shutdown')
  useAuthUserByToken()

  useEffect(() => {
    dispatch(initializeApp()).catch(err => {
      if (err instanceof Error) {
        toast.error(err.message)
      }
    })
  }, [dispatch])

  useEffect(() => {
    isMobile && dispatch(setTopFilterHeight(0))
  }, [isMobile, dispatch])

  if (tokenFromUrl && !initialized) {
    TokenHolder.set(tokenFromUrl, true)
    history?.replace({ search: '' })

    dispatch(initializeApp()).catch(err => {
      if (err instanceof Error) {
        toast.error(err.message)
      }
    })
    return <Page.LoadingScreen />
  }

  if (!initialized) {
    return <Page.LoadingScreen />
  }

  return (
    <BrowserRouter>
      {isLoading ? (
        <>
          <Page.Navigator />
          <Page.LoadingScreen />
        </>
      ) : (
        <>
          <ScrollRestoration />
          <AppFlow />
          <GoogleAnalytics />
        </>
      )}
    </BrowserRouter>
  )
}

const AppFlow = () => {
  const isEmployer = useTypedSelector(state => state.auth.isEmployer)
  const isAdminWorkers = useTypedSelector(state => state.auth.isSkillitAdmin)
  const location = useLocation()
  const isPreInterview = location.pathname.includes(route.preInterview)

  return isPreInterview && !isEmployer && !isAdminWorkers ? (
    <PreInterviewFlow />
  ) : (
    <InAppFlow isEmployer={isEmployer} isAdminWorkers={isAdminWorkers} />
  )
}

const InAppFlow = React.memo(
  (props: { isEmployer: boolean; isAdminWorkers: boolean }) => {
    const appRef = React.useRef<HTMLDivElement>(null)
    const isAuth = useTypedSelector(s => s.auth.isAuth)
    const history = useHistory()
    const isEmployer = props.isEmployer
    const isAdminWorkers = props.isAdminWorkers
    const navbarHeight = useTypedSelector(state => state.variables.navbarHeight)
    const topFilterHeight = useTypedSelector(
      state => state.variables.topFilterHeight
    )

    const dispatch = useDispatch()
    const location = useLocation()
    const isWorkerWelcomePage = location.pathname === route.workerWelcome
    const isAssessmentPage = location.pathname.includes(route.assessment)
    const isLandingPages =
      location.pathname.includes(route.inviteInterview) ||
      location.pathname.includes(route.feedback)

    const selectInAppFlow = () => {
      if (!isAuth) return suspenseWrapper(AuthRouter)
      if (isEmployer) return suspenseWrapper(EmployerRouter)
      if (isAdminWorkers) return suspenseWrapper(AdminWorkersRoute)

      return suspenseWrapper(WorkerInAppRouter)
    }

    useEffect(() => {
      const isNotWorker = isEmployer || isAdminWorkers
      const workerDetailsPath = location.pathname.includes(
        `${route.workerDetails}/`
      )
      if (isNotWorker) {
        if (
          location.pathname === route.employerDashboard ||
          workerDetailsPath
        ) {
          dispatch(setIsEmployer(true))
          sessionStorage.removeItem(EAdminWorkersSStoreKeys.isAdmin)
          !isEmployer &&
            workerDetailsPath &&
            isAuth &&
            history.push(location.pathname)
        }
        if (location.pathname === route.adminWorkers) {
          dispatch(setIsEmployer(false))
          sessionStorage.setItem(
            EAdminWorkersSStoreKeys.isAdmin,
            JSON.stringify('true')
          )
        }
      }
    }, [
      location.pathname,
      dispatch,
      history,
      isAuth,
      isEmployer,
      isAdminWorkers,
    ])

    useEffect(() => {
      if (!isLandingPages) {
        sessionStorage.removeItem(ELandingPagesSStoreKeys.isFeedbackSent)
        sessionStorage.removeItem(ELandingPagesSStoreKeys.isFeedbackNotesSent)
        sessionStorage.removeItem(ELandingPagesSStoreKeys.isInterviewInvited)
      }
    }, [isLandingPages])

    const urlFrom = sessionStorage.getItem('from')
    const isBeta: boolean = urlFrom?.includes('beta') ?? false
    const isAllowBeta = useTypedSelector(state => state.auth.isBetaEnabled)
    const isNotLegacyVersion = !Cookies.get('legacyVersion')
    const isAcceptableRoles = isEmployer || isAdminWorkers
    const isWorker = useTypedSelector(state => state.auth.isWorker)
    const isInbox =
      window.location.href.includes('/beta/inbox') ||
      (urlFrom?.includes('/beta/inbox') ?? false)

    if (isAllowBeta && isNotLegacyVersion && isAcceptableRoles) {
      const SSLink = urlFrom
        ? urlFrom.includes('beta')
          ? urlFrom
          : `/beta${urlFrom}`
        : null

      const link =
        window.location.pathname.includes('sk/') ||
        window.location.pathname.includes('inbox/')
          ? `/beta${window.location.pathname}${window.location.search}`
          : '/beta'

      const path = SSLink ?? link

      sessionStorage.removeItem('from')

      window.location.assign(`${window.location.origin}${path}`)

      return <Page.LoadingScreen />
    }

    if (isBeta && isAcceptableRoles) {
      sessionStorage.removeItem('from')
      window.location.assign(`${window.location.origin}${urlFrom}`)

      return <Page.LoadingScreen />
    }

    if (isInbox && isWorker) {
      let targetUrl = '/'

      if (window.location.href.includes('/beta/inbox')) {
        targetUrl = window.location.origin + location.pathname
      } else if (urlFrom?.includes('/beta/inbox')) {
        targetUrl = window.location.origin + urlFrom
      }

      sessionStorage.removeItem('from')
      window.location.assign(targetUrl)
      return <Page.LoadingScreen />
    }

    return (
      <div
        style={{ top: `${navbarHeight + topFilterHeight}px` }}
        ref={appRef}
        className={`app-container ${
          isWorkerWelcomePage ? 'app-container__white-background' : ''
        }`}
      >
        <Route
          exact={true}
          path={`${route.lastResult}/:email?`}
          component={Page.LastResultPage}
        />
        <Page.Navigator
          onboarding={
            isWorkerWelcomePage || isAssessmentPage ? true : undefined
          }
          landingPage={isLandingPages ? true : undefined}
        />
        {selectInAppFlow()}
      </div>
    )
  }
)

const PreInterviewFlow = React.memo(() => {
  const location = useLocation()
  const isSchedulePage = location.pathname.includes(route.preInterviewSchedule)
  const userInfo = useTypedSelector(s => s.obInfo.workerInfo)
  const isObCompleted = userInfo.is_completed

  const isOnboardingApproved = useTypedSelector(
    state => state.auth.isOnboardingApproved
  )

  if (isOnboardingApproved)
    return <InAppFlow isEmployer={false} isAdminWorkers={false} />

  return (
    <div className="preinterview-bg-container">
      <Page.Navigator onboarding />
      {isObCompleted ? (
        <>
          {!isSchedulePage && (
            <Redirect to={`${route.OBPath.onboardingThanks}`} />
          )}
          <Route
            exact={true}
            path={`${route.preInterviewSchedule}/:uuid`}
            component={Page.SchedulePage}
          />
          <Route
            exact={true}
            path={`${route.OBPath.onboardingThanks}`}
            component={Page.OBPages.OBThanks}
          />
        </>
      ) : isSchedulePage ? (
        <Route
          exact={true}
          path={`${route.preInterviewSchedule}/:uuid`}
          component={Page.SchedulePage}
        />
      ) : (
        suspenseWrapper(OnboardingRouter)
      )}
    </div>
  )
})
