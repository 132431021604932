import { toast } from 'react-toastify'
import axios from 'axios'
import { BACKEND_URL } from './config'
import Cookies from 'js-cookie'
import { TokenHolder, FACEBOOK_KEY } from './TokenHolder'

export const ERROR_NO_INTERNET = 'No internet connection'
export const EXPIRED_SESSION_MESSAGE =
  'Your session has expired. Please sign in again.'
const EXPIRED_ERROR = 'The Token is expired'

export const API = axios.create({
  baseURL: BACKEND_URL,
  withCredentials: true,
  responseType: 'json',
})

export const API_NO_TOKEN = axios.create({
  baseURL: BACKEND_URL,
  withCredentials: false,
  responseType: 'json',
})

export const API_FORM_DATA = axios.create({
  baseURL: BACKEND_URL,
  withCredentials: true,
  headers: { 'Content-type': 'multipart/form-data' },
  responseType: 'json',
})

API.interceptors.request.use(config => {
  if (!window.navigator.onLine) {
    return toast.error(ERROR_NO_INTERNET, {
      toastId: ERROR_NO_INTERNET,
    })
  }

  const token = Cookies.get('token')
  if (token) {
    config.headers.Authorization = 'Token ' + token
  }
  return config
})

API.interceptors.response.use(
  response => response,
  error => {
    if (error.response.data.detail === EXPIRED_ERROR) {
      TokenHolder.remove()
      Cookies.remove(FACEBOOK_KEY)
      toast.error(EXPIRED_SESSION_MESSAGE, {
        toastId: EXPIRED_SESSION_MESSAGE,
      })
      setTimeout(() => window.location.reload(), 4000)
    }
    return Promise.reject(error)
  }
)

API_NO_TOKEN.interceptors.request.use(config => {
  if (!window.navigator.onLine)
    return toast.error(ERROR_NO_INTERNET, {
      toastId: ERROR_NO_INTERNET,
    })
  return config
})

API_FORM_DATA.interceptors.request.use(config => {
  const token = Cookies.get('token')
  if (token) {
    config.headers.Authorization = 'Token ' + token
  }
  return config
})
