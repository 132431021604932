import React from 'react'
import './switcher.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export const Switcher = (props: Props) => {
  const [checked, setChecked] = React.useState(false)
  return (
    <div {...props}>
      <label className={`switch`}>
        <input
          type="checkbox"
          checked={checked}
          onChange={el => setChecked(el.target.checked)}
        />
        <span className="slider round"></span>
        <span className={`text ${checked ? 'checked' : ''}`}>Off</span>
      </label>
    </div>
  )
}
