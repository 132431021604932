import React, { useEffect, useState } from 'react'

export enum AsyncStatus {
  Idle,
  Success,
  Error,
  Executing,
}

export function useAsyncAction<Result>(
  action: () => Promise<Result>,
  imediately: boolean
) {
  const [status, setStatus] = useState(AsyncStatus.Idle)
  const [value, setValue] = useState<Result | null>(null)
  const [error, setError] = useState<Error | null>(null)

  const memorizedAction = React.useCallback(async () => {
    setStatus(AsyncStatus.Executing)
    setValue(null)
    setError(null)
    return action()
      .then(response => {
        setValue(response)
        setStatus(AsyncStatus.Success)
      })
      .catch(error => {
        setError(error)
        setStatus(AsyncStatus.Error)
      })
  }, [action])

  useEffect(() => {
    if (imediately) {
      memorizedAction()
    }
  }, [memorizedAction, imediately])

  return { memorizedAction, status, value, error }
}
