import {
  IAvailableInterview,
  IDataForShare,
  IFeedback,
  IFilterParams,
  IHistoryData,
  IInterviewUrlData,
  ILocation,
  IInterviewRequestData,
  RecentSearches,
  WorkersFilterQuery,
  LastSharedCoworkerList,
  IBetaVersionStatus,
} from './../../models/Employer'
import { CustomOption } from '../../components/atoms'
import { OfferValues } from '../../components/organism'
import { AddAddress, Address, FilterType } from '../../models'
import {
  Benchmark,
  ListOfWorkers,
  WorkerInfo,
  EmployerInfo,
  AssessmentModal,
  CompanyIntervewRating,
  InterviewDate,
  WorkerInInstantSearch,
} from '../../models/Employer'
import { responseHandler } from '../responseHandlers'
import { API, API_NO_TOKEN } from './../API'
import { responseHandlerNoToken } from '../responseHandlerNoToken'

export const addToCategory = async (
  userId: number,
  updatedCategory: string
) => {
  return responseHandler(
    API.get(
      `api/employer/${userId}/add-to-category/?category=${updatedCategory}`
    )
  )
}

export const removeFromCategory = async (
  userId: number,
  updatedCategory: string
) => {
  return responseHandler(
    API.get(
      `api/employer/${userId}/delete-from-category/?category=${updatedCategory}`
    )
  )
}

export const getWorkerInfo = async (
  unique_url: string,
  isInstantSearch: boolean,
  token?: string,
  currentTimeZone?: string
): Promise<WorkerInfo> => {
  const timeZone = currentTimeZone ? `?timezone=${currentTimeZone}` : ''
  if (token) {
    return responseHandlerNoToken(
      API_NO_TOKEN.post(
        `api/employer/get-employee-info/`,
        { unique_url: unique_url },
        {
          headers: {
            Authorization: 'Token ' + token,
          },
        }
      )
    )
  }

  return isInstantSearch
    ? responseHandler(
        API.post(`api/employer/get-employee-info/?instant_search=true`, {
          unique_url: unique_url,
        })
      )
    : responseHandler(
        API.post(`api/employer/get-employee-info/${timeZone}`, {
          unique_url: unique_url,
        })
      )
}

export const getFilterParams = async (): Promise<IFilterParams> => {
  return responseHandler(API.get('api/employer/get-filter-params/'))
}

export const getListOfWorkers = async (
  data: WorkersFilterQuery,
  timeZone: string
): Promise<ListOfWorkers> => {
  const searchType =
    data.type.type === FilterType.search ? '?regular_search=true' : ''
  const currentTimeZone = searchType
    ? `&timezone=${timeZone}`
    : `?timezone=${timeZone}`
  return responseHandler(
    API.post(
      `/api/employer/get-employees-list/${searchType + currentTimeZone}`,
      data
    )
  )
}

export const getListPosition = async (): Promise<CustomOption[]> => {
  return responseHandler(API.get('api/position/'))
}
//TODO: refactoring:in all requests use ReactQuery(not used yet located in MakeOfferModal)
export const savePosition = async (
  position: CustomOption
): Promise<CustomOption> => {
  return responseHandler(API.post('api/position/', position))
}
//TODO: refactoring:in all requests use ReactQuery(not used yet located in MakeOfferModal)
export const deletePosition = async (
  position: CustomOption
): Promise<CustomOption[]> => {
  return responseHandler(API.delete(`api/position/${position.id}`))
}

export const getBenchmark = async (): Promise<Benchmark[]> => {
  return responseHandler(API.get('api/employer/get-benchmarks/'))
}
//TODO: refactoring:in all requests use ReactQuery(not used yet located in MakeOfferModal)
export const sendOffer = async (data: OfferValues) => {
  return responseHandler(API.post('api/employer/make-offer/', data))
}

export const setInterview = async (workerId: number, data: InterviewDate) => {
  return responseHandler(
    API.post(`api/interview/${workerId}/set-interview/`, data)
  )
}

export const immediatelySetInterview = async (
  id: number,
  data: IInterviewRequestData,
  interviewUrlData?: IInterviewUrlData
) => {
  if (interviewUrlData) {
    return responseHandlerNoToken(
      API_NO_TOKEN.post(
        `api/employer/${id}/invite-to-interview/`,
        { ...data, action: interviewUrlData.action },
        {
          headers: {
            Authorization: 'Token ' + interviewUrlData.token,
          },
        }
      )
    )
  }

  return responseHandler(
    API.post(`api/employer/${id}/invite-to-interview/`, data)
  )
}
//TODO: refactoring:in all requests use ReactQuery
export const saveInterviewRating = async (
  id: number,
  data: CompanyIntervewRating
) => {
  return responseHandler(API.post(`api/interview/${id}/mark-interview/`, data))
}
//TODO: refactoring:in all requests use ReactQuery
export const editNotes = async (
  id: number,
  notes: string
): Promise<AddAddress[]> => {
  return responseHandler(API.post(`api/interview/${id}/edit-notes/`, { notes }))
}
//TODO: refactoring:in all requests use ReactQuery
export const getEmployerInfo = async (): Promise<EmployerInfo> => {
  return responseHandler(API.get('api/employer/get-employer-info/'))
}

export const getAssessmentInfo = async (
  id: number
): Promise<AssessmentModal[]> => {
  return responseHandler(API.get(`api/employer/${id}/assessment-forms/`))
}
//TODO: refactoring:in all requests use ReactQuery(not used yet)
export const sendAssessmentEmployee = async (id: number, key: string) => {
  return responseHandler(API.post('api/employer/send-assessment/', { id, key }))
}
//TODO: not used
export const getAddress = async (): Promise<Address[]> => {
  return responseHandler(API.get('api/address/'))
}
//TODO: not used
export const setAddress = async (data: AddAddress): Promise<AddAddress[]> => {
  return responseHandler(API.post('api/address/', data))
}

export const getInstantSearch = async (
  searchValue: string
): Promise<WorkerInInstantSearch> => {
  return responseHandler(
    API.get(`api/employer/instant-search/?name=${searchValue}`)
  )
}

export const getInstantLocation = async (
  searchValue: string
): Promise<ILocation[]> => {
  return responseHandler(
    API.get(`api/employer/locations-prompts/?data=${searchValue}`)
  )
}

export const getInstantSkills = async (
  searchValue: string
): Promise<WorkerInInstantSearch> => {
  return responseHandler(
    API.get(`api/employer/skills-prompts/?data=${searchValue}`)
  )
}

export const getRecentSearches = async (): Promise<RecentSearches[]> => {
  return responseHandler(API.get('api/employer/last-search/'))
}
//TODO: not used
export const getRolesPropmpts = async (): Promise<string[]> => {
  return responseHandler(API.get('api/employer/roles-prompts/'))
}

export const clearSearchHistory = async () => {
  return responseHandler(API.get('api/employer/clear-search/'))
}

export const getInterviewTypes = async (
  id: number,
  token?: string
): Promise<IAvailableInterview> => {
  if (token) {
    return responseHandlerNoToken(
      API_NO_TOKEN.get(`api/employer/${id}/available-interview-types/`, {
        headers: {
          Authorization: 'Token ' + token,
        },
      })
    )
  }

  return responseHandler(
    API.get(`api/employer/${id}/available-interview-types/`)
  )
}

export const shareWorkerData = async (data: IDataForShare): Promise<any> => {
  return responseHandler(API.post('api/employer/share/worker/', data))
}

export const shareFeedback = async (token: string, data: IFeedback) => {
  return responseHandlerNoToken(
    API_NO_TOKEN.post('api/employer/share/feedback/', data, {
      headers: {
        Authorization: 'Token ' + token,
      },
    })
  )
}

export const getHistoryList = async (url: string): Promise<IHistoryData> => {
  return responseHandler(
    API.get(`api/employer/share/history/?worker_url=${url}`)
  )
}

export const getLastShared = async (): Promise<LastSharedCoworkerList> => {
  return responseHandler(API.get('api/employer/share/last-shared/'))
}

export const getBetaVersionStatus = async (): Promise<IBetaVersionStatus> => {
  return responseHandler(API.get('api/users/beta-version/'))
}
