enum PreInterviewStep {
  NotPassed = 'not-passed',
  Result = 'result',
  Score = 'score',
  Passed = 'passed',
  Confirmation = 'confirmation',
  Schedule = 'schedule',
  JobDescription = 'job-description',
  TellUsAboutYou = 'tell-us-about-you',
  Start = 'start',
  Qualification = 'qualification',
  Format = 'format',
  Available = 'available',
  Onboarding = 'onboarding',
  Forms = 'forms',
  OnboardingFormat = 'onboarding-format',
}

enum Onboarding {
  name = 'name',
  email = 'email',
  trades = 'trades',
  location = 'location',
  movePlanning = 'move_planning',
  lastJobs = 'last_jobs',
  uploadResume = 'upload_resume',
  references = 'references',
  travelToWork = 'travel_to_work',
  rates = 'rates',
  willingToRelocate = 'willing_to_relocate',
  thanks = 'thanks',
  additionalInfo = 'additional_Info',
  experience = 'experience',
  languages = 'languages',
}

export const dashboard = '/dashboard'
export const employerDashboard = '/employer-dashboard'
export const workerDetails = '/sk'
export const signUp = '/sign-up'
export const signIn = '/sign-in'
export const signInEmailLogin = '/login-email'
export const signInPasswordLogin = '/login-password'
export const challenges = '/challenges'
export const facebookLogin = '/social-login'
export const quizResult = '/quiz-results'
export const takeChallenge = '/take-challenge'
export const jobsPage = '/jobs'
export const aboutPage = '/about'
export const training = '/training'
export const blog = `${training}/blog`
export const thirdBlog = `${blog}/3`
export const passwordReset = '/reset-password'
export const profile = '/profile'
export const publicProfile = `${profile}/public`
export const workPreferences = `${profile}/work-preferences`
export const profileSkillsInfo = `${profile}/skills`
export const profileBasicInfo = `${profile}/basic-info`
export const lastResult = '/last-result'
export const completeQualifications = `${profile}/complete-qualifications`
export const jobDetail = `${jobsPage}/job-detail`
export const assessment = '/assessment'
export const workerWelcome = `/worker-welcome`
export const inviteInterview = '/invite-interview'
export const feedback = '/feedback'

export const preInterview = '/pre-interview'

export const preInterviewStart = `${preInterview}/${PreInterviewStep.Start}`
export const preInterviewNotPassed = `${preInterview}/${PreInterviewStep.NotPassed}`
export const preInterviewResult = `${preInterview}/${PreInterviewStep.Result}`
export const preInterviewScore = `${preInterview}/${PreInterviewStep.Score}`
export const preInterviewPassed = `${preInterview}/${PreInterviewStep.Passed}`
export const preInterviewConfirmation = `${preInterview}/${PreInterviewStep.Confirmation}`
export const preInterviewSchedule = `${preInterview}/${PreInterviewStep.Schedule}`
export const tellUsAboutYou = `${preInterview}/${PreInterviewStep.TellUsAboutYou}`
export const PreInterviewQualification = `${preInterview}/${PreInterviewStep.Qualification}`
export const PreInterviewFormat = `${preInterview}/${PreInterviewStep.Format}`
export const PreInterviewAvailable = `${preInterview}/${PreInterviewStep.Available}`
export const interviewerSetting = '/interviewer-setting'
export const preInterviewJobDescription = `${preInterview}/${PreInterviewStep.JobDescription}`
export const preInterviewOnboarding = `${preInterview}/${PreInterviewStep.Onboarding}`
export const preInterviewForms = `${preInterview}/${PreInterviewStep.Forms}`
export const preInterviewOnboardingFormat = `${preInterview}/${PreInterviewStep.OnboardingFormat}`

export const OBPath = {
  onboardingName: `${preInterview}/${Onboarding.name}`,
  onboardingEmail: `${preInterview}/${Onboarding.email}`,
  onboardingTrades: `${preInterview}/${Onboarding.trades}`,
  onboardingLocation: `${preInterview}/${Onboarding.location}`,
  onboardingMovePlanning: `${preInterview}/${Onboarding.movePlanning}`,
  onboardingUploadResume: `${preInterview}/${Onboarding.uploadResume}`,
  onboardingLastJobs: `${preInterview}/${Onboarding.lastJobs}`,
  onboardingReferences: `${preInterview}/${Onboarding.references}`,
  onboardingTravelToWork: `${preInterview}/${Onboarding.travelToWork}`,
  onboardingRates: `${preInterview}/${Onboarding.rates}`,
  onboardingWillingToRelocate: `${preInterview}/${Onboarding.willingToRelocate}`,
  onboardingExperience: `${preInterview}/${Onboarding.experience}`,
  onboardingThanks: `${preInterview}/${Onboarding.thanks}`,
  onboardingAdditionalInfo: `${preInterview}/${Onboarding.additionalInfo}`,
  onboardingLanguages: `${preInterview}/${Onboarding.languages}`,
}

export const adminWorkers = '/admin-workers'

export const OBpageNames: { [key: number]: string } = {
  1: Onboarding.name,
  2: Onboarding.email,
  3: Onboarding.trades,
  4: Onboarding.experience,
  5: Onboarding.location,
  6: Onboarding.movePlanning,
  7: Onboarding.uploadResume,
  8: Onboarding.lastJobs,
  9: Onboarding.languages,
  10: Onboarding.additionalInfo,
  11: Onboarding.travelToWork,
  12: Onboarding.willingToRelocate,
  13: Onboarding.rates,
  14: Onboarding.references,
}
