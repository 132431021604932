import React from 'react'
import close from '../../../assets/icons/close/close-icon.svg'

import './title.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  text: string
  closeAvailable: boolean
  onClickClose?: () => void
  textClassName?: string
}

export const Title = ({
  text,
  onClickClose,
  closeAvailable,
  className = '',
  textClassName = '',
}: Props) => {
  return (
    <div className={`title-container ${className}`}>
      <p className={`title-container__text ${textClassName}`}>{text}</p>
      <img
        src={close}
        onClick={onClickClose}
        alt="close-button"
        className="title-container__button"
        hidden={!closeAvailable}
      />
    </div>
  )
}
