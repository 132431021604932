import React from 'react'
import {
  LoadingContent,
  ModalBox,
} from '../../../../../../components/molecules'
import { PopupLoader } from '../../../../../../components/organism'
import { ISkills, SkillsInfo } from '../../../../../../models'
import { IInitialState } from '../../../../../../redux'
import { BasicSkillsModal } from './BasicSkillsModal'
import edit_pencil from '../../../../../../assets/icons/other/edit-pencil.svg'
import { Checkbox } from '../../../../../../components/atoms'
import './basicSkills.scss'
import { convertBooleanChoiceToString } from '../../../../../../utils/scripts'

interface Props extends React.HTMLAttributes<HTMLElement> {
  isSaving: boolean
  isModalVisible: boolean
  isSkillsInfoChanged: boolean
  setIsConfirmationModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  skillsInfoForm: SkillsInfo
  setSkillsInfoForm: React.Dispatch<React.SetStateAction<SkillsInfo>>
  handleSubmit: (newData: SkillsInfo) => void
  isConfirmationModalVisible: boolean
  skillsInfoState: SkillsInfo
  choiceLists: IInitialState
  isLoading: boolean
  onChangeEmailMe: (id: number, isChecked: boolean) => void
  showSkillsList: (skillsList: string[]) => JSX.Element | JSX.Element[]
  roles: string[]
  sortedSkills: ISkills[]
  showLanguagesList: (list: string[] | null) => JSX.Element | JSX.Element[]
  listForPreferredLang: { id: number; value: string }[] | null
}

export const BasicSkillsUI = (props: Props) => {
  const {
    isSaving,
    isModalVisible,
    isSkillsInfoChanged,
    setIsConfirmationModalVisible,
    setIsModalVisible,
    skillsInfoForm,
    setSkillsInfoForm,
    handleSubmit,
    isConfirmationModalVisible,
    skillsInfoState,
    choiceLists,
    isLoading,
    onChangeEmailMe,
    showSkillsList,
    roles,
    sortedSkills,
    showLanguagesList,
    listForPreferredLang,
  } = props

  return (
    <div
      className={`basic-skills ${props.className ?? ''}`}
      data-testid="basic-skills"
    >
      {isSaving && <PopupLoader>Saving...</PopupLoader>}
      {isModalVisible && (
        <ModalBox
          className="basic-skills__modal-box"
          onCloseModal={() =>
            isSkillsInfoChanged
              ? setIsConfirmationModalVisible(true)
              : setIsModalVisible(false)
          }
          visibleCloseIcon={true}
        >
          <BasicSkillsModal
            className={props.className}
            skillsInfoForm={skillsInfoForm}
            setSkillsInfoForm={setSkillsInfoForm}
            setIsModalVisible={setIsModalVisible}
            handleSubmit={handleSubmit}
            isConfirmationModalVisible={isConfirmationModalVisible}
            setIsConfirmationModalVisible={setIsConfirmationModalVisible}
            skillsInfoState={skillsInfoState}
            choiceLists={choiceLists}
            roles={roles}
            listForPreferredLang={listForPreferredLang}
          />
        </ModalBox>
      )}
      <div className="basic-skills__header">
        <p className="basic-skills__header-title">Skills & Qualifications</p>
        <img
          className="basic-skills__header-edit-pensil"
          src={edit_pencil}
          alt="edit-pensil"
          onKeyDown={e => {
            if (e.key === 'Enter') {
              setIsModalVisible(true)
            }
          }}
          onClick={() => {
            setIsModalVisible(true)
          }}
        />
      </div>
      <hr />

      <LoadingContent isLoading={isLoading}>
        <div className="basic-skills__skill">
          <p className="basic-skills__skill-question">
            Are you legally authorized to work in the U.S.?
          </p>
          <p className="basic-skills__skill-answers">
            {skillsInfoState &&
              convertBooleanChoiceToString(skillsInfoState.eligible_work_us)}
          </p>
        </div>
        <hr />

        <div className="basic-skills__skill">
          <p className="basic-skills__skill-question">
            Are you Interested in Upskilling?
          </p>
          <p className="basic-skills__skill-answers">
            {skillsInfoState &&
              convertBooleanChoiceToString(
                skillsInfoState.interested_in_upskilling
              )}
          </p>
          <div className="basic-skills__skill-email-me">
            <Checkbox
              className="basic-skills__skill-email-me-checkbox"
              id="email_me"
              name="Email me information and free opportunities to grow my skills and career."
              idCheckbox={1}
              checked={skillsInfoState?.email_me === true ? true : undefined}
              toggleCheckbox={(_, checked) => onChangeEmailMe(_, checked)}
            />
          </div>
        </div>
        <hr />

        <div className="basic-skills__skill">
          <p className="basic-skills__skill-question">Skills</p>
          {sortedSkills.length ? (
            sortedSkills.map(skill => {
              return (
                <p key={skill.choice} className="basic-skills__skill-answers">
                  {`${skill.choice} (${
                    skill.level === 1
                      ? `${skill.level} Year)`
                      : `${skill.level} Years)`
                  }`}
                </p>
              )
            })
          ) : (
            <p className="basic-skills__skill-answers">None provided</p>
          )}
        </div>
        <hr />

        <div className="basic-skills__skill">
          <p className="basic-skills__skill-question">Unions</p>
          {skillsInfoState && showSkillsList(skillsInfoState.unions)}
        </div>
        <hr />

        <div className="basic-skills__skill">
          <p className="basic-skills__skill-question">Language fluency</p>
          {showLanguagesList(skillsInfoState?.language)}
        </div>
      </LoadingContent>
    </div>
  )
}
