import { useState } from 'react'
import './WorkerSummaryCard.scss'

interface Props {
  company_name: string
  start_year: string
  months: number
  description: string | null
  job_title: string
  years: number
  noDescription: boolean
}

export const WorkerSummaryCard = (props: Props) => {
  const {
    company_name,
    start_year,
    months,
    description,
    job_title,
    years,
    noDescription,
  } = props

  const [isVisibleDesc, setIsVisibleDesc] = useState(false)

  const handleToggle = () => {
    setIsVisibleDesc(!isVisibleDesc)
  }

  const experienceYears =
    years < 2 ? (
      <p className="worker-summary-card__experience-years_start-year">
        <span className="worker-summary-card__experience-years_exp">
          {months} months
        </span>{' '}
        starting in {start_year}
      </p>
    ) : (
      <p className="worker-summary-card__experience-years_start-year">
        <span className="worker-summary-card__experience-years_exp">
          {Math.floor(years)} years
        </span>{' '}
        starting in {start_year}
      </p>
    )

  const iconClass = isVisibleDesc
    ? 'worker-summary-card__button-close'
    : 'worker-summary-card__button-open'

  return (
    <div className="worker-summary-card">
      <button
        hidden={!description}
        className={`worker-summary-card__button ${iconClass}`}
        onClick={handleToggle}
      />
      <div
        className={`worker-summary-card__experience ${
          noDescription && 'worker-summary-card__experience_no-icon'
        }`}
      >
        <div className="worker-summary-card__experience-jobs">
          <p>{`${company_name}, ${job_title}`}</p>
        </div>
        <div className="worker-summary-card__experience-years">
          {experienceYears}
        </div>
      </div>
      {isVisibleDesc && (
        <p className="worker-summary-card__experience-description">
          {description}
        </p>
      )}
    </div>
  )
}
