import React, { HTMLAttributes, useCallback, useMemo, useState } from 'react'
import { CheckboxSelectUI } from './CheckboxSelectUI'

type TCheckboxSelectItem = {
  key: string
  value: string
  isChecked: boolean
}

export interface ICheckboxSelectProps extends HTMLAttributes<HTMLElement> {
  listName: string
  list: TCheckboxSelectItem[]
  checkboxHandler: (key: string, isChecked: boolean) => void
  filterOpeningTogler?: (filterName: string, isOpen: boolean) => void
  filterIsOpen?: Record<string, boolean>
  filterName?: string
}

export const CheckboxSelect = (props: ICheckboxSelectProps) => {
  const { listName, list, filterOpeningTogler, filterIsOpen, filterName } =
    props

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const externalIsOpen = useMemo(
    () => (filterIsOpen && filterName ? filterIsOpen[filterName] : isOpen),
    [filterIsOpen, filterName, isOpen]
  )

  const btnTitle = useMemo(() => {
    const checkedList = list.filter(item => item.isChecked)
    if (!checkedList.length) return listName
    return checkedList[0].value + (checkedList.length > 1 ? ', ...' : '')
  }, [list, listName])

  const onBtnClick = useCallback(() => {
    if (filterOpeningTogler) {
      filterOpeningTogler(filterName ?? '', !externalIsOpen)
    } else {
      setIsOpen(!isOpen)
    }
  }, [isOpen, filterOpeningTogler, filterName, externalIsOpen])

  return (
    <CheckboxSelectUI
      {...props}
      btnTitle={btnTitle}
      onBtnClick={onBtnClick}
      isOpen={externalIsOpen}
      isSelected={listName !== btnTitle}
    />
  )
}
