import { ILocationInfoOB } from '../../models'

export const defaultLocationInfo: ILocationInfoOB = {
  home_location: {
    zip_code: null,
    longitude: null,
    latitude: null,
    state: null,
    city: null,
    street: null,
    raw_input: null,
  },
  relocation_location: {
    zip_code: null,
    longitude: null,
    latitude: null,
    state: null,
    city: null,
    street: null,
    date: null,
    raw_input: null,
  },
  preferred_locations: null,
  willing_to_relocate: null,
}

export enum EWorkerPageTitle {
  dashboard = 'Skillit - Dashboard',
  training = 'Skillit - Training',
  profile = 'Skillit - Profile',
  welcome = 'Skillit - Welcome',
  assessment = 'Skillit - Assessment',
}

export const defaultTravelDistance = 50
