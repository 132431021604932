import React from 'react'
import Calendar, { Detail } from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import './bookingCalendar.scss'
import { scheduleCurrentType } from '../../../../../utils/constants'
import { LoadingContent, NavigationFooter } from '../../../../molecules'
import { PopupLoader } from '../../../General'
import moment from 'moment'
import { Button, Input } from '../../../../atoms'
import {
  DailySlots,
  DaySlot,
  FreeTimes,
  IUserDataSlots,
  Onboarding,
  SpecificJob,
  UserReservation,
} from '../../../../../models'
import { getDailySlotsArray } from '../../../../../utils/scripts'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  interviewType: 'In-Person' | 'Phone'
  activeButtonIndex: number
  isPopupLoaderShowed: boolean
  date: Date
  type: Onboarding | SpecificJob | null
  slotsData?: IUserDataSlots
  setTime: () => void
  onClickMonth: (date: Date) => void
  onChangeDate: (selectedDate: Date) => void
  handleActiveDate: (slot: FreeTimes, date: string) => void
  addSlots: (
    mainArr: DaySlot[] | undefined,
    quantity: number,
    newDate?: Date
  ) => void
  getTime: (time: string) => string
  getCalendarLabel: (label: 'prev' | 'next') => JSX.Element
  getTileDisabled: (date: Date, view: Detail) => boolean
  isLoading: boolean
  slots?: DaySlot[]
  selectedTime?: UserReservation
  getSlots?: {
    daysWithSlots: DailySlots[]
    slotsWithDays: DaySlot[]
  }
  isNavFooterShowed?: true
  isPhoneFromServer?: boolean
  phone: string
  onChangePhone: (e: React.FormEvent<HTMLInputElement>) => void
  interviewerName: string
  minDateFromStorage: string | null
}

export const BookingCalendarUI = (props: IProps) => {
  const {
    interviewType,
    activeButtonIndex,
    isPopupLoaderShowed,
    date,
    type,
    slotsData,
    setTime,
    onClickMonth,
    onChangeDate,
    handleActiveDate,
    addSlots,
    getTime,
    getCalendarLabel,
    getTileDisabled,
    isLoading,
    slots,
    selectedTime,
    getSlots,
    isNavFooterShowed,
    isPhoneFromServer,
    onChangePhone,
    phone,
    interviewerName,
    minDateFromStorage,
  } = props

  return (
    <div className="schedule">
      {isPopupLoaderShowed && <PopupLoader />}

      <h3
        className={
          type === scheduleCurrentType.onboarding
            ? 'schedule__title-onboarding'
            : 'schedule__title'
        }
      >
        {type === scheduleCurrentType.onboarding
          ? 'Schedule'
          : `Schedule Your ${interviewType} Interview with ${interviewerName}`}
      </h3>

      <LoadingContent
        isLoading={isLoading}
        className="schedule__time-block-loader"
      >
        {!isPhoneFromServer && (
          <div className="schedule__phone">
            <p className="schedule__phone-description">
              What is the best phone number to reach you?
            </p>
            <Input
              value={phone}
              onChange={onChangePhone}
              inputClassName="schedule__phone-input"
            />
          </div>
        )}

        <p className="schedule__description">
          {`Pick the best time for your ${
            slotsData?.slots.duration ?? ''
          } minutes ${
            type === scheduleCurrentType.onboarding
              ? 'phone chat with your Skillit Career Guide.'
              : `${interviewType} interview.`
          }`}
        </p>

        <div className="schedule__content">
          <Calendar
            className="schedule__calendar schedule__calendar"
            minDate={new Date(minDateFromStorage ?? '')}
            showNeighboringMonth={false}
            calendarType="US"
            onClickMonth={value => onClickMonth(value)}
            formatShortWeekday={(locale, date) => moment(date).format('dd')}
            tileDisabled={({ activeStartDate, date, view }) =>
              getTileDisabled(date, view)
            }
            minDetail="year"
            nextLabel={getCalendarLabel('next')}
            prevLabel={getCalendarLabel('prev')}
            next2Label={null}
            prev2Label={null}
            onClickDay={value => onChangeDate(value)}
            value={date}
          />

          <div className="schedule__time-block">
            <div className="schedule__time-block-list-container">
              <ul className="schedule__daily-slots-list">
                {getDailySlotsArray(slots).length
                  ? getDailySlotsArray(slots).map(day => (
                      <li key={day.date} className="schedule__daily-slots-day">
                        <div className="schedule__time-block-date-title">
                          {`${moment(day.date).format('ddd')}, ${moment(
                            day.date
                          ).format('MMM D')}`}
                        </div>

                        <ul className={`schedule__time-block-list`}>
                          {day.slots.map(item => (
                            <li
                              key={item.id}
                              onClick={() => {
                                handleActiveDate(item, day.date)
                              }}
                              onKeyDown={event => {
                                if (event.key === 'Enter') {
                                  handleActiveDate(item, day.date)
                                }
                              }}
                              className="schedule__time-block-item"
                            >
                              <Button
                                className={`schedule__time-block-item-btn ${
                                  activeButtonIndex === item.id
                                    ? 'schedule__time-block--active'
                                    : ''
                                }`}
                              >
                                {`${getTime(item.starts_at)} - ${getTime(
                                  item.ends_at
                                )} ${item.timezone}`}
                              </Button>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))
                  : 'Sorry, it looks like there is no free time for this date.'}
              </ul>
              {!!getDailySlotsArray(slots).length && (
                <Button
                  className="schedule__time-block-see-more-btn"
                  disabled={false}
                  onClick={() => addSlots(getSlots?.slotsWithDays, 6)}
                >
                  see More Times
                </Button>
              )}
            </div>
          </div>
        </div>
      </LoadingContent>

      {isNavFooterShowed ? (
        <NavigationFooter className="schedule__navfooter">
          <Button
            className="schedule__time-block-btn"
            data-testid="schedule__time-block-btn_select-time"
            disabled={!selectedTime}
            onClick={setTime}
          >
            Select Time
          </Button>
        </NavigationFooter>
      ) : (
        <Button
          className="schedule__time-block-btn"
          data-testid="schedule__time-block-btn_select-time"
          disabled={!selectedTime || (!isPhoneFromServer && !phone.length)}
          onClick={setTime}
        >
          Select Time
        </Button>
      )}
    </div>
  )
}
