import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useEffect,
} from 'react'
import { UsersTableUI } from './UsersTableUI'
import { WorkersDataForAdminContext } from '../../../../Context'
import { EAdminWorkersSStoreKeys } from '../../../../../utils/constants'
import { setIsEmployer, useAppDispatch } from '../../../../../redux'

interface Props extends React.HTMLAttributes<HTMLElement> {
  isLoading: boolean
}

export const UsersTable = (props: Props) => {
  const { workersList, queryParams, setQueryParams, setCurrentPage } =
    useContext(WorkersDataForAdminContext)
  const dispatch = useAppDispatch()
  const tableRef = useRef<HTMLDivElement>(null)
  const isAscSort = useMemo(
    () => queryParams?.sort_params.type === 'ascending',
    [queryParams?.sort_params.type]
  )

  const getSortOrder = useCallback(
    (sortName: string) => {
      if (queryParams?.sort_params.field !== sortName) {
        return 'ascending'
      }

      return isAscSort ? 'descending' : 'ascending'
    },
    [queryParams, isAscSort]
  )

  const handleSorting = useCallback(
    (sortName: string) => {
      setQueryParams(prevParams => ({
        ...prevParams,
        page_number: 0,
        sort_params: {
          field: sortName,
          type: getSortOrder(sortName),
        },
      }))

      setCurrentPage(0)
    },
    [setCurrentPage, setQueryParams, getSortOrder]
  )

  const transitionToEmployer = useCallback(() => {
    dispatch(setIsEmployer(true))
    sessionStorage.removeItem(EAdminWorkersSStoreKeys.isAdmin)
    sessionStorage.setItem(
      EAdminWorkersSStoreKeys.transitionFromAdmin,
      JSON.stringify(true)
    )
  }, [dispatch])

  useEffect(() => {
    sessionStorage.removeItem(EAdminWorkersSStoreKeys.transitionFromAdmin)
  }, [])

  return (
    <UsersTableUI
      {...props}
      workersList={workersList}
      queryParams={queryParams}
      isAscSort={isAscSort}
      handleSorting={handleSorting}
      tableRef={tableRef}
      transitionToEmployer={transitionToEmployer}
    />
  )
}
