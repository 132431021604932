import React, { ReactElement, useEffect } from 'react'
import { useStateWithDep } from '../../../utils/hooks'
import './checkbox.scss'

export enum ECheckboxTheme {
  orange = 'orange',
}

interface Props extends React.HTMLAttributes<HTMLInputElement> {
  name: string | ReactElement
  idCheckbox: number
  toggleCheckbox: (id: number, isChecked: boolean) => void
  checked?: true
  buttonsGrid?: true
  remove?: (isChecked: boolean) => void
  disabled?: true
  theme?: ECheckboxTheme.orange
}

export const Checkbox = (props: Props) => {
  const {
    style,
    name,
    buttonsGrid,
    disabled,
    toggleCheckbox,
    checked,
    idCheckbox,
    remove,
    theme,
  } = props
  const [isChecked, setIsChecked] = useStateWithDep<boolean>(checked ?? false)
  const mainClassName =
    (buttonsGrid ? 'checkbox__container-button-grid' : '') +
    (props.className ? ` ${props.className}` : '') +
    (theme ? ` checkbox_theme-${theme}` : '')

  const onChange = () => {
    setIsChecked(!isChecked)
    toggleCheckbox(idCheckbox, isChecked)
  }

  useEffect(() => {
    if (disabled && isChecked) {
      setIsChecked(false)
      toggleCheckbox(idCheckbox, isChecked)
    }
  }, [disabled, idCheckbox, isChecked, setIsChecked, toggleCheckbox])

  return (
    <label
      style={style}
      className={`checkbox checkbox__container ${mainClassName}`}
      data-testid={`checkbox__label_${name}`}
    >
      {name}
      <input
        checked={isChecked}
        onChange={() => {
          remove && remove(isChecked)
          onChange()
        }}
        className={`${buttonsGrid && 'checkbox__button-grid'} checkbox__input`}
        type="checkbox"
        disabled={disabled}
        data-testid={`checkbox__input_${name}`}
      />
      <span
        className={`${
          buttonsGrid && 'checkbox__button-grid-checkmark'
        } checkbox__checkmark`}
      ></span>
    </label>
  )
}
