import React, { useState } from 'react'
import {
  AssessmentInfo,
  AssessmentResult,
  PublicInfo,
} from '../../../../../models'
import { toast } from 'react-toastify'
import {
  getAssessmentResults,
  getAssessmentForms,
  updatePublicInfo,
} from '../../../../../services/workerApi/profileAPI'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  ProfileAnalyticsName,
  WorkerQueries,
} from '../../../../../utils/constants'
import { QueryState } from 'react-query/types/core/query'
import { onTrackingActions } from '../../../../../utils/scripts'
import { ERROR_SOMETHING } from '../../../../../services/auth-service'
import { ProfileAssessmentBlockUI } from './ProfileAssessmentBlockUI'
import './profileAssessmentBlock.scss'

interface Props extends React.HTMLAttributes<HTMLElement> {
  publicInfo: PublicInfo
  uuid: string
}

export const ProfileAssessmentBlock = (props: Props) => {
  const { publicInfo, uuid, className } = props
  const queryClient = useQueryClient()
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const assessmentsResultsQuery:
    | QueryState<AssessmentResult[], undefined>
    | undefined = queryClient.getQueryState(WorkerQueries.assessmentResults)

  const { data: assessments } = useQuery(
    WorkerQueries.assessmentResults,
    () => getAssessmentResults(),
    {
      enabled: !assessmentsResultsQuery?.data,
    }
  )

  const assessmentsFormsQuery:
    | QueryState<AssessmentInfo, undefined>
    | undefined = queryClient.getQueryState([
    WorkerQueries.availableForms,
    true,
    uuid,
    true,
  ])

  const { data: skillitForms } = useQuery(
    [WorkerQueries.availableForms, true, uuid, true],
    () => getAssessmentForms(true, uuid, true),
    {
      enabled: !assessmentsFormsQuery?.data,
    }
  )

  const publicInfoQuery: QueryState<PublicInfo, undefined> | undefined =
    queryClient.getQueryState(WorkerQueries.publicInfo)

  const scoreDisplayMutation = useMutation((data: { score_display: boolean }) =>
    updatePublicInfo(data)
  )

  const switchBtnHandler = (status: boolean) => {
    const updatedPublicInfo = {
      ...publicInfoQuery?.data,
      score_display: status,
    }
    scoreDisplayMutation.mutate(
      { score_display: status },
      {
        onSuccess: () => {
          onTrackingActions(ProfileAnalyticsName.profile_edited)
          queryClient.setQueryData(WorkerQueries.publicInfo, updatedPublicInfo)
        },
        onError: err => {
          if (err instanceof Error) toast.error(ERROR_SOMETHING)
        },
      }
    )
  }

  return (
    <ProfileAssessmentBlockUI
      assessments={assessments}
      publicInfo={publicInfo}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      switchBtnHandler={switchBtnHandler}
      skillitForms={skillitForms}
      className={className}
    />
  )
}
