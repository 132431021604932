import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './languages/en.json'
import es from './languages/es.json'

i18next.use(initReactI18next).init({
  fallbackLng: 'en',
  resources: {
    en: {
      translation: en,
    },
    es: {
      translation: es,
    },
  },
})
