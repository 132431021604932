import React from 'react'
import { Button, CandidatesCounter, SearchItem } from '../../../atoms'
import searchIcon from '../../../../assets/icons/other/search-icon.svg'
import './usersTab.scss'
import { ModalBox, Pagination } from '../../../molecules'
import { UsersTable } from './UsersTable'
import { AddResumeModal } from './AddResumeModal'
import { InviteWorker } from './InviteWorker'
import { workWithTypeData, UploadResume, TUploadResume } from '../../General'
import { uploadResumeOfInvitedWorker } from '../../../../services/adminWorkersAPI'
import {
  IUploadResumeOfInvitedWorker,
  IWorkerInfoAdminPage,
} from '../../../../models/AdminWorkes'
import { WorkerFilters } from '../../../molecules/Admin/WorkerFilters/WorkerFilters'

interface Props {
  setIsResumeModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  isResumeModalOpen: boolean
  setIsInviteOpen: React.Dispatch<React.SetStateAction<boolean>>
  isInviteOpen: boolean
  setNewWorker: React.Dispatch<
    React.SetStateAction<IUploadResumeOfInvitedWorker | null>
  >
  newWorker: IUploadResumeOfInvitedWorker | null
  setUploadedResume: React.Dispatch<React.SetStateAction<File | null>>
  uploadedResume: File | null
  handlePageChange: (page: number) => void
  currentPage: number
  totalWorkers: number | null
  totalPages: number | null
  isLoading: boolean
  setTotalWorkers: React.Dispatch<React.SetStateAction<number | null>>
  onDragOver: (e: React.DragEvent) => void
  onDragLeave: (e: React.DragEvent) => void
  onDrop: (e: React.DragEvent) => void
  classDragEnter: boolean
  file: File | null
  setSearchValue: React.Dispatch<React.SetStateAction<string | null>>
  searchResult: string
  searchValue: string | null
  handleSearch: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onCloseSearchResult: () => void
  setFile: React.Dispatch<React.SetStateAction<File | null>>
  setClassDragEnter: React.Dispatch<React.SetStateAction<boolean>>
  uploadResumeRef: React.ForwardedRef<TUploadResume>
  handleRefetchListOfWorker: () => Promise<void>
  workersList: IWorkerInfoAdminPage[]
}

export const UsersTabUI = (props: Props) => {
  const {
    setIsResumeModalOpen,
    isResumeModalOpen,
    setIsInviteOpen,
    isInviteOpen,
    setNewWorker,
    newWorker,
    uploadedResume,
    setUploadedResume,
    handlePageChange,
    currentPage,
    totalWorkers,
    totalPages,
    isLoading,
    setTotalWorkers,
    onDragOver,
    onDragLeave,
    onDrop,
    classDragEnter,
    file,
    searchResult,
    searchValue,
    setSearchValue,
    handleSearch,
    onCloseSearchResult,
    setFile,
    setClassDragEnter,
    uploadResumeRef,
    handleRefetchListOfWorker,
    workersList,
  } = props

  return (
    <div
      className="users-tab"
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      {(isResumeModalOpen || isInviteOpen) && (
        <ModalBox
          className={`modal-box_external-close-Icon ${
            isResumeModalOpen ? 'invite-upload-resume' : ''
          }`}
          onCloseModal={() => {
            setIsResumeModalOpen(false)
            setIsInviteOpen(false)
          }}
          visibleCloseIcon
        >
          {isResumeModalOpen ? (
            <AddResumeModal
              setIsResumeModalOpen={setIsResumeModalOpen}
              setIsInviteOpen={setIsInviteOpen}
              setNewWorker={setNewWorker}
              file={file}
              classDragEnter={classDragEnter}
              setUploadedResume={setUploadedResume}
              setFile={setFile}
              setClassDragEnter={setClassDragEnter}
              uploadResumeRef={uploadResumeRef}
            />
          ) : (
            <InviteWorker
              handleRefetchListOfWorker={handleRefetchListOfWorker}
              setIsInviteOpen={setIsInviteOpen}
              newWorker={newWorker}
              setNewWorker={setNewWorker}
              uploadedResume={uploadedResume}
              setUploadedResume={setUploadedResume}
              setTotalWorkers={setTotalWorkers}
            />
          )}
        </ModalBox>
      )}
      {!!searchResult && (
        <div className="users-tab__search-result">
          <p className="users-tab__search-result-title">
            Displays results for:
          </p>
          <SearchItem
            item={searchResult}
            className="users-tab__search-result-item"
            onRemove={onCloseSearchResult}
          />
        </div>
      )}

      <div className="users-tab__header">
        <div className="users-tab__btns-wrapper">
          <Button
            className="users-tab__btn-invite_user"
            onClick={() => {
              setIsResumeModalOpen(true)
              setUploadedResume(null)
            }}
          >
            Invite User +
          </Button>
          <WorkerFilters />
        </div>
        <div className="users-tab__search">
          <img
            className="users-tab__search-icon"
            src={searchIcon}
            alt="search"
          />

          <input
            className="users-tab__search-input"
            type="text"
            value={searchValue ?? ''}
            placeholder="Name or Email"
            onChange={e => setSearchValue(e.target.value)}
            onKeyPress={handleSearch}
          />
        </div>
      </div>

      <div className="users-tab__table-wrapper">
        <UsersTable isLoading={isLoading} className="users-tab__table" />
        {!workersList.length && !isLoading && (
          <div className="users-tab__table-empty-list-wrapper">
            <p className="users-tab__table-empty-list">No results found</p>
          </div>
        )}
      </div>

      {!(isResumeModalOpen || isInviteOpen) && !isLoading && (
        <>
          {!!workersList.length && (
            <div className="users-tab__pagination-wrapper">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages ?? 1}
                handlePageChange={handlePageChange}
                className="users-tab__pagination"
              />
              <CandidatesCounter count={totalWorkers ?? undefined} />
            </div>
          )}

          <UploadResume
            className="users-tab__resume"
            accept={workWithTypeData.resumeSupportTypes().toString()}
            updatedFile={undefined}
            description="drag & drop a resume"
            file={file}
            classDragEnter={classDragEnter}
            uploadAction={uploadResumeOfInvitedWorker}
            setIsInviteOpen={setIsInviteOpen}
            setNewWorker={setNewWorker}
            setUploadedResume={setUploadedResume}
            setFile={setFile}
            setClassDragEnter={setClassDragEnter}
            ref={uploadResumeRef}
          />
        </>
      )}
    </div>
  )
}
