import { ScheduleTypeEnum } from './ScheduleModel'
import { FilterType, osha_status } from './EmployeeParams'
import { ETravelDistance } from '../utils/constants'

export enum AssmntStatus {
  notSent = 'available',
  sent = 'pending',
  passed = 'completed',
}

export interface WorkerJob {
  company_name: string
  job_title: string
  dates: string
  location: string | null
  months: number
  start_year: string
  years: number
  description: string | null
}

export interface WorkerInfoPastEmployment {
  total_years: number
  experience: WorkerJob[]
}

export interface IWorkerSkill {
  level: number | null
  value: string | null
}

export interface WorkerInfoDetails {
  resume: string
  certifications: { [key: string]: string[] }
  past_employment_and_experience: WorkerInfoPastEmployment
  skills: IWorkerSkill[]
}

export interface Rating {
  percentage: number
  number: number
}

export interface MyFavoritesWorker {
  id: number
  profile_picture: string | null
  first_name: string
  full_role: string
  last_name: string
  zip_code: string
  unique_url: string
}

export interface CompanyInterviewDetails {
  company_info: EmployerCompanyInfo
  id: number
  notes: string
  overall_score: number
}

export interface SkillitInterviewDetails {
  attendance_punctuality_score: number | null
  id: number
  overall_score: number | null
  professionalism_score: number | null
  rating: {
    percentage: number
    number: number
    zip_code: string
    state: string
  } | null
  attitude_score: number | null
}

export interface CompanyInterview {
  available: boolean
  pending: boolean
  details: CompanyInterviewDetails | null
  scheduled_date: string | null
}

export interface SkillitInterview {
  available: boolean
  details: SkillitInterviewDetails
}

export interface WorkerInfo {
  id: number
  background_image: string
  about_me: string
  details: WorkerInfoDetails
  assessment_scores: AssessmentModal[]
  user_card: UserCard
  my_favourites: MyFavoritesWorker[]
  interview_scores: {
    company_interview: CompanyInterview
    skillit_interview: SkillitInterview
  }
}

export type OshaType =
  | osha_status.osha_10
  | osha_status.osha_30
  | osha_status.willingToObtain_OSHA_30
  | osha_status.osha_40
  | osha_status.osha_62

export interface ListOfPassedTests {
  background_check: boolean
  company_info: { company: string; interview: boolean; assessment: boolean }
  skillit_info: { interview: boolean; assessment: boolean }
  osha: OshaType[]
}

export interface UserCard {
  id: number
  first_name: string
  last_name: string
  phone: string
  travel_distance: string | null
  desired_pay_rate: string | null
  profile_picture: string
  assessment_score: number
  interview_score: number | null
  experience: string
  is_saved: boolean
  is_hidden: boolean
  full_role: string
  zip_code: string | null
  previously: string[]
  list_of_passed: ListOfPassedTests
  is_new: boolean
  resume: string | null
  interview_status_info: {
    date: string | null
    requested_by: {
      first_name: string
      last_name: string
      profile_picture: string | null
      username: string
    } | null
    status: string | null
  } | null
  is_recently_viewed: boolean
  union: boolean | null
  unique_url: string
  city: string | null
  state: string | null
  languages: {
    preferred_language: string | null
    languages: string[]
  }
}

export interface ListOfWorkers {
  total_pages: number
  total_employees: number
  employees_list: UserCard[]
}

export interface AssessmentFilterParams {
  id: number
  key: string
  company: string
  name: string
}

export interface FilterItem {
  id: number
  choice: string
}

export interface IFilterParams {
  assessment: AssessmentFilterParams[] | null
  interview: { skillit_company_id: number | null }
  skills: FilterItem[] | null
  qualifications:
    | {
        group: string
        certifications: FilterItem[]
      }[]
    | null
  languages: FilterItem[] | null
  roles: FilterItem[] | null
  location:
    | {
        zip_code: string
        city: string
        name: string
      }[]
    | null
}

export interface AssessmentScore {
  id: number
  value: string
}

export interface IInterviewScore {
  company_id: number
  value: string
}

export interface IFilterType {
  type: FilterType
  properties: string | null
}

export interface SkillsType extends Omit<SkillsTypeQuery, 'value'> {
  exact: boolean
  value: FilterItem
}

export interface SkillsTypeQuery {
  value: number
}

type ExcludeFilters<T> = Omit<
  T,
  'skills' | 'role' | 'training_certifications' | 'osha_status' | 'language'
>

export interface WorkersFilter extends ExcludeFilters<WorkersFilterQuery> {
  osha_status: FilterItem[] | null
  training_certifications: FilterItem[] | null
  role: FilterItem[] | null
  language: FilterItem[] | null
  skills: SkillsType[] | null
}

export interface WorkersFilterQuery {
  type: IFilterType
  page_number: number | null
  page_size: number | null
  most_recent_pay_rate_min: string | null
  most_recent_pay_rate_max: string | null
  desired_pay_rate_min: string | null
  desired_pay_rate_max: string | null
  experience_years: number[] | null
  vehicle: boolean | null
  valid_drivers_license: boolean | null
  osha_status: number[] | null
  interview_score: IInterviewScore[] | null
  training_certifications: number[] | null
  travel_distance_interval: ETravelDistance[] | null
  work_experience: string | null
  assessment_score: AssessmentScore[] | null
  profile_picture_field: boolean | null
  name: string | null
  role: number[] | null
  skills: SkillsTypeQuery[] | null
  location: string[] | null
  relocate: boolean | null
  unions: boolean | null
  language: number[] | null
  eligible_work_us: boolean | null
}

export interface Benchmark {
  company_name: string
  salary: number
}

export interface EmployerCompanyInfo {
  name: string
  logo: string
}
export interface EmployerInfo {
  basic_info: {
    first_name: string
    profile_picture: string | null
    last_name: string
    username: string
  }
  co_workers: CoworkerData[]
  company_info: EmployerCompanyInfo
}

export interface CoworkerData {
  first_name: string
  last_name: string
  profile_picture: string | null
  username: string
}

export interface LastSharedCoworkerList {
  co_workers_list: CoworkerData[]
}

export interface AssessmentModal {
  key: string
  name: string | null
  description: string
  url: string
  company_info: {
    name: string
    logo: string | null
  }
  status: {
    status: string
    details: AssmntDetails | null
  }
}

export interface AssmntDetails {
  sent_by?: string
  date?: string
  name: string
  overall_score: number
  categories_scores?: CategoriesScores
  zip_code: number
  rating: Rating
}

export interface CategoriesScores {
  [key: string]: number | undefined
}

export interface CompanyIntervewRating {
  overall_score: number
  notes: string | null
}

export interface InterviewDate {
  starts_at: string
  ends_at: string
  timezone: string
  event_type: ScheduleTypeEnum
  address: number | null
}

export interface WorkerInInstantSearch {
  employees?: SearchEmployees[]
  roles?: string[]
  location?: ILocation[]
  skills?: FilterItem[]
}

export interface ILocation {
  city: string
  zip_code: string
}

export interface SearchEmployees {
  id: number
  full_name: string
  full_role: string | null
  profile_picture: string | null
  unique_url: string
}
export interface SkillsTypeRecentSearch {
  exact: boolean
  value: number
}
export interface RecentSearches {
  type: 'regular' | 'instant' | 'name'
  searched_user: number | null
  search_string: string | null
  full_name: string | null
  roles: number[] | null
  skills: SkillsTypeRecentSearch[] | null
  zip_code: string[] | null
  unique_url: string
}

export type InterviewFormatTypes = 'In-Person' | 'Video' | 'Phone'

export interface IInterviewRequestData {
  type: InterviewFormatTypes
  role: string
  project_id: number
  availability: string
  trade_id: number
}

export interface EmployerInterviewType {
  type: InterviewFormatTypes
  available: boolean
}

export interface IInterviewProject {
  id: number
  name: string | null
}

export interface IAvailableInterview {
  available_interview_types: EmployerInterviewType[]
  projects: IInterviewProject[]
  trades: IInterviewProject[]
}

export interface IDataForShare {
  unique_url: string
  to_username: string
  note: string
  feedback_allowed: boolean
  interview_allowed: boolean
}

export interface IInterviewUrlData {
  token: string | null
  action?: string
}

export interface IFeedback {
  action: string
  interested: boolean
  feedback?: string | null
}
export interface ICoworkerData {
  first_name: string
  last_name: string
  profile_picture: string | null
  username: string
}

export interface IHistoryItem {
  action: string
  addressed_to: ICoworkerData
  created_at: string
  feedback: string | null
  owned_by: ICoworkerData | null
}

export interface IHistoryData {
  items: IHistoryItem[]
  next_page: number | null
  page: number
  previous_page: number | null
}

export interface IBetaVersionStatus {
  allow_beta_version: boolean
}
