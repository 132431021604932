import React, { useState } from 'react'

type InputChangeHandler = React.ChangeEvent<HTMLInputElement>

export const useInput = (
  initialValue: string = '',
  clearErrors?: () => void
) => {
  const [value, setValue] = useState(initialValue)

  const onChange = (event: InputChangeHandler) => {
    setValue(event.target.value)
    if (clearErrors) {
      clearErrors()
    }
  }
  const handler = {
    value,
    onChange,
  }
  return handler
}

export const useValidateInput = (
  initialValue: string,
  validator: (text: string) => boolean
) => {
  const [isValid, setIsValid] = useState(() => validator(initialValue))
  const inputState = useInput(initialValue)

  const onChange = (event: InputChangeHandler) => {
    const newIsValid = validator(event.target.value)
    setIsValid(newIsValid)
    inputState.onChange(event)
  }

  const value = inputState.value

  return {
    isValid,
    value,
    onChange,
  }
}
