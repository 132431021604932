import './navigator.scss'

interface ICoWorkerLogo extends React.HTMLAttributes<HTMLElement> {
  info: {
    first_name: string
    profile_picture: string | null
  }
  owner?: true
}

export const CoWorkerLogo = (props: ICoWorkerLogo) => {
  const { info, owner } = props

  return info.profile_picture ? (
    <img
      src={info.profile_picture}
      alt="user_picture"
      className={`navbar-list__logo ${owner ? 'navbar-list__logo_owner' : ''}`}
      style={props.style}
    />
  ) : (
    <div
      className={`navbar-list__logo ${owner ? 'navbar-list__logo_owner' : ''}`}
      style={props.style}
    >
      {info.first_name[0]}
    </div>
  )
}
