import React, { useEffect, useMemo, useRef, useState } from 'react'
import { UsersTabUI } from './UsersTabUI'
import {
  IQueryParamsWorkersList,
  IUploadResumeOfInvitedWorker,
  IWorkerInfoAdminPage,
} from '../../../../models/AdminWorkes'
import { getListOfWorkersAdminPage } from '../../../../services/adminWorkersAPI'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import {
  AdminWorkerQueries,
  EAdminWorkersSStoreKeys,
} from '../../../../utils/constants'
import { useStateWithDep } from '../../../../utils/hooks'
import { TUploadResume } from '../../General'
import { WorkersDataForAdminContext } from '../../../Context'
import { IDatePeriod } from '../../../atoms'
import moment from 'moment'

export const UsersTab = () => {
  const queryParamsFromStorage = JSON.parse(
    `${sessionStorage.getItem(EAdminWorkersSStoreKeys.queryParams)}`
  )

  const [isResumeModalOpen, setIsResumeModalOpen] = useState(false)
  const [isInviteOpen, setIsInviteOpen] = useState(false)
  const [workersList, setWorkersList] = useState<IWorkerInfoAdminPage[]>([])
  const [newWorker, setNewWorker] =
    useState<IUploadResumeOfInvitedWorker | null>(null)
  const [uploadedResume, setUploadedResume] = useState<File | null>(null)
  const [currentPage, setCurrentPage] = useState(
    queryParamsFromStorage?.page_number ?? 0
  )
  const [totalWorkers, setTotalWorkers] = useState<number | null>(null)
  const [totalPages, setTotalPages] = useState<number | null>(null)
  const displayWorkersOnPage = 20
  const [classDragEnter, setClassDragEnter] = useState(false)
  const [file, setFile] = useStateWithDep<File | null>(null)

  const initialQueryParams: IQueryParamsWorkersList = useMemo(
    () => ({
      page_size: displayWorkersOnPage,
      page_number: currentPage,
      query_string: null,
      status: null,
      roles: null,
      date_min: null,
      date_max: null,
      locations: null,
      sort_params: { field: 'created', type: 'descending' },
    }),
    [currentPage]
  )

  const [queryParams, setQueryParams] = useState<IQueryParamsWorkersList>(
    queryParamsFromStorage ?? initialQueryParams
  )
  const [period, setPeriod] = useState<IDatePeriod>({
    date_min: queryParams.date_min
      ? moment(queryParams.date_min, 'M/D/YY').toDate()
      : null,
    date_max: queryParams.date_max
      ? moment(queryParams.date_max, 'M/D/YY').toDate()
      : null,
  })

  const uploadResumeRef = useRef<TUploadResume>(null)
  const [searchValue, setSearchValue] = useState<string | null>(null)
  const [searchResult, setSearchResult] = useState(
    queryParamsFromStorage?.query_string ?? ''
  )

  const contextProps = useMemo(
    () => ({
      workersList: workersList,
      setWorkersList: setWorkersList,
      queryParams: queryParams,
      setQueryParams: setQueryParams,
      period: period,
      setPeriod: setPeriod,
      setCurrentPage,
    }),
    [workersList, queryParams, period]
  )

  const searchQueryString = useMemo(() => {
    if (searchValue) return searchValue
    if (searchResult) return searchResult
    return null
  }, [searchValue, searchResult])

  const pageNumber = useMemo(() => {
    if (!searchQueryString && currentPage && searchResult) return 0
    if (searchResult && searchQueryString && searchResult !== searchQueryString)
      return 0

    return currentPage
  }, [currentPage, searchResult, searchQueryString])

  const {
    data: listOfWorkers,
    refetch: getListOfWorkerRefresh,
    isLoading,
    isRefetching,
  } = useQuery(
    AdminWorkerQueries.listOfWorkersAdminPage,
    () =>
      getListOfWorkersAdminPage({
        ...queryParams,
        page_number: pageNumber,
        query_string: searchQueryString,
      }),
    {
      onSuccess() {
        sessionStorage.setItem(
          EAdminWorkersSStoreKeys.queryParams,
          JSON.stringify({
            ...queryParams,
            page_number: pageNumber,
            query_string: searchQueryString,
          })
        )
      },
      onError: err => {
        if (err instanceof Error) toast.error(err.message)
      },
    }
  )

  const updateListWorkerMutation = useMutation(
    (data: IQueryParamsWorkersList) => getListOfWorkersAdminPage(data),
    {
      onSuccess(data) {
        setWorkersList(data.workers_list)
        setTotalWorkers(data.total_workers_number)
        setTotalPages(data.total_pages)
      },
      onError(err) {
        if (err instanceof Error) toast.error(err.message)
      },
    }
  )

  const handleRefetchListOfWorker = async () => {
    await getListOfWorkerRefresh()
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const onDragOver = (e: React.DragEvent) => {
    e.preventDefault()
    !file && !isInviteOpen && setClassDragEnter(true)
  }

  const onDragLeave = (e: React.DragEvent) => {
    e.preventDefault()
    !file && !isInviteOpen && setClassDragEnter(false)
  }

  const onDrop = (e: React.DragEvent) => {
    e.preventDefault()
    if (!file && !isInviteOpen) {
      setClassDragEnter(false)
      uploadResumeRef.current?.handleFile(e.dataTransfer.files[0])
    }
  }
  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.currentTarget?.blur()
      if (searchValue) {
        updateListWorkerMutation.mutate({
          ...queryParams,
          query_string: searchValue,
        })
        setSearchResult(searchValue)
        sessionStorage.setItem(
          EAdminWorkersSStoreKeys.queryParams,
          JSON.stringify({
            ...queryParams,
            query_string: searchValue,
          })
        )
      }
      setCurrentPage(0)
      setSearchValue(null)
    }
  }

  const onCloseSearchResult = () => {
    updateListWorkerMutation.mutate({
      ...queryParams,
      query_string: null,
    })
    sessionStorage.setItem(
      EAdminWorkersSStoreKeys.queryParams,
      JSON.stringify({
        ...queryParams,
        page_number: 0,
        query_string: null,
      })
    )
    setSearchResult('')
    setCurrentPage(0)
  }

  useEffect(() => {
    getListOfWorkerRefresh()
  }, [currentPage, getListOfWorkerRefresh, queryParams])

  useEffect(() => {
    if (listOfWorkers) {
      setWorkersList(listOfWorkers.workers_list)
      setTotalWorkers(listOfWorkers.total_workers_number)
      setTotalPages(listOfWorkers.total_pages)
    }
  }, [listOfWorkers])

  return (
    <WorkersDataForAdminContext.Provider value={contextProps}>
      <UsersTabUI
        isResumeModalOpen={isResumeModalOpen}
        setIsResumeModalOpen={setIsResumeModalOpen}
        isInviteOpen={isInviteOpen}
        setIsInviteOpen={setIsInviteOpen}
        newWorker={newWorker}
        setNewWorker={setNewWorker}
        uploadedResume={uploadedResume}
        setUploadedResume={setUploadedResume}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
        totalWorkers={totalWorkers}
        totalPages={totalPages}
        isLoading={
          isLoading || isRefetching || updateListWorkerMutation.isLoading
        }
        setTotalWorkers={setTotalWorkers}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        classDragEnter={classDragEnter}
        file={file}
        setFile={setFile}
        setClassDragEnter={setClassDragEnter}
        uploadResumeRef={uploadResumeRef}
        handleRefetchListOfWorker={handleRefetchListOfWorker}
        searchResult={searchResult}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        handleSearch={handleSearch}
        onCloseSearchResult={onCloseSearchResult}
        workersList={workersList}
      />
    </WorkersDataForAdminContext.Provider>
  )
}
