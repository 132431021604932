import React from 'react'
import './progressCircle.scss'
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { ChallengeResultModel } from '../../../../models'
import { colorCalculator } from '../../../../utils/scripts/colorCalculator'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  result: ChallengeResultModel | null
}

export const ProgressCircle = (props: Props) => {
  const percentage = props.result
    ? colorCalculator.percentage(props.result.score)
    : null
  const color = props.result
    ? colorCalculator.color(props.result.score, false)
    : null

  return (
    <div
      className={`quiz-container__progress-block ${props.className ?? ''}`}
      data-testid="quiz-container__progress-block"
    >
      <CircularProgressbarWithChildren
        value={percentage ?? 0}
        className="progress-wrapper"
        circleRatio={0.75}
        minValue={0}
        maxValue={100}
        strokeWidth={10}
        styles={buildStyles({
          rotation: 1 / 2 + 1 / 8,
          strokeLinecap: 'round',
          pathColor: `url(#${'gradientColor'})`,
          textColor: `url(#${'gradientColor'})`,
          trailColor: '#E5E5E5',
        })}
      >
        <svg style={{ height: 0, visibility: 'hidden', opacity: 0 }}>
          <defs>
            <linearGradient
              id={'gradientColor'}
              gradientTransform={`rotate(90)`}
            >
              <stop offset="0%" stopColor={color !== null ? color.first : ''} />
              <stop
                offset="100%"
                stopColor={color !== null ? color.second : ''}
              />
            </linearGradient>
          </defs>
        </svg>
        <div className="progress-wrapper">
          <p className={`score ${color !== null ? color.classScore : ''}`}>{`${
            props.result ? props.result.score : ''
          }`}</p>
          <p
            className={`total ${color !== null ? color.classScore : ''}`}
          >{`Out of 10`}</p>
        </div>
      </CircularProgressbarWithChildren>
    </div>
  )
}
