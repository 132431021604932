import React, { useCallback, useState } from 'react'
import moment from 'moment'
import { BasicInfoModel, ILocationInfoOB } from '../../../../../../models'
import { BasicInfoFormUI } from './BasicInfoFormUI'
import { ZipCode } from 'use-places-autocomplete'
import { addGoogleLocation } from '../../../../../../utils/scripts'

export interface IBasicInfoForm {
  handleSubmit: (
    e: React.SyntheticEvent<HTMLFormElement>,
    movingPlanning: ILocationInfoOB
  ) => void
  basicInfo?: BasicInfoModel
  communicationData: string[]
  stateForm: BasicInfoModel
  setStateForm: React.Dispatch<React.SetStateAction<BasicInfoModel>>
  isConfirmationModalVisible: boolean
  setIsConfirmationModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  stateInfo?: BasicInfoModel
  minTravelDistance: number
  locationInfo: ILocationInfoOB
  setLocationInfo: React.Dispatch<React.SetStateAction<ILocationInfoOB>>
  defaultLocationInfo: ILocationInfoOB
}

export const BasicInfoForm = (props: IBasicInfoForm) => {
  const { locationInfo, setLocationInfo } = props

  const [isFilledPlace, setIsFilledPlace] = useState<boolean>(
    !!locationInfo.relocation_location?.latitude
  )

  const onSelectDate = (initDate: Date) => {
    if (locationInfo.relocation_location) {
      setLocationInfo({
        ...locationInfo,
        relocation_location: {
          ...locationInfo.relocation_location,
          date: moment(initDate).format('yyyy-MM-DD'),
        },
      })
    }
  }

  const onRemovedAddingPlace = useCallback(
    (uniqueKey: string, initPlacesList: ILocationInfoOB) => {
      const places = initPlacesList?.preferred_locations?.filter(
        (place, index) => {
          return `${index}${place.city}${place.state}` !== uniqueKey
        }
      )
      setLocationInfo(prevLocation => {
        return {
          ...prevLocation,
          preferred_locations: places?.length ? places : [],
        }
      })
    },
    [setLocationInfo]
  )

  const onAddPlace = useCallback(
    (
      initSuggestion: google.maps.places.AutocompletePrediction,
      initZipCode: ZipCode,
      initLat: number,
      initLng: number
    ) => {
      setLocationInfo(prevLocation =>
        addGoogleLocation(
          prevLocation,
          initSuggestion,
          initZipCode,
          initLat,
          initLng
        )
      )
    },
    [setLocationInfo]
  )

  return (
    <BasicInfoFormUI
      {...props}
      isFilledPlace={isFilledPlace}
      setIsFilledPlace={setIsFilledPlace}
      locationInfo={locationInfo}
      setLocationInfo={setLocationInfo}
      onAddPlace={onAddPlace}
      onRemovedAddingPlace={onRemovedAddingPlace}
      onSelectDate={onSelectDate}
    />
  )
}
