import { useState, useCallback } from 'react'
import { Button, Prompt } from '../../../../atoms'
import { ModalBox } from '../../../../molecules'
import { UploadFiles, Config } from '../../../General/UploadFiles'
import arrow from '../../../../../assets/icons/arrows/black_arrow_down.svg'
import checkArrow from '../../../../../assets/icons/checkmarks/yellow_checkmark_1.svg'
import './profileVideoBlock.scss'
import {
  workWithTypeData,
  TypeVideo,
} from '../../../General/UploadFiles/DataTypes/DataTypes'
import { updatePublicFiles } from '../../../../../services/workerApi/profileAPI'
import { useMutation, useQueryClient } from 'react-query'
import { PublicInfo } from '../../../../../models'
import { QueryState } from 'react-query/types/core/query'
import { WorkerQueries } from '../../../../../utils/constants'

interface Props {
  videoFile: string | undefined
  onTrackingUploadFile: () => void
}

export const ProfileVideoBlock = (props: Props) => {
  const queryClient = useQueryClient()
  const [exampleOne, setExampleOne] = useState(false)
  const [exampleTwo, setExampleTwo] = useState(false)
  const [isSeeMore, setIsSeeMore] = useState(false)

  const publicInfoQuery: QueryState<PublicInfo, undefined> | undefined =
    queryClient.getQueryState(WorkerQueries.publicInfo)

  const mutationPublicFiles = useMutation(
    (newData: { formData: FormData; config?: Config }) =>
      updatePublicFiles(newData.formData, newData.config)
  )

  const mutatePublicFiles = useCallback(
    (formData: FormData, config?: Config) => {
      let response: PublicInfo | Error
      mutationPublicFiles.mutate(
        { formData, config },
        {
          onSuccess: (updatedData: PublicInfo) => {
            queryClient.setQueryData(WorkerQueries.publicInfo, {
              ...publicInfoQuery?.data,
              video: updatedData.video,
            })

            response = updatedData
          },
          onError: err => {
            if (err instanceof Error) response = err
          },
        }
      )
      return new Promise(_ => response)
    },
    [mutationPublicFiles, publicInfoQuery?.data, queryClient]
  )

  return (
    <div className="container video-block" data-testid="video-block">
      {exampleOne && (
        <ModalBox visibleCloseIcon onCloseModal={() => setExampleOne(false)}>
          Example Video 1
        </ModalBox>
      )}

      {exampleTwo && (
        <ModalBox visibleCloseIcon onCloseModal={() => setExampleTwo(false)}>
          Example Video 2
        </ModalBox>
      )}
      <div className="video-block__block-name">
        <h3 className="video-block__name">About Video</h3>
        <Prompt
          text={'Upload an about video so that firms can get to know you.'}
        >
          <div className="prompt__profile-help">i</div>
        </Prompt>
      </div>

      <p className="video-block__description">Upload a video your Video Bio.</p>
      <UploadFiles
        accept={workWithTypeData.videoAllTypes().toString()}
        updatedFile={props.videoFile}
        description="Upload Your Own About Video"
        buttonName="Add My Video"
        typeData={TypeVideo}
        uploadButtonText="Change/Delete Video"
        modalTitleTxt="About Video"
        modalUploadTxt="Video"
        modalRemoveTxt="Current Video"
        formDataName="video"
        updateAction={mutatePublicFiles}
        onTrackingUploadFile={props.onTrackingUploadFile}
      />
      <div className="video-block__example-block">
        <Button
          onClick={() => setExampleOne(true)}
          className="profile_white-btn"
        >
          Watch Example Video 1
        </Button>
        <Button
          onClick={() => setExampleTwo(true)}
          className="profile_white-btn"
        >
          Watch Example Video 2
        </Button>
      </div>
      <p className="video-block__description">
        Want to make a video that gets you the best jobs?
      </p>
      <div
        onClick={() => setIsSeeMore(!isSeeMore)}
        className="video-block__name video-block__see-more"
      >
        See Video How-To’s
        <img
          className={`${isSeeMore ? 'video-block__see-more-arrow' : ''}`}
          src={arrow}
          alt="arrow"
        />
      </div>
      {isSeeMore && (
        <div>
          <div className="video-block__see-more-block">
            <img src={checkArrow} alt="check" />
            <p>
              Try to record in a place with lots of light (morning and evening
              times are the best if you can get natural light)
            </p>
          </div>
          <div className="video-block__see-more-block">
            <img src={checkArrow} alt="check" />
            <p>Choose a background that is professional and not distracting</p>
          </div>
          <div className="video-block__see-more-block">
            <img src={checkArrow} alt="check" />
            <p>Keep background noise to a minimum so that you can be heard.</p>
          </div>
          <div className="video-block__see-more-block">
            <img src={checkArrow} alt="check" />
            <p>
              Introduce yourself e.g. “Hi, my name is … and I have been a
              carpenter for___ years.”
            </p>
          </div>
          <div className="video-block__see-more-block">
            <img src={checkArrow} alt="check" />
            <p>
              Share a bit about your background, what you have to offer, and
              what you hope to find in your next job. What do you do well or
              what makes you different from other people in your field?
            </p>
          </div>
          <div className="video-block__see-more-block">
            <img src={checkArrow} alt="check" />
            <p>
              If you want to share a few personal details (e.g. hobbies, family,
              goals) feel free but keep the whole video less than 2 minutes.
            </p>
          </div>
        </div>
      )}
    </div>
  )
}
