import { formatPhoneNumber, inputFormatting } from '../../../../utils/scripts'
import { RadioBtnElement } from '../../../../components/molecules'
import { PHONE, ZIP_CODE } from '../../../PreInterviewOnboarding'

export const handleInputProfile = (
  fieldName: string,
  eventValue: string,
  setValues: React.Dispatch<React.SetStateAction<any>>
) => {
  setValues((values: any) => {
    if (fieldName === ZIP_CODE) {
      return {
        ...values,
        [fieldName]:
          inputFormatting(eventValue, 5).match(/\d/g)?.join('') ?? '',
      }
    }
    if (fieldName === PHONE) {
      return {
        ...values,
        [fieldName]: formatPhoneNumber(eventValue, false, true),
      }
    }
    return {
      ...values,
      [fieldName]: eventValue,
    }
  })
}

export const handleCheckboxProfile = (
  isChecked: boolean,
  setValues: React.Dispatch<React.SetStateAction<any>>,
  item: string,
  fieldName?: string,
  resultValueBoolean?: boolean
) => {
  if (!fieldName) {
    if (!isChecked) {
      setValues((values: any) => [...values, item])
    } else {
      setValues((values: any) => values.filter((el: any) => el !== item))
    }

    return
  }

  if (resultValueBoolean) {
    setValues((values: any) => ({
      ...values,
      [fieldName]: !isChecked,
    }))

    return
  }

  if (!isChecked) {
    setValues((values: any) => ({
      ...values,
      [fieldName]: [...values[fieldName], item],
    }))
  } else {
    setValues((values: any) => {
      const newValues = values[fieldName].filter((el: any) => el !== item)
      return {
        ...values,
        [fieldName]: [...newValues],
      }
    })
  }
}

export const handleRadioBtnProfile = (
  id: number,
  btnList: RadioBtnElement[],
  setValues: React.Dispatch<React.SetStateAction<any>>,
  fieldName: string,
  resultValueBoolean?: boolean
) => {
  const selectedEl = btnList.find(el => el.id === id)

  if (resultValueBoolean) {
    setValues((values: any) => ({
      ...values,
      [fieldName]: selectedEl?.value === 'Yes',
    }))
  } else {
    setValues((values: any) => ({
      ...values,
      [fieldName]: selectedEl?.value,
    }))
  }
}

export const getYesNoList = (
  noId: number,
  yesId: number,
  isReverse?: boolean
): RadioBtnElement[] => {
  return isReverse
    ? [
        { id: yesId, value: 'No' },
        { id: noId, value: 'Yes' },
      ]
    : [
        { id: noId, value: 'No' },
        { id: yesId, value: 'Yes' },
      ]
}

export const getSelectedYesNoAnswerId = (
  yesNoList: RadioBtnElement[],
  userChoice: boolean | null
): number | undefined => {
  if (userChoice === null) {
    return undefined
  }

  const yesNoAnswer: RadioBtnElement | undefined = yesNoList.find(
    el => el.value === (userChoice ? 'Yes' : 'No')
  )

  return yesNoAnswer?.id
}
