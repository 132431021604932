import React from 'react'
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar'
import './profileCircle.scss'

interface Props extends React.HTMLAttributes<HTMLElement> {
  score?: number
  text?: string
  hidePercent?: true
  pathColor?: string
}

export const ProfileProgressCircle = (props: Props) => {
  const { score, text, hidePercent, pathColor } = props
  return (
    <div
      className={`profile-circle ${props.className ?? ''}`}
      data-testid="profile-circle"
    >
      <CircularProgressbarWithChildren
        value={props.score ?? 0}
        className="progress-wrapper"
        circleRatio={0.8}
        minValue={0}
        maxValue={100}
        strokeWidth={8}
        styles={buildStyles({
          rotation: 1 / 2 + 1 / 10,
          strokeLinecap: 'round',
          pathColor: pathColor ?? '#F5BC3E',
          textColor: '#F5BC3E',
          trailColor: '#D8D8D8',
        })}
      >
        <div>
          <p
            className={`profile-circle__score ${
              score === 100 ? 'profile-circle__score_fz' : ''
            }`}
          >
            {score ?? (
              <strong className="profile-circle__score-dots">...</strong>
            )}
            {!hidePercent && <span>{score !== undefined && '%'}</span>}
          </p>
          <p className="profile-circle__text">{text}</p>
        </div>
      </CircularProgressbarWithChildren>
    </div>
  )
}
