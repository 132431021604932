import React, { Fragment } from 'react'
import polygonWhiteIcon from '../../../../../assets/icons/other/polygon-white.svg'
import {
  IQueryParamsWorkersList,
  IWorkerInfoAdminPage,
} from '../../../../../models/AdminWorkes'
import { LoadingContent, StatusTogglers } from '../../../../molecules'
import { CounterWithPopup, Popup } from '../../../../atoms'
import * as route from '../../../../../services/route'
import './usersTable.scss'
import { WorkerStatuses } from './WorkerStatusesInProgressColumn'
import { Link } from 'react-router-dom'

const sortParams = {
  LOCATION: 'location',
  ROLE: 'role',
  CREATED: 'created',
}

interface Props extends React.HTMLAttributes<HTMLElement> {
  workersList: IWorkerInfoAdminPage[]
  queryParams: IQueryParamsWorkersList | null
  isLoading: boolean
  isAscSort: boolean
  handleSorting: (sortName: string) => void
  tableRef: React.RefObject<HTMLDivElement>
  transitionToEmployer: () => void
}

export const UsersTableUI = (props: Props) => {
  const {
    workersList,
    queryParams,
    className,
    isLoading,
    isAscSort,
    handleSorting,
    tableRef,
    transitionToEmployer,
  } = props

  return (
    <div
      className={`users-table ${className ?? ''}`}
      ref={tableRef}
      data-testid="users-table"
    >
      <div className="users-table__header users-table__header-name">Name</div>
      <div className="users-table__header">Email</div>
      <div className="users-table__header">
        <button
          className="users-table__sort-btn"
          type="button"
          onClick={() => handleSorting(sortParams.CREATED)}
        >
          <span>Created At</span>
          {queryParams?.sort_params.field === sortParams.CREATED && (
            <img
              className={`users-table__header-polygon-sort ${
                isAscSort ? 'users-table__header-polygon-sort_asc' : ''
              }`}
              src={polygonWhiteIcon}
              alt="polygon-white-icon"
            />
          )}
        </button>
      </div>

      <div className="users-table__header">
        <button
          className="users-table__sort-btn"
          type="button"
          onClick={() => handleSorting(sortParams.ROLE)}
        >
          <span>Role(s)</span>
          {queryParams?.sort_params.field === sortParams.ROLE && (
            <img
              className={`users-table__header-polygon-sort ${
                isAscSort ? 'users-table__header-polygon-sort_asc' : ''
              }`}
              src={polygonWhiteIcon}
              alt="polygon-white-icon"
            />
          )}
        </button>
      </div>

      <div className="users-table__header">
        <button
          className="users-table__sort-btn"
          type="button"
          onClick={() => handleSorting(sortParams.LOCATION)}
        >
          <span>Home Region</span>
          {queryParams?.sort_params.field === sortParams.LOCATION && (
            <img
              className={`users-table__header-polygon-sort ${
                isAscSort ? 'users-table__header-polygon-sort_asc' : ''
              }`}
              src={polygonWhiteIcon}
              alt="polygon-white-icon"
            />
          )}
        </button>
      </div>

      <div className="users-table__header users-table__header-progress">
        Progress
      </div>

      <LoadingContent isLoading={isLoading}>
        {workersList.map((worker, index) => {
          const isLastWorker = index === workersList.length - 1
          return (
            <Fragment key={worker.id}>
              <div
                className={`users-table__user ${
                  isLastWorker ? 'users-table__user-last-worker-name' : ''
                }`}
              >
                {worker.profile_picture ? (
                  <img
                    className="users-table__user-photo"
                    src={worker.profile_picture}
                    alt="user_photo"
                  />
                ) : (
                  <div className="users-table__user-default-photo">
                    {worker.first_name && worker.first_name[0]}
                  </div>
                )}
                <Link
                  to={`${route.workerDetails}/${worker.unique_url}`}
                  onClick={() => transitionToEmployer()}
                >
                  <p className="users-table__user-name">
                    {worker.first_name} {worker.last_name}
                  </p>
                </Link>
              </div>
              <div className="users-table__user users-table__user-email">
                {worker.username}
              </div>
              <div className="users-table__user">{worker.created}</div>
              <div className="users-table__user">
                {worker.full_role && (
                  <CounterWithPopup
                    list={worker.full_role.split(',')}
                    containerRef={tableRef}
                  />
                )}
              </div>

              <div
                className="users-table__user"
                data-testid="users-table__user-location"
              >
                <Popup
                  textList={worker.location?.msa_name ?? ''}
                  separator={'-'}
                  showItemNumber={1}
                />
              </div>
              <div
                className={`users-table__user ${
                  isLastWorker ? 'users-table__user-last-worker-progress' : ''
                }`}
              >
                <div className="users-table__user-progress">
                  <WorkerStatuses workerStatus={worker.status} />
                  <StatusTogglers worker={worker} />
                </div>
              </div>
            </Fragment>
          )
        })}
      </LoadingContent>
    </div>
  )
}
