import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as route from '../../../services/route'
import { useHistory } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { QueryState } from 'react-query/types/core/query'
import { ITradesOB } from '../../../models'
import { updateRolesOB } from '../../../services/workerApi'
import { OBTradesUI } from './OBTradesUI'
import { WorkerQueries } from '../../../utils/constants/queries/worker'
import { toast } from 'react-toastify'
import { RoleNames } from '../../../utils/constants/obConstants'
import { setWorkerInfo, useAppDispatch } from '../../../redux'
import {
  usePageNumUpdateOB,
  useTypedSelector,
  useStateWithDep,
  useIdentifyActions,
} from '../../../utils/hooks'
import { EWorkerSStoreKeys, OBAnalyticsName } from '../../../utils/constants'
import {
  extraSpacesFormatting,
  getExistingRoles,
  getFilteredOtherRole,
  getUserInfoFromStorage,
  onTrackingActions,
} from '../../../utils/scripts'
import { getNewUserInfo } from './OBTradesFunc'
import { getLastPageState } from '../../../utils/hooks/obHooks/usePageNumUpdateOB'

export const OBTrades = () => {
  useIdentifyActions()
  const defaultTradesOB: ITradesOB = useMemo(
    () => ({
      roles: [],
      other_role: null,
    }),
    []
  )
  const otherTradeRef = useRef<HTMLInputElement>(null)
  const history = useHistory()
  const userInfoFromStorage = useMemo(() => getUserInfoFromStorage(), [])
  const dispatch = useAppDispatch()
  const userInfo = useTypedSelector(s => s.obInfo.workerInfo)
  const { roles } = useTypedSelector(s => s.variables.choiceListForWorker)
  const lastPage = userInfo.last_page < 4 ? 4 : userInfo.last_page
  const lastPageState = useMemo(() => getLastPageState(lastPage), [lastPage])
  const pageStateOBMutation = usePageNumUpdateOB()
  const queryClient = useQueryClient()
  const preparedRolesData = roles.supported_roles?.concat(
    roles.unsupported_roles ?? []
  )
  const tradesFromStorage = useMemo(() => {
    if (!userInfoFromStorage) return null
    const tradesOB = { ...defaultTradesOB }
    userInfoFromStorage?.user_data.role.forEach((role: any) => {
      if (role === RoleNames.other_trade) {
        tradesOB.other_role = RoleNames.other_trade
      } else {
        tradesOB.roles.push(role)
      }
    })
    return tradesOB
  }, [defaultTradesOB, userInfoFromStorage])
  const obTradesInfoQuery: QueryState<ITradesOB | undefined> | undefined =
    queryClient.getQueryState(WorkerQueries.obTrades)

  const [obTrade, setObTrade] = useStateWithDep<ITradesOB>(
    tradesFromStorage ?? obTradesInfoQuery?.data ?? defaultTradesOB
  )
  const [isOtherTradeInput, setIsOtherTradeInput] = useState(false)
  const [isFilledOtherTrade, setIsFilledOtherTrade] = useState(false)

  const isFindedSupportedRoles = useMemo(() => {
    return obTrade.roles.some(role =>
      roles.supported_roles?.some(supportedRole => supportedRole === role)
    )
  }, [obTrade.roles, roles.supported_roles])

  const obTradesInfoMutation = useMutation(
    (data: ITradesOB) => updateRolesOB(data),
    {
      onError: err => {
        if (err instanceof Error) toast.error(err.message)
      },
    }
  )

  const savingData = useCallback(() => {
    sessionStorage.setItem(
      EWorkerSStoreKeys.obData,
      JSON.stringify(
        getNewUserInfo(userInfoFromStorage ?? userInfo, lastPageState, obTrade)
      )
    )
    dispatch(setWorkerInfo(getNewUserInfo(userInfo, lastPageState, obTrade)))
    queryClient.setQueryData(
      WorkerQueries.workerInfoOB,
      getNewUserInfo(userInfo, lastPageState, obTrade)
    )
  }, [
    dispatch,
    queryClient,
    userInfo,
    userInfoFromStorage,
    lastPageState,
    obTrade,
  ])

  const getDisabled = useCallback(() => {
    const queryStatusByPage = isFilledOtherTrade
      ? obTradesInfoMutation.isSuccess && pageStateOBMutation.isIdle
      : obTradesInfoMutation.isIdle

    const isWaitingResponse = queryStatusByPage
      ? false
      : !obTradesInfoMutation.isError

    if (
      isWaitingResponse ||
      (!obTrade.other_role && isOtherTradeInput) ||
      (isFilledOtherTrade && !isFindedSupportedRoles) ||
      !obTrade.roles?.length
    )
      return true
    return false
  }, [
    isFindedSupportedRoles,
    isFilledOtherTrade,
    isOtherTradeInput,
    obTrade,
    obTradesInfoMutation,
    pageStateOBMutation,
  ])

  const onSuccessMutate = useCallback(
    (trades: ITradesOB) => {
      queryClient.setQueryData(WorkerQueries.obTrades, trades)
      setObTrade(trades)
    },
    [queryClient, setObTrade]
  )

  const onChangeOtherInput = useCallback(
    (value: string) => {
      const existingRoles: string[] = getExistingRoles(
        value,
        obTrade.roles,
        preparedRolesData
      )

      setObTrade(prevTrades => {
        return {
          roles: existingRoles.length
            ? [...prevTrades.roles, ...existingRoles]
            : [...prevTrades.roles],
          other_role: extraSpacesFormatting(value),
        }
      })
    },
    [preparedRolesData, setObTrade, obTrade.roles]
  )

  const onClickBack = useCallback(() => {
    sessionStorage.setItem(
      EWorkerSStoreKeys.obData,
      JSON.stringify(
        getNewUserInfo(userInfoFromStorage ?? userInfo, lastPageState, obTrade)
      )
    )
    history.push(route.OBPath.onboardingEmail)
  }, [history, userInfo, userInfoFromStorage, lastPageState, obTrade])

  const onClickNext = async (
    initTrades: ITradesOB,
    initSetTrade: React.Dispatch<React.SetStateAction<ITradesOB>>,
    initSetIsFilledOtherTrade: React.Dispatch<React.SetStateAction<boolean>>,
    initSetIsOtherTrade: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    const isRolesChanged =
      userInfo.user_data.role.length !== initTrades.roles.length ||
      !userInfo.user_data.role.every(role => initTrades.roles.includes(role))

    const isOtherRoleChanged =
      userInfo.user_data.other_role !== initTrades.other_role

    const preparedOtherRolesList =
      getFilteredOtherRole(initTrades.other_role, preparedRolesData) ?? ''

    const correctTrades: ITradesOB = {
      ...initTrades,
      other_role: initTrades.roles.includes(RoleNames.other_trade)
        ? preparedOtherRolesList
        : '',
    }

    if (initTrades.other_role && initTrades.other_role.length >= 100) {
      toast.error(
        'Please, enter less than 100 characters in the "Other trade" field'
      )
      return
    }

    if (!isRolesChanged && !isOtherRoleChanged && isFindedSupportedRoles) {
      history.push(route.OBPath.onboardingExperience)
    } else {
      if (
        initTrades.roles &&
        initTrades.roles.find(
          (checkedRole: string) =>
            roles.supported_roles?.some(
              supportedRole => supportedRole === checkedRole
            ) || checkedRole === RoleNames.other_trade
        )
      ) {
        if (
          initTrades.roles.find((checkedRole: string) =>
            roles.supported_roles?.some(
              supportedRole => supportedRole === checkedRole
            )
          )
        ) {
          await onTrackingActions(OBAnalyticsName.trades_selected)
          obTradesInfoMutation.mutate(correctTrades, {
            onSuccess: () => {
              pageStateOBMutation.mutate(lastPageState, {
                onSuccess: () => {
                  onSuccessMutate(correctTrades)
                  savingData()
                  history.push(route.OBPath.onboardingExperience)
                },
              })
            },
          })
        } else if (initTrades.roles.includes(RoleNames.other_trade)) {
          obTradesInfoMutation.mutate(correctTrades, {
            onSuccess: res => {
              if (res.is_supported) {
                onTrackingActions(OBAnalyticsName.trades_selected)

                pageStateOBMutation.mutate(lastPageState, {
                  onSuccess: () => {
                    savingData()
                    onSuccessMutate(correctTrades)
                    initSetIsOtherTrade(false)
                    history.push(route.OBPath.onboardingExperience)
                  },
                })
              } else {
                initSetIsFilledOtherTrade(true)
                initSetIsOtherTrade(false)
                const notSupportedRolesList = initTrades.roles.filter(
                  role => role !== RoleNames.other_trade
                )
                const otherRoleList = preparedOtherRolesList?.split(', ')
                const allOtherRolesList = [
                  ...otherRoleList,
                  ...notSupportedRolesList,
                ]

                initSetTrade(prevObTrades => {
                  return {
                    ...prevObTrades,
                    other_role: allOtherRolesList.filter(el => el).join(', '),
                  }
                })
              }
            },
          })
        }
      } else {
        const notSupportRolesList = initTrades.roles
          .filter(role => role !== RoleNames.other_trade)
          .join(', ')

        const tradesWithOtherRole: ITradesOB = {
          ...correctTrades,
          other_role: notSupportRolesList,
        }

        obTradesInfoMutation.mutate(correctTrades, {
          onSuccess: () => {
            initSetIsFilledOtherTrade(true)
            onSuccessMutate(tradesWithOtherRole)
          },
        })
      }
    }
  }

  useEffect(() => {
    if (isOtherTradeInput) {
      window.scrollTo(0, document.body.scrollHeight)

      otherTradeRef?.current?.focus()
    }
  }, [isOtherTradeInput])

  useEffect(() => {
    const info = userInfoFromStorage ?? userInfo
    if (info.user_data.role) {
      const correctRolesList = info.user_data.other_role
        ? info.user_data.role
        : info.user_data.role.filter(
            (role: any) => role !== RoleNames.other_trade
          )
      setObTrade({
        roles: correctRolesList,
        other_role: info.user_data.other_role,
      })
    }
    info.user_data.other_role && setIsOtherTradeInput(true)
  }, [userInfo, userInfoFromStorage, setObTrade])

  return (
    <OBTradesUI
      isFilledOtherTrade={isFilledOtherTrade}
      preparedRolesData={preparedRolesData}
      setObTrade={setObTrade}
      isOtherTradeInput={isOtherTradeInput}
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      obTrade={obTrade}
      setIsFilledOtherTrade={setIsFilledOtherTrade}
      setIsOtherTradeInput={setIsOtherTradeInput}
      isDisabled={getDisabled()}
      supportedRoles={roles.supported_roles}
      onChangeOtherInput={onChangeOtherInput}
      otherTradeRef={otherTradeRef}
    />
  )
}
