import { Link } from 'react-router-dom'
import { Button } from '../../../../atoms'
import './scheduleFormat.scss'
import { getInterviewerData } from '../../../../../services/workerApi'
import { WorkerQueries } from '../../../../../utils/constants'
import { LoadingContent } from '../../../../molecules'

import { useQuery } from 'react-query'

interface Props {
  scheduleLink?: string
  onClickScheduleBtn?: () => void
}

export const ScheduleFormat = (props: Props) => {
  const { onClickScheduleBtn, scheduleLink } = props

  const { data: interviewerData, isLoading: isLoadingInterviewer } = useQuery(
    WorkerQueries.InterviewerData,
    () => getInterviewerData()
  )

  return (
    <section className="schedule-format">
      <h3 className="schedule-format__title">Pick your intro call time</h3>

      <LoadingContent isLoading={isLoadingInterviewer}>
        <div className="schedule-format__interviewer">
          {interviewerData?.profile_image ? (
            <img
              className="schedule-format__interviewer-photo"
              src={interviewerData.profile_image}
              alt="interviewer"
            />
          ) : (
            <div className="schedule-format__interviewer-letter">
              {interviewerData?.name.length ? interviewerData.name[0] : ''}
            </div>
          )}
          <div className="schedule-format__interviewer-block">
            <p className="schedule-format__interviewer-name">
              {interviewerData?.name}
            </p>
            <span className="schedule-format__interviewer-position">
              Skillit Career Guide
            </span>
            <p className="schedule-format__interviewer-phone">
              <a href="tel:(219) SKILLIT">(219) SKILLIT</a>
            </p>
            <p className="schedule-format__interviewer-email">
              <a href={`mailto:${interviewerData?.email}`}>
                {interviewerData?.email}
              </a>
            </p>
          </div>
        </div>
      </LoadingContent>

      <p className="schedule-format__detail">
        Please pick a time for us to connect so we can verify your details and
        answer any questions you may have. No pressure, this isn't an interview
        - We're on your side!
      </p>
      <div className="schedule-format__button-block">
        {scheduleLink ? (
          <Link
            className="schedule-format__schedule-time-link"
            to={scheduleLink}
          >
            <Button className="schedule-format__button-schedule-time">
              Schedule a Time
            </Button>
          </Link>
        ) : (
          <Button
            onClick={onClickScheduleBtn}
            className="schedule-format__button-schedule-time"
          >
            Schedule a Time
          </Button>
        )}
      </div>
    </section>
  )
}
