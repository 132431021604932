import React from 'react'
import { Helmet } from 'react-helmet'
import { EPageTitleOB } from '../../../utils/constants'
import { useTranslation } from 'react-i18next'
import { InterviewerModel } from '../../../models'
import { LoadingScreen } from '../../InAppFlow'

type TProps = {
  name: string
  isLoading: boolean
  interviewerInfo?: InterviewerModel
}

export const OBThanksUI: React.FC<TProps> = ({
  name,
  isLoading,
  interviewerInfo,
}) => {
  const { t, i18n } = useTranslation()

  if (isLoading) return <LoadingScreen />

  return (
    <div className="thanks-page ob-container">
      <Helmet>
        <title> {EPageTitleOB.thanks} </title>
      </Helmet>

      <div className="thanks-page__text">
        <p className="thanks-page__question ob-question">
          {i18n.language.slice(0, 2) === 'es'
            ? t('thanks.Congrats name, your application is complete!', {
                Nombre: `${!name ? 'Nombre' : name}`,
              })
            : t('thanks.Congrats name, your application is complete!', {
                Name: `${!name ? 'User' : name}`,
              })}
        </p>

        <div className="thanks-page__description">
          <p className="ob-description">
            {t(
              'thanks.Thanks for sticking with it! Now you can sit back and relax as a member of our team reviews your application. We promise to let you know within 2 business days (usually less) whether your Skillit membership has been approved.'
            )}
          </p>

          <p className="ob-description">
            {t(
              'thanks.If you have any questions in the meantime, please feel free to reach out to me at email or phone',
              {
                Email: interviewerInfo?.email ?? 'email',
              }
            )}
          </p>
        </div>
      </div>

      <div className="thanks-page__contacts">
        {interviewerInfo?.profile_image ? (
          <img
            src={interviewerInfo?.profile_image}
            alt="representative_photo"
            className="thanks-page__contacts-img"
          />
        ) : (
          <div className="thanks-page__contacts-letter">
            {interviewerInfo?.name.length ? interviewerInfo.name[0] : ''}
          </div>
        )}

        <div className="thanks-page__contacts-wrapper">
          <p className="thanks-page__contacts-item thanks-page__contacts-item_name">
            {interviewerInfo?.name}
          </p>

          <p className="thanks-page__contacts-item">
            {t('thanks.Your worker success representative')}
          </p>

          <p className="thanks-page__contacts-item">
            <a href={`mailto:${interviewerInfo?.email}`}>
              {interviewerInfo?.email}
            </a>
          </p>

          <p className="thanks-page__contacts-item">
            <a href="tel:(219) SKILLIT">(219) SKILLIT</a>
          </p>
        </div>
      </div>
    </div>
  )
}
