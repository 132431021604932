export const changeColorOfPartText = (
  text: string,
  value?: string,
  placeAutocomplete?: boolean
) => {
  if (!value) {
    return text
  }

  const correctValue = value.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')
  const regex = new RegExp(`(${correctValue})`, 'gi')
  const parts = placeAutocomplete
    ? text.replace(', USA', '').split(regex)
    : text.split(regex)

  return parts.filter(String).map((part, i) => {
    return (
      <span className={regex.test(part) ? 'color' : ''} key={i}>
        {part}
      </span>
    )
  })
}
