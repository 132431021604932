import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import * as Sentry from '@sentry/react'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'normalize.css'
import { sentryEnv } from './utils/constants'
import { SENTRY_ENV } from './services/config'

Sentry.init({
  dsn: 'https://b69db4db09f545a4a2ad3ae643d78c96@o4504454547439616.ingest.sentry.io/4504454558318592',
  integrations: [new Sentry.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  environment: SENTRY_ENV,
  allowUrls: [sentryEnv.dev.url, sentryEnv.stage.url, sentryEnv.prod.url],
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
