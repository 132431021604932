import React from 'react'
import { Loader } from '../../../../components/atoms'
import './loadingScreen.scss'

export const LoadingScreen = () => {
  return (
    <div className="loading-page">
      <Loader />
    </div>
  )
}
