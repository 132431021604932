import { Fragment } from 'react'
import zipcodes from 'zipcodes'
import {
  AssessmentInfo,
  AssessmentResult,
  PublicInfo,
} from '../../../../../models'
import {
  Button,
  ProfileProgressCircle,
  SwitchBtn,
  Prompt,
} from '../../../../atoms'
import { ModalBox } from '../../../../molecules'
import { ProfileAssessmentModal } from './ProfileAssessmentModal'

interface Props extends React.HTMLAttributes<HTMLElement> {
  switchBtnHandler: (status: boolean) => void
  skillitForms?: AssessmentInfo
  assessments?: AssessmentResult[]
  publicInfo: PublicInfo
  isModalVisible: boolean
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const ProfileAssessmentBlockUI = (props: Props) => {
  const {
    className,
    switchBtnHandler,
    skillitForms,
    assessments,
    publicInfo,
    isModalVisible,
    setIsModalVisible,
  } = props
  return (
    <div className={`profile-assessment ${className ? className : ''}`}>
      {isModalVisible && (
        <ModalBox
          className="profile-assessment__modal"
          visibleCloseIcon={true}
          onCloseModal={() => setIsModalVisible(false)}
        >
          <ProfileAssessmentModal
            skillitForms={skillitForms?.type_forms ?? []}
          />
        </ModalBox>
      )}
      <SwitchBtn
        defaultStatus={publicInfo.score_display}
        statusHandler={status => switchBtnHandler(status)}
        enabledStatusName="Display my assessment scores to firms"
        disabledStatusName="Display my assessment scores to firms"
      />
      {assessments && assessments?.length > 0 ? (
        assessments.map((assessment, index) => {
          return (
            <Fragment key={assessment.name}>
              <h3 className="profile-assessment__title">
                {assessment.name}
                <span className="profile-assessment__prompt">
                  <Prompt text={'Take the assessment to get your score'}>
                    <div className="profile-assessment__help">i</div>
                  </Prompt>
                </span>
              </h3>

              <div className="profile-assessment__content">
                <div className="profile-assessment__challenge-block">
                  <ul className="profile-assessment__challenge-list">
                    {assessment.categories_scores &&
                      Object.keys(assessment.categories_scores).map(
                        (category, index) => {
                          return (
                            <li
                              key={`${assessment.name}-${index}`}
                              className="profile-assessment__challenge-item"
                            >
                              <div className="profile-assessment__challenge-item-name">
                                {category.split('_').join(' ')}
                              </div>
                              <div className="profile-assessment__challenge-item-result">
                                {assessment.categories_scores[category]}
                              </div>
                            </li>
                          )
                        }
                      )}
                  </ul>
                </div>
                <div className="profile-assessment__score-block">
                  <ProfileProgressCircle
                    score={assessment?.overall_score ?? undefined}
                    text={'score'}
                  />
                </div>
              </div>
              <p className="profile-assessment__congrats-text">
                {`Congrats! You did the ${assessment?.name} better than `}
                {`${assessment?.rating?.percentage}% (${
                  assessment?.rating?.number
                }) of workers in ${
                  assessment?.zip_code &&
                  zipcodes.lookup(+assessment?.zip_code)?.state
                }.`}
              </p>
              {index === 0 &&
                skillitForms?.type_forms &&
                skillitForms.type_forms.length > 0 && (
                  <Button
                    className="profile_white-btn"
                    onClick={() => setIsModalVisible(true)}
                  >
                    Take another Assessment
                  </Button>
                )}
              <p className="profile-assessment__career-guid-contact">
                Feel like you could have done better?
                <a
                  href="mailto:taylor@skillit.com"
                  className="profile-assessment__career-guid-contact-link"
                >
                  &nbsp;Contact&nbsp;
                </a>
                your career guide to let them know why.
              </p>
              {index !== assessments.length - 1 && <hr />}
            </Fragment>
          )
        })
      ) : (
        <Button
          className="profile_white-btn"
          onClick={() => setIsModalVisible(true)}
        >
          Take Assessment
        </Button>
      )}
    </div>
  )
}
