import React, {
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import moment from 'moment'
import { TWorkExperience } from '../../../../../../models'
import { LastJobsContext } from '../../../../../Context'
import { AddJobFormUI } from './AddJobFormUI'
import { EDate, OBAnalyticsName } from '../../../../../../utils/constants'
import {
  EJobFields,
  TCombinedFormType,
  TEditIncompleteExp,
} from '../../../../../../pages/Onboarding/OBLastJobs/OBLastJobs'
import { useMutation } from 'react-query'
import { companiesAutocompleteOB } from '../../../../../../services/workerApi'
import {
  checkIsFutureDate,
  getEndDate,
  getPreparedEndDate,
  getStartDate,
  onTrackingActions,
  scrollToElement,
} from '../../../../../../utils/scripts'
import { toast } from 'react-toastify'

export type TIsFormOpen = false | TEditIncompleteExp

export type TIsFormOpenToAdd = false | 'add' | number

export type TFieldsErr = {
  company_name: boolean
  job_title: boolean
  location: boolean
  start_date: boolean
  months: boolean
  isFutureDate: boolean
}

interface IProps {
  isFormOpen: TIsFormOpen | TIsFormOpenToAdd
  highlightFields?: EJobFields[]
  setIsFormOpen:
    | React.Dispatch<React.SetStateAction<TIsFormOpenToAdd>>
    | React.Dispatch<React.SetStateAction<TIsFormOpen>>
  onCloseModal: (value: boolean, setIsFormOpen: TCombinedFormType) => void
  isPromptOpen: boolean
  isClickCloseBtn: boolean
  setIsPromptOpen: React.Dispatch<React.SetStateAction<boolean>>
  setIsClickCloseBtn: React.Dispatch<React.SetStateAction<boolean>>
}

const initialData: TWorkExperience = {
  company_name: '',
  job_title: '',
  location: '',
  start_date: null,
  end_date: null,
  description: '',
  is_current: undefined,
}

const initialFieldErr: TFieldsErr = {
  company_name: false,
  job_title: false,
  location: false,
  start_date: false,
  months: false,
  isFutureDate: false,
}

export const AddJobForm = (props: IProps) => {
  const {
    setIsFormOpen,
    isFormOpen,
    isClickCloseBtn,
    onCloseModal,
    setIsClickCloseBtn,
    setIsPromptOpen,
    isPromptOpen,
    ...otherProps
  } = props

  const jobsContext = useContext(LastJobsContext)
  const jobs = jobsContext.jobs
  const setJobs = jobsContext.setJobs
  const currentYear = useMemo(() => new Date().getFullYear(), [])
  const currentMonth = useMemo(() => new Date().getMonth(), [])
  const editableJob = useMemo(() => {
    if (isFormOpen === 'add') return false
    if (typeof isFormOpen === 'object') return isFormOpen.index
    return isFormOpen
  }, [isFormOpen])

  const [isSaveClicked, setIsSaveClicked] = useState(false)
  const initialFormData = useMemo(() => {
    return editableJob !== false ? jobs[editableJob] : initialData
  }, [editableJob, jobs])

  const inputBlockRef = useRef<HTMLDivElement>(null)

  const companyNameRef = useRef<HTMLInputElement>(null)
  const roleNameRef = useRef<HTMLInputElement>(null)
  const locationRef = useRef<HTMLInputElement>(null)
  const startDateRef = useRef<HTMLInputElement>(null)
  const monthRef = useRef<HTMLInputElement>(null)

  const [isSubmitBtnActive, setIsSubmitBtnActive] = useState(false)
  const [isSubmitBtnClick, setIsSubmitBtnClick] = useState(false)
  const [suggestions, setSuggestions] = useState<string[]>([])
  const [formData, setFormData] = useState(initialFormData)

  const getDefaultMonths: string | undefined = useMemo(() => {
    if (formData?.start_date && formData?.end_date) {
      const isEndDatePresent = formData.end_date === EDate.present
      const startYear = new Date(formData.start_date).getFullYear()
      const startMonth = new Date(formData.start_date).getMonth() + 1
      const endYear = isEndDatePresent
        ? currentYear
        : new Date(formData?.end_date).getFullYear()
      const endMonth = isEndDatePresent
        ? currentMonth + 1
        : new Date(formData?.end_date).getMonth() + 1
      return `${(endYear - startYear) * 12 + (endMonth - startMonth)}`
    }
  }, [currentYear, currentMonth, formData?.end_date, formData?.start_date])

  const [startYear, setStartYear] = useState<string>(
    `${
      formData?.start_date ? new Date(formData?.start_date).getFullYear() : ''
    }`
  )
  const [months, setMonths] = useState<string | undefined>(getDefaultMonths)
  const [fieldsErr, setFieldsErr] = useState<TFieldsErr>(initialFieldErr)
  const [isCurrentDate, setIsCurrentDate] = useState<boolean>(
    formData?.is_current || formData?.end_date === EDate.present
  )
  const startDate = useMemo(
    () => getStartDate(startYear, months, isCurrentDate),
    [isCurrentDate, months, startYear]
  )
  const endDate = useMemo(
    () => getEndDate(startYear, months, isCurrentDate),
    [isCurrentDate, months, startYear]
  )

  const companiesAutocompleteMutation = useMutation(
    (value: string) => companiesAutocompleteOB(value),
    {
      onSuccess(data) {
        const uniqueCompanies = new Set(data)
        setSuggestions(Array.from(uniqueCompanies))
      },
    }
  )

  const isDataChanged = useMemo(() => {
    const { company_name, job_title, location, description } = formData
    const initialIsCurrent =
      initialFormData.is_current ?? initialFormData.end_date === EDate.present

    const initialStartDate = `${
      initialFormData.start_date
        ? new Date(initialFormData.start_date).getFullYear()
        : ''
    }`
    if (
      initialFormData.company_name === company_name &&
      initialFormData.job_title === job_title &&
      initialFormData.location === location &&
      getDefaultMonths === months &&
      initialFormData.description === description &&
      initialIsCurrent === isCurrentDate &&
      initialStartDate === startYear
    ) {
      return false
    }
    return true
  }, [
    formData,
    getDefaultMonths,
    initialFormData.company_name,
    initialFormData.description,
    initialFormData.end_date,
    initialFormData.is_current,
    initialFormData.job_title,
    initialFormData.location,
    initialFormData.start_date,
    isCurrentDate,
    months,
    startYear,
  ])

  const onChangeStartDate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (fieldsErr.start_date) {
        setFieldsErr({ ...fieldsErr, start_date: false })
      }
      const digitalValue = e.target.value.replace(/[^\d]/g, '')
      const preparedValue =
        +digitalValue > new Date().getFullYear()
          ? `${new Date().getFullYear()}`
          : digitalValue

      if (!isCurrentDate) {
        if (!fieldsErr.start_date) {
          months &&
            !digitalValue &&
            setFieldsErr({ ...fieldsErr, start_date: true })
        } else digitalValue && setFieldsErr({ ...fieldsErr, start_date: false })
      }

      setStartYear(preparedValue)
    },
    [fieldsErr, months, isCurrentDate]
  )

  const onChangeMonth = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (fieldsErr.months || fieldsErr.isFutureDate) {
        setFieldsErr({ ...fieldsErr, months: false, isFutureDate: false })
      }
      const digitalValue = e.target.value.replace(/[^\d]/g, '').substring(0, 3)

      setMonths(digitalValue)
      !isCurrentDate &&
        !startYear &&
        setFieldsErr({
          ...fieldsErr,
          months: !digitalValue,
          start_date: true,
        })
    },
    [fieldsErr, startYear, isCurrentDate]
  )

  const toggleCheckbox = useCallback(
    (id: number, isChecked: boolean) => {
      const currentYear = new Date().getFullYear()
      const isFutureDate = checkIsFutureDate(startYear, months)
      const yearsDifference = currentYear - +startYear

      setIsCurrentDate(!isChecked)
      if (!isChecked) {
        if (fieldsErr.months || fieldsErr.start_date || fieldsErr.isFutureDate)
          setFieldsErr({
            ...fieldsErr,
            start_date: false,
            months: false,
            isFutureDate: false,
          })
        if (startYear && months && isFutureDate) {
          setMonths(`${yearsDifference * 12 + new Date().getMonth()}`)
        } else if (months) {
          const remainingMonth = +months + 1 - (new Date().getMonth() + 1)
          const lengthOfYears = Math.ceil(remainingMonth / 12)
          setStartYear(
            lengthOfYears > 0
              ? `${currentYear - lengthOfYears}`
              : `${currentYear}`
          )
        } else if (startYear) {
          setMonths(`${yearsDifference * 12 + new Date().getMonth()}`)
        }
      }
    },
    [startYear, months, fieldsErr]
  )

  const onClickSuggestion = useCallback(
    (value: string, key: EJobFields) => {
      setFormData({ ...formData, [key]: value })
    },
    [formData]
  )

  const inputHandler = useCallback(
    (inputValue: string, key: EJobFields) => {
      if (key === EJobFields.company_name) {
        inputValue && companiesAutocompleteMutation.mutate(inputValue)
      }
      const isMonthField = key === EJobFields.end_date
      const isDescriptionField = key === EJobFields.description
      if (!isMonthField && !isDescriptionField && fieldsErr[key]) {
        setFieldsErr({ ...fieldsErr, [key]: false })
      }
      setFormData({ ...formData, [key]: inputValue })
    },
    [formData, fieldsErr, companiesAutocompleteMutation]
  )

  const textareaHandler = useCallback(
    e => {
      setFormData({ ...formData, description: e.target.value })
    },
    [formData]
  )

  const checkDate = useCallback(() => {
    const isFutureDate = checkIsFutureDate(startYear, months)
    let isFieldErr = false
    let key: keyof typeof fieldsErr

    for (key in fieldsErr) {
      if (fieldsErr[key]) {
        isFieldErr = true
      }
      if (
        key !== 'months' &&
        key !== 'start_date' &&
        key !== 'isFutureDate' &&
        !formData[key]
      ) {
        const memorizedKey = key
        setFieldsErr(prev => ({ ...prev, [memorizedKey]: true }))
        isFieldErr = true
      }
    }

    if (startDate && startDate < new Date('1900')) {
      setFieldsErr(prev => ({
        ...prev,
        start_date: true,
      }))
      isFieldErr = true
    }

    if (!startDate || !endDate || isFutureDate) {
      setFieldsErr(prev => ({
        ...prev,
        start_date: !startDate,
        months: !endDate,
        isFutureDate: isFutureDate,
      }))
      isFieldErr = true
    }

    return isFieldErr
  }, [startYear, months, fieldsErr, startDate, endDate, formData])

  const checkEmptyFields = useCallback(
    (fields: TFieldsErr) => {
      const hasTrueValue = Object.values(fields).some(value => value)

      if (hasTrueValue) {
        toast.error('Please fill all fields correctly')
        setIsPromptOpen(false)
        setIsClickCloseBtn(false)
      }
    },
    [setIsClickCloseBtn, setIsPromptOpen]
  )

  const onSubmitHandler = useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      setIsSubmitBtnClick(true)
      const startDateFormatted = moment(startDate).format('yyyy-MM-DD')
      const endDateFormatted = moment(endDate).format('yyyy-MM-DD')

      if (checkDate()) return

      const preparedJobData: TWorkExperience = {
        ...formData,
        company_name: formData.company_name,
        job_title: formData.job_title,
        location: formData.location,
        start_date:
          startDateFormatted === 'Invalid date' ? '' : startDateFormatted,
        end_date: getPreparedEndDate(endDateFormatted, isCurrentDate),
        description: formData.description,
        is_current: isCurrentDate,
      }
      onTrackingActions(OBAnalyticsName.work_experience_job_added)

      setJobs(prevJobs => {
        if (editableJob !== false) {
          const editedJob = [...prevJobs]
          editedJob.splice(editableJob, 1, preparedJobData)
          return editedJob
        } else return [...prevJobs, preparedJobData]
      })

      setIsFormOpen(false)
      setIsPromptOpen(false)
    },
    [
      startDate,
      endDate,
      checkDate,
      formData,
      isCurrentDate,
      setJobs,
      setIsFormOpen,
      editableJob,
      setIsPromptOpen,
    ]
  )

  const scrollToErrorField = useCallback(() => {
    switch (true) {
      case fieldsErr.company_name:
        scrollToElement(companyNameRef)
        break
      case fieldsErr.location:
        scrollToElement(locationRef)
        break
      case fieldsErr.job_title:
        scrollToElement(roleNameRef)
        break
      case fieldsErr.start_date:
        scrollToElement(startDateRef)
        break
      case fieldsErr.months:
        scrollToElement(monthRef)
        break
      case fieldsErr.isFutureDate:
        scrollToElement(monthRef)
        break
    }
  }, [fieldsErr])

  useEffect(() => {
    const isFieldFilled = !!(
      formData.company_name ||
      formData.start_date ||
      formData.end_date ||
      formData.job_title ||
      formData.location ||
      formData.is_current
    )
    setIsSubmitBtnActive(isFieldFilled)
  }, [formData])

  useEffect(() => {
    if (isClickCloseBtn) {
      onCloseModal(isDataChanged, setIsFormOpen)
      setIsClickCloseBtn(false)
    }
  }, [
    isDataChanged,
    isClickCloseBtn,
    onCloseModal,
    setIsClickCloseBtn,
    setIsFormOpen,
  ])

  useEffect(() => {
    if (isPromptOpen && isSaveClicked) {
      checkEmptyFields(fieldsErr)
      setIsSaveClicked(false)
    }
  }, [checkEmptyFields, fieldsErr, isPromptOpen, isSaveClicked])

  useEffect(() => {
    isSubmitBtnClick && scrollToErrorField()
    setIsSubmitBtnClick(false)
  }, [scrollToErrorField, fieldsErr, isSubmitBtnClick])

  return (
    <AddJobFormUI
      {...otherProps}
      inputBlockRef={inputBlockRef}
      suggestions={suggestions}
      onClickSuggestion={onClickSuggestion}
      formData={formData}
      startYear={startYear}
      isCurrentDate={isCurrentDate}
      toggleCheckbox={toggleCheckbox}
      months={months}
      inputHandler={inputHandler}
      textareaHandler={textareaHandler}
      onChangeMonth={onChangeMonth}
      onChangeStartDate={onChangeStartDate}
      onSubmitHandler={onSubmitHandler}
      isSubmitBtnActive={isSubmitBtnActive}
      fieldsErr={fieldsErr}
      setIsFormOpen={setIsFormOpen}
      setIsPromptOpen={setIsPromptOpen}
      setIsClickCloseBtn={setIsClickCloseBtn}
      isPromptOpen={isPromptOpen}
      setIsSaveClicked={setIsSaveClicked}
      companyNameRef={companyNameRef}
      roleNameRef={roleNameRef}
      locationRef={locationRef}
      startDateRef={startDateRef}
      monthRef={monthRef}
    />
  )
}
