import React, { useEffect } from 'react'
import { EFieldProperies, FilterType } from '../../../../../models'
import {
  setEnteredZipCode,
  setFilters,
  useAppDispatch,
} from '../../../../../redux'
import { useTypedSelector } from '../../../../../utils/hooks'
import { SearchItem } from '../../../../atoms'
import { zipFromSearch } from '../../../../organism'
import './searchResultList.scss'

export const SearchResultlist = () => {
  const dispatch = useAppDispatch()
  const filters = useTypedSelector(state => state.employer.filters)
  const enteredZipCode = useTypedSelector(s => s.employer.enteredZipcode)

  useEffect(() => {
    enteredZipCode === null && sessionStorage.setItem(zipFromSearch, '')
  }, [enteredZipCode])

  return (
    <div className="search-filter-result-wrapper">
      <div className="search-filter-result-title">Displays results for:</div>
      <div className="search-filter-result-list-wrapper">
        <ul className="search-filter-result-list">
          {filters.role &&
            filters.role.map(el => (
              <li className="search-filter-result-item" key={el.id}>
                <SearchItem
                  item={el.choice}
                  onRemove={() => {
                    dispatch(
                      setFilters({
                        ...filters,
                        role: filters.role?.filter(elem => elem !== el) ?? null,
                        type: {
                          type: FilterType.search,
                          properties: filters.role?.length
                            ? EFieldProperies.regular
                            : null,
                        },
                      })
                    )
                  }}
                />
              </li>
            ))}
          {filters.name && (
            <li className="search-filter-result-item" key={filters.name}>
              <SearchItem
                item={filters.name}
                onRemove={() => {
                  dispatch(
                    setFilters({
                      ...filters,
                      name: null,
                      type: {
                        type: FilterType.search,
                        properties: filters.role?.length
                          ? EFieldProperies.regular
                          : null,
                      },
                    })
                  )
                }}
              />
            </li>
          )}
          {filters.location &&
            filters.location.map(el => {
              return (
                <li className="search-filter-result-item" key={el}>
                  <SearchItem
                    renderAsZipcode={el === enteredZipCode}
                    item={el}
                    onRemove={() => {
                      el === enteredZipCode && dispatch(setEnteredZipCode(null))
                      dispatch(
                        setFilters({
                          ...filters,
                          location:
                            filters.location?.filter(elem => elem !== el) ??
                            null,
                        })
                      )
                    }}
                  />
                </li>
              )
            })}
          {filters.skills &&
            filters.skills.map(el => (
              <li className="search-filter-result-item" key={el.value.id}>
                <SearchItem
                  item={el.value.choice}
                  onRemove={() => {
                    dispatch(
                      setFilters({
                        ...filters,
                        skills:
                          filters.skills?.filter(
                            elem => elem.value.id !== el.value.id
                          ) ?? null,
                      })
                    )
                  }}
                />
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}
