import { useCallback, useState } from 'react'
import { ActionSidebarUI } from './ActionSidebarUI'
import './actionSidebar.scss'
import { WorkerInfo, IHistoryData } from '../../../../../models'
import { ModalData } from '../../../../../pages/InAppFlow'
import { useStateWithDep } from '../../../../../utils/hooks'
import { useMutation, useQuery } from 'react-query'
import {
  addToCategory,
  removeFromCategory,
  getHistoryList,
} from '../../../../../services/employerAPI'
import { toast } from 'react-toastify'
import moment from 'moment'
import { EmployerQueries } from '../../../../../utils/constants'

export interface IEmployerHistory {
  title: string
  date: string
  description: string | null
  photo: string | null
}

interface Props {
  workerData: WorkerInfo | null
  modalAction: (activeModal: ModalData) => void
  uniqueUrl: string
  isDisabletInterviewButton: boolean
}

export const ActionSidebar = (props: Props) => {
  const { workerData, modalAction, uniqueUrl } = props
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isSaved, setIsSaved] = useStateWithDep<boolean>(
    workerData?.user_card?.is_saved ?? false
  )

  const { data: historyList }: { data: IHistoryData | undefined } = useQuery(
    EmployerQueries.historyList,
    () => getHistoryList(uniqueUrl)
  )

  const addToCategoryMutation = useMutation(
    (data: { id: number; category: string }) =>
      addToCategory(data.id, data.category)
  )
  const removeFromCategoryMutation = useMutation(
    (data: { id: number; category: string }) =>
      removeFromCategory(data.id, data.category)
  )

  const saveAction = useCallback(
    (category: string, id: number, addAction: boolean) => {
      const mutateVariables = { id, category }
      const mutateOptions = {
        onSuccess: () => {
          setIsSaved(addAction)
        },
        onError: (err: any) => {
          if (err instanceof Error) toast.error(err.message)
        },
      }

      addAction
        ? addToCategoryMutation.mutate(mutateVariables, mutateOptions)
        : removeFromCategoryMutation.mutate(mutateVariables, mutateOptions)
    },
    [addToCategoryMutation, removeFromCategoryMutation, setIsSaved]
  )

  const convertedHistoryList = historyList?.items.map(item => {
    const actions = item.action.split('_').join(' ')
    const title = !item.owned_by
      ? 'Unknown ' + actions
      : item.owned_by.first_name + ' ' + actions

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const convertedTime = moment
      .utc(item.created_at)
      .tz(userTimeZone)
      .format('dddd MMMM Do h:mmA z')

    const photo = !item.owned_by ? null : item.owned_by.profile_picture
    return {
      title,
      date: convertedTime,
      description: item.feedback,
      photo,
    }
  })

  return (
    <ActionSidebarUI
      {...props}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      userData={workerData?.user_card ?? null}
      isSaved={isSaved}
      saveAction={saveAction}
      modalAction={modalAction}
      historyList={convertedHistoryList}
      isSaving={
        addToCategoryMutation.isLoading || removeFromCategoryMutation.isLoading
      }
    />
  )
}
