import React, { useCallback, useState } from 'react'
import {
  EJobPlaceholders,
  EJobTitle,
  FormatedExpList,
} from '../../workExperienceFuncAndVariables'
import { EJobFields } from '../../../../../../../pages/Onboarding/OBLastJobs/OBLastJobs'
import {
  AutocompleteInput,
  Checkbox,
  ECheckboxTheme,
  Input,
} from '../../../../../../atoms'
import { useMutation } from 'react-query'
import { companiesAutocompleteOB } from '../../../../../../../services/workerApi'
import { extraSpacesFormatting } from '../../../../../../../utils/scripts'

interface Props {
  modalWorkExp: FormatedExpList[]
  removeJob: (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    jobNumber: number
  ) => void
  onChangeInputValue: (e: string, numberJob: number, inputName: string) => void
  toggleCheckbox: (id: number, isChecked: boolean) => void
}

export const ModalWorkList = (props: Props) => {
  const { modalWorkExp, removeJob, onChangeInputValue, toggleCheckbox } = props
  const [suggestions, setSuggestions] = useState<string[]>([])

  const companiesAutocompleteMutation = useMutation(
    (value: string) => companiesAutocompleteOB(value),
    {
      onSuccess(data) {
        const uniqueCompanies = new Set(data)
        setSuggestions(Array.from(uniqueCompanies))
      },
    }
  )

  const inputHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.target.value && companiesAutocompleteMutation.mutate(e.target.value)
    },
    [companiesAutocompleteMutation]
  )

  const getJobTitle = useCallback(
    (jobNumber: number) => {
      if (modalWorkExp.length < 2) {
        return (
          <p className="work-experience-modal__form-title">
            Please provide the details of your most recent position
          </p>
        )
      }

      return jobNumber === 1 ? (
        <p className="work-experience-modal__form-title-recent-job">
          Most Recent Job
        </p>
      ) : (
        <div className="work-experience-modal__form-title-wrapper">
          <p className="work-experience-modal__form-title-jobs">
            {`Job #${jobNumber}`}
          </p>
          <p
            className="work-experience-modal__form-title-remote-jobs"
            data-testid={`work-experience-modal__form-title-remote-jobs-${jobNumber}`}
            onClick={e => removeJob(e, jobNumber)}
          >
            X
          </p>
        </div>
      )
    },
    [modalWorkExp.length, removeJob]
  )

  return (
    <>
      {modalWorkExp?.map(question => {
        return (
          <React.Fragment key={question.job_number}>
            {getJobTitle(question.job_number)}
            <div className="work-experience-modal__form-input-block">
              <p className="work-experience-modal__form-question-title">
                {EJobTitle.company_name}
              </p>
              <AutocompleteInput
                className="work-experience-modal__input"
                value={
                  question.questions_work_experience[EJobFields.company_name]
                }
                placeholder={EJobPlaceholders.company}
                onChange={e => {
                  inputHandler(e)
                  onChangeInputValue(
                    extraSpacesFormatting(e.target.value),
                    question.job_number,
                    EJobFields.company_name
                  )
                }}
                suggestions={suggestions}
                onClickSuggestion={value =>
                  onChangeInputValue(
                    value,
                    question.job_number,
                    EJobFields.company_name
                  )
                }
                showFocus={false}
              />
              <p className="work-experience-modal__form-question-title">
                {EJobTitle.job_title}
              </p>
              <Input
                name={EJobFields.job_title}
                value={question.questions_work_experience[EJobFields.job_title]}
                className="work-experience-modal__input input-container__input"
                placeholder={EJobPlaceholders.job_title}
                onChange={e =>
                  onChangeInputValue(
                    extraSpacesFormatting(e.target.value),
                    question.job_number,
                    EJobFields.job_title
                  )
                }
              />
              <p className="work-experience-modal__form-question-title">
                {EJobTitle.location}
              </p>
              <AutocompleteInput
                className="work-experience-modal__input"
                value={
                  question.questions_work_experience[EJobFields.location] ?? ''
                }
                placeholder={EJobPlaceholders.location}
                onChange={e => {
                  onChangeInputValue(
                    extraSpacesFormatting(e.target.value),
                    question.job_number,
                    EJobFields.location
                  )
                }}
                places
                onClickSuggestion={value => {
                  onChangeInputValue(
                    value,
                    question.job_number,
                    EJobFields.location
                  )
                }}
                showFocus={false}
              />
              <p className="work-experience-modal__form-question-title">
                {EJobTitle.start_date}
              </p>
              <Input
                name={EJobFields.start_date}
                value={
                  question.questions_work_experience[EJobFields.start_date]
                }
                className="work-experience-modal__input input-container__input"
                placeholder={EJobPlaceholders.start_date}
                onChange={e =>
                  onChangeInputValue(
                    e.target.value,
                    question.job_number,
                    EJobFields.start_date
                  )
                }
              />
              <p className="work-experience-modal__form-question-title">
                {EJobTitle.end_date}
              </p>
              <Input
                name={EJobFields.end_date}
                value={question.questions_work_experience[EJobFields.end_date]}
                className="work-experience-modal__input input-container__input"
                placeholder={EJobPlaceholders.end_date}
                onChange={e =>
                  onChangeInputValue(
                    e.target.value,
                    question.job_number,
                    EJobFields.end_date
                  )
                }
              />
            </div>

            <Checkbox
              idCheckbox={question.job_number}
              className="work-experience-modal__form-question-checkbox"
              name="I currently work here"
              checked={
                question.questions_work_experience.is_current ? true : undefined
              }
              toggleCheckbox={toggleCheckbox}
              theme={ECheckboxTheme.orange}
            />
          </React.Fragment>
        )
      })}
    </>
  )
}
