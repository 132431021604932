import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { OBLanguagesUI } from './OBLanguagesUI'
import { useHistory } from 'react-router-dom'
import * as route from '../../../services/route'
import { usePageNumUpdateOB, useTypedSelector } from '../../../utils/hooks'
import { getLastPageState } from '../../../utils/hooks/obHooks/usePageNumUpdateOB'
import {
  getUserInfoFromStorage,
  onTrackingActions,
} from '../../../utils/scripts'
import {
  EWorkerSStoreKeys,
  OBAnalyticsName,
  WorkerQueries,
} from '../../../utils/constants'
import { useMutation, useQueryClient } from 'react-query'
import { IWorkerInfoOB, SkillsInfoOnlylanguages } from '../../../models'
import { updateSkillsInfo } from '../../../services/workerApi'
import { toast } from 'react-toastify'
import { setWorkerInfo, useAppDispatch } from '../../../redux'
import { useTranslation } from 'react-i18next'

export const OBLanguages = () => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()
  const pageStateOBMutation = usePageNumUpdateOB()
  const userInfo = useTypedSelector(s => s.obInfo.workerInfo)
  const choiceLists = useTypedSelector(s => s.variables.choiceListForWorker)
  const lastPage = userInfo.last_page < 10 ? 10 : userInfo.last_page
  const lastPageState = useMemo(() => getLastPageState(lastPage), [lastPage])
  const userInfoFromStorage = useMemo(() => getUserInfoFromStorage(), [])
  const { t } = useTranslation()

  const supportedLanguages = useMemo(() => {
    const languages = choiceLists.languages
      ?.filter(lang => lang.is_supported)
      .map(lang => t(`preffered_languages.${lang.choice}`))

    if (!languages?.length) return ''
    if (languages.length === 1) return languages[0]
    if (languages.length === 2)
      return languages.join(` ${t('preffered_languages.and')} `)

    return `${languages.slice(0, -1).join(', ')} ${t(
      'preffered_languages.and'
    )} ${languages[languages?.length - 1]}`
  }, [choiceLists.languages, t])

  const defaultSelectedlanguages = useMemo(() => {
    let languages: string[] = []
    if (userInfoFromStorage) {
      languages = [...userInfoFromStorage.user_data.language].map(
        item => item.choice
      )
    } else if (userInfo) {
      languages = [...userInfo.user_data.language].map(item => item.choice)
    }
    return languages
  }, [userInfoFromStorage, userInfo])

  const defaultPreferredlanguages = useMemo(() => {
    let preffedLanguage:
      | {
          id: number
          value: string
        }
      | undefined

    if (userInfoFromStorage) {
      const language = userInfoFromStorage.user_data.language.find(
        item => item.is_preferred
      )
      const languageIndex = userInfoFromStorage.user_data.language.findIndex(
        item => item.is_preferred
      )
      preffedLanguage = {
        id: languageIndex,
        value: language?.choice ?? '',
      }
    } else if (userInfo) {
      const language = userInfo.user_data.language.find(
        item => item.is_preferred
      )
      const languageIndex = userInfo.user_data.language.findIndex(
        item => item.is_preferred
      )

      preffedLanguage = {
        id: languageIndex,
        value: language?.choice ?? '',
      }
    }
    return preffedLanguage
  }, [userInfoFromStorage, userInfo])

  const [selectedLanguages, setSelectedLanguages] = useState<string[]>(
    defaultSelectedlanguages
  )
  const [preferredLanguage, setPreferredLanguage] = useState<
    { id: number; value: string } | undefined
  >(defaultPreferredlanguages)

  const languageMutation = useMutation(
    (data: SkillsInfoOnlylanguages) => updateSkillsInfo(data),
    {
      onError: err => {
        if (err instanceof Error) toast.error(err.message)
      },
    }
  )
  const preparedSelectedLanguage = useMemo(() => {
    return selectedLanguages.map((elem, index) => {
      return {
        id: index,
        value: elem,
      }
    })
  }, [selectedLanguages])

  const isNotSupportedSelectedLanguage = useMemo(() => {
    return choiceLists.languages?.find(
      lang =>
        lang.choice ===
          preparedSelectedLanguage.find(
            language => language.id === preferredLanguage?.id
          )?.value && !lang.is_supported
    )
  }, [choiceLists.languages, preparedSelectedLanguage, preferredLanguage?.id])

  const newSkillsInfo: SkillsInfoOnlylanguages = useMemo(
    () => ({
      language: selectedLanguages.length === 1 ? [] : selectedLanguages,
      preferred_language:
        selectedLanguages.length === 1
          ? selectedLanguages[0]
          : preferredLanguage?.value ?? '',
    }),
    [preferredLanguage, selectedLanguages]
  )
  const selectedLanguagesList = newSkillsInfo.language?.map(lang => ({
    choice: lang,
    is_preferred: preferredLanguage?.value === lang,
  }))

  const isLanguageChanged = useMemo(() => {
    if (userInfo.user_data.language.length !== selectedLanguages.length)
      return true

    if (
      userInfo.user_data.language.find(el => el.is_preferred)?.choice !==
      preferredLanguage?.value
    )
      return true

    if (
      userInfo.user_data.language.some(
        elem => !selectedLanguages.includes(elem.choice)
      )
    )
      return true
    return false
  }, [userInfo.user_data.language, preferredLanguage?.value, selectedLanguages])

  const getNewData = useCallback(
    (data: IWorkerInfoOB) => ({
      ...data,
      last_page: lastPage,
      last_location: lastPageState.last_location,
      user_data: {
        ...data.user_data,
        language:
          selectedLanguages.length === 1
            ? [
                {
                  choice: selectedLanguages[0],
                  is_preferred: true,
                },
              ]
            : selectedLanguagesList,
      },
    }),
    [
      selectedLanguagesList,
      lastPage,
      lastPageState.last_location,
      selectedLanguages,
    ]
  )

  const saveSessionStorage = useCallback(() => {
    sessionStorage.setItem(
      EWorkerSStoreKeys.obData,
      JSON.stringify(getNewData(userInfoFromStorage ?? userInfo))
    )
  }, [getNewData, userInfoFromStorage, userInfo])

  const isDisabledNextBtn = useMemo(() => {
    if (!selectedLanguages.length) return true
    if (selectedLanguages.length === 1) return false
    if (selectedLanguages.length > 1 && !preferredLanguage?.value) return true
    return false
  }, [selectedLanguages.length, preferredLanguage])

  const preparedNewSkillsInfo: SkillsInfoOnlylanguages = useMemo(
    () => ({
      ...newSkillsInfo,
      language: selectedLanguages.filter(
        lang => lang !== newSkillsInfo.preferred_language
      ),
    }),
    [selectedLanguages, newSkillsInfo]
  )

  const preFiledLanguage: boolean = useMemo(
    () =>
      JSON.parse(
        sessionStorage.getItem(EWorkerSStoreKeys.obPreFilledLanguage) ?? 'false'
      ),
    []
  )

  const onClickBack = useCallback(() => {
    saveSessionStorage()
    history.push(route.OBPath.onboardingLastJobs)
  }, [history, saveSessionStorage])

  const onClickNext = useCallback(async () => {
    if (isLanguageChanged || preFiledLanguage) {
      await onTrackingActions(OBAnalyticsName.languages_submited)
      languageMutation.mutate(preparedNewSkillsInfo, {
        onSuccess() {
          pageStateOBMutation.mutate(lastPageState, {
            onSuccess() {
              queryClient.setQueryData(
                WorkerQueries.skillsInfo,
                preparedNewSkillsInfo
              )
              dispatch(setWorkerInfo(getNewData(userInfo)))
              saveSessionStorage()
              sessionStorage.removeItem(EWorkerSStoreKeys.obPreFilledLanguage)
              history.push(route.OBPath.onboardingAdditionalInfo)
            },
          })
        },
      })
    } else {
      saveSessionStorage()
      history.push(route.OBPath.onboardingAdditionalInfo)
    }
  }, [
    history,
    pageStateOBMutation,
    lastPageState,
    languageMutation,
    preparedNewSkillsInfo,
    dispatch,
    saveSessionStorage,
    queryClient,
    getNewData,
    userInfo,
    isLanguageChanged,
    preFiledLanguage,
  ])

  useEffect(() => {
    if (
      !preparedSelectedLanguage.find(
        el => el.value === preferredLanguage?.value
      )
    )
      setPreferredLanguage(undefined)
  }, [preparedSelectedLanguage, preferredLanguage])

  return (
    <OBLanguagesUI
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      languagesList={choiceLists.languages ?? []}
      selectedLanguages={selectedLanguages}
      setSelectedLanguages={setSelectedLanguages}
      preferredLanguage={preferredLanguage}
      setPreferredLanguage={setPreferredLanguage}
      preparedSelectedLanguage={preparedSelectedLanguage}
      isNotSupportedSelectedLanguage={!!isNotSupportedSelectedLanguage}
      isDisabled={isDisabledNextBtn || languageMutation.isLoading}
      supportedLanguages={supportedLanguages}
    />
  )
}
