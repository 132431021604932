import { createContext } from 'react'
import {
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult,
} from 'react-query'
import { ListOfWorkers } from '../../models/Employer'

interface Props {
  workersList: ListOfWorkers | null
  counter: number | null
  setCounter: React.Dispatch<React.SetStateAction<number | null>>
  totalPages: number | undefined
  refreshList: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<ListOfWorkers | undefined, Error | null>>
  setCardLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const EmployeesListContext = createContext<Props>({
  workersList: null,
  counter: null,
  setCounter: () => null,
  totalPages: undefined,
  refreshList: () => new Promise(res => res),
  setCardLoading: () => false,
})
