import React, { useEffect, useState, useMemo } from 'react'
import { mobileWidth } from '../../constants'

interface ContextProps {
  onMobile: boolean
}

const defaultContextValues: ContextProps = {
  onMobile: false,
}

const MediaQueryContext =
  React.createContext<ContextProps>(defaultContextValues)

interface Props {
  children?: React.ReactNode
}

export const MediaQueryContextProvider = ({ children }: Props) => {
  const [onMobile, setOnMobile] = useState<boolean>(false)
  const mediaQueryChanged = (e: MediaQueryListEvent): void =>
    setOnMobile(e.matches)

  useEffect(() => {
    const mql = window.matchMedia(`(max-width: ${mobileWidth}px)`)
    mql?.addEventListener('change', mediaQueryChanged)
    setOnMobile(mql?.matches)

    return (): void => mql?.removeEventListener('change', mediaQueryChanged)
  }, [])

  const providerValue = useMemo(
    () => ({
      onMobile,
    }),
    [onMobile]
  )

  return (
    <MediaQueryContext.Provider value={providerValue}>
      {children}
    </MediaQueryContext.Provider>
  )
}

export const useMediaContext = (): boolean => {
  const context = React.useContext(MediaQueryContext)

  if (context === undefined) {
    throw new Error(
      'useMediaContext must be used within a MediaQueryContextProvider'
    )
  }

  return context.onMobile
}
