import React from 'react'
import { Helmet } from 'react-helmet'
import './adminWorkers.scss'
import { adminTabs } from './AdminWorkers'
import { EAdminPageTitle } from '../../../utils/constants'

interface Props {
  activeTab: string
  setActiveTab: React.Dispatch<React.SetStateAction<string>>
  activeTabContent?: JSX.Element
}

export const AdminWorkersUI = (props: Props) => {
  const { activeTab, setActiveTab, activeTabContent } = props
  return (
    <div className="admin-workers">
      <Helmet>
        <title>{EAdminPageTitle.adminWorkers}</title>
      </Helmet>

      <div className="admin-workers__tabs-container">
        <div className="admin-workers__tabs">
          <ul
            className="admin-workers__tabs-list"
            data-testid="admin-workers__tabs-list"
          >
            {adminTabs.map(tab => (
              <li
                key={tab.id}
                className={`${
                  tab.name === activeTab && 'admin-workers__tabs-tab_active'
                } admin-workers__tabs-tab`}
                onClick={() => {
                  setActiveTab(tab.name)
                }}
              >
                <img src={tab.img} alt={`${tab.name} icon`} />
                {tab.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="admin-workers__content">{activeTabContent}</div>
    </div>
  )
}
