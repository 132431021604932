import React, { useCallback } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { QueryState } from 'react-query/types/core/query'
import { toast } from 'react-toastify'
import { PublicInfo } from '../../../../../../models'
import { ERROR_SOMETHING } from '../../../../../../services/auth-service'
import { updatePublicInfo } from '../../../../../../services/workerApi/profileAPI'
import {
  ProfileAnalyticsName,
  WorkerQueries,
} from '../../../../../../utils/constants'
import {
  inputFormatting,
  onTrackingActions,
} from '../../../../../../utils/scripts'
import { INames } from '../PublicProfileHeader'
import { UsernameEditModalUI } from './UsernameEditModalUI'

interface Props {
  publicInfo: PublicInfo
  setPublicInfo: React.Dispatch<React.SetStateAction<PublicInfo>>
  setIsEditNameModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  isConfirmationModalVisible: boolean
  setIsConfirmationModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  names: INames
  setNames: React.Dispatch<React.SetStateAction<INames>>
}

export const UsernameEditModal = (props: Props) => {
  const {
    publicInfo,
    setPublicInfo,
    setIsEditNameModalVisible,
    isConfirmationModalVisible,
    setIsConfirmationModalVisible,
    names,
    setNames,
  } = props

  const queryClient = useQueryClient()
  const publicInfoQuery: QueryState<PublicInfo, undefined> | undefined =
    queryClient.getQueryState(WorkerQueries.publicInfo)
  const userNameMutation = useMutation((data: INames) => updatePublicInfo(data))

  const getErrorMessage = useCallback(() => {
    switch (true) {
      case !names.first_name.length && !names.last_name.length:
        return `'First Name' and 'Last Name' fields are required`
      case !names.first_name.length:
        return `The 'First Name' field is required`
      default:
        return `The 'Last Name' field is required`
    }
  }, [names.first_name.length, names.last_name.length])

  const handleSubmit = useCallback(() => {
    if (!names.first_name.length || !names.last_name.length) {
      toast.error(getErrorMessage())
      return
    }

    const updatedPublicInfo = { ...publicInfoQuery?.data, ...names }

    setPublicInfo(prevInfo => {
      return {
        ...prevInfo,
        ...names,
      }
    })

    userNameMutation.mutate(names, {
      onSuccess: () => {
        queryClient.setQueryData(WorkerQueries.publicInfo, updatedPublicInfo)
        onTrackingActions(ProfileAnalyticsName.profile_edited)
        setIsEditNameModalVisible(false)
      },
      onError: err => {
        if (err instanceof Error) toast.error(ERROR_SOMETHING)
      },
    })
  }, [
    getErrorMessage,
    names,
    publicInfoQuery?.data,
    queryClient,
    setIsEditNameModalVisible,
    setPublicInfo,
    userNameMutation,
  ])

  const onRemoveChanges = useCallback(() => {
    setPublicInfo(publicInfo)
    setNames({
      first_name: publicInfo.first_name ?? '',
      last_name: publicInfo.last_name ?? '',
      nickname: publicInfo.nickname ?? '',
    })
    setIsConfirmationModalVisible(false)
    setIsEditNameModalVisible(false)
  }, [
    publicInfo,
    setIsConfirmationModalVisible,
    setIsEditNameModalVisible,
    setNames,
    setPublicInfo,
  ])

  const onInputChange = useCallback(
    (value: string, inputName: string) => {
      setNames(prevNames => {
        const maxLength = inputName === 'nickname' ? 10 : 50
        return {
          ...prevNames,
          [inputName]: inputFormatting(value, maxLength),
        }
      })
    },
    [setNames]
  )

  return (
    <UsernameEditModalUI
      isConfirmationModalVisible={isConfirmationModalVisible}
      setIsConfirmationModalVisible={setIsConfirmationModalVisible}
      names={names}
      handleSubmit={handleSubmit}
      isSaving={userNameMutation.isLoading}
      onRemoveChanges={onRemoveChanges}
      onInputChange={onInputChange}
    />
  )
}
