import moment from 'moment'
import React, { useState, useCallback, useMemo, useRef } from 'react'
import { IFilterDate } from '../../../models/AdminWorkes'
import './doubleCalendar.scss'
import { DoubleCalendarUI } from './DoubleCalendarUI'

interface IProps {
  period: IDatePeriod
  setPeriod: React.Dispatch<React.SetStateAction<IDatePeriod>>
  handleDatePeriodChanges: (period: IFilterDate) => void
  filterOpeningTogler?: (filterName: string, isOpen: boolean) => void
  filterIsOpen?: Record<string, boolean>
  filterName?: string
}

export interface IDatePeriod {
  date_min: Date | null
  date_max: Date | null
}

export const DoubleCalendar = (prop: IProps) => {
  const {
    period,
    setPeriod,
    handleDatePeriodChanges,
    filterOpeningTogler,
    filterIsOpen,
    filterName,
  } = prop

  const calendarRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [date, setDate] = useState(new Date())

  const isOneMonth =
    window.innerWidth - (calendarRef.current?.getBoundingClientRect().x ?? 0) <
    584

  const externalIsOpen = useMemo(
    () => (filterIsOpen && filterName ? filterIsOpen[filterName] : isOpen),
    [filterIsOpen, filterName, isOpen]
  )

  const getPrevDate = useCallback(
    (currentDate: Date) => {
      const newDate = new Date(currentDate)

      if (date.getMonth() === 0) {
        newDate.setFullYear(date.getFullYear() - 1)
        newDate.setMonth(11)
      } else {
        newDate.setMonth(date.getMonth() - 1)
      }

      return newDate
    },
    [date]
  )

  const getNextDate = useCallback(
    (currentDate: Date) => {
      const newDate = new Date(currentDate)

      if (date.getMonth() === 11) {
        newDate.setFullYear(date.getFullYear() + 1)
        newDate.setMonth(0)
      } else {
        newDate.setMonth(date.getMonth() + 1)
      }

      return newDate
    },
    [date]
  )

  const onArrowClick = useCallback(
    (event: React.MouseEvent, action: 'prev' | 'next') => {
      const parentBtn: Element | null = event.currentTarget.parentElement
      const isParentBtnDisabled = parentBtn
        ? parentBtn.hasAttribute('disabled')
        : true

      if (!isParentBtnDisabled) {
        switch (action) {
          case 'next':
            setDate(getNextDate(date))
            break
          case 'prev':
            setDate(getPrevDate(date))
            break
          default:
            break
        }
      }
    },
    [date, getNextDate, getPrevDate]
  )

  const dateToString = useCallback((unformattedDate: Date | null) => {
    if (!unformattedDate) return null
    return moment(unformattedDate).format('M/D/YY')
  }, [])

  const handleClickDay = useCallback(
    (currentDate: Date) => {
      switch (true) {
        case dateToString(period.date_min) === dateToString(currentDate) ||
          dateToString(period.date_max) === dateToString(currentDate):
          setPeriod({
            date_min: null,
            date_max: null,
          })

          handleDatePeriodChanges({
            date_min: null,
            date_max: null,
          })
          break

        case !period.date_min:
        case period.date_min && period.date_min > currentDate:
          setPeriod(prevDate => {
            prevDate.date_max &&
              handleDatePeriodChanges({
                date_min: dateToString(currentDate),
                date_max: dateToString(prevDate.date_max),
              })

            return {
              date_min: currentDate,
              date_max: prevDate.date_max,
            }
          })
          break

        case period.date_min &&
          !period.date_max &&
          period.date_min > currentDate:
          setPeriod(prevDate => {
            handleDatePeriodChanges({
              date_min: dateToString(currentDate),
              date_max: dateToString(prevDate.date_min),
            })

            return {
              date_min: currentDate,
              date_max: prevDate.date_min,
            }
          })
          break

        default:
          setPeriod(prevDate => {
            handleDatePeriodChanges({
              date_min: dateToString(prevDate.date_min),
              date_max: dateToString(currentDate),
            })

            return {
              date_min: prevDate.date_min,
              date_max: currentDate,
            }
          })
      }
    },
    [
      dateToString,
      handleDatePeriodChanges,
      period.date_max,
      period.date_min,
      setPeriod,
    ]
  )

  const onBtnClick = useCallback(() => {
    if (filterOpeningTogler) {
      filterOpeningTogler(filterName ?? '', !externalIsOpen)
    } else {
      setIsOpen(!isOpen)
    }
  }, [isOpen, filterOpeningTogler, filterName, externalIsOpen])

  return (
    <DoubleCalendarUI
      isOpen={externalIsOpen}
      onBtnClick={onBtnClick}
      period={period}
      startDate={isOneMonth ? date : getPrevDate(date)}
      handleClickDay={handleClickDay}
      onArrowClick={onArrowClick}
      isOneMonth={isOneMonth}
      ref={calendarRef}
    />
  )
}
