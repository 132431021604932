import React, { useCallback, useContext, useMemo, useState } from 'react'
import { ShareDataOfWorkerUI } from './ShareDataOfWorkerUI'
import { EmployeeInfoContext } from '../../../../Context'
import { IDataForShare, LastSharedCoworkerList } from '../../../../../models'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { shareWorkerData } from '../../../../../services/employerAPI'
import { ElemObj } from '../../../../atoms'
import { EmployerQueries } from '../../../../../utils/constants'

interface Props {
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const ShareDataOfWorker = (props: Props) => {
  const { setIsModalVisible } = props
  const queryClient = useQueryClient()
  const workerDataUserCard =
    useContext(EmployeeInfoContext).workerData?.user_card

  const coworkerListCash: LastSharedCoworkerList | undefined =
    queryClient.getQueryState<LastSharedCoworkerList, undefined>(
      EmployerQueries.lastSharedList
    )?.data

  const listCoWorkers: ElemObj[] = useMemo(() => {
    return (
      coworkerListCash?.co_workers_list.map((co_worker, index) => {
        return {
          id: index + 1,
          element: `${co_worker.first_name} ${co_worker.last_name}`,
        }
      }) ?? []
    )
  }, [coworkerListCash?.co_workers_list])

  const defaultCoWorkerData = useMemo(() => {
    return listCoWorkers.length > 0
      ? {
          id: listCoWorkers[0].id,
          element: listCoWorkers[0].element,
        }
      : {
          id: 0,
          element: '',
        }
  }, [listCoWorkers])

  const [coWorker, setCoWorker] = useState<ElemObj>(defaultCoWorkerData)

  const defaultShareData: IDataForShare = {
    unique_url: workerDataUserCard?.unique_url ?? '',
    to_username: '',
    note: '',
    feedback_allowed: false,
    interview_allowed: false,
  }
  const [dataForShare, setDataForShare] =
    useState<IDataForShare>(defaultShareData)
  const maxLengthNote = 400

  const shareWorkerDataMutation = useMutation(
    (data: IDataForShare) => shareWorkerData(data),
    {
      onSuccess() {
        setCoWorker({ id: 0, element: '' })
        setIsModalVisible(false)
        queryClient.refetchQueries(EmployerQueries.lastSharedList)
      },
      onError(err) {
        if (err instanceof Error) toast.error(err.message)
      },
    }
  )
  const handleSubmit = useCallback(() => {
    const interviewAllowed = !dataForShare.feedback_allowed
      ? false
      : dataForShare.interview_allowed
    const preparedData = {
      ...dataForShare,
      to_username:
        coworkerListCash?.co_workers_list.find(
          (_, index) =>
            typeof coWorker.id === 'number' && index === coWorker.id - 1
        )?.username ?? '',
      interview_allowed: interviewAllowed,
    }
    setDataForShare(prevData => ({
      ...prevData,
      interview_allowed: interviewAllowed,
    }))
    shareWorkerDataMutation.mutate(preparedData)
  }, [
    coworkerListCash?.co_workers_list,
    dataForShare,
    shareWorkerDataMutation,
    coWorker.id,
  ])

  return (
    <ShareDataOfWorkerUI
      listCoWorkers={listCoWorkers}
      dataForShare={dataForShare}
      setDataForShare={setDataForShare}
      maxLengthNote={maxLengthNote}
      workerName={workerDataUserCard?.first_name ?? ''}
      coWorker={coWorker}
      setCoWorker={setCoWorker}
      handleSubmit={handleSubmit}
    />
  )
}
