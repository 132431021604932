export const sentryEnv = {
  dev: {
    name: 'develop',
    url: 'https://dev.skillit.com',
  },
  stage: {
    name: 'stage',
    url: 'https://stage.skillit.com',
  },
  prod: {
    name: 'production',
    url: 'https://app.skillit.com',
  },
}
