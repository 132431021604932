import { createContext } from 'react'
import { IWorkerInfoOB, TWorkExperience } from '../../../models'
import { initialWorkerInfoOB } from '../../../utils/constants'

interface IProps {
  userInfo: IWorkerInfoOB
  jobs: TWorkExperience[]
  setJobs: React.Dispatch<React.SetStateAction<TWorkExperience[]>>
}

export const LastJobsContext = createContext<IProps>({
  userInfo: initialWorkerInfoOB,
  jobs: [],
  setJobs: () => [],
})
