import React from 'react'
import { NavItemType, NavMenuItem } from '.'
import { v4 as uuidv4 } from 'uuid'

interface Props {
  el: NavMenuItem
  onClickItem?: () => void
  onClickLogout?: () => void
  active: boolean
}

export const NavItem = (props: Props) => {
  const { el, onClickItem = () => {}, onClickLogout = () => {}, active } = props

  return (
    <div
      className="nav-menu__item-content"
      key={uuidv4()}
      onClick={() => {
        el.type === NavItemType.Logout ? onClickLogout() : onClickItem()
      }}
      data-testid={`nav-menu-item-${el.type}`}
    >
      <img
        className={`nav-menu__icon 
        ${active && 'nav-menu__icon_active'}`}
        alt="nav-item-icon"
        src={active ? el.activeIcon : el.icon}
      ></img>
      <li
        className={`nav-menu__item 
        ${active ? 'nav-menu__text_active' : ''}`}
      >
        {el.type.toString()}
      </li>
    </div>
  )
}
