import React, { useEffect, useRef } from 'react'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
  ZipCode,
} from 'use-places-autocomplete'
import { OBPlaceAutocompleteUI } from './OBPlaceAutocompleteUI'
import locationIcon from './../../../../assets/icons/employer/LocationZipCode.svg'
import { changeColorOfPartText } from '../../../../utils/scripts/changeColorOfPartText'
import { IHomeLocationOB, ILocationInfoOB } from '../../../../models'
import { toast } from 'react-toastify'
import { EWorkerSStoreKeys } from '../../../../utils/constants'

interface Props extends React.HTMLAttributes<HTMLElement> {
  setIsFilledPlace?: React.Dispatch<React.SetStateAction<boolean>>
  placeholder: string
  homeLocationPage?: true
  setLocationOB?: React.Dispatch<React.SetStateAction<ILocationInfoOB>>
  locationOB?: ILocationInfoOB
  relocationPage?: true
  preferredLocationsPage?: true
  onRemovedAddingPlace?: (
    uniqueKey: string,
    initPlacesList: ILocationInfoOB
  ) => void
  onAddPlace?: (
    initSuggestion: google.maps.places.AutocompletePrediction,
    initZipCode: ZipCode,
    initLat: number,
    initLng: number
  ) => void
  showFocus?: boolean
}

export const OBPlaceAutocomplete = (props: Props) => {
  const {
    className,
    setIsFilledPlace,
    placeholder,
    homeLocationPage,
    setLocationOB,
    locationOB,
    relocationPage,
    preferredLocationsPage,
    onRemovedAddingPlace,
    onAddPlace,
    showFocus,
  } = props
  const inputRef = useRef<HTMLInputElement>(null)
  const emptyHomeLocation: IHomeLocationOB = {
    zip_code: null,
    longitude: null,
    latitude: null,
    state: null,
    city: null,
    street: null,
    raw_input: null,
  }

  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: homeLocationPage ? [] : ['(cities)'],
      componentRestrictions: {
        country: 'us',
      },
    },
  })

  useEffect(() => {
    if (homeLocationPage && locationOB?.home_location && !value) {
      if (
        !locationOB.home_location?.raw_input &&
        locationOB.home_location?.city &&
        locationOB.home_location?.state
      ) {
        setValue(
          `${locationOB.home_location.city}, ${locationOB.home_location.state}`
        )
      } else if (locationOB.home_location?.raw_input) {
        setValue(locationOB.home_location.raw_input)
      }
    }
    if (relocationPage && locationOB?.relocation_location?.latitude && !value) {
      const defaultValue = locationOB.relocation_location.city
        ? `${locationOB.relocation_location.city}, ${locationOB.relocation_location.state}`
        : `${locationOB.relocation_location.state}`

      setValue(defaultValue)
    }
    // eslint-disable-next-line
  }, [locationOB?.home_location, locationOB?.relocation_location, setValue]) ///TODO need remote eslit-disable

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    if (!e.target.value && homeLocationPage && setLocationOB) {
      setLocationOB(prevLocation => {
        return {
          ...prevLocation,
          home_location: emptyHomeLocation,
        }
      })
    }
    if (
      !e.target.value &&
      relocationPage &&
      setLocationOB &&
      setIsFilledPlace
    ) {
      setIsFilledPlace(false)
      setLocationOB(prevLocation => {
        return {
          ...prevLocation,
          relocation_location: null,
        }
      })
    }
  }

  const handleSelect =
    (suggestion: google.maps.places.AutocompletePrediction) => () => {
      if (suggestion.terms.length < 2) {
        toast.error('Please, select at least a state')

        setLocationOB &&
          setLocationOB(prevLocationData => ({
            ...prevLocationData,
            home_location: emptyHomeLocation,
          }))

        return
      }

      const resultDescription = suggestion.description.replace(', USA', '')
      setValue(resultDescription, false)
      clearSuggestions()
      sessionStorage.removeItem(EWorkerSStoreKeys.placeAutocomplete)
      setIsFilledPlace && setIsFilledPlace(true)

      homeLocationPage &&
        setLocationOB &&
        setLocationOB(prevLocationData => {
          if (+suggestion.terms[suggestion.terms.length - 2].value) {
            return {
              ...prevLocationData,
              home_location: {
                ...prevLocationData.home_location,
                state: suggestion.terms[suggestion.terms.length - 3].value,
                city: suggestion.terms[suggestion.terms.length - 4].value,
                street: null,
              },
            }
          } else {
            return {
              ...prevLocationData,
              home_location: {
                ...prevLocationData.home_location,
                state: suggestion.terms[suggestion.terms.length - 2].value,
                city: suggestion.terms[suggestion.terms.length - 3]
                  ? suggestion.terms[suggestion.terms.length - 3].value
                  : null,
                street: suggestion.terms[suggestion.terms.length - 4]
                  ? suggestion.terms[suggestion.terms.length - 4].value
                  : null,
              },
            }
          }
        })

      getGeocode({ address: suggestion.description }).then(results => {
        const { lat, lng } = getLatLng(results[0])
        const zipCode = getZipCode(results[0], true)

        homeLocationPage &&
          setLocationOB &&
          setLocationOB(prevLocationData => {
            return {
              ...prevLocationData,
              home_location: {
                ...prevLocationData.home_location,
                latitude: lat,
                longitude: lng,
                zip_code: zipCode ?? '',
                raw_input: resultDescription,
              },
            }
          })

        relocationPage &&
          setLocationOB &&
          setLocationOB(prevReocationData => {
            return {
              ...prevReocationData,
              relocation_location: {
                ...prevReocationData.relocation_location,
                state: suggestion.terms[suggestion.terms.length - 2].value,
                city: suggestion.terms[suggestion.terms.length - 3]
                  ? suggestion.terms[suggestion.terms.length - 3].value
                  : null,
                zip_code: zipCode ?? '',
                latitude: lat,
                longitude: lng,
                date: null,
                street: null,
                raw_input: null,
              },
            }
          })
        if (
          preferredLocationsPage &&
          locationOB?.preferred_locations &&
          setLocationOB
        ) {
          onAddPlace && onAddPlace(suggestion, zipCode, lat, lng)
          setValue('')
        }
      })
    }

  const renderSuggestions = () => {
    return data.map(suggestion => {
      return (
        <li
          className="ob-place-autocomplete__suggestions-list-suggestion"
          key={suggestion.place_id}
        >
          <img
            src={locationIcon}
            alt="location-icon"
            className="ob-place-autocomplete__suggestions-list-suggestion-icon"
          />
          <p
            className="ob-place-autocomplete__suggestions-list-suggestion-description"
            onClick={handleSelect(suggestion)}
          >
            {changeColorOfPartText(suggestion.description, value, true)}
          </p>
        </li>
      )
    })
  }

  useEffect(() => {
    if (showFocus) {
      inputRef?.current?.focus()
    }
  }, [showFocus])

  return (
    <OBPlaceAutocompleteUI
      className={className}
      value={value}
      handleInput={handleInput}
      renderSuggestions={renderSuggestions}
      status={status}
      placeholder={placeholder}
      placesList={locationOB}
      onRemovedAddingPlace={onRemovedAddingPlace}
      preferredLocationsPage={preferredLocationsPage}
      ref={inputRef}
    />
  )
}
