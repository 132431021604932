import s from './remember-me.module.scss'

interface Props {
  showModal: (_: boolean) => void
  rememberMe: boolean
  onToggleRememberMe: () => void
  isEmailLogin: boolean
}

export const RememberMe = ({
  showModal,
  rememberMe,
  onToggleRememberMe,
  isEmailLogin,
}: Props): JSX.Element => {
  return (
    <div className={s.container}>
      {!isEmailLogin && (
        <>
          <input
            type="checkbox"
            className={s.checkbox}
            checked={rememberMe}
            onChange={ev => onToggleRememberMe()}
          />
          <button
            className={s.button + ' ' + s.rememberMe}
            onClick={onToggleRememberMe}
          >
            Remember Me
          </button>
        </>
      )}
      <button
        className={s.button + ' ' + s.forgotPassword}
        onClick={() => {
          showModal(true)
        }}
      >
        Forgot Password?
      </button>
    </div>
  )
}
