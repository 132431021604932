import React from 'react'
import { Checkbox, Button } from '../../../../../../../components/atoms'
import { handleCheckboxProfile } from '../../../handleProfileInputs'
import './certificateGroupsModal.scss'
import { ModalBox } from '../../../../../../../components/molecules/General'
import { ConfirmationUpdateModal } from '../../../../../../../components/molecules/Worker'
import {
  ICertificationGroup,
  SkillsInfoOnlyCertifications,
} from '../../../../../../../models'

interface Props extends React.HTMLAttributes<HTMLElement> {
  handleSubmit: () => void
  isConfirmationModalVisible: boolean
  setIsConfirmationModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  choiceLists: ICertificationGroup[] | null
  certInfoForm: SkillsInfoOnlyCertifications
  setCertInfoForm: React.Dispatch<
    React.SetStateAction<SkillsInfoOnlyCertifications>
  >
  certInfoState: SkillsInfoOnlyCertifications
}

export const CertificateGroupsModal = (props: Props) => {
  const {
    handleSubmit,
    isConfirmationModalVisible,
    setIsConfirmationModalVisible,
    setIsModalVisible,
    className,
    choiceLists,
    certInfoForm,
    setCertInfoForm,
    certInfoState,
  } = props

  return (
    <div
      className={`certificate-groups-modal ${className ?? ''}`}
      data-testid="certificate-groups-modal"
    >
      {isConfirmationModalVisible && (
        <ModalBox className="upload-file__modal-box">
          <ConfirmationUpdateModal
            title="Discard Changes"
            uploadText="Save Changes"
            removeText="Discard Changes"
            updateData={() => {
              handleSubmit()
              setIsConfirmationModalVisible(false)
            }}
            remove={() => {
              setIsConfirmationModalVisible(false)
              setIsModalVisible(false)
              setCertInfoForm(certInfoState)
            }}
          />
        </ModalBox>
      )}
      <p className="certificate-groups-modal__title">
        Edit Training/Certifications
      </p>

      {choiceLists?.map(group => (
        <React.Fragment key={group.group}>
          <p className="certificate-groups-modal__form-title">{group.group}</p>
          <div className="certificate-groups-modal__form-btn-grid">
            {group.certifications?.map((item, index) => {
              const checked = certInfoForm.training_certifications.find(
                el => el === item
              )
                ? true
                : undefined
              return (
                <Checkbox
                  className="certificate-groups-modal__form-answer-checkbox"
                  buttonsGrid
                  key={item}
                  name={item}
                  idCheckbox={index}
                  checked={checked}
                  toggleCheckbox={(_, isChecked: boolean) =>
                    handleCheckboxProfile(
                      isChecked,
                      setCertInfoForm,
                      item,
                      'training_certifications'
                    )
                  }
                />
              )
            })}
          </div>
        </React.Fragment>
      ))}
      <hr />
      <div className="certificate-groups-modal__button-wrapper">
        <Button
          className="certificate-groups-modal__button-saved"
          type="submit"
          onClick={() => handleSubmit()}
        >
          SAVE CHANGES
        </Button>
      </div>
    </div>
  )
}
