import {
  InterviewerModel,
  Onboarding,
  SpecificJob,
} from '../../../../../models'
import { scheduleCurrentType } from '../../../../../utils/constants'
import { ModalBox, SuccessPopup } from '../../../../molecules'
import moment from 'moment'
import 'moment-timezone'
import './bookedModal.scss'

interface Props {
  date: Date
  type: Onboarding | SpecificJob | null
  isModalVisible: boolean
  onCloseModal: () => void
  interviewer: InterviewerModel | null
}

export const BookedModal = (props: Props) => {
  const { isModalVisible, type, onCloseModal, date, interviewer } = props
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const timeZoneAbr = moment.tz(userTimeZone).zoneAbbr()

  return (
    <>
      {isModalVisible && (
        <ModalBox
          className="booked-modal"
          onCloseModal={onCloseModal}
          visibleCloseIcon={true}
        >
          <SuccessPopup
            date={`${moment(date).format(
              'dddd, MMMM Do [at] h:mm a'
            )} ${timeZoneAbr}`}
            subDescription={`${
              type === scheduleCurrentType.onboarding
                ? 'You will receive a calendar invite with full details shortly in your email.'
                : 'You will receive a calendar invite with full details shortly.'
            }`}
            newPhoto={type === scheduleCurrentType.onboarding}
            interviewer={interviewer}
          />
        </ModalBox>
      )}
    </>
  )
}
