import React, { useCallback, useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as route from '../../../services/route'
import { TypeResume } from '../../../components/organism'
import { uploadResumeWithSovren } from '../../../services/workerApi'
import { OBUploadResumeUI } from './OBUploadResumeUI'
import { IWorkerInfoOB, TUploadResume } from '../../../models'
import { setWorkerInfo, useAppDispatch } from '../../../redux'
import { useTypedSelector, usePageNumUpdateOB } from '../../../utils/hooks'
import {
  EWorkerSStoreKeys,
  OBAnalyticsName,
  WorkerQueries,
} from '../../../utils/constants'
import {
  getUserInfoFromStorage,
  onTrackingActions,
  uploadFileHandler,
} from '../../../utils/scripts'
import { getLastPageState } from '../../../utils/hooks/obHooks/usePageNumUpdateOB'

export const OBUploadResume = () => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const userInfoFromStorage = useMemo(() => getUserInfoFromStorage(), [])
  const userInfo = useTypedSelector(s => s.obInfo.workerInfo)
  const lastPage = userInfo.last_page < 8 ? 8 : userInfo.last_page
  const lastPageState = useMemo(() => getLastPageState(lastPage), [lastPage])

  const pageStateOBMutation = usePageNumUpdateOB()
  const formData = new FormData()

  const customJobsHandler = useCallback(async () => {
    await onTrackingActions(OBAnalyticsName.resume_skipped)
    const getNewData = (data: IWorkerInfoOB): IWorkerInfoOB => ({
      ...data,
      last_page: lastPage,
      last_location: lastPageState.last_location,
    })
    dispatch(setWorkerInfo(getNewData(userInfo)))
    queryClient.setQueryData(WorkerQueries.workerInfoOB, getNewData(userInfo))
    sessionStorage.setItem(
      EWorkerSStoreKeys.obData,
      JSON.stringify(getNewData(userInfoFromStorage ?? userInfo))
    )
    history.push(route.OBPath.onboardingLastJobs)
  }, [
    dispatch,
    lastPage,
    lastPageState.last_location,
    userInfo,
    userInfoFromStorage,
    queryClient,
    history,
  ])

  const uploadResumeMutation = useMutation(
    (data: FormData) => uploadResumeWithSovren(data),
    {
      onSuccess: (data: TUploadResume) => {
        onTrackingActions(OBAnalyticsName.resume_parsed)
        const getNewData = (userInfoData: IWorkerInfoOB) => ({
          ...userInfoData,
          last_page: lastPage,
          last_location: lastPageState.last_location,
          user_data: {
            ...userInfoData.user_data,
            work_experience: data.work_experience,
            incomplete_work_experience: data.incomplete_work_experience,
            custom_jobs: true,
          },
        })
        queryClient.setQueryData(
          WorkerQueries.workerInfoOB,
          getNewData(userInfo)
        )
        dispatch(setWorkerInfo(getNewData(userInfo)))
        sessionStorage.setItem(
          EWorkerSStoreKeys.obData,
          JSON.stringify(getNewData(userInfoFromStorage ?? userInfo))
        )
        sessionStorage.setItem(
          EWorkerSStoreKeys.obIncompleteExperience,
          JSON.stringify(
            data.incomplete_work_experience.length ? 'true' : 'false'
          )
        )
        pageStateOBMutation.mutate(lastPageState, {
          onSuccess() {
            history.push(route.OBPath.onboardingLastJobs)
          },
        })
      },
      onError: err => {
        onTrackingActions(OBAnalyticsName.resume_failed_parsing)
        if (err instanceof Error) {
          toast.error(
            'The provided document was unable to be converted to text.'
          )
        }
      },
    }
  )

  const onClickNext = useCallback(() => {
    history.push(route.OBPath.onboardingLastJobs)
  }, [history])

  const onClickBack = useCallback(() => {
    history.push(route.OBPath.onboardingLocation)
  }, [history])

  const uploadResume = (e: React.ChangeEvent<HTMLInputElement>) => {
    uploadFileHandler(
      e,
      25000000,
      TypeResume,
      updatedFile => {
        formData.append('resume', updatedFile)
        uploadResumeMutation.mutate(formData)
      },
      onTrackingActions
    )
  }

  return (
    <OBUploadResumeUI
      uploadResume={uploadResume}
      customJobsHandler={customJobsHandler}
      isLoading={uploadResumeMutation.isLoading}
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      isNextBtnDisable={!userInfo.user_data.work_experience.length}
    />
  )
}
