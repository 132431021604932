export type Tarray = string[] | null | undefined

export const compareTwoArrayOfString = (
  arr1: Tarray,
  arr2: Tarray
): boolean => {
  if (!arr1 || !arr2) {
    return false
  }

  if (arr1.length !== arr2.length) {
    return false
  }

  return arr1.every(el1 => {
    return arr2.some(el2 => el1 === el2)
  })
}
