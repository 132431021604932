import React from 'react'
import { Button, Checkbox } from '../../../../../../../components/atoms'
import {
  CheckboxAndMultiselect,
  ConfirmationUpdateModal,
  ModalBox,
  RadioBtnElement,
  RadioButtonsList,
} from '../../../../../../../components/molecules'
import { SkillsInfo } from '../../../../../../../models'
import { IInitialState } from '../../../../../../../redux'
import {
  getSelectedYesNoAnswerId,
  handleCheckboxProfile,
  handleRadioBtnProfile,
} from '../../../handleProfileInputs'
import './basicSkillsModal.scss'
import { getSupportedLanguages } from '../../../../../../../utils/scripts'

interface Props extends React.HTMLAttributes<HTMLElement> {
  isConfirmationModalVisible: boolean
  skillsInfoForm: SkillsInfo
  setSkillsInfoForm: React.Dispatch<React.SetStateAction<SkillsInfo>>
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  handleSubmit: (newData: SkillsInfo) => void
  setIsConfirmationModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  skillsInfoState: SkillsInfo
  choiceLists: IInitialState
  eligibleWork: RadioBtnElement[]
  upskilling: RadioBtnElement[]
  usersSkillsDataWithId?: {
    id: number
    choice: string
  }[]
  listForPreferredLang: { id: number; value: string }[]
  isNotSupporedLang: boolean
  isDisabledSaveBtn: boolean
}

export const BasicSkillsModalUI = (props: Props) => {
  const {
    isConfirmationModalVisible,
    skillsInfoForm,
    setSkillsInfoForm,
    setIsModalVisible,
    handleSubmit,
    setIsConfirmationModalVisible,
    skillsInfoState,
    choiceLists,
    eligibleWork,
    upskilling,
    usersSkillsDataWithId,
    listForPreferredLang,
    isNotSupporedLang,
    isDisabledSaveBtn,
  } = props

  return (
    <div
      className={`basic-skills-modal ${props.className ?? ''}`}
      data-testid="basic-skills-modal"
    >
      {isConfirmationModalVisible && (
        <ModalBox className="upload-file__modal-box">
          <ConfirmationUpdateModal
            title="Discard Changes"
            uploadText="Save Changes"
            removeText="Discard Changes"
            updateData={e => {
              handleSubmit(skillsInfoForm)
              setIsConfirmationModalVisible(false)
            }}
            remove={() => {
              setIsConfirmationModalVisible(false)
              setIsModalVisible(false)
              setSkillsInfoForm(skillsInfoState)
            }}
          />
        </ModalBox>
      )}
      <p className="basic-skills-modal__title">Edit Skills & Qualifications</p>
      <form
        className="basic-skills-modal__form"
        method="put"
        onSubmit={e => {
          e.preventDefault()
          handleSubmit(skillsInfoForm)
        }}
        data-testid="basic-skills-modal__form"
      >
        <div className="basic-skills-modal__form-question">
          <p className="basic-skills-modal__form-question-title">
            Are you legally authorized to work in the U.S.?
          </p>
          <RadioButtonsList
            className="basic-skills-modal__form-answer-radio-btn"
            list={eligibleWork}
            selected={getSelectedYesNoAnswerId(
              eligibleWork,
              skillsInfoForm.eligible_work_us
            )}
            onChangeBtn={(id: number) =>
              handleRadioBtnProfile(
                id,
                eligibleWork,
                setSkillsInfoForm,
                'eligible_work_us',
                true
              )
            }
          />
        </div>
        <div className="basic-skills-modal__form-question">
          <p className="basic-skills-modal__form-question-title">
            Are you interested in Upskilling?
          </p>
          <RadioButtonsList
            className="basic-skills-modal__form-answer-radio-btn"
            list={upskilling}
            selected={getSelectedYesNoAnswerId(
              upskilling,
              skillsInfoForm.interested_in_upskilling
            )}
            onChangeBtn={(id: number) =>
              handleRadioBtnProfile(
                id,
                upskilling,
                setSkillsInfoForm,
                'interested_in_upskilling',
                true
              )
            }
          />

          <div className="basic-skills-modal__form-email-me-wrapper">
            <Checkbox
              className="basic-skills-modal__form-email-me-checkbox"
              id="email_me"
              name="Email me information and free opportunities to grow my skills and career."
              idCheckbox={1}
              checked={skillsInfoForm.email_me === true ? true : undefined}
              toggleCheckbox={(_, isChecked: boolean) => {
                setSkillsInfoForm(
                  prev => prev && { ...prev, email_me: !isChecked }
                )
              }}
            />
          </div>
        </div>

        <p className="basic-skills-modal__form-question-title">Skills</p>
        {usersSkillsDataWithId &&
          usersSkillsDataWithId.map((skillChoice, index) => {
            const checked = skillsInfoForm.skills.find(
              el => el.choice === skillChoice.choice
            )
              ? true
              : undefined
            return (
              <CheckboxAndMultiselect
                key={skillChoice.choice}
                className={
                  index === usersSkillsDataWithId.length - 1
                    ? 'checkbox-multiselect__last-elem'
                    : ''
                }
                skillsAndLevelInfoFrom={skillsInfoForm.skills}
                item={skillChoice}
                checked={checked}
                setSkillsInfoForm={setSkillsInfoForm}
              />
            )
          })}

        <p className="basic-skills-modal__form-question-title">Unions</p>
        <div className="basic-skills-modal__form-btn-grid">
          {choiceLists.choiceListForWorker.unions?.map((item, index) => {
            const checked = skillsInfoForm.unions.find(el => el === item)
              ? true
              : undefined
            return (
              <Checkbox
                className="basic-skills-modal__form-answer-checkbox"
                buttonsGrid
                key={item}
                name={item}
                idCheckbox={index}
                checked={checked}
                toggleCheckbox={(_, isChecked: boolean) =>
                  handleCheckboxProfile(
                    isChecked,
                    setSkillsInfoForm,
                    item,
                    'unions'
                  )
                }
              />
            )
          })}
        </div>

        <p className="basic-skills-modal__form-question-title">
          Language fluency
        </p>
        <div className="basic-skills-modal__form-btn-grid">
          {choiceLists.choiceListForWorker.languages?.map((item, index) => {
            const checked = skillsInfoForm.language?.find(
              el => el === item.choice
            )
              ? true
              : undefined
            return (
              <Checkbox
                className="basic-skills-modal__form-answer-checkbox"
                buttonsGrid
                key={item.choice}
                name={item.choice}
                idCheckbox={index}
                checked={checked}
                toggleCheckbox={(_, isChecked: boolean) =>
                  handleCheckboxProfile(
                    isChecked,
                    setSkillsInfoForm,
                    item.choice,
                    'language'
                  )
                }
              />
            )
          })}
        </div>
        {skillsInfoForm.language && skillsInfoForm.language.length > 1 && (
          <>
            <p className="basic-skills-modal__form-question-title">
              Which is your preferred language?
            </p>
            <div className="basic-skills-modal__form-btn-grid">
              <RadioButtonsList
                className="basic-skills-modal__form-answer-rbtn-grid"
                list={listForPreferredLang}
                selected={
                  listForPreferredLang.find(
                    el => el.value === skillsInfoForm.preferred_language
                  )?.id
                }
                onChangeBtn={(id: number) =>
                  handleRadioBtnProfile(
                    id,
                    listForPreferredLang,
                    setSkillsInfoForm,
                    'preferred_language'
                  )
                }
                singleButtonsGrid
              />
            </div>
            {skillsInfoForm.preferred_language && isNotSupporedLang && (
              <p className="basic-skills-modal__form-question-title">
                {`Please note: we currently only support ${getSupportedLanguages(
                  choiceLists.choiceListForWorker.languages ?? []
                )} for worker support.`}
              </p>
            )}
          </>
        )}
        <hr />
        <div className="basic-skills-modal__btn-wrapper">
          <Button
            type="submit"
            className="basic-skills-modal__button-saved"
            disabled={isDisabledSaveBtn}
          >
            SAVE CHANGES
          </Button>
        </div>
      </form>
    </div>
  )
}
