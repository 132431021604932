import { RefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { NavMenu } from '../../../../components/atoms'
import { Search } from '../../../../components/molecules'
import { TopFilterBar } from '../../../../components/organism'
import { EmployerInfo } from '../../../../models'
import { NavbarList } from './NavbarList'
import './navigator.scss'

interface Props {
  isEmployer: boolean
  isAdminWorkers: boolean
  isNotWorker: boolean
  isAuth: boolean
  defaultFilterSettings: () => void
  onClickLogout: () => Promise<void>
  isMobile: boolean
  memorizedToggleMenu: () => void
  modalRef: RefObject<HTMLDivElement>
  filterBarRef: RefObject<HTMLDivElement>
  onboarding?: true
  employerInfo?: EmployerInfo
  addExtraClass: () => string
  landingPage?: true
}

export const NavigatorUI = (props: Props) => {
  const {
    isEmployer,
    isAdminWorkers,
    isNotWorker,
    isAuth,
    defaultFilterSettings,
    onboarding,
    onClickLogout,
    isMobile,
    memorizedToggleMenu,
    employerInfo,
    modalRef,
    filterBarRef,
    addExtraClass,
    landingPage,
  } = props
  const { t } = useTranslation()

  if (landingPage)
    return (
      <div>
        <nav className="navbar">
          <div className="header-container">
            <a
              className="header-container__link-wrapper"
              href="https://skillit.com/"
            >
              <div className="header-title"></div>
            </a>
          </div>
        </nav>
      </div>
    )

  return (
    <div>
      <nav
        className={`navbar ${isNotWorker ? 'navbar__employer-container' : ''}`}
      >
        <div
          className={`header-container ${
            isNotWorker
              ? 'header-container_without-padding employer-container'
              : ''
          }`}
          data-testid="header-container"
        >
          {isAuth ? (
            <Link
              onClick={defaultFilterSettings}
              className={`header-title ${addExtraClass()}`}
              to="/"
            ></Link>
          ) : (
            <>
              <a
                className="header-container__link-wrapper"
                href="https://skillit.com/"
              >
                <div className="header-title"></div>
              </a>

              {!onboarding && (
                <div className="header-container__sign-up">
                  Don’t have an account? You can{' '}
                  <span
                    onClick={() => {
                      window.location.assign(
                        'https://skillit.com/worker-or-employer/'
                      )
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        window.location.assign(
                          'https://skillit.com/worker-or-employer/'
                        )
                      }
                    }}
                    tabIndex={0}
                    className="header-container__sign-up--link"
                  >
                    sign up here
                  </span>
                </div>
              )}
            </>
          )}

          {onboarding && (
            <p
              className="navbar__sign-out"
              onClick={onClickLogout}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  onClickLogout()
                }
              }}
            >
              {isAuth
                ? `${t('Shared_Header.Sign out')}`
                : `${t('Shared_Header.Sign in')}`}
            </p>
          )}

          {isAuth && isMobile && !onboarding && (
            <div
              className="hamburger"
              onClick={memorizedToggleMenu}
              data-testid="hamburger"
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  memorizedToggleMenu()
                }
              }}
            >
              <span />
              <span />
              <span />
            </div>
          )}

          {isAuth &&
            !isMobile &&
            !onboarding &&
            (isNotWorker ? (
              <div
                className={
                  isAdminWorkers && !isEmployer
                    ? 'navbar__wrapper-admin'
                    : 'navbar__wrapper'
                }
                data-testid="navbar__wrapper-admin"
              >
                {isEmployer && <Search />}

                <NavbarList
                  className="navbar__header-list"
                  isEmployer={isEmployer}
                  isAdminWorkers={isAdminWorkers}
                  employerInfo={employerInfo}
                  onClickLogout={onClickLogout}
                />
              </div>
            ) : (
              <p
                className="navbar__sign-out"
                onClick={onClickLogout}
                data-testid="navbar__sign-out"
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    onClickLogout()
                  }
                }}
              >
                Sign out
              </p>
            ))}
        </div>

        {isAuth && (
          <div ref={modalRef} className="modal">
            <div className="navMenuWrapper">
              <NavMenu
                onClickLogout={onClickLogout}
                onClickItem={memorizedToggleMenu}
              />
            </div>
          </div>
        )}

        {isAuth && isEmployer && !isMobile && (
          <TopFilterBar ref={filterBarRef} />
        )}
      </nav>
    </div>
  )
}
