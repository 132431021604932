import { useState } from 'react'
import { LoadingScreen } from '../../../../pages'
import { setIsBetaEnabled, useAppDispatch } from '../../../../redux'
import closeIcon from '../../../../assets/icons/close/close-search.svg'
import './betaToggle.scss'
import Cookies from 'js-cookie'
import { useTypedSelector } from '../../../../utils/hooks'

interface Props {
  setIsBetaToggle: React.Dispatch<React.SetStateAction<boolean>>
}

export const BetaToggle = (props: Props) => {
  const { setIsBetaToggle } = props
  const dispatch = useAppDispatch()
  const [isTransition, setIsTransition] = useState(false)

  const isBetaEnabled = useTypedSelector(state => state.auth.isBetaEnabled)

  const closeBetaToggle = () => {
    dispatch(setIsBetaEnabled(false))
    setIsBetaToggle(false)
  }

  const clickToggle = (toggleStatus: boolean) => {
    setTimeout(() => {
      const currentDomain = window.location.origin
      Cookies.remove('legacyVersion')
      const newUrl = `${currentDomain}/beta${window.location.pathname}`
      window.location.assign(newUrl)
      setIsTransition(true)
    }, 100)
  }

  return (
    <div className="beta-toggle">
      {isTransition && <LoadingScreen />}
      <div className="beta-toggle__container">
        <div className="beta-toggle__wrapper">
          <div className="beta-toggle__content">
            {!isBetaEnabled ? (
              <p className="beta-toggle__title">
                Thanks for all your feedback on our enhanced worker search.
                We're putting on the finishing touches and will roll out the new
                version soon!
              </p>
            ) : (
              <>
                <p className="beta-toggle__title">
                  You are using Skillit’s old worker search.
                </p>
                <p
                  className="beta-toggle__title--link"
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      closeBetaToggle()
                    }
                  }}
                  onClick={() => clickToggle(true)}
                >
                  Click here to switch to the new and improved experience.
                </p>
              </>
            )}
          </div>
          <img
            className="beta-toggle__close-icon"
            src={closeIcon}
            alt="close icon"
            onClick={() => {
              closeBetaToggle()
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                closeBetaToggle()
              }
            }}
            tabIndex={0}
          />
        </div>
      </div>
    </div>
  )
}
