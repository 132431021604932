import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import './passwordReset.scss'
import { PasswordResetForm } from '../../../../components/organism'
import { useParams } from 'react-router-dom'
import { resetPassword } from '../../../../services/auth-service'
import { useHistory } from 'react-router-dom'
import { logout, syncUser, useAppDispatch } from '../../../../redux'
import { LocalStorage } from '../../../../services/localStorage'
import { TokenHolder } from '../../../../services/TokenHolder'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useTypedSelector } from '../../../../utils/hooks'

export const CURRENT_TOKEN = 'currentToken'

export const PasswordReset = () => {
  const params: { uid: string; token: string; ref: string | undefined } =
    useParams()
  const isAuth = useTypedSelector(s => s.auth.isAuth)
  const token = useMemo(() => TokenHolder.get(), [])
  const [isLogouted, setIsLogouted] = useState<boolean>(!isAuth)
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')
  const [errMessage, setErrMessage] = useState<string>()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const urlReference = decodeURIComponent(params.ref ?? '').split('=')[1]

  const password1Change = (event: ChangeEvent<HTMLInputElement>): void => {
    setPassword1(event.target.value)
  }

  const password2Change = (event: ChangeEvent<HTMLInputElement>): void => {
    setPassword2(event.target.value)
  }

  const useResetPassMutation = useMutation(
    (data: { token: string; uid: string; password: string }) =>
      resetPassword(data.token, data.uid, data.password),
    {
      onError(err) {
        if (err instanceof Error) toast.error(err.message)
      },
    }
  )

  const resetRequest = useCallback(() => {
    if (password1 !== password2) {
      return setErrMessage('Passwords are not equal')
    }
    useResetPassMutation.mutate(
      {
        token: params.token,
        uid: params.uid,
        password: password1,
      },
      {
        onSuccess(data) {
          TokenHolder.set(data.token, false)
          LocalStorage.setItemForKey(true, 'wasRegistred')
          dispatch(syncUser()).then(_ => {
            setTimeout(() => localStorage.removeItem(CURRENT_TOKEN), 5000)
            history.push(urlReference ? `/${urlReference}` : '/')
          })
        },
      }
    )
  }, [
    dispatch,
    history,
    params,
    password1,
    password2,
    urlReference,
    useResetPassMutation,
  ])

  const logoutHandler = useCallback(() => {
    const memorizedToken = localStorage.getItem(CURRENT_TOKEN)
    setIsLogouted(true)
    dispatch(
      logout(() => {
        memorizedToken && localStorage.setItem(CURRENT_TOKEN, memorizedToken)
      })
    )
  }, [dispatch])

  useEffect(() => {
    const tokenFromStorage: string | null = localStorage.getItem(CURRENT_TOKEN)
    if (
      !isLogouted &&
      token &&
      (tokenFromStorage === token || !tokenFromStorage)
    ) {
      !tokenFromStorage && localStorage.setItem(CURRENT_TOKEN, token)
      if (localStorage.getItem(CURRENT_TOKEN) === token) {
        logoutHandler()
      }
    }
  }, [dispatch, isLogouted, token, logoutHandler])

  return (
    <PasswordResetForm
      formSubmit={resetRequest}
      password1Change={password1Change}
      password2Change={password2Change}
      errorMessage={errMessage}
    />
  )
}
