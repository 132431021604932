import React from 'react'
import { IUploadResumeOfInvitedWorker } from '../../../../../models/AdminWorkes'
import { TUploadResume } from '../../../General'
import { AddResumeModalIU } from './AddResumeModalUI'

export interface ISetStateAction {
  setIsInviteOpen: React.Dispatch<React.SetStateAction<boolean>>
  setIsResumeModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setNewWorker: React.Dispatch<
    React.SetStateAction<IUploadResumeOfInvitedWorker | null>
  >
  setUploadedResume: React.Dispatch<React.SetStateAction<File | null>>
  setFile: React.Dispatch<React.SetStateAction<File | null>>
  setClassDragEnter: React.Dispatch<React.SetStateAction<boolean>>
}

interface Props extends ISetStateAction {
  classDragEnter: boolean
  file: File | null
  uploadResumeRef: React.ForwardedRef<TUploadResume>
}

export const AddResumeModal = (props: Props) => {
  return <AddResumeModalIU {...props} />
}
