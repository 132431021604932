import React, { useContext } from 'react'
import logo from '../../../../assets/icons/logo/skillit-icon.png'
import zipcodes from 'zipcodes'
import { CompanyInterviewBlock, SkillsAssessmentCard } from '../../../molecules'
import { ModalData } from '../../../../pages/InAppFlow'
import './interviewScoresBlock.scss'
import { EmployeeInfoContext } from '../../../Context/EmployeeInfoContext'
import { CategoriesScores } from '../../../../models/Employer'
import { UsaStates } from 'usa-states'

interface Props {
  scoreList: CategoriesScores
  modalAction: (activeModal: ModalData) => void
}

export const InterviewScoresBlock = (props: Props) => {
  const workerContext = useContext(EmployeeInfoContext)
  const worker = workerContext.workerData
  // const companyInterview = worker?.interview_scores.company_interview
  const skillitInterview = worker?.interview_scores.skillit_interview.details
  // const userData = worker?.user_card
  const { scoreList } = props
  const usStates = new UsaStates()

  const stateName =
    skillitInterview &&
    usStates.states.filter(state => {
      if (skillitInterview.rating?.zip_code) {
        return (
          state.abbreviation ===
          zipcodes.lookup(skillitInterview.rating?.zip_code)?.state
        )
      }

      if (
        skillitInterview.rating?.state &&
        skillitInterview.rating.state.length > 3
      ) {
        return (
          state.name.toLowerCase() ===
          skillitInterview.rating?.state.toLowerCase()
        )
      }

      return state.abbreviation === skillitInterview.rating?.state
    })

  // const [date, setDate] = useState(new Date())  // TODO Temporarily disabled
  // const calendarPopupHandler = useCallback(() => date && false, [date])

  return (
    <div className="interview-scores">
      {/* <div className="interview-scores__header">
        {!companyInterview?.available && !companyInterview?.pending && (
          <Button
            onClick={() => {
              userData &&
                modalAction({
                  user: userData,
                  modalName: EmpActionType.interview,
                })
            }}
          >
            Schedule Interview
          </Button>
        )}
        <MultipurposeSelect // TODO Temporarily disabled
          btnText="Select intended start date"
          popup={
            <Calendar
              className="employer-interview__calendar schedule__calendar"
              minDate={new Date()}
              showNeighboringMonth={false}
              calendarType="US"
              formatShortWeekday={(locale, dateCalendar) =>
                moment(dateCalendar).format('dd')
              }
              minDetail="year"
              nextLabel={<div className="schedule__calendar-arrow-next"></div>}
              prevLabel={<div className="schedule__calendar-arrow-prev"></div>}
              next2Label={null}
              prev2Label={null}
              onChange={(value: Date, event: ChangeEvent<HTMLInputElement>) =>
                event.target.tagName && setDate(value)
              }
              value={date}
            />
          }
          isPopupShouldBeShowed={calendarPopupHandler}
        />
      </div> */}
      <div className="interview-scores__main">
        <SkillsAssessmentCard
          score={skillitInterview?.overall_score ?? undefined}
          titleName="Skillit Interview"
          logo={logo}
          betterThan={skillitInterview?.rating?.percentage}
          peopleQuantity={skillitInterview?.rating?.number}
          place={stateName?.[0]?.name ?? '--'}
          link="/"
          scoreList={scoreList}
          isInterviewCard
        />
        <CompanyInterviewBlock />
      </div>
    </div>
  )
}
