import React from 'react'
import './profileInfoBlock.scss'
import arrow from '../../../../../assets/icons/checkmarks/yellow-double-checkmark.svg'
import { Button } from '../../../../atoms'
import { Link } from 'react-router-dom'

interface Props extends React.HTMLAttributes<HTMLElement> {
  title: string
  description: string
  progresCircle: JSX.Element
  route: string
}

export const ProfileInfoBlock = (props: Props) => {
  const { className, title, description, progresCircle, route } = props

  return (
    <div
      className={`profile-info-block ${className ?? ''}`}
      data-testid="profile-info-block"
    >
      <div className="profile-info-block__wrapper">
        <div>
          <h3 className="profile-info-block__title">{title}</h3>
          <p className="profile-info-block__description">{description}</p>
          <div className="profile-info-block__required">
            <img src={arrow} alt="double arrow" />
            <p className="profile-info-block__required-text">
              Required for Offers
            </p>
            <Link className="profile-info-block__edit-link" to={route}>
              <Button className="profile_white-btn">edit</Button>
            </Link>
          </div>
        </div>
        {progresCircle}
      </div>
    </div>
  )
}
