import React, { RefObject, useMemo } from 'react'
import {
  AutocompleteInput,
  Button,
  Checkbox,
  ECheckboxTheme,
  OBInput,
} from '../../../../../atoms'
import { Textarea } from '../../../../../atoms/textareas'
import './addJobForm.scss'
import { TWorkExperience } from '../../../../../../models'
import { EJobFields } from '../../../../../../pages/Onboarding/OBLastJobs/OBLastJobs'
import { useTranslation } from 'react-i18next'
import { TFieldsErr, TIsFormOpen, TIsFormOpenToAdd } from './AddJobForm'
import { extraSpacesFormatting } from '../../../../../../utils/scripts'
import { ConfirmationUpdateModal, ModalBox } from '../../../../../molecules'

interface IAddJobForm extends React.HTMLAttributes<HTMLFormElement> {
  highlightFields?: EJobFields[]
  inputBlockRef: RefObject<HTMLDivElement>
  suggestions: string[]
  onClickSuggestion: (value: string, key: EJobFields) => void
  startYear?: string
  isCurrentDate: boolean
  toggleCheckbox: (id: number, isChecked: boolean) => void
  months?: string
  inputHandler: (e: string, key: EJobFields) => void
  textareaHandler: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onChangeMonth: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChangeStartDate: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSubmitHandler: (e: React.FormEvent) => void
  isSubmitBtnActive: boolean
  formData: TWorkExperience
  fieldsErr: TFieldsErr
  isPromptOpen: boolean
  setIsPromptOpen: React.Dispatch<React.SetStateAction<boolean>>
  setIsFormOpen:
    | React.Dispatch<React.SetStateAction<TIsFormOpenToAdd>>
    | React.Dispatch<React.SetStateAction<TIsFormOpen>>
  setIsClickCloseBtn: React.Dispatch<React.SetStateAction<boolean>>
  setIsSaveClicked: React.Dispatch<React.SetStateAction<boolean>>
  companyNameRef: React.RefObject<HTMLInputElement>
  roleNameRef: React.RefObject<HTMLInputElement>
  locationRef: React.RefObject<HTMLInputElement>
  startDateRef: React.RefObject<HTMLInputElement>
  monthRef: React.RefObject<HTMLInputElement>
}

export const AddJobFormUI = (props: IAddJobForm) => {
  const {
    highlightFields,
    inputBlockRef,
    suggestions,
    onClickSuggestion,
    startYear,
    onChangeMonth,
    isCurrentDate,
    toggleCheckbox,
    months,
    inputHandler,
    textareaHandler,
    onSubmitHandler,
    isSubmitBtnActive,
    onChangeStartDate,
    formData,
    fieldsErr,
    isPromptOpen,
    setIsPromptOpen,
    setIsFormOpen,
    setIsClickCloseBtn,
    setIsSaveClicked,
    companyNameRef,
    roleNameRef,
    locationRef,
    startDateRef,
    monthRef,
  } = props
  const { t } = useTranslation()

  const checkboxStyle = useMemo(() => {
    if (fieldsErr.months) return '20px'
    if (fieldsErr.isFutureDate) return '30px'
    return '0px'
  }, [fieldsErr.isFutureDate, fieldsErr.months])

  //TODO add translate to Spanish
  const monthsErrText = useMemo(() => {
    if (fieldsErr.isFutureDate)
      return 'Job can not end in the future, please choose a smaller number of months or check “I currently work here”'
    if (fieldsErr.months)
      return 'You should enter a number of months or activate the checkbox first'
  }, [fieldsErr.isFutureDate, fieldsErr.months])

  return (
    <div className="job-form-ob__wrapper">
      {isPromptOpen && (
        <ModalBox className="upload-file__modal-box">
          <ConfirmationUpdateModal
            title="Discard Changes"
            uploadText="Save Changes"
            removeText="Discard Changes"
            updateData={e => {
              setIsSaveClicked(true)
              onSubmitHandler(e)
            }}
            remove={() => {
              setIsPromptOpen(false)
              setIsFormOpen(false)
              setIsClickCloseBtn(false)
            }}
          />
        </ModalBox>
      )}
      <form
        className="job-form-ob"
        onSubmit={onSubmitHandler}
        data-testid="job-form-ob"
      >
        <div className="job-form-ob__form-wrapper">
          <div className="job-form-ob__input-block" ref={inputBlockRef}>
            <AutocompleteInput
              className={`job-form-ob__input-company ${
                highlightFields?.includes(EJobFields.company_name) &&
                'job-form-ob_highlight'
              }`}
              value={formData?.company_name ?? undefined}
              placeholder={t('last_jobs.Company Name').toString()}
              onChange={e =>
                inputHandler(
                  extraSpacesFormatting(e.target.value),
                  EJobFields.company_name
                )
              }
              suggestions={suggestions}
              onClickSuggestion={value =>
                onClickSuggestion(value, EJobFields.company_name)
              }
              texterror={
                fieldsErr.company_name
                  ? `${t('last_jobs.Please, fill in this field')}`
                  : undefined
              }
              showFocus={true}
              externalRef={companyNameRef}
            />
            <OBInput
              className={`job-form-ob__input-role ${
                highlightFields?.includes(EJobFields.job_title) &&
                'job-form-ob_highlight'
              }`}
              placeholder={t('last_jobs.Role / Title').toString()}
              value={formData?.job_title ?? undefined}
              onChange={e =>
                inputHandler(
                  extraSpacesFormatting(e.target.value),
                  EJobFields.job_title
                )
              }
              texterror={
                fieldsErr.job_title
                  ? `${t('last_jobs.Please, fill in this field')}`
                  : undefined
              }
              ref={roleNameRef}
            />
            <AutocompleteInput
              className={`job-form-ob__input-place ${
                highlightFields?.includes(EJobFields.location) &&
                'job-form-ob_highlight'
              }`}
              value={formData?.location ?? undefined}
              placeholder={t('last_jobs.Location').toString()}
              onChange={e =>
                inputHandler(
                  extraSpacesFormatting(e.target.value),
                  EJobFields.location
                )
              }
              places
              onClickSuggestion={value =>
                onClickSuggestion(value, EJobFields.location)
              }
              texterror={
                fieldsErr.location
                  ? `${t('last_jobs.Please, fill in this field')}`
                  : undefined
              }
              externalRef={locationRef}
            />
            <OBInput
              className={`job-form-ob__input-date ${
                highlightFields?.includes(EJobFields.start_date) &&
                'job-form-ob_highlight'
              }`}
              placeholder={t('last_jobs.What year did you start?').toString()}
              value={startYear}
              onChange={onChangeStartDate}
              texterror={
                fieldsErr.start_date
                  ? `${t(
                      'last_jobs.You should enter a start date or activate the checkbox first'
                    )}`
                  : undefined
              }
              ref={startDateRef}
            />
            <OBInput
              className={`job-form-ob__input-date ${
                highlightFields?.includes(EJobFields.end_date) &&
                'job-form-ob_highlight'
              }`}
              placeholder={t(
                'last_jobs.How long were you at this job (months)?'
              ).toString()}
              value={months ?? ''}
              onChange={onChangeMonth}
              texterror={monthsErrText}
              ref={monthRef}
            />
          </div>
          <Checkbox
            idCheckbox={0}
            style={{ marginTop: checkboxStyle }}
            name={t('last_jobs.I currently work here').toString()}
            checked={isCurrentDate || undefined}
            toggleCheckbox={toggleCheckbox}
            theme={ECheckboxTheme.orange}
          />
          <Textarea
            className={`job-form-ob__textarea ${
              highlightFields?.includes(EJobFields.description) &&
              'job-form-ob_highlight'
            }`}
            placeholder={t(
              'last_jobs.What did you do? The more specific the better'
            ).toString()}
            rows={6}
            value={formData?.description ?? undefined}
            onChange={textareaHandler}
          />
          <Button
            className="job-form-ob__btn-submit"
            type="submit"
            onClick={onSubmitHandler}
            disabled={!isSubmitBtnActive}
          >
            {t('last_jobs.ADD A JOB')}
          </Button>
        </div>
      </form>
    </div>
  )
}
