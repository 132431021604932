import { useCallback } from 'react'
import './footer.scss'
import * as route from '../../../../services/route'
import footerLogo from '../../../../assets/icons/logo/skillit-logo-rubber_no-symbol.png'
import socIcons from '../../../../assets/icons/social/social_icons-white.svg'
import { Link } from 'react-router-dom'
import { defaultFilter, setFilters, useAppDispatch } from '../../../../redux'

interface Props {
  isEmployer?: true
}

export const Footer = (props: Props) => {
  const { isEmployer } = props
  const dispatch = useAppDispatch()
  const currentYear = new Date().getFullYear()

  const dataFooter = useCallback(() => {
    return isEmployer
      ? [
          { name: 'Dashboard', pathName: route.dashboard },
          { name: 'Contact Us', pathName: 'https://www.skillit.com/contact' },
        ]
      : [
          { name: 'Dashboard', pathName: route.dashboard },
          { name: 'About', pathName: 'https://www.skillit.com/about' },
          { name: 'Profile', pathName: route.profile },
          { name: 'Support', pathName: 'https://www.skillit.com/contact' },
          { name: 'Training', pathName: route.training },
        ]
  }, [isEmployer])

  return (
    <div
      className={`footer ${
        isEmployer && 'employer-container footer_change-max-width'
      }`}
    >
      <div className="footer__link-wrapper">
        <ul
          className={`footer__list ${
            isEmployer ? 'footer__list_employer' : ''
          }`}
        >
          {dataFooter().map(link =>
            link.name === 'Support' ||
            link.name === 'Contact Us' ||
            link.name === 'About' ? (
              <li key={link.pathName} className="footer__link">
                <a href={link.pathName} target="_blank" rel="noreferrer">
                  {link.name}
                </a>
              </li>
            ) : (
              <li key={link.pathName} className="footer__link">
                <Link to={link.pathName}>{link.name}</Link>
              </li>
            )
          )}
        </ul>
        <div className="footer__social">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/Skillitusa"
            className="footer__social-link"
          >
            <img
              src={socIcons}
              alt="facebook"
              className="footer__social_facebook"
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/SkillitUSA"
            className="footer__social-link"
          >
            <img src={socIcons} alt="twitter" />
          </a>
          {/* TODO show it after getting instagram link
            <a
              target="_blank"
              rel="noreferrer"
              href="https://"
              className="footer__social-link"
            >
              <img
                src={socIcons}
                alt="instagram"
                className="footer__social_instagram"
              />
            </a>
           */}
        </div>
      </div>
      <div className="footer__logo-container">
        <Link
          onClick={() => isEmployer && dispatch(setFilters(defaultFilter))}
          to="/"
        >
          <img
            className="footer__logo-img"
            src={footerLogo}
            alt="footer-logo"
          />
        </Link>
        <div className="footer__privacy-block">
          <p className="footer__skillit-text">© {currentYear} Skillit Inc.</p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.skillit.com/terms"
          >
            Terms
          </a>
          <span>|</span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.skillit.com/privacy"
          >
            Privacy
          </a>
        </div>
      </div>
    </div>
  )
}
