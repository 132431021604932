import { RefObject } from 'react'
import { IWorkerSkill } from '../../../../models'
import './skillsChart.scss'

interface IProps {
  titleWithYear: string
  maxLevel: number
  sortedSkills: IWorkerSkill[]
  axisLabels: string[]
  axisLabelRef: RefObject<HTMLDivElement>
  axisColumnRef: RefObject<HTMLDivElement>
  isLongLabel: boolean
}

export const ExperienceChartUI = (props: IProps) => {
  const {
    titleWithYear,
    maxLevel,
    sortedSkills,
    axisLabels,
    axisLabelRef,
    axisColumnRef,
    isLongLabel,
  } = props

  return (
    <div
      className={`experience-chart ${
        !sortedSkills.length ? 'experience-chart_no-display' : ''
      }`}
    >
      <div className="worker-detail-section-header">
        <h4>{titleWithYear}</h4>
      </div>

      <div className="experience-chart__chart-container">
        <div className="experience-chart__chart-left-space"></div>

        <div className="experience-chart__chart">
          {sortedSkills.map(skill => (
            <div
              key={skill.value}
              className="experience-chart__chart-row"
              style={{ width: `${((skill.level ?? 0) / maxLevel) * 100}%` }}
              data-testid="experience-chart__chart-row"
            >
              <div className="experience-chart__chart-row-text">
                {skill.value}
              </div>
            </div>
          ))}

          <div
            ref={axisColumnRef}
            className="experience-chart__chart-axis-columns"
            data-testid="experience-chart__chart-axis-columns"
          >
            {axisLabels.map(label => (
              <div key={label}></div>
            ))}
          </div>

          <div
            ref={axisLabelRef}
            className={`experience-chart__chart-axis-labels ${
              isLongLabel ? 'experience-chart__chart-axis-labels_short' : ''
            }`}
            data-testid="experience-chart__chart-axis-labels"
          >
            {axisLabels.map(label => (
              <div key={label}>{label}</div>
            ))}
          </div>
        </div>

        <div className="experience-chart__chart-right-space"></div>
      </div>
    </div>
  )
}
