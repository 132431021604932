import { Config } from '../../components/organism/General/UploadFiles'
import {
  PublicInfo,
  BasicInfoModel,
  SkillsInfo,
  WorkPreferencesInfo,
  ProfileCompletionInfo,
  DrivingInfo,
  OshaInfo,
  AssessmentResult,
  AssessmentInfo,
  SkillsInfoOnlyExperience,
  SkillsInfoOnlyAdditionalInfo,
  SkillsInfoOnlylanguages,
  TravelDistance,
  SkillsInfoOnlyCertifications,
} from '../../models'
import { IHourlyPayInfoOB } from '../../pages/Onboarding/OBRates'
import { API, API_NO_TOKEN } from '../API'
import { BACKEND_URL } from '../config'
import { responseHandler } from '../responseHandlers'

export const getPublicInfo = async (): Promise<PublicInfo> => {
  return responseHandler(API.get(`${BACKEND_URL}api/profile/get-public-info/`))
}

export const updatePublicInfo = async (
  publicData: any,
  config?: Config
): Promise<PublicInfo> => {
  return responseHandler(
    API.put('api/profile/update-public-info/', publicData, config ?? undefined),
    true
  )
}

export const updatePublicFiles = async (
  publicData: FormData,
  config?: Config
): Promise<PublicInfo> => {
  return responseHandler(
    API.put(
      'api/profile/update-public-files/',
      publicData,
      config ?? undefined
    ),
    true
  )
}

export const getBasicInfo = async (): Promise<BasicInfoModel> => {
  return responseHandler(API.get('api/profile/get-basic-info/'))
}

export const updateBasicInfo = async (
  basicData: BasicInfoModel | TravelDistance
): Promise<BasicInfoModel> => {
  return responseHandler(API.put('api/profile/update-basic-info/', basicData))
}

export const getSkillsInfo = async (): Promise<SkillsInfo> => {
  return responseHandler(API.get('api/profile/get-skills-info/'))
}

export const updateSkillsInfo = async (
  skillsInfo:
    | SkillsInfo
    | SkillsInfoOnlyExperience
    | SkillsInfoOnlyAdditionalInfo
    | SkillsInfoOnlylanguages
    | SkillsInfoOnlyCertifications,
  queryParam?: string
): Promise<SkillsInfo> => {
  if (queryParam) {
    return responseHandler(
      API.put(`api/profile/update-skills-info/?${queryParam}`, skillsInfo)
    )
  }

  return responseHandler(API.put('api/profile/update-skills-info/', skillsInfo))
}

export const getWorkPreferencesInfo =
  async (): Promise<WorkPreferencesInfo> => {
    return responseHandler(
      API.get(`${BACKEND_URL}api/profile/get-work-preferences-info/`)
    )
  }

export const updateWorkPreferencesInfo = async (
  prefData: WorkPreferencesInfo | IHourlyPayInfoOB
): Promise<WorkPreferencesInfo> => {
  return responseHandler(
    API.put(`api/profile/update-work-preferences-info/`, prefData)
  )
}

export const getProfileCompletionInfo = async (
  currentTimeZone?: string
): Promise<ProfileCompletionInfo> => {
  const timeZone = currentTimeZone ? `?timezone=${currentTimeZone}` : ''
  return responseHandler(API.get(`api/profile/get-overall-info/${timeZone}`))
}

export const disableProfileNotification = async (): Promise<any> => {
  return responseHandler(
    API.get(`${BACKEND_URL}api/profile/disable-notification/`)
  )
}

export const getDrivingInfo = async (): Promise<DrivingInfo> => {
  return responseHandler(API.get('api/profile/get-driving-info/'))
}

export const updateDrivingInfo = async (
  dataDriving: FormData,
  config?: Config
): Promise<DrivingInfo> => {
  return responseHandler(
    API.put(
      'api/profile/update-driving-info/',
      dataDriving,
      config ?? undefined
    )
  )
}

export const getOshaInfo = async (): Promise<OshaInfo> => {
  return responseHandler(API.get('api/profile/get-osha-info/'))
}

export const updateOshaInfo = async (
  oshaInfo: FormData,
  config?: Config
): Promise<OshaInfo> => {
  return responseHandler(
    API.put('api/profile/update-osha-info/', oshaInfo, config ?? undefined)
  )
}

export const getAssessmentResults = async (): Promise<AssessmentResult[]> => {
  return responseHandler(API.get('api/profile/get-assessment-results/'))
}

export const getAssessmentForms = async (
  only_skillit: boolean,
  uuid: string,
  isAuth: boolean
): Promise<AssessmentInfo> => {
  const accessAPI = isAuth ? API : API_NO_TOKEN
  return responseHandler(
    accessAPI.get(
      `api/profile/assessment-forms/?only_skillit=${only_skillit}&uuid=${uuid}`
    )
  )
}
