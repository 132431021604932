import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AsyncThunkConfig } from '.'
import { LocalStorage } from '../services/localStorage'
import { syncUser } from './auth-reducer'

const initialState = {
  initialized: false,
  wasRegistered: false,
}

export const initializeApp: AsyncThunk<
  {
    registered: boolean
  },
  void,
  AsyncThunkConfig
> = createAsyncThunk('APP/INITIALIZE', async (_, thunkAPI) => {
  const registered = LocalStorage.isKeySetted('wasRegistred')
  await thunkAPI.dispatch(syncUser())
  return { registered: registered }
})

const appSlice = createSlice({
  name: 'APP',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(initializeApp.fulfilled, (state, action) => {
      state.initialized = true
      state.wasRegistered = action.payload.registered
    })
  },
})

export const appReducer = appSlice.reducer
export const appActions = appSlice.actions
