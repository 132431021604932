import { createSlice } from '@reduxjs/toolkit'
import { zipFromSearch } from '../components/organism'
import { DASHBOARD_PAGE, FilterType, WorkersFilter } from '../models'

export const defaultFilter: WorkersFilter = {
  type: {
    type: FilterType.all,
    properties: null,
  },
  page_number: 0,
  page_size: 9,
  most_recent_pay_rate_min: null,
  most_recent_pay_rate_max: null,
  desired_pay_rate_min: null,
  desired_pay_rate_max: null,
  experience_years: null,
  vehicle: null,
  valid_drivers_license: null,
  osha_status: null,
  interview_score: null,
  training_certifications: null,
  travel_distance_interval: null,
  work_experience: null,
  assessment_score: null,
  profile_picture_field: null,
  name: null,
  role: null,
  skills: null,
  location: null,
  relocate: null,
  unions: null,
  language: null,
  eligible_work_us: null,
}

const filtersFromStorage = JSON.parse(`${sessionStorage.getItem('filters')}`)
const pageFromStore = localStorage.getItem(DASHBOARD_PAGE)
const decodePage = pageFromStore ? JSON.parse(pageFromStore) : 0
const initFilters: WorkersFilter = filtersFromStorage ?? {
  ...defaultFilter,
  page_number: decodePage.page ?? 0,
}

export enum EFilterTypes {
  groupFilterType = 'group_filter',
  filterType = 'filter_type',
}

interface IInitialState {
  employerFilterType: EFilterTypes.groupFilterType
  filters: WorkersFilter
  detailsSearched: false
  isTopFilterVisible: false
  isNavSearchHover: boolean
  isBackBlur: false
  enteredZipcode: string | null
}

const initialState: IInitialState = {
  employerFilterType: EFilterTypes.groupFilterType,
  filters: initFilters,
  detailsSearched: false,
  isTopFilterVisible: false,
  isNavSearchHover: false,
  isBackBlur: false,
  enteredZipcode: sessionStorage.getItem(zipFromSearch),
}

export const employerSlice = createSlice({
  name: 'EMPLOYER',
  initialState,
  reducers: {
    setFilterType: (state, action) => {
      if (state.employerFilterType !== action.payload) {
        state.employerFilterType = action.payload
      }
    },
    setFilters: (state, action) => {
      let updatedFilters: WorkersFilter = action.payload
      if (
        updatedFilters.type.type === FilterType.search &&
        !updatedFilters.name &&
        !updatedFilters.role?.length &&
        !updatedFilters.location?.length &&
        !updatedFilters.skills?.length
      ) {
        updatedFilters = {
          ...updatedFilters,
          type: {
            type: FilterType.all,
            properties: null,
          },
        }
      }
      state.filters = updatedFilters
    },
    setDetailsSearched: (state, action) => {
      state.detailsSearched = action.payload
    },
    setIsTopFilterVisible: (state, action) => {
      state.isTopFilterVisible = action.payload
    },
    setIsNavSearchHover: (state, action) => {
      state.isNavSearchHover = action.payload
    },
    setIsBackBlur: (state, action) => {
      state.isBackBlur = action.payload
    },
    setEnteredZipCode: (state, action) => {
      state.enteredZipcode = action.payload
    },
  },
})

export const {
  setFilterType,
  setFilters,
  setDetailsSearched,
  setIsTopFilterVisible,
  setIsNavSearchHover,
  setIsBackBlur,
  setEnteredZipCode,
} = employerSlice.actions
export const employerReducer = employerSlice.reducer
