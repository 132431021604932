import React from 'react'
import './firstBlog.scss'
import messerImg from '../../../../../assets/img/other/Messer-form.png'

export const FirstBlog = () => {
  return (
    <div className="first-blog">
      <h3 className="first-blog__blog-title">
        How to ace your carpenter foreman interview
      </h3>
      <div className="first-blog__foreword">
        <p>
          Congratulations on landing an interview for a Carpenter Foreman
          position! 💪
        </p>
        <p>
          In most job interviews, you can expect the interviewer or hiring
          manager to cover your skills, strengths, and weaknesses, your
          understanding of both the construction industry and the role, why you
          want this particular job at this company and your career goals.
        </p>
        <p>
          The hiring process is competitive, but with simple preparation, you’ll
          stand out as a candidate. Below, we’ll cover everything you need to
          know to nail your next Carpenter Foreman interview.
        </p>
      </div>
      <h4 className="first-blog__block-title">Do your research</h4>
      <div className="first-blog__block-wrapper">
        <img src={messerImg} alt="Messer" className="first-blog__img" />
        <p>
          Start by carefully reading the job description that is posted for the
          role you’ve applied for. Most Carpenter Foreman roles are a balance of
          soft and technical skills. To succeed as a Carpenter Foreman, you’ll
          need soft skills like organization, communication, problem-solving,
          and time management, as well as technical skills like construction
          math, the ability to read blueprints and understand building layouts,
          and a knowledge of construction techniques, tools, equipment, and
          materials.
        </p>
        <p>
          For example, if you’re interviewing at Messer Construction Co, they
          value and look for the following skills profile:
        </p>
        <p>
          Next, spend some time researching the company and role. After you’ve
          read the job description and understand how your own skills are
          relevant to the role, examine the company mission, get to know the
          leadership team (you may even discover common ground), and read any
          recent company news. All of this will show that you are a prepared and
          knowledgeable candidate who cares about the construction industry in
          the larger context of your own role.
        </p>
        <p>
          All of this will help you prepare to answer one of the most common
          interview questions: “Why us?” In other words, what’s attracted you to
          this company in particular, and why do you want to work for them? Get
          specific, using some of the information you’ve uncovered from the
          advice above.
        </p>
      </div>

      <h4 className="first-blog__block-title">Practice makes perfect</h4>
      <div className="first-blog__block-wrapper">
        <p>
          Rehearse talking about yourself, your skills, and your strengths out
          loud, either by yourself or in the presence of a trusted friend or
          family member who can provide encouragement and feedback. You may feel
          awkward at first, but it will allow you to become more comfortable
          speaking in public, shows you’re prepared, and will bolster your
          confidence if you get nervous during the interview.
        </p>
        <p>
          When you’re talking about your skill set, be sure to highlight a mix
          of foundational knowledge for the Carpenter Foreman role as well as
          your unique perspective. Reflect back on specific experiences in past
          roles: Perhaps you creatively managed your budget to save the company
          money, or you led your team to complete a project ahead of schedule
          with your time and team management skills.
        </p>
      </div>

      <h4 className="first-blog__block-title">Come prepared</h4>
      <div className="first-blog__block-wrapper">
        <p>
          Though you’d never wear dress clothes to a job site, presenting a
          professional image is important (plus, you’ll feel more confident).
          Business casual - a button-up shirt, slacks, and dress shoes for men;
          or tailored dress pants and sweater, blouse, or blazer paired with
          sensible shoes for women - is acceptable. This also demonstrates to
          the interviewer or hiring manager that you are a serious candidate and
          can be trusted to be personnel or client-facing.
        </p>
        <p>
          Coming prepared also means bringing extra copies of your resume (or
          work history), a list of references, and a notebook and pen to take
          notes. This isn’t just for show - it proves that you are attentive,
          and also helps you keep track of anything that you’d want to follow up
          on after the interview
        </p>
      </div>
      <h4 className="first-blog__block-title">Play to your strengths</h4>
      <div className="first-blog__block-wrapper">
        <p>
          There is no such thing as the perfect candidate. Playing up your
          strengths and speaking passionately about past wins will help you
          shine despite areas where you may have an opportunity for growth.
        </p>
        <p>
          Think of a past positive experience in which your strengths stood out.
          Perhaps you’ve obtained a special trade certification, specialize in
          an area of construction, or successfully managed a particularly
          complex project. You could also promote any past achievements such as
          awards you’ve received, workplace safety initiatives you’ve
          implemented, or even perfect work attendance.
        </p>
        <p>
          If the interviewer or hiring manager asks a question and your
          experience doesn’t exactly match up, you can always fall back on the
          concept of being curious: eager, lifelong learners make excellent team
          members. Employers want to know that you can do the job, or quickly
          learn how to do it.
        </p>
        <p>
          If possible, cite specific examples of how you’ve performed the
          requirements of this job in the past. If not, cite examples of times
          you have quickly learned something new and had success. If you’ve
          taken up a hobby or interest that requires a relevant skill set to the
          role (such as cooking or a new team sport) then use it to your
          advantage.
        </p>
      </div>

      <h4 className="first-blog__block-title">Show you’re a team player</h4>
      <div className="first-blog__block-wrapper">
        <p>
          Teamwork is an essential skill for this role, so it’s wise to have a
          few real-life examples prepared for how you’ve worked on or managed
          teams. If you led your team to increased output or performance, or you
          helped initiate skills development for your team, speak up!
          Construction companies want a Carpenter Foreman who is enthusiastic
          and reliable.
        </p>
        <p>
          You could also touch on cross-functional collaboration. This means
          that you have knowledge of and get along with other departments to
          help the whole company run smoothly.
        </p>
        <p>
          Finally, though you’ll be working as part of a team, hierarchies
          matter in companies. Demonstrate how well you’ll fit in and help the
          company succeed. It never hurts to highlight your skills in a way that
          shows you made your boss look good.
        </p>
      </div>

      <h4 className="first-blog__block-title">End on a high note</h4>
      <div className="first-blog__block-wrapper">
        <p>
          When the interviewer or hiring manager asks if you have any questions,
          use it as an opportunity to set yourself apart and show enthusiasm for
          the role. The good news is, since you’ve read this far, you’re already
          prepared!
        </p>
        <p>
          Rather than ask about time off and promotions, you may choose to ask
          instead about the company’s approach to training, career path
          development, and opportunities for growth. You should also avoid
          asking about anything you and the hiring manager have already covered,
          and instead focus on the company’s projects, achievements, and goals.
        </p>
        <p>
          Be sure to follow up after the interview with a personalized thank you
          note (e-mail is fine). It shows that you are gracious on top of being
          a standout candidate . Make sure your thank you note doesn’t sound too
          generic, and includes a specific detail or takeaway or insight from
          your time together.
        </p>
        <p>
          Finally, remember: Interviews are a conversation, not an
          interrogation. The goal of the interview is not only for the employer
          to get a feel for your skills and abilities to see if you’d be a good
          fit for the role, but for you to learn more about the company and see
          if you’d enjoy working there.
        </p>
      </div>

      <br />
      <p>Good luck!</p>
      <p>The Skillit Team</p>
    </div>
  )
}
