import { IWorkerInfoOB } from '../../models'

export const listBlocksPBLinear = [
  {
    title: 'You >',
    amountLines: 5,
  },
  {
    title: 'Your Work >',
    amountLines: 3,
  },
  {
    title: 'Your Future >',
    amountLines: 4,
  },
]

export const initialWorkerInfoOB: IWorkerInfoOB = {
  last_page: 0,
  last_location: null,
  is_completed: false,
  user_data: {
    id: null,
    password_set: '',
    username: null,
    first_name: null,
    last_name: null,
    phone: null,
    role: [],
    training_certifications: [],
    skills: [],
    language: [],
    unions: [],
    referee: [],
    custom_jobs: false,
    most_recent_pay_rate: null,
    desired_pay_rate: null,
    work_experience: [],
    incomplete_work_experience: [],
    home_location: {
      zip_code: null,
      longitude: null,
      latitude: null,
      state: null,
      city: null,
      street: null,
      raw_input: null,
    },
    relocation_location: null,
    preferred_locations: null,
    willing_to_relocate: null,
    travel_distance: null,
    other_role: null,
    eligible_work_us: null,
  },
}

export enum EDate {
  present = 'Present',
}

export enum ECompletionStatusOB {
  invited = 'invited',
  registered = 'registered',
  applied = 'applied',
  approved = 'approved',
  rejected = 'rejected',
  verified = 'verified',
}

export enum RoleNames {
  carpenter = 'Carpenter',
  general_labor = 'General Labor',
  concrete = 'Concrete Worker',
  other_trade = 'Other',
  superintendent = 'Superintendent',
  pipelayer = 'Pipelayer',
  pipefitter = 'Pipefitter',
  millwright = 'Millwright',
  welder = 'Welder',
  heavy_equipment_operator = 'Heavy Equipment Operator - Loading / Excavating',
}

export enum EPageTitleOB {
  unions_certif_lang = 'Skillit - Onboarding Unions/Certificates/Language',
  email = 'Skillit - Onboarding Email Page',
  experience = 'Skillit - Onboarding Experience Page',
  best_jobs = 'Skillit - Onboarding Best Jobs Page',
  home_location = 'Skillit - Onboarding Home Location Page',
  move_planning = 'Skillit - Onboarding Move Planning Page',
  name = 'Skillit - Onboarding Name Page',
  rates = 'Skillit - Onboarding Rates',
  reference = 'Skillit - Onboarding Reference',
  thanks = 'Skillit - Onboarding Thanks Page',
  trades = 'Skillit - Onboarding Trades Page',
  travel_to_work = 'Skillit - Onboarding Travel To Work Page',
  upload_resume = 'Skillit - Onboarding Upload Resume Page',
  willing_to_relocate = 'Skillit - Onboarding Willing To Relocate Page',
  language = 'Skillit - Onboarding Language Page',
}

export const mobileWidth = 1023
