import React from 'react'
import './errorMessage.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
}

export const ErrorMessage = ({ children }: Props) => {
  return (
    <div className={'error-msg'} data-testid="error-message">
      {children}
    </div>
  )
}
