import { NavMenu } from '../../../../components/atoms/NavMenu'
import { useMediaContext } from '../../../../utils/hooks'
import './sideBarWrapper.scss'
interface Props {
  children: JSX.Element
}
export const SideBarWrapper = (props: Props) => {
  const isMobile = useMediaContext()
  return (
    <div className={`${!isMobile && 'side-bar'}`} data-testid="side-bar">
      {!isMobile && (
        <div className="side-bar__nav" data-testid="side-bar__nav">
          <NavMenu></NavMenu>
        </div>
      )}
      <div
        className={`${!isMobile && 'side-bar__content'}`}
        data-testid="side-bar__content"
      >
        {props.children}
      </div>
    </div>
  )
}
