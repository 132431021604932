import { unwrapResult } from '@reduxjs/toolkit'
import * as route from '../../../../services/route'
import { useCallback, useEffect, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { IWorkerInfoOB } from '../../../../models'
import {
  logout,
  setWorkerInfo,
  signUpOB,
  useAppDispatch,
} from '../../../../redux'
import {
  errorMsg,
  ErrorType,
  EWorkerSStoreKeys,
  WorkerQueries,
} from '../../../constants'
import { useTypedSelector } from '../../UseTypedSelector'
import { usePageNumUpdateOB, getLastPageState } from '../usePageNumUpdateOB'
import { formatPhoneNumber, EFormatPhoneNumFunc } from '../../../scripts'

type TProps = {
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const useGetInfoFromMarketingSite = async (props: TProps) => {
  const { isLoading, setIsLoading } = props
  const queryClient = useQueryClient()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const isAuth = useTypedSelector(s => s.auth.isAuth)
  const userInfo = useTypedSelector(state => state.obInfo.workerInfo)
  const pageStateOBMutation = usePageNumUpdateOB()
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  )

  const isIndeedEmail =
    searchParams.get('email')?.includes('@indeedemail.com') ?? false

  const basicData = useMemo(
    () => ({
      firstName: searchParams.get('fname')?.trim(),
      lastName: searchParams.get('lname')?.trim(),
      phone: searchParams.get('phone'),
      email: searchParams.get('email'),
      source: searchParams.get('source'),
    }),
    [searchParams]
  )

  const isFilledNameFields = !!basicData.firstName && !!basicData.lastName

  const preparedLastPageStage = useMemo(() => {
    if (!isFilledNameFields) return 1
    return isIndeedEmail ? 2 : 3
  }, [isFilledNameFields, isIndeedEmail])

  const lastPageState = useMemo(
    () => getLastPageState(preparedLastPageStage),
    [preparedLastPageStage]
  )

  const signUpOBPayload = useMemo(
    () => ({
      email: basicData.email ?? '',
      phone:
        formatPhoneNumber(
          basicData.phone ?? undefined,
          true,
          true,
          EFormatPhoneNumFunc.enterFromMarketingSite
        ) ?? '',
      first_name: basicData.firstName ?? '',
      last_name: basicData.lastName ?? '',
      source: basicData.source ?? undefined,
    }),
    [
      basicData.email,
      basicData.firstName,
      basicData.lastName,
      basicData.phone,
      basicData.source,
    ]
  )

  const preparedDate = useCallback(
    (data: IWorkerInfoOB) => {
      const phoneNumber: string | null =
        formatPhoneNumber(
          basicData.phone ?? undefined,
          true,
          true,
          EFormatPhoneNumFunc.enterFromMarketingSite
        ) ?? null
      return {
        ...data,
        last_page: preparedLastPageStage,
        last_location: lastPageState.last_location,
        user_data: {
          ...data.user_data,
          first_name: basicData.firstName ?? '',
          last_name: basicData.lastName ?? '',
          phone: phoneNumber,
          username: isIndeedEmail ? '' : basicData.email,
        },
      }
    },
    [
      basicData,
      lastPageState.last_location,
      isIndeedEmail,
      preparedLastPageStage,
    ]
  )

  const storeSaving = useCallback(() => {
    sessionStorage.setItem(
      EWorkerSStoreKeys.obData,
      JSON.stringify(preparedDate(userInfo))
    )
  }, [preparedDate, userInfo])

  const savingData = useCallback(() => {
    storeSaving()
    pageStateOBMutation.mutate(lastPageState, {
      onSuccess: () => {
        const newData = preparedDate(userInfo)
        dispatch(setWorkerInfo(newData))
        queryClient.setQueryData(WorkerQueries.workerInfoOB, newData)
        history.push(route.OBPath.onboardingTrades)
        setIsLoading(false)
      },
    })
  }, [
    dispatch,
    history,
    pageStateOBMutation,
    preparedDate,
    queryClient,
    storeSaving,
    userInfo,
    setIsLoading,
    lastPageState,
  ])

  const createUser = useCallback(() => {
    setIsLoading(true)
    if (basicData.email && isFilledNameFields) {
      dispatch(signUpOB(signUpOBPayload))
        .then(unwrapResult)
        .then(_ => {
          savingData()
          sessionStorage.setItem(
            EWorkerSStoreKeys.obFirstEnterFromMarketingSite,
            'false'
          )
        })
        .catch(error => {
          if (error.message === ErrorType.existingEmail) {
            toast.error(errorMsg.existingEmail)
            sessionStorage.clear()
            localStorage.clear()
            history.push(route.signIn)
          } else {
            dispatch(
              setWorkerInfo({
                ...preparedDate(userInfo),
                last_page: 2,
                last_location: `/${route.OBpageNames[2]}`,
              })
            )
            sessionStorage.setItem(
              EWorkerSStoreKeys.obData,
              JSON.stringify({
                ...preparedDate(userInfo),
                last_page: 2,
                last_location: `/${route.OBpageNames[2]}`,
              })
            )
            sessionStorage.setItem(
              EWorkerSStoreKeys.obFirstEnterFromMarketingSite,
              'false'
            )
            if (error.name !== 'TypeError') {
              toast.error(error.message)
            }
          }
          setIsLoading(false)
        })
    }
  }, [
    basicData,
    dispatch,
    savingData,
    setIsLoading,
    preparedDate,
    userInfo,
    history,
    isFilledNameFields,
    signUpOBPayload,
  ])

  useEffect(() => {
    if (basicData.source) {
      sessionStorage.setItem(
        EWorkerSStoreKeys.obSourceFromMarketingSite,
        basicData.source
      )
    }
  }, [basicData.source])

  useEffect(() => {
    if (isIndeedEmail) {
      sessionStorage.setItem(EWorkerSStoreKeys.obIsIndeedEmail, 'true')
      dispatch(setWorkerInfo(preparedDate(userInfo)))
      sessionStorage.setItem(
        EWorkerSStoreKeys.obData,
        JSON.stringify(preparedDate(userInfo))
      )
      sessionStorage.setItem(
        EWorkerSStoreKeys.obFirstEnterFromMarketingSite,
        'false'
      )
      history.push(route.OBPath.onboardingEmail)
    }
  }, [
    isIndeedEmail,
    dispatch,
    history,
    preparedDate,
    userInfo,
    isFilledNameFields,
  ])

  useEffect(() => {
    const firstEnter = sessionStorage.getItem(
      EWorkerSStoreKeys.obFirstEnterFromMarketingSite
    )

    const trackFromMarketing = async () => {
      await window.analytics.track('Landing Page Submitted', basicData)
    }

    firstEnter !== 'false' &&
      location.pathname.includes(route.OBPath.onboardingEmail) &&
      basicData.email &&
      trackFromMarketing()
  }, [basicData, location.pathname])

  useEffect(() => {
    const firstEnter = sessionStorage.getItem(
      EWorkerSStoreKeys.obFirstEnterFromMarketingSite
    )

    const isIndeedEmail = sessionStorage.getItem(
      EWorkerSStoreKeys.obIsIndeedEmail
    )

    if (userInfo.last_page > 2 && firstEnter !== null) {
      sessionStorage.removeItem(EWorkerSStoreKeys.obFirstEnterFromMarketingSite)
      sessionStorage.removeItem(EWorkerSStoreKeys.obIsIndeedEmail)
      sessionStorage.removeItem(EWorkerSStoreKeys.obSourceFromMarketingSite)
    }

    if (
      !isAuth &&
      basicData.email &&
      isFilledNameFields &&
      !userInfo.user_data.username &&
      !isLoading &&
      !isIndeedEmail
    ) {
      createUser()
    }

    if (basicData.email && !isFilledNameFields) {
      storeSaving()
      sessionStorage.setItem(
        EWorkerSStoreKeys.obFirstEnterFromMarketingSite,
        'false'
      )
      history.push(route.OBPath.onboardingName)
    }
  }, [
    basicData,
    isAuth,
    userInfo,
    isLoading,
    createUser,
    isIndeedEmail,
    isFilledNameFields,
    savingData,
    storeSaving,
    history,
  ])

  useEffect(() => {
    const firstEnter = sessionStorage.getItem(
      EWorkerSStoreKeys.obFirstEnterFromMarketingSite
    )

    if (
      isAuth &&
      firstEnter === null &&
      location.pathname.includes(route.OBPath.onboardingEmail) &&
      basicData.email
    ) {
      sessionStorage.setItem(
        EWorkerSStoreKeys.obFirstEnterFromMarketingSite,
        'true'
      )
      dispatch(logout())
    }
  }, [basicData.email, location.pathname, isAuth, dispatch])

  return isLoading
}
