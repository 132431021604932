import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { QueryState } from 'react-query/types/core/query'
import { Config } from '../../../../../../components/organism'
import { DrivingInfo } from '../../../../../../models'
import {
  getDrivingInfo,
  updateDrivingInfo,
} from '../../../../../../services/workerApi'
import { WorkerQueries } from '../../../../../../utils/constants'
import { DrivingInfoBlockUI } from './DrivingInfoBlockUI'

interface Props extends React.HTMLAttributes<HTMLElement> {}

export const DrivingInfoBlock = (props: Props) => {
  const queryClient = useQueryClient()
  const drivingInfoQuery: QueryState<DrivingInfo, undefined> | undefined =
    queryClient.getQueryState(WorkerQueries.drivingInfo)
  const { data: drivingInfo, isLoading } = useQuery(
    WorkerQueries.drivingInfo,
    () => getDrivingInfo(),
    {
      enabled: !drivingInfoQuery?.data,
    }
  )

  const drivingInfoMutation = useMutation(
    (newData: { formData: FormData; config?: Config }) =>
      updateDrivingInfo(newData.formData, newData.config)
  )

  return (
    <DrivingInfoBlockUI
      {...props}
      drivingInfo={drivingInfo}
      isLoading={isLoading}
      drivingInfoMutation={drivingInfoMutation}
    />
  )
}
