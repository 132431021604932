import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { QueryState } from 'react-query/types/core/query'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ILocationInfoOB, IWorkerInfoOB } from '../../../models'
import { setWorkerInfo, useAppDispatch } from '../../../redux'
import * as route from '../../../services/route'
import { updateLocationInfoOB } from '../../../services/workerApi'
import {
  OBAnalyticsName,
  WorkerQueries,
  EWorkerSStoreKeys,
} from '../../../utils/constants'
import {
  useTypedSelector,
  usePageNumUpdateOB,
  useMediaContext,
} from '../../../utils/hooks'
import { getLastPageState } from '../../../utils/hooks/obHooks/usePageNumUpdateOB'
import {
  getUserInfoFromStorage,
  onTrackingActions,
  skippingLocationDataFields,
} from '../../../utils/scripts'
import { OBMovePlanningUI } from './OBMovePlanningUI'

export const OBMovePlanning = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const userInfo = useTypedSelector(s => s.obInfo.workerInfo)
  const isMobile = useMediaContext()
  const userInfoFromStorage = useMemo(() => getUserInfoFromStorage(), [])
  const lastPage =
    userInfo.last_page < 7 ? (isMobile ? 8 : 7) : userInfo.last_page
  const lastPageState = useMemo(() => getLastPageState(lastPage), [lastPage])
  const pageStateOBMutation = usePageNumUpdateOB()

  const isUserNew =
    userInfoFromStorage?.user_data.willing_to_relocate ??
    userInfo.user_data.willing_to_relocate === null

  const selectedPlanningOption =
    userInfoFromStorage?.user_data.willing_to_relocate ??
    userInfo.user_data.willing_to_relocate
      ? 1
      : 2

  const [isMovePlanning, setIsMovePlanning] = useState<number | undefined>(
    isUserNew ? undefined : selectedPlanningOption
  )

  const defaultRelocationInfo: ILocationInfoOB = useMemo(
    () => ({
      relocation_location:
        userInfoFromStorage?.user_data.relocation_location ??
        userInfo.user_data.relocation_location,

      preferred_locations:
        userInfoFromStorage?.user_data.preferred_locations ??
        userInfo.user_data.preferred_locations,

      home_location:
        userInfoFromStorage?.user_data.home_location ??
        userInfo.user_data.home_location,

      willing_to_relocate:
        userInfoFromStorage?.user_data.willing_to_relocate ??
        userInfo.user_data.willing_to_relocate,
    }),
    [userInfo, userInfoFromStorage]
  )

  const queryClient = useQueryClient()
  const obRelocationInfoQuery:
    | QueryState<ILocationInfoOB | undefined>
    | undefined = queryClient.getQueryState(WorkerQueries.locationInfoOB)

  const obRelocationInfoMutation = useMutation(
    (data: ILocationInfoOB) =>
      updateLocationInfoOB(skippingLocationDataFields(data)),
    {
      onError: err => {
        if (err instanceof Error) {
          toast.error(err.message)
        }
      },
    }
  )

  const [relocationOB, setRelocationOB] = useState<ILocationInfoOB>(
    defaultRelocationInfo ?? obRelocationInfoQuery?.data
  )

  const [isFilledPlace, setIsFilledPlace] = useState(false)

  const isRelocationLocationChanged = useMemo(
    () =>
      JSON.stringify(userInfoFromStorage?.user_data.relocation_location) !==
        JSON.stringify(userInfo?.user_data.relocation_location) ||
      JSON.stringify(userInfoFromStorage?.user_data.relocation_location) !==
        JSON.stringify(relocationOB?.relocation_location) ||
      isMovePlanning !== 1,
    [
      isMovePlanning,
      userInfoFromStorage?.user_data.relocation_location,
      userInfo?.user_data.relocation_location,
      relocationOB?.relocation_location,
    ]
  )

  useEffect(() => {
    !userInfoFromStorage &&
      userInfo?.user_data?.relocation_location &&
      !obRelocationInfoQuery?.data &&
      setRelocationOB({
        ...defaultRelocationInfo,
        relocation_location: userInfo?.user_data.relocation_location,
        willing_to_relocate: userInfo?.user_data.willing_to_relocate,
      })
  }, [
    userInfo?.user_data.relocation_location,
    userInfo?.user_data.willing_to_relocate,
    defaultRelocationInfo,
    obRelocationInfoQuery?.data,
    userInfoFromStorage,
  ])

  const onSelectDate = (initDate: Date) => {
    setRelocationOB(prevRelocation => {
      if (prevRelocation.relocation_location) {
        return {
          ...prevRelocation,
          relocation_location: {
            ...prevRelocation.relocation_location,
            date: moment(initDate).format('yyyy-MM-DD'),
          },
        }
      } else {
        return prevRelocation
      }
    })
  }

  const getNewData = useCallback(
    (
      data: IWorkerInfoOB,
      dataRelocation: ILocationInfoOB,
      dataWillingToRelocate: boolean | null
    ) => ({
      ...data,
      last_page: lastPage,
      last_location: lastPageState.last_location,
      user_data: {
        ...data.user_data,
        relocation_location: dataRelocation?.relocation_location,
        willing_to_relocate: dataWillingToRelocate,
      },
    }),
    [lastPage, lastPageState.last_location]
  )

  const onClickBack = useCallback(() => {
    sessionStorage.setItem(
      EWorkerSStoreKeys.obData,
      JSON.stringify(
        getNewData(
          userInfoFromStorage ?? userInfo,
          relocationOB,
          isMovePlanning === 1
        )
      )
    )

    history.push(route.OBPath.onboardingLocation)
  }, [
    getNewData,
    relocationOB,
    history,
    isMovePlanning,
    userInfo,
    userInfoFromStorage,
  ])

  const onClickNext = useCallback(
    async (
      initRelocationOB: ILocationInfoOB,
      initSetRelocationOB: React.Dispatch<React.SetStateAction<ILocationInfoOB>>
    ) => {
      const isNotPlanningMove = {
        ...defaultRelocationInfo,
        relocation_location: null,
        willing_to_relocate: false,
      }

      if (!isRelocationLocationChanged) {
        sessionStorage.setItem(
          EWorkerSStoreKeys.obData,
          JSON.stringify(
            getNewData(
              userInfoFromStorage ?? userInfo,
              relocationOB,
              relocationOB?.willing_to_relocate
            )
          )
        )
        isMobile
          ? history.push(route.OBPath.onboardingLastJobs)
          : history.push(route.OBPath.onboardingUploadResume)
      } else if (isMovePlanning === 2) {
        await onTrackingActions(OBAnalyticsName.uncoming_move_submitted)
        dispatch(
          setWorkerInfo(
            getNewData(
              userInfo,
              { ...initRelocationOB, relocation_location: null },
              false
            )
          )
        )
        obRelocationInfoMutation.mutate(isNotPlanningMove, {
          onSuccess: () => {
            queryClient.setQueryData(
              WorkerQueries.locationInfoOB,
              isNotPlanningMove
            )
            initSetRelocationOB(isNotPlanningMove)
          },
        })
        pageStateOBMutation.mutate(lastPageState, {
          onSuccess() {
            sessionStorage.setItem(
              EWorkerSStoreKeys.obData,
              JSON.stringify(
                getNewData(
                  userInfoFromStorage ?? userInfo,
                  { ...initRelocationOB, relocation_location: null },
                  false
                )
              )
            )
            isMobile
              ? history.push(route.OBPath.onboardingLastJobs)
              : history.push(route.OBPath.onboardingUploadResume)
          },
        })
      } else {
        await onTrackingActions(OBAnalyticsName.uncoming_move_submitted)
        dispatch(setWorkerInfo(getNewData(userInfo, initRelocationOB, true)))
        obRelocationInfoMutation.mutate(
          { ...initRelocationOB, willing_to_relocate: true },
          {
            onSuccess: () => {
              queryClient.setQueryData(WorkerQueries.locationInfoOB, {
                ...initRelocationOB,
                willing_to_relocate: true,
              })
              initSetRelocationOB({
                ...initRelocationOB,
                willing_to_relocate: true,
              })
            },
          }
        )
        pageStateOBMutation.mutate(lastPageState, {
          onSuccess() {
            sessionStorage.setItem(
              EWorkerSStoreKeys.obData,
              JSON.stringify(
                getNewData(
                  userInfoFromStorage ?? userInfo,
                  initRelocationOB,
                  true
                )
              )
            )

            isMobile
              ? history.push(route.OBPath.onboardingLastJobs)
              : history.push(route.OBPath.onboardingUploadResume)
          },
        })
      }
    },
    [
      defaultRelocationInfo,
      dispatch,
      getNewData,
      history,
      isMobile,
      isMovePlanning,
      isRelocationLocationChanged,
      obRelocationInfoMutation,
      pageStateOBMutation,
      queryClient,
      userInfo,
      userInfoFromStorage,
      relocationOB,
      lastPageState,
    ]
  )
  useEffect(() => {
    isMovePlanning === 2 && setIsFilledPlace(false)
  }, [isMovePlanning])

  useEffect(() => {
    isMovePlanning === 1 &&
      (userInfoFromStorage ?? userInfo).user_data.relocation_location?.city &&
      setIsFilledPlace(true)
  }, [isMovePlanning, userInfoFromStorage, userInfo])

  useEffect(() => {
    if (
      userInfoFromStorage?.user_data.relocation_location &&
      userInfoFromStorage?.user_data.willing_to_relocate
    ) {
      setIsMovePlanning(1)
      setIsFilledPlace(true)
    }
    if (
      userInfoFromStorage?.user_data.willing_to_relocate === false &&
      !userInfoFromStorage?.user_data.relocation_location
    ) {
      setIsMovePlanning(2)
    }
  }, [userInfoFromStorage])

  return (
    <OBMovePlanningUI
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      isMovePlanning={isMovePlanning}
      setIsMovePlanning={setIsMovePlanning}
      isFilledPlace={isFilledPlace}
      setIsFilledPlace={setIsFilledPlace}
      relocationOB={relocationOB}
      setRelocationOB={setRelocationOB}
      defaultRelocationInfo={defaultRelocationInfo}
      onSelectDate={onSelectDate}
      isWaitingResponse={
        obRelocationInfoMutation.isIdle
          ? false
          : !obRelocationInfoMutation.error
      }
    />
  )
}
