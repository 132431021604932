import { ButtonBack, Loader } from '../../../../../components/atoms'
import { ModalBox } from '../../../../../components/molecules'
import { WorkPreferencesInfo } from '../../../../../models'
import { WorkPrefForModal } from './WorkPreferences'
import { WorkPreferencesContent } from './WorkPreferencesContent.tsx'
import { WorkPreferncesModal } from './WorkPreferencesModal'
import * as route from '../../../../../services/route'
import { PopupLoader } from '../../../../../components/organism'
import './workPreferences.scss'

interface Props {
  isSaving: boolean
  isPrefLoading: boolean
  isModalVisible: boolean
  handleSubmit: () => void
  isDataNotChanged: boolean
  preparedRolesData?: string[]
  isConfirmationModalVisible: boolean
  editableWorkPreferences: WorkPrefForModal
  workPreferences: WorkPreferencesInfo | null
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  setIsConfirmationModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  setEditableWorkPreferences: React.Dispatch<
    React.SetStateAction<WorkPrefForModal>
  >
  setWorkPreferences: React.Dispatch<
    React.SetStateAction<WorkPreferencesInfo | null>
  >
  onChangeOtherInput: (value: string) => void
}

export const WorkPreferencesUI = (props: Props) => {
  const {
    isSaving,
    handleSubmit,
    isPrefLoading,
    isModalVisible,
    workPreferences,
    isDataNotChanged,
    setIsModalVisible,
    preparedRolesData,
    setWorkPreferences,
    editableWorkPreferences,
    setEditableWorkPreferences,
    isConfirmationModalVisible,
    setIsConfirmationModalVisible,
    onChangeOtherInput,
  } = props
  return (
    <div className="work-preferences" data-testid="work-preferences">
      <ButtonBack route={route.profile} title="Profile" />
      {isSaving && <PopupLoader>Saving...</PopupLoader>}
      {isModalVisible && (
        <ModalBox
          className={`work-preferences__modal`}
          onCloseModal={() => {
            isDataNotChanged
              ? setIsModalVisible(false)
              : setIsConfirmationModalVisible(true)
          }}
          visibleCloseIcon={true}
        >
          {!isPrefLoading && workPreferences ? (
            <WorkPreferncesModal
              workPreferences={workPreferences}
              setWorkPreferences={setWorkPreferences}
              setIsModalVisible={setIsModalVisible}
              isConfirmationModalVisible={isConfirmationModalVisible}
              setIsConfirmationModalVisible={setIsConfirmationModalVisible}
              editableWorkPreferences={editableWorkPreferences}
              setEditableWorkPreferences={setEditableWorkPreferences}
              preparedRolesData={preparedRolesData}
              handleSubmit={handleSubmit}
              onChangeOtherInput={onChangeOtherInput}
            />
          ) : (
            <Loader />
          )}
        </ModalBox>
      )}
      <WorkPreferencesContent
        workPreferences={workPreferences}
        setIsModalVisible={setIsModalVisible}
      />
    </div>
  )
}
