import React, { useCallback, useEffect, useRef, useState } from 'react'
import { style } from '../../../../utils/constants'
import { useMediaContext } from '../../../../utils/hooks'
import { EPos, PromptUI } from '../../prompts'
import './sliderWithArrows.scss'

enum EButton {
  left = 'left',
  right = 'right',
}
enum ESizeStyles {
  circleHeight = 23,
}

interface IProps extends React.HTMLAttributes<HTMLElement> {
  title?: string
  defaultValue?: number
  rangeProcessing: (value: number) => void
  valueTitle: string
  step?: number
  min?: number
  max?: number
}

export const SliderWithArrows = (props: IProps) => {
  const {
    className,
    title,
    defaultValue = 0,
    rangeProcessing,
    valueTitle,
    step = 1,
    min = 0,
    max = 100,
  } = props
  const isMobile = useMediaContext()
  const promptRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  let moveInterval = useRef<NodeJS.Timeout | null>(null)
  let delay = useRef<NodeJS.Timeout | null>(null)
  const [btnPressed, setBtnPressed] = useState<
    EButton.left | EButton.right | null
  >(null)
  const [value, setValue] = useState<number>(defaultValue)

  const onClickHandler = useCallback(
    (e: React.MouseEvent, isLeft: boolean) => {
      const action = () => {
        setValue(prevValue => {
          const nextStep = isLeft ? prevValue - step : prevValue + step
          if (nextStep < min || nextStep > max) return prevValue
          return nextStep
        })
      }

      switch (e.type) {
        case 'mousedown':
          if (!moveInterval.current) {
            setBtnPressed(isLeft ? EButton.left : EButton.right)
            delay.current = setTimeout(() => {
              moveInterval.current = setInterval(() => action(), 40)
            }, 300)
          }
          break
        case 'mouseleave':
        case 'mouseup':
          if (moveInterval.current) {
            clearInterval(moveInterval.current)
            moveInterval.current = null
            setBtnPressed(null)
          } else {
            e.type !== 'mouseleave' && action()
            delay.current && clearTimeout(delay.current)
            delay.current = null
            setBtnPressed(null)
          }
          break
      }
    },
    [setValue, step, min, max]
  )

  useEffect(() => {
    if (defaultValue !== value) {
      rangeProcessing(value)
    }
    if (inputRef.current) {
      inputRef.current.style.setProperty(
        '--value',
        `${(value - min) * (100 / (max - min))}%`
      )
      inputRef.current.style.setProperty(
        '--circleHeight',
        `${ESizeStyles.circleHeight}px`
      )
    }
  }, [value, min, max, rangeProcessing, defaultValue])

  useEffect(() => {
    if (promptRef.current) {
      const workingLength =
        (promptRef.current.parentElement?.offsetWidth ?? 0) - 23
      const leftIndent =
        ESizeStyles.circleHeight / 2 - promptRef.current.offsetWidth / 2

      promptRef.current.style.marginLeft = `calc(${leftIndent}px + ${
        (workingLength * (value - min)) / (max - min)
      }px)`
    }
  }, [valueTitle, value, min, max])

  return (
    <div className={`slider-with-arrows ${className ?? ''}`}>
      <p className="slider-with-arrows__title">{title ?? ''}</p>
      <div className="slider-with-arrows__wrapper">
        <svg
          className="slider-with-arrows__arrow"
          width="16"
          height="19"
          viewBox="0 0 16 19"
          fill={btnPressed === EButton.left ? style.mainOrangeColor : 'none'}
          xmlns="http://www.w3.org/2000/svg"
          onMouseDown={e => onClickHandler(e, true)}
          onMouseUp={e => onClickHandler(e, true)}
          onMouseLeave={e => onClickHandler(e, true)}
          data-testid="slider-with-arrows__arrow_left"
        >
          <path
            d="M15.25 17.7272L1 9.5L15.25 1.27276L15.25 17.7272Z"
            stroke="black"
          />
        </svg>

        <div className="slider-with-arrows__slider-wrapper">
          <PromptUI
            ref={promptRef}
            className="slider-with-arrows__value-prompt"
            position={isMobile ? EPos.top : EPos.bottom}
            text={valueTitle}
          />
          <input
            id="slider"
            ref={inputRef}
            className="slider-with-arrows__slider"
            type="range"
            min={min}
            max={max}
            value={value}
            step={step}
            onChange={e => setValue(+e.target.value)}
          ></input>
        </div>

        <svg
          className="slider-with-arrows__arrow"
          width="16"
          height="19"
          viewBox="0 0 16 19"
          fill={btnPressed === EButton.right ? style.mainOrangeColor : 'none'}
          xmlns="http://www.w3.org/2000/svg"
          onMouseDown={e => onClickHandler(e, false)}
          onMouseUp={e => onClickHandler(e, false)}
          onMouseLeave={e => onClickHandler(e, false)}
          data-testid="slider-with-arrows__arrow_right"
        >
          <path
            d="M0.75 1.27276L15 9.5L0.75 17.7272L0.75 1.27276Z"
            stroke="black"
          />
        </svg>
      </div>
    </div>
  )
}
