import { Button } from '../../../../components/atoms'
import { ModalBox, NavigationFooter } from '../../../../components/molecules'
import photoCameraImg from '../../../../assets/icons/other/photo-camera.svg'
import infoImg from '../../../../assets/icons/other/info.svg'
import { Textarea } from '../../../../components/atoms/textareas'
import { Helmet } from 'react-helmet'
import { EWorkerPageTitle } from '../../../../utils/constants'
import React from 'react'
import { WelcomePageModal } from './WelcomePageModal'

interface IProps extends React.HTMLAttributes<HTMLElement> {
  name: string
  isProfilePhoto: boolean
  about: string
  setAbout: React.Dispatch<React.SetStateAction<string>>
  maxLengthAbout: number
  onUploadPhoto: () => void
  onClickNext: () => void
  isWaitingResponse: boolean
  isExampleModalVisible: boolean
  setIsExampleModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const WelcomePageUI = (props: IProps) => {
  const {
    children,
    name,
    isProfilePhoto,
    about,
    setAbout,
    maxLengthAbout,
    onUploadPhoto,
    onClickNext,
    isWaitingResponse,
    isExampleModalVisible,
    setIsExampleModalVisible,
  } = props

  return (
    <div className="worker-welcome ob-container">
      <Helmet>
        <title>{EWorkerPageTitle.welcome}</title>
      </Helmet>

      {isExampleModalVisible && (
        <ModalBox
          className="worker-welcome__modal welcome-modal"
          onCloseModal={() => setIsExampleModalVisible(false)}
          visibleCloseIcon
        >
          <WelcomePageModal />
        </ModalBox>
      )}

      <div className="worker-welcome__text">
        <p
          className="worker-welcome__title ob-question"
          data-testid="worker-welcome__title"
        >
          Welcome to Skillit, <strong>{name.length ? name : 'User'}</strong>!
        </p>

        <p className="worker-welcome__description ob-description">
          Take a moment and tell future employers a bit about yourself. We
          suggest a profile pic and a quick sentence or two telling us what
          makes you... you
        </p>
      </div>

      <div className="worker-welcome__info">
        <div className="worker-welcome__info-photo profile-photo">
          {children}

          {!isProfilePhoto && (
            <div className="profile-photo__text">
              {name.length ? name[0] : ''}
            </div>
          )}

          <div className="profile-photo__btn-wrapper">
            <button
              type="button"
              className="profile-photo__btn"
              onClick={onUploadPhoto}
            >
              <img
                className="profile-photo__btn-icon"
                src={photoCameraImg}
                alt="Photocamera"
              />
            </button>
          </div>
        </div>

        <div className="worker-welcome__info-about about">
          <p className="about__quotes">”</p>

          <div className="about__textarea-wrapper">
            <Textarea
              className="about__textarea"
              placeholder="I am..."
              rows={7}
              value={about}
              onChange={e => setAbout(e.target.value)}
              maxLength={maxLengthAbout}
            />
          </div>

          <p className="about__textarea-counter">
            {about.length}/{maxLengthAbout}
          </p>
        </div>
      </div>

      <div className="worker-welcome__examples">
        <img
          className="worker-welcome__examples-img"
          src={infoImg}
          alt="Information"
        />

        <button
          type="button"
          className="worker-welcome__examples-btn"
          onClick={() => setIsExampleModalVisible(true)}
        >
          Need some inspiration? We can show you some examples
        </button>
      </div>

      <NavigationFooter isInitializationPage>
        <Button
          onClick={onClickNext}
          disabled={!about.length || isWaitingResponse}
        >
          Next
        </Button>
      </NavigationFooter>
    </div>
  )
}
