import React, { useState } from 'react'
import {
  Button,
  Checkbox,
  Input,
  Multiselect,
} from '../../../../../../components/atoms'
import { WorkPreferencesInfo } from '../../../../../../models'
import {
  hourlyPayList,
  idealEmployer,
  workPreference,
  getObjWorkPref,
  getCheckedRole,
} from './workPrefVarsAndFunc'
import { handleCheckboxProfile } from '../../handleProfileInputs'
import { WorkPrefForModal } from '../WorkPreferences'
import './workPreferencesModal.scss'
import {
  ModalBox,
  ConfirmationUpdateModal,
} from '../../../../../../components/molecules'
import { RoleNames } from '../../../../../../utils/constants'

interface Props {
  workPreferences: WorkPreferencesInfo
  setWorkPreferences: React.Dispatch<
    React.SetStateAction<WorkPreferencesInfo | null>
  >
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  isConfirmationModalVisible: boolean
  setIsConfirmationModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  editableWorkPreferences: WorkPrefForModal
  setEditableWorkPreferences: React.Dispatch<
    React.SetStateAction<WorkPrefForModal>
  >
  preparedRolesData?: string[]
  handleSubmit: () => void
  onChangeOtherInput: (value: string) => void
}

export const WorkPreferncesModal = (props: Props) => {
  const {
    preparedRolesData,
    editableWorkPreferences,
    setEditableWorkPreferences,
    workPreferences,
    handleSubmit,
    setIsModalVisible,
    isConfirmationModalVisible,
    setIsConfirmationModalVisible,
    onChangeOtherInput,
  } = props
  const [showOtherRoleInput, setShowOtherRoleInput] = useState<boolean>(
    !!editableWorkPreferences?.other_role
  )

  return (
    <div className="work-prefernces-modal" data-testid="work-prefernces-modal">
      {isConfirmationModalVisible && (
        <ModalBox className="upload-file__modal-box">
          <ConfirmationUpdateModal
            title="Discard Changes"
            uploadText="Save Changes"
            removeText="Discard Changes"
            updateData={() => {
              handleSubmit()
              setIsConfirmationModalVisible(false)
            }}
            remove={() => {
              setIsConfirmationModalVisible(false)
              setIsModalVisible(false)
              setEditableWorkPreferences(getObjWorkPref(workPreferences))
            }}
          />
        </ModalBox>
      )}
      <h3 className="work-prefernces-modal__title">Edit Worker Preferences</h3>
      <main className="work-prefernces-modal__main">
        <section className="work-prefernces-modal__editable-block">
          <h4 className="work-prefernces-modal__editable-block-title">
            Most recent hourly pay
          </h4>
          <Multiselect
            className="work-prefernces-modal__editable-block-select"
            multiSelect={false}
            elements={hourlyPayList}
            selectedElement={editableWorkPreferences.most_recent_pay_rate}
            getSelectedElement={hourlyPay =>
              setEditableWorkPreferences(workPref => ({
                ...workPref,
                most_recent_pay_rate: hourlyPay,
              }))
            }
            placeholder="Select"
          />
        </section>
        <section className="work-prefernces-modal__editable-block">
          <h4 className="work-prefernces-modal__editable-block-title">
            Desired hourly pay
          </h4>
          <Multiselect
            className="work-prefernces-modal__editable-block-select"
            multiSelect={false}
            elements={hourlyPayList}
            selectedElement={editableWorkPreferences.desired_pay_rate}
            getSelectedElement={hourlyPay =>
              setEditableWorkPreferences(workPref => ({
                ...workPref,
                desired_pay_rate: hourlyPay,
              }))
            }
            placeholder="Select"
          />
        </section>
        <section className="work-prefernces-modal__editable-block">
          <h4 className="work-prefernces-modal__editable-block-title">
            Role (Please select role where you have experience or would like to
            learn).
          </h4>
          <div className="work-prefernces-modal__editable-block_btns-grid">
            {preparedRolesData &&
              preparedRolesData.map((role, roleIndex) => {
                return (
                  <Checkbox
                    key={role}
                    buttonsGrid
                    name={role}
                    idCheckbox={roleIndex}
                    checked={getCheckedRole(
                      role,
                      editableWorkPreferences.roles,
                      editableWorkPreferences.other_role
                    )}
                    toggleCheckbox={(_, isChecked) => {
                      handleCheckboxProfile(
                        isChecked,
                        setEditableWorkPreferences,
                        role,
                        'roles'
                      )
                      if (role === RoleNames.other_trade) {
                        setShowOtherRoleInput(!isChecked)
                      }
                    }}
                  />
                )
              })}
          </div>

          {showOtherRoleInput && (
            <Input
              className="work-prefernces-modal__editable-block_input"
              value={editableWorkPreferences.other_role ?? ''}
              onChange={e => onChangeOtherInput(e.target.value)}
              placeholder="What other role do you practice?"
            />
          )}
        </section>
        <section className="work-prefernces-modal__editable-block">
          <h4 className="work-prefernces-modal__editable-block-title">
            Work Preference
          </h4>
          <div className="work-prefernces-modal__editable-block_btns-grid">
            {workPreference.map((pref, prefIndex) => {
              const checked =
                editableWorkPreferences.work_preferences &&
                editableWorkPreferences.work_preferences.find(el => el === pref)
                  ? true
                  : undefined
              return (
                <Checkbox
                  buttonsGrid
                  key={pref}
                  name={pref}
                  idCheckbox={prefIndex}
                  checked={checked}
                  toggleCheckbox={(_, isChecked) =>
                    handleCheckboxProfile(
                      isChecked,
                      setEditableWorkPreferences,
                      pref,
                      'work_preferences'
                    )
                  }
                />
              )
            })}
          </div>
        </section>
        <section className="work-prefernces-modal__editable-block">
          <h4 className="work-prefernces-modal__editable-block-title">
            Ideal Employer
          </h4>
          <div className="work-prefernces-modal__editable-block_btns-grid">
            {idealEmployer.map((employer, employerIndex) => {
              const checked =
                editableWorkPreferences.ideal_employer &&
                editableWorkPreferences.ideal_employer.find(
                  el => el === employer
                )
                  ? true
                  : undefined
              return (
                <Checkbox
                  buttonsGrid
                  key={employer}
                  name={employer}
                  idCheckbox={employerIndex}
                  checked={checked}
                  toggleCheckbox={(_, isChecked) =>
                    handleCheckboxProfile(
                      isChecked,
                      setEditableWorkPreferences,
                      employer,
                      'ideal_employer'
                    )
                  }
                />
              )
            })}
          </div>
        </section>
        <hr />
      </main>
      <Button
        type="submit"
        className="work-prefernces-modal__btn-save"
        onClick={e => {
          e.preventDefault()
          handleSubmit()
        }}
      >
        SAVE CHANGES
      </Button>
    </div>
  )
}
