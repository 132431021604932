import { Fragment } from 'react'
import edit_pencil from '../../../../../../assets/icons/other/edit-pencil.svg'
import { ModalBox } from '../../../../../../components/molecules'
import { PopupLoader } from '../../../../../../components/organism'
import {
  ICertificationGroup,
  SkillsInfoOnlyCertifications,
} from '../../../../../../models'
import { CertificateGroupsModal } from './CertificateGroupsModal'
import './certificateGroups.scss'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  isSaving: boolean
  isModalVisible: boolean
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  isConfirmationModalVisible: boolean
  setIsConfirmationModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  handleSubmit: () => void
  onCloseModal: () => void
  selectedCertList: ICertificationGroup[]
  choiceLists: ICertificationGroup[] | null
  certInfoForm: SkillsInfoOnlyCertifications
  setCertInfoForm: React.Dispatch<
    React.SetStateAction<SkillsInfoOnlyCertifications>
  >
  certInfoState: SkillsInfoOnlyCertifications
  setCertInfoState: React.Dispatch<
    React.SetStateAction<SkillsInfoOnlyCertifications>
  >
}

export const CertificateGroupsUI = (props: IProps) => {
  const {
    className,
    isSaving,
    isModalVisible,
    setIsModalVisible,
    isConfirmationModalVisible,
    setIsConfirmationModalVisible,
    handleSubmit,
    onCloseModal,
    selectedCertList,
  } = props

  return (
    <div
      className={`certificate-groups ${className ?? ''}`}
      data-testid="certificate-groups"
    >
      {isSaving && <PopupLoader>Saving...</PopupLoader>}

      {isModalVisible && (
        <ModalBox
          className="certificate-groups__modal-box"
          onCloseModal={onCloseModal}
          visibleCloseIcon={true}
        >
          <CertificateGroupsModal
            {...props}
            className={className}
            handleSubmit={handleSubmit}
            isConfirmationModalVisible={isConfirmationModalVisible}
            setIsConfirmationModalVisible={setIsConfirmationModalVisible}
            setIsModalVisible={setIsModalVisible}
          />
        </ModalBox>
      )}

      <div className="certificate-groups__header">
        <p className="certificate-groups__header-title">
          Training/Certifications
        </p>
        <img
          className="certificate-groups__header-edit-pensil"
          src={edit_pencil}
          alt="edit-pensil"
          onClick={() => setIsModalVisible(true)}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              setIsModalVisible(true)
            }
          }}
        />
      </div>

      {selectedCertList.length ? (
        selectedCertList.map((group, index) => (
          <Fragment key={group.group}>
            <div className="certificate-groups__skill">
              <p className="certificate-groups__skill-question">
                {group.group}
              </p>
              {group.certifications.map((el, index) => (
                <p
                  key={el + index}
                  className="certificate-groups__skill-answers"
                >
                  {el}
                </p>
              ))}
            </div>
            {selectedCertList.length > 1 &&
              selectedCertList.length - 1 !== index && <hr />}
          </Fragment>
        ))
      ) : (
        <p className="certificate-groups__skill-answers">None provided</p>
      )}
    </div>
  )
}
