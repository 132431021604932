import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  ChallengeModel,
  ChallengeModelFormatter,
} from '../../../../models/ChallengeModel'
import { Button } from '../../../atoms'
import challengeIcon from '../../../../assets/icons/other/challenge-icon.svg'
import './challengeDetail.scss'
import clock from '../../../../assets/icons/other/clock.svg'
import passed from '../../../../assets/icons/checkmarks/green_circle_checkmark.svg'
import * as route from '../../../../services/route'
interface Props {
  model: ChallengeModel
  classChallenge?: string
}

export const ChallengeDetail = (props: Props) => {
  const model = props.model
  const history = useHistory()
  const isVisible = model.visible
  const onClickChallenge = () => {
    history.push(
      isVisible
        ? `${route.takeChallenge}/${props.model.key}`
        : `${route.quizResult}/${props.model.id}`
    )
  }
  const buttonClassName = `challenge-detail__confirm-button ${
    isVisible ? '' : 'button_secondary'
  }`
  return (
    <div
      className={`challenge-detail ${props.classChallenge ?? ''} ${
        isVisible ? '' : 'challenge-detail_visible'
      }`}
    >
      <div className="challenge-detail__top-content">
        {model.image ? (
          <img
            className="challenge-detail__icon"
            alt="challenge-icon"
            src={model.image}
          />
        ) : (
          <img
            className="challenge-detail__icon"
            alt="challenge-icon"
            src={challengeIcon}
          />
        )}
        <div className="challenge-detail__description-content">
          <div className="challenge-detail__condition-content">
            {isVisible ? (
              <>
                <Condition
                  title=""
                  condition={ChallengeModelFormatter.formattedDuration(model)}
                  wrapperClassName="challenge-detail__content"
                >
                  <img alt="clock" src={clock} />
                </Condition>
                <Condition
                  title="Level"
                  condition={ChallengeModelFormatter.formattedLevel(model)}
                  wrapperClassName="challenge-detail__content"
                >
                  <div className="challenge-dots">
                    <span className="challenge-dots__level challenge-dots__level_active"></span>
                    <span
                      className={`challenge-dots__level ${
                        model.level === 'Intermediate'
                          ? 'challenge-dots__level_active'
                          : ''
                      }`}
                    ></span>
                    <span className="challenge-dots__level"></span>
                  </div>
                </Condition>
              </>
            ) : (
              <>
                <Condition
                  title="Passed"
                  condition={null}
                  wrapperClassName="challenge-detail__content"
                >
                  <div className="challenge-detail__passed">
                    <img alt="clock" src={passed} /> <p>Passed</p>
                  </div>
                </Condition>
                <Condition
                  title="Passed"
                  condition={null}
                  wrapperClassName="challenge-detail__content"
                >
                  <p className="challenge-detail__passed-percent">
                    {model.percentage}%
                  </p>
                </Condition>
              </>
            )}
          </div>
        </div>
      </div>
      <h2 className="challenge-detail__title">{model.title}</h2>
      <p className="challenge-detail__description">{model.description}</p>

      <Button className={buttonClassName} onClick={onClickChallenge}>
        {isVisible ? 'Take the Challenge' : 'VIEW YOUR RESULTS'}
      </Button>
    </div>
  )
}

interface ConditionProps {
  title: string
  condition: string | null
  wrapperClassName: string
  children?: React.ReactNode
}

const Condition = (props: ConditionProps) => {
  const className = 'challenge-condition'
    .concat(' ')
    .concat(props.wrapperClassName)
  return (
    <div className={className}>
      {props.children}
      <p className="challenge-condition__title">{props.condition}</p>
    </div>
  )
}
