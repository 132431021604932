import React from 'react'
import all from '../../../../../../assets/icons/active-icons/all_img.svg'
import saved from '../../../../../../assets/icons/active-icons/saved_img.svg'
import recent from '../../../../../../assets/icons/active-icons/recent_img.svg'
import hidden from '../../../../../../assets/icons/active-icons/hidden_img.svg'
import { FilterType } from '../../../../../../models'
import './databaseGroup.scss'
import { useAppDispatch } from '../../../../../../redux/store'
import {
  defaultFilter,
  EFilterTypes,
  setFilters,
  setFilterType,
} from '../../../../../../redux/employer-reducer'
import { useStateWithDep } from '../../../../../../utils/hooks'

interface Props {
  activeItem: string
}

export const DatabaseGroup = (props: Props) => {
  const [activeItem, setActiveItem] = useStateWithDep(props.activeItem)
  const dispatch = useAppDispatch()

  const groupItems = [
    { id: 1, name: 'All', img: all, objectName: FilterType.all },
    { id: 2, name: 'Saved', img: saved, objectName: FilterType.saved },
    {
      id: 3,
      name: 'Recently viewed',
      img: recent,
      objectName: FilterType.recentlyViewed,
    },
    { id: 4, name: 'Hidden', img: hidden, objectName: FilterType.hidden },
  ]

  const onSelectItem = (objName: FilterType) => {
    if (objName !== activeItem) {
      setActiveItem(objName)
      dispatch(
        setFilters({
          ...defaultFilter,
          type: { ...defaultFilter.type, type: objName },
        })
      )
    }
  }

  return (
    <div className="db-group">
      <ul className="db-group__list employer-container">
        {groupItems.map(item => (
          <li
            key={item.id}
            className={`${
              activeItem === item.objectName && 'db-group__item_active'
            } db-group__item`}
            onClick={() => {
              onSelectItem(item.objectName)
              dispatch(setFilterType(EFilterTypes.groupFilterType))
            }}
          >
            <img src={item.img} alt={`${item.name} icon`} />
            {item.name}
          </li>
        ))}
      </ul>
    </div>
  )
}
