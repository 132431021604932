import { useEffect, useState, useRef, useCallback } from 'react'
import { useMediaContext, useTypedSelector } from '../../../../utils/hooks'
import { useHistory } from 'react-router-dom'
import * as route from '../../../../services/route'
import {
  useAppDispatch,
  setIsTopFilterVisible,
  setIsNavSearchHover,
  logout,
  setTopFilterHeight,
  defaultFilter,
  setFilters,
} from '../../../../redux'
import { useQueryClient } from 'react-query'
import { NavigatorUI } from './NavigatorUI'

interface Props {
  onboarding?: true
  landingPage?: true
}

//https://dev.to/devggaurav/let-s-build-a-responsive-navbar-and-hamburger-menu-using-html-css-and-javascript-4gci
export const Navigator = (props: Props) => {
  const queryClient = useQueryClient()
  const { onboarding, landingPage } = props
  const filterBarRef = useRef<HTMLDivElement>(null)
  const modalRef = useRef<HTMLDivElement>(null)
  const history = useHistory()
  const employerInfo = useTypedSelector(s => s.auth.user)?.employer_info
  const dispatch = useAppDispatch()
  const isAuth = useTypedSelector(state => state.auth.isAuth)
  const isEmployer = useTypedSelector(state => state.auth.isEmployer)
  const isAdminWorkers = useTypedSelector(state => state.auth.isSkillitAdmin)
  const isTopFilterVisible = useTypedSelector(
    state => state.employer.isTopFilterVisible
  )
  const isMobile = useMediaContext()
  const [isOpened, setIsOpened] = useState(false)
  const memorizedToggleMenu = useCallback(() => {
    const hamburger = document.querySelector('.hamburger')
    const navMenu = document.querySelector('.navMenuWrapper')

    hamburger?.classList.toggle('active')
    navMenu?.classList.toggle('active')
    modalRef.current?.classList.toggle('active')
    document.body.classList.toggle('no-scroll')
    setIsOpened(!isOpened)
  }, [isOpened])

  const defaultFilterSettings = useCallback(() => {
    if (isEmployer) {
      dispatch(setFilters(defaultFilter))
      dispatch(setIsTopFilterVisible(false))
      dispatch(setIsNavSearchHover(false))
    }
  }, [dispatch, isEmployer])

  const onClickLogout = useCallback(async () => {
    await dispatch(logout())
    window.analytics?.reset()
    memorizedToggleMenu()

    if (onboarding) {
      sessionStorage.clear()
    }

    history.replace(route.signIn, null)
    defaultFilterSettings()
    queryClient.clear()
  }, [
    defaultFilterSettings,
    dispatch,
    history,
    memorizedToggleMenu,
    onboarding,
    queryClient,
  ])

  const addExtraClass = useCallback(() => {
    if (!isEmployer && !isAdminWorkers) return ''
    return isEmployer ? 'header-title__employer' : 'header-title__admin-workers'
  }, [isEmployer, isAdminWorkers])

  useEffect(() => {
    if (!isMobile && isOpened) {
      memorizedToggleMenu()
    }
  }, [isMobile, isAuth, isOpened, memorizedToggleMenu])

  useEffect(() => {
    document.body.classList.remove('no-scroll')
  }, [])

  useEffect(() => {
    const unlisten = history.listen(location => {
      if (history.action === 'POP') {
        if (isOpened) memorizedToggleMenu()
      }
    })
    return () => unlisten()
  }, [history, isOpened, memorizedToggleMenu])

  useEffect(() => {
    isMobile &&
      dispatch(setIsTopFilterVisible(false)) &&
      dispatch(setIsNavSearchHover(false))
    if (filterBarRef.current && modalRef.current) {
      const filterBarHeight =
        filterBarRef.current.getBoundingClientRect().height
      dispatch(
        setTopFilterHeight(
          isTopFilterVisible && !isMobile ? filterBarHeight : 0
        )
      )
      filterBarRef.current.style.bottom =
        isTopFilterVisible && !isMobile ? `-${filterBarHeight}px` : '0'

      isTopFilterVisible
        ? document.body.classList.add('no-scroll')
        : document.body.classList.remove('no-scroll')
    }
  }, [isTopFilterVisible, isMobile, dispatch])

  return (
    <NavigatorUI
      isEmployer={isEmployer}
      isAdminWorkers={isAdminWorkers}
      isNotWorker={isEmployer || isAdminWorkers}
      isAuth={isAuth}
      defaultFilterSettings={defaultFilterSettings}
      onboarding={onboarding}
      onClickLogout={onClickLogout}
      isMobile={isMobile}
      memorizedToggleMenu={memorizedToggleMenu}
      employerInfo={employerInfo}
      modalRef={modalRef}
      filterBarRef={filterBarRef}
      addExtraClass={addExtraClass}
      landingPage={landingPage}
    />
  )
}
