import { ResultWorkExperience } from '../../../../../../models'
import { Button } from '../../../../../atoms'
import { ConfirmationUpdateModal, ModalBox } from '../../../../../molecules'
import { FormatedExpList } from '../workExperienceFuncAndVariables'
import { ModalWorkList } from './ModalWorkList'

interface IProps extends React.HTMLAttributes<HTMLDListElement> {
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  setIsChanged: React.Dispatch<React.SetStateAction<boolean>>
  isConfirmationModal: boolean
  setIsConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>
  handleSubmit: (
    e: React.FormEvent<HTMLFormElement>,
    modalWorkExp: ResultWorkExperience[]
  ) => void
  modalWorkExp: FormatedExpList[]
  onChangeInputValue: (e: string, numberJob: number, inputName: string) => void
  removeJob: (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    jobNumber: number
  ) => void
  addNewJob: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  toggleCheckbox: (id: number, isChecked: boolean) => void
}

export const WorkExperienceModalUI = (props: IProps) => {
  const {
    className,
    isConfirmationModal,
    setIsConfirmationModal,
    handleSubmit,
    modalWorkExp,
    setIsChanged,
    setIsModalVisible,
    onChangeInputValue,
    removeJob,
    addNewJob,
    toggleCheckbox,
  } = props

  return (
    <div className={`work-experience-modal ${className ?? ''}`}>
      {isConfirmationModal && (
        <ModalBox className="upload-file__modal-box">
          <ConfirmationUpdateModal
            title="Discard Changes"
            uploadText="Save Changes"
            removeText="Discard Changes"
            updateData={e => {
              handleSubmit(e, modalWorkExp)
            }}
            remove={() => {
              setIsConfirmationModal(false)
              setIsModalVisible(false)
              setIsChanged(false)
            }}
          />
        </ModalBox>
      )}

      <h2 className="work-experience-modal__title">Work Experience</h2>
      <form
        method="post"
        onSubmit={e => handleSubmit(e, modalWorkExp)}
        className="work-experience-modal__form"
      >
        <ModalWorkList
          modalWorkExp={modalWorkExp}
          onChangeInputValue={onChangeInputValue}
          removeJob={removeJob}
          toggleCheckbox={toggleCheckbox}
        />
        <Button
          className="work-experience-modal__button-added profile_white-btn profile_orange-btn"
          onClick={e => {
            addNewJob(e)
          }}
        >
          + Add Another Position
        </Button>
        <hr />
        <div className="work-experience-modal__button-wrapper">
          <Button type="submit" className="work-experience-modal__button-saved">
            SAVE CHANGES
          </Button>
        </div>
      </form>
    </div>
  )
}
