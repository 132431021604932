import { useContext } from 'react'
import { AssessmentModal, AssmntStatus } from '../../../../models/Employer'
import { ModalData } from '../../../../pages/InAppFlow'
import { SkillsAssessmentCard } from '../../../molecules'
import zipcodes from 'zipcodes'
import './assessmentScoreBlock.scss'
import { EmployeeInfoContext } from '../../../Context/EmployeeInfoContext'
import { UsaStates } from 'usa-states'

interface Props {
  modalAction: (activeModal: ModalData) => void
  statusAssmnt: AssessmentModal[]
}

export const AssessmentScoresBlock = (props: Props) => {
  const usStates = new UsaStates()
  const workerData = useContext(EmployeeInfoContext).workerData
  const avaliableAssmnt = workerData?.assessment_scores?.some(
    assmnt => assmnt.status.details !== null
  )

  return (
    <div className="assmnt-block">
      {avaliableAssmnt && (
        <div className="worker-detail-section-header">
          <h4>Assessment Scores</h4>
          {/* ///TODO this code will be needed next build */}

          {/* <Button
          onClick={() => {
            workerData?.user_card &&
              props.modalAction({
                user: workerData?.user_card,
                modalName: EmpActionType.assessment,
              })
          }}
        >
          Send Assessment
        </Button> */}
        </div>
      )}
      <div className="assmnt-block__statistic">
        {workerData?.assessment_scores?.map((block, i) => {
          if (block.status.status === AssmntStatus.passed) {
            const details = block?.status?.details
            const stateName = details?.zip_code
              ? usStates.states.filter(
                  state =>
                    state.abbreviation ===
                    zipcodes.lookup(details?.zip_code)?.state
                )
              : undefined

            return (
              <SkillsAssessmentCard
                className={i % 2 ? '' : 'assmnt-block__border'}
                key={block.key}
                score={details?.overall_score}
                titleName={block?.name}
                logo={block.company_info.logo}
                betterThan={details?.rating?.percentage ?? 0}
                peopleQuantity={details?.rating?.number ?? 0}
                place={stateName?.[0]?.name ?? ''}
                link="/"
                scoreList={details?.categories_scores}
              />
            )
          } else return undefined
        })}
      </div>
      {/* {props.statusAssmnt.length !== 0 && (
        <div className="assmnt-block__status">
          <h4>Pending Assessments</h4>
          {props.statusAssmnt.map(card => (
            <AssessmentSendingCard
              key={card.key}
              assmntInfo={card}
              sendAssmnt={{
                email: workerData?.user_card?.username!,
                key: card.key,
              }}
            />
          ))}
        </div>
      )} */}
    </div>
  )
}
