export namespace LocalStorage {
  export const isKeySetted = (key: LocalStorageKey): boolean => {
    return !!localStorage.getItem(key)
  }

  export const removeItemAtKey = (key: LocalStorageKey) => {
    localStorage.removeItem(key)
  }

  export const setItemForKey = <T>(item: T, key: LocalStorageKey) => {
    localStorage.setItem(key, JSON.stringify(item))
  }

  export const getItemForKey = <T>(key: LocalStorageKey): T | null => {
    const item = localStorage.getItem(key)
    return item == null ? null : JSON.parse(item)
  }
}
export type LocalStorageKey =
  | 'wasRegistred'
  | 'flex_profile'
  | 'assessmentScore'
