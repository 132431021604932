import React from 'react'
import './successMessageModal.scss'
import { Button, SimpleCard } from '../../../../components/atoms'
import { Link } from 'react-router-dom'
import { IntervewData, ScheduleTypeEnum, UserCard } from '../../../../models'
import moment from 'moment'
import grattersIcon from '../../../../assets/icons/checkmarks/green-notmissed-checkmark.svg'
import arrow from '../../../../assets/icons/arrows/yellow_arrowBack.svg'
import * as route from '../../../../services/route'
import { EAdminWorkersSStoreKeys } from '../../../../utils/constants'

interface Props extends React.HTMLAttributes<HTMLElement> {
  workerData: UserCard | null
  employerDashboardPage?: true
  onCloseSuccessModal?: () => void
  interviewData?: IntervewData
}

export const SuccessMessageModal = (props: Props) => {
  const {
    workerData,
    employerDashboardPage,
    onCloseSuccessModal,
    interviewData,
  } = props

  const transitionFromAdmin = JSON.parse(
    `${sessionStorage.getItem(EAdminWorkersSStoreKeys.transitionFromAdmin)}`
  )

  // const getInterviewType = () => {
  //   switch (interviewData?.typeInterview) {
  //     case ScheduleTypeEnum.phone:
  //       return `a ${interviewData.typeInterview}`
  //     case ScheduleTypeEnum.inPerson:
  //       return 'an in-person'
  //     default:
  //       return ''
  //   }
  // }

  return (
    <div className="success-message-modal">
      <h2 className="success-message-modal__title">
        {/* ///TODO change !interviewData to interviewData */}
        {!interviewData ? (
          <>
            {/* Interview with{' '} */}
            <img
              className="success-message-modal__description-gratters-icon"
              src={grattersIcon}
              alt="gratters-icon"
            />{' '}
            Invitation request received!
            {/* <strong>
              {workerData?.first_name ?? ''} {workerData?.last_name ?? ''}
            </strong> */}
          </>
        ) : (
          <>
            Make{' '}
            {
              <strong>
                {workerData?.first_name ?? ''} {workerData?.last_name ?? ''}
              </strong>
            }{' '}
            an offer
          </>
        )}
      </h2>
      <div className="success-message-modal__wrapper">
        <div className="success-message-modal__description">
          {/* <div className="success-message-modal__description-gratters">
            <img
              className="success-message-modal__description-gratters-icon"
              src={grattersIcon}
              alt="gratters-icon"
            />
            <p className="success-message-modal__description-gratters-text">
              {interviewData ? (
                <>
                  You’ve scheduled {getInterviewType()} interview with{' '}
                  <strong>
                    {workerData?.first_name ?? ''} {workerData?.last_name ?? ''}
                  </strong>
                  !
                </>
              ) : (
                <>
                  You’ve made{' '}
                  <strong>
                    {workerData?.first_name ?? ''} {workerData?.last_name ?? ''}
                  </strong>{' '}
                  an offer!
                </>
              )}
            </p>
      
          </div> */}
          <p className="success-message-modal__description-gratters-text">
            Skillit will now schedule your interview with{' '}
            <strong>
              {workerData?.first_name ?? ''} {workerData?.last_name ?? ''}.
            </strong>{' '}
          </p>
          <p className="success-message-modal__description-gratters-text">
            Please expect a calendar invitation shortly!
          </p>
          <p className="success-message-modal__description-gratters-text">
            Team Skillit
          </p>
          {!interviewData ? (
            <></>
          ) : (
            // <InterviewContent
            //   workerData={workerData}
            //   startDateInterview={interviewData?.startDateInterview}
            //   endDateInterview={interviewData?.endDateInterview}
            //   communicationData={interviewData?.communicationData ?? ''}
            //   typeInterview={interviewData?.typeInterview ?? null}
            // />
            <>
              <h4 className="success-message-modal__description-title">
                Here’s what to expect next
              </h4>
              <p className="success-message-modal__description-text">
                Vestibulum id ligula porta felis euismod semper. Donec sed odio
                dui. Fusce dapibus, tellus ac cursus commodo, tortor mauris
                condimentum nibh, ut fermentum massa justo sit amet risus. Etiam
                porta sem malesuada magna mollis.
              </p>
            </>
          )}
        </div>
        <SimpleCard
          className="success-message-modal__worker-card"
          photo={workerData?.profile_picture || null}
          name={`${workerData?.first_name || ''} ${
            workerData?.last_name || ''
          }`}
          full_role={workerData?.full_role || ''}
          zipcode={workerData?.zip_code || ''}
        />
        {employerDashboardPage ? (
          <Button
            className="success-message-modal__button"
            onClick={() => {
              if (onCloseSuccessModal) onCloseSuccessModal()
            }}
          >
            Back to Candidates
          </Button>
        ) : (
          // <Link className="success-message-modal__link" to={'/'}>
          //   <Button className="success-message-modal__button">
          //     Back to Candidates
          //   </Button>
          // </Link>
          <Link
            to={
              transitionFromAdmin ? route.adminWorkers : route.employerDashboard
            }
            className="success-message-modal__back-link"
          >
            <img src={arrow} alt="back" />
            Back to List
          </Link>
        )}
      </div>
    </div>
  )
}

interface InterviewData {
  workerData: UserCard | null
  startDateInterview?: Date
  endDateInterview?: Date
  communicationData: string
  typeInterview: ScheduleTypeEnum | null
}

export const InterviewContent = (props: InterviewData) => {
  const {
    workerData,
    startDateInterview,
    endDateInterview,
    communicationData,
    typeInterview,
  } = props
  let communicationMessage

  switch (typeInterview) {
    case ScheduleTypeEnum.phone:
      communicationMessage = (
        <>Call {workerData?.first_name ?? 'candidate'} at </>
      )
      break
    case ScheduleTypeEnum.videoCall:
      communicationMessage = <>Join the call using this link: </>
      break
    default:
      communicationMessage = ''
  }

  return (
    <>
      <h4 className="filled-data-interview__title">
        Interview with {workerData?.first_name ?? ''}{' '}
        {workerData?.last_name ?? ''}
      </h4>
      <p className="filled-data-interview__description">
        {moment(startDateInterview).format('MMMM DD, YYYY')}
      </p>
      <p className="filled-data-interview__description">
        {`${moment(startDateInterview).format('h:mm A')} - ${moment(
          endDateInterview
        ).format('h:mm A')}`}
      </p>
      <p className="filled-data-interview__description">
        {communicationMessage}
        <span className="filled-data-interview__description-color">
          {communicationData}
        </span>
      </p>
    </>
  )
}
