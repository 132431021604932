import React from 'react'
import s from './social-login.module.scss'
import facebook from '../../../../assets/icons/social/facebook.svg'

interface Props {
  items: SocialLoginType[]
  onClick: (type: SocialLoginType) => void
}

export enum SocialLoginType {
  Facebook = 'Facebook',
}

export const SocialLogin = ({ items, onClick }: Props) => {
  const renderItem = (el: SocialLoginType, onClick: () => void) => {
    const getConfig = (type: SocialLoginType) => {
      switch (type) {
        case SocialLoginType.Facebook:
          return {
            title: 'Continue with Facebook',
            icon: facebook,
            textStyle: s.facebookText,
            backgroundStyle: s.facebookContainer,
            iconStyle: s.facebookIcon,
          }
      }
    }

    const config = getConfig(el)
    return (
      <div
        className={s.itemContainer + ' ' + config.backgroundStyle}
        onClick={onClick}
        key={el}
      >
        <img
          src={config.icon}
          alt="social-icon"
          className={s.itemIcon + ' ' + config.iconStyle}
        />
        <label className={s.itemText + ' ' + config.textStyle}>
          {config.title}
        </label>
      </div>
    )
  }

  return (
    <div className={s.container}>
      {items.map((el, index) => renderItem(el, () => onClick(items[index])))}
    </div>
  )
}
