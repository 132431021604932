import { Pagination as NewPagination } from 'react-headless-pagination'
import './pagination.scss'

interface Props {
  currentPage: number
  handlePageChange: (page: number) => void
  totalPages: number
  className?: string
}

export const Pagination = (props: Props) => {
  const { currentPage, handlePageChange, totalPages, className } = props
  return (
    <NewPagination
      currentPage={currentPage}
      setCurrentPage={(page: number) => handlePageChange(page)}
      totalPages={totalPages}
      edgePageCount={1}
      middlePagesSiblingCount={1}
      className={`pagination ${className}`}
      truncableText="..."
      truncableClassName=""
    >
      <NewPagination.PrevButton
        className="pagination__btn-prev"
        data-testid="pagination__btn-prev"
      >
        &#60;
      </NewPagination.PrevButton>
      <div className="flex items-center justify-center flex-grow">
        <NewPagination.PageButton
          activeClassName="pagination__active-page"
          inactiveClassName="pagination__inactive-page"
          className="pagination__page"
        />
      </div>
      <NewPagination.NextButton
        className="pagination__btn-next"
        data-testid="pagination__btn-next"
      >
        &#62;
      </NewPagination.NextButton>
    </NewPagination>
  )
}
