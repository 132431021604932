import React, { useContext, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { EmpActionType, FilterType, UserCard } from '../../../../../../models'
import * as route from '../../../../../../services/route'
import hideIcon from '../../../../../../assets/icons/active-icons/eye-off-line.svg'
import showIcon from '../../../../../../assets/icons/active-icons/close-eye.svg'
import saveIcon from '../../../../../../assets/icons/active-icons/save-icon.svg'
import disableSave from '../../../../../../assets/icons/active-icons/unsave-icon.svg'

import {
  addToCategory,
  removeFromCategory,
} from '../../../../../../services/employerAPI'
import { EmployeesListContext } from '../../../../../Context/EmployeesListContext'
import { setFilters, useAppDispatch } from '../../../../../../redux'
import {
  useTypedSelector,
  useStateWithDep,
} from '../../../../../../utils/hooks'
import { useMutation } from 'react-query'

interface Props {
  userData: UserCard | null
  isHeaderCard?: boolean
  isHiddenUser: boolean
  setIshiddenUser: React.Dispatch<React.SetStateAction<boolean>>
  workerName: string
}

export const CardHeader = (props: Props) => {
  //TODO: move the UI part out
  const { userData, isHeaderCard, isHiddenUser, setIshiddenUser, workerName } =
    props
  const dispatch = useAppDispatch()
  const workerListContext = useContext(EmployeesListContext)
  const filters = useTypedSelector(state => state.employer.filters)
  const [isLiked, setIsLiked] = useStateWithDep<boolean>(
    userData?.is_saved ?? false
  )

  const { totalPages, counter, setCounter, refreshList, setCardLoading } =
    workerListContext

  const likedText = isLiked ? 'Saved' : 'Save'
  const likedIcon = isLiked ? disableSave : saveIcon
  const hiddenText = isHiddenUser ? 'Hidden' : 'Hide'
  const hiddenIcon = isHiddenUser ? showIcon : hideIcon

  const cardCategoryAddMutation = useMutation(
    (data: { id: number; category: string }) =>
      addToCategory(data.id, data.category)
  )
  const cardCategoryRemoveMutation = useMutation(
    (data: { id: number; category: string }) =>
      removeFromCategory(data.id, data.category)
  )

  const handlingLastCard = useCallback(() => {
    counter === 1 && setCardLoading(true)
    if (
      filters.page_number &&
      totalPages === filters.page_number + 1 &&
      counter === 1
    ) {
      dispatch(setFilters({ ...filters, page_number: filters.page_number - 1 }))
      setCardLoading(false)
    } else {
      counter === 1 && refreshList().then(() => setCardLoading(false))
    }
  }, [counter, dispatch, filters, refreshList, totalPages, setCardLoading])

  const actionHandler = useCallback(
    (category: string, id: number, addAction: boolean) => {
      const setter = (action: boolean) => {
        if (category === EmpActionType.hidden) {
          setIshiddenUser(action)
          counter && setCounter(counter - 1)
        } else setIsLiked(action)
      }
      setter(addAction)

      const mutateVariables = { id, category }
      const mutateOptions = {
        onSuccess: () => {
          if (
            filters.type.type === FilterType.hidden ||
            filters.type.type === FilterType.saved
          ) {
            refreshList()
          }
          counter && handlingLastCard()
        },
        onError: () => {
          setter(!addAction)
        },
      }

      addAction
        ? cardCategoryAddMutation.mutate(mutateVariables, mutateOptions)
        : cardCategoryRemoveMutation.mutate(mutateVariables, mutateOptions)
    },
    [
      cardCategoryAddMutation,
      filters.type.type,
      cardCategoryRemoveMutation,
      counter,
      handlingLastCard,
      setCounter,
      setIsLiked,
      setIshiddenUser,
      refreshList,
    ]
  )

  return (
    <div
      className={`worker-card__header ${
        !isHeaderCard && userData?.is_new ? 'worker-card__header-new-user' : ''
      }`}
    >
      <div
        className={`worker-card__worker-block ${
          isHeaderCard ? 'worker-card__header-photo' : ''
        }`}
      >
        <Link
          className={`worker-card__link-block  ${
            isHeaderCard ? 'worker-card__is-header' : ''
          }`}
          to={`${route.workerDetails}/${userData?.unique_url}`}
        >
          {userData?.profile_picture ? (
            <div className="worker-card__border-photo">
              <img
                className="worker-card__worker-block-photo"
                src={userData?.profile_picture}
                alt="user"
              />
            </div>
          ) : (
            <div className="worker-card__default-logo">
              {userData?.first_name[0]}
            </div>
          )}
          <h3 className="worker-card__worker-block-name">{workerName}</h3>
        </Link>
      </div>
      {!isHeaderCard && (
        <div className="worker-card__buttons-block-hidden">
          <div
            className="worker-card__button-hide_wrapper"
            onClick={() => {
              userData &&
                actionHandler(
                  EmpActionType.hidden,
                  userData.id ?? null,
                  !isHiddenUser
                )
            }}
          >
            <img
              className="worker-card__button-hide"
              src={hiddenIcon}
              alt="hide"
            />
            <p>{hiddenText}</p>
          </div>

          <img
            onClick={e => {
              e.stopPropagation()
              userData &&
                actionHandler(EmpActionType.saved, userData.id, !isLiked)
            }}
            className="worker-card__like-status"
            src={likedIcon}
            alt="like status"
          />
          <p
            className={`${
              isLiked ? 'worker-card__like-status-text-small' : ''
            }`}
            onClick={e => {
              e.stopPropagation()
              userData &&
                actionHandler(EmpActionType.saved, userData.id, !isLiked)
            }}
          >
            {likedText}
          </p>
        </div>
      )}
      {isHeaderCard && (
        <div
          className={`worker-card__buttons-block ${
            isHeaderCard && 'worker-card__buttons-block_unhovered'
          }`}
        >
          <div
            className="worker-card__buttons-block-hidden"
            onClick={() =>
              userData &&
              actionHandler(
                EmpActionType.hidden,
                userData.id ?? null,
                !isHiddenUser
              )
            }
          >
            <img
              className="worker-card__button-hide"
              src={hiddenIcon}
              alt="hide"
            />
            <p>{hiddenText}</p>
          </div>
        </div>
      )}
    </div>
  )
}
