import React from 'react'
import './progressBarInterview.scss'

interface Props {
  status: 1 | 2 | 3 | 4
}
export enum Progress {
  bio = 1,
  qualify = 2,
  interview = 3,
  offer = 4,
}

export const ProgressBarInterview = ({ status }: Props) => {
  return (
    <div className="progress-bar">
      <div
        className={`progress-bar__point progress-bar__point_bio 
          ${status >= Progress.bio && 'progress-bar__point_active'}
          ${status > Progress.bio && 'progress-bar__point_passed'}`}
      ></div>
      <div
        className={`progress-bar__dashed
          ${status > Progress.bio && 'progress-bar__dashed_active'}`}
      ></div>
      <div
        className={`progress-bar__point progress-bar__point_qualify 
          ${status >= Progress.qualify && 'progress-bar__point_active'}
          ${status > Progress.qualify && 'progress-bar__point_passed'}`}
      ></div>
      <div
        className={`progress-bar__dashed
          ${status > Progress.qualify && 'progress-bar__dashed_active'}`}
      ></div>
      <div
        className={`progress-bar__point progress-bar__point_interview
          ${status >= Progress.interview && 'progress-bar__point_active'}
          ${status > Progress.interview && 'progress-bar__point_passed'}`}
      ></div>
      <div
        className={`progress-bar__dashed
          ${status > Progress.interview && 'progress-bar__dashed_active'}`}
      ></div>
      <div
        className={`progress-bar__point progress-bar__point_offer
          ${status >= Progress.offer && 'progress-bar__point_active'}`}
      ></div>
    </div>
  )
}
