import { DailySlots, DaySlot } from '../../../models'

export function getDailySlotsArray(
  slotsArr: DaySlot[] | undefined
): DailySlots[] {
  if (!slotsArr || !slotsArr.length) return []
  let dailySlotsList = [
    {
      date: slotsArr[0].date,
      slots: [
        {
          id: slotsArr[0].slot.id,
          timezone: slotsArr[0].slot.timezone,
          starts_at: slotsArr[0].slot.starts_at,
          ends_at: slotsArr[0].slot.ends_at,
        },
      ],
    },
  ]
  slotsArr.forEach((slot, index) => {
    if (index === 0) return
    const dailySlotsListIndex = dailySlotsList.findIndex(
      day => day.date === slot.date
    )

    if (dailySlotsListIndex + 1) {
      dailySlotsList[dailySlotsListIndex].slots.push({
        id: slot.slot.id,
        timezone: slot.slot.timezone,
        starts_at: slot.slot.starts_at,
        ends_at: slot.slot.ends_at,
      })
    } else {
      dailySlotsList.push({
        date: slot.date,
        slots: [
          {
            id: slot.slot.id,
            timezone: slot.slot.timezone,
            starts_at: slot.slot.starts_at,
            ends_at: slot.slot.ends_at,
          },
        ],
      })
    }
  })

  return dailySlotsList
}

export function getDaySlotArray(
  dailySlots: DailySlots[] | undefined
): DaySlot[] {
  if (!dailySlots || !dailySlots.length) return []
  let daySlotList: DaySlot[] = []
  dailySlots.forEach(day => {
    day.slots.forEach(timeSlot => {
      daySlotList.push({
        date: day.date,
        slot: timeSlot,
      })
    })
  })
  return daySlotList
}
