import React, { useState, useMemo, useEffect, useCallback } from 'react'
import {
  ISkillsInfoWithoutPreferred,
  SkillsInfo,
  WorkPreferencesInfo,
} from '../../../../../../models/ProfileModel'
import { toast } from 'react-toastify'
import {
  getSkillsInfo,
  getWorkPreferencesInfo,
  updateSkillsInfo,
} from '../../../../../../services/workerApi/profileAPI'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  ProfileAnalyticsName,
  WorkerQueries,
} from '../../../../../../utils/constants'
import { QueryState } from 'react-query/types/core/query'
import { onTrackingActions } from '../../../../../../utils/scripts'
import { useTypedSelector } from '../../../../../../utils/hooks'
import { BasicSkillsUI } from './BasicSkillsUI'
import { skillsSorting } from '../../../../../../utils/scripts/general'
import { useParams } from 'react-router-dom'

interface Props extends React.HTMLAttributes<HTMLElement> {}

export const BasicSkills = (props: Props) => {
  const choiceLists = useTypedSelector(s => s.variables)
  const queryClient = useQueryClient()
  const { modal } = useParams<{ modal: string }>()
  const isOpenModalUrl = modal === 'skills'
  const defaultSkillsInfo: SkillsInfo = useMemo(
    () => ({
      eligible_work_us: null,
      interested_in_upskilling: null,
      email_me: false,
      training_certifications: [],
      skills: [],
      unions: [],
      language: null,
      preferred_language: null,
    }),
    []
  )

  const [skillsInfoState, setSkillsInfoState] =
    useState<SkillsInfo>(defaultSkillsInfo)
  const [skillsInfoForm, setSkillsInfoForm] =
    useState<SkillsInfo>(defaultSkillsInfo)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(
    isOpenModalUrl ?? false
  )
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false)

  const isSkillsInfoChanged = useMemo(
    () => JSON.stringify(skillsInfoState) !== JSON.stringify(skillsInfoForm),
    [skillsInfoState, skillsInfoForm]
  )

  const sortedSkills = useMemo(() => {
    return [...skillsInfoState.skills.sort(skillsSorting)]
  }, [skillsInfoState?.skills])

  const skillsInfoQuery:
    | QueryState<ISkillsInfoWithoutPreferred, undefined>
    | undefined = queryClient.getQueryState(WorkerQueries.skillsInfo)

  const {
    data: skillsInfo,
    isLoading,
  }: {
    data: ISkillsInfoWithoutPreferred | undefined
    isLoading: boolean
  } = useQuery(WorkerQueries.skillsInfo, () => getSkillsInfo(), {
    enabled: !skillsInfoQuery?.data,
  })

  const skillsInfoMutation = useMutation(
    (data: SkillsInfo) => updateSkillsInfo(data),
    {
      onSuccess: (newData: SkillsInfo) => {
        queryClient.setQueryData(WorkerQueries.skillsInfo, newData)
      },
    }
  )

  const workPrefQuery: QueryState<WorkPreferencesInfo, undefined> | undefined =
    queryClient.getQueryState(WorkerQueries.workPreferencesInfo)

  const { data: workPrefInfo } = useQuery(
    WorkerQueries.workPreferencesInfo,
    () => getWorkPreferencesInfo(),
    {
      enabled: !workPrefQuery?.data,
    }
  )
  const listForPreferredLang = useMemo(() => {
    return (
      skillsInfoForm?.language &&
      skillsInfoForm?.language.map(lang => {
        return {
          id: new Date().getTime() + Math.random(),
          value: lang,
        }
      })
    )
  }, [skillsInfoForm?.language])

  const onChangeEmailMe = (id: number, isChecked: boolean) => {
    skillsInfoMutation.mutate(
      { ...skillsInfoState, email_me: !isChecked },
      {
        onSuccess: () => {
          setSkillsInfoState(prev => prev && { ...prev, email_me: !isChecked })
        },
        onError: err => {
          if (err instanceof Error) {
            toast.error(err.message)
          }
        },
      }
    )
  }

  const showSkillsList = useCallback((skillsList: string[]) => {
    if (!skillsList.length)
      return <p className="basic-skills__skill-answers">None provided</p>
    return skillsList.map(el => {
      return (
        <p key={el} className="basic-skills__skill-answers">
          {el}
        </p>
      )
    })
  }, [])

  const showLanguagesList = useCallback(
    (list: string[] | null) => {
      if (!list?.length)
        return <p className="basic-skills__skill-answers">None provided</p>

      const preparedData = list.map(el =>
        skillsInfo?.language?.find(
          elem => elem.choice === el && elem.is_preferred
        )
          ? `${el} (Preferred)`
          : el
      )
      const preferredLang = preparedData.find(el => el.includes('(Preferred)'))
      const sortedList = preferredLang
        ? [preferredLang].concat(
            preparedData.filter(el => el !== preferredLang)
          )
        : preparedData

      return sortedList.map(el => {
        return (
          <p key={el} className="basic-skills__skill-answers">
            {el}
          </p>
        )
      })
    },
    [skillsInfo?.language]
  )

  const checkSkillFilled = useCallback((data: SkillsInfo): boolean => {
    let errorText: string | null = null

    if (!data.skills.length) {
      errorText = 'Please select at least one of the skills listed'
    }

    if (data.skills.some(skill => !skill.level)) {
      errorText =
        'Please specify the number of years of experience you have in the selected skills'
    }

    if (!data.language?.length) {
      errorText = 'Please select at least one of the languages listed'
    }

    if (
      data.language?.length &&
      data.language.length > 1 &&
      !data.preferred_language
    ) {
      errorText = 'Please select one of preferred languages'
    }

    errorText && toast.error(errorText)
    return !!errorText
  }, [])

  const handleSubmit = useCallback(
    (newData: SkillsInfo) => {
      if (!isSkillsInfoChanged) {
        setIsModalVisible(false)
        return
      }

      if (checkSkillFilled(newData)) return

      const preparedData: SkillsInfo = {
        ...newData,
        language:
          newData.language?.length === 1
            ? []
            : newData.language?.filter(
                lang => lang !== newData.preferred_language
              ) ?? newData.language,
        preferred_language:
          newData.language?.length === 1
            ? newData.language[0]
            : newData.preferred_language,
      }
      skillsInfoMutation.mutate(preparedData, {
        onSuccess: () => {
          setIsModalVisible(false)
          setSkillsInfoState(newData)
          onTrackingActions(ProfileAnalyticsName.profile_edited)
        },
        onError: err => {
          if (err instanceof Error) {
            toast.error(err.message)
          }
        },
      })
    },
    [checkSkillFilled, skillsInfoMutation, isSkillsInfoChanged]
  )

  useEffect(() => {
    if (skillsInfo) {
      const languages = skillsInfo.language?.map(elem => elem.choice)
      const preferredLanguage = skillsInfo.language?.find(
        el => el.is_preferred
      )?.choice
      const preparedData: SkillsInfo = {
        ...skillsInfo,
        language: languages ?? null,
        preferred_language: preferredLanguage ?? null,
      }
      setSkillsInfoState(preparedData)
      setSkillsInfoForm(preparedData)
    }
  }, [skillsInfo])

  return (
    <BasicSkillsUI
      {...props}
      isSaving={skillsInfoMutation.isLoading}
      isModalVisible={isModalVisible}
      isSkillsInfoChanged={isSkillsInfoChanged}
      setIsConfirmationModalVisible={setIsConfirmationModalVisible}
      setIsModalVisible={setIsModalVisible}
      skillsInfoForm={skillsInfoForm}
      setSkillsInfoForm={setSkillsInfoForm}
      handleSubmit={handleSubmit}
      isConfirmationModalVisible={isConfirmationModalVisible}
      skillsInfoState={skillsInfoState}
      choiceLists={choiceLists}
      isLoading={isLoading}
      onChangeEmailMe={onChangeEmailMe}
      showSkillsList={showSkillsList}
      roles={workPrefInfo?.roles ?? []}
      sortedSkills={sortedSkills}
      showLanguagesList={showLanguagesList}
      listForPreferredLang={listForPreferredLang}
    />
  )
}
