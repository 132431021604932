import { useMemo, useRef } from 'react'
import { IWorkerSkill } from '../../../../models'
import { ExperienceChartUI } from './SkillsChartUI'
import { experienceFormatting } from '../../../../utils/scripts'

interface IProp {
  title: string
  data: IWorkerSkill[]
}

export const SkillsChart = ({ title, data }: IProp) => {
  const axisLabelRef = useRef<HTMLDivElement>(null)
  const axisColumnRef = useRef<HTMLDivElement>(null)
  const axisLabelWidth = (axisLabelRef.current?.lastChild as HTMLDivElement)
    ?.offsetWidth
  const axisColumnWidth = (axisColumnRef.current?.lastChild as HTMLDivElement)
    ?.offsetWidth
  const isLongLabel = axisColumnWidth <= axisLabelWidth

  const sortedSkills = useMemo(
    () =>
      data
        .filter(el => el.level && el.value)
        .sort((el1, el2) => (el2.level ?? 0) - (el1.level ?? 0)),
    [data]
  )

  const maxLevel = useMemo(() => sortedSkills[0]?.level ?? 0, [sortedSkills])

  const axisLabels: string[] = useMemo(
    () =>
      Array(maxLevel)
        .fill('-')
        .map((_, i) => {
          return isLongLabel ? `${i + 1}` : experienceFormatting(i + 1)
        }),
    [maxLevel, isLongLabel]
  )

  const titleWithYear = useMemo(
    () => `${title} (${experienceFormatting(maxLevel)})`,
    [maxLevel, title]
  )

  return (
    <ExperienceChartUI
      titleWithYear={titleWithYear}
      maxLevel={maxLevel}
      sortedSkills={sortedSkills}
      axisLabels={axisLabels}
      axisLabelRef={axisLabelRef}
      axisColumnRef={axisColumnRef}
      isLongLabel={isLongLabel}
    />
  )
}
