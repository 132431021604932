import { ICertificationGroup } from '../../../../../../models'

export const preparingSelectedCertList = (
  selectedData: string[],
  choices: ICertificationGroup[]
): ICertificationGroup[] => {
  const selectedGroups: { [group: string]: string[] } = {}

  selectedData.forEach(selectedValue => {
    choices.forEach(groupData => {
      if (groupData.certifications.includes(selectedValue)) {
        if (!selectedGroups[groupData.group]) {
          selectedGroups[groupData.group] = []
        }
        selectedGroups[groupData.group].push(selectedValue)
      }
    })
  })

  const transformedData: ICertificationGroup[] = Object.keys(
    selectedGroups
  ).map(group => ({
    group,
    certifications: selectedGroups[group],
  }))

  return transformedData
}
