import { ZipCode } from 'use-places-autocomplete'
import { ILocationInfoOB, IHomeLocationOB } from '../../../../models'

export const addGoogleLocation = (
  prevLocation: ILocationInfoOB,
  initSuggestion: google.maps.places.AutocompletePrediction,
  initZipCode: ZipCode,
  initLat: number,
  initLng: number
) => {
  const preferredLocationsList: IHomeLocationOB[] = prevLocation
    .preferred_locations?.length
    ? [...prevLocation.preferred_locations]
    : []
  preferredLocationsList.push({
    state: initSuggestion.terms[initSuggestion.terms.length - 2].value,
    city: initSuggestion.terms[initSuggestion.terms.length - 3]
      ? initSuggestion.terms[initSuggestion.terms.length - 3].value
      : null,
    zip_code: initZipCode ?? '',
    latitude: initLat,
    longitude: initLng,
    street: null,
    raw_input: null,
  })
  return {
    ...prevLocation,
    preferred_locations: preferredLocationsList,
  }
}
