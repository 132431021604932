import './bannerVertical.scss'

interface Props extends React.HTMLAttributes<HTMLElement> {
  bannerImage: string
}

export const BannerVertical = (props: Props) => {
  const { bannerImage } = props
  return (
    <div className="banner-vertical">
      <div
        className="banner-vertical__banner"
        style={{ backgroundImage: `url(${bannerImage})` }}
      ></div>
      <div className="banner-vertical__content">{props.children}</div>
    </div>
  )
}
