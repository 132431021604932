import { createContext } from 'react'
import {
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult,
} from 'react-query'
import { WorkerInfo } from '../../models/Employer'

interface Props {
  workerData: WorkerInfo | null
  action: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<WorkerInfo, unknown>>
}

export const EmployeeInfoContext = createContext<Props>({
  workerData: null,
  action: () => new Promise((res, _rej) => res),
})
