import React, { useEffect } from 'react'
import { EFields } from '../../../../../models'
import { SearchInputState } from '../../../../organism'
import { SearchListWorkers } from '../SearchListWorkers'
import './searchInput.scss'
import { SearchItemList } from './SearchItemList/SearchItemList'
import { useSearchInput } from './useSearchInput'

export interface SearchInputData {
  icon: string
  name: EFields
  placeholder: string
}

interface Props extends React.HTMLAttributes<HTMLElement> {
  inputData: SearchInputData
  separator?: true
  getListFromSearch: (value: string) => Promise<any>
  isMultiple?: true
  iType: EFields
  setSelectedValue: React.Dispatch<React.SetStateAction<SearchInputState>>
  selectedValue: SearchInputState
  clearedInput: boolean
  activeField?: EFields
  setActiveField: React.Dispatch<React.SetStateAction<EFields | undefined>>
}

export const SearchInput = (props: Props) => {
  const {
    inputData,
    separator,
    getListFromSearch,
    isMultiple,
    iType,
    setSelectedValue,
    selectedValue,
    clearedInput,
    activeField,
    setActiveField,
  } = props

  const {
    value,
    setValue,
    instantSearchList,
    setInstantSearchList,
    setFocusNext,
    inputRef,
    handlingValue,
  } = useSearchInput({
    iType,
    selectedValue,
    getListFromSearch,
    setSelectedValue,
  })

  const onSelectValue = (value: string) => {
    if (isMultiple) {
      !selectedValue.selected.includes(value) &&
        setSelectedValue(prev => ({
          value: '',
          selected: [...prev.selected, value],
        }))
      setValue(undefined)
    } else {
      setSelectedValue({ value: '', selected: [value] })
      setValue(undefined)
    }
    setInstantSearchList(null)
  }

  useEffect(() => {
    setFocusNext()
  }, [setFocusNext, selectedValue.selected])

  useEffect(() => {
    if (clearedInput) setValue(undefined)
  }, [clearedInput, setValue])

  return (
    <div
      onFocus={props.onFocus}
      ref={inputRef}
      className={`search-input ${props.className ?? ''}`}
    >
      <div className="search-input__wrapper">
        <img
          className="search-input__icon"
          src={inputData.icon}
          alt={inputData.name}
        />

        <SearchItemList
          setSelectedElement={setSelectedValue}
          selecedElem={selectedValue.selected}
          inputData={inputData}
          handlingValue={handlingValue}
          value={value}
          isMultiple={!!isMultiple}
          setValue={setValue}
          activeField={activeField}
          iType={iType}
        />

        {separator && <div className="search-input__separator"></div>}
      </div>

      {activeField === iType && value && value.length && instantSearchList && (
        <SearchListWorkers
          className={
            selectedValue.selected.length > 2
              ? 'search-input__aditional-margin'
              : ''
          }
          instantSearchList={instantSearchList}
          value={value}
          setValue={setValue}
          onSelectValue={onSelectValue}
          iType={iType}
          setActiveField={setActiveField}
        />
      )}
    </div>
  )
}
