import React, { useEffect, useRef, useState } from 'react'
import { EFields } from '../../../../../../models'
import { SearchItem } from '../../../../../atoms/SearchItem'
import { SearchInputState } from '../../../../../organism'
import { SearchInputData } from '../SearchInput'
import './searchItemList.scss'

interface Props {
  selecedElem: string[]
  setSelectedElement: React.Dispatch<React.SetStateAction<SearchInputState>>
  inputData: SearchInputData
  value: string | undefined
  handlingValue: (e: React.ChangeEvent<HTMLInputElement>) => void
  isMultiple: boolean
  setValue: React.Dispatch<React.SetStateAction<string | undefined>>
  activeField?: EFields
  iType: EFields
}

export const SearchItemList = (props: Props) => {
  const {
    selecedElem,
    setSelectedElement,
    inputData,
    handlingValue,
    value,
    isMultiple,
    setValue,
    activeField,
    iType,
  } = props

  const isSelected = isMultiple && !!selecedElem.length
  const [isShowedInput, setShowedInput] = useState(true)
  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const leftShadowRef = useRef<HTMLDivElement>(null)
  const rightShadowRef = useRef<HTMLDivElement>(null)
  const elemLength = selecedElem.length

  const showInput = (e: React.SyntheticEvent) => {
    if (e.target instanceof Element) {
      if (e.target.className !== 'search-item__close') toggleInput()
    }
    setTimeout(() => inputRef.current?.focus())
  }

  const toggleInput = () => {
    if (elemLength > 2 && isShowedInput) {
      setShowedInput(false)
      setValue(undefined)
    } else setShowedInput(true)
  }

  const wheelScroll = (e: React.WheelEvent<HTMLDivElement>) => {
    if (containerRef.current && elemLength > 2) {
      containerRef.current.scrollLeft += e.deltaY / 7

      if (containerRef.current.scrollLeft > 0) {
        leftShadowRef.current!.style.display = 'block'
      } else {
        leftShadowRef.current!.style.display = 'none'
      }
      if (
        containerRef.current.scrollLeft >=
        containerRef.current.scrollWidth - containerRef.current.offsetWidth
      ) {
        rightShadowRef.current!.style.display = 'none'
      } else {
        rightShadowRef.current!.style.display = 'block'
      }
    }
  }

  useEffect(() => {
    setTimeout(() => inputRef.current?.focus())
    elemLength <= 2 && setShowedInput(true)
  }, [elemLength])

  useEffect(() => {
    if (elemLength > 2) {
      rightShadowRef.current!.style.display = 'none'
      leftShadowRef.current!.style.display = 'block'
      containerRef.current &&
        containerRef.current?.scrollTo({
          left: containerRef.current.offsetWidth,
          behavior: 'smooth',
        })
    }
  }, [elemLength])

  useEffect(() => {
    iType !== activeField && elemLength > 2 && setShowedInput(false)
  }, [activeField, elemLength, iType])

  return (
    <div className="s-item-list">
      <div
        ref={containerRef}
        onWheel={wheelScroll}
        onClick={showInput}
        className="s-item-list__selected-items"
      >
        {selecedElem.map(item => (
          ///TODO add valid key
          <SearchItem
            key={item}
            setSelectedElement={setSelectedElement}
            item={item}
          />
        ))}
        {elemLength > 2 && (
          <>
            <div
              ref={rightShadowRef}
              className={`s-item-list__right__shadow`}
            ></div>
            <div
              ref={leftShadowRef}
              className={`s-item-list__left__shadow`}
            ></div>
          </>
        )}
      </div>

      {(!selecedElem.length || isSelected) && isShowedInput && (
        <input
          ref={inputRef}
          tabIndex={0}
          type="text"
          name={inputData.name}
          value={value ?? ''}
          onChange={handlingValue}
          className={`search-input__input ${
            elemLength > 2 ? 'search-input__input_padding' : ''
          } `}
          autoComplete="off"
          placeholder={
            !selecedElem.length
              ? inputData.placeholder
              : `+ add one or more ${inputData.name}...`
          }
        />
      )}
    </div>
  )
}
