import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { UseMutationResult, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { Config } from '../../../../../../../components/organism'
import { DrivingInfo } from '../../../../../../../models'
import {
  ProfileAnalyticsName,
  WorkerQueries,
} from '../../../../../../../utils/constants'
import { onTrackingActions } from '../../../../../../../utils/scripts'
import { DrivingStatusUI } from './DrivingStatusUI'
import { useParams } from 'react-router-dom'

interface Props extends React.HTMLAttributes<HTMLElement> {
  drivingInfo?: DrivingInfo
  drivingInfoMutation: UseMutationResult<
    DrivingInfo,
    unknown,
    {
      formData: FormData
      config?: Config
    },
    unknown
  >
}

export const DrivingStatus = (props: Props) => {
  const { modal } = useParams<{ modal: string }>()
  const isOpenModalUrl = modal === 'driver-license'

  const { drivingInfo, drivingInfoMutation } = props
  const queryClient = useQueryClient()
  const defaultDrivingInfo: DrivingInfo = useMemo(
    () => ({
      vehicle: null,
      valid_drivers_license: null,
      drivers_license: null,
    }),
    []
  )

  const [isModalVisible, setIsModalVisible] = useState(isOpenModalUrl ?? false)
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false)
  const [drivingInfoState, setDrivingInfoState] =
    useState<DrivingInfo>(defaultDrivingInfo)
  const [drivingInfoForm, setDrivingInfoForm] =
    useState<DrivingInfo>(defaultDrivingInfo)
  const isDrivingInfoChanged = useMemo(
    () => JSON.stringify(drivingInfoState) !== JSON.stringify(drivingInfoForm),
    [drivingInfoState, drivingInfoForm]
  )

  const handleSubmit = useCallback(() => {
    const formData = new FormData()
    formData.append('vehicle', JSON.stringify(drivingInfoForm.vehicle))
    formData.append(
      'valid_drivers_license',
      JSON.stringify(drivingInfoForm.valid_drivers_license)
    )
    drivingInfoMutation.mutate(
      { formData: formData },
      {
        onSuccess: (newData: DrivingInfo) => {
          setIsModalVisible(false)
          queryClient.setQueryData(WorkerQueries.drivingInfo, newData)
        },
        onError: err => {
          if (err instanceof Error) toast.error(err.message)
        },
      }
    )
    setDrivingInfoState(drivingInfoForm)
    onTrackingActions(ProfileAnalyticsName.profile_edited)
  }, [drivingInfoForm, drivingInfoMutation, queryClient])

  useEffect(() => {
    if (drivingInfo) {
      setDrivingInfoState(drivingInfo)
      setDrivingInfoForm(drivingInfo)
    }
  }, [drivingInfo])

  return (
    <DrivingStatusUI
      isSaving={drivingInfoMutation.isLoading}
      isModalVisible={isModalVisible}
      isDrivingInfoChanged={isDrivingInfoChanged}
      setIsConfirmationModalVisible={setIsConfirmationModalVisible}
      setIsModalVisible={setIsModalVisible}
      handleSubmit={handleSubmit}
      setDrivingInfoForm={setDrivingInfoForm}
      drivingInfoForm={drivingInfoForm}
      isConfirmationModalVisible={isConfirmationModalVisible}
      drivingInfoState={drivingInfoState}
    />
  )
}
