import { WorkPreferencesInfo } from '../../../../../../models'
import { RoleNames } from '../../../../../../utils/constants'
import { getPayMinValueTitle } from '../../../../../../utils/scripts'

export const hourlyPayList = new Array(100).fill(null).map((_, index) => ({
  id: index,
  element: `$${index}`,
}))

export const workPreference: string[] = [
  'Temp',
  'Permanent',
  'Temp to Permanent',
  'Full Time',
  'Part Time',
]
export const idealEmployer: string[] = [
  'Good Benefits',
  'Women Friendly',
  'Commercial',
  'Residential',
  'Career Mobility',
  'Training and Upskilling',
]

export const getObjWorkPref = (workPref: WorkPreferencesInfo) => {
  return {
    most_recent_pay_rate: workPref?.most_recent_pay_rate
      ? {
          id: hourlyPayList.findIndex(
            pay => pay.element === workPref.most_recent_pay_rate
          ),
          element: getPayMinValueTitle(
            workPref.most_recent_pay_rate.substring(1)
          ),
        }
      : null,
    desired_pay_rate: workPref?.desired_pay_rate
      ? {
          id: hourlyPayList.findIndex(
            pay => pay.element === workPref.desired_pay_rate
          ),
          element: getPayMinValueTitle(workPref.desired_pay_rate.substring(1)),
        }
      : null,
    work_preferences: workPref?.work_preferences.length
      ? workPref?.work_preferences
      : [],
    ideal_employer: workPref?.ideal_employer.length
      ? workPref?.ideal_employer
      : [],
    roles: workPref?.roles?.length ? workPref?.roles : [],
    other_role: workPref?.other_role ? workPref?.other_role : null,
  }
}

export const getCheckedRole = (
  checkboxName: string,
  roleList: string[] | null,
  otherRole?: string | null
): true | undefined => {
  if (!roleList || (checkboxName === RoleNames.other_trade && !otherRole)) {
    return undefined
  }

  return roleList.find(el => el === checkboxName) ? true : undefined
}
