import React, { useCallback, useRef, useState } from 'react'
import {
  WorkerInInstantSearch,
  EFields,
  FilterItem,
} from '../../../../../models'
import { SearchInputState } from '../../../../organism'

interface Props {
  iType: EFields
  selectedValue: SearchInputState
  getListFromSearch: (value: string) => Promise<any>
  setSelectedValue: React.Dispatch<React.SetStateAction<SearchInputState>>
}

export const useSearchInput = (props: Props) => {
  const [value, setValue] = useState<string>()
  const [instantSearchList, setInstantSearchList] =
    useState<WorkerInInstantSearch | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const getListFromTypes = async (initValue: string) => {
    return await props.getListFromSearch(initValue).then(res => {
      if (props.iType === EFields.role) return res
      return props.iType === EFields.location
        ? { location: res }
        : { skills: filteredList(res) }
    })
  }

  const handlingValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    props.setSelectedValue(prev => ({ ...prev, value: e.target.value }))

    if (!e.target.value) {
      setInstantSearchList(null)
    } else {
      getListFromTypes(e.target.value).then(res => setInstantSearchList(res))
    }
  }

  const setFocusNext = useCallback(() => {
    props.iType === EFields.role &&
      inputRef.current?.nextElementSibling?.querySelector('input')?.focus()
    props.iType === EFields.location &&
      inputRef.current?.nextElementSibling?.querySelector('input')?.focus()
  }, [props.iType])

  const filteredList = (suggestions: string[] | FilterItem[] | undefined) => {
    if (props.iType === EFields.skills) {
      return (suggestions as FilterItem[])?.filter(
        item => !props.selectedValue.selected.includes(item.choice)
      )
    } else {
      return (suggestions as string[])?.filter(
        item => !props.selectedValue.selected.includes(item)
      )
    }
  }

  return {
    value,
    setValue,
    instantSearchList,
    setInstantSearchList,
    getListFromTypes,
    handlingValue,
    setFocusNext,
    inputRef,
  }
}
