import { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import clearingBin from '../../../../../assets/icons/other/clearing-bin-icon.svg'
import lastSearch from '../../../../../assets/icons/other/lastSearch.svg'
import { IFilterParams, RecentSearches } from '../../../../../models'
import { useAppDispatch, setIsNavSearchHover } from '../../../../../redux'
import * as route from '../../../../../services/route'
import zipcodes from 'zipcodes'
import './lastSearch.scss'

interface Props {
  lastSearch: RecentSearches[]
  onSubmitFromRecent: (dataHistory: RecentSearches) => void
  parentHeight: number
  isEmpty: boolean
  setIsEmpty: React.Dispatch<React.SetStateAction<boolean>>
  onClearSearchHistory: (event: SyntheticEvent) => void
  filterParams?: IFilterParams
}

export const LastSearch = (props: Props) => {
  const { parentHeight, filterParams } = props
  const lastSearchRef = useRef<HTMLUListElement>(null)
  const dispatch = useAppDispatch()
  const [wrapperHeight, setWrapperHeight] = useState(0)

  useEffect(() => {
    setWrapperHeight(lastSearchRef.current?.offsetHeight ?? 0)
  }, [])

  return (
    <div
      className="last-search_wrapper"
      style={{
        height: `${wrapperHeight + parentHeight + 10}px`,
      }}
      onMouseLeave={() => dispatch(setIsNavSearchHover(false))}
    >
      <ul ref={lastSearchRef} className="last-search" style={{ top: '40px' }}>
        <div className="last-search__title">Recent searches</div>
        {!props.isEmpty && props.lastSearch.length ? (
          <>
            {props.lastSearch?.map((worker, i) => {
              const workerZipCode = worker.zip_code && worker.zip_code[0]
              const cityName = workerZipCode
                ? zipcodes.lookup(workerZipCode)?.city
                : null

              const location =
                workerZipCode && cityName
                  ? `${zipcodes.lookup(workerZipCode)?.city}, ${
                      zipcodes.lookup(workerZipCode)?.state
                    }`
                  : workerZipCode

              const skills = worker.skills
                ?.map(
                  skill =>
                    filterParams?.skills?.find(
                      skillFromFilters => skillFromFilters.id === skill.value
                    )?.choice
                )
                .join(', ')

              return worker.type === 'instant' ? (
                <Link
                  key={worker.searched_user}
                  onClick={event => {
                    event.stopPropagation()
                    dispatch(setIsNavSearchHover(false))
                  }}
                  to={`${route.workerDetails}/${worker.unique_url}?instant_search`}
                >
                  <li id="last-search__item" className="last-search__item">
                    <img
                      src={lastSearch}
                      alt={`recently searched ${worker.full_name}`}
                    />
                    {worker.full_name}
                  </li>
                </Link>
              ) : worker.type === 'regular' ? (
                <li
                  key={uuidv4()}
                  onClick={event => {
                    event.stopPropagation()
                    props.onSubmitFromRecent(worker)
                    dispatch(setIsNavSearchHover(false))
                  }}
                  id="last-search__item"
                  className="last-search__item"
                >
                  <img
                    src={lastSearch}
                    alt={`recently searched ${worker.search_string}`}
                  />
                  {[
                    worker.search_string ?? '',
                    worker.roles?.map(
                      role =>
                        filterParams?.roles?.find(
                          roleFromFilters => roleFromFilters.id === role
                        )?.choice
                    ) ?? '',
                    location ?? '',
                    skills ?? '',
                  ]
                    .filter(el => !!el.length)
                    .join('-')}
                </li>
              ) : (
                <li
                  key={uuidv4()}
                  onClick={event => {
                    event.stopPropagation()
                    worker.search_string && props.onSubmitFromRecent(worker)
                    dispatch(setIsNavSearchHover(false))
                  }}
                  className="last-search__item"
                >
                  <img
                    src={lastSearch}
                    alt={`recently searched ${worker.search_string}`}
                  />
                  {[worker.search_string ?? '', location ?? '', skills ?? '']
                    .filter(el => !!el.length)
                    .join('-')}
                </li>
              )
            })}
            <div
              className="last-search__clearing"
              onClick={event => props.onClearSearchHistory(event)}
            >
              <img src={clearingBin} alt={`clearing bin`} />
              Clear search history
            </div>
          </>
        ) : (
          <p className="last-search__empty">Search history is empty...</p>
        )}
      </ul>
    </div>
  )
}
