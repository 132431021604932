export class StepModel {
  title: string
  state: StepState

  constructor(title: string, state: StepState) {
    this.state = state
    this.title = title
  }
}

export enum StepState {
  completed,
  current,
  todo,
}
