import React, { useMemo } from 'react'
import './workerCard.scss'
import { Link } from 'react-router-dom'
import * as route from '../../../../../services/route'
import { UserCard } from '../../../../../models/Employer'
import { ModalData } from '../../../../../pages/InAppFlow'
import { ListOfPassed } from './WorkerCardComponets/ListOfPassed'
import { ScoresBlocks } from './WorkerCardComponets/ScoresBlocks'
import { CardHeader } from './WorkerCardComponets/CardHeader'
import { useStateWithDep } from '../../../../../utils/hooks'
import { Button } from '../../../../atoms'
import calendarIcon from '../../../../../assets/icons/employer/calendar-icon.svg'
import checkmarkViewed from '../../../../../assets/icons/checkmarks/checkmark-blue.svg'
import moment from 'moment'
import 'moment-timezone'
import { EmpActionType } from '../../../../../models'
import { capitalizeFirstLetter } from '../../../../../utils/scripts/capitalizeFirstLetter/capitalizeFirstLetter'
import { interviewStatus } from '../../../../../utils/constants'

interface Props extends React.HTMLAttributes<HTMLElement> {
  isHeaderCard?: true
  userData: UserCard
  modalAction: (activeModal: ModalData) => void
  isHideFilterPressed: boolean
  invitedRequests: number[]
  showHiddenUsers?: boolean
}

export const WorkerCard = (props: Props) => {
  const {
    userData,
    isHeaderCard,
    isHideFilterPressed,
    invitedRequests,
    showHiddenUsers,
  } = props
  const experience = userData?.experience && +userData?.experience
  const [isHiddenUser, setIshiddenUser] = useStateWithDep<boolean>(
    userData?.is_hidden ?? false
  )
  const currentDate = moment().format('MMMM Do, YYYY')
  const isInvited = invitedRequests?.some(id => id === userData.id)
  const isShouldCardShowed = (): 'none' | 'block' | 'flex' => {
    if (
      (!isHideFilterPressed && !isHiddenUser) ||
      (isHideFilterPressed && isHiddenUser) ||
      isHeaderCard ||
      showHiddenUsers
    )
      return isHeaderCard ? 'block' : 'flex'
    return 'none'
  }
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const timeZoneAbr = moment.tz(userTimeZone).zoneAbbr()
  const additionalInterviewData = useMemo(() => {
    switch (userData.interview_status_info?.status) {
      case interviewStatus.scheduled:
      case interviewStatus.rescheduled:
        return {
          preText: 'for',
          timeZoneAbr: timeZoneAbr,
        }
    }
    return {
      preText: 'on',
      timeZoneAbr: '',
    }
  }, [userData.interview_status_info?.status, timeZoneAbr])

  const isInterviewData =
    userData.interview_status_info?.date &&
    userData.interview_status_info?.status &&
    userData.interview_status_info?.requested_by?.first_name &&
    userData.interview_status_info?.requested_by?.last_name
  const fullName = `${userData?.first_name ?? ''} ${userData?.last_name ?? ''}`
  const isLongFullName = `${fullName}`.length > 25
  const workerName =
    !isHeaderCard && isLongFullName ? `${fullName.slice(0, 25)}...` : fullName

  const isDisabletInterviewButton = useMemo(() => {
    const interviewStatusInfo = userData?.interview_status_info

    if (interviewStatusInfo === null) return false

    if (!isInterviewData) return true

    return false
  }, [userData.interview_status_info, isInterviewData])

  const prepInterviewStatus = (status: string): string => {
    switch (status) {
      case 'no_showed':
        return 'No Showed'
      case 'unreachable':
        return 'Worker unreachable'
      default:
        return `Interview ${capitalizeFirstLetter(
          userData?.interview_status_info?.status
        )}`
    }
  }
  return (
    <div
      style={{ display: isShouldCardShowed() }}
      className={`worker-card  ${props.className} ${
        !isHeaderCard ? 'worker-card__rounded' : ''
      }`}
    >
      <div className="worker-card__rounded-wrapper">
        {!isHeaderCard && !userData.is_recently_viewed && userData?.is_new && (
          <div className="worker-card__new-user-wrapper">
            <strong className="worker-card__new-user">NEW!</strong>
          </div>
        )}

        {!isHeaderCard && userData.is_recently_viewed && (
          <div className="worker-card__viewed-user-wrapper">
            <img
              className="worker-card__viewed-user-icon"
              src={checkmarkViewed}
              alt="checkmark-viewed"
            />
            <strong className="worker-card__viewed-user">
              Recently Viewed
            </strong>
          </div>
        )}

        <div className="worker-card__secondary-wrapper">
          <CardHeader
            userData={userData}
            isHeaderCard={isHeaderCard}
            isHiddenUser={isHiddenUser}
            setIshiddenUser={setIshiddenUser}
            workerName={workerName}
          />
          <Link
            className={isHeaderCard ? 'worker-card__is-header' : ''}
            to={`${route.workerDetails}/${userData.unique_url}`}
          >
            <div className="worker-card__info">
              <ScoresBlocks
                isHeaderCard={isHeaderCard}
                experience={experience}
                userData={userData}
              />
              {isHeaderCard && <ListOfPassed userData={userData} />}
            </div>
          </Link>
        </div>

        {!isHeaderCard && (
          <div className="worker-card__footer-wrapper">
            <div className="worker-card__footer">
              {isInterviewData || isInvited ? (
                <div className="worker-card__footer-interview-data-wrapper">
                  <div className="worker-card__footer-interview-data-icon-wrapper">
                    <img
                      className="worker-card__footer-interview-data-icon"
                      src={calendarIcon}
                      alt="interview-data"
                    />
                  </div>
                  {isInvited ? (
                    <span className="worker-card__footer-interview-data-text">
                      {`Interview requsted on `}
                      <strong>{currentDate}</strong>
                    </span>
                  ) : (
                    <span className="worker-card__footer-interview-data-text">
                      {`${prepInterviewStatus(
                        userData.interview_status_info?.status ?? ''
                      )} ${additionalInterviewData.preText} `}
                      <strong>
                        {`${userData?.interview_status_info?.date} 
                          ${additionalInterviewData.timeZoneAbr}`}
                      </strong>
                    </span>
                  )}
                </div>
              ) : (
                <Button
                  onClick={() =>
                    props.modalAction &&
                    userData &&
                    props.modalAction({
                      user: userData,
                      modalName: EmpActionType.interview,
                    })
                  }
                  className="worker-card__button-offer worker-card__button-offer_secondary"
                  disabled={isDisabletInterviewButton}
                >
                  Invite to Interview
                </Button>
              )}

              <a
                className="worker-block-info__header-link"
                href={userData?.resume ?? undefined}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  className="worker-card__button-resume"
                  disabled={userData?.resume ? undefined : true}
                >
                  View Resume
                </Button>
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
