import { EDate } from '../../../constants'

export const getDuration = (
  initStartDate: string | null,
  initEndDate: string | null
) => {
  if (!initStartDate || !initEndDate) return 'No date'

  const startDate = new Date(initStartDate)
  const endDate =
    initEndDate === EDate.present ? new Date() : new Date(initEndDate)

  if (
    startDate.toString() === 'Invalid Date' ||
    endDate.toString() === 'Invalid Date'
  ) {
    return 'Invalid Date'
  }

  const startYears = startDate.getFullYear()
  const monthsDuration =
    endDate.getMonth() -
    startDate.getMonth() +
    12 * (endDate.getFullYear() - startDate.getFullYear())

  return `${startYears} for ${monthsDuration} months`
}
