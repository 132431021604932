import React from 'react'
import { Link } from 'react-router-dom'
import './buttonBack.scss'

interface Props {
  route: string
  title: string
}

export const ButtonBack = (props: Props) => {
  const { route, title } = props
  return (
    <div className="button-back">
      <Link className="button-back__wrapper container" to={route}>
        <div className="button-back__img"></div>
        <span className="button-back__text">{title}</span>
      </Link>
    </div>
  )
}
