import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { toast } from 'react-toastify'
import {
  getSkillsInfo,
  updateSkillsInfo,
} from '../../../../../../services/workerApi/profileAPI'
import {
  ProfileAnalyticsName,
  WorkerQueries,
} from '../../../../../../utils/constants'
import {
  compareTwoArrayOfString,
  onTrackingActions,
} from '../../../../../../utils/scripts'
import { useTypedSelector } from '../../../../../../utils/hooks'
import { CertificateGroupsUI } from './CertificateGroupsUI'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  SkillsInfo,
  SkillsInfoOnlyCertifications,
} from '../../../../../../models'
import { QueryState } from 'react-query/types/core/query'
import { preparingSelectedCertList } from '../BasicSkills/BasicSkillsFunc'
import { useParams } from 'react-router-dom'

interface Props extends React.HTMLAttributes<HTMLElement> {}

export const CertificateGroups = (props: Props) => {
  const queryClient = useQueryClient()
  const { modal } = useParams<{ modal: string }>()
  const isOpenModalUrl = modal === 'certifications'
  const choiceLists = useTypedSelector(
    s => s.variables.choiceListForWorker.training_certifications
  )

  const defaultSkillsInfo: SkillsInfoOnlyCertifications = useMemo(
    () => ({
      training_certifications: [],
    }),
    []
  )

  const [certInfoForm, setCertInfoForm] =
    useState<SkillsInfoOnlyCertifications>(defaultSkillsInfo)
  const [certInfoState, setCertInfoState] =
    useState<SkillsInfoOnlyCertifications>(defaultSkillsInfo)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(
    isOpenModalUrl ?? false
  )
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false)

  const skillsInfoQuery: QueryState<SkillsInfo, undefined> | undefined =
    queryClient.getQueryState(WorkerQueries.skillsInfo)

  const {
    data: skillsInfo,
  }: {
    data: SkillsInfo | undefined
  } = useQuery(WorkerQueries.skillsInfo, () => getSkillsInfo(), {
    enabled: !skillsInfoQuery?.data,
  })

  const selectedCertList = preparingSelectedCertList(
    skillsInfo?.training_certifications ?? [],
    choiceLists ?? []
  )

  const skillsInfoMutation = useMutation(
    (data: SkillsInfoOnlyCertifications) => updateSkillsInfo(data),
    {
      onSuccess: (updatedData: SkillsInfo) => {
        queryClient.setQueryData(WorkerQueries.skillsInfo, updatedData)
      },
    }
  )

  const isStatusNotChanged = useMemo(
    () =>
      compareTwoArrayOfString(
        skillsInfo?.training_certifications,
        certInfoForm.training_certifications
      ),
    [skillsInfo?.training_certifications, certInfoForm.training_certifications]
  )

  const onCloseModal = useCallback(() => {
    if (isStatusNotChanged) {
      setIsModalVisible(false)
    } else {
      setIsConfirmationModalVisible(true)
    }
  }, [isStatusNotChanged])

  const handleSubmit = useCallback(() => {
    if (isStatusNotChanged) {
      setIsModalVisible(false)
      return
    }

    skillsInfoMutation.mutate(certInfoForm, {
      onSuccess: () => {
        setIsModalVisible(false)
        onTrackingActions(ProfileAnalyticsName.profile_edited)
      },
      onError: err => {
        if (err instanceof Error) toast.error(err.message)
      },
    })
  }, [certInfoForm, skillsInfoMutation, isStatusNotChanged])

  useEffect(() => {
    if (skillsInfo) {
      setCertInfoForm({
        training_certifications: skillsInfo.training_certifications,
      })
      setCertInfoState({
        training_certifications: skillsInfo.training_certifications,
      })
    }
  }, [skillsInfo])

  return (
    <CertificateGroupsUI
      {...props}
      isSaving={skillsInfoMutation.isLoading}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      isConfirmationModalVisible={isConfirmationModalVisible}
      setIsConfirmationModalVisible={setIsConfirmationModalVisible}
      handleSubmit={handleSubmit}
      onCloseModal={onCloseModal}
      selectedCertList={selectedCertList}
      choiceLists={choiceLists}
      certInfoForm={certInfoForm}
      setCertInfoForm={setCertInfoForm}
      certInfoState={certInfoState}
      setCertInfoState={setCertInfoState}
    />
  )
}
