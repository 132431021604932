import React from 'react'
import './radioBtn.scss'

interface Props extends React.InputHTMLAttributes<HTMLElement> {
  radioId: number
  selected: number | undefined
  onChangeBtn: (id: number) => void
  singleButtonsGrid?: true
  isDisabled?: boolean
}

export const RadioBtn = (props: Props) => {
  const {
    radioId,
    selected,
    value,
    onChangeBtn,
    isDisabled,
    singleButtonsGrid,
  } = props

  return (
    <div
      className={`radio-button ${
        singleButtonsGrid ? 'radio-button-grid' : ''
      } ${isDisabled ? 'radio-button__disabled' : ''} ${props.className ?? ''}`}
      data-testid="radio-button"
    >
      <input
        id={`${radioId}`}
        type="radio"
        className="radio-button__input"
        value={value}
        checked={selected === radioId}
        onChange={() => onChangeBtn(radioId)}
        disabled={isDisabled}
      />
      <label htmlFor={`${radioId}`} className="radio-button__label">
        {value}
      </label>
    </div>
  )
}
