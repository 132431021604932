import React, { useState } from 'react'
import { ISkills, SkillsInfo } from '../../../../models'
import { Checkbox, Multiselect } from '../../../atoms'
import { optionsSelectAndBG } from '../SelectAndBG'
import './checkboxAndMultiselect.scss'

interface Choice {
  id: number
  choice: string
}

interface Props extends React.HTMLAttributes<HTMLElement> {
  item: Choice
  checked?: true
  skillsAndLevelInfoFrom: ISkills[]
  setSkillsInfoForm: React.Dispatch<React.SetStateAction<SkillsInfo>>
}

export const CheckboxAndMultiselect = (props: Props) => {
  const [isChecked, setChecked] = useState(props.checked ? true : false)

  const onUpdateSkillsLevel = (
    selectedElement: string,
    initSetSkillsInfoForm: React.Dispatch<React.SetStateAction<SkillsInfo>>,
    itemChoice: string
  ) => {
    initSetSkillsInfoForm(prev => {
      const updatedSkills = prev.skills.map(prevSkills =>
        prevSkills.choice === itemChoice
          ? {
              choice: props.item.choice,
              level: +selectedElement.split(' ')[0],
            }
          : prevSkills
      )
      return {
        ...prev,
        skills: updatedSkills,
      }
    })
  }

  return (
    <div className={`checkbox-multiselect__wrapper ${props.className ?? ''} `}>
      <Checkbox
        buttonsGrid
        key={props.item.id}
        name={props.item.choice}
        idCheckbox={props.item.id}
        checked={props.checked}
        toggleCheckbox={(id: number, isChecked: boolean) => {
          setChecked(!isChecked)
          !isChecked
            ? props.setSkillsInfoForm(prev => {
                return {
                  ...prev,
                  skills: [
                    ...prev.skills,
                    {
                      level: 0,
                      choice: props.item.choice,
                    },
                  ],
                }
              })
            : props.setSkillsInfoForm(prev => {
                return {
                  ...prev,
                  skills: prev.skills.filter(
                    skill => skill.choice !== props.item.choice
                  ),
                }
              })
        }}
      />
      {isChecked && (
        <Multiselect
          className="checkbox-multiselect__select"
          placeholder="Select Experience"
          multiSelect={false}
          elements={optionsSelectAndBG}
          selectedElement={
            optionsSelectAndBG.find(elem =>
              props.skillsAndLevelInfoFrom.find(skill => {
                return (
                  elem.element ===
                    `${
                      skill.level === 1
                        ? `${skill.level} Year`
                        : `${skill.level} Years`
                    }` && skill.choice === props.item.choice
                )
              })
            ) ?? null
          }
          getSelectedElement={element => {
            element &&
              onUpdateSkillsLevel(
                element.element,
                props.setSkillsInfoForm,
                props.item.choice
              )
          }}
        />
      )}
    </div>
  )
}
