import React from 'react'
import { Helmet } from 'react-helmet'
import { Button } from '../../../components/atoms'
import {
  NavigationFooter,
  ProgressBarLinear,
} from '../../../components/molecules'
import { OBPlaceAutocomplete } from '../../../components/molecules/Worker/OBPlaceAutocomplete/OBPlaceAutocomplete'
import { ILocationInfoOB } from '../../../models'
import {
  EPageTitleOB,
  listBlocksPBLinear,
} from '../../../utils/constants/obConstants'
import { useTranslation } from 'react-i18next'
import './obLocation.scss'

interface Props {
  onClickBack: () => void
  onClickNext: (
    initLocationOB: ILocationInfoOB,
    initSetLocationOB: React.Dispatch<React.SetStateAction<ILocationInfoOB>>
  ) => void
  setLocationOB: React.Dispatch<React.SetStateAction<ILocationInfoOB>>
  locationOB: ILocationInfoOB
  isWaitingResponse: boolean
}

export const OBLocationUI = (props: Props) => {
  const {
    onClickBack,
    onClickNext,
    setLocationOB,
    locationOB,
    isWaitingResponse,
  } = props
  const { t } = useTranslation()

  return (
    <div className="ob-location">
      <Helmet>
        <title> {EPageTitleOB.home_location} </title>
      </Helmet>
      <div className="ob-location__wrapper ob-container">
        <ProgressBarLinear
          className="ob-location__progress-bar"
          initData={listBlocksPBLinear}
          currentPage={5}
        />
        <p className="ob-location__question ob-question">
          {t('location.Where do you call home these days?')}
        </p>
        <p className="ob-location__description ob-description">
          {t(
            "location.Knowing where you are helps us find you the best opportunities. If you're open to work in other"
          )}
        </p>
        <OBPlaceAutocomplete
          setLocationOB={setLocationOB}
          locationOB={locationOB}
          placeholder={t('location.Address, City, Town or ZIP')}
          homeLocationPage
          showFocus={true}
        />
      </div>

      <NavigationFooter actionBack={() => onClickBack()}>
        <Button
          onClick={() => onClickNext(locationOB, setLocationOB)}
          disabled={
            (locationOB?.home_location?.latitude ? false : true) ||
            isWaitingResponse
          }
        >
          {t('Shared_Header.Next')}
        </Button>
      </NavigationFooter>
    </div>
  )
}
