import editPen from '../../../../../assets/icons/other/edit-pencil.svg'
import zipcodes from 'zipcodes'
import { ModalBox } from '../../../../molecules'
import { UsernameEditModal } from './UsernameEditModal'
import { PublicInfo, WorkPreferencesInfo } from '../../../../../models'
import './publicProfileHeader.scss'
import { INames } from './PublicProfileHeader'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  publicInfo: PublicInfo
  setPublicInfo: React.Dispatch<React.SetStateAction<PublicInfo>>
  isEditNameModalVisible: boolean
  setIsEditNameModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  isConfirmationModalVisible: boolean
  setIsConfirmationModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  setIsUserImgModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  setIsBckImgModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  names: INames
  setNames: React.Dispatch<React.SetStateAction<INames>>
  userPhoto: JSX.Element
  backImg: JSX.Element
  onCloseEditNameModal: () => void
  workPreferences?: WorkPreferencesInfo
  location?: zipcodes.ZipCode
}

export const PublicProfileHeaderUI = (props: IProps) => {
  const {
    publicInfo,
    setPublicInfo,
    isEditNameModalVisible,
    setIsEditNameModalVisible,
    isConfirmationModalVisible,
    setIsConfirmationModalVisible,
    setIsUserImgModalVisible,
    setIsBckImgModalVisible,
    names,
    setNames,
    workPreferences,
    location,
    onCloseEditNameModal,
    backImg,
    userPhoto,
  } = props

  return (
    <div className="public-profile-header" data-testid="public-profile-header">
      <div className="public-profile-header__background-img">
        {backImg}
        <button
          type="button"
          className="public-profile-header__btn-add"
          onClick={() => setIsBckImgModalVisible(true)}
        >
          +
        </button>
      </div>

      <div className="public-profile-header__basic-info container">
        <div className="public-profile-header__basic-info-user-photo">
          {userPhoto}
          <button
            type="button"
            className="public-profile-header__btn-add"
            onClick={() => setIsUserImgModalVisible(true)}
          >
            +
          </button>
        </div>

        <div className="public-profile-header__basic-info-user">
          <h3 className="public-profile-header__basic-info-user-name">
            <span>{publicInfo?.first_name}&nbsp;</span>
            {publicInfo?.nickname && (
              <span>"{publicInfo?.nickname}"&nbsp;</span>
            )}
            <span>{publicInfo?.last_name}</span>
          </h3>
          <img
            className="public-profile-header__basic-info-user-edit-name"
            src={editPen}
            alt="edit-pensil"
            onClick={() => setIsEditNameModalVisible(true)}
          />
        </div>

        {isEditNameModalVisible && publicInfo && setPublicInfo && (
          <ModalBox
            className={`public-profile-header__username-edit-modal`}
            onCloseModal={onCloseEditNameModal}
            visibleCloseIcon={true}
          >
            <UsernameEditModal
              publicInfo={publicInfo}
              setPublicInfo={setPublicInfo}
              setIsEditNameModalVisible={setIsEditNameModalVisible}
              isConfirmationModalVisible={isConfirmationModalVisible}
              setIsConfirmationModalVisible={setIsConfirmationModalVisible}
              names={names}
              setNames={setNames}
            />
          </ModalBox>
        )}

        {workPreferences?.roles && workPreferences?.roles.length > 0 && (
          <p className="public-profile-header__basic-info-position-block">
            <span className="public-profile-header__basic-info-position">
              {workPreferences.roles[0]}
            </span>
            <span className="public-profile-header__basic-info-experience"></span>
          </p>
        )}

        {location && (
          <p className="public-profile-header__basic-info-places">
            {location?.city}, {location?.state}, {location?.country}
          </p>
        )}

        {/* <p className="public-profile-header__basic-info-rating">
        <div className="public-profile-header__basic-info-rating-stars">
          <div className="public-profile-header__basic-info-rating-star"></div>
          <div className="public-profile-header__basic-info-rating-star"></div>
          <div className="public-profile-header__basic-info-rating-star"></div>
          <div className="public-profile-header__basic-info-rating-star"></div>
          <div className="public-profile-header__basic-info-rating-star"></div>
        </div>
        <span className="public-profile-header__basic-info-rating-counter">&nbsp; (0) &nbsp;</span>
        <div className="public-profile-header__basic-info-rating-prompt-icon">
          <Prompt
            className="public-profile-header__basic-info-rating-prompt"
          >Your star rating is based on reviews of past employers</Prompt>
          i
        </div>
      </p> */}
        {/* TODO this block will be connected when the user rating logic becomes known */}

        {workPreferences?.work_preferences &&
          workPreferences?.desired_pay_rate && (
            <div className="public-profile-header__basic-info-seeking">
              <p className="public-profile-header__basic-info-seeking-title">
                Seeking
              </p>
              <div className="public-profile-header__basic-info-seeking-options">
                {workPreferences.work_preferences.map(preference => (
                  <div
                    key={preference}
                    className="public-profile-header__basic-info-seeking-option profile_white-btn"
                  >
                    {preference}
                  </div>
                ))}
                <div className="public-profile-header__basic-info-seeking-option profile_white-btn">
                  {workPreferences.desired_pay_rate}/hour
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  )
}
