import React from 'react'
import { Button, Form, Input } from '../../../../components/atoms'
import { Validator } from '../../../../components/atoms/validator/validator'
import { PopupLoader } from '../../../../components/organism'
import { messageList, PasswordInput } from './ChangePasswordPage'
import './changePasswordPage.scss'

interface Props {
  changePasswordAction: () => void
  isActive: boolean
  isLoading: boolean
  runTimeValidateErrors: string[]
  serverError?: string
  newPasswordInput: PasswordInput
  currentPasswordInput: PasswordInput
  confirmPasswordInput: PasswordInput
}

export const ChangePasswordPageUI = (props: Props) => {
  const {
    isActive,
    isLoading,
    serverError,
    newPasswordInput,
    confirmPasswordInput,
    currentPasswordInput,
    changePasswordAction,
    runTimeValidateErrors,
  } = props

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        changePasswordAction()
      }}
      onEnter={e => {
        e.preventDefault()
        isActive && changePasswordAction()
      }}
      className="change-password"
      data-testid="change-password"
    >
      {isLoading && <PopupLoader>Saving...</PopupLoader>}
      <p className="change-password__title">Change your password</p>
      <p className="change-password__subtitle">Please include at least:</p>
      <div className="change-password__validator-form">
        <Validator
          text="8 characters"
          isValid={
            !runTimeValidateErrors.includes(messageList.CHARACTERS_ERROR)
          }
        />
        <Validator
          text="1 capital letter"
          isValid={
            !runTimeValidateErrors.includes(messageList.CAPITAL_LETTER_ERROR)
          }
        />
        <Validator
          text="1 lowercase letter"
          isValid={
            !runTimeValidateErrors.includes(messageList.LOWERCASE_LETTER_ERROR)
          }
        />
        <Validator
          text="1 special character"
          isValid={
            !runTimeValidateErrors.includes(messageList.SPECIAL_LETTER_ERROR)
          }
        />
      </div>
      <div className="change-password__input-form">
        <Input
          placeholder="Current password"
          className="change-password__input"
          type="password"
          autoComplete="off"
          textError={
            serverError === messageList.INVALID_PASSWORD_ERROR
              ? serverError
              : undefined
          }
          clearErrorOnChange
          {...currentPasswordInput}
        />
        <Input
          placeholder="New password"
          className="change-password__input"
          type="password"
          autoComplete="off"
          {...newPasswordInput}
        />
        <Input
          placeholder="Confirm password"
          className="change-password__input"
          type="password"
          autoComplete="off"
          textError={
            serverError === messageList.PASSWORD_DOES_NOT_MATCH_ERROR
              ? serverError
              : undefined
          }
          clearErrorOnChange
          {...confirmPasswordInput}
        />
      </div>
      <Button
        disabled={!isActive}
        type="submit"
        className="change-password__confirm-button"
      >
        CHANGE PASSWORD
      </Button>
    </Form>
  )
}
