import './careerGuideCall.scss'
import { Link } from 'react-router-dom'
import * as route from '../../../../../services/route'

interface Props {
  name: string | null
  phone: string | null
  date: string | null
}

export const CareerGuideCall = (props: Props) => {
  const { name, date, phone } = props

  return (
    <div className="career-guide-call">
      You have a call scheduled with {name ?? '--'}
      <div className="dashboard__call-info">
        <span>When</span>{' '}
        <span
          className="dashboard__call-info-property"
          data-testid="dashboard__call-info-property"
        >
          {date ?? '--'}
        </span>
      </div>
      <div className="dashboard__call-info">
        <span>How</span>{' '}
        <span className="dashboard__call-info-property">
          {name} will call you at {phone ?? '--'}
          <div className="dashboard__call-info-property-note">
            <span>to update your contact info please </span>
            <Link
              className="dashboard__call-info-property-note-link"
              to={route.profileBasicInfo}
            >
              edit your profile
            </Link>
          </div>
        </span>
      </div>
    </div>
  )
}
