import React, { ChangeEvent } from 'react'
import './passwordResetForm.scss'
import { Title, Input, Button, ErrorMessage } from '../../../atoms'

interface Props {
  formSubmit: () => void
  password1Change: (event: ChangeEvent<HTMLInputElement>) => void
  password2Change: (event: ChangeEvent<HTMLInputElement>) => void
  errorMessage: string | undefined
}

export const PasswordResetForm = ({
  formSubmit,
  password1Change,
  password2Change,
  errorMessage,
}: Props) => {
  return (
    <div className={'password-reset__container'}>
      <div className={'password-reset__box'}>
        <Title
          text={'Set Password'}
          closeAvailable={false}
          className={'password-reset__title-container'}
          textClassName={'password-reset__title'}
        />
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <Input
          labelText={'New Password'}
          containerClassName={'password-reset__input-container'}
          inputClassName={'password-reset__input'}
          type={'password'}
          onChange={password1Change}
        />
        <Input
          labelText={'Confirm Password'}
          containerClassName={'password-reset__input-container'}
          inputClassName={'password-reset__input'}
          type={'password'}
          onChange={password2Change}
        />
        <Button className={'password-reset__submit'} onClick={formSubmit}>
          Submit
        </Button>
      </div>
    </div>
  )
}
