import { IS_QUALIFY } from './../utils/constants/preInterviewConstants'
import {
  getPreInterviewQualifyResult,
  postAsnwers,
} from '../services/workerApi'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { FormAnswers } from '../models/PreInterviewForms'

export const qualificationResult = createAsyncThunk(
  'AUTH/QUALIFICATION-RESULT',
  async ({ id, email }: { email: string; id: string }, thunkAPI) => {
    const qualify = await getPreInterviewQualifyResult(id, email)
      .then(res => {
        localStorage.setItem(
          IS_QUALIFY,
          JSON.stringify({ isQualify: res.if_qualified, email: email })
        )
        return res.if_qualified
      })
      .catch(() => false)
    return { isQualified: qualify }
  }
)

export const onboardingQualification = createAsyncThunk(
  'AUTH/ONBOARDING-QUALIFICATION',
  async (answers: FormAnswers, thunkAPI) => {
    const qualify = await postAsnwers(answers).then(res => {
      if (res.if_qualified) {
        localStorage.setItem(IS_QUALIFY, JSON.stringify({ isQualify: true }))
      }
      return res
    })
    return { isQualified: qualify.page ? false : true, data: qualify }
  }
)
