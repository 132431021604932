import React, { useState } from 'react'
import {
  CareerGuideInfo,
  DaySlot,
  InterviewerModel,
} from '../../../../../models'
import { useTypedSelector } from '../../../../../utils/hooks'
import { ModalBox } from '../../../../molecules'
import {
  ScheduleFormat,
  BookedModal,
  NotBookedModal,
  BookingCalendar,
} from '../../ScheduleComponents'
import './careerGuideChat.scss'
import moment from 'moment'
import 'moment-timezone'

interface CareerGuideChatProps {
  isCareerGuideModalVisible: boolean
  setIsCareerGuideModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  setCareerGuideInfo: React.Dispatch<
    React.SetStateAction<CareerGuideInfo | null>
  >
}

export const CareerGuideChat = (props: CareerGuideChatProps) => {
  const {
    isCareerGuideModalVisible,
    setIsCareerGuideModalVisible,
    setCareerGuideInfo,
  } = props

  const user = useTypedSelector(s => s.auth.user)
  const userPhone = useTypedSelector(s => s.obInfo.workerInfo.user_data.phone)
  const uuid = user?.uuid
  const [isBookingModalVisible, setIsBookingModalVisible] =
    useState<boolean>(false)
  const [date, setDate] = useState(new Date())
  const [slots, setSlots] = useState<DaySlot[]>()
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [isNotBookingModalVisible, setIsNotBookingModalVisible] =
    useState<boolean>(false)
  const [errorBooking, setErrorBooking] = useState<string>()
  const [interviewer, setInterviewer] = useState<InterviewerModel | null>(null)

  const onCloseModal = () => {
    const preparedData = {
      name: interviewer?.name.split(' ')[0] ?? '',
      phone: userPhone,
      profile_image: interviewer?.profile_image ?? '',
      email: interviewer?.email ?? '',
      interview_date: `${moment(date)
        .format('dddd, MMMM Do [at] h:mm a')
        .replace('am', 'AM')
        .replace('pm', 'PM')}`,
    }

    setCareerGuideInfo(preparedData)
    setIsModalVisible(false)
  }

  return (
    <div className="career-guide-chat">
      {uuid ? (
        <>
          {isCareerGuideModalVisible && (
            <ModalBox
              onCloseModal={() => setIsCareerGuideModalVisible(false)}
              visibleCloseIcon
            >
              <div className="career-guide-chat__format">
                <ScheduleFormat
                  onClickScheduleBtn={() => {
                    setIsCareerGuideModalVisible(false)
                    setIsBookingModalVisible(true)
                  }}
                />
              </div>
            </ModalBox>
          )}
          {isBookingModalVisible && (
            <ModalBox
              onCloseModal={() => {
                sessionStorage.removeItem('minDate')
                setDate(new Date())
                setIsBookingModalVisible(false)
              }}
              visibleCloseIcon
            >
              <div className="career-guide-chat__booking">
                <BookedModal
                  date={date}
                  type={'onboarding'}
                  isModalVisible={isModalVisible}
                  onCloseModal={onCloseModal}
                  interviewer={interviewer}
                />
                <NotBookedModal
                  textError={errorBooking ?? ''}
                  uuid={uuid}
                  isModalBooking={isNotBookingModalVisible}
                  setIsModalBooking={setIsNotBookingModalVisible}
                />
                <BookingCalendar
                  uuid={uuid}
                  event_type={null}
                  date={date}
                  setDate={setDate}
                  slots={slots}
                  setSlots={setSlots}
                  type={null}
                  setIsModalBooking={setIsNotBookingModalVisible}
                  setIsModalVisible={setIsModalVisible}
                  setErrorBooking={setErrorBooking}
                  setInterviewer={setInterviewer}
                />
              </div>
            </ModalBox>
          )}
          Have a 10 minute call with your career guide to begin getting offers.
        </>
      ) : (
        'Something went wrong, there is no email'
      )}
    </div>
  )
}
