import { useCallback, useEffect, useMemo } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { getAssessmentForms } from '../../../../services/workerApi'
import {
  WorkerDashboardAnalyticsName,
  WorkerQueries,
} from '../../../../utils/constants'
import { useTypedSelector } from '../../../../utils/hooks'
import { onTrackingActions } from '../../../../utils/scripts'
import './assessmentPage.scss'
import { AssessmentPageUI } from './AssessmentPageUI'

export const AssessmentPage = () => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const { id, uuid: paramUuid } = useParams<{ id: string; uuid?: string }>()
  const isAuth = useTypedSelector(s => s.auth.isAuth)
  const userInfo = useTypedSelector(s => s.auth.user)
  const uuid = isAuth ? userInfo?.uuid ?? '' : paramUuid ?? ''

  if (isAuth && paramUuid && paramUuid !== userInfo?.uuid) {
    history.push('/')
  }

  const assessmentFormsQuery = queryClient.getQueryState(
    WorkerQueries.availableForms
  )

  const {
    data: assessmentInfo,
    isLoading,
    isSuccess,
  } = useQuery(
    [WorkerQueries.availableForms, false, uuid, isAuth],
    () => getAssessmentForms(false, uuid, isAuth),
    { enabled: !assessmentFormsQuery?.data }
  )

  const email = isAuth ? userInfo?.username : assessmentInfo?.email

  const currentAssessmentForm = useMemo(
    () =>
      assessmentInfo?.type_forms &&
      assessmentInfo.type_forms.find(form => form.key === id),
    [assessmentInfo?.type_forms, id]
  )

  const getTitle = useCallback(() => {
    if (isSuccess && currentAssessmentForm) {
      return currentAssessmentForm?.name
    }
    if (isSuccess && !currentAssessmentForm) {
      return 'You already passed this assessment'
    }
    return 'The assessment form not found'
  }, [currentAssessmentForm, isSuccess])

  useEffect(() => {
    if (currentAssessmentForm) {
      onTrackingActions(WorkerDashboardAnalyticsName.assessment_started)
    }
  }, [currentAssessmentForm])

  return (
    <AssessmentPageUI
      title={getTitle()}
      link={
        currentAssessmentForm?.url && email
          ? `${currentAssessmentForm.url}#email=${email}`
          : null
      }
      isLoading={isLoading}
    />
  )
}
