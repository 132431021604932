import { useEffect, useState, useCallback } from 'react'
import { Config } from '../../../../components/organism/General/UploadFiles'
import {
  getPublicInfo,
  disableProfileNotification,
  updatePublicFiles,
} from '../../../../services/workerApi/profileAPI'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  ProfileAnalyticsName,
  WorkerQueries,
} from '../../../../utils/constants'
import { onTrackingActions } from '../../../../utils/scripts'
import { QueryState } from 'react-query/types/core/query'
import { PublicInfo } from '../../../../models'
import { useTypedSelector } from '../../../../utils/hooks'
import { PublicProfileUI } from './PublicProfileUI'
import { toast } from 'react-toastify'
import { ERROR_SOMETHING } from '../../../../services/auth-service'

export const PublicProfile = () => {
  const queryClient = useQueryClient()
  const uuid = useTypedSelector(s => s.auth.user?.uuid)

  const publicInfoQuery: QueryState<PublicInfo, undefined> | undefined =
    queryClient.getQueryState(WorkerQueries.publicInfo)

  const { data: publicInfo, isLoading: isPublicInfoLoading } = useQuery(
    WorkerQueries.publicInfo,
    () => getPublicInfo(),
    { enabled: !publicInfoQuery?.data }
  )

  const [isPromptHidden, setIsPromptHidden] = useState<boolean>(
    !publicInfo?.profile_notification
  )

  const mutationPublicFiles = useMutation(
    (newData: { formData: FormData; config?: Config }) =>
      updatePublicFiles(newData.formData, newData.config)
  )

  const mutatePublicFiles = useCallback(
    (formData: FormData, config?: Config) => {
      let response: PublicInfo | Error
      mutationPublicFiles.mutate(
        { formData, config },
        {
          onSuccess: (updatedData: PublicInfo) => {
            queryClient.setQueryData(WorkerQueries.publicInfo, {
              ...publicInfoQuery?.data,
              resume: updatedData.resume,
            })

            response = updatedData
          },
          onError: err => {
            if (err instanceof Error) {
              response = err
              toast.error(ERROR_SOMETHING)
            }
          },
        }
      )
      return new Promise(_ => response)
    },
    [mutationPublicFiles, publicInfoQuery?.data, queryClient]
  )

  const onTrackingUploadFile = () => {
    onTrackingActions(ProfileAnalyticsName.profile_edited)
  }

  useEffect(() => {
    publicInfoQuery?.data?.profile_notification &&
      disableProfileNotification().then(() => {
        queryClient.setQueryData(WorkerQueries.publicInfo, {
          ...publicInfoQuery?.data,
          profile_notification: isPromptHidden,
        })
      })
  }, [isPromptHidden, publicInfoQuery?.data, queryClient])

  return (
    <PublicProfileUI
      uuid={uuid}
      publicInfo={publicInfo}
      isPromptHidden={isPromptHidden}
      setIsPromptHidden={setIsPromptHidden}
      mutatePublicFiles={mutatePublicFiles}
      isPublicInfoLoading={isPublicInfoLoading}
      onTrackingUploadFile={onTrackingUploadFile}
    />
  )
}
