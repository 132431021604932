import './workerReviewCard.scss'
interface Props {
  title: string
  date: string
  description: string | null
  photo: string | null
}

export const WorkerReviewCard = (props: Props) => {
  const { title, date, description, photo } = props
  return (
    <div className="review-card">
      {photo ? (
        <img className="review-card__photo" src={photo} alt="employerPhoto" />
      ) : (
        <div
          className="review-card__default-photo"
          data-testid="review-card__default-photo"
        >
          {title[0].toUpperCase()}
        </div>
      )}
      <div className="review-card__info">
        <p className="review-card__info-title">{title}</p>
        <p className="review-card__info-date">{date}</p>
        <p className="review-card__info-description">{description}</p>
      </div>
    </div>
  )
}
