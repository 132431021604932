import { OBReferencesUI } from './OBReferencesUI'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as route from '../../../services/route'
import { IObRefCompanyDataType } from '../../../models'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import {
  completedOnboarding,
  updateReferencesInfoOB,
} from '../../../services/workerApi'
import {
  EWorkerSStoreKeys,
  OBAnalyticsName,
  WorkerQueries,
  analyticsPageTitleOB,
} from '../../../utils/constants'
import { toast } from 'react-toastify'
import { setWorkerInfo, useAppDispatch } from '../../../redux'
import { useTypedSelector } from '../../../utils/hooks'
import {
  getUserInfoFromStorage,
  onEventPage,
  onTrackingActions,
  formatPhoneNumber,
} from '../../../utils/scripts'

export enum EObRefInputType {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phone = 'tel',
}

export const OBReferences = () => {
  const inputRef = useRef<HTMLInputElement>(null)
  const history = useHistory()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const userInfoFromStorage = useMemo(() => getUserInfoFromStorage(), [])
  const userInfo = useTypedSelector(s => s.obInfo.workerInfo)

  const [firstCompanyData, setFirstCompanyData] = useState({
    first_name: userInfoFromStorage?.user_data?.referee[0]?.first_name
      ? userInfoFromStorage?.user_data?.referee[0]?.first_name
      : userInfo?.user_data?.referee[0]?.first_name ?? '',
    last_name: userInfoFromStorage?.user_data?.referee[0]?.last_name
      ? userInfoFromStorage?.user_data?.referee[0]?.last_name
      : userInfo?.user_data?.referee[0]?.last_name ?? '',
    phone_number:
      userInfoFromStorage?.user_data?.referee[0]?.phone_number?.toString()
        ? userInfoFromStorage?.user_data?.referee[0]?.phone_number?.toString()
        : userInfo?.user_data?.referee[0]?.phone_number?.toString() ?? '',
    email_address: userInfoFromStorage?.user_data?.referee[0]?.email_address
      ? userInfoFromStorage?.user_data?.referee[0]?.email_address
      : userInfo?.user_data?.referee[0]?.email_address ?? '',
  })

  const [secondCompanyData, setSecondCompanyData] = useState({
    first_name: userInfoFromStorage?.user_data?.referee[1]?.first_name
      ? userInfoFromStorage?.user_data?.referee[1]?.first_name
      : userInfo?.user_data?.referee[1]?.first_name ?? '',
    last_name: userInfoFromStorage?.user_data?.referee[1]?.last_name
      ? userInfoFromStorage?.user_data?.referee[1]?.last_name
      : userInfo?.user_data?.referee[1]?.last_name ?? '',
    phone_number:
      userInfoFromStorage?.user_data?.referee[1]?.phone_number?.toString()
        ? userInfoFromStorage?.user_data?.referee[1]?.phone_number?.toString()
        : userInfo?.user_data?.referee[1]?.phone_number?.toString() ?? '',
    email_address: userInfoFromStorage?.user_data?.referee[1]?.email_address
      ? userInfoFromStorage?.user_data?.referee[1]?.email_address
      : userInfo?.user_data?.referee[1]?.email_address ?? '',
  })

  const { refetch: obCompleteRefresh } = useQuery(
    WorkerQueries.completedOB,
    () => completedOnboarding(),
    {
      onSuccess: () => {
        sessionStorage.removeItem(EWorkerSStoreKeys.obData)
        sessionStorage.removeItem(EWorkerSStoreKeys.obLocation)
        dispatch(
          setWorkerInfo({
            ...userInfo,
            is_completed: true,
          })
        )
        history.push(route.OBPath.onboardingThanks)
      },
      onError: err => {
        if (err instanceof Error) toast.error(err.message)
      },
      enabled: false,
    }
  )

  const obReferencesInfoMutation = useMutation(
    (data: IObRefCompanyDataType[]) => updateReferencesInfoOB(data),
    {
      onSuccess: () => {
        obCompleteRefresh()
      },
      onError: err => {
        if (err instanceof Error) toast.error(err.message)
      },
    }
  )

  const onClickNext = useCallback(
    async isButton => {
      if (isButton) {
        await onTrackingActions(OBAnalyticsName.references_submitted)
        obReferencesInfoMutation.mutate([firstCompanyData, secondCompanyData])
      } else {
        await onTrackingActions(OBAnalyticsName.references_skipped)
        obCompleteRefresh()
      }
    },
    [
      firstCompanyData,
      obReferencesInfoMutation,
      secondCompanyData,
      obCompleteRefresh,
    ]
  )

  const onClickBack = useCallback(() => {
    history.push(route.OBPath.onboardingRates)

    sessionStorage.setItem(
      EWorkerSStoreKeys.obData,
      JSON.stringify({
        ...userInfo,
        user_data: {
          ...userInfo?.user_data,
          referee: [firstCompanyData, secondCompanyData],
        },
      })
    )
  }, [history, firstCompanyData, secondCompanyData, userInfo])

  const addDataFirstCompany = (data: string, inputType?: string) => {
    switch (inputType) {
      case EObRefInputType.firstName:
        setFirstCompanyData({ ...firstCompanyData, first_name: data })
        break
      case EObRefInputType.lastName:
        setFirstCompanyData({ ...firstCompanyData, last_name: data })
        break
      case EObRefInputType.email:
        setFirstCompanyData({ ...firstCompanyData, email_address: data })
        break
      case EObRefInputType.phone:
        setFirstCompanyData({
          ...firstCompanyData,
          phone_number: formatPhoneNumber(data, true, true) ?? '',
        })
        break
    }
  }

  const addDataSecondCompany = (data: string, inputType?: string) => {
    switch (inputType) {
      case EObRefInputType.firstName:
        setSecondCompanyData({ ...secondCompanyData, first_name: data })
        break
      case EObRefInputType.lastName:
        setSecondCompanyData({ ...secondCompanyData, last_name: data })
        break
      case EObRefInputType.email:
        setSecondCompanyData({ ...secondCompanyData, email_address: data })
        break
      case EObRefInputType.phone:
        setSecondCompanyData({
          ...secondCompanyData,
          phone_number: formatPhoneNumber(data, true, true) ?? '',
        })
        break
    }
  }

  useEffect(() => {
    onEventPage(
      location.pathname,
      analyticsPageTitleOB[route.OBPath.onboardingReferences]
    )
  }, [location.pathname])

  useEffect(() => inputRef?.current?.focus(), [])

  return (
    <OBReferencesUI
      onClickNext={onClickNext}
      onClickBack={onClickBack}
      addDataFirstCompany={addDataFirstCompany}
      addDataSecondCompany={addDataSecondCompany}
      firstCompanyData={firstCompanyData}
      secondCompanyData={secondCompanyData}
      isWaitingResponse={
        obReferencesInfoMutation.isIdle
          ? false
          : !obReferencesInfoMutation.error
      }
      ref={inputRef}
    />
  )
}
