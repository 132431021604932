import React from 'react'
import { useHistory } from 'react-router'
import { JobCardComponent } from '../../../../components/molecules/Worker/JobCardComponent'
import { LoadingContent } from '../../../../components/molecules/General/LoadingContent'
import { Job } from '../../../../models'
import { getJobs } from '../../../../services/workerApi/getApi'
import { AsyncStatus, useAsyncAction } from '../../../../utils/hooks'
import * as route from '../../../../services/route'
import { Helmet } from 'react-helmet'
import './jobsPage.scss'

type JobQualify =
  | 'Journey'
  | 'Master'
  | 'Lead'
  | 'Jobsite'
  | 'Company'
  | 'Senior'

export const JobsPage = () => {
  const jobsAction = useAsyncJob()
  const history = useHistory()
  const jobLoading = jobsAction.status === AsyncStatus.Executing

  const temp = jobsAction.value

  const onClickLearnMore = (index: number) => {
    const id = temp![index].job.id
    history.push(`${route.jobDetail}/${id}`)
  }

  return (
    <div className={`jobs-page ${jobLoading ? 'jobs-page_loading' : ''}`}>
      <Helmet>
        <title>Skillit - Jobs</title>
      </Helmet>
      <section className="jobs-page__banner container">
        <div className="jobs-page__banner-content">
          <h2 className="jobs-page__banner-title">Earn More For Being You</h2>
          <p className="jobs-page__banner-description">
            Get paid a premium from the nation’s top construction firms for
            having the knowledge and skills they value most.
          </p>
        </div>
      </section>
      <section className="jobs-page__content container">
        <LoadingContent isLoading={jobLoading}>
          {temp?.map((el, index) => (
            <JobCardComponent
              key={el.job.id}
              templateJob={el}
              onClickLearnMore={() => onClickLearnMore(index)}
            />
          ))}
        </LoadingContent>
      </section>
    </div>
  )
}

// TODO: This is template interface while waiting server finish getJob request, pls remove it later
export interface TemplateJob {
  job: Job
  position: string
  company: string
  requiredQualify: JobQualify[]
  salary: { min: number; max: number }
  title: string
}

export const useAsyncJob = () => {
  const jobsAction = useAsyncAction(getJobs, true)
  //TODO: remove this then when server finished job query
  const temp = jobsAction.value?.map((el, index): TemplateJob => {
    const isCarpenter = index % 2 === 0
    const position = isCarpenter ? 'Carpenter' : 'Foreman'
    const company = isCarpenter
      ? 'Messer Construction Co.'
      : 'Messer Construction Co.'
    const qualify: JobQualify[] = isCarpenter
      ? ['Journey', 'Master', 'Lead']
      : ['Jobsite', 'Company', 'Senior']
    const salary = isCarpenter ? { min: 18, max: 26 } : { min: 26, max: 30 }
    const title = isCarpenter ? 'Carpenter' : 'Carpenter Foreman'
    return {
      job: el,
      position: position,
      company: company,
      requiredQualify: qualify,
      salary: salary,
      title: title,
    }
  })

  return { status: jobsAction.status, value: temp, error: jobsAction.error }
}
