import React from 'react'
import { Button } from '../../buttons'
import { Checkbox, ECheckboxTheme } from '../../Checkbox'
import { ICheckboxSelectProps } from './CheckboxSelect'
import polygonIcon from '../../../../assets/icons/other/polygon-icon.svg'
import './checkboxSelect.scss'

interface IProps extends ICheckboxSelectProps {
  btnTitle: string
  isOpen: boolean
  onBtnClick: () => void
  isSelected: boolean
}

export const CheckboxSelectUI = (props: IProps) => {
  const {
    className,
    btnTitle,
    onBtnClick,
    isOpen,
    isSelected,
    list,
    checkboxHandler,
  } = props

  return (
    <div className={`checkbox-select ${className ?? ''}`}>
      <Button
        className={`checkbox-select__btn ${
          isOpen ? 'checkbox-select__btn_active' : ''
        } ${!isOpen && isSelected ? 'checkbox-select__btn_selected' : ''}`}
        onClick={onBtnClick}
        data-testid="checkbox-select__btn"
      >
        {btnTitle}
        <img src={polygonIcon} alt="polygon icon" />
      </Button>
      {isOpen && (
        <ul
          className="checkbox-select__list"
          data-testid="checkbox-select__list"
        >
          {list.map((item, index) => (
            <li
              key={item.key}
              className="checkbox-select__item"
              data-testid={`checkbox-select__list_${item.key}`}
            >
              <Checkbox
                name={item.value}
                idCheckbox={index}
                toggleCheckbox={(_, isChecked: boolean) =>
                  checkboxHandler(item.key, !isChecked)
                }
                checked={item.isChecked ? true : undefined}
                theme={ECheckboxTheme.orange}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
