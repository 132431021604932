import React, { useMemo } from 'react'
import { WorkPreferencesInfo } from '../../../../../../models'
import editIcon from '../../../../../../assets/icons/other/edit-pencil.svg'
import './workPreferncesContent.scss'
import { roleSorting } from '../../../../../../utils/scripts/general'
import { getPayMinValueTitle } from '../../../../../../utils/scripts'

interface Props {
  workPreferences: WorkPreferencesInfo | null
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const WorkPreferencesContent = (props: Props) => {
  const { setIsModalVisible, workPreferences } = props

  const sortedRoles = useMemo(
    () => workPreferences?.roles?.sort(roleSorting).join(', '),
    [workPreferences?.roles]
  )

  return (
    <main
      className="work-preferences-content container"
      data-testid="work-preferences-content"
    >
      <div className="work-preferences-content__header">
        <div className="work-preferences-content__title">Work Preferences</div>
        <img
          src={editIcon}
          className="work-preferences-content__edit-icon"
          alt="edit work preferences"
          onClick={() => setIsModalVisible(true)}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              setIsModalVisible(true)
            }
          }}
        />
      </div>
      <section className="work-preferences-content__block">
        <h4 className="work-preferences-content__block-title">
          Most recent hourly pay
        </h4>
        <p className="work-preferences-content__block-content">
          {getPayMinValueTitle(
            workPreferences?.most_recent_pay_rate?.substring(1),
            true
          )}
        </p>
        <hr />
      </section>
      <section className="work-preferences-content__block">
        <h4 className="work-preferences-content__block-title">
          Desired hourly pay
        </h4>
        <p className="work-preferences-content__block-content">
          {getPayMinValueTitle(
            workPreferences?.desired_pay_rate?.substring(1),
            true
          )}
        </p>
        <hr />
      </section>
      <section className="work-preferences-content__block">
        <h4 className="work-preferences-content__block-title">Role</h4>
        <p className="work-preferences-content__block-content">
          {workPreferences?.roles?.length ? sortedRoles : 'None provided'}
        </p>
        <hr />
      </section>
      <section className="work-preferences-content__block">
        <h4 className="work-preferences-content__block-title">
          Work Preference
        </h4>
        <p className="work-preferences-content__block-content">
          {workPreferences?.work_preferences.length
            ? workPreferences?.work_preferences.join(', ')
            : 'None provided'}
        </p>
        <hr />
      </section>
      <section className="work-preferences-content__block">
        <h4 className="work-preferences-content__block-title">
          Ideal Employer
        </h4>
        <p className="work-preferences-content__block-content">
          {workPreferences?.ideal_employer.length
            ? workPreferences?.ideal_employer.join(', ')
            : 'None provided'}
        </p>
      </section>
    </main>
  )
}
