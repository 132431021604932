import { WorkerInfoDetails } from '../../../../../models'
import { WorkerSummaryCard } from '../../../../atoms'
import { v4 as uuidv4 } from 'uuid'

interface Props {
  workerDataDetails?: WorkerInfoDetails
  noDescription: boolean
  hasExperience: boolean
}

export const WorkerEmploymentHighlightsBlockUI = (props: Props) => {
  const { workerDataDetails, noDescription, hasExperience } = props

  return (
    <div className="employment-highlights">
      {hasExperience && (
        <>
          <div className="worker-detail-section-header">
            <h4>Employment Highlights</h4>
          </div>

          <div className="employment-highlights__experience">
            <p className="employment-highlights__experience-title">
              Past employment &amp; experience
            </p>
            {workerDataDetails?.past_employment_and_experience.experience.map(
              job => (
                <WorkerSummaryCard
                  noDescription={noDescription}
                  key={uuidv4()}
                  years={job.years}
                  company_name={job.company_name}
                  start_year={job.start_year}
                  months={job.months}
                  job_title={job.job_title}
                  description={job.description}
                />
              )
            )}
          </div>
        </>
      )}
    </div>
  )
}
