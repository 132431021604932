import { useEffect } from 'react'
import { useTypedSelector } from '../UseTypedSelector'

declare global {
  interface Window {
    analytics: any
  }
}

export const useIdentifyActions = () => {
  const user = useTypedSelector(s => s.auth.user)

  useEffect(() => {
    const userIdFromLocalStorage = localStorage.getItem('ajs_user_id')
    if (!userIdFromLocalStorage || userIdFromLocalStorage === 'null') {
      user?.uuid && window.analytics.identify(`${user?.uuid}`)
    }
  }, [user?.uuid])
}
