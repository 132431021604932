import React from 'react'
import { useStateWithDep } from '../../../../utils/hooks'
import { ErrorMessageWithCross } from '../../ErrorMessages'
import './textarea.scss'

interface IProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  texterror?: string
  clearErrorOnChange?: boolean
}

export const Textarea = (props: IProps) => {
  const { className, texterror, clearErrorOnChange, ...otherProps } = props

  const [textError, setTextError] = useStateWithDep(texterror)
  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    clearErrorOnChange && setTextError(undefined)
    props.onChange && props.onChange(e)
  }

  return (
    <>
      <textarea
        {...otherProps}
        onChange={onChange}
        className={`textarea ${className ?? ''}`}
        data-testid="textarea"
      ></textarea>
      {textError !== undefined && (
        <ErrorMessageWithCross textError={textError} />
      )}
    </>
  )
}
