import React from 'react'
import { EmailLogin, SocialLoginType } from '../../../molecules'

interface Props {
  type: LoginType
  onClickLoginType?: (type: SocialLoginType) => void
  onConfirmEmailLogin: (login: string, password: string) => Promise<void>
  children?: React.ReactNode
  defaultEmail?: string
}

export enum LoginType {
  SignIn,
  SignUp,
  SignInEmail,
}

export const LoginForm = ({
  defaultEmail = '',
  onConfirmEmailLogin,
  type,
  children,
  ...otherProps
}: Props) => {
  const emailPlaceHolder = () => {
    switch (type) {
      case LoginType.SignInEmail:
        return 'Email or Phone'
      case LoginType.SignIn:
        return 'Email address'
      case LoginType.SignUp:
        return 'Your email address'
    }
  }
  const passwordPlaceHolder = () => {
    switch (type) {
      case LoginType.SignInEmail:
      case LoginType.SignIn:
        return 'Password'
      case LoginType.SignUp:
        return 'Create a password'
    }
  }

  const confirmTitle = () => {
    switch (type) {
      case LoginType.SignInEmail:
        return 'CONTINUE TO LOGIN'
      case LoginType.SignIn:
        return 'SIGN IN'
      case LoginType.SignUp:
        return 'SIGN UP'
    }
  }
  return (
    <>
      <EmailLogin
        {...otherProps}
        defaultEmail={defaultEmail}
        emailPlaceHolder={emailPlaceHolder()}
        passwordPlaceHolder={passwordPlaceHolder()}
        confirmTitle={confirmTitle()}
        onClickConfirm={onConfirmEmailLogin}
        isSignUp={type === LoginType.SignUp}
        loginType={type}
      />
      {children}
    </>
  )
}
