import * as route from '../../../services/route'

export enum OBAnalyticsName {
  name_submitted = 'Onboarding: Name Submitted',
  contact_info_submitted = 'Onboarding: Contact Info Submitted',
  trades_selected = 'Onboarding: Trade(s) Selected',
  home_location_submitted = 'Onboarding: Home Location Submitted',
  uncoming_move_submitted = 'Onboarding: Uncoming Move Submitted',
  resume_failed_parsing = 'Onboarding: Resume Failed Parsing',
  resume_parsed = 'Onboarding: Resume Parsed',
  resume_skipped = 'Onboarding: Resume Skipped',
  work_experience_job_added = 'Onboarding: Work Experience (Job) Added',
  work_experience_job_removed = 'Onboarding: Work Experience (Job) Removed',
  work_experience_job_edited = 'Onboarding: Work Experience (Job) Edited',
  work_experience_submitted = 'Onboarding: Work Experience Submitted',
  skill_levels_submitted = 'Onboarding: Skill Levels Submitted',
  certifications_submitted = 'Onboarding: Certifications Submitted',
  travel_distance_submitted = 'Onboarding: Travel Distance Submitted',
  relocation_intent_submitted = 'Onboarding: Relocation Intent Submitted',
  desired_city_added = 'Onboarding: Desired City Added',
  desired_city_removed = 'Onboarding: Desired City Removed',
  pay_rates_submitted = 'Onboarding: Pay Rates Submitted',
  references_submitted = 'Onboarding: References Submitted',
  references_skipped = 'Onboarding: References Skipped',
  ob_app_complete = 'Onboarding: Application Complete',
  languages_submited = 'Onboarding: Languages Submitted',
}

export enum ProfileAnalyticsName {
  profile_edited = 'Profile: Profile Edited',
}

export enum WorkerDashboardAnalyticsName {
  assessment_started = 'Assessments: Assessment Started',
}

export const analyticsPageTitleOB = {
  [`${route.OBPath.onboardingName}`]: 'Skillit - Onboarding Name Page',
  [`${route.OBPath.onboardingEmail}`]: 'Skillit - Onboarding Email Page',
  [`${route.OBPath.onboardingTrades}`]: 'Skillit - Onboarding Trades Page',
  [`${route.OBPath.onboardingLocation}`]:
    'Skillit - Onboarding Home Location Page',
  [`${route.OBPath.onboardingMovePlanning}`]:
    'Skillit - Onboarding Move Planning Page',
  [`${route.OBPath.onboardingExperience}`]:
    'Skillit - Onboarding Experience Page',
  [`${route.OBPath.onboardingLastJobs}`]: 'Skillit - Onboarding Best Jobs Page',
  [`${route.OBPath.onboardingReferences}`]: 'Skillit - Onboarding Reference',
  [`${route.OBPath.onboardingTravelToWork}`]:
    'Skillit - Onboarding Travel To Work Page',
  [`${route.OBPath.onboardingRates}`]: 'Skillit - Onboarding Rates',
  [`${route.OBPath.onboardingWillingToRelocate}`]:
    'Skillit - Onboarding Willing To Relocate Page',
  [`${route.OBPath.onboardingThanks}`]: 'Skillit - Onboarding Thanks Page',
  [`${route.OBPath.onboardingAdditionalInfo}`]:
    'Skillit - Onboarding Unions/Certificats/Language',
  [`${route.OBPath.onboardingUploadResume}`]:
    'Skillit - Onboarding Upload Resume Page',
}

export const analyticsPageTitleWorker = {
  [`${route.dashboard}`]: 'Skillit - Dashboard',
  [`${route.training}`]: 'Skillit - Training',
  [`${route.profile}`]: 'Skillit - Profile',
  [`${route.publicProfile}`]: 'Skillit - Profile',
  [`${route.workPreferences}`]: 'Skillit - Profile',
  [`${route.profileSkillsInfo}`]: 'Skillit - Profile',
  [`${route.profileBasicInfo}`]: 'Skillit - Profile',
  [`${route.workerWelcome}`]: 'Skillit - Welcome',
  [`${route.assessment}`]: 'Skillit - Assessment',
}
