import React from 'react'
import './notPassedForms.scss'
import { PreInterviewTemp } from '../../Templates/PreInterviewTemplate'
import succsessImg from '../../../assets/icons/checkmarks/succsess-int.svg'

export const NotPassedForms = () => {
  return (
    <div className="not-passed-forms preinterview-container">
      <PreInterviewTemp
        imgUrl={succsessImg}
        title="Are you sure?"
        progressBar={false}
      >
        Sorry, Skillit only works with carpenters who are willing to take a drug
        test and background check. Please come back if you change your mind!
      </PreInterviewTemp>
    </div>
  )
}
