import React, { forwardRef } from 'react'
import { useStateWithDep } from '../../../../utils/hooks'
import { ErrorMessageWithCross } from '../../ErrorMessages'
import './ob-input.scss'

interface IOBInput extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: string
  texterror?: string
  clearErrorOnChange?: boolean
}

export const OBInput = forwardRef<HTMLInputElement, IOBInput>((props, ref) => {
  const {
    value,
    placeholder,
    className,
    icon,
    texterror,
    clearErrorOnChange,
    ...otherProps
  } = props

  const [textError, setTextError] = useStateWithDep(texterror)
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearErrorOnChange && setTextError(undefined)
    props.onChange && props.onChange(e)
  }

  return (
    <div className={`ob-input ${className ?? ''}`}>
      {icon && (
        <div
          className={`ob-input__icon-wrapper ${
            props.disabled ? 'ob-input__input_disabled' : ''
          } ${value ? 'ob-input__icon-wrapper_active' : ''}`}
        >
          <img src={icon} alt="action" className="ob-input__icon" />
        </div>
      )}
      <input
        {...otherProps}
        value={value}
        ref={ref}
        onChange={onChange}
        className={`ob-input__input ${value ? 'ob-input__input_active' : ''}`}
        placeholder={placeholder}
      />
      {textError !== undefined && (
        <ErrorMessageWithCross textError={textError} />
      )}
    </div>
  )
})
