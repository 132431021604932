import { EmployerInfo } from '../../../../models'

export const splitCoworkersList = (
  list: EmployerInfo['co_workers']
): {
  preparedList: EmployerInfo['co_workers']
  remainingElCount: number
} => {
  if (list.length > 3) {
    const preparedList = [...list]
    const remElCount = preparedList.splice(3).length
    return {
      preparedList: preparedList,
      remainingElCount: remElCount,
    }
  }
  return {
    preparedList: list,
    remainingElCount: 0,
  }
}
