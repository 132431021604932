import { EDate } from '../../constants'

export const getStartDate = (
  startYear: string,
  months?: string,
  isCurrent?: boolean
): Date | null => {
  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth()
  const isFutureDate = checkIsFutureDate(startYear, months)

  if (isCurrent && !isFutureDate) {
    if (months) {
      const lengthOfYears = Math.floor(+months / 12)
      const lengthOfMonths = +months % 12

      return new Date(
        currentYear - lengthOfYears,
        currentMonth - lengthOfMonths,
        1
      )
    }
  }

  return startYear ? new Date(+startYear, 0, 1) : null
}

export const getEndDate = (
  startYear: string,
  months?: string,
  isCurrent?: boolean
): Date | null => {
  const currentYear = new Date().getFullYear()

  if (isCurrent) return new Date()
  if (months) {
    const getYearsFromMonths = Math.floor(+months / 12)
    const remainingMonths = +months % 12

    return new Date(
      +startYear + getYearsFromMonths || currentYear - getYearsFromMonths,
      remainingMonths
    )
  }

  return null
}

export const getPreparedEndDate = (initEndDate: string, isCurrent: boolean) => {
  if (initEndDate === 'Invalid date') return ''
  return isCurrent ? EDate.present : initEndDate
}

export const checkIsFutureDate = (initDate: string, months?: string) => {
  if (!months) return false

  const yearsFromDate = Math.floor(+months / 12)
  const restMonthsFromDate = +months % 12

  return new Date(+initDate + yearsFromDate, restMonthsFromDate) > new Date()
}
