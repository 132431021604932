import { useMemo, useState } from 'react'
import './popup.scss'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  textList: string
  separator: string
  showItemNumber: number
}

export const Popup = (props: IProps) => {
  const { className, textList, separator, showItemNumber } = props

  const [showPopup, setShowPopup] = useState(false)
  const showedItem = useMemo(
    () => textList.split(separator).slice(0, showItemNumber).join(separator),
    [textList, separator, showItemNumber]
  )

  return (
    <div
      className={`popup ${className ?? ''}`}
      data-testid="popup"
      onMouseEnter={() => setShowPopup(true)}
      onMouseLeave={() => setShowPopup(false)}
    >
      <div className="popup__visible-text">{showedItem}</div>

      {showPopup && <div className="popup__popup-text">{textList}</div>}
    </div>
  )
}
