import React from 'react'
import { PersonModel } from '../../../../models/personModel'
import './personCard.scss'

export const PersonCard = (props: PersonModel) => {
  return (
    <div className="person-card">
      <img src={props.img} alt={props.name} className="person-card__img" />
      <p className="person-card__name">
        <strong>{props.name}</strong>
      </p>
      <p className="person-card__position">{props.position}</p>
      <p className="person-card__descroption">
        {props.description}&nbsp;
        <a target="blank" href={props.link} className="person-card__link link">
          LinkedIn
        </a>
      </p>
    </div>
  )
}
