const arrayOfWeekdays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
const arrayOfMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
export const dayPrefix = (today: number) => {
  let ending = today % 10
  if (ending === 1 && today !== 11) {
    return today + 'st'
  }
  if (ending === 2 && today !== 12) {
    return today + 'nd'
  }
  if (ending === 3 && today !== 13) {
    return today + 'rd'
  }
  return today + 'th'
}

export const dayInText = (day: number) => {
  return arrayOfWeekdays[day]
}

export const monthInText = (month: number) => {
  return arrayOfMonths[month]
}

// Expected date format input - '2021-11-05'
// Expected output - "December 5th"
// Probably in future we should add date format
export const formatDateFrom = (dateStr: string) => {
  const month = +dateStr.substring(5, 7)
  const date = dateStr.substring(8, 10)
  const formattedMonth = monthInText(month - 1)
  const formattedDate = dayPrefix(+date)
  return `${formattedMonth} ${formattedDate}`
}
