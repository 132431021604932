export enum WorkerQueries {
  assessmentResults = 'assessmentResults',
  availableForms = 'AvailableForms',
  publicInfo = 'publicInfo',
  basicInfo = 'basicInfo',
  workPreferencesInfo = 'workPreferencesInfo/',
  drivingInfo = 'drivingInfo',
  skillsInfo = 'skillsInfo',
  obTrades = 'obTrades',
  workerInfoOB = 'workerInfoOB',
  locationInfoOB = 'locationInfoOB',
  completedOB = 'completedOB',
  getRolesForWorker = 'getRolesForWorker',
  getSkillsForWorker = 'getSkillsForWorker',
  customJobsOB = 'customJobsOB',
  oshaInfo = 'oshaInfo',
  clearIncompleteExperience = 'clearIncompleteExperience',
  interviewerInfo = 'interviewerInfo',
  appliedOB = 'appliedOB',
  freeSlots = 'freeSlots',
  InterviewerData = 'InterviewerData',
  homeLocationInfoOB = 'homeLocationInfoOB',
}
