import React, { forwardRef } from 'react'
import { changeColorOfPartText } from '../../../../utils/scripts/changeColorOfPartText'
import { ErrorMessageWithCross } from '../../ErrorMessages'
import locationIcon from './../../../../assets/icons/employer/LocationZipCode.svg'
import './autocompleteInput.scss'

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isSuggVisible: boolean
  icon?: string
  textError?: string
  suggestions: string[]
  onClickSuggestion: (text: string) => void
  isPlaces: boolean
  placeValue: string
}

export const AutocompleteInputUI = forwardRef<HTMLInputElement, IProps>(
  (props, ref) => {
    const {
      isSuggVisible,
      icon,
      className,
      placeholder,
      textError,
      value,
      onChange,
      suggestions,
      onClickSuggestion,
      isPlaces,
      placeValue,
      ...otherProps
    } = props

    return (
      <div className={`auto-c-places-input ${className ?? ''}`}>
        {icon && (
          <div
            className={`auto-c-places-input__icon-wrapper ${
              props.disabled ? 'auto-c-places-input__input_disabled' : ''
            } ${value ? 'auto-c-places-input__icon-wrapper_active' : ''}`}
          >
            <img
              src={icon}
              alt="action"
              className="auto-c-places-input__icon"
            />
          </div>
        )}
        <input
          {...otherProps}
          value={value}
          ref={ref}
          onChange={onChange}
          className={`auto-c-places-input__input ${
            value ? 'auto-c-places-input__input_active' : ''
          }`}
          placeholder={placeholder}
        />
        {textError !== undefined && (
          <ErrorMessageWithCross textError={textError} />
        )}
        {isSuggVisible && !!suggestions.length && (
          <div
            className="auto-c-places-input__suggestions"
            data-testid="auto-c-places-input__suggestions"
          >
            {suggestions.map((suggestion, i) => {
              return (
                <div
                  key={`${suggestion + i}`}
                  className="auto-c-places-input__suggestions-element"
                  onClick={() => onClickSuggestion(suggestion)}
                  data-testid="auto-c-places-input__suggestions-element"
                >
                  {isPlaces && (
                    <img
                      className="auto-c-places-input__suggestions-element-icon"
                      src={locationIcon}
                      alt="marker"
                    />
                  )}
                  <div
                    className="auto-c-places-input__suggestions-element-text"
                    style={{ marginLeft: `${isPlaces ? 0 : 37}px` }}
                    data-testid="auto-c-places-input__suggestions-element-text"
                  >
                    {changeColorOfPartText(
                      suggestion,
                      !placeValue && typeof value === 'string'
                        ? value
                        : placeValue,
                      false
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
)
