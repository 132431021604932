import React, { useCallback, useState } from 'react'
import './workExperienceModal.scss'
import {
  AnswerWorkExperience,
  PublicInfo,
  ResultWorkExperience,
} from '../../../../../../models'
import {
  blueColor,
  greenColor,
  publicWorkExperience,
} from '../workExperienceFuncAndVariables'
import { WorkExperienceModalUI } from './WorkExperienceModalUI'
import { EJobFields } from '../../../../../../pages/Onboarding/OBLastJobs/OBLastJobs'
import {
  checkIsFutureDate,
  inputFormatting,
} from '../../../../../../utils/scripts'

export interface Props extends React.HTMLAttributes<HTMLElement> {
  handleSubmit: (
    e: React.FormEvent<HTMLFormElement>,
    modalWorkExp: ResultWorkExperience[]
  ) => void
  publicInfo: PublicInfo
  workExperience: ResultWorkExperience[]
  isConfirmationModal: boolean
  setIsConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>
  setIsChanged: React.Dispatch<React.SetStateAction<boolean>>
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const WorkExperienceModal = (props: Props) => {
  const {
    handleSubmit,
    workExperience,
    isConfirmationModal,
    setIsConfirmationModal,
    setIsChanged,
    setIsModalVisible,
  } = props

  const [modalWorkExp, setModalWorkExp] = useState(
    publicWorkExperience(workExperience.map(el => el.questions_work_experience))
  )

  const addNewJob = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      setModalWorkExp(prev => [
        ...prev,
        {
          job_number: prev.length + 1,
          questions_work_experience: {
            company_name: '',
            job_title: '',
            location: '',
            start_date: '',
            end_date: '',
            is_current: '',
          },
          color: (prev.length + 1) % 2 !== 0 ? greenColor : blueColor,
        },
      ])
      setIsChanged(true)
    },
    [setIsChanged]
  )

  const removeJob = useCallback(
    (
      e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
      jobNumber: number
    ) => {
      e.preventDefault()
      setModalWorkExp(prevQuestions => {
        return prevQuestions
          .filter(prevWork => prevWork.job_number !== jobNumber)
          .map(job => {
            if (job.job_number > jobNumber) {
              return {
                ...job,
                job_number: job.job_number - 1,
                color: (job.job_number - 1) % 2 !== 0 ? greenColor : blueColor,
              }
            } else return job
          })
      })
      setIsChanged(true)
    },
    [setIsChanged]
  )

  const formattingDates = (inputValue: string, fieldName: string) => {
    let preparedValue = ''

    if (fieldName === EJobFields.start_date) {
      const digitalValue = inputValue.replace(/[^\d]/g, '')
      preparedValue =
        +digitalValue > new Date().getFullYear()
          ? `${new Date().getFullYear()}`
          : digitalValue
    }

    if (fieldName === EJobFields.end_date) {
      preparedValue = inputValue.replace(/[^\d]/g, '').substring(0, 3)
    }

    return preparedValue
  }

  const changeWorkExpData = useCallback(
    (
      inputName: string,
      inputValue: string,
      field: AnswerWorkExperience,
      key: string
    ) => {
      const workExpData = field

      if (inputName === EJobFields.start_date) {
        workExpData[key] = formattingDates(inputValue, EJobFields.start_date)
      } else if (inputName === EJobFields.end_date) {
        workExpData[key] = formattingDates(inputValue, EJobFields.end_date)
      } else {
        workExpData[key] = inputFormatting(inputValue, 60)
      }
      return workExpData
    },
    []
  )

  const onChangeInputValue = useCallback(
    (inputValue: string, numberJob: number, inputName: string) => {
      setModalWorkExp(prevQuestions => {
        const changeableWork = prevQuestions.find(
          prevWork => prevWork.job_number === numberJob
        )

        if (changeableWork?.questions_work_experience) {
          for (let key in changeableWork?.questions_work_experience) {
            const field = changeableWork?.questions_work_experience

            if (inputName === key) {
              field[key] = changeWorkExpData(inputName, inputValue, field, key)[
                key
              ]
            }
          }
        }

        return prevQuestions.map(prevWork => {
          if (prevWork.job_number === changeableWork?.job_number) {
            return changeableWork
          } else return prevWork
        })
      })
      setIsChanged(true)
    },
    [setIsChanged, changeWorkExpData]
  )

  const getYearAndMonths = (startDate: string, endDate: string) => {
    const currentYear = new Date().getFullYear()
    const yearsDifference = currentYear - +startDate

    const data = {
      startYear: startDate,
      months: endDate,
    }

    const isFutureDate = checkIsFutureDate(startDate, endDate)

    if (startDate && endDate && isFutureDate) {
      data.months = `${yearsDifference * 12 + new Date().getMonth()}`
    } else if (endDate) {
      const remainingMonth = +endDate + 1 - (new Date().getMonth() + 1)
      const lengthOfYears = Math.ceil(remainingMonth / 12)

      data.startYear =
        lengthOfYears > 0 ? `${currentYear - lengthOfYears}` : `${currentYear}`
    } else if (startDate) {
      data.months = `${yearsDifference * 12 + new Date().getMonth()}`
    }

    return data
  }

  const toggleCheckbox = useCallback(
    (id: number, isChecked: boolean) => {
      setModalWorkExp(prevQuestions => {
        const changeableWork = prevQuestions.find(
          prevWork => prevWork.job_number === id
        )

        return prevQuestions.map(prevWork => {
          if (prevWork.job_number === changeableWork?.job_number) {
            const preparedData = getYearAndMonths(
              changeableWork?.questions_work_experience.start_date,
              changeableWork?.questions_work_experience.end_date
            )

            if (!isChecked) {
              return {
                ...changeableWork,
                questions_work_experience: {
                  ...changeableWork.questions_work_experience,
                  start_date: preparedData.startYear,
                  end_date: preparedData.months,
                  is_current: 'true',
                },
              }
            } else {
              return {
                ...changeableWork,
                questions_work_experience: {
                  ...changeableWork.questions_work_experience,
                  is_current: '',
                },
              }
            }
          } else return prevWork
        })
      })

      setIsChanged(true)
    },
    [setIsChanged]
  )

  return (
    <WorkExperienceModalUI
      {...props}
      setIsModalVisible={setIsModalVisible}
      setIsChanged={setIsChanged}
      isConfirmationModal={isConfirmationModal}
      setIsConfirmationModal={setIsConfirmationModal}
      handleSubmit={handleSubmit}
      modalWorkExp={modalWorkExp}
      onChangeInputValue={onChangeInputValue}
      removeJob={removeJob}
      addNewJob={addNewJob}
      toggleCheckbox={toggleCheckbox}
    />
  )
}
