import { Helmet } from 'react-helmet'
import {
  CompletionCard,
  DashboardAssessmentModal,
  DashboardHeader,
  ModalBox,
} from '../../../../components/molecules'
import { ProgressWidget } from '../../../../components/molecules/Worker/ProgressWidget'
import {
  CareerGuideCall,
  CareerGuideChat,
} from '../../../../components/organism'
import {
  AssessmentInfo,
  AssessmentResult,
  CareerGuideInfo,
  ProfileCompletionInfo,
  PublicInfo,
} from '../../../../models'
import { EWorkerPageTitle } from '../../../../utils/constants'
import { formatPhoneNumber } from '../../../../utils/scripts'
import takeAssessImg from '../../../../assets/icons/worker/takeAssessImg.svg'
import profileCardImg from '../../../../assets/icons/worker/profileCard.svg'
import toolsImg from '../../../../assets/icons/worker/tools-dashboard.svg'
import call from '../../../../assets/icons/worker/call-dashboard.svg'
import './dashboard.scss'
import { useMemo } from 'react'

interface Props {
  user?: ProfileCompletionInfo
  publicInfo?: PublicInfo
  timeZoneAbr: string
  profileToggler: () => void
  isProfileCompleted: boolean
  isAssessmentsSuccess: boolean
  isAssessModalVisible: boolean
  assessments?: AssessmentResult[]
  isCareerGuideModalVisible: boolean
  careerGuideInfo?: CareerGuideInfo | null
  assessmentAvailableForms?: AssessmentInfo
  setIsAssessModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  setIsCareerGuideModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  phone?: string
  setCareerGuideInfo: React.Dispatch<
    React.SetStateAction<CareerGuideInfo | null>
  >
}

export const DashboardUI = (props: Props) => {
  const {
    user,
    publicInfo,
    assessments,
    timeZoneAbr,
    profileToggler,
    careerGuideInfo,
    isProfileCompleted,
    isAssessmentsSuccess,
    isAssessModalVisible,
    setIsAssessModalVisible,
    assessmentAvailableForms,
    isCareerGuideModalVisible,
    setIsCareerGuideModalVisible,
    setCareerGuideInfo,
    phone,
  } = props

  const isCompletedGuideCall = user?.progress.career_guide_call.is_complete
  const guideCallMsg = careerGuideInfo
    ? 'Career Guide Call'
    : 'Chat with your Career Guide'
  const interviewerName = useMemo(
    () =>
      careerGuideInfo?.name?.split(' ').length === 2
        ? careerGuideInfo?.name?.split(' ')[0]
        : careerGuideInfo?.name,
    [careerGuideInfo?.name]
  )

  return (
    <div className="dashboard">
      <Helmet>
        <title>{EWorkerPageTitle.dashboard}</title>
      </Helmet>
      {isAssessModalVisible && (
        <ModalBox
          className="dashboard__assessment-modal-box"
          onCloseModal={() => setIsAssessModalVisible(false)}
          visibleCloseIcon={true}
        >
          <DashboardAssessmentModal
            availableForms={assessmentAvailableForms?.type_forms ?? []}
          />
        </ModalBox>
      )}
      <DashboardHeader
        name={`${publicInfo?.first_name}`}
        userPhoto={publicInfo?.profile_picture ?? undefined}
      />
      <main className="dashboard__main container">
        <ProgressWidget user={user} />
        <section className="dashboard__section">
          <CompletionCard
            className={`dashboard__section-item ${
              isProfileCompleted ? ' dashboard__section-item_completed' : ''
            }`}
            completed={isProfileCompleted}
            icon={profileCardImg}
            title={
              isProfileCompleted ? 'Profile Complete!' : 'Complete Your Profile'
            }
            actionBtn={{
              text: `${
                isProfileCompleted ? 'View profile' : 'Complete Profile'
              }`,
              action: () => profileToggler(),
            }}
          >
            Having a complete profile will give potential employers more
            information about you and your skills and help get you the right
            job.
          </CompletionCard>

          <CompletionCard
            className={`dashboard__section-item ${
              isCompletedGuideCall ? ' dashboard__section-item_completed' : ''
            }`}
            completed={isCompletedGuideCall ?? false}
            icon={call}
            title={
              isCompletedGuideCall
                ? 'Skillit Welcome Call Complete'
                : guideCallMsg
            }
            actionBtn={
              !isCompletedGuideCall && !careerGuideInfo
                ? {
                    text: 'Schedule Call',
                    action: () => setIsCareerGuideModalVisible(true),
                  }
                : undefined
            }
          >
            {careerGuideInfo ? (
              <CareerGuideCall
                name={interviewerName ?? null}
                phone={
                  formatPhoneNumber(
                    phone?.includes('+') ? phone.slice(2) : phone
                  ) ?? null
                }
                date={`${careerGuideInfo.interview_date} ${timeZoneAbr}`}
              />
            ) : (
              <CareerGuideChat
                setCareerGuideInfo={setCareerGuideInfo}
                isCareerGuideModalVisible={isCareerGuideModalVisible}
                setIsCareerGuideModalVisible={setIsCareerGuideModalVisible}
              />
            )}
          </CompletionCard>

          {assessmentAvailableForms?.type_forms &&
          assessmentAvailableForms.type_forms.length > 0 ? (
            <CompletionCard
              className="dashboard__section-item"
              completed={false}
              icon={takeAssessImg}
              title="Take Assessment"
              actionBtn={{
                text: 'Start Assessment',
                action: () => setIsAssessModalVisible(true),
              }}
            >
              Take an assessment to determine what aspects of construction work
              you are proficient at and which need some improvement.
            </CompletionCard>
          ) : null}
          {isAssessmentsSuccess &&
            assessments?.map(assessment => {
              return (
                <CompletionCard
                  key={assessment.name}
                  className="dashboard__section-item  dashboard__section-item_completed"
                  completed={true}
                  icon={takeAssessImg}
                  title={`${assessment.name} Complete`}
                >
                  Curabitur blandit tempus porttitor. Cras justo odio, dapibus
                  ac facilisis in, egestas eget quam.
                </CompletionCard>
              )
            })}
        </section>

        <div className="dashboard__separator-text">
          <h3>Check These Out Too </h3>
          <div className="dashboard__line"></div>
        </div>

        <section className="dashboard__section">
          <CompletionCard
            className="dashboard__section-item"
            completed={false}
            icon={toolsImg}
            title="Learn a New Skill"
            actionBtn={{
              text: 'Explore Classes',
              action: () => {
                window.open('https://mtcopeland.com/', '_blank', 'noopener')
              },
            }}
          >
            Advance your career by obtaining a new skill or perfecting an
            existing one.
          </CompletionCard>
        </section>
      </main>
    </div>
  )
}
