import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useMutation } from 'react-query'

import { toast } from 'react-toastify'
import {
  IWorkerInfoAdminPage,
  IWorkerStatus,
} from '../../../../models/AdminWorkes'
import { editWorkerStatus } from '../../../../services/adminWorkersAPI'
import { ECompletionStatusOB } from '../../../../utils/constants'
import { WorkersDataForAdminContext } from '../../../Context'
import { StatusTogglersUI } from './StatusTogglersUI'

interface IProps extends React.HTMLAttributes<HTMLElement> {
  worker: IWorkerInfoAdminPage
}

export type TStatusList = {
  verified: boolean
  approved: boolean
  rejected: boolean
  hubspot: boolean
}

export const StatusTogglers = (props: IProps) => {
  const { worker } = props
  const setWorkersList = useContext(WorkersDataForAdminContext).setWorkersList
  const wandLinkParams = `?transit-to-worker-token=${worker.login_as_token}`
  const wandLink = `${window.location.host}/transit-to-worker${wandLinkParams}`

  const [isRejectModalVisible, setIsRejectModalVisible] =
    useState<boolean>(false)
  const [isApproveModalVisible, setIsApproveModalVisible] =
    useState<boolean>(false)
  const [isVerifyModalVisible, setIsVerifyModalVisible] =
    useState<boolean>(false)

  const isVerifyActive: boolean = useMemo(() => {
    return (
      worker.status === ECompletionStatusOB.applied ||
      worker.status === ECompletionStatusOB.approved ||
      worker.status === ECompletionStatusOB.rejected
    )
  }, [worker.status])

  const isApproveActive: boolean = useMemo(() => {
    return (
      worker.status === ECompletionStatusOB.applied ||
      worker.status === ECompletionStatusOB.verified ||
      worker.status === ECompletionStatusOB.rejected
    )
  }, [worker.status])

  const isRejectActive: boolean = useMemo(() => {
    return (
      worker.status === ECompletionStatusOB.applied ||
      worker.status === ECompletionStatusOB.verified ||
      worker.status === ECompletionStatusOB.approved
    )
  }, [worker.status])

  const workerStatusMutation = useMutation(
    (data: IWorkerStatus) => editWorkerStatus(data, worker.id),
    {
      onError: err => {
        if (err instanceof Error) {
          toast.error(err.message)
        }
      },
    }
  )

  const saveLink = useCallback(() => {
    navigator.clipboard.writeText(wandLink).then(() => {
      toast.success('Login link copied to the clipboard', {
        autoClose: 1500,
      })
    })
  }, [wandLink])

  const statusHandler = useCallback(
    (status: IWorkerStatus) => {
      workerStatusMutation.mutate(status, {
        onSuccess() {
          setWorkersList(prevWorkersList => {
            return prevWorkersList.map(workerFromList =>
              workerFromList.id === worker.id
                ? { ...workerFromList, status: status.status }
                : workerFromList
            )
          })
          setIsRejectModalVisible(false)
          setIsVerifyModalVisible(false)
          setIsApproveModalVisible(false)
        },
      })
    },
    [workerStatusMutation, setWorkersList, worker.id]
  )

  const onClickStatus = useCallback(
    (status: ECompletionStatusOB | 'wandButton') => {
      if (status === 'wandButton') {
        saveLink()
      }
      if (status === ECompletionStatusOB.verified && isVerifyActive) {
        setIsVerifyModalVisible(true)
      }
      if (status === ECompletionStatusOB.approved && isApproveActive) {
        setIsApproveModalVisible(true)
      }
      if (status === ECompletionStatusOB.rejected && isRejectActive) {
        setIsRejectModalVisible(true)
      }
    },
    [isRejectActive, isApproveActive, isVerifyActive, saveLink]
  )

  return (
    <StatusTogglersUI
      {...props}
      worker={worker}
      onClickStatus={onClickStatus}
      isVerifyActive={isVerifyActive}
      isApproveActive={isApproveActive}
      isRejectModalVisible={isRejectModalVisible}
      setIsRejectModalVisible={setIsRejectModalVisible}
      isVerifyModalVisible={isVerifyModalVisible}
      setIsVerifyModalVisible={setIsVerifyModalVisible}
      isApproveModalVisible={isApproveModalVisible}
      setIsApproveModalVisible={setIsApproveModalVisible}
      isRejectActive={isRejectActive}
      statusHandler={statusHandler}
      savingStatus={workerStatusMutation.isLoading}
      wandLink={wandLink}
    />
  )
}
