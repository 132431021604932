import { IWorkerInfoOB } from '../../../../models'
import { EWorkerSStoreKeys } from '../../../constants'

export const getUserInfoFromStorage = <T = IWorkerInfoOB>(
  key: EWorkerSStoreKeys = EWorkerSStoreKeys.obData
): T | null => {
  const sessionStorageData: string | null = sessionStorage.getItem(key)
  const userDataFromStorage: T | null = sessionStorageData
    ? JSON.parse(sessionStorageData)
    : null

  return userDataFromStorage
}
