import React, { useCallback, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import './progressBarLinear.scss'
import { useTranslation } from 'react-i18next'

interface IBlockLinesPB {
  title: string
  amountLines: number
}

interface Props extends React.HTMLAttributes<HTMLElement> {
  initData: IBlockLinesPB[]
  currentPage: number
}

export const ProgressBarLinear = React.memo((props: Props) => {
  const { initData, currentPage } = props
  const { t } = useTranslation()
  let countLines = 0

  const totalLines = useMemo(
    () =>
      initData
        .map(block => block.amountLines)
        .reduce((first, last) => first + last, 0),
    [initData]
  )

  const columnsPercent = useMemo(
    () =>
      initData.map(block =>
        (100 * (block.amountLines / totalLines)).toFixed(2)
      ),
    [initData, totalLines]
  )

  const getGridColumnStyle = useCallback((percents: string[]) => {
    let gridTemplateColumnsStyle = ''

    percents.forEach(percent => {
      gridTemplateColumnsStyle += `minmax(max-content, ${percent}%) `
    })

    return gridTemplateColumnsStyle
  }, [])

  return (
    <div
      className={`progress-linear ${props.className ?? ''}`}
      style={{ gridTemplateColumns: getGridColumnStyle(columnsPercent) }}
    >
      {initData.map((block, index) => {
        const arrayLinesInBlock = new Array(block.amountLines).fill(1)

        return (
          <div
            key={block.title}
            className="progress-linear__block"
            data-testid="progress-linear__block"
          >
            <p
              className={`progress-linear__block-title ${
                currentPage > countLines ? 'active-title' : ''
              }`}
              data-testid="progress-linear__block-title"
            >
              {t(`Shared_Header.${block.title}`)}
            </p>
            <div
              className="progress-linear__block-wrapper-lines"
              data-testid="progress-linear__block-wrapper-lines"
            >
              {arrayLinesInBlock.map(line => {
                countLines++
                return (
                  <div
                    key={uuidv4()}
                    className={`progress-linear__block-line ${
                      currentPage >= countLines ? 'active-line' : ''
                    }`}
                    data-testid={`${
                      currentPage >= countLines ? 'active-line' : ''
                    }`}
                  />
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
})
