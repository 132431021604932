import { emailLogin, useAppDispatch } from '../../../redux'
import { useEffect } from 'react'
import { setAuthToken } from '../../../services/setAuthToken'

export const useAuthUserByToken = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const pathName: string = window.location.pathname
    const tokenName = `${pathName.split('/')[1]}-token`
    const token = urlParams.get(tokenName) ?? ''
    const fromPath = urlParams.get('from')

    switch (pathName) {
      case '/transit-to-worker':
        setAuthToken(token)
        break

      case '/email-login':
        fromPath && sessionStorage.setItem('from', fromPath)
        dispatch(emailLogin({ oneTimeToken: token }))
        break
      case '/editing-form':
        fromPath && sessionStorage.setItem('from', fromPath)
        setAuthToken(token)
        break
    }
  }, [dispatch])
}
