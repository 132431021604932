import { OBThanksUI } from './OBThanksUI'
import './obThanks.scss'
import { useIdentifyActions, useTypedSelector } from '../../../utils/hooks'
import { useCallback, useEffect } from 'react'
import {
  EWorkerSStoreKeys,
  WorkerQueries,
  analyticsPageTitleOB,
} from '../../../utils/constants'
import { onEventPage } from '../../../utils/scripts'
import * as route from '../../../services/route'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { getInterviewerData } from '../../../services/workerApi'
import { QueryState } from 'react-query/types/core/query'
import { InterviewerModel } from '../../../models'

export const OBThanks = () => {
  useIdentifyActions()
  const userInfo = useTypedSelector(state => state.obInfo.workerInfo.user_data)
  const name = userInfo?.first_name || ''
  const location = useLocation()
  const language = window.navigator.language
  const { i18n } = useTranslation()
  const queryClient = useQueryClient()

  const interviewerInfoQuery:
    | QueryState<InterviewerModel, undefined>
    | undefined = queryClient.getQueryState(WorkerQueries.interviewerInfo)

  const { data: interviewerInfo, isLoading } = useQuery(
    WorkerQueries.interviewerInfo,
    () => getInterviewerData(),
    { enabled: !interviewerInfoQuery?.data }
  )

  const disableBackHistoryBtn = useCallback(() => {
    window.history.pushState(null, '', window.location.href)
  }, [])

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [i18n, language])

  useEffect(() => {
    disableBackHistoryBtn()
    window.addEventListener('popstate', disableBackHistoryBtn)

    onEventPage(
      location.pathname,
      analyticsPageTitleOB[route.OBPath.onboardingThanks]
    )
    sessionStorage.removeItem(EWorkerSStoreKeys.obData)
    sessionStorage.removeItem(EWorkerSStoreKeys.obLocation)
    localStorage.clear()

    return () => {
      window.removeEventListener('popstate', disableBackHistoryBtn)
    }
  }, [disableBackHistoryBtn, location.pathname])

  return (
    <OBThanksUI
      name={name}
      isLoading={isLoading}
      interviewerInfo={interviewerInfo}
    />
  )
}
