import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { OBLocationUI } from './OBLocationUI'
import * as route from '../../../services/route'
import { useHistory } from 'react-router-dom'
import { setWorkerInfo, useAppDispatch } from '../../../redux'
import {
  usePageNumUpdateOB,
  useTypedSelector,
  useMediaContext,
} from '../../../utils/hooks'
import {
  ILocationInfoOB,
  IWorkerInfoOB,
  TRequestHomeLocation,
} from '../../../models'
import { useMutation, useQueryClient } from 'react-query'
import { QueryState } from 'react-query/types/core/query'
import {
  EWorkerSStoreKeys,
  OBAnalyticsName,
  WorkerQueries,
} from '../../../utils/constants'
import { updateLocationInfoOB } from '../../../services/workerApi'
import { toast } from 'react-toastify'
import {
  getUserInfoFromStorage,
  onTrackingActions,
} from '../../../utils/scripts'
import { getLastPageState } from '../../../utils/hooks/obHooks/usePageNumUpdateOB'

export const OBLocation = () => {
  const userInfoFromStorage = useMemo(() => getUserInfoFromStorage(), [])
  const defaultHomeLocationInfo: ILocationInfoOB = useMemo(
    () => ({
      home_location: {
        zip_code: null,
        longitude: null,
        latitude: null,
        state: null,
        city: null,
        street: null,
        raw_input: null,
      },
      relocation_location: null,
      preferred_locations: null,
      willing_to_relocate: null,
    }),
    []
  )
  const homeLocationFromStorage = useMemo(() => {
    if (!userInfoFromStorage) return null
    const location = { ...defaultHomeLocationInfo }
    location.home_location = userInfoFromStorage.user_data.home_location
    location.relocation_location =
      userInfoFromStorage.user_data.relocation_location
    location.preferred_locations =
      userInfoFromStorage.user_data.preferred_locations
    location.willing_to_relocate =
      userInfoFromStorage.user_data.willing_to_relocate
    return location
  }, [defaultHomeLocationInfo, userInfoFromStorage])
  const history = useHistory()
  const isMobile = useMediaContext()
  const dispatch = useAppDispatch()
  const userInfo = useTypedSelector(s => s.obInfo.workerInfo)
  const pageStateOBMutation = usePageNumUpdateOB()

  const lastPage =
    userInfo.last_page < 7 ? (isMobile ? 8 : 7) : userInfo.last_page
  const lastPageState = useMemo(() => getLastPageState(lastPage), [lastPage])
  const queryClient = useQueryClient()

  const obLocationInfoQuery:
    | QueryState<ILocationInfoOB | undefined>
    | undefined = queryClient.getQueryState(WorkerQueries.homeLocationInfoOB)

  const obLocationInfoMutation = useMutation(
    (data: TRequestHomeLocation) => updateLocationInfoOB(data),
    {
      onSuccess: (newData: TRequestHomeLocation) => {
        queryClient.setQueryData(WorkerQueries.homeLocationInfoOB, newData)
      },
    }
  )

  const [locationOB, setLocationOB] = useState<ILocationInfoOB>(
    homeLocationFromStorage ??
      obLocationInfoQuery?.data ??
      defaultHomeLocationInfo
  )
  const isHomeLocationChanged = useMemo(
    () =>
      JSON.stringify(userInfo.user_data.home_location) !==
      JSON.stringify(locationOB.home_location),
    [userInfo.user_data.home_location, locationOB.home_location]
  )

  const getNewData = useCallback(
    (data: IWorkerInfoOB) => ({
      ...data,
      last_page: lastPage,
      last_location: lastPageState.last_location,
      user_data: {
        ...data.user_data,
        home_location: locationOB.home_location,
      },
    }),
    [lastPage, locationOB.home_location, lastPageState.last_location]
  )

  const onClickBack = useCallback(() => {
    sessionStorage.setItem(
      EWorkerSStoreKeys.obData,
      JSON.stringify(getNewData(userInfoFromStorage ?? userInfo))
    )
    history.push(route.OBPath.onboardingExperience)
  }, [history, getNewData, userInfo, userInfoFromStorage])

  const onClickNext = useCallback(
    async (
      initLocationOB: ILocationInfoOB,
      initSetLocationOB: React.Dispatch<React.SetStateAction<ILocationInfoOB>>
    ) => {
      if (!isHomeLocationChanged) {
        sessionStorage.removeItem(EWorkerSStoreKeys.placeAutocomplete)
        isMobile || userInfo.user_data.custom_jobs
          ? history.push(route.OBPath.onboardingLastJobs)
          : history.push(route.OBPath.onboardingUploadResume)
      } else {
        await onTrackingActions(OBAnalyticsName.home_location_submitted)

        const preparedHomeLocation = {
          home_location: {
            zip_code: initLocationOB.home_location.zip_code,
            longitude: initLocationOB.home_location.longitude,
            latitude: initLocationOB.home_location.latitude,
            state: initLocationOB.home_location.state,
            city: initLocationOB.home_location.city,
            street: initLocationOB.home_location.street,
            raw_input: initLocationOB.home_location.raw_input,
          },
        }

        obLocationInfoMutation.mutate(preparedHomeLocation, {
          onSuccess: () => {
            pageStateOBMutation.mutate(lastPageState, {
              onSuccess() {
                initSetLocationOB(initLocationOB)
                dispatch(setWorkerInfo(getNewData(userInfo)))
                sessionStorage.setItem(
                  EWorkerSStoreKeys.obData,
                  JSON.stringify(getNewData(userInfoFromStorage ?? userInfo))
                )
                sessionStorage.removeItem(EWorkerSStoreKeys.placeAutocomplete)
                isMobile || userInfo.user_data.custom_jobs
                  ? history.push(route.OBPath.onboardingLastJobs)
                  : history.push(route.OBPath.onboardingUploadResume)
              },
            })
          },
          onError: err => {
            if (err instanceof Error) toast.error(err.message)
          },
        })
      }
    },
    [
      dispatch,
      history,
      isHomeLocationChanged,
      userInfoFromStorage,
      getNewData,
      obLocationInfoMutation,
      pageStateOBMutation,
      userInfo,
      isMobile,
      lastPageState,
    ]
  )

  useEffect(() => {
    const info = userInfoFromStorage ?? userInfo
    info.user_data?.home_location?.longitude &&
      setLocationOB({
        ...defaultHomeLocationInfo,
        home_location: info.user_data?.home_location,
      })
  }, [userInfo, userInfoFromStorage, defaultHomeLocationInfo])

  return (
    <OBLocationUI
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      setLocationOB={setLocationOB}
      locationOB={locationOB}
      isWaitingResponse={
        obLocationInfoMutation.isIdle ? false : !obLocationInfoMutation.error
      }
    />
  )
}
