export enum EAdminPageTitle {
  adminWorkers = 'Skillit - Admin Workers',
}

export enum EFormDataKeys {
  resume = 'resume',
  data = 'data',
}

export const verifyAttendance = [
  'Connected first attempt',
  'Attended call',
  'Attended call on time',
  'Remained on call until end',
  'Scheduled Call / Called in',
]

export const verifyProfessionalism = [
  'Great communicator',
  'Good phone etiquette',
  'Prior good phone or text etiquette ',
  'Was polite',
  'Seems ethical',
]

export const verifyMotivation = [
  'Seems eager to work',
  'Was engaged on the call',
  'Seems interested in career advancement',
  'Appears good to work alongside',
  'Has a complete profile prior',
]
