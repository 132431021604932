import { ISkills } from '../../../models'
import { RoleNames } from '../../constants'

export const roleSorting = (a: string, b: string) => {
  if (b === RoleNames.other_trade) return -1
  if (a === RoleNames.other_trade) return 1
  return a.toLowerCase().localeCompare(b.toLowerCase())
}
export const skillsSorting = (a: ISkills, b: ISkills) =>
  a.choice.toLowerCase().localeCompare(b.choice.toLowerCase())
