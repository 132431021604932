import React from 'react'
import './publicBlock.scss'
import { Link } from 'react-router-dom'
import * as route from '../../../../../services/route'
import { toast } from 'react-toastify'
import { updatePublicInfo } from '../../../../../services/workerApi/profileAPI'
import { SwitchBtn, Button } from '../../../../atoms'
import {
  ProfileAnalyticsName,
  WorkerQueries,
} from '../../../../../utils/constants'
import { onTrackingActions } from '../../../../../utils/scripts'
import { PublicInfo } from '../../../../../models'
import { QueryState } from 'react-query/types/core/query'
import { useMutation, useQueryClient } from 'react-query'
import { ERROR_SOMETHING } from '../../../../../services/auth-service'

interface Props extends React.HTMLAttributes<HTMLElement> {
  name: string
  progressCircle: JSX.Element
  userPhoto: string
  publicSeekingStatus?: boolean
}

export const PublicBlock = (props: Props) => {
  const { name, progressCircle, userPhoto, publicSeekingStatus } = props
  const queryClient = useQueryClient()

  const publicInfoQuery: QueryState<PublicInfo, undefined> | undefined =
    queryClient.getQueryState(WorkerQueries.publicInfo)

  const seekingStatusMutation = useMutation(
    (data: { seeking_status: boolean }) => updatePublicInfo(data)
  )

  const switchBtnHandler = (status: boolean) => {
    const updatedPublicInfo = {
      ...publicInfoQuery?.data,
      seeking_status: status,
    }
    seekingStatusMutation.mutate(
      { seeking_status: status },
      {
        onSuccess: () => {
          onTrackingActions(ProfileAnalyticsName.profile_edited)
          queryClient.setQueryData(WorkerQueries.publicInfo, updatedPublicInfo)
        },
        onError: err => {
          if (err instanceof Error) toast.error(ERROR_SOMETHING)
        },
      }
    )
  }

  return (
    <div className="public-block container" data-testid="public-block">
      <div className="public-block__section">
        <div className="public-block__user-profile">
          <img
            className="public-block__user-photo"
            src={userPhoto}
            alt="user_photo"
          />
          <SwitchBtn
            name={name}
            defaultStatus={publicSeekingStatus}
            statusHandler={status => switchBtnHandler(status)}
            enabledStatusName="Currently Seeking Work"
            disabledStatusName="Not Seeking Work"
          />
          {progressCircle}
        </div>
      </div>
      <Link className="public-block__link" to={route.publicProfile}>
        <Button className="public-block__button profile_white-btn">
          edit public profile
        </Button>
      </Link>
    </div>
  )
}
