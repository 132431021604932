import img1 from '../../../../assets/img/worker/How_to_ace_your_foreman_interview_IMAGE.png'
import img2 from '../../../../assets/img/worker/blog/second_blog-img.png'
import img3 from '../../../../assets/img/worker/IntroducingImg.png'

export const blogData = [
  {
    id: 1,
    imgUrl: img1,
    date: new Date(),
    author: 'Nicole Kaes',
    title: 'How to ace your carpenter foreman interview',
    description:
      "From the simple dos and don'ts to getting the dress code juuuust right, Skillit shares our customer's tips to nailing (no pun intended) your next Carpenter Foreman interview.",
    link: '../../components/organism/BlogList/FirstBlog',
  },
  {
    id: 2,
    imgUrl: img2,
    date: new Date(),
    author: 'Mindy Otten-Chen',
    title: '5 tips for becoming a better carpenter',
    description:
      'Lead Finish Carpenter Mindy-Otten Chen offers up some advice to fellow carpenters looking to boost their career.',
    link: '../../components/organism/BlogList/SecondBlog',
  },
  {
    id: 3,
    imgUrl: img3,
    date: new Date(),
    author: 'Fraser Patterson',
    title: 'Introducing Skills Premiums for carpenters',
    description:
      'The nation’s top construction firms are paying skills-assessed carpenters a premium for their personal mix of carpentry skills and knowledge.',
    link: '../../components/organism/BlogList/ThirdBlog',
  },
]
