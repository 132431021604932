import './interviewEmployerCard.scss'

interface Props extends React.HTMLAttributes<HTMLElement> {
  title: string
  description: string
  photo?: string | null
  name: string
  titleColor: string
}

export const InterviewEmployerCard = (props: Props) => {
  const { title, description, photo, name, className, titleColor } = props
  return (
    <div className={`interview-employer-card ${className}`}>
      <p
        className={`interview-employer-card__title interview-employer-card__title--${titleColor}`}
      >
        {title}
      </p>

      <div className="interview-employer-card__info">
        {photo ? (
          <img
            className="interview-employer-card__info-photo"
            src={photo}
            alt="employerPhoto"
          />
        ) : (
          <div className="interview-employer-card__info-default-photo">
            {name[0]}
          </div>
        )}
        <div className="interview-employer-card__info-description">
          <p className="interview-employer-card__info-description_name">
            {name}
          </p>
          <p className="interview-employer-card__info-description_text">
            {description}
          </p>
        </div>
      </div>
    </div>
  )
}
