import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import buble from '../../../../assets/icons/other/buble_img.svg'
import searchFile from '../../../../assets/icons/other/file-check.svg'
import './readingResume.scss'

export const ReadingResume = () => {
  const { t, i18n } = useTranslation()

  return (
    <div className="reading-resume">
      <div className="reading-resume__img-wrapper">
        <img src={buble} alt="buble" className="reading-resume__img-buble" />
        <img
          src={searchFile}
          alt="check"
          className="reading-resume__img-check"
        />
      </div>
      <div className="reading-resume__text">
        <p className="reading-resume__action-text">
          {i18n.language.slice(0, 2) === 'es' ? (
            <Trans i18nKey="upload_resume.description-for-Trans">
              Estamos leyendo tu currículum
              <code />
              para aprender sobre ti
            </Trans>
          ) : (
            <Trans i18nKey="upload_resume.description-for-Trans">
              We are reading your resume
              <code />
              to learn about you
            </Trans>
          )}
        </p>
        <p className="reading-resume__waiting-text">
          {t(
            'upload_resume.Please hang tight for a minute or two while we take it all in'
          )}
        </p>
      </div>
    </div>
  )
}
