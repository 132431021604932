import React from 'react'
import { Button } from '../../../components/atoms'
import { ProgressBarInterview } from '../../../components/molecules/Worker/ProgressBarInterview'
import { Link as RouteLink } from 'react-router-dom'
import './preInterviewTemp.scss'

type Link = string
type ClickAction = () => void

type LinkAction = Link | ClickAction

interface Props extends React.HTMLAttributes<HTMLElement> {
  imgUrl: string
  title: string
  description?: string
  progressBar: boolean
  statusBar?: 1 | 2 | 3 | 4
  buttonText?: string
  linkAction?: LinkAction
}

export const PreInterviewTemp = (props: Props) => {
  return (
    <div className={`temp-pre-interview ${props.className ?? ''}`}>
      {props.progressBar && (
        <ProgressBarInterview status={props.statusBar ?? 1} />
      )}
      <section className="temp-pre-interview__wrapper">
        <div
          className="temp-pre-interview__img"
          style={{ backgroundImage: `url(${props.imgUrl})` }}
        ></div>
        <h2 className="temp-pre-interview__title">{props.title}</h2>
        <p className="temp-pre-interview__description">
          {props.description ?? ''}
          {props.children ?? ''}
        </p>

        {props.buttonText ? (
          typeof props.linkAction === 'string' ? (
            <RouteLink
              className="temp-pre-interview__link"
              to={props.linkAction as Link}
            >
              <Button className="temp-pre-interview__button">
                {props.buttonText}
              </Button>
            </RouteLink>
          ) : (
            <Button
              className="temp-pre-interview__button"
              onClick={props.linkAction as ClickAction}
            >
              {props.buttonText}
            </Button>
          )
        ) : (
          ''
        )}
      </section>
    </div>
  )
}
