import { IWorkerInfoOB, SkillsInfoOnlyAdditionalInfo } from '../../../models'

export const checkDataChange = (
  userInfo: IWorkerInfoOB,
  additionalInfo: SkillsInfoOnlyAdditionalInfo
) =>
  userInfo.user_data.training_certifications.length !==
    additionalInfo.training_certifications.length ||
  userInfo.user_data.unions.length !== additionalInfo.unions.length ||
  !userInfo.user_data.training_certifications.every(
    userInfoCert =>
      !additionalInfo.training_certifications.every(
        additionalCert => userInfoCert !== additionalCert
      )
  ) ||
  !userInfo.user_data.unions.every(
    userInfoUnions =>
      !additionalInfo.unions.every(
        additionalUnions => userInfoUnions !== additionalUnions
      )
  ) ||
  userInfo.user_data.eligible_work_us !== additionalInfo.eligible_work_us
