import React from 'react'
import { Helmet } from 'react-helmet'
import { BackBlur, InterviewEmployerCard } from '../../../../components/atoms'
import {
  AssessmentScoresBlock,
  SkillsChart,
  InterviewFormat,
  InterviewScoresBlock,
  SuccessMessageModal,
  WorkerDetailsHeader,
} from '../../../../components/organism'
import {
  CategoriesScores,
  EmpActionType,
  ICertificationGroup,
  IntervewData,
  WorkerInfo,
} from '../../../../models'
import { ModalData } from '../EmployerDashboard'
import { AssessmentModal as AssessmentStatuses } from '../../../../models/Employer'
import {
  ActionSidebar,
  LoadingContent,
  ModalBox,
  WorkerCertificationsBlock,
  WorkerEmploymentHighlightsBlock,
} from '../../../../components/molecules'

import './workerDetails.scss'

interface Props {
  workerData?: WorkerInfo
  isLoading: boolean
  activeModal?: ModalData
  setActiveModal: React.Dispatch<React.SetStateAction<ModalData | undefined>>
  isSuccessModal: boolean
  setIsSuccessModal: React.Dispatch<React.SetStateAction<boolean>>
  setInvitedRequests: React.Dispatch<React.SetStateAction<number[]>>
  currentInterviewData?: IntervewData
  backBlurAction: () => void
  modalAction: (modal: ModalData) => void
  invitedRequests: number[]
  interviewScores: CategoriesScores
  statusAssmnt: AssessmentStatuses[]
  isActiveBackBlur: boolean
  uniqueUrl: string
  interviewer: {
    name: string
    photo?: string | null
    description: string
    title: string
    titleColor: string
  } | null
  preparedCertifications: ICertificationGroup[]
  isDisabletInterviewButton: boolean
}

export const WorkerDetailsUI = (props: Props) => {
  const {
    workerData,
    isLoading,
    activeModal,
    setActiveModal,
    isSuccessModal,
    setIsSuccessModal,
    setInvitedRequests,
    currentInterviewData,
    backBlurAction,
    modalAction,
    invitedRequests,
    interviewScores,
    statusAssmnt,
    isActiveBackBlur,
    uniqueUrl,
    interviewer,
    preparedCertifications,
  } = props

  return (
    <div
      className={`worker-details employer-container ${
        !isLoading ? 'worker-details_grid' : ''
      }`}
    >
      <Helmet>
        <title>Skillit - Employee Details</title>
      </Helmet>

      {/* {activeModal?.modalName === EmpActionType.makeOffer && (      ///TODO it will be needed when the customer says
        <ModalBox
          onCloseModal={() => setActiveModal(undefined)}
          visibleCloseIcon
        >
          <MakeOfferModal
            userData={activeModal?.user}
            setActiveModal={setActiveModal}
            setIsSuccessModal={setIsSuccessModal}
            selectData={selectData}
            setSelectData={setSelectData}
            benchmark={benchmark ?? []}
            companyName="Messer"
          />
        </ModalBox>
      )}

      {activeModal?.modalName === EmpActionType.assessment && (
        <ModalBox
          onCloseModal={() => setActiveModal(undefined)}
          visibleCloseIcon
        >
          <AssessmentModal
            closeModal={() => setActiveModal(undefined)}
            userData={activeModal?.user}
            handleAssmnt={handleAssmnt}
          />
        </ModalBox>
      )} */}

      {activeModal?.modalName === EmpActionType.interview && (
        <ModalBox
          onCloseModal={() => setActiveModal(undefined)}
          visibleCloseIcon
        >
          {/* <EmployerSheduleInterview
            userData={activeModal?.user}
            setActiveModal={setActiveModal}
            setIsSuccessModal={setIsSuccessModal}
            setCurrentInterviewData={setCurrentInterviewData}
          /> */}

          <InterviewFormat
            userData={activeModal?.user}
            setActiveModal={setActiveModal}
            setIsSuccessModal={setIsSuccessModal}
            setInvitedRequests={setInvitedRequests}
          />
        </ModalBox>
      )}
      {isSuccessModal && (
        <ModalBox
          className="worker-details__success-modal"
          onCloseModal={() => setIsSuccessModal(false)}
          visibleCloseIcon
        >
          <SuccessMessageModal
            workerData={workerData?.user_card ?? null}
            interviewData={currentInterviewData}
          />
        </ModalBox>
      )}
      <LoadingContent isLoading={isLoading}>
        <main className="worker-details__main">
          <BackBlur
            isActive={isActiveBackBlur}
            setIsActive={() => backBlurAction()}
          />
          <WorkerDetailsHeader
            modalAction={modalAction}
            workerData={workerData ?? null}
            invitedRequests={invitedRequests}
          />

          {workerData?.about_me && (
            <section className="worker-details__about-me">
              <h4 className="worker-details__about-me-title">
                About {workerData?.user_card?.first_name ?? ''}
              </h4>
              <p className="worker-details__about-me-description">
                {workerData?.about_me}
              </p>
            </section>
          )}

          <section className="worker-details__interview-scores">
            <InterviewScoresBlock
              modalAction={modalAction}
              scoreList={interviewScores}
            />
          </section>

          <section className="worker-details__assessment-block">
            <AssessmentScoresBlock
              modalAction={modalAction}
              statusAssmnt={statusAssmnt}
            />
          </section>

          {!!workerData?.details.skills.length && (
            <section>
              <SkillsChart
                title="Areas of Experience"
                data={workerData.details.skills}
              />
            </section>
          )}

          <section>
            <WorkerEmploymentHighlightsBlock
              workerDataDetails={workerData?.details}
            />
          </section>

          {!!preparedCertifications.length && (
            <section>
              <WorkerCertificationsBlock
                certificationList={preparedCertifications}
              />
            </section>
          )}
        </main>
        <aside>
          {interviewer && (
            <InterviewEmployerCard
              className="worker-details__interview"
              title={interviewer.title}
              description={interviewer.description}
              photo={interviewer.photo}
              name={interviewer.name}
              titleColor={interviewer.titleColor}
            />
          )}

          <ActionSidebar
            {...props}
            modalAction={modalAction}
            workerData={workerData ?? null}
            uniqueUrl={uniqueUrl}
          />
        </aside>
      </LoadingContent>
    </div>
  )
}
