import React from 'react'
import './shareDataOfWorker.scss'
import {
  Button,
  Checkbox,
  ElemObj,
  Multiselect,
  Textarea,
} from '../../../../atoms'
import { IDataForShare } from '../../../../../models'

interface Props {
  dataForShare: IDataForShare
  setDataForShare: React.Dispatch<React.SetStateAction<IDataForShare>>
  maxLengthNote: number
  listCoWorkers: ElemObj[]
  workerName: string
  coWorker: ElemObj
  setCoWorker: React.Dispatch<React.SetStateAction<ElemObj>>
  handleSubmit: () => void
}

export const ShareDataOfWorkerUI = (props: Props) => {
  const {
    dataForShare,
    setDataForShare,
    maxLengthNote,
    listCoWorkers,
    workerName,
    coWorker,
    setCoWorker,
    handleSubmit,
  } = props
  return (
    <div className="share-data">
      <h1 className="share-data__title">{`Send ${workerName}’s Profile to a Coworker`}</h1>

      <Multiselect
        className={`share-data__select`}
        multiSelect={false}
        elements={listCoWorkers}
        selectedElement={listCoWorkers[0]}
        getSelectedElement={co_worker => co_worker && setCoWorker(co_worker)}
      />
      <div className="share-data__note-wrapper">
        <Textarea
          className="share-data__note"
          placeholder="Optional note to Coworker"
          rows={6}
          value={dataForShare.note}
          onChange={e =>
            setDataForShare(prev => ({
              ...prev,
              note: e.target.value,
            }))
          }
          maxLength={maxLengthNote}
        />
      </div>
      <div className="share-data__checkboxes">
        <Checkbox
          className="share-data__checkboxes-checkbox"
          id="feedback_allowed"
          name="Request Feedback"
          idCheckbox={1}
          checked={undefined}
          toggleCheckbox={(_, checked) =>
            setDataForShare(prev => ({
              ...prev,
              feedback_allowed: !checked,
            }))
          }
        />
        {dataForShare.feedback_allowed && (
          <Checkbox
            className="share-data__checkboxes-checkbox"
            id="interview_allowed"
            name={`Allow Coworker to interview ${
              workerName.slice(0, 1).toLocaleUpperCase() + workerName.slice(1)
            }`}
            idCheckbox={2}
            checked={undefined}
            toggleCheckbox={(_, checked) =>
              setDataForShare(prev => ({
                ...prev,
                interview_allowed: !checked,
              }))
            }
          />
        )}
      </div>

      <Button className="share-data__btn" onClick={handleSubmit}>
        {`PASS TO ${coWorker.element.split(' ')[0]}`}
      </Button>
    </div>
  )
}
