import React, { useCallback, useEffect, useState } from 'react'
import { EWorkerSStoreKeys } from '../../../../../utils/constants'
import { BestJobsOBUI } from './BestJobsOBUI'
import {
  TCombinedFormType,
  TEditIncompleteExp,
} from '../../../../../pages/Onboarding/OBLastJobs/OBLastJobs'

export type TEditableJob = false | number

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  isPromptOpen: boolean
  setIsPromptOpen: React.Dispatch<React.SetStateAction<boolean>>
  onCloseModal: (value: boolean, setIsFormOpen: TCombinedFormType) => void
  isClickCloseBtn: boolean
  setIsClickCloseBtn: React.Dispatch<React.SetStateAction<boolean>>
  setJobEditModal: React.Dispatch<
    React.SetStateAction<false | TEditIncompleteExp>
  >
}

export const BestJobsOB = (props: IProps) => {
  const [isFormOpen, setIsFormOpen] = useState<false | 'add' | number>(false)

  const addOneMoreJob = useCallback(() => setIsFormOpen('add'), [])

  useEffect(() => {
    !isFormOpen &&
      sessionStorage.removeItem(EWorkerSStoreKeys.placeAutocomplete)
  }, [isFormOpen])

  return (
    <BestJobsOBUI
      {...props}
      isFormOpen={isFormOpen}
      setIsFormOpen={setIsFormOpen}
      addOneMoreJob={addOneMoreJob}
    />
  )
}
