import React, { forwardRef, useEffect, useMemo, useRef } from 'react'
import './promptUI.scss'

export enum EPos {
  left = 'left',
  right = 'right',
  top = 'top',
  bottom = 'bottom',
}

interface IProps extends React.HTMLAttributes<HTMLElement> {
  text?: string
  position: EPos.bottom | EPos.top | EPos.left | EPos.right
}

export const PromptUI = forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const { className, style, text, position } = props
  const promptRef = useRef<HTMLDivElement>(null)
  const triangleRef = useRef<HTMLDivElement>(null)
  const sizes = useMemo(
    () => ({
      poligonHeight: 8.5,
      borderWidth: 1,
    }),
    []
  )

  const positionStyles = useMemo(
    () => ({
      [EPos.top]: {
        bottom: `${-sizes.poligonHeight / 2 - sizes.borderWidth}px`,
        left: '50%',
        transform: 'translateX(-50%) rotateZ(-135deg)',
      },
      [EPos.bottom]: {
        top: `${-sizes.poligonHeight / 2 - sizes.borderWidth}px`,
        left: '50%',
        transform: 'translateX(-50%) rotateZ(45deg)',
      },
      [EPos.left]: {
        top: '50%',
        right: `${-sizes.poligonHeight / 2 - sizes.borderWidth}px`,
        transform: 'translateY(-50%) rotateZ(135deg)',
      },
      [EPos.right]: {
        top: '50%',
        left: `${-sizes.poligonHeight / 2 - sizes.borderWidth}px`,
        transform: 'translateY(-50%) rotateZ(-45deg)',
      },
    }),
    [sizes]
  )

  useEffect(() => {
    if (promptRef.current && triangleRef.current) {
      promptRef.current.style.setProperty(
        '--poligonHeight',
        `${sizes.poligonHeight}px`
      )
      promptRef.current.style.setProperty(
        '--borderWidth',
        `${sizes.borderWidth}px`
      )
    }
  }, [sizes])

  return (
    <div className={className ?? ''} style={style} ref={ref}>
      <div ref={promptRef} className="prompt-ui" data-testid="prompt-ui">
        <div
          ref={triangleRef}
          className="prompt-ui__triangle"
          style={positionStyles[position]}
          data-testid="prompt-ui__triangle"
        ></div>
        {text}
      </div>
    </div>
  )
})
