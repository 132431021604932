import React, { useCallback } from 'react'
import './workerDetailsHeader.scss'
import arrow from '../../../../assets/icons/arrows/yellow_arrowBack.svg'
import { Link } from 'react-router-dom'
import * as route from '../../../../services/route'
import { WorkerInfo } from '../../../../models/Employer'
import { WorkerCard } from '../../../molecules'
import { ModalData } from '../../../../pages/InAppFlow'
import bckDefPhoto from '../../../../assets/img/worker/profile/backgroundDefault.png'
import { setIsEmployer, useAppDispatch } from '../../../../redux'
import { EAdminWorkersSStoreKeys } from '../../../../utils/constants'

interface Props {
  workerData: WorkerInfo | null
  modalAction: (activeModal: ModalData) => void
  invitedRequests: number[]
}

export const WorkerDetailsHeader = (props: Props) => {
  const { workerData } = props
  const dispatch = useAppDispatch()
  const userCard = workerData?.user_card ?? null

  const transitionFromAdmin = JSON.parse(
    `${sessionStorage.getItem(EAdminWorkersSStoreKeys.transitionFromAdmin)}`
  )

  const backToAdminList = useCallback(() => {
    dispatch(setIsEmployer(false))
    sessionStorage.setItem(
      EAdminWorkersSStoreKeys.isAdmin,
      JSON.stringify('true')
    )
    sessionStorage.removeItem(EAdminWorkersSStoreKeys.transitionFromAdmin)
  }, [dispatch])

  return (
    <div className="worker-header">
      <img
        className="worker-header__bg-photo"
        src={workerData?.background_image ?? bckDefPhoto}
        alt="background_photo"
      />
      <Link
        to={transitionFromAdmin ? route.adminWorkers : route.employerDashboard}
        className="worker-header__back-button"
        onClick={() => {
          transitionFromAdmin && backToAdminList()
        }}
      >
        <img src={arrow} alt="back" />
        Back to List
      </Link>
      {userCard && (
        <WorkerCard
          isHideFilterPressed={false}
          isHeaderCard
          className="worker-header__header-card"
          userData={userCard}
          modalAction={props.modalAction}
          invitedRequests={props.invitedRequests}
        />
      )}
    </div>
  )
}
