import React from 'react'

import { FirstBlog } from './FirstBlog'
import { SecondBlog } from './SecondBlog'
import { ThirdBlog } from './ThirdBlog'

export const BlogSelection = ({ id }: { id: number }) => {
  const list: { id: number; blog: JSX.Element }[] = [
    {
      id: 1,
      blog: <FirstBlog />,
    },
    {
      id: 2,
      blog: <SecondBlog />,
    },
    {
      id: 3,
      blog: <ThirdBlog />,
    },
  ]

  return <>{list.find(blog => blog.id === id)?.blog}</>
}
