import React from 'react'
import { Banner } from '../../../../components/atoms/Banner'
import forJobsImg from '../../../../assets/img/worker/forJobMobile.png'
import forJobsImgDesk from '../../../../assets/img/worker/forJobsImgDesk.png'
import worker from '../../../../assets/img/worker/screen/worker2-3x.png'
import workerMob from '../../../../assets/img/worker/landscape/workerMobile.png'
import { Helmet } from 'react-helmet'
import worker4 from '../../../../assets/img/worker/screen/worker4-3x.png'
import worker4Mob from '../../../../assets/img/worker/mobile/worker4Mob.png'
import { SecondaryCard } from '../../../../components/molecules/Worker/SecondaryCard'
import './trainingPage.scss'
import * as route from '../../../../services/route'
import { ListSection } from '../../../../components/organism/General/ListSection'
import { v4 as uuidv4 } from 'uuid'
import { useMediaContext } from '../../../../utils/hooks'
import { EWorkerPageTitle } from '../../../../utils/constants'

interface Training {
  imgPath: string
  title: string
  subTitle: string
  blogId: number
}

export const TrainingPage = () => {
  const isMobile = useMediaContext()
  const trainings = React.useMemo(() => {
    const list: Training[] = [
      {
        imgPath: isMobile ? workerMob : worker,
        title: 'How to ace your carpenter foreman interview',
        subTitle:
          "From the simple dos and don'ts to getting the dress code juuuust right, Skillit shares our customer's tips to nailing (no pun intended) your next Carpenter Foreman interview.",
        blogId: 1,
      },
      {
        imgPath: isMobile ? worker4Mob : worker4,
        title: '5 tips for becoming a better carpenter',
        subTitle:
          'Lead Finish Carpenter Mindy-Otten Chen offers up some advice to fellow carpenters looking to boost their career.',
        blogId: 2,
      },
      {
        imgPath: isMobile ? forJobsImg : forJobsImgDesk,
        title: 'Introducing Skills Premiums for carpenters',
        subTitle:
          'The nation’s top construction firms are paying skills-assessed carpenters a premium for their personal mix of carpentry skills and knowledge.',
        blogId: 3,
      },
    ]
    return list
  }, [isMobile])
  return (
    <div className="training-page">
      <Helmet>
        <title>{EWorkerPageTitle.training}</title>
      </Helmet>
      <Banner className="training-page__banner-wrapper">
        <h2 className="training-page__title">Training</h2>
        <p className="training-page__subtitle">
          Skillit is not just about getting the best job now; it’s also about
          growing your career. Explore articles and more to help you learn and
          earn your true potential.
        </p>
      </Banner>
      <section className="training-page__list">
        <ListSection
          className="training-page__list-content"
          numberOfItems={trainings.length}
          itemAtIndex={i => {
            const el = trainings[i]
            return (
              <SecondaryCard
                key={uuidv4()}
                className="training-page__list-item"
                title={el.title}
                imgUrl={el.imgPath}
                description={el.subTitle}
                buttonText={'READ MORE'}
                action={`${route.blog}/${el.blogId}`}
              />
            )
          }}
        />
      </section>
    </div>
  )
}
