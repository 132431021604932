import { Helmet } from 'react-helmet'
import { Button } from '../../../components/atoms'
import { SliderWithArrows } from '../../../components/atoms/sliders/SliderWithArrows'
import {
  NavigationFooter,
  ProgressBarLinear,
} from '../../../components/molecules'
import {
  EPageTitleOB,
  listBlocksPBLinear,
} from '../../../utils/constants/obConstants'
import { IHourlyPayInfoOB } from './OBRates'
import { useTranslation, Trans } from 'react-i18next'
import './obRates.scss'

interface IProps {
  hourlyPay: IHourlyPayInfoOB
  onClickNext: () => void
  onClickBack: () => void
  hourlyPayHandler: (number: number, key: string) => void
  isNextButtonDisabled: boolean
}

export const OBRatesUI = (props: IProps) => {
  const {
    onClickNext,
    onClickBack,
    hourlyPay,
    hourlyPayHandler: hourlyHandler,
    isNextButtonDisabled,
  } = props
  const { t, i18n } = useTranslation()

  return (
    <div className="ob-rates ob-container">
      <Helmet>
        <title>{EPageTitleOB.rates}</title>
      </Helmet>

      <ProgressBarLinear initData={listBlocksPBLinear} currentPage={11} />

      <div className="ob-rates__wrapper">
        <div className="ob-rates__content">
          <p className="ob-rates__question ob-question">
            {i18n.language.slice(0, 2) === 'es' ? (
              <Trans i18nKey={'rates.recent-pay'}>
                ¿Cuánto <strong>cobras</strong> por hora?
              </Trans>
            ) : (
              <Trans i18nKey={'rates.recent-pay'}>
                What is your <code>most recent</code> hourly pay rate?
              </Trans>
            )}
          </p>
          <p className="ob-rates__description">
            {t('rates.You can always update this later.')}
          </p>
          <SliderWithArrows
            defaultValue={+hourlyPay.most_recent_pay_rate}
            rangeProcessing={function (number) {
              hourlyHandler(number, 'most_recent_pay_rate')
            }}
            valueTitle={`$${hourlyPay.most_recent_pay_rate} / ${t(
              'rates.hour'
            )}`}
            min={0}
            max={99}
          />
        </div>

        <div className="ob-rates__content">
          <p className="ob-rates__question ob-question">
            {i18n.language.slice(0, 2) === 'es' ? (
              <Trans i18nKey={'rates.desired-pay'}>
                ¿Cuánto <code>quieres</code> cobrar por hora?
              </Trans>
            ) : (
              <Trans i18nKey={'rates.desired-pay'}>
                What is your <code>desired</code> hourly pay rate?
              </Trans>
            )}
          </p>
          <p className="ob-rates__description">
            {t(
              'rates.Let us help you get there! Skilled labor shouldn’t be cheap.'
            )}
          </p>
          <SliderWithArrows
            defaultValue={+hourlyPay.desired_pay_rate}
            rangeProcessing={function (number) {
              hourlyHandler(number, 'desired_pay_rate')
            }}
            valueTitle={`$${hourlyPay.desired_pay_rate} / ${t('rates.hour')}`}
            min={0}
            max={99}
          />
        </div>
      </div>

      <NavigationFooter actionBack={onClickBack}>
        <Button onClick={onClickNext} disabled={isNextButtonDisabled}>
          {t('Shared_Header.Next')}
        </Button>
      </NavigationFooter>
    </div>
  )
}
