import {
  IHomeLocationOB,
  ILocationInfoOB,
  IRelocationLocationOB,
} from '../../../models'

export const skippingLocationDataFields = (data: ILocationInfoOB) => {
  const preparedHomeLocation: IHomeLocationOB = {
    zip_code: data.home_location.zip_code,
    longitude: data.home_location.longitude,
    latitude: data.home_location.latitude,
    state: data.home_location.state,
    city: data.home_location.city,
    street: data.home_location.street,
    raw_input: data.home_location.raw_input,
  }
  const preparedRelocateLocation: IRelocationLocationOB | null =
    data.relocation_location
      ? {
          zip_code: data.relocation_location.zip_code,
          longitude: data.relocation_location.longitude,
          latitude: data.relocation_location.latitude,
          state: data.relocation_location.state,
          city: data.relocation_location.city,
          street: data.relocation_location.street,
          raw_input: data.relocation_location.raw_input,
          date: data.relocation_location.date,
        }
      : null

  const preparedPreferredLocations: IHomeLocationOB[] | null =
    !data.preferred_locations
      ? null
      : data.preferred_locations.map(location => {
          return {
            zip_code: location.zip_code,
            longitude: location.longitude,
            latitude: location.latitude,
            state: location.state,
            city: location.city,
            street: location.street,
            raw_input: location.raw_input,
          }
        })

  return {
    ...data,
    home_location: preparedHomeLocation,
    relocation_location: preparedRelocateLocation,
    preferred_locations: preparedPreferredLocations,
  }
}
