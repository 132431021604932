import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { OBWillingToRelocateUI } from './OBWillingToRelocateUI'
import * as route from '../../../services/route'
import { setWorkerInfo, useAppDispatch } from '../../../redux'
import { usePageNumUpdateOB, useTypedSelector } from '../../../utils/hooks'
import { ILocationInfoOB, IWorkerInfoOB } from '../../../models'
import { useMutation, useQueryClient } from 'react-query'
import { QueryState } from 'react-query/types/core/query'
import {
  EWorkerSStoreKeys,
  OBAnalyticsName,
  WorkerQueries,
} from '../../../utils/constants'
import { updateLocationInfoOB } from '../../../services/workerApi'
import { toast } from 'react-toastify'
import { ZipCode } from 'use-places-autocomplete'
import {
  addGoogleLocation,
  getUserInfoFromStorage,
  onTrackingActions,
  skippingLocationDataFields,
} from '../../../utils/scripts'
import { getLastPageState } from '../../../utils/hooks/obHooks/usePageNumUpdateOB'

interface IPreferredLocation {
  isPreferredLocations: number
}

export const OBWillingToRelocate = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const pageStateOBMutation = usePageNumUpdateOB()
  const userInfo = useTypedSelector(s => s.obInfo.workerInfo)
  const lastPage = userInfo.last_page < 13 ? 13 : userInfo.last_page
  const lastPageState = useMemo(() => getLastPageState(lastPage), [lastPage])
  const userInfoFromStorage = useMemo(() => getUserInfoFromStorage(), [])
  const willingToRelocateInfoFromStorage = useMemo(
    () =>
      getUserInfoFromStorage<IPreferredLocation>(EWorkerSStoreKeys.obLocation),
    []
  )

  const queryClient = useQueryClient()
  const obPrefferLocationInfoQuery:
    | QueryState<ILocationInfoOB | undefined>
    | undefined = queryClient.getQueryState(WorkerQueries.locationInfoOB)

  const obPrefferLocationInfoMutation = useMutation(
    (data: ILocationInfoOB) =>
      updateLocationInfoOB(skippingLocationDataFields(data)),
    {
      onSuccess: (newData: ILocationInfoOB) => {
        queryClient.setQueryData(WorkerQueries.locationInfoOB, newData)
      },
    }
  )
  const defaultPrefferLocations: ILocationInfoOB = useMemo(
    () => ({
      home_location:
        userInfoFromStorage?.user_data.home_location ??
        userInfo.user_data.home_location,

      relocation_location:
        userInfoFromStorage?.user_data.relocation_location ??
        userInfo.user_data.relocation_location,

      preferred_locations:
        userInfoFromStorage?.user_data.preferred_locations ??
        userInfo.user_data.preferred_locations,

      willing_to_relocate:
        userInfoFromStorage?.user_data.willing_to_relocate ??
        userInfo.user_data.willing_to_relocate,
    }),
    [userInfo, userInfoFromStorage]
  )

  const [willingToRelocate, setWillingToRelocate] = useState<
    number | undefined
  >(willingToRelocateInfoFromStorage?.isPreferredLocations)
  const [preferredPlacesList, setPreferredPlacesList] =
    useState<ILocationInfoOB>(
      defaultPrefferLocations ?? obPrefferLocationInfoQuery?.data
    )

  const isPreferredLocationChanged = useMemo(
    () =>
      !userInfo.user_data?.preferred_locations?.every(
        preferredLocation =>
          !preferredPlacesList.preferred_locations?.every(
            el => preferredLocation.city !== el.city
          )
      ) ||
      !userInfo.user_data?.preferred_locations?.every(
        preferredLocation =>
          !preferredPlacesList.preferred_locations?.every(
            el => preferredLocation.state !== el.state
          )
      ),
    [
      preferredPlacesList.preferred_locations,
      userInfo.user_data?.preferred_locations,
    ]
  )

  const isDataChanged = useMemo(
    () =>
      userInfo.user_data?.preferred_locations?.length !==
        preferredPlacesList.preferred_locations?.length ||
      isPreferredLocationChanged,
    [
      isPreferredLocationChanged,
      preferredPlacesList.preferred_locations?.length,
      userInfo.user_data?.preferred_locations,
    ]
  )

  const onRemovedAddingPlace = (
    uniqueKey: string,
    initPlacesList: ILocationInfoOB
  ) => {
    const places = initPlacesList?.preferred_locations?.filter(
      (place, index) => {
        return `${index}${place.city}${place.state}` !== uniqueKey
      }
    )
    setPreferredPlacesList(prevLocation => {
      return {
        ...prevLocation,
        preferred_locations: places?.length ? places : [],
      }
    })

    onTrackingActions(OBAnalyticsName.desired_city_removed)
  }

  const onAddPlace = (
    initSuggestion: google.maps.places.AutocompletePrediction,
    initZipCode: ZipCode,
    initLat: number,
    initLng: number
  ) => {
    setPreferredPlacesList(prevLocation =>
      addGoogleLocation(
        prevLocation,
        initSuggestion,
        initZipCode,
        initLat,
        initLng
      )
    )

    onTrackingActions(OBAnalyticsName.desired_city_added)
  }

  const getNewData = useCallback(
    (data: IWorkerInfoOB) => ({
      ...data,
      last_page: lastPage,
      last_location: lastPageState.last_location,
      user_data: {
        ...data.user_data,
        preferred_locations: preferredPlacesList.preferred_locations,
      },
    }),
    [
      lastPage,
      lastPageState.last_location,
      preferredPlacesList.preferred_locations,
    ]
  )

  const onClickBack = useCallback(() => {
    sessionStorage.setItem(
      EWorkerSStoreKeys.obData,
      JSON.stringify(getNewData(userInfoFromStorage ?? userInfo))
    )
    sessionStorage.setItem(
      EWorkerSStoreKeys.obLocation,
      JSON.stringify({
        ...willingToRelocateInfoFromStorage,
        isPreferredLocations: willingToRelocate,
      })
    )
    history.push(route.OBPath.onboardingTravelToWork)
  }, [
    getNewData,
    history,
    userInfo,
    userInfoFromStorage,
    willingToRelocateInfoFromStorage,
    willingToRelocate,
  ])

  const onClickNext = useCallback(
    async (
      initPreferredPlacesList: ILocationInfoOB,
      initSetPreferredPlacesList: React.Dispatch<
        React.SetStateAction<ILocationInfoOB>
      >
    ) => {
      if (!isDataChanged) {
        sessionStorage.setItem(
          EWorkerSStoreKeys.obData,
          JSON.stringify(getNewData(userInfoFromStorage ?? userInfo))
        )
        sessionStorage.setItem(
          EWorkerSStoreKeys.obLocation,
          JSON.stringify({
            ...willingToRelocateInfoFromStorage,
            isPreferredLocations: willingToRelocate,
          })
        )
        history.push(route.OBPath.onboardingRates)
      } else {
        await onTrackingActions(OBAnalyticsName.relocation_intent_submitted)

        obPrefferLocationInfoMutation.mutate(initPreferredPlacesList, {
          onSuccess: () => {
            initSetPreferredPlacesList(initPreferredPlacesList)
            pageStateOBMutation.mutate(lastPageState, {
              onSuccess() {
                dispatch(setWorkerInfo(getNewData(userInfo)))
                sessionStorage.setItem(
                  EWorkerSStoreKeys.obLocation,
                  JSON.stringify({
                    ...willingToRelocateInfoFromStorage,
                    isPreferredLocations: willingToRelocate,
                  })
                )
                sessionStorage.setItem(
                  EWorkerSStoreKeys.obData,
                  JSON.stringify(getNewData(userInfoFromStorage ?? userInfo))
                )
                history.push(route.OBPath.onboardingRates)
              },
            })
          },
          onError: err => {
            if (err instanceof Error) toast.error(err.message)
          },
        })
      }
    },
    [
      dispatch,
      getNewData,
      history,
      isDataChanged,
      obPrefferLocationInfoMutation,
      willingToRelocateInfoFromStorage,
      pageStateOBMutation,
      userInfo,
      userInfoFromStorage,
      willingToRelocate,
      lastPageState,
    ]
  )

  useEffect(() => {
    willingToRelocate === 2 &&
      setPreferredPlacesList({
        ...defaultPrefferLocations,
        preferred_locations: [],
      })
  }, [defaultPrefferLocations, willingToRelocate])

  useEffect(() => {
    if (
      userInfoFromStorage?.user_data.preferred_locations ??
      userInfo.user_data.preferred_locations
    ) {
      const isPreferredLocationLength =
        !!userInfoFromStorage?.user_data.preferred_locations?.length
      setWillingToRelocate(isPreferredLocationLength ? 1 : 2)
      setPreferredPlacesList({
        ...defaultPrefferLocations,
        preferred_locations: isPreferredLocationLength
          ? userInfoFromStorage?.user_data.preferred_locations ??
            userInfo.user_data.preferred_locations
          : [],
      })
    }
  }, [
    defaultPrefferLocations,
    userInfo.user_data.preferred_locations,
    userInfoFromStorage?.user_data.preferred_locations,
  ])

  return (
    <OBWillingToRelocateUI
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      willingToRelocate={willingToRelocate}
      setWillingToRelocate={setWillingToRelocate}
      preferredPlacesList={preferredPlacesList}
      setPreferredPlacesList={setPreferredPlacesList}
      onRemovedAddingPlace={onRemovedAddingPlace}
      onAddPlace={onAddPlace}
      isWaitingResponse={
        obPrefferLocationInfoMutation.isIdle
          ? false
          : !obPrefferLocationInfoMutation.error
      }
    />
  )
}
