import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as route from '../../../services/route'
import {
  useGetWorkerInfo,
  usePageNumUpdateOB,
  useStateWithDep,
  useTypedSelector,
} from '../../../utils/hooks'
import { OBEmailUI } from './OBEmailUI'
import {
  errorMsg,
  ErrorType,
  EWorkerSStoreKeys,
  OBAnalyticsName,
  WorkerQueries,
} from '../../../utils/constants'
import {
  setUser,
  setUserEmail,
  setWorkerInfo,
  signUpOB,
  useAppDispatch,
} from '../../../redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  getBasicInfo,
  getPublicInfo,
  startAuthenticatedOB,
  updateBasicInfo,
  updatePublicInfo,
} from '../../../services/workerApi'
import { BasicInfoModel, IWorkerInfoOB, PublicInfo } from '../../../models'
import {
  getUserInfoFromStorage,
  onTrackingActions,
  formatPhoneNumber,
} from '../../../utils/scripts'
import { getLastPageState } from '../../../utils/hooks/obHooks/usePageNumUpdateOB'

export const OBEmail = () => {
  const inputEmailRef = useRef<HTMLInputElement>(null)
  const queryClient = useQueryClient()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const userInfoFromStorage = getUserInfoFromStorage()
  const syncUser = useTypedSelector(state => state.auth.user)
  const userInfo = useTypedSelector(state => state.obInfo.workerInfo)
  const { obModelErrorState } = useGetWorkerInfo()
  const lastPage = userInfo.last_page < 3 ? 3 : userInfo.last_page
  const lastPageState = useMemo(() => getLastPageState(lastPage), [lastPage])

  const { data: publicInfo } = useQuery(
    WorkerQueries.publicInfo,
    () => syncUser && getPublicInfo(),
    {
      enabled: !queryClient.getQueryState(WorkerQueries.publicInfo)?.data,
    }
  )

  const startAuthenticatedMutation = useMutation(_ => startAuthenticatedOB(), {
    onError: err => {
      if (err instanceof Error) {
        toast.error(err.message)
      }
    },
  })

  const pageStateOBMutation = usePageNumUpdateOB()

  const { data: basicInfo } = useQuery(
    WorkerQueries.basicInfo,
    () => syncUser && getBasicInfo(),
    {
      enabled: !queryClient.getQueryState(WorkerQueries.basicInfo)?.data,
    }
  )

  const workerBasicInfoMutation = useMutation(
    (data: BasicInfoModel) => updateBasicInfo(data),
    {
      onError: err => {
        if (err instanceof Error) {
          toast.error(err.message)
        }
      },
    }
  )

  const workerPublicInfoMutation = useMutation(
    (data: PublicInfo) => updatePublicInfo(data),
    {
      onError: err => {
        if (err instanceof Error) {
          toast.error(err.message)
        }
      },
    }
  )

  const userEmail = syncUser?.username
  const name: string = useMemo(
    () =>
      userInfoFromStorage?.user_data.first_name ?? publicInfo?.first_name ?? '',
    [userInfoFromStorage?.user_data.first_name, publicInfo?.first_name]
  )

  const [phone, setPhone] = useStateWithDep<string>(
    userInfoFromStorage?.user_data.phone ??
      basicInfo?.phone ??
      userInfo.user_data.phone ??
      ''
  )
  const indeedEmail =
    userEmail?.includes('@indeedemail.com') ??
    userInfoFromStorage?.user_data.username?.includes('@indeedemail.com') ??
    userInfo.user_data.username?.includes('@indeedemail.com')

  const [email, setEmail] = useStateWithDep<string>(
    indeedEmail
      ? ''
      : userEmail ??
          userInfoFromStorage?.user_data.username ??
          userInfo.user_data.username ??
          ''
  )

  const [isWaitingResponse, setIsWaitingResponse] = useState(false)

  const onChangePhone = (e: React.FormEvent<HTMLInputElement>) => {
    setPhone(formatPhoneNumber(e.currentTarget.value, false, true) ?? '')
  }

  const onChangeEmail = (e: React.FormEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value)
  }

  const preparedDate = useCallback(
    (data: IWorkerInfoOB) => {
      const phoneNumber: string | null =
        formatPhoneNumber(phone, true, true) ?? null
      return {
        ...data,
        last_page: lastPage < 3 ? 3 : lastPage,
        last_location: lastPageState.last_location,
        user_data: {
          ...data.user_data,
          phone: phoneNumber,
          username: email,
        },
      }
    },
    [email, phone, lastPage, lastPageState.last_location]
  )

  const storeSaving = useCallback(() => {
    sessionStorage.setItem(
      EWorkerSStoreKeys.obData,
      JSON.stringify(preparedDate(userInfoFromStorage ?? userInfo))
    )
  }, [userInfoFromStorage, preparedDate, userInfo])

  const onClickNext = () => {
    const phoneNumber: string | undefined = formatPhoneNumber(phone, true, true)

    const savingData = () => {
      storeSaving()
      pageStateOBMutation.mutate(lastPageState, {
        onSuccess: () => {
          const newData = preparedDate(
            lastPageState.last_page < 3
              ? userInfoFromStorage ?? userInfo
              : {
                  ...(userInfoFromStorage ?? userInfo),
                  user_data: {
                    ...userInfo.user_data,
                    first_name:
                      userInfoFromStorage?.user_data.first_name ??
                      userInfo.user_data.first_name,
                    last_name:
                      userInfoFromStorage?.user_data.last_name ??
                      userInfo.user_data.last_name,
                  },
                }
          )

          dispatch(setWorkerInfo(newData))
          dispatch(setUser(email))
          dispatch(setUserEmail(email))
          queryClient.setQueryData(WorkerQueries.workerInfoOB, newData)
          history.push(route.OBPath.onboardingTrades)
        },
      })
    }

    if (syncUser) {
      const updateInfo = () => {
        const isDataChanged = !(
          (basicInfo?.phone ?? '') === phoneNumber &&
          basicInfo?.username === email &&
          publicInfo?.first_name ===
            userInfoFromStorage?.user_data.first_name &&
          publicInfo?.last_name === userInfoFromStorage?.user_data.last_name
        )

        !isDataChanged && history.push(route.OBPath.onboardingTrades)
        if (basicInfo && publicInfo) {
          const preparedBasicData: BasicInfoModel = {
            ...basicInfo,
            username: email,
            phone: formatPhoneNumber(phone, true, true) ?? '',
          }
          const preparedPubicData: PublicInfo = {
            ...publicInfo,
            first_name: name,
            last_name:
              userInfoFromStorage?.user_data.last_name ??
              publicInfo.last_name ??
              '',
          }

          if (isDataChanged) {
            onTrackingActions(OBAnalyticsName.contact_info_submitted)
            workerBasicInfoMutation.mutate(preparedBasicData, {
              onSuccess: res => {
                queryClient.setQueryData(WorkerQueries.basicInfo, res)
                workerPublicInfoMutation.mutate(preparedPubicData, {
                  onSuccess: res => {
                    queryClient.setQueryData(WorkerQueries.publicInfo, res)
                    savingData()
                  },
                })
              },
            })
          }
        } else toast.error('No employee data')
      }
      obModelErrorState
        ? startAuthenticatedMutation.mutate(undefined, {
            onSuccess: () => updateInfo(),
          })
        : updateInfo()
    } else {
      onTrackingActions(OBAnalyticsName.contact_info_submitted)
      dispatch(
        signUpOB({
          email: email.trim(),
          phone: formatPhoneNumber(phone, true, true) ?? '',
          first_name: name,
          last_name: userInfoFromStorage?.user_data.last_name ?? '',
          source:
            sessionStorage.getItem(
              EWorkerSStoreKeys.obSourceFromMarketingSite
            ) ?? undefined,
        })
      )
        .then(unwrapResult)
        .then(_ => {
          savingData()
        })
        .catch(error => {
          if (error.message === ErrorType.existingEmail) {
            toast.error(errorMsg.existingEmail)
            sessionStorage.clear()
            localStorage.clear()
            history.push(route.signIn)
          } else {
            storeSaving()
            if (error.name !== 'TypeError') {
              toast.error(error.message)
            }
          }
          setIsWaitingResponse(false)
        })
      setIsWaitingResponse(true)
    }
  }

  const onClickBack = () => {
    sessionStorage.setItem(
      EWorkerSStoreKeys.obData,
      JSON.stringify(preparedDate(userInfoFromStorage ?? userInfo))
    )
    history.push(route.OBPath.onboardingName)
  }

  useEffect(() => inputEmailRef?.current?.focus(), [])

  return (
    <OBEmailUI
      name={name}
      isPasswordSet={!!userInfo.user_data.password_set}
      phone={formatPhoneNumber(phone, false, true) ?? ''}
      onChangePhone={onChangePhone}
      email={email}
      onChangeEmail={onChangeEmail}
      onClickNext={onClickNext}
      onClickBack={onClickBack}
      isWaitingResponse={
        isWaitingResponse ||
        (workerBasicInfoMutation.isIdle
          ? false
          : !workerBasicInfoMutation.error) ||
        (workerPublicInfoMutation.isIdle
          ? false
          : !workerPublicInfoMutation.error)
      }
      ref={inputEmailRef}
    />
  )
}
