import React, { ChangeEvent, useEffect, useState } from 'react'
import { setUserEmail, useAppDispatch } from '../../../../redux'
import { Input } from '../../../atoms/inputs/Input'
import './email-login.scss'
import ReCAPTCHA from 'react-google-recaptcha'
import { RECAPTCHA_KEY } from '../../../../services/config'
import { useTypedSelector } from '../../../../utils/hooks'
import { LoginType } from '../../../organism'
import { formatPhoneNumber } from '../../../../utils/scripts'

interface Props {
  defaultEmail: string
  emailPlaceHolder: string
  confirmTitle: string
  passwordPlaceHolder: string
  onClickConfirm: (login: string, password: string) => Promise<void>
  isSignUp?: boolean
  loginType: LoginType
}

export const EmailLogin = ({
  defaultEmail,
  emailPlaceHolder,
  passwordPlaceHolder,
  confirmTitle,
  onClickConfirm,
  isSignUp,
  loginType,
}: Props) => {
  const dispatch = useAppDispatch()
  const verificationModal = useTypedSelector(s => s.auth.verificationModal)
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState(defaultEmail)
  const [isLoading, setIsLoading] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [isFormattingNumber, setIsFormattingNumber] = useState<boolean>(false)

  const isMagicLink = loginType === 2
  const isValidFields = isMagicLink
    ? !!email.length
    : !!email.length && !!password.length

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value

    const modifiedString = inputValue.replace(/[^a-zA-Z0-9@]/g, '')

    const charactersIsNaN = /[^+\d\s()-]/.test(inputValue)

    if (inputValue.length >= 6 && !charactersIsNaN) {
      setIsFormattingNumber(true)
      setEmail(formatPhoneNumber(inputValue, false, true))
    } else {
      if (isFormattingNumber) {
        setEmail(modifiedString)
        setIsFormattingNumber(false)
      } else {
        setEmail(inputValue)
      }
    }
  }

  const onConfirm = async (e: any) => {
    e.preventDefault()
    if (!isValid() || isLoading) {
      return
    }
    setIsLoading(true)
    await onClickConfirm(email, password)
    setEmail('')
    setIsLoading(false)
    !isMagicLink && dispatch(setUserEmail(decodeURIComponent(email)))
  }

  const isValid = () => {
    if (isSignUp) {
      return isValidFields && isVerified
    }
    return isValidFields
  }

  useEffect(() => {
    if (isSignUp && verificationModal) {
      setPassword('')
      setEmail('')
    }
  }, [isSignUp, verificationModal])

  return (
    <form className="email-container" onSubmit={onConfirm}>
      <Input
        className="email-container__input"
        placeholder={emailPlaceHolder}
        type="email"
        value={email}
        onChange={handleInputChange}
      />
      {!isMagicLink && (
        <Input
          className="email-container__input"
          placeholder={passwordPlaceHolder}
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          autoComplete="off"
        />
      )}

      {isSignUp && (
        <ReCAPTCHA
          className="email-captcha"
          tabindex={0}
          sitekey={`${RECAPTCHA_KEY}`}
          onChange={() => setIsVerified(true)}
          onExpired={() => setIsVerified(false)}
        />
      )}

      <button
        type="submit"
        disabled={!isValid()}
        className="confirm-button"
        onClick={onConfirm}
      >
        {confirmTitle}
      </button>
    </form>
  )
}
