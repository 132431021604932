import React, { HTMLAttributes, useCallback, useEffect, useMemo } from 'react'

interface Props extends HTMLAttributes<HTMLFormElement> {
  onEnter?: (e: KeyboardEvent) => void
}

export const Form = React.memo((props: Props) => {
  const { onEnter, ...rest } = props
  const onEnterDeclared = useMemo(() => {
    return onEnter !== undefined
  }, [onEnter])
  const onEnterHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        onEnter!(event)
      }
    },
    [onEnter]
  )

  useEffect(() => {
    if (onEnterDeclared) {
      document.addEventListener('keypress', onEnterHandler)
      return () => document.removeEventListener('keypress', onEnterHandler)
    }
  }, [onEnterHandler, onEnterDeclared])
  return <form {...rest} />
})
