import React, { useState } from 'react'

import './switchBtn.scss'

interface Props extends React.HTMLAttributes<HTMLElement> {
  defaultStatus?: boolean
  statusHandler?: (status: boolean) => void
  name?: string
  enabledStatusName?: string
  disabledStatusName?: string
}

export const SwitchBtn = (props: Props) => {
  const {
    defaultStatus,
    statusHandler,
    name,
    enabledStatusName,
    disabledStatusName,
  } = props
  const [toggleStatus, setToggleStatus] = useState(defaultStatus)

  return (
    <div className={`switch-btn ${props.className ?? ''}`}>
      <p className="switch-btn__namefield">{name ?? ''}</p>
      <div
        className="switch-btn__status"
        onClick={() => {
          statusHandler?.(!toggleStatus)
          setToggleStatus(!toggleStatus)
        }}
        data-testid="switch-btn__status-togler"
      >
        <div
          className={
            toggleStatus
              ? 'switch-btn__status-icon'
              : 'switch-btn__status-icon-disable'
          }
          data-testid="switch-btn__status-icon"
        >
          <div className="switch-btn__status-icon-toggle"></div>
        </div>
        <span
          className="switch-btn__status-text"
          data-testid="switch-btn__status-text"
        >
          {toggleStatus ? enabledStatusName ?? '' : disabledStatusName ?? ''}
        </span>
      </div>
    </div>
  )
}
