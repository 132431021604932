import React, { useMemo, useState, useEffect } from 'react'
import { BasicInfoModel, ILocationInfoOB } from '../../../../../models'
import { toast } from 'react-toastify'
import {
  getBasicInfo,
  updateBasicInfo,
} from '../../../../../services/workerApi/profileAPI'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  defaultTravelDistance,
  ProfileAnalyticsName,
  WorkerQueries,
} from '../../../../../utils/constants'
import { updateLocationInfoOB } from '../../../../../services/workerApi'
import { useAppDispatch, setWorkerInfo } from '../../../../../redux'
import { useTypedSelector, useStateWithDep } from '../../../../../utils/hooks'
import {
  compareTwoArrayOfString,
  formatPhoneNumber,
  onTrackingActions,
  skippingLocationDataFields,
} from '../../../../../utils/scripts'
import { BasicInfoUI } from './BasicInfoUI'
import './basicInfo.scss'
import { useParams } from 'react-router-dom'

export const BasicInfo = () => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const { modal } = useParams<{ modal: string }>()
  const isOpenModalUrl = modal === 'edit'
  const userInfo = useTypedSelector(s => s.obInfo.workerInfo)
  const communicationData = ['Phone Call', 'SMS (Text)', 'Email']
  const minTravelDistance = 2

  const [isModalVisible, setIsModalVisible] = useState(isOpenModalUrl ?? false)
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false)

  const initialStateInfo = {
    first_name: '',
    last_name: '',
    username: '',
    phone: '',
    communication_preference: [],
    zip_code: null,
    travel_distance: defaultTravelDistance,
  }
  const [stateForm, setStateForm] = useState<BasicInfoModel>(initialStateInfo)

  const basicInfoQuery = queryClient.getQueryState(WorkerQueries.basicInfo)

  const { data: basicInfo, isLoading } = useQuery<BasicInfoModel>(
    WorkerQueries.basicInfo,
    () => getBasicInfo(),
    {
      enabled: !basicInfoQuery?.data,
    }
  )

  const relocationInfoMutation = useMutation((data: ILocationInfoOB) =>
    updateLocationInfoOB(skippingLocationDataFields(data))
  )

  const basicInfoMutation = useMutation((data: BasicInfoModel) =>
    updateBasicInfo(data)
  )

  const prepStateInfo = useMemo(() => {
    if (basicInfo) {
      return {
        ...basicInfo,
        phone: basicInfo?.phone ?? '',
      }
    }
  }, [basicInfo])

  const [stateInfo, setStateInfo] = useStateWithDep<BasicInfoModel>(
    prepStateInfo ?? initialStateInfo
  )

  const savingLoader = useMemo(
    () => relocationInfoMutation.isLoading || basicInfoMutation.isLoading,
    [basicInfoMutation.isLoading, relocationInfoMutation.isLoading]
  )

  const defaultLocationInfo: ILocationInfoOB = useMemo(
    () => ({
      preferred_locations: !userInfo.user_data.preferred_locations
        ? []
        : userInfo.user_data.preferred_locations,
      home_location: basicInfo?.zip_code
        ? {
            ...userInfo.user_data.home_location,
            zip_code: basicInfo?.zip_code,
          }
        : userInfo.user_data.home_location,
      willing_to_relocate:
        userInfo.user_data.willing_to_relocate === null
          ? false
          : userInfo.user_data.willing_to_relocate,
      relocation_location: userInfo.user_data.relocation_location,
    }),
    [
      basicInfo?.zip_code,
      userInfo.user_data.home_location,
      userInfo.user_data.willing_to_relocate,
      userInfo.user_data.preferred_locations,
      userInfo.user_data.relocation_location,
    ]
  )

  const [locationInfo, setLocationInfo] =
    useStateWithDep<ILocationInfoOB>(defaultLocationInfo)

  const homeLocationDescription = useMemo(() => {
    if (
      !userInfo.user_data.home_location?.raw_input &&
      userInfo.user_data.home_location?.state &&
      userInfo.user_data.home_location?.city
    ) {
      return `${userInfo.user_data.home_location.city}, ${userInfo.user_data.home_location.state}`
    }

    return `${userInfo.user_data.home_location?.raw_input ?? 'None Provided'}`
  }, [userInfo.user_data.home_location])

  const phoneNumber = useMemo(() => {
    if (stateInfo?.phone) {
      return (
        formatPhoneNumber(
          stateInfo.phone.includes('+')
            ? stateInfo.phone.slice(2)
            : stateInfo.phone
        ) ?? 'None Provided'
      )
    }

    return 'None Provided'
  }, [stateInfo])

  const isDataNotChanged = useMemo(() => {
    const didPrefChange = compareTwoArrayOfString(
      stateForm?.communication_preference,
      stateInfo?.communication_preference
    )

    const didPrefLocationChange =
      userInfo.user_data.preferred_locations &&
      locationInfo.preferred_locations &&
      userInfo.user_data.preferred_locations.length ===
        locationInfo.preferred_locations.length &&
      userInfo.user_data.preferred_locations.every((el, index) => {
        return (
          locationInfo.preferred_locations &&
          el.latitude === locationInfo.preferred_locations[index].latitude
        )
      })

    return (
      stateInfo?.phone?.replace(/\D/g, '')?.toString() ===
        stateForm?.phone?.replace(/\D/g, '')?.toString() &&
      didPrefChange &&
      stateInfo?.travel_distance === stateForm?.travel_distance &&
      userInfo.user_data.home_location?.latitude?.toString() ===
        locationInfo.home_location?.latitude?.toString() &&
      userInfo.user_data.relocation_location?.latitude?.toString() ===
        locationInfo.relocation_location?.latitude?.toString() &&
      userInfo.user_data.relocation_location?.date ===
        locationInfo.relocation_location?.date &&
      didPrefLocationChange
    )
  }, [locationInfo, userInfo, stateInfo, stateForm])

  const handleSubmit = (
    e: React.SyntheticEvent<HTMLFormElement>,
    movingPlanning: ILocationInfoOB
  ) => {
    e.preventDefault()
    if (isDataNotChanged) {
      setIsModalVisible(false)
      return
    }
    if (!locationInfo.home_location.latitude) {
      toast.error('Please enter your location')
    } else {
      relocationInfoMutation.mutate(
        {
          ...movingPlanning,
          willing_to_relocate: !!movingPlanning.relocation_location,
        },
        {
          onSuccess(data) {
            const preparedData = {
              ...userInfo,
              user_data: {
                ...userInfo.user_data,
                home_location: data.home_location,
                relocation_location: data.relocation_location,
                preferred_locations: data.preferred_locations,
                willing_to_relocate: !!data.relocation_location,
              },
            }

            queryClient.setQueryData(WorkerQueries.workerInfoOB, preparedData)
            dispatch(setWorkerInfo(preparedData))

            const preparedStateForm: BasicInfoModel = {
              ...stateForm,
              phone: stateForm.phone ?? '',
              zip_code: locationInfo.home_location.zip_code ?? '',
            }
            basicInfoMutation.mutate(preparedStateForm, {
              onSuccess: () => {
                queryClient.setQueryData(
                  WorkerQueries.basicInfo,
                  preparedStateForm
                )
                onTrackingActions(ProfileAnalyticsName.profile_edited)
                setStateInfo(stateForm)
                setIsModalVisible(false)
              },
              onError: err => {
                if (err instanceof Error) toast.error(err.message)
              },
            })
          },
          onError: err => {
            if (err instanceof Error) toast.error(err.message)
          },
        }
      )
    }
  }

  useEffect(() => {
    if (basicInfo)
      setStateForm({
        ...basicInfo,
        travel_distance: basicInfo.travel_distance ?? defaultTravelDistance,
      })
  }, [basicInfo])

  return (
    <BasicInfoUI
      userInfo={userInfo}
      isLoading={isLoading}
      stateForm={stateForm}
      basicInfo={basicInfo}
      stateInfo={stateInfo}
      phoneNumber={phoneNumber}
      savingLoader={savingLoader}
      handleSubmit={handleSubmit}
      locationInfo={locationInfo}
      setStateForm={setStateForm}
      isDataNotChanged={isDataNotChanged}
      isModalVisible={isModalVisible}
      setLocationInfo={setLocationInfo}
      setIsModalVisible={setIsModalVisible}
      communicationData={communicationData}
      minTravelDistance={minTravelDistance}
      defaultLocationInfo={defaultLocationInfo}
      homeLocationDescription={homeLocationDescription}
      isConfirmationModalVisible={isConfirmationModalVisible}
      setIsConfirmationModalVisible={setIsConfirmationModalVisible}
    />
  )
}
