import { Button, WorkerReviewCard } from '../../../../atoms'
import { ModalBox } from '../../../General'
import { ShareDataOfWorker } from '../../WorkerDetails'
import { IEmployerHistory } from './ActionSidebar'
import shareIcon from '../../../../../assets/icons/active-icons/share-icon.svg'
import saveIcon from '../../../../../assets/icons/active-icons/save-icon2.svg'
import interviewIcon from '../../../../../assets/icons/active-icons/two_people-icon.svg'
import './actionSidebar.scss'
import { EmpActionType, UserCard } from '../../../../../models'
import { ModalData } from '../../../../../pages/InAppFlow'
import { v4 as uuidv4 } from 'uuid'

interface Props {
  isModalVisible: boolean
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  userData: UserCard | null
  isSaved: boolean
  saveAction: (category: string, id: number, addAction: boolean) => void
  modalAction?: (activeModal: ModalData) => void
  historyList?: IEmployerHistory[]
  isSaving: boolean
  isDisabletInterviewButton: boolean
}

export const ActionSidebarUI = (props: Props) => {
  const {
    isModalVisible,
    setIsModalVisible,
    userData,
    isSaved,
    saveAction,
    modalAction,
    historyList,
    isSaving,
    isDisabletInterviewButton,
  } = props

  return (
    <div className="action-sidebar">
      {isModalVisible && (
        <ModalBox
          className="modal-box_external-close-Icon"
          visibleCloseIcon
          onCloseModal={() => setIsModalVisible(false)}
        >
          <ShareDataOfWorker setIsModalVisible={setIsModalVisible} />
        </ModalBox>
      )}
      <div className="action-sidebar__actions">
        <p className="action-sidebar__actions-title">Actions</p>

        <Button
          className="action-sidebar__btn"
          onClick={e => {
            e.stopPropagation()
            userData && saveAction(EmpActionType.saved, userData.id, !isSaved)
          }}
        >
          {isSaving ? (
            <div className="action-sidebar__btn-loader" />
          ) : (
            <img
              className="action-sidebar__btn-img"
              src={saveIcon}
              alt="save-icon"
            />
          )}
          {isSaved ? 'Saved' : 'Save'}
        </Button>

        <Button
          className="action-sidebar__btn"
          onClick={() => setIsModalVisible(true)}
        >
          <img
            className="action-sidebar__btn-img"
            src={shareIcon}
            alt="share-icon"
          />
          Share
        </Button>

        <Button
          className="action-sidebar__btn"
          onClick={() =>
            modalAction &&
            userData &&
            modalAction({
              user: userData,
              modalName: EmpActionType.interview,
            })
          }
          disabled={isDisabletInterviewButton}
        >
          <img
            className="action-sidebar__btn-img"
            src={interviewIcon}
            alt="interview-icon"
          />
          Interview
        </Button>
      </div>

      <div className="action-sidebar__history">
        <p className="action-sidebar__actions-title">History</p>

        <div className="action-sidebar__history-list">
          {historyList?.length ? (
            historyList.map(el => (
              <WorkerReviewCard
                key={uuidv4()}
                title={el.title}
                description={el.description}
                photo={el.photo}
                date={el.date}
              />
            ))
          ) : (
            <p>No history yet</p>
          )}
        </div>
      </div>
    </div>
  )
}
