import React, { useCallback, useEffect, useRef, useState } from 'react'
import usePlacesAutocomplete, { HookArgs } from 'use-places-autocomplete'
import { EWorkerSStoreKeys } from '../../../../utils/constants'
import { useStateWithDep } from '../../../../utils/hooks'
import { AutocompleteInputUI } from './AutocompleteInputUI'

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: string
  texterror?: string
  clearErrorOnChange?: boolean
  places?: HookArgs | true
  suggestions?: string[]
  onClickSuggestion?: (value: string) => void
  showFocus?: boolean
  externalRef?: React.RefObject<HTMLInputElement>
}

export const AutocompleteInput = (props: IProps) => {
  const {
    value,
    onChange,
    texterror,
    clearErrorOnChange,
    places,
    suggestions,
    onClickSuggestion,
    showFocus,
    externalRef,
    ...otherProps
  } = props

  const {
    value: placeValue,
    suggestions: { data },
    setValue: setPlaceValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['(cities)'],
      componentRestrictions: {
        country: 'us',
      },
    },
  })

  const internalRef = useRef<HTMLInputElement>(null)
  const inputRef = externalRef ?? internalRef
  const [textError, setTextError] = useStateWithDep(texterror)
  const [suggestionsList, setSuggestionsList] = useState<string[]>([])
  const [isSuggVisible, setIsSuggVisible] = useState<boolean>(
    suggestions ? false : true
  )

  const onChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      clearErrorOnChange && setTextError(undefined)
      places && setPlaceValue(e.target.value)
      onChange && onChange(e)
      if (suggestions) {
        setIsSuggVisible(e.target.value ? true : false)
      }
    },
    [
      clearErrorOnChange,
      suggestions,
      onChange,
      setPlaceValue,
      setTextError,
      places,
    ]
  )

  const onClickSuggestionHandler = useCallback(
    (text: string) => {
      onClickSuggestion && onClickSuggestion(text)
      clearSuggestions()
      sessionStorage.removeItem(EWorkerSStoreKeys.placeAutocomplete)
      if (suggestions) {
        setIsSuggVisible(false)
      }
    },
    [clearSuggestions, onClickSuggestion, suggestions]
  )

  useEffect(() => {
    if (places) {
      setSuggestionsList(
        data.map(el => el.description.split(', ').slice(0, -1).join(', '))
      )
    }
    if (suggestions) {
      setSuggestionsList(suggestions)
    }
  }, [data, places, suggestions])

  useEffect(() => {
    if (showFocus) {
      inputRef?.current?.focus()
    }
  }, [inputRef, showFocus])

  return (
    <AutocompleteInputUI
      ref={inputRef}
      isSuggVisible={isSuggVisible}
      value={value}
      suggestions={suggestionsList}
      textError={textError}
      onChange={onChangeHandler}
      onClickSuggestion={onClickSuggestionHandler}
      isPlaces={!suggestions}
      placeValue={placeValue}
      {...otherProps}
    />
  )
}
