import React, { forwardRef } from 'react'
import './input.scss'
import { useStateWithDep } from '../../../../utils/hooks'
import { ErrorMessageWithCross } from '../../ErrorMessages'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  containerClassName?: string
  labelText?: string
  labelClassName?: string
  inputClassName?: string
  textError?: string
  clearErrorOnChange?: boolean
}
export type InputRef = HTMLInputElement

export const Input = forwardRef<InputRef, InputProps>((props, ref) => {
  const {
    containerClassName,
    labelText,
    labelClassName,
    inputClassName,
    textError: error,
    clearErrorOnChange,
    onChange,
    ...otherProps
  } = props

  const [textError, setTextError] = useStateWithDep(error)
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (clearErrorOnChange) {
      setTextError(undefined)
    }
    if (onChange) {
      onChange(e)
    }
  }

  return (
    <div>
      <div className={`input-container ${containerClassName ?? ''}`}>
        <input
          ref={ref}
          className={`input-container__input ${inputClassName ?? ''}`}
          {...otherProps}
          onChange={onChangeInput}
        />
        {labelText && (
          <label
            className={`input-container__label ${labelClassName ?? ''}`}
            htmlFor={props.id}
          >
            {labelText}
          </label>
        )}
      </div>
      {textError !== undefined && (
        <ErrorMessageWithCross textError={textError} />
      )}
    </div>
  )
})
