import React, { useState, useMemo, useCallback } from 'react'
import { WorkerVerificationUI } from './WorkerVerificationUI'
import {
  IVerifyData,
  IWorkerInfoAdminPage,
  IWorkerStatus,
} from '../../../../models/AdminWorkes'
import {
  ECompletionStatusOB,
  verifyAttendance,
  verifyMotivation,
  verifyProfessionalism,
} from '../../../../utils/constants'
import './workerVerification.scss'

interface Props {
  worker: IWorkerInfoAdminPage
  statusHandler: (status: IWorkerStatus) => void
}

export const WorkerVerification = (props: Props) => {
  const { worker, statusHandler } = props

  const initialInputData: IVerifyData = {
    attendance_punctuality_score: [],
    professionalism_score: [],
    attitude_score: [],
    notes: '',
  }
  const [verificationData, setVerificationData] =
    useState<IVerifyData>(initialInputData)

  const percentScore = useMemo(() => {
    return {
      attendance:
        100 *
        (verificationData.attendance_punctuality_score.length /
          verifyAttendance.length),
      professionalism:
        100 *
        (verificationData.professionalism_score.length /
          verifyProfessionalism.length),
      attitude:
        100 *
        (verificationData.attitude_score.length / verifyMotivation.length),
    }
  }, [verificationData])

  const verifyWorker = useCallback(() => {
    const preparedVerifyData: IWorkerStatus = {
      status: ECompletionStatusOB.verified,
      interview_details: {
        attendance_punctuality_score: percentScore.attendance,
        professionalism_score: percentScore.professionalism,
        attitude_score: percentScore.attitude,
        answers: {
          attendance_punctuality: verificationData.attendance_punctuality_score,
          professionalism: verificationData.professionalism_score,
          motivation: verificationData.attitude_score,
        },
        notes: verificationData.notes || null,
      },
    }
    statusHandler(preparedVerifyData)
  }, [percentScore, verificationData, statusHandler])

  return (
    <WorkerVerificationUI
      worker={worker}
      verifyWorker={verifyWorker}
      verificationData={verificationData}
      setVerificationData={setVerificationData}
    />
  )
}
