import { Config } from '../../components/organism'
import {
  IFiltersOptionsAdminPage,
  IListOfWorkesAdminPage,
  IQueryParamsWorkersList,
  IUploadResumeOfInvitedWorker,
  IWorkerStatus,
} from '../../models/AdminWorkes'
import { API } from '../API'
import { responseHandler } from '../responseHandlers'

export const uploadResumeOfInvitedWorker = async (
  data: FormData,
  config?: Config
): Promise<IUploadResumeOfInvitedWorker> => {
  return responseHandler(
    API.post('api/admin-portal/parse-resume/', data, config ?? undefined)
  )
}

export const inviteNewWorker = async (data: FormData): Promise<any> => {
  return responseHandler(API.post('api/admin-portal/invite/create/', data))
}

export const getListOfWorkersAdminPage = async (
  data: IQueryParamsWorkersList | null
): Promise<IListOfWorkesAdminPage> => {
  return responseHandler(API.post('api/admin-portal/workers-list/', data))
}

export const getWorkerToken = async (id: number): Promise<string> => {
  return responseHandler(API.get(`api/admin-portal/${id}/worker-token/`))
}

export const editWorkerStatus = async (
  status: IWorkerStatus,
  id: number | null
) => {
  return responseHandler(API.post(`api/admin-portal/${id}/set-status/`, status))
}

export const getFiltersOptionsAdminPage =
  async (): Promise<IFiltersOptionsAdminPage> => {
    return responseHandler(API.get('api/admin-portal/filter-params/'))
  }
