import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../../Context'
import { Chart } from 'react-google-charts'
import { colorCalculator } from '../../../utils/scripts/colorCalculator'

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export const ChartStatistic = (props: Props) => {
  const context = useContext(Context)
  const [data, setData] = useState<Ydata[]>([])
  const color = context.challengeResult
    ? colorCalculator.color(context.challengeResult.score, false)
    : null
  const betterScore =
    context.metrics?.better_score !== undefined
      ? context.metrics?.better_score
      : null
  const pointCircle = `point { size:8; shape-type: circle; color: ${color?.second}}`
  const pointLine =
    'point { size: 0; shape-type: circle; } line {stroke-color: #E5E5E5; }'
  const pointUrl =
    'point { size: 0; shape-type: circle; } line {stroke-color: url(#grad1); }'
  const gradientLine = `
  <defs>
      <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="${color?.first}"/>
          <stop offset="1" stop-color="${color?.second}"/>
      </linearGradient>
  </defs>`

  type Ydata = (number | string | object)[]

  const NormalDensityZx = (x: number, Mean: number, StdDev: number) => {
    let a = x - Mean
    return (
      Math.exp(-(a * a) / (1.1 * StdDev * StdDev)) /
      (Math.sqrt(2 * Math.PI) * StdDev)
    )
  }

  const options = {
    height: window.innerWidth >= 1024 ? 250 : 160,
    annotations: {
      stem: {
        color: 'transparent',
        length: -6,
      },
      textStyle: {
        auraColor: 'transparent',
        color: '#FFFFFF',
        bold: true,
        fontSize: 10,
      },
    },
    pointSize: 1,
    dataOpacity: 1,
    legend: { position: 'top' },
    hAxis: {
      title: 'Percentile',
      titleTextStyle: {
        color: '#737373',
        italic: false,
        bold: false,
        fontSize: 14,
      },
      baselineColor: 'none',
      gridlines: { color: 'transparent', count: 10 },
      showTextEvery: 1,
      textStyle: {
        bold: false,
        textPosition: 'none',
        color: '#737373',
        fontName: 'Poppins',
      },
    },

    vAxis: {
      gridlineColor: 'none',
      textPosition: 'none',
      baselineColor: 'none',
      gridlines: { count: 0 },
    },
    curveType: 'function',

    colors: [`${color?.second}`],
    chartArea: {
      height: '80%',
      width: '100%',
      top: 5,
      bottom: 75,
      left: 20,
      right: 20,
    },
    lineWidth: 6,
  }

  useEffect(() => {
    if (betterScore !== null) {
      let chartData = []
      const percent = betterScore === 0 ? 1 : Math.round(betterScore)
      chartData.push([
        'Percentage',
        'People',
        { type: 'string', role: 'style' },
        { type: 'string', role: 'tooltip' },
      ])
      let index = 1
      for (let i = 0; i <= 100; i++) {
        let xAxis = NormalDensityZx(index, 50, 21)
        chartData[index] = [5]
        chartData[index][0] = i
        chartData[index][1] = xAxis * 100

        if (index === percent) {
          chartData[index][2] = pointCircle
        } else {
          if (index > percent) {
            chartData[index][2] = pointLine
          } else {
            chartData[index][2] = pointUrl
          }
        }
        chartData[index][3] = `Percentile: ${index}%\nPeople: ${+Math.round(
          xAxis * 1000
        )}%`
        index++
      }
      setData(chartData)
    }
  }, [betterScore, pointCircle])

  return (
    <div {...props}>
      {data.length && betterScore !== null ? (
        <Chart
          chartType="LineChart"
          width={'100%'}
          loader={<div>Loading Chart</div>}
          data={data}
          options={options}
          chartEvents={[
            {
              eventName: 'ready',
              callback({ chartWrapper }) {
                if (chartWrapper.getChart()) {
                  let container = document.querySelector(
                    '.quiz-container__graph-wrapper'
                  )
                  if (container) {
                    let svg =
                      container.children[0].children[0].getElementsByTagName(
                        'svg'
                      )[0]
                    svg?.insertAdjacentHTML('beforeend', gradientLine)
                  }
                }
              },
            },
          ]}
          rootProps={{ 'data-testid': '1' }}
        />
      ) : (
        ''
      )}
    </div>
  )
}
