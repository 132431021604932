import { useState, useEffect, useCallback } from 'react'
import { SocialLoginType } from '../../../../components/molecules/General/SocialLogin'
import { useLocation, useParams } from 'react-router'
import { useAppDispatch } from '../../../../redux/store'
import { setVerificattionModal, signIn } from '../../../../redux/auth-reducer'
import { unwrapResult } from '@reduxjs/toolkit'
import { BACKEND_URL } from '../../../../services/config'
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify'
import { onEventPage } from '../../../../utils/scripts'
import './signInPage.scss'
import { SignInPageUI } from './SignInPageUI'

export enum EPageTitle {
  sign_in = 'Skillit - Sign in',
}

export const SignInPage = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { email } = useParams<{ email: string }>()
  const [decodeEmail, setDecodeEmail] = useState(decodeURIComponent(email)) // = decodeURIComponent(email);
  const [fpModalDisplay, setFpModalDisplay] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)
  const [isPopupLoaderShowed, setIsPopupLoaderShowed] = useState(false)

  const onClickLoginType = (type: SocialLoginType) => {
    switch (type) {
      case SocialLoginType.Facebook:
        const url = new URL('api/facebook/authorization', BACKEND_URL)
        window.open(url.toString(), '_self')
    }
  }

  const onConfirmEmailLogin = useCallback(
    async (email: string, password: string) => {
      setIsPopupLoaderShowed(true)
      await dispatch(
        signIn({ email: email, password: password, rememberMe: rememberMe })
      )
        .then(unwrapResult)
        .catch(error => {
          setIsPopupLoaderShowed(false)

          if (error.message === 'unverified email') {
            return dispatch(setVerificattionModal(true))
          }
          if (error.name !== 'TypeError') toast.error(error.message)
        })
    },
    [dispatch, rememberMe]
  )

  const onCloseModal = () => {
    setFpModalDisplay(false)
  }

  useEffect(() => {
    onEventPage(location.pathname, EPageTitle.sign_in)
  }, [location.pathname])

  useEffect(() => {
    setDecodeEmail(decodeURIComponent(email))
  }, [email])

  return (
    <SignInPageUI
      email={email}
      rememberMe={rememberMe}
      decodeEmail={decodeEmail}
      onCloseModal={onCloseModal}
      setRememberMe={setRememberMe}
      fpModalDisplay={fpModalDisplay}
      onClickLoginType={onClickLoginType}
      isPopupLoaderShowed={isPopupLoaderShowed}
      setFpModalDisplay={setFpModalDisplay}
      onConfirmEmailLogin={onConfirmEmailLogin}
    />
  )
}
