import React, { SetStateAction } from 'react'
import { Button } from '../../../../components/atoms'

import './feedbackPage.scss'

interface IProps {
  maxLength: number
  notes: string
  setNotes: React.Dispatch<SetStateAction<string>>
  isSuccess: boolean
  isLoading: boolean
  onClickBtn: () => void
}

export const FeedbackPageUI = (props: IProps) => {
  const { maxLength, notes, setNotes, isSuccess, isLoading, onClickBtn } = props

  return (
    <div className="feedback-page">
      <h2 className="feedback-page__title">Thank you for your feedback!</h2>

      <div className="feedback-page__text">
        Feel free to add any additional questions / comments / feedback below
      </div>

      {!isSuccess && (
        <div className="feedback-page__textarea-container">
          <textarea
            className="feedback-page__textarea"
            placeholder="Notes"
            maxLength={maxLength}
            value={notes}
            onChange={e => setNotes(e.target.value)}
          />

          <div className="feedback-page__textarea-counter">
            {notes.length}/{maxLength}
          </div>
        </div>
      )}

      <Button
        className={`feedback-page__btn ${
          isSuccess ? 'feedback-page__btn_success' : ''
        }`}
        disabled={!notes.length || isLoading || isSuccess}
        onClick={onClickBtn}
      >
        {isSuccess ? 'Sent!' : 'Send'}
      </Button>
    </div>
  )
}
