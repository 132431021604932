/* eslint-disable no-extend-native */
export {}

declare global {
  interface Set<T> {
    equal(rhs: Set<T>): boolean
  }
}

Set.prototype.equal = function <T>(this: Set<T>, rhs: Set<T>): boolean {
  if (this.size !== rhs.size) {
    return false
  }
  const arr = Array.from(this)

  for (let el of arr) {
    if (!rhs.has(el)) {
      return false
    }
  }

  return true
}
