import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AsyncThunkConfig } from '.'
import { IWorkerInfoOB, UserStatus } from '../models'
import { getWorkerInfoOB } from '../services/workerApi'
import {
  ECompletionStatusOB,
  EWorkerSStoreKeys,
  initialWorkerInfoOB,
} from '../utils/constants'
import { getUserInfoFromStorage } from '../utils/scripts'
import { getUserStatus } from '../services/auth-service'

export const syncObData: AsyncThunk<IWorkerInfoOB, void, AsyncThunkConfig> =
  createAsyncThunk('OB-INFO/SYNC-OB-DATA', async (_, thunkAPI) => {
    const obData = await getWorkerInfoOB()
    const userStatus: UserStatus = await getUserStatus()

    if (!obData.is_completed) {
      sessionStorage.setItem(EWorkerSStoreKeys.obData, JSON.stringify(obData))
    }
    if (
      userStatus.details?.status === ECompletionStatusOB.invited &&
      (obData.user_data.incomplete_work_experience.length ||
        obData.user_data.work_experience.length)
    ) {
      sessionStorage.setItem(
        EWorkerSStoreKeys.obIsInvited,
        JSON.stringify('true')
      )
    }

    if (
      userStatus.details?.status === ECompletionStatusOB.invited &&
      obData.user_data.language.length
    ) {
      sessionStorage.setItem(
        EWorkerSStoreKeys.obPreFilledLanguage,
        JSON.stringify('true')
      )
    }

    if (
      userStatus.details?.status === ECompletionStatusOB.registered &&
      obData.user_data.incomplete_work_experience.length
    ) {
      sessionStorage.setItem(
        EWorkerSStoreKeys.obIncompleteExperience,
        JSON.stringify('true')
      )
    }

    sessionStorage.removeItem(EWorkerSStoreKeys.placeAutocomplete)
    return obData
  })

export const obInfoSlice = createSlice({
  name: 'OB-INFO',
  initialState: {
    workerInfo: initialWorkerInfoOB,
  },
  reducers: {
    setWorkerInfo: (state, action) => {
      state.workerInfo = action.payload
      const userInfoFromStorage = getUserInfoFromStorage()

      if (!state.workerInfo.is_completed && !userInfoFromStorage) {
        sessionStorage.setItem(
          EWorkerSStoreKeys.obData,
          JSON.stringify(state.workerInfo)
        )
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(syncObData.fulfilled, (state, action) => {
      state.workerInfo = action.payload
    })
  },
})

export const { setWorkerInfo } = obInfoSlice.actions
export const obReducer = obInfoSlice.reducer
