import React, { useContext } from 'react'
import { LastJobsContext } from '../../../../Context'
import { ModalBox } from '../../../../molecules'
import { AddJobForm } from './AddJobForm'
import { BestJob } from './BestJob'
import { useTranslation } from 'react-i18next'
import './bestJobsOB.scss'
import {
  TCombinedFormType,
  TEditIncompleteExp,
} from '../../../../../pages/Onboarding/OBLastJobs/OBLastJobs'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  isFormOpen: false | 'add' | number
  setIsFormOpen: React.Dispatch<React.SetStateAction<false | 'add' | number>>
  addOneMoreJob: () => void
  isPromptOpen: boolean
  setIsPromptOpen: React.Dispatch<React.SetStateAction<boolean>>
  onCloseModal: (value: boolean, setIsFormOpen: TCombinedFormType) => void
  isClickCloseBtn: boolean
  setIsClickCloseBtn: React.Dispatch<React.SetStateAction<boolean>>
  setJobEditModal: React.Dispatch<
    React.SetStateAction<false | TEditIncompleteExp>
  >
}

export const BestJobsOBUI = (props: IProps) => {
  const {
    className,
    isFormOpen,
    setIsFormOpen,
    addOneMoreJob,
    setIsClickCloseBtn,
  } = props
  const jobs = useContext(LastJobsContext).jobs
  const { t } = useTranslation()

  return (
    <div className={`three-best-jobs ${className ?? ''}`}>
      {isFormOpen === 'add' && (
        <ModalBox
          className="three-best-jobs__form"
          onCloseModal={() => {
            setIsClickCloseBtn(true)
          }}
          visibleCloseIcon
          bgClickDisabled
        >
          <AddJobForm
            {...props}
            isFormOpen={isFormOpen}
            setIsFormOpen={setIsFormOpen}
          />
        </ModalBox>
      )}
      {jobs.length >= 3 ? (
        <>
          {jobs.map((job, i) => {
            return (
              <BestJob
                {...props}
                isFormOpen={isFormOpen}
                setIsFormOpen={setIsFormOpen}
                key={job.company_name ?? '' + i}
                index={i}
              />
            )
          })}
          <button
            className="three-best-jobs__one-more-btn"
            onClick={addOneMoreJob}
          >
            {t('last_jobs.Want to add one more job? Click here.')}
          </button>
        </>
      ) : (
        <>
          <BestJob
            {...props}
            isFormOpen={isFormOpen}
            setIsFormOpen={setIsFormOpen}
            index={0}
          />
          <BestJob
            {...props}
            isFormOpen={isFormOpen}
            setIsFormOpen={setIsFormOpen}
            index={1}
          />
          <BestJob
            {...props}
            isFormOpen={isFormOpen}
            setIsFormOpen={setIsFormOpen}
            index={2}
          />
        </>
      )}
    </div>
  )
}
