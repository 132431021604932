export const getSupportedLanguages = (
  listLanguages: { choice: string; is_supported: boolean }[]
) => {
  const supportedLanguages = listLanguages
    ?.filter(lang => lang.is_supported)
    .map(lang => lang.choice)
  if (!supportedLanguages.length) return ''
  if (supportedLanguages.length === 1) return supportedLanguages[0]
  if (supportedLanguages.length === 2) return supportedLanguages.join(' and ')

  return `${supportedLanguages.slice(0, -1).join(', ')} and ${
    supportedLanguages[supportedLanguages?.length - 1]
  }`
}
