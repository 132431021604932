import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { GOOGLE_ID } from '../services/config'

declare global {
  interface Window {
    gtag?: (
      key: string,
      trackingId: string | undefined,
      config: { page_path: string; page_location: string; page_title: string }
    ) => void
  }
}

export const GoogleAnalytics = () => {
  const history = useHistory()

  useEffect(() => {
    const unlisten = history.listen(location => {
      const gtag = window.gtag
      if (window.location.pathname === location.pathname) {
        return
      }
      if (history.action === 'PUSH' && typeof gtag === 'function') {
        gtag('config', GOOGLE_ID, {
          page_title: document.title,
          page_location: window.location.href,
          page_path: location.pathname,
        })
      }
    })

    return () => unlisten()
  }, [history])

  return null
}

export const sendMetriks = (
  action: string,
  category: string,
  label: string,
  value?: string
) => {
  const values: any = {}
  if (typeof category !== 'undefined') {
    values['event_category'] = category
  }
  if (typeof label !== 'undefined') {
    values['event_label'] = label
  }
  if (typeof value !== 'undefined') {
    values['value'] = value
  }
  if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {
    window.gtag('event', action, values)
  }
}
