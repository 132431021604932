import React from 'react'
import { Helmet } from 'react-helmet'
import * as route from '../../../../services/route'
import { ButtonBack, Loader } from '../../../../components/atoms'
import {
  workWithTypeData,
  TypeResume,
} from '../../../../components/organism/General/UploadFiles/DataTypes/DataTypes'
import {
  AboutMeBlock,
  Config,
  LinksBlock,
  PublicProfileHeader,
  UploadFiles,
  WorkExperienceBlock,
} from '../../../../components/organism'
import { ProfileAssessmentBlock } from '../../../../components/organism/Worker/ProfileComponents/ProfileAssessmentBlock'
import { ProfileVideoBlock } from '../../../../components/organism/Worker/ProfileComponents/ProfileVideoBlock'
import { PublicInfo } from '../../../../models'
import { EWorkerPageTitle } from '../../../../utils/constants'
import './publicProfile.scss'

interface Props {
  uuid?: string
  isPromptHidden: boolean
  publicInfo?: PublicInfo
  isPublicInfoLoading: boolean
  onTrackingUploadFile: () => void
  setIsPromptHidden: React.Dispatch<React.SetStateAction<boolean>>
  mutatePublicFiles: (formData: FormData, config?: Config) => Promise<unknown>
}

export const PublicProfileUI = (props: Props) => {
  const {
    uuid,
    publicInfo,
    isPromptHidden,
    mutatePublicFiles,
    setIsPromptHidden,
    isPublicInfoLoading,
    onTrackingUploadFile,
  } = props
  return (
    <div className="public-profile">
      <ButtonBack route={route.profile} title="Profile" />

      <Helmet>
        <title>{EWorkerPageTitle.profile}</title>
      </Helmet>
      <div
        className={`public-profile__prompt-wrapper${
          isPromptHidden ? ' public-profile__prompt_hidden' : ''
        }`}
        data-testid="public-profile__prompt-wrapper"
      >
        <div className="public-profile__prompt container">
          <div
            className="public-profile__prompt-close-icon"
            onClick={() => {
              setIsPromptHidden(true)
            }}
            data-testid="public-profile__prompt-close-icon"
          ></div>
          Welcome to your public profile! The info you share here is what firms
          will see when they consider you for a job. To edit any of your info,
          click the
          <span className="public-profile__prompt-pencil-img-wrapper">
            <div className="public-profile__prompt-pencil-img"></div>
          </span>
          icon.
        </div>
      </div>

      <div className="public-profile__content">
        <section className="public-profile__edit-block public-profile__basic-info-rating-star public-profile__basic-info-block">
          {!isPublicInfoLoading ? (
            publicInfo && (
              <PublicProfileHeader
                publicInfoData={publicInfo}
                onTrackingUploadFile={onTrackingUploadFile}
              />
            )
          ) : (
            <Loader />
          )}
        </section>
        <section className="public-profile__edit-block">
          {publicInfo && (
            <ProfileAssessmentBlock
              className="container"
              publicInfo={publicInfo}
              uuid={uuid ?? ''}
            />
          )}
        </section>
        <section className="public-profile__edit-block">
          <AboutMeBlock
            className="container"
            publicAboutMeText={publicInfo && publicInfo.about_me}
          />
        </section>
        <section className="public-profile__edit-block">
          <ProfileVideoBlock
            videoFile={publicInfo?.video ?? undefined}
            onTrackingUploadFile={onTrackingUploadFile}
          />
        </section>
        <section className="public-profile__edit-block">
          <div className="container">
            <h3 className="public-profile__block-name">Resume</h3>
            <UploadFiles
              className="public-profile__resume"
              accept={workWithTypeData.resumeSupportTypes().toString()}
              updatedFile={publicInfo?.resume ?? undefined}
              description="Show firms and our Career guides what you’ve done"
              buttonName="Add Resume"
              typeData={TypeResume}
              uploadButtonText="Change/Delete Resume"
              modalTitleTxt="Resume"
              modalUploadTxt="Resume"
              modalRemoveTxt="Current Resume"
              formDataName="resume"
              updateAction={mutatePublicFiles}
              onTrackingUploadFile={onTrackingUploadFile}
            />
          </div>
        </section>
        <section className="public-profile__edit-block">
          {publicInfo && (
            <WorkExperienceBlock
              className="container"
              publicInfo={publicInfo}
            />
          )}
        </section>
        <section className="public-profile__edit-block">
          <LinksBlock
            className="container"
            publicLinks={publicInfo && publicInfo.links}
          />
        </section>
      </div>
    </div>
  )
}
