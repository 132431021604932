import React, { useState, useCallback, useMemo } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { Context } from '../../../../components/Context'
import { QuizResultProgress } from '../../../../components/molecules/Worker/quiz-result-progress'
import { ResultsModel } from '../../../../models/ChallengeResultModel'
import './quiz-result.scss'
import * as route from '../../../../services/route'
import {
  getChallengeResult,
  getMetrics,
} from '../../../../services/auth-service'
import { Banner } from '../../../../components/atoms/Banner'
import arrowBack from '../../../../assets/icons/arrows/yellow-arrow-left_1.svg'
import { Carusel } from '../../../../components/molecules/General/Carusel'
import { SecondaryCard } from '../../../../components/molecules'
import forJobsImg from '../../../../assets/img/worker/forJobMobile.png'
import forJobsDesk from '../../../../assets/img/worker/forJobsImgDesk.png'
import {
  AsyncStatus,
  useAsyncAction,
  useMediaContext,
} from '../../../../utils/hooks'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ChallengeDetail } from '../../../../components/organism/Worker/ChallengeDetail'
import { getInAppChallenges } from '../../../../services/workerApi/getApi'
import { LoadingContent } from '../../../../components/molecules/General/LoadingContent'
import { Helmet } from 'react-helmet'

export const QuizResults = () => {
  const isMobile = useMediaContext()
  const location = useLocation()
  const [challengeResult, setChallengeResult] = useState<ResultsModel>({
    challengeResult: null,
    metrics: null,
  })
  const urlParts = location.pathname.split('/')
  const formId = +urlParts[urlParts.length - 1]

  const getResults = useCallback(
    async () =>
      await Promise.all([getChallengeResult(formId), getMetrics(formId)]).catch(
        error => {
          toast.error('Unable to fetch metrics for this form and score')
          return error
        }
      ),
    [formId]
  )

  const challengeResultAction = useAsyncAction(getResults, true)
  const challengeResultLoading =
    challengeResultAction.status === AsyncStatus.Executing
  const value = challengeResultAction.value
  useMemo(() => {
    if (value !== null) {
      setChallengeResult({ challengeResult: value[0], metrics: value[1] })
    }
  }, [value])

  const challengeListAction = useAsyncAction(getInAppChallenges, true)
  const challengeListLoading =
    challengeListAction.status === AsyncStatus.Executing
  const challenges = (challengeListAction.value ?? []).filter(el => el.visible)

  return (
    <Context.Provider value={challengeResult}>
      <ToastContainer autoClose={3000} theme="colored" />
      <Helmet>
        <title>Skillit - Quiz result</title>
      </Helmet>
      <Banner className="banner-quiz">
        <Link className="banner-quiz__link link" to={route.challenges}>
          {' '}
          <img src={arrowBack} alt="arrow-back" /> Back to all challenges
        </Link>
        <h1 className="banner-quiz__title">Challenge Results</h1>
        <h2 className="banner-quiz__sub-title">
          {challengeResult.challengeResult?.name}
        </h2>
        <p className="banner-quiz__description">
          {challengeResult.challengeResult?.description}
        </p>
      </Banner>

      <div className="container-quiz">
        <LoadingContent isLoading={challengeResultLoading}>
          <QuizResultProgress className="container-quiz__container-margin" />
        </LoadingContent>
        <div className="container-quiz__container-title container">
          <h2 className="container-quiz__title container">
            Take the Next Challenge
          </h2>
          <hr className="container-quiz__hr" />
        </div>
        <LoadingContent isLoading={challengeListLoading}>
          <Carusel
            className="container-quiz__carusel"
            numberOfItems={challenges.length}
            itemAtIndex={index => (
              <div style={{ width: 334 }}>
                <ChallengeDetail
                  classChallenge="challenge_carusel"
                  model={challenges[index]}
                  key={challenges[index].key}
                />
              </div>
            )}
          ></Carusel>
        </LoadingContent>
        <div
          className={`container-quiz__secondary-card ${
            challengeListLoading ? 'container-quiz__secondary-card_margin' : ''
          }`}
        >
          <div className="challenge-page__contests-bck-img-wrapper">
            <div className="container-quiz__contests-bck-img"></div>
          </div>
          <Banner className="challenge-page__contests-banner">
            <SecondaryCard
              imgUrl={isMobile ? forJobsImg : forJobsDesk}
              title="Apply for Jobs"
              description="Find the right job for you with companies that will pay you a premium for your specific skill set."
              buttonText="Match me with my perfect Job"
              className="challenge-page__contests-jobs-card"
              action={route.jobsPage}
            />
          </Banner>
        </div>
      </div>
    </Context.Provider>
  )
}
