export const DASHBOARD_PAGE = 'emp_dashboard_page'

export enum EmpActionType {
  assessment = 'assessment',
  interview = 'interview',
  makeOffer = 'make_offer',
  saved = 'saved',
  hidden = 'hidden',
}

export enum EFields {
  skills = 'skills',
  role = 'role',
  location = 'location',
}

export enum EFieldProperies {
  roleSearch = 'role_search',
  name = 'name',
  regular = 'regular',
  instant = 'instant',
}

export enum FilterType {
  all = 'all',
  saved = 'saved',
  recentlyViewed = 'recently_viewed',
  hidden = 'hidden',
  search = 'search',
}

export enum WorkPreferences {
  full_time = 'full_time',
  permanent = 'permanent',
  part_time = 'part_time',
  temp = 'temp',
  temp_to_permanent = 'temp_to_permanent',
}

export enum osha_status {
  osha_10 = 'OSHA Construction 10 hr',
  osha_30 = 'OSHA 30',
  willingToObtain_OSHA_30 = 'Willing to obtain OSHA',
  osha_40 = 'OSHA 40',
  osha_62 = 'OSHA 62',
}

export enum willing_to_obtain_osha {
  obtain_osha_10 = 'Willing to obtain OSHA 10',
  obtain_osha_30 = 'Willing to obtain OSHA 30',
  obtain_osha_40 = 'Willing to obtain OSHA 40',
  obtain_osha_62 = 'Willing to obtain OSHA 62',
}

export enum Qualifications {
  CPR = 'CPR',
  AED = 'AED',
  FirstAid = 'First Aid',
  SpanishFluency = 'Fluent in Spanish',
  EnglishFluency = 'Fluent in English',
  ElevatorOperator = 'Elevator Operator',
}

export enum Tools {
  SteelToeBoots = 'Steel Toe Boots',
  HardHat = 'Hard Hat',
  SafetyVest = 'Safety Vest',
  ToolBeltAndBasicTools = 'Tool Belt & Basic Tools',
}

export enum Skills {
  roughCarpentry = 'Rough Carpentry',
  formwork = 'Formwork',
  concrete = 'Concrete',
  finishCarpentry = 'Finish Carpentry',
  constructionMath = 'Construction Math',
  cranesRigging = 'Cranes & Rigging',
  planReading = 'Plan Reading',
}
