import { useMemo, useCallback, useState } from 'react'
import { useInput } from '../../../../utils/hooks'
import { setPassword } from '../../../../services/auth-service'
import { toast } from 'react-toastify'
import { ChangePasswordPageUI } from './ChangePasswordPageUI'
import { useMutation } from 'react-query'

interface Props {
  closeModal: () => void
}

export interface PasswordInput {
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}
interface IPasswords {
  currentPassword: string
  newPassword: string
}

export const messageList = {
  CHARACTERS_ERROR: '8_CHARACTERS_ERROR',
  CAPITAL_LETTER_ERROR: '1_CAPITAL_LETTER_ERROR',
  LOWERCASE_LETTER_ERROR: '1_LOWERCASE_LETTER_ERROR',
  SPECIAL_LETTER_ERROR: '1_SPECIAL_LETTER_ERROR',
  PASSWORD_DOES_NOT_MATCH_ERROR: "Passwords doesn't match",
  INVALID_PASSWORD_ERROR: 'The old password you have entered is incorrect.',
  SUCCESS_MESSAGE: 'Password is set',
}

const validate = (text: string): string[] => {
  let errors: string[] = []
  if (text.length < 8) {
    errors.push(messageList.CHARACTERS_ERROR)
  }
  if (text.toLowerCase() === text) {
    errors.push(messageList.CAPITAL_LETTER_ERROR)
  }
  if (text.toUpperCase() === text) {
    errors.push(messageList.LOWERCASE_LETTER_ERROR)
  }
  const format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/
  if (!format.test(text)) {
    errors.push(messageList.SPECIAL_LETTER_ERROR)
  }
  return errors
}

export const ChangePasswordPage = ({ closeModal }: Props) => {
  const [serverError, setServerError] = useState<string>()
  const currentPasswordInput = useInput()
  const newPasswordInput = useInput()
  const confirmPasswordInput = useInput('', () => setServerError(undefined))

  const runTimeValidateErrors = useMemo(() => {
    return validate(newPasswordInput.value)
  }, [newPasswordInput.value])

  const isActive = useMemo(() => {
    return (
      currentPasswordInput.value.length > 0 &&
      newPasswordInput.value.length > 0 &&
      confirmPasswordInput.value.length > 0 &&
      runTimeValidateErrors.length === 0
    )
  }, [
    confirmPasswordInput.value.length,
    currentPasswordInput.value.length,
    runTimeValidateErrors.length,
    newPasswordInput.value.length,
  ])

  const setPasswordMutation = useMutation(
    (data: IPasswords) => setPassword(data.currentPassword, data.newPassword),
    {
      onSuccess: () => {
        toast.success(messageList.SUCCESS_MESSAGE)
        closeModal()
      },
      onError: (err: Error) => {
        setServerError(err?.message)
      },
    }
  )

  const onChangePassword = useCallback(() => {
    if (newPasswordInput.value !== confirmPasswordInput.value) {
      setServerError(messageList.PASSWORD_DOES_NOT_MATCH_ERROR)
      return
    }
    setServerError(undefined)
    setPasswordMutation.mutate({
      currentPassword: currentPasswordInput.value,
      newPassword: newPasswordInput.value,
    })
  }, [
    newPasswordInput.value,
    confirmPasswordInput.value,
    currentPasswordInput.value,
    setPasswordMutation,
  ])

  return (
    <ChangePasswordPageUI
      isActive={isActive}
      isLoading={setPasswordMutation.isLoading}
      serverError={serverError}
      newPasswordInput={newPasswordInput}
      changePasswordAction={onChangePassword}
      confirmPasswordInput={confirmPasswordInput}
      currentPasswordInput={currentPasswordInput}
      runTimeValidateErrors={runTimeValidateErrors}
    />
  )
}
