import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import './counterWithPopup.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  list: string[]
  containerRef?: React.RefObject<HTMLDivElement>
}

export const CounterWithPopup = (props: Props) => {
  const { className, list, containerRef } = props
  const wrapperRef = useRef<HTMLDivElement>(null)
  const itemRef = useRef<HTMLDivElement>(null)
  const popupRef = useRef<HTMLDivElement>(null)
  const [showPopup, setShowPopup] = useState(false)
  const [isLongItemName, setIsLongItemName] = useState(false)

  useEffect(() => {
    if (wrapperRef?.current && itemRef?.current) {
      const wrapperWidth = wrapperRef.current.offsetWidth
      const itemWidth = itemRef.current.offsetWidth
      const itemText = itemRef.current.textContent

      if (list.length === 1 && itemWidth > wrapperWidth) {
        setIsLongItemName(true)
      }

      if (itemText && itemWidth > wrapperWidth) {
        const maxChars =
          Math.floor((wrapperWidth / itemWidth) * itemText.length) - 2
        itemRef.current.textContent = `${itemText.slice(0, maxChars)}`
      }
    }
  }, [list.length, isLongItemName])

  useLayoutEffect(() => {
    if (containerRef) {
      const containerY = containerRef.current?.getBoundingClientRect().y
      const popupY = popupRef.current?.getBoundingClientRect().y

      if (showPopup && containerY && popupY && popupY < containerY) {
        popupRef.current.style.top = '-100%'
      }
    }
  }, [showPopup, containerRef])

  return (
    <div className={`counter-with-popup ${className ?? ''}`} ref={wrapperRef}>
      <div className="counter-with-popup__item" ref={itemRef}>
        {list[0]}
      </div>

      {(list.length > 1 || isLongItemName) && (
        <div className="counter-with-popup__counter">
          <div className="counter-with-popup__counter-blur"></div>
          <div
            className="counter-with-popup__counter-number"
            data-testid="counter-with-popup__counter-number"
            onMouseEnter={() => setShowPopup(true)}
          >
            {isLongItemName && list.length === 1
              ? '...'
              : `+${list.length - 1}`}
          </div>
        </div>
      )}

      {showPopup && (
        <div
          className="counter-with-popup__popup"
          data-testid="counter-with-popup__popup"
          ref={popupRef}
          onMouseLeave={() => setShowPopup(false)}
        >
          {list.join(', ')}
        </div>
      )}
    </div>
  )
}
