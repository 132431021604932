import React from 'react'
import { Helmet } from 'react-helmet'
import { Button, Checkbox, Input } from '../../../components/atoms'
import {
  NavigationFooter,
  ProgressBarLinear,
} from '../../../components/molecules'
import { ITradesOB } from '../../../models'
import {
  EPageTitleOB,
  listBlocksPBLinear,
  RoleNames,
} from '../../../utils/constants/obConstants'
import backgroundImg from '../../../assets/icons/other/buble_img.svg'
import { v4 as uuidv4 } from 'uuid'
import './obTrades.scss'
import { handleCheckboxProfile } from '../../InAppFlow/Worker/ProfileCompletion/handleProfileInputs'
import { useTranslation } from 'react-i18next'

interface Props {
  isFilledOtherTrade: boolean
  preparedRolesData?: string[]
  setObTrade: React.Dispatch<React.SetStateAction<ITradesOB>>
  isOtherTradeInput: boolean
  onClickBack: () => void
  onClickNext: (
    initTrades: ITradesOB,
    initSetTrade: React.Dispatch<React.SetStateAction<ITradesOB>>,
    initSetIsFilledOtherTrade: React.Dispatch<React.SetStateAction<boolean>>,
    initSetIsOtherTrade: React.Dispatch<React.SetStateAction<boolean>>
  ) => void
  obTrade: ITradesOB
  setIsFilledOtherTrade: React.Dispatch<React.SetStateAction<boolean>>
  setIsOtherTradeInput: React.Dispatch<React.SetStateAction<boolean>>
  isDisabled: boolean
  supportedRoles: string[] | null
  onChangeOtherInput: (value: string) => void
  otherTradeRef: React.RefObject<HTMLInputElement>
}

export const OBTradesUI = (props: Props) => {
  const {
    isFilledOtherTrade,
    preparedRolesData,
    setObTrade,
    isOtherTradeInput,
    onClickBack,
    onClickNext,
    obTrade,
    setIsFilledOtherTrade,
    setIsOtherTradeInput,
    isDisabled,
    supportedRoles,
    onChangeOtherInput,
    otherTradeRef,
  } = props
  const { t } = useTranslation()

  return (
    <div className="trades-page ob-container">
      <Helmet>
        <title> {EPageTitleOB.trades} </title>
      </Helmet>
      {!isFilledOtherTrade ? (
        <div className="trades-page__wrapper">
          <ProgressBarLinear
            className="trades-page__progress-bar"
            initData={listBlocksPBLinear}
            currentPage={3}
          />
          <div className="trades-page__form">
            <p className="trades-page__form-question ob-question">
              {t('trades.Which trade(s) best describe you?')}
            </p>
            <p className="trades-page__form-description ob-description">
              {t('trades.Tell us about the trades in which you are skilled.')}
            </p>
            <div className="trades-page__form-editable-block block-choice-trade">
              {preparedRolesData &&
                preparedRolesData.map((trade, tradeIndex) => {
                  const checked = obTrade.roles?.find(el => el === trade)
                  return (
                    <Checkbox
                      key={uuidv4()}
                      buttonsGrid
                      className={`${
                        checked ? 'ob__checkbox_checked' : ''
                      } block-choice-trade_name`}
                      name={t([`trades.${trade}`, `${trade}`]).toString()}
                      idCheckbox={tradeIndex}
                      checked={checked ? true : undefined}
                      toggleCheckbox={(_, isChecked) => {
                        handleCheckboxProfile(
                          isChecked,
                          setObTrade,
                          trade,
                          'roles'
                        )
                        trade === RoleNames.other_trade &&
                          setIsOtherTradeInput(!isChecked)
                      }}
                    />
                  )
                })}
            </div>
            {isOtherTradeInput && (
              <Input
                className={`trades-page__form-input ${
                  obTrade.other_role ? 'filled-input' : ''
                }`}
                value={obTrade.other_role ?? ''}
                onChange={e => onChangeOtherInput(e.target.value)}
                placeholder={t('trades.What trade do you practice?').toString()}
                ref={otherTradeRef}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="other-trade-block">
          <div className="other-trade-block__wrapper">
            <p className="other-trade-block__title ob-question">
              {t('trades.Thanks for your interest!')}
            </p>

            <p className="other-trade-block__subtitle ob-question">
              {t("trades.Unfortunately we're not yet working with role", {
                role: obTrade.other_role
                  ?.split(', ')
                  .map(el => t([`trades.${el}`, el]) + 's')
                  .join(', '),
              })}
            </p>

            <p className="other-trade-block__description ob-description">
              {t("trades.But we'll be sure to let you know the moment we do!")}
            </p>

            <p className="other-trade-block__question">
              {t(
                'trades.Would you be interested in an entry level position in any of the following trades?'
              )}
            </p>

            <div className="other-trade-block-editable-block block-choice-trade">
              {supportedRoles?.map((supportedRole, supportedRoleIndex) => {
                const checked = obTrade.roles?.find(el => el === supportedRole)
                return (
                  <Checkbox
                    className={`${
                      checked ? 'ob__checkbox_checked' : ''
                    } block-choice-trade_name`}
                    key={supportedRole}
                    buttonsGrid
                    name={t([
                      `trades.${supportedRole}`,
                      `${supportedRole}`,
                    ]).toString()}
                    idCheckbox={supportedRoleIndex}
                    checked={checked ? true : undefined}
                    toggleCheckbox={(_, isChecked) => {
                      handleCheckboxProfile(
                        isChecked,
                        setObTrade,
                        supportedRole,
                        'roles'
                      )
                    }}
                  />
                )
              })}
            </div>
          </div>
          <img
            className="other-trade-block__background-img"
            src={backgroundImg}
            alt="not-interesting-trade"
          />
        </div>
      )}

      <NavigationFooter actionBack={() => onClickBack()}>
        <Button
          onClick={() => {
            onClickNext(
              obTrade,
              setObTrade,
              setIsFilledOtherTrade,
              setIsOtherTradeInput
            )
          }}
          disabled={isDisabled}
        >
          {t('Shared_Header.Next')}
        </Button>
      </NavigationFooter>
    </div>
  )
}
