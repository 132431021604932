import { IUserDataSlots, InterviewerModel } from './../../models/ScheduleModel'
import { toast } from 'react-toastify'
import {
  CallendarsList,
  UserReservation,
  AvaliavleNow,
  Onboarding,
  SpecificJob,
} from '../../models/ScheduleModel'
import { BACKEND_URL } from '../config'
import { API, API_NO_TOKEN } from '../API'
import { ERROR_SOMETHING } from '../auth-service'
import {
  ChallengeModel,
  Job,
  QualificationsUser,
  ChallengeResultModel,
} from '../../models'
import { responseHandler } from '../responseHandlers'

export const getInAppChallenges = async (): Promise<ChallengeModel[]> => {
  return responseHandler(API.get('api/forms/in_app/'))
}

export const getAssessmentForm = async (): Promise<ChallengeModel[]> => {
  return responseHandler(API.get('api/forms/assessment/'))
}

export const getQualificationForm = async (
  id: string | null
): Promise<ChallengeModel[] | null> => {
  return responseHandler<ChallengeModel[] | null>(
    API.get(`api/forms/qualification?job_id=${id}`)
  ).catch(() => {
    toast.error(ERROR_SOMETHING)
    return Promise.reject()
  })
}

export const getJobs = async (): Promise<Job[]> => {
  return responseHandler(API.get('api/forms/job/'))
}

export const getQualificationsData = async (): Promise<QualificationsUser> => {
  return responseHandler(API.get('api/users/get_info/'))
}
//TODO: refactoring:in all requests use ReactQuery
export const getPreInterviewQualifyResult = async (
  id: string | null,
  email: string
): Promise<{ if_qualified: boolean }> => {
  return responseHandler(
    API_NO_TOKEN.get(`api/users/if-qualified?email=${email}&job_id=${id}`)
  )
}

export const getFreeSlots = async (
  job_id: string | null,
  date: string,
  timezone: string,
  uuid: string,
  type: Onboarding | SpecificJob | null
): Promise<IUserDataSlots> => {
  return responseHandler(
    API_NO_TOKEN.get(
      `api/slots/get-available-slots/?month=${date}&timezone=${timezone}&uuid=${uuid}${
        type ? '&type=' + type : ''
      }${job_id ? '&job_id=' + job_id : ''}`
    )
  )
}
//TODO: refactoring:in all requests use ReactQuery
export const bookSlot = async (
  date: UserReservation
): Promise<InterviewerModel> => {
  return responseHandler(API_NO_TOKEN.post('api/slots/book-slot/', date))
}

export const getAssessmentScore = async (
  email: string
): Promise<ChallengeResultModel | null> => {
  return responseHandler<ChallengeResultModel | null>(
    API_NO_TOKEN.get(`api/forms/assessment-result/?email=${email}`)
  ).catch(_ => {
    return Promise.resolve(null)
  })
}
//TODO:not used
export const removeAssessmentScore = async () => {
  return responseHandler(API.get('api/users/discard-assessment-result/'))
} //TODO:not used
export const removePreInterviewNotification = async (): Promise<void> => {
  return responseHandler(API.get('api/users/delete-notification/'))
}

export const getCallendarsList = async (): Promise<CallendarsList[]> => {
  return responseHandler(API.get(`${BACKEND_URL}api/slots/list-calendars/`))
}

export const getJobDescription = async (id: string): Promise<any> => {
  ///TODO need iterate over and leave disired properties for the model
  return responseHandler(API.get(`${BACKEND_URL}api/jobs/${id}/`)).catch(() => {
    toast.error(ERROR_SOMETHING)
  })
}

export const getIsAvaliableNow = async (
  event_type: string,
  type: string,
  job_id: string | null
): Promise<AvaliavleNow> => {
  return responseHandler(
    API.get(
      `${BACKEND_URL}api/slots/check-allowance${type ? '?type=' + type : ''}${
        event_type ? '?event_type=' + event_type : ''
      }${job_id ? '?job_id=' + job_id : ''}`
    )
  )
}
