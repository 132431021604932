import React from 'react'
import './overallCompletion.scss'

interface Props extends React.HTMLAttributes<HTMLElement> {
  overallPercentage: number | undefined
  title: string
  description?: string
}

export const OverallCompletion = (props: Props) => {
  return (
    <div className={`overall-completion ${props.className ?? ''}`}>
      <div className="overall-completion__percent-block">
        <div className="overall-completion__percent-block-title">
          {props.title}
        </div>
        <div
          className="overall-completion__percent-block-percent"
          data-testid="overall-completion__percent-block-percent"
        >
          {props.overallPercentage ?? ''}%
        </div>
      </div>

      <div className="overall-completion__line">
        <div
          className="overall-completion__line-filled"
          data-testid="overall-completion__line-filled"
          style={{ width: `${props.overallPercentage ?? 0}%` }}
        ></div>
      </div>

      {props.description && (
        <div className="overall-completion__description">
          {props.description}
        </div>
      )}
    </div>
  )
}
