import moment from 'moment'
import React from 'react'
import Calendar from 'react-calendar'
import { Helmet } from 'react-helmet'
import { Button } from '../../../components/atoms'
import {
  NavigationFooter,
  ProgressBarLinear,
  RadioButtonsList,
} from '../../../components/molecules'
import { OBPlaceAutocomplete } from '../../../components/molecules/Worker/OBPlaceAutocomplete/OBPlaceAutocomplete'
import { ILocationInfoOB } from '../../../models'
import {
  EPageTitleOB,
  listBlocksPBLinear,
} from '../../../utils/constants/obConstants'
import { useTranslation } from 'react-i18next'
import './obMovePlanning.scss'

interface Props {
  onClickBack: () => void
  onClickNext: (
    initRelocationOB: ILocationInfoOB,
    initSetRelocationOB: React.Dispatch<React.SetStateAction<ILocationInfoOB>>
  ) => void
  isMovePlanning: number | undefined
  setIsMovePlanning: React.Dispatch<React.SetStateAction<number | undefined>>
  isFilledPlace: boolean
  setIsFilledPlace: React.Dispatch<React.SetStateAction<boolean>>
  relocationOB: ILocationInfoOB
  setRelocationOB: React.Dispatch<React.SetStateAction<ILocationInfoOB>>
  defaultRelocationInfo: ILocationInfoOB
  onSelectDate: (initDate: Date) => void
  isWaitingResponse: boolean
}

export const OBMovePlanningUI = (props: Props) => {
  const {
    onClickBack,
    onClickNext,
    isMovePlanning,
    setIsMovePlanning,
    isFilledPlace,
    setIsFilledPlace,
    relocationOB,
    setRelocationOB,
    defaultRelocationInfo,
    onSelectDate,
    isWaitingResponse,
  } = props
  const { t } = useTranslation()

  return (
    <div className="move-planning">
      <Helmet>
        <title> {EPageTitleOB.move_planning} </title>
      </Helmet>

      <div
        className={`move-planning__wrapper ob-container ${
          isMovePlanning === 1 ? 'move-planning__wrapper-filled-place' : ''
        }`}
      >
        <ProgressBarLinear
          className="move-planning__progress-bar"
          initData={listBlocksPBLinear}
          currentPage={5}
        />
        <p className="move-planning__question ob-question">
          {t('move_planning.Are you planning to move in the next 3 months?')}
        </p>
        <p className="move-planning__description ob-description">
          {t(
            'move_planning.Your location directly affects search results for an employer'
          )}
        </p>

        <RadioButtonsList
          className={`move-planning__radio-btn ${
            isMovePlanning === 1 ? 'move-planning__radio-btn-filled-place' : ''
          }`}
          list={[
            { id: 1, value: `${t('move_planning.Yes')}` },
            { id: 2, value: `${t('move_planning.No')}` },
          ]}
          selected={isMovePlanning}
          onChangeBtn={(id: number) => {
            setIsMovePlanning(id)
            setRelocationOB(defaultRelocationInfo)
          }}
          singleButtonsGrid
        />

        {isMovePlanning === 1 && (
          <div
            className={`move-planning__place ${
              isFilledPlace ? 'filled_place' : ''
            }`}
          >
            <p className="move-planning__place-title">
              {t('move_planning.Awesome! Where are you heading?')}
            </p>
            <OBPlaceAutocomplete
              className="move-planning__place-autocomplete"
              setIsFilledPlace={setIsFilledPlace}
              placeholder={t('move_planning.Enter a Town / City')}
              locationOB={relocationOB}
              setLocationOB={setRelocationOB}
              relocationPage
              showFocus={true}
            />
          </div>
        )}

        {isMovePlanning === 1 && isFilledPlace && (
          <div className="move-planning__calendar-wrapper">
            <p className="move-planning__calendar-title">
              {t('move_planning.When are you going?')}
            </p>
            <Calendar
              className="move-planning__calendar schedule__calendar"
              minDate={new Date()}
              showDoubleView={false}
              showFixedNumberOfWeeks={false}
              showNeighboringMonth={false}
              calendarType="US"
              minDetail="year"
              next2Label={null}
              prev2Label={null}
              onClickDay={value => {
                onSelectDate(value)
              }}
              value={
                relocationOB.relocation_location?.date
                  ? moment(relocationOB.relocation_location?.date).toDate()
                  : null
              }
            />
          </div>
        )}
      </div>

      <NavigationFooter actionBack={() => onClickBack()}>
        <Button
          onClick={() => onClickNext(relocationOB, setRelocationOB)}
          disabled={
            !(isMovePlanning === 2 || relocationOB.relocation_location?.date) ||
            isWaitingResponse
          }
        >
          {t('Shared_Header.Next')}
        </Button>
      </NavigationFooter>
    </div>
  )
}
