import {
  Button,
  ElemObj,
  Input,
  Loader,
  Multiselect,
  Textarea,
} from '../../../../atoms'
import { RadioBtnElement, RadioButtonsList } from '../../../../molecules'
import { UserCard } from '../../../../../models'
import { PopupLoader } from '../../../General'
import { IInterviewData } from './InterviewFormat'
import interviewIcon from '../../../../../assets/icons/active-icons/interview.svg'

import './interviewFormat.scss'

interface IProps {
  isLoading: boolean
  locationsList: ElemObj[]
  interviewData: IInterviewData
  setInterviewData: React.Dispatch<React.SetStateAction<IInterviewData>>
  selectedFormat: number
  setSelectedFormat: React.Dispatch<React.SetStateAction<number>>
  sendInterview: () => void
  workerData?: UserCard
  interviewTypes?: RadioBtnElement[]
  tradesList: ElemObj[]
  textboxHandler: (value: string, name: keyof IInterviewData) => void
}

export const InterviewFormatUI = (props: IProps) => {
  const {
    isLoading,
    locationsList,
    interviewData,
    setInterviewData,
    selectedFormat,
    setSelectedFormat,
    sendInterview,
    workerData,
    interviewTypes,
    tradesList,
    textboxHandler,
  } = props

  return (
    <div className="employer-interview" data-testid="employer-interview">
      {isLoading && <PopupLoader></PopupLoader>}
      <h4 className="employer-interview__title">
        <img
          className="employer-interview__icon"
          src={interviewIcon}
          alt="interview"
        />
        Invite&nbsp;
        <span>
          {workerData?.first_name ?? ''} {workerData?.last_name ?? ''}&nbsp;
        </span>
        to interview
      </h4>

      <div className="employer-interview__container">
        <section className="employer-interview__section">
          <p style={{ fontSize: '14px' }}>
            Please confirm you would like to interview{' '}
            <strong>
              {workerData?.first_name ?? ''} {workerData?.last_name ?? ''}
            </strong>
          </p>

          {interviewTypes ? (
            <>
              <RadioButtonsList
                list={interviewTypes}
                onChangeBtn={id => setSelectedFormat(id)}
                selected={selectedFormat}
              />

              <Multiselect
                className="employer-interview__section-select"
                multiSelect={false}
                elements={locationsList}
                selectedElement={interviewData.location}
                getSelectedElement={selectedItem =>
                  setInterviewData(prevData => ({
                    ...prevData,
                    location: selectedItem,
                  }))
                }
                placeholder="Location"
              />

              <Multiselect
                className="employer-interview__section-select"
                multiSelect={false}
                elements={tradesList}
                selectedElement={interviewData.trade}
                getSelectedElement={selectedItem =>
                  setInterviewData(prevData => ({
                    ...prevData,
                    trade: selectedItem,
                  }))
                }
                placeholder="Trade"
              />

              <Input
                className="employer-interview__section-input"
                value={interviewData.role}
                onChange={e => textboxHandler(e.target.value, 'role')}
                placeholder="Role"
                maxLength={50}
              />

              <div className="employer-interview__section-hint employer-interview__section-hint_role">
                Please enter the name of the role you are hiring for.
              </div>

              <Textarea
                className="employer-interview__section-textarea"
                value={interviewData.availability}
                onChange={e => textboxHandler(e.target.value, 'availability')}
                maxLength={200}
                placeholder="Availability"
              />

              <div className="employer-interview__section-hint">
                {`Tell us when you’re available to interview ${
                  workerData?.first_name ? workerData.first_name : 'worker'
                }. Don’t forget to
                include time zones!`}
              </div>
            </>
          ) : (
            <Loader className="employer-interview__loader" />
          )}

          {interviewTypes && (
            <Button
              onClick={sendInterview}
              className="employer-interview__submit"
              disabled={
                !(
                  interviewData.role &&
                  interviewData.location &&
                  interviewData.availability &&
                  interviewData.trade
                )
              }
            >
              Submit Interview Request
            </Button>
          )}
        </section>

        <aside>
          <div className="employer-interview__notice">
            <div className="employer-interview__notice-wrapper">
              <div className="employer-interview__notice-letter">i</div>
              <p className="employer-interview__notice-title">Heads up!</p>
            </div>

            <div className="employer-interview__notice-text">
              {`The information you provide in this form will be passed directly to ${
                workerData?.first_name ?? 'worker'
              }. Please be specific and courteous.`}
            </div>
          </div>
        </aside>
      </div>
    </div>
  )
}
