import { ILastPageOB, ITradesOB, IWorkerInfoOB } from '../../../models'
import { RoleNames } from '../../../utils/constants'

export const getNewUserInfo = (
  initUserInfo: IWorkerInfoOB,
  initLastPageState: ILastPageOB,
  initObTrades: ITradesOB
): IWorkerInfoOB => ({
  ...initUserInfo,
  last_page: initLastPageState.last_page,
  last_location: initLastPageState.last_location,
  user_data: {
    ...initUserInfo.user_data,
    role: initObTrades.roles,
    other_role: initObTrades.roles?.includes(RoleNames.other_trade)
      ? initObTrades.other_role
      : '',
  },
})
