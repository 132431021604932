import React from 'react'
import { OverallCompletion } from '../../../organism'
import checkedIcon from '../../../../assets/icons/checkmarks/green_checkmark_d.svg'
import dots from '../../../../assets/icons/other/iconDots.svg'
import './progressWidget.scss'
import { ProfileCompletionInfo } from '../../../../models'

interface Props {
  user?: ProfileCompletionInfo
}

export const ProgressWidget = (props: Props) => {
  let progress = 0

  if (props.user?.progress) {
    let key: keyof typeof props.user.progress

    for (key in props.user.progress) {
      if (props.user.progress[key].is_complete) {
        progress = progress + 25
      }
    }
  }

  return (
    <div className="progress-widget">
      <h3 className="progress-widget__title">Your Progress</h3>
      <OverallCompletion title="complete" overallPercentage={progress} />
      <div className="progress-widget__info-block">
        <img
          className="progress-widget__arrow-checked"
          src={props.user?.progress.onboarding.is_complete ? checkedIcon : dots}
          alt="is-checked-icon"
        />
        <p
          className={
            props.user?.progress.onboarding.is_complete
              ? 'progress-widget__field_active'
              : 'progress-widget__field'
          }
        >
          Onboarding
        </p>
      </div>

      <div className="progress-widget__info-block">
        <img
          className="progress-widget__arrow-checked"
          src={
            props.user?.progress.complete_profile.is_complete
              ? checkedIcon
              : dots
          }
          alt="is-checked-icon"
        />
        <p
          className={
            props.user?.progress.complete_profile.is_complete
              ? 'progress-widget__field_active'
              : 'progress-widget__field'
          }
        >
          Complete Profile
        </p>
      </div>

      <div className="progress-widget__info-block">
        <img
          className="progress-widget__arrow-checked"
          src={
            props.user?.progress.career_guide_call.is_complete
              ? checkedIcon
              : dots
          }
          alt="is-checked-icon"
        />
        <p
          className={
            props.user?.progress.career_guide_call.is_complete
              ? 'progress-widget__field_active'
              : 'progress-widget__field'
          }
        >
          Career Guide Call
        </p>
      </div>

      <div className="progress-widget__info-block">
        <img
          className="progress-widget__arrow-checked"
          src={
            props.user?.progress.take_assessment.is_complete
              ? checkedIcon
              : dots
          }
          alt="is-checked-icon"
        />
        <p
          className={
            props.user?.progress.take_assessment.is_complete
              ? 'progress-widget__field_active'
              : 'progress-widget__field'
          }
        >
          Take Assessment
        </p>
      </div>
    </div>
  )
}
