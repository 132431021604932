import { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { IWorkerInfoOB } from '../../../../models'
import { setWorkerInfo, useAppDispatch } from '../../../../redux'
import { getWorkerInfoOB } from '../../../../services/workerApi'
import { WorkerQueries } from '../../../constants'
import { useTypedSelector } from '../../UseTypedSelector'

export const useGetWorkerInfo = () => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const syncUser = useTypedSelector(state => state.auth.user)

  const [isRequestSent, setIsRequestSent] = useState(false)

  const info = useQuery(WorkerQueries.workerInfoOB, () => getWorkerInfoOB(), {
    onSuccess: (data: IWorkerInfoOB) => dispatch(setWorkerInfo(data)),
    onError: err => {
      if (err instanceof Error) {
        setIsRequestSent(true)
        toast.error(err.message)
      }
    },
    enabled:
      !isRequestSent &&
      !!syncUser &&
      !queryClient.getQueryState(WorkerQueries.workerInfoOB)?.data,
  })

  return { ...info, obModelErrorState: isRequestSent }
}
