import { Suspense } from 'react'
import { LoadingScreen } from '../../../pages'

export const suspenseWrapper = (
  Component: React.LazyExoticComponent<() => JSX.Element>
) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component />
    </Suspense>
  )
}
