import React from 'react'
import { ResultWorkExperience } from '../../../../../../models'
import edit_pensil from '../../../../../../assets/icons/other/edit-pencil.svg'

interface Props {
  filteredWorkExperience: ResultWorkExperience[]
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const WorkList = (props: Props) => {
  const { filteredWorkExperience, setIsModalVisible } = props

  return (
    <div data-testid="work-experience-list">
      <div className="work-experience__header">
        <p className="work-experience__header-title">Work Experience</p>
        <img
          className="work-experience__header-edit-pensil"
          src={edit_pensil}
          alt="edit-pensil"
          onClick={() => setIsModalVisible(true)}
        />
      </div>
      {filteredWorkExperience.length > 0 ? (
        <div className="work-experience__jobs-list">
          {filteredWorkExperience.map(job => {
            return (
              <React.Fragment key={job.job_number}>
                <div className="work-experience__job">
                  <div
                    className="work-experience__job-marker"
                    style={job.color}
                  ></div>
                  <div className="work-experience__job-details">
                    <div className="work-experience__job-details-title">
                      {job.questions_work_experience.job_title}
                    </div>
                    <div className="work-experience__job-details-description">
                      <span className="work-experience__job-details-description_text">
                        {`${job.questions_work_experience.company_name} `}
                      </span>
                      <br />
                      <span className="work-experience__job-details-description_text">
                        {`${job.questions_work_experience.start_date} for ${job.questions_work_experience.end_date} months`}
                      </span>
                      {job.questions_work_experience.location && (
                        <>
                          <span className="work-experience__job-details-description_text work-experience__job-details-description_text-separator">
                            |
                          </span>

                          <span className="work-experience__job-details-description_text">
                            {job.questions_work_experience.location}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {job.job_number !== filteredWorkExperience.length && <hr />}
              </React.Fragment>
            )
          })}
        </div>
      ) : (
        <div className="work-experience__wrapper">
          <p className="work-experience__wrapper-description">
            Show firms where you’ve worked in the past
          </p>
        </div>
      )}
    </div>
  )
}
