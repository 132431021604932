import React, { HTMLAttributes } from 'react'
import './sectionTitle.scss'

interface Props extends HTMLAttributes<HTMLElement> {
  titleClassName?: string
}

export const SectionTitle = (props: Props) => {
  return (
    <div className={`block-title ${props.className ?? ''}`}>
      <h4 className={`block-title__title ${props.titleClassName ?? ''}`}>
        {props.children}&ensp;
      </h4>
      <hr />
    </div>
  )
}
