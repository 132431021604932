import { IWorkerInfoAdminPage } from '../../../../models/AdminWorkes'
import { Button } from '../../../atoms'
import { ModalBox } from '../../General'

interface Props {
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
  worker: IWorkerInfoAdminPage
  actionTitle: string
  onClickNo: () => void
  onClickYes: () => void
}

export const StatusTogglersModal = (props: Props) => {
  const { closeModal, worker, actionTitle, onClickNo, onClickYes } = props
  return (
    <ModalBox
      className="modal-box_external-close-Icon"
      onCloseModal={() => {
        closeModal(false)
      }}
      visibleCloseIcon
    >
      <div className="status-togglers__modal">
        <p className="status-togglers__modal-question">
          {` Are you sure you want to ${actionTitle} "${worker.first_name} ${worker.last_name}"?`}
        </p>
        <div className="status-togglers__modal-choice">
          <Button
            className="status-togglers__modal-btn status-togglers__modal-btn_no"
            onClick={onClickNo}
          >
            No
          </Button>
          <Button
            className="status-togglers__modal-btn"
            onClick={onClickYes}
            data-testid="status-togglers__modal-btn"
          >
            {`${actionTitle}`}
          </Button>
        </div>
      </div>
    </ModalBox>
  )
}
