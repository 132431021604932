import React from 'react'
import { useHistory } from 'react-router-dom'
import { ChallengeModel } from '../../../models/ChallengeModel'
import { Button } from '../buttons'
import './challenge.scss'
import challengeIcon from '../../../assets/icons/other/challenge-icon.svg'

interface Props {
  model: ChallengeModel
}

export const Challenge = (props: Props) => {
  const history = useHistory()

  const onClickTakeChallenge = () => {
    history.push(`take-challenge/${props.model.key}`)
  }
  const subTitle = () => {
    if (props.model.level === null && props.model.duration === null) {
      return ''
    }
    if (props.model.level === null) {
      return props.model.duration
    }
    if (props.model.duration === null) {
      return props.model.level
    }
    return `${props.model.level}, ${props.model.duration} minutes`
  }
  return (
    <div className="challenge-container">
      <img src={challengeIcon} alt="challenge" />
      <div className="challenge-description">
        <p>{props.model.title}</p>
        <p>{subTitle()}</p>
      </div>
      <Button
        className="challenge-container__link-btn"
        onClick={onClickTakeChallenge}
      >
        Take the Challenge
      </Button>
    </div>
  )
}
