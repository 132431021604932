import React from 'react'
import { Link } from '../../../../../../models'
import { Button, Input } from '../../../../../atoms'
import { ConfirmationUpdateModal, ModalBox } from '../../../../../molecules'
import { initialLinks } from '../LinksBlock'
import './linksModal.scss'

interface Props extends React.HTMLAttributes<HTMLElement> {
  isConfirmationModalVisible: boolean
  handleSubmit: () => void
  setIsConfirmationModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  filledLinks: Link[]
  setLinks: React.Dispatch<React.SetStateAction<Link[]>>
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  links: Link[]
  addLinkFormIconAndPlaceholderText: (linkTitle: string) => {
    placeholderText: string
    linkIcon: string
  }
  onChangeLinkValue: (value: string, inkName: string) => void
}

export const LinksModalUI = (props: Props) => {
  const {
    className,
    isConfirmationModalVisible,
    handleSubmit,
    setIsConfirmationModalVisible,
    filledLinks,
    setLinks,
    setIsModalVisible,
    links,
    addLinkFormIconAndPlaceholderText,
    onChangeLinkValue,
  } = props

  return (
    <div className={`links-modal ${className ?? ''}`} data-testid="links-modal">
      {isConfirmationModalVisible && (
        <ModalBox className="upload-file__modal-box">
          <ConfirmationUpdateModal
            title="Discard Changes"
            uploadText="Save Changes"
            removeText="Discard Changes"
            updateData={() => {
              handleSubmit()
              setIsConfirmationModalVisible(false)
            }}
            remove={() => {
              filledLinks.length > 0
                ? setLinks(filledLinks)
                : setLinks(initialLinks)
              setIsConfirmationModalVisible(false)
              setIsModalVisible(false)
            }}
          />
        </ModalBox>
      )}
      <p className="links-modal__title">Professional Links</p>
      <form
        method="post"
        onSubmit={e => {
          e.preventDefault()
          handleSubmit()
        }}
        className="links-modal__form"
      >
        <p className="links-modal__form-title">
          Upload any professional Social Media Links
        </p>
        {links.map(link => {
          return (
            <React.Fragment key={link.title}>
              <div className="links-modal__form-link-header">
                <img
                  className="links-modal__form-link-header-icon"
                  src={addLinkFormIconAndPlaceholderText(link.title).linkIcon}
                  alt={`${link.title}`}
                />
                <p className="links-modal__form-link-header-title">
                  {link.title}
                </p>
              </div>
              <Input
                name={link.title}
                value={link.value}
                className="links-modal__input input-container__input"
                placeholder={
                  addLinkFormIconAndPlaceholderText(link.title).placeholderText
                }
                onChange={e => {
                  e.preventDefault()
                  onChangeLinkValue(e.target.value, link.title)
                }}
              />
            </React.Fragment>
          )
        })}

        <hr />
        <div className="links-modal__button-wrapper">
          <Button type="submit" className="links-modal__button-saved">
            SAVE CHANGES
          </Button>
        </div>
      </form>
    </div>
  )
}
