import { ICertificationGroup } from '../../../../../models'
import { WorkerCertificationsBlockUI } from './WorkerCertificationsBlockUI'

interface Props {
  certificationList: ICertificationGroup[]
}

export const WorkerCertificationsBlock = (props: Props) => {
  return <WorkerCertificationsBlockUI {...props} />
}
