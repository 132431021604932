import React from 'react'
import imgEdit from '../../../../../../assets/icons/other/edit-pencil.svg'
import imgEye from '../../../../../../assets/icons/active-icons/eye.svg'
import { BasicInfoModel, IWorkerInfoOB } from '../../../../../../models'
import { v4 as uuidv4 } from 'uuid'
import './basicInfoContent.scss'
import { defaultTravelDistance } from '../../../../../../utils/constants'

interface Props {
  userInfo: IWorkerInfoOB
  basicInfo?: BasicInfoModel
  openModal: React.Dispatch<React.SetStateAction<boolean>>
  homeLocationDescription: string
  phoneNumber: string
}

export const BasicInfoContent = (props: Props) => {
  const {
    userInfo,
    basicInfo,
    openModal,
    homeLocationDescription,
    phoneNumber,
  } = props

  return (
    <div>
      <div className="basic-info-content__title-block">
        <h3>Basic Info</h3>
        <img
          className="basic-info-content__edit-icon"
          src={imgEdit}
          alt="edit"
          onClick={() => openModal(true)}
        />
      </div>
      <div className="basic-info-content__prompt">
        <img src={imgEye} alt="eye" />
        <p>Visible only to you and Skillit until an offer is made</p>
      </div>
      <hr className="basic-info-content__hr" />

      <div className="basic-info-content__info">
        <div className="basic-info-content__info-square"></div>
        <div className="basic-info-content__info-block">
          <p>Email</p>
          <span>
            {basicInfo?.username ? basicInfo.username : 'None Provided'}
          </span>
        </div>
      </div>
      <hr className="basic-info-content__hr" />

      <div className="basic-info-content__info">
        <div className="basic-info-content__info-square"></div>
        <div className="basic-info-content__info-block">
          <p>Phone</p>
          <span>{phoneNumber}</span>
        </div>
      </div>
      <hr className="basic-info-content__hr" />

      <div className="basic-info-content__info">
        <div className="basic-info-content__info-square"></div>
        <div className="basic-info-content__info-block">
          <p>Communication Preference</p>
          {basicInfo?.communication_preference.length ? (
            basicInfo.communication_preference.map((el, i) => (
              <span key={uuidv4()}>{(i ? ', ' : '') + el}</span>
            ))
          ) : (
            <span>None Provided</span>
          )}
        </div>
      </div>
      <hr className="basic-info-content__hr" />

      <div className="basic-info-content__info">
        <div className="basic-info-content__info-square"></div>
        <div className="basic-info-content__info-block">
          <p>Your location</p>
          <span>{homeLocationDescription}</span>
        </div>
      </div>
      <hr className="basic-info-content__hr" />

      <div className="basic-info-content__info">
        <div className="basic-info-content__info-square"></div>
        <div className="basic-info-content__info-block">
          <p>Travel Distance</p>
          <span>
            {basicInfo?.travel_distance
              ? basicInfo.travel_distance
              : defaultTravelDistance}
          </span>
        </div>
      </div>
      <hr className="basic-info-content__hr" />

      <div className="basic-info-content__info">
        <div className="basic-info-content__info-square"></div>
        <div className="basic-info-content__info-block">
          <p>Planning to move</p>
          <span>
            {userInfo.user_data.relocation_location
              ? `${userInfo.user_data.relocation_location.city} ${userInfo.user_data.relocation_location.state} at ${userInfo.user_data.relocation_location.date}`
              : 'None Provided'}
          </span>
        </div>
      </div>
      <hr className="basic-info-content__hr" />

      <div className="basic-info-content__info">
        <div className="basic-info-content__info-square"></div>
        <div className="basic-info-content__info-block">
          <p>Willing to move to location</p>
          <span>
            {userInfo.user_data.preferred_locations?.length
              ? `${userInfo.user_data.preferred_locations
                  ?.map(el => el.city)
                  .join(', ')}`
              : 'None Provided'}
          </span>
        </div>
      </div>
    </div>
  )
}
