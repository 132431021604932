import { unwrapResult } from '@reduxjs/toolkit'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { setWorkerInfo, syncUser, useAppDispatch } from '../../../../redux'
import { LocalStorage } from '../../../../services/localStorage'
import * as route from '../../../../services/route'
import { TokenHolder } from '../../../../services/TokenHolder'
import { getTokenByInviteUrl } from '../../../../services/workerApi'
import { initialWorkerInfoOB } from '../../../constants'
import { useTypedSelector } from '../../UseTypedSelector'

export const useAuthUserByUrl = (
  setIsLoadingScreenShowed: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const isAuth = useTypedSelector(s => s.auth.isAuth)

  const authByInviteUrlMutation = useMutation(
    (data: string) => getTokenByInviteUrl(data),
    {
      onSuccess(data) {
        if (isAuth) {
          localStorage.clear()
          sessionStorage.clear()
          queryClient.clear()
          dispatch(setWorkerInfo(initialWorkerInfoOB))
        }
        LocalStorage.setItemForKey(true, 'flex_profile')
        TokenHolder.set(data.token, false)
        LocalStorage.setItemForKey(true, 'wasRegistred')
        dispatch(syncUser())
          .then(unwrapResult)
          .finally(() => {
            setIsLoadingScreenShowed(false)
            history.push(route.OBPath.onboardingName)
          })
      },

      onError: err => {
        setIsLoadingScreenShowed(false)
        if (err instanceof Error) {
          toast.error(err.message)
        }
      },
    }
  )

  return (tokenFromUrl: string) => {
    setIsLoadingScreenShowed(true)
    authByInviteUrlMutation.mutate(tokenFromUrl)
  }
}
