import { Button } from '../../../../atoms'
import { ModalBox } from '../../../../molecules'
import { AboutMeModal } from './AboutMeModal'
import edit_pensil from '../../../../../assets/icons/other/edit-pencil.svg'
import { PopupLoader } from '../../../General'

interface Props extends React.HTMLAttributes<HTMLElement> {
  isModalVisible: boolean
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  setIsEditorVisible: React.Dispatch<React.SetStateAction<boolean>>
  setInputValue: React.Dispatch<React.SetStateAction<string>>
  inputValue: string
  isEditorVisible: boolean
  maxLengthTextArea: number
  updateInfoAboutMe: (value: string) => void
  savingLoader: boolean
}

export const AboutMeBlockUI = (props: Props) => {
  const {
    isModalVisible,
    setIsModalVisible,
    className,
    inputValue,
    isEditorVisible,
    maxLengthTextArea,
    updateInfoAboutMe,
    setIsEditorVisible,
    setInputValue,
    savingLoader,
  } = props
  return (
    <div className={`about-me ${className ?? ''}`}>
      {savingLoader && <PopupLoader>Saving...</PopupLoader>}
      {isModalVisible && (
        <ModalBox
          className="about-me__modal-box"
          onCloseModal={() => setIsModalVisible(false)}
          visibleCloseIcon={true}
        >
          <AboutMeModal className="container" />
        </ModalBox>
      )}
      <div className="about-me__header">
        <p className="about-me__header-title">About Me</p>
        {!isEditorVisible && (
          <img
            className="about-me__header-edit-pensil"
            src={edit_pensil}
            alt="edit-pensil"
            onClick={() => setIsEditorVisible(true)}
          />
        )}
      </div>

      {isEditorVisible ? (
        <>
          <textarea
            className="about-me__input-text"
            maxLength={maxLengthTextArea}
            rows={3}
            value={inputValue}
            placeholder="Tell us a bit more about you and what makes you stand out as an awesome worker and human. View Examples below."
            onChange={e => setInputValue(e.target.value)}
          />
          <p className="about-me__counter-symbol">{`${inputValue.length}/${maxLengthTextArea}`}</p>

          <Button
            className="about-me__button-example profile_white-btn"
            onClick={() => setIsModalVisible(true)}
          >
            View Example 1
          </Button>
          <Button
            type="submit"
            className="about-me__button-saved profile_white-btn profile_orange-btn"
            onClick={() => updateInfoAboutMe(inputValue)}
          >
            Save Changes
          </Button>
        </>
      ) : (
        <p className="about-me__saved-text">{inputValue}</p>
      )}
    </div>
  )
}
