import React from 'react'
import { IInvitedWorker } from '../../../../../models/AdminWorkes'
import { handleCheckboxProfile } from '../../../../../pages/InAppFlow/Worker/ProfileCompletion/handleProfileInputs'
import {
  formatPhoneNumber,
  inputFormatting,
} from '../../../../../utils/scripts'
import { OBInput, Multiselect, Button, Checkbox } from '../../../../atoms'
import { sourceList } from './InviteWorker'
import './inviteWorker.scss'

interface Props {
  invitedWorker: IInvitedWorker
  setInvitedWorker: React.Dispatch<React.SetStateAction<IInvitedWorker>>
  handleSubmit: () => void
  isDisable: boolean
  supportedRoles:
    | {
        id: number
        choice: string
      }[]
    | null
}

export const InviteWorkerUI = (props: Props) => {
  const {
    invitedWorker,
    setInvitedWorker,
    handleSubmit,
    isDisable,
    supportedRoles,
  } = props

  return (
    <div className="invite-worker">
      <h1 className="invite-worker__title">Invite New Worker</h1>
      <div className="invite-worker__content">
        <div className="invite-worker__inputs-wrapper">
          <OBInput
            placeholder="First Name"
            className="invite-worker__input"
            value={invitedWorker.first_name ?? ''}
            onChange={e =>
              setInvitedWorker({
                ...invitedWorker,
                first_name: inputFormatting(e.target.value, 50),
              })
            }
          />
          <OBInput
            placeholder="Last Name"
            className="invite-worker__input"
            value={invitedWorker.last_name ?? ''}
            onChange={e =>
              setInvitedWorker({
                ...invitedWorker,
                last_name: inputFormatting(e.target.value, 50),
              })
            }
          />
          <OBInput
            placeholder="Email"
            className="invite-worker__input"
            value={invitedWorker.email ?? ''}
            onChange={e =>
              setInvitedWorker({
                ...invitedWorker,
                email: e.target.value,
              })
            }
          />
          <OBInput
            placeholder="Phone"
            className="invite-worker__input"
            value={
              formatPhoneNumber(invitedWorker.phone ?? '', false, true) ?? ''
            }
            onChange={e =>
              setInvitedWorker({
                ...invitedWorker,
                phone: e.target.value,
              })
            }
          />
        </div>
        <Multiselect
          className={`invite-worker__select ${
            invitedWorker.source ? 'invite-worker__select-active' : ''
          }`}
          multiSelect={false}
          elements={sourceList}
          selectedElement={
            sourceList.find(
              source => source.element === invitedWorker.source
            ) ?? null
          }
          getSelectedElement={selectedSource =>
            setInvitedWorker({
              ...invitedWorker,
              source: selectedSource?.element ?? '',
            })
          }
          placeholder={'Source'}
        />
        <div className="invite-worker__checkbox-select">
          <p
            className={`invite-worker__checkbox-select-title ${
              invitedWorker.roles?.length
                ? 'invite-worker__checkbox-select-title_active'
                : ''
            }`}
          >
            Role(s)
          </p>
          <div className="invite-worker__checkbox-select-list">
            {supportedRoles &&
              supportedRoles.map(role => {
                const checked = invitedWorker.roles?.find(
                  el => el === role.choice
                )
                  ? true
                  : undefined
                return (
                  <Checkbox
                    className="invite-worker__checkbox-select-item"
                    key={role.id}
                    buttonsGrid
                    name={role.choice}
                    idCheckbox={role.id}
                    checked={checked}
                    toggleCheckbox={(_, isChecked) => {
                      handleCheckboxProfile(
                        isChecked,
                        setInvitedWorker,
                        role.choice,
                        'roles'
                      )
                    }}
                  />
                )
              })}
          </div>
        </div>
      </div>
      <Button
        className="invite-worker__btn"
        onClick={() => handleSubmit()}
        disabled={isDisable}
      >
        INVITE TO SKILLIT
      </Button>
    </div>
  )
}
