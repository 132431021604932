import React from 'react'
import { UseMutationResult } from 'react-query'
import { DrivingInfo } from '../../../../../../models'
import { DriversLicense } from './DriversLicense/DriversLicense'
import { DrivingStatus } from './DrivingStatus'
import './drivingInfoBlock.scss'
import { Config } from '../../../../../../components/organism'
import { LoadingContent } from '../../../../../../components/molecules'

interface Props extends React.HTMLAttributes<HTMLElement> {
  drivingInfo?: DrivingInfo
  isLoading: boolean
  drivingInfoMutation: UseMutationResult<
    DrivingInfo,
    unknown,
    {
      formData: FormData
      config?: Config
    },
    unknown
  >
}

export const DrivingInfoBlockUI = (props: Props) => {
  const { drivingInfo, isLoading, drivingInfoMutation } = props

  return (
    <div
      className={`driving-info ${props.className ?? ''}`}
      data-testid="driving-info"
    >
      <LoadingContent isLoading={isLoading}>
        <DrivingStatus
          drivingInfo={drivingInfo}
          drivingInfoMutation={drivingInfoMutation}
        />
        <hr className="driving-info__separator" />
        <DriversLicense
          license={drivingInfo?.drivers_license}
          drivingInfoMutation={drivingInfoMutation}
        />
      </LoadingContent>
    </div>
  )
}
