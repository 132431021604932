import React from 'react'
import { Helmet } from 'react-helmet'
import { ZipCode } from 'use-places-autocomplete'
import { Button } from '../../../components/atoms'
import {
  NavigationFooter,
  ProgressBarLinear,
  RadioButtonsList,
} from '../../../components/molecules'
import { OBPlaceAutocomplete } from '../../../components/molecules/Worker/OBPlaceAutocomplete/OBPlaceAutocomplete'
import { ILocationInfoOB } from '../../../models'
import {
  EPageTitleOB,
  listBlocksPBLinear,
} from '../../../utils/constants/obConstants'
import { useTranslation } from 'react-i18next'
import './obWillingToRelocate.scss'

interface Props {
  onClickBack: () => void
  onClickNext: (
    initPreferredPlacesList: ILocationInfoOB,
    initSetPreferredPlacesList: React.Dispatch<
      React.SetStateAction<ILocationInfoOB>
    >
  ) => void
  setWillingToRelocate: React.Dispatch<React.SetStateAction<number | undefined>>
  willingToRelocate?: number
  setPreferredPlacesList: React.Dispatch<React.SetStateAction<ILocationInfoOB>>
  preferredPlacesList: ILocationInfoOB
  onRemovedAddingPlace: (
    uniqueKey: string,
    initPlacesList: ILocationInfoOB
  ) => void
  onAddPlace: (
    initSuggestion: google.maps.places.AutocompletePrediction,
    initZipCode: ZipCode,
    initLat: number,
    initLng: number
  ) => void
  isWaitingResponse: boolean
}

export const OBWillingToRelocateUI = (props: Props) => {
  const {
    onClickBack,
    onClickNext,
    willingToRelocate,
    setWillingToRelocate,
    preferredPlacesList,
    setPreferredPlacesList,
    isWaitingResponse,
    onRemovedAddingPlace,
    onAddPlace,
  } = props
  const { t } = useTranslation()

  return (
    <div className="willing-to-relocate">
      <Helmet>
        <title> {EPageTitleOB.willing_to_relocate} </title>
      </Helmet>
      <div className="willing-to-relocate__wrapper ob-container">
        <ProgressBarLinear
          className="willing-to-relocate__progress-bar"
          initData={listBlocksPBLinear}
          currentPage={10}
        />
        <p className="willing-to-relocate__question ob-question">
          {t(
            'willing_to_relocate.Would you move to a new city for the right job?'
          )}
        </p>
        <p className="willing-to-relocate__description ob-description">
          {t(
            'willing_to_relocate.Skillit works with contractors all over the country. Knowing where you’d go (or not) helps us connect you with the right opportunities for you.'
          )}
        </p>

        <RadioButtonsList
          className="willing-to-relocate__radio-btn ob__radio-btn_checked"
          list={[
            { id: 2, value: `${t('willing_to_relocate.Not right now')}` },
            {
              id: 1,
              value: `${t(
                'willing_to_relocate.I would consider the following cities...'
              )}`,
            },
          ]}
          selected={willingToRelocate}
          onChangeBtn={(id: number) => {
            setWillingToRelocate(id)
            setPreferredPlacesList({
              ...preferredPlacesList,
              preferred_locations: [],
            })
          }}
          singleButtonsGrid
        />
        {willingToRelocate === 1 && (
          <OBPlaceAutocomplete
            className="willing-to-relocate__place-autocomplete"
            placeholder={t(
              'willing_to_relocate.Which cities would you consider?'
            )}
            locationOB={preferredPlacesList}
            setLocationOB={setPreferredPlacesList}
            onRemovedAddingPlace={onRemovedAddingPlace}
            onAddPlace={onAddPlace}
            preferredLocationsPage
            showFocus={true}
          />
        )}
      </div>

      <NavigationFooter actionBack={() => onClickBack()}>
        <Button
          onClick={() =>
            onClickNext(preferredPlacesList, setPreferredPlacesList)
          }
          disabled={
            !(
              willingToRelocate === 2 ||
              !!preferredPlacesList.preferred_locations?.length
            ) || isWaitingResponse
          }
        >
          {t('Shared_Header.Next')}
        </Button>
      </NavigationFooter>
    </div>
  )
}
