import {
  IVerifyData,
  IWorkerInfoAdminPage,
} from '../../../../models/AdminWorkes'
import { handleCheckboxProfile } from '../../../../pages/InAppFlow/Worker/ProfileCompletion/handleProfileInputs'
import {
  verifyAttendance,
  verifyMotivation,
  verifyProfessionalism,
} from '../../../../utils/constants'
import { Button, Checkbox } from '../../../atoms'
import { Textarea } from '../../../atoms/textareas'

interface Props {
  verifyWorker: () => void
  worker: IWorkerInfoAdminPage
  verificationData: IVerifyData
  setVerificationData: React.Dispatch<React.SetStateAction<IVerifyData>>
}

export const WorkerVerificationUI = (props: Props) => {
  const { worker, verifyWorker, verificationData, setVerificationData } = props

  return (
    <div className="worker-verified">
      <p
        className="worker-verified__user-name"
        data-testid="worker-verified__user-name"
      >
        {`Verify ${worker.first_name} ${worker.last_name}`}
      </p>

      <div className="worker-verified__content">
        <div className="worker-verified__appraisal-lists">
          <div className="worker-verified__appraisal-list">
            <span className="worker-verified__list-name">
              Attendance & Punctuality
            </span>
            <ul
              className="worker-verified__checkbox-list"
              data-testid="worker-verified__checkbox-attendance-list"
            >
              {verifyAttendance?.map((el, i) => {
                const checked =
                  verificationData.attendance_punctuality_score.includes(el)
                return (
                  <li
                    key={el}
                    className="worker-verified__checkbox-item"
                    data-testid="worker-verified__checkbox-attendance-item"
                  >
                    <Checkbox
                      name={el}
                      idCheckbox={i}
                      checked={checked ? true : undefined}
                      toggleCheckbox={(_, isChecked) => {
                        handleCheckboxProfile(
                          isChecked,
                          setVerificationData,
                          el,
                          'attendance_punctuality_score'
                        )
                      }}
                    />
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="worker-verified__appraisal-list">
            <span className="worker-verified__list-name">Professionalism</span>
            <ul
              className="worker-verified__checkbox-list"
              data-testid="worker-verified__checkbox-professionalism-list"
            >
              {verifyProfessionalism?.map((el, i) => {
                const checked =
                  verificationData.professionalism_score.includes(el)
                return (
                  <li
                    key={el}
                    className="worker-verified__checkbox-item"
                    data-testid="worker-verified__checkbox-professionalism-item"
                  >
                    <Checkbox
                      name={el}
                      idCheckbox={i}
                      checked={checked ? true : undefined}
                      toggleCheckbox={(_, isChecked) => {
                        handleCheckboxProfile(
                          isChecked,
                          setVerificationData,
                          el,
                          'professionalism_score'
                        )
                      }}
                    />
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="worker-verified__appraisal-list">
            <span className="worker-verified__list-name">Motivation</span>
            <ul
              className="worker-verified__checkbox-list"
              data-testid="worker-verified__checkbox-motivation-list"
            >
              {verifyMotivation?.map((el, i) => {
                const checked = verificationData.attitude_score.includes(el)
                return (
                  <li
                    key={el}
                    className="worker-verified__checkbox-item"
                    data-testid="worker-verified__checkbox-motivation-item"
                  >
                    <Checkbox
                      name={el}
                      idCheckbox={i}
                      checked={checked ? true : undefined}
                      toggleCheckbox={(_, isChecked) => {
                        handleCheckboxProfile(
                          isChecked,
                          setVerificationData,
                          el,
                          'attitude_score'
                        )
                      }}
                    />
                  </li>
                )
              })}
            </ul>
          </div>
        </div>

        <Textarea
          className="worker-verified__notes"
          placeholder={`Notes about ${worker.first_name}`}
          onChange={e =>
            setVerificationData({
              ...verificationData,
              notes: e.target.value.trim(),
            })
          }
          maxLength={500}
        />
      </div>

      <Button
        className="worker-verified__btn"
        data-testid="worker-verified__btn"
        onClick={() => verifyWorker()}
      >
        <p
          className="worker-verified__btn-text"
          data-testid="worker-verified__btn-text"
        >
          {`Verify ${worker.first_name} ${worker.last_name}`}
        </p>
      </Button>
    </div>
  )
}
