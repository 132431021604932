import React, { useCallback, useMemo, useState } from 'react'
import './workExperienceBlock.scss'
import {
  ResultWorkExperience,
  PublicInfo,
  AnswerWorkExperience,
} from '../../../../../models/ProfileModel'
import {
  filterEmptyExp,
  handlingErrors,
  changeFormatDates,
  publicWorkExperience,
} from './workExperienceFuncAndVariables'
import { updatePublicInfo } from '../../../../../services/workerApi'
import { toast } from 'react-toastify'
import {
  ProfileAnalyticsName,
  WorkerQueries,
} from '../../../../../utils/constants'
import {
  getEndDate,
  getPreparedEndDate,
  getStartDate,
  onTrackingActions,
} from '../../../../../utils/scripts'
import { useMutation, useQueryClient } from 'react-query'
import { QueryState } from 'react-query/types/core/query'
import { WorkExperienceBlockUI } from './WorkExperienceBlockUI'
import moment from 'moment'

interface Props extends React.HTMLAttributes<HTMLElement> {
  publicWorkExperience?: ResultWorkExperience[] | null
  publicInfo: PublicInfo
}

export const WorkExperienceBlock = (props: Props) => {
  const { publicInfo } = props
  const queryClient = useQueryClient()
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [savingLoader, setSavingLoader] = useState(false)
  const correctPublicWorkExp = useMemo(() => {
    return changeFormatDates(publicInfo.work_experience)
  }, [publicInfo.work_experience])
  const [workExperience, setWorkExperience] = useState<ResultWorkExperience[]>(
    publicWorkExperience(correctPublicWorkExp)
  )

  const [isConfirmationModal, setIsConfirmationModal] = useState(false)
  const [isChanged, setIsChanged] = useState(false)
  const filteredWorkExperience = useMemo(
    () =>
      workExperience.filter(job =>
        Object.values(job.questions_work_experience).some(value => !!value)
      ),
    [workExperience]
  )

  const publicInfoQuery: QueryState<PublicInfo, undefined> | undefined =
    queryClient.getQueryState(WorkerQueries.publicInfo)

  const workExpMutation = useMutation(
    (data: { work_experience: AnswerWorkExperience[] }) =>
      updatePublicInfo(data),
    {
      onError: err => {
        setSavingLoader(false)
        if (err instanceof Error) toast.error(err.message)
      },
    }
  )

  const handleSubmit = useCallback(
    (
      e: React.FormEvent<HTMLFormElement>,
      modalWorkExp: ResultWorkExperience[]
    ) => {
      e.preventDefault()
      setIsConfirmationModal(false)

      const isNotLocationAtJob = modalWorkExp.some(
        work => !work.questions_work_experience.location
      )
      const errorsList = handlingErrors(modalWorkExp)

      if (!isChanged && !isNotLocationAtJob) {
        setIsModalVisible(false)
        return
      }

      if (errorsList.length) {
        return errorsList.forEach(err =>
          toast.error(err, {
            toastId: 'work_experience',
          })
        )
      }

      const preparedModalWorkExp = filterEmptyExp(modalWorkExp).map(el => {
        const startDateFormatted = moment(
          getStartDate(
            el.questions_work_experience.start_date,
            el.questions_work_experience.end_date,
            !!el.questions_work_experience.is_current
          )
        ).format('yyyy-MM-DD')

        const endDateFormatted = moment(
          getEndDate(
            el.questions_work_experience.start_date,
            el.questions_work_experience.end_date,
            !!el.questions_work_experience.is_current
          )
        ).format('yyyy-MM-DD')

        return {
          ...el,
          questions_work_experience: {
            ...el.questions_work_experience,
            start_date: startDateFormatted,
            end_date: getPreparedEndDate(
              endDateFormatted,
              !!el.questions_work_experience.is_current
            ),
          },
        }
      })

      const preparedWorkExpForRender = filterEmptyExp(modalWorkExp).map(el => {
        const startDateFormatted = moment(
          getStartDate(
            el.questions_work_experience.start_date,
            el.questions_work_experience.end_date,
            !!el.questions_work_experience.is_current
          )
        ).format('yyyy')

        return {
          ...el,
          questions_work_experience: {
            ...el.questions_work_experience,
            start_date: startDateFormatted,
          },
        }
      })

      setSavingLoader(true)

      const preparedNewWorkExp = filterEmptyExp(preparedModalWorkExp).map(
        el => ({
          ...el.questions_work_experience,
        })
      )

      const newWorkExp = {
        work_experience: preparedNewWorkExp,
      }

      const updatedPublicInfo = { ...publicInfoQuery?.data, ...newWorkExp }

      workExpMutation.mutate(newWorkExp, {
        onSuccess: () => {
          queryClient.setQueryData(WorkerQueries.publicInfo, updatedPublicInfo)
          setWorkExperience(preparedWorkExpForRender)
          onTrackingActions(ProfileAnalyticsName.profile_edited)
          setIsModalVisible(false)
          setIsChanged(false)
          setSavingLoader(false)
        },
      })
    },
    [isChanged, publicInfoQuery?.data, queryClient, workExpMutation]
  )

  return (
    <WorkExperienceBlockUI
      {...props}
      savingLoader={savingLoader}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      isChanged={isChanged}
      setIsChanged={setIsChanged}
      isConfirmationModal={isConfirmationModal}
      setIsConfirmationModal={setIsConfirmationModal}
      publicInfo={publicInfo}
      handleSubmit={handleSubmit}
      workExperience={workExperience}
      filteredWorkExperience={filteredWorkExperience}
    />
  )
}
