import React from 'react'
import { Link } from 'react-router-dom'
import { EFields, WorkerInInstantSearch } from '../../../../../models'
import { v4 as uuidv4 } from 'uuid'
import * as route from '../../../../../services/route'
import './searchListWorkers.scss'
import { WorkerFromSearchList } from '../WorkerFromSearchList'
import {
  useAppDispatch,
  setIsNavSearchHover,
  setIsTopFilterVisible,
} from '../../../../../redux'
import zipcodes from 'zipcodes'

interface Props extends React.HTMLAttributes<HTMLElement> {
  instantSearchList: WorkerInInstantSearch
  value: string
  setValue: React.Dispatch<React.SetStateAction<string | undefined>>
  onSelectValue: (value: string) => void
  iType: EFields
  setActiveField: React.Dispatch<React.SetStateAction<EFields | undefined>>
}

export const SearchListWorkers = (props: Props) => {
  const dispatch = useAppDispatch()
  return (
    <div
      className={`search__list-wrapper 
      ${props.className ?? ''}`}
    >
      {props.instantSearchList && (
        <div className="search__list-workers">
          {props.iType === EFields.role &&
            props.instantSearchList?.employees?.map(worker => {
              return (
                <Link
                  className="search__list-workers-worker-details"
                  key={worker.id}
                  to={`${route.workerDetails}/${worker.unique_url}?instant_search`}
                  onClick={() => {
                    dispatch(setIsNavSearchHover(false))
                    dispatch(setIsTopFilterVisible(false))
                    props.setValue('')
                    props.setActiveField(undefined)
                  }}
                >
                  <WorkerFromSearchList
                    value={props.value}
                    photo={worker.profile_picture}
                    name={worker.full_name}
                    full_role={worker.full_role ?? undefined}
                  />
                </Link>
              )
            })}

          {props.iType === EFields.role &&
            props.instantSearchList?.roles?.map(role => {
              return (
                <div
                  key={uuidv4()}
                  onClick={() => {
                    props.onSelectValue(role)
                    props.setActiveField(undefined)
                  }}
                >
                  <WorkerFromSearchList
                    key={uuidv4()}
                    value={props.value}
                    name={role}
                    withioutPhoto
                  />
                </div>
              )
            })}

          {props.iType === EFields.location &&
            props.instantSearchList?.location?.map(location => {
              const name = zipcodes.lookup(location.zip_code)?.city
                ? `${zipcodes.lookup(location.zip_code)?.city}`
                : location.city
              return (
                <div
                  key={uuidv4()}
                  onMouseDown={() => props.onSelectValue(location.zip_code)}
                >
                  <WorkerFromSearchList
                    key={uuidv4()}
                    value={props.value}
                    name={name}
                    secondName={zipcodes.lookup(location.zip_code)?.state}
                    withioutPhoto
                  />
                </div>
              )
            })}

          {props.iType === EFields.skills &&
            props.instantSearchList?.skills?.map(skill => {
              return (
                <div
                  key={uuidv4()}
                  onMouseDown={() => props.onSelectValue(skill.choice)}
                >
                  <WorkerFromSearchList
                    key={uuidv4()}
                    value={props.value}
                    name={skill.choice}
                    withioutPhoto
                  />
                </div>
              )
            })}
        </div>
      )}
    </div>
  )
}
