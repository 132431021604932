import React, { useMemo } from 'react'
import { JsxElement } from 'typescript'
import interviewerPhoto from '../../../../assets/img/worker/onboarding/interviewer.png'
import { InterviewerModel } from '../../../../models'
import './successPopup.scss'

interface Props extends React.HTMLAttributes<HTMLElement> {
  date?: string
  subDescription?: JsxElement | string
  newPhoto?: boolean
  interviewer?: InterviewerModel | null
}

export const SuccessPopup = (props: Props) => {
  const { date, subDescription, newPhoto, interviewer } = props

  const imgSrc = useMemo(
    () => (newPhoto ? `${interviewer?.profile_image}` : `${interviewerPhoto}`),
    [interviewer?.profile_image, newPhoto]
  )

  return (
    <div className="call-now">
      <h5 className="call-now__title">You’re All Set!</h5>

      <div className="call-now__content">
        <div className="call-now__date">{date ?? ''}</div>
        <div className="call-now__with">with</div>
        <div className="call-now__interviewer">
          {interviewer?.profile_image ? (
            <img
              src={imgSrc}
              alt="interviewer"
              className="call-now__interviewer-photo"
            />
          ) : (
            <div
              className="call-now__interviewer-letter"
              data-testid="call-now__interviewer-letter"
            >
              {interviewer?.name.length ? interviewer.name[0] : ''}
            </div>
          )}
          <div className="call-now__interviewer-name">{interviewer?.name}</div>
          <div className="call-now__interviewer-position">
            Skillit Career Guide
          </div>
          <div className="call-now__interviewer-phone">(219) SKILLIT</div>
          <div className="call-now__interviewer-email">
            {interviewer?.email}
          </div>
        </div>
      </div>

      <div className="call-now__sub-description">{subDescription ?? ''}</div>
    </div>
  )
}
