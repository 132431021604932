import React from 'react'
import './aboutMeModal.scss'
import exampleSmithPhoto from '../../../../../../assets/img/worker/example_smith.png'
import exampleKioPhoto from '../../../../../../assets/img/worker/profile/example_kio.png'

interface Props extends React.HTMLAttributes<HTMLElement> {}

export const AboutMeModal = (props: Props) => {
  return (
    <div className={`about-modal ${props.className ?? ''}`}>
      <div className="about-modal__wrapper-examples">
        <p className="about-modal__title">Example About Me</p>
        <div className="about-modal__example">
          <img
            className="about-modal__example-icon"
            src={exampleKioPhoto}
            alt="example_kio_photo"
          />
          <p className="about-modal__example-text">
            Hi. I’m Kio. I am excited to work for a company that values a
            diverse workforce and look forward to sharing, learning, and working
            with the best firms. I started as a woodworker but I really wanted
            to build homes for people. So I switched to being a carpenter
            working mostly on high-end residential projects doing everything
            from demo to finish. Although I enjoy all the building phases I
            found that finish carpentry is what I love the most. I live in
            downtown Nashville and spend my spare time eating my way through the
            city. I also enjoy going rock climbing and spending time outside.
          </p>
        </div>

        <div className="about-modal__example">
          <img
            className="about-modal__example-icon"
            src={exampleSmithPhoto}
            alt="example_smith_photo"
          />
          <p className="about-modal__example-text">
            Hello, my name is Anthony Smith. I live in Brooklyn, New York. I
            enjoy helping peope that are in need of it, and I truly enjoy
            working with and meeting people from different backgrounds. I did my
            last four years working for myself, and saw the challenges of being
            an independent contractor. I gave it 110% everyday, and am now
            looking forward to giving a new firm that dedication and 110%
            everyday.
          </p>
        </div>
      </div>
    </div>
  )
}
