import React, { useCallback, useMemo } from 'react'
import { OBTravelToWorkUI } from './OBTravelToWorkUI'
import * as route from '../../../services/route'
import { useHistory } from 'react-router-dom'
import { setWorkerInfo, useAppDispatch } from '../../../redux'
import {
  usePageNumUpdateOB,
  useTypedSelector,
  useStateWithDep,
} from '../../../utils/hooks'
import { toNumber } from 'lodash'
import { useMutation, useQueryClient } from 'react-query'
import {
  defaultTravelDistance,
  EWorkerSStoreKeys,
  OBAnalyticsName,
  WorkerQueries,
} from '../../../utils/constants'
import { updateBasicInfo } from '../../../services/workerApi'
import { BasicInfoModel, TravelDistance, IWorkerInfoOB } from '../../../models'
import { toast } from 'react-toastify'
import { getZoomLevel } from './getZoomLevel'
import {
  getUserInfoFromStorage,
  onTrackingActions,
} from '../../../utils/scripts'
import { getLastPageState } from '../../../utils/hooks/obHooks/usePageNumUpdateOB'

export const OBTravelToWork = () => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const userInfo = useTypedSelector(s => s.obInfo.workerInfo)
  const lastPage = userInfo.last_page < 12 ? 12 : userInfo.last_page
  const lastPageState = useMemo(() => getLastPageState(lastPage), [lastPage])
  const pageStateOBMutation = usePageNumUpdateOB()
  const userInfoFromStorage = useMemo(() => getUserInfoFromStorage(), [])

  const distanceTraveData = useMemo(() => {
    return {
      travel_distance:
        userInfoFromStorage?.user_data.travel_distance ?? defaultTravelDistance,
    }
  }, [userInfoFromStorage?.user_data.travel_distance])

  const workerBasicInfoMutation = useMutation(
    (data: TravelDistance) => updateBasicInfo(data),
    {
      onSuccess: (newData: BasicInfoModel) => {
        queryClient.setQueryData(WorkerQueries.basicInfo, newData)
        pageStateOBMutation.mutate(lastPageState, {
          onSuccess() {
            history.push(route.OBPath.onboardingWillingToRelocate)
          },
        })
      },

      onError: err => {
        if (err instanceof Error) {
          toast.error(err.message)
        }
      },
    }
  )

  const [distanceValue, setDistanceValue] =
    useStateWithDep<TravelDistance>(distanceTraveData)

  const onRangeProcessingTravelDistance = useCallback(
    (initValue: number) => {
      setDistanceValue(prevDistance => {
        return {
          ...prevDistance,
          travel_distance: initValue,
        }
      })
    },
    [setDistanceValue]
  )

  const getNewData = useCallback(
    (data: IWorkerInfoOB) => ({
      ...data,
      last_page: lastPage,
      last_location: lastPageState.last_location,
      user_data: {
        ...data.user_data,
        travel_distance: distanceValue.travel_distance,
      },
    }),
    [distanceValue.travel_distance, lastPage, lastPageState.last_location]
  )

  const saveSessionStorage = useCallback(() => {
    sessionStorage.setItem(
      EWorkerSStoreKeys.obData,
      JSON.stringify(getNewData(userInfoFromStorage ?? userInfo))
    )
  }, [getNewData, userInfo, userInfoFromStorage])

  const onClickBack = useCallback(() => {
    saveSessionStorage()
    history.push(route.OBPath.onboardingAdditionalInfo)
  }, [history, saveSessionStorage])

  const onClickNext = useCallback(
    async (
      initDistanceValue: TravelDistance,
      initSetDistanceValue: React.Dispatch<React.SetStateAction<TravelDistance>>
    ) => {
      if (
        userInfo.user_data?.travel_distance ===
        initDistanceValue.travel_distance
      ) {
        history.push(route.OBPath.onboardingWillingToRelocate)
      } else {
        await onTrackingActions(OBAnalyticsName.travel_distance_submitted)
        dispatch(
          setWorkerInfo({
            ...userInfo,
            last_page: lastPage,
            last_location: lastPageState.last_location,
            user_data: {
              ...userInfo.user_data,
              travel_distance: initDistanceValue.travel_distance,
            },
          })
        )
        saveSessionStorage()
        workerBasicInfoMutation.mutate(
          { ...initDistanceValue },
          {
            onSuccess: () => {
              initSetDistanceValue(initDistanceValue)
            },
          }
        )
      }
    },
    [
      dispatch,
      history,
      lastPage,
      lastPageState.last_location,
      userInfo,
      workerBasicInfoMutation,
      saveSessionStorage,
    ]
  )

  return (
    <OBTravelToWorkUI
      homeLocationLng={toNumber(
        userInfoFromStorage?.user_data?.home_location?.longitude ??
          userInfo?.user_data?.home_location?.longitude
      )}
      homeLocationLat={toNumber(
        userInfoFromStorage?.user_data?.home_location?.latitude ??
          userInfo?.user_data?.home_location?.latitude
      )}
      distanceValue={distanceValue}
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      zoomLevel={getZoomLevel(distanceValue.travel_distance)}
      onRangeProcessingTravelDistance={onRangeProcessingTravelDistance}
      setDistanceValue={setDistanceValue}
    />
  )
}
