import React from 'react'
import { useParams } from 'react-router'
import { BlogSelection } from '../../../../components/organism/Worker/BlogList/BlogSelection'
import { blogData } from '../BlogPage/blog-data'
import { Helmet } from 'react-helmet'
import { TitleBlogModel } from '../../../../models/TitleBlogModel'

import './blogTemplate.scss'

interface Props {
  id: number
  blog?: TitleBlogModel
  hideBlog?: () => void
  setBlog?: (id: number) => void
  fetchBlogList?: () => void
}

export const BlogTemplatePage = () => {
  const { id } = useParams<{ id: string }>()
  const blog = blogData.find(blog => blog.id === +id)
  return <BlogTemplate blog={blog} id={+id} />
}

export const BlogTemplate = (props: Props) => {
  const author = props.blog?.author
  let date = props.blog?.date
  if (!date) {
    date = new Date()
  }
  const blogDate = date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
  })

  return (
    <div className="blog-template container">
      <Helmet>
        <title>Skillit - Blog</title>
      </Helmet>
      <p className="blog-template__date">
        {blogDate} - Written By {author}
      </p>
      <article className="blog-template__content">
        <BlogSelection id={props.id} />
      </article>
      <p className="blog-template__author">{author}</p>
    </div>
  )
}
