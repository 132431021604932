import React from 'react'
import { Helmet } from 'react-helmet'
import { Button } from '../../../components/atoms'
import {
  ModalBox,
  NavigationFooter,
  ProgressBarLinear,
} from '../../../components/molecules'
import { BestJobsOB } from '../../../components/organism'
import { AddJobForm } from '../../../components/organism/Worker/OBcomponents/BestJobsOB/AddJobForm'
import {
  EPageTitleOB,
  listBlocksPBLinear,
} from '../../../utils/constants/obConstants'
import { useTranslation } from 'react-i18next'
import './ob-last-jobs.scss'
import { TCombinedFormType, TEditIncompleteExp } from './OBLastJobs'
import { TWorkExperience } from '../../../models'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  onClickBack: () => void
  onClickNext: (e: React.MouseEvent<HTMLButtonElement>) => void
  jobEditModal: false | TEditIncompleteExp
  setJobEditModal: React.Dispatch<
    React.SetStateAction<false | TEditIncompleteExp>
  >
  isUploadedResume: boolean
  isWaitingResponse: boolean
  jobs: TWorkExperience[]
  isPromptOpen: boolean
  setIsPromptOpen: React.Dispatch<React.SetStateAction<boolean>>
  onCloseModal: (value: boolean, setIsFormOpen: TCombinedFormType) => void
  isClickCloseBtn: boolean
  setIsClickCloseBtn: React.Dispatch<React.SetStateAction<boolean>>
}

export const OBLastJobsUI = (props: IProps) => {
  const {
    onClickBack,
    onClickNext,
    isUploadedResume,
    isWaitingResponse,
    jobEditModal,
    setJobEditModal,
    jobs,
    setIsClickCloseBtn,
  } = props
  const { t } = useTranslation()

  return (
    <div className="ob-last-jobs ob-container">
      <Helmet>
        <title> {EPageTitleOB.best_jobs} </title>
      </Helmet>

      <ProgressBarLinear
        className="name-page__progress-bar"
        initData={listBlocksPBLinear}
        currentPage={6}
      />

      {jobEditModal && (
        <ModalBox
          className="three-best-jobs__form"
          onCloseModal={() => {
            setIsClickCloseBtn(true)
          }}
          visibleCloseIcon
          bgClickDisabled
        >
          <AddJobForm
            {...props}
            isFormOpen={jobEditModal.index}
            highlightFields={jobEditModal.fields}
            setIsFormOpen={setJobEditModal}
            setIsClickCloseBtn={setIsClickCloseBtn}
          />
        </ModalBox>
      )}

      <div className="ob-last-jobs__content">
        <p className="ob-last-jobs__title ob-question">
          {isUploadedResume
            ? `${t(
                'last_jobs.Nice looking resume! We found the following jobs'
              )}`
            : `${t('last_jobs.What were your three best jobs?')}`}
        </p>
        <p className="ob-last-jobs__description ob-description">
          {isUploadedResume
            ? `${t(
                'last_jobs.Feel free to edit or remove any of the jobs below.'
              )}`
            : `${t(
                'last_jobs.Go ahead - brag a little. Knowing about your best experiences and employers will help us get to know you better.'
              )}`}
        </p>
        <BestJobsOB className="ob-last-jobs__job-list" {...props} />
      </div>

      <NavigationFooter actionBack={onClickBack}>
        <Button
          onClick={onClickNext}
          disabled={isWaitingResponse || !jobs?.length}
        >
          {t('Shared_Header.Next')}
        </Button>
      </NavigationFooter>
    </div>
  )
}
