import React, { useCallback } from 'react'
import { Link } from '../../../../../../models/ProfileModel'
import instagram_icon from '../../../../../../assets/icons/social/instagram.svg'
import linkedin_icon from '../../../../../../assets/icons/social/linkedin.svg'
import youtube_icon from '../../../../../../assets/icons/social/youtube.svg'
import { NamesLinks } from '../LinksBlock'
import { LinksModalUI } from './LinksModalUI'

export enum PlaceHoldersInputsLinks {
  instagram = 'Instagram Handle',
  linkedIn = 'LinkedIn Profile URL',
  youtube = 'Youtube Channel URL',
}

interface Props extends React.HTMLAttributes<HTMLElement> {
  links: Link[]
  setLinks: React.Dispatch<React.SetStateAction<Link[]>>
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  handleSubmit: () => void
  filledLinks: Link[]
  isConfirmationModalVisible: boolean
  setIsConfirmationModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const LinksModal = (props: Props) => {
  const { setLinks } = props

  const onChangeLinkValue = useCallback(
    (value: string, linkName: string) => {
      setLinks(prevLinks => {
        return prevLinks.map(prevLink => {
          if (prevLink.title === linkName) {
            return {
              ...prevLink,
              value,
            }
          } else {
            return prevLink
          }
        })
      })
    },
    [setLinks]
  )

  const addLinkFormIconAndPlaceholderText = useCallback((linkTitle: string) => {
    if (linkTitle === NamesLinks.instagram) {
      return {
        placeholderText: PlaceHoldersInputsLinks.instagram,
        linkIcon: instagram_icon,
      }
    }
    if (linkTitle === NamesLinks.linkedIn) {
      return {
        placeholderText: PlaceHoldersInputsLinks.linkedIn,
        linkIcon: linkedin_icon,
      }
    }
    return {
      placeholderText: PlaceHoldersInputsLinks.youtube,
      linkIcon: youtube_icon,
    }
  }, [])

  return (
    <LinksModalUI
      {...props}
      onChangeLinkValue={onChangeLinkValue}
      addLinkFormIconAndPlaceholderText={addLinkFormIconAndPlaceholderText}
    />
  )
}
