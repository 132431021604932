import { Helmet } from 'react-helmet'
import { RememberMe, SocialLoginType } from '../../../../components/molecules'
import {
  ForgotPasswordModal,
  LoginForm,
  PopupLoader,
} from '../../../../components/organism'
import { LoginType } from '../../../../components/organism/General/LoginForm'
import { EPageTitle } from './SignInPage'
import { BannerVertical } from '../../../../components/atoms/BannerVertical/BannerVertical'
import bannerImage from '../../../../assets/img/worker/passwor-login-banner.png'
import { Link } from 'react-router-dom'
import * as route from '../../../../services/route'

interface Props {
  email: string
  decodeEmail: string
  rememberMe: boolean
  fpModalDisplay: boolean
  isPopupLoaderShowed: boolean
  onCloseModal: () => void
  onClickLoginType: (type: SocialLoginType) => void
  setRememberMe: React.Dispatch<React.SetStateAction<boolean>>
  setFpModalDisplay: React.Dispatch<React.SetStateAction<boolean>>
  onConfirmEmailLogin: (email: string, password: string) => Promise<void>
}

export const SignInPageUI = (props: Props) => {
  const {
    email,
    rememberMe,
    decodeEmail,
    onCloseModal,
    setRememberMe,
    fpModalDisplay,
    onClickLoginType,
    setFpModalDisplay,
    isPopupLoaderShowed,
    onConfirmEmailLogin,
  } = props

  return (
    <>
      <Helmet>
        <title>{EPageTitle.sign_in}</title>
      </Helmet>
      {isPopupLoaderShowed && <PopupLoader />}
      <BannerVertical bannerImage={bannerImage}>
        <div className="login-wrapper">
          <div className="email-login__title-block">
            <h1 className="email-login__title-block--title">Welcome!</h1>
            <p className="email-login__title-block--description">
              Enter your email address and password to log in
            </p>
          </div>
          <LoginForm
            defaultEmail={email ? decodeEmail : ''}
            onClickLoginType={onClickLoginType}
            onConfirmEmailLogin={onConfirmEmailLogin}
            type={LoginType.SignIn}
          >
            <RememberMe
              showModal={setFpModalDisplay}
              rememberMe={rememberMe}
              onToggleRememberMe={() => setRememberMe(!rememberMe)}
              isEmailLogin={false}
            />
            {fpModalDisplay && (
              <ForgotPasswordModal onCloseModal={onCloseModal} />
            )}
          </LoginForm>
          <Link className="link" to={route.signInEmailLogin}>
            <p className="email-login-page__signin-link-wrapper">
              Or log in with a link sent to your email or phone number instead
            </p>
          </Link>
        </div>
      </BannerVertical>
    </>
  )
}
