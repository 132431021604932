import React from 'react'
import './banner.scss'

interface Props extends React.HTMLAttributes<HTMLElement> {}

export const Banner = (props: Props) => {
  return (
    <div className={`banner ${props.className}`} data-testid="banner">
      <div className="container">{props.children ?? ''}</div>
    </div>
  )
}
