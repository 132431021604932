import React, { forwardRef } from 'react'
import './obPlaceAutocomplete.scss'
import { Status } from 'use-places-autocomplete'
import circleCloseIcon from './../../../../assets/icons/close/circle_close_icon.svg'
import { ILocationInfoOB } from '../../../../models'

interface Props extends React.HTMLAttributes<HTMLElement> {
  value: string
  handleInput: (e: React.ChangeEvent<HTMLInputElement>) => void
  renderSuggestions: () => JSX.Element[]
  status: Status
  placeholder: string
  placesList?: ILocationInfoOB
  onRemovedAddingPlace?: (
    uniqueKey: string,
    initPlacesList: ILocationInfoOB
  ) => void
  preferredLocationsPage?: true
}

export const OBPlaceAutocompleteUI = forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const {
      value,
      handleInput,
      renderSuggestions,
      status,
      placeholder,
      placesList,
      onRemovedAddingPlace,
      preferredLocationsPage,
    } = props

    return (
      <div className={`ob-place-autocomplete ${props.className}`}>
        <input
          className={`ob-place-autocomplete__input ${
            value ? 'filled_input' : ''
          }`}
          value={value}
          onChange={handleInput}
          placeholder={placeholder}
          ref={ref}
        />
        {status === 'OK' && (
          <ul className="ob-place-autocomplete__suggestions-list">
            {renderSuggestions()}
          </ul>
        )}
        {preferredLocationsPage &&
          placesList?.preferred_locations &&
          !!placesList?.preferred_locations?.length &&
          placesList?.preferred_locations.map((place, index) => {
            const key = `${index}${place.city}${place.state}`
            return (
              <div className="ob-place-autocomplete__adding-place" key={key}>
                <p className="ob-place-autocomplete__adding-place-text">
                  {place.city
                    ? `${place.city}, ${place.state}`
                    : `${place.state}`}
                </p>
                <img
                  src={circleCloseIcon}
                  alt="close-icon"
                  className="ob-place-autocomplete__adding-place-close-icon"
                  onClick={() =>
                    onRemovedAddingPlace &&
                    onRemovedAddingPlace(key, placesList)
                  }
                />
              </div>
            )
          })}
      </div>
    )
  }
)
