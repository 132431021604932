import React from 'react'
import './listSection.scss'

interface Props extends React.HTMLAttributes<HTMLElement> {
  numberOfItems: number
  itemAtIndex: (index: number) => React.ReactElement
}

export function ListSection(props: Props) {
  const { numberOfItems, itemAtIndex } = props
  return (
    <div className={`list-section container ${props.className ?? ''}`}>
      {Array(numberOfItems)
        .fill('')
        .map((_, index) => itemAtIndex(index))}
    </div>
  )
}
