import imgEye from '../../../../../../assets/icons/active-icons/eye.svg'
import { Config } from '../../../../../../components/organism/General/UploadFiles'
import { LoadingContent } from '../../../../../../components/molecules'
import {
  workWithTypeData,
  TypeResume,
  UploadFiles,
} from '../../../../../../components/organism'
import { OshaInfo } from '../../../../../../models'
import './oshaInfo.scss'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  isLoading: boolean
  oshaInfoMutate: (formData: FormData, config?: Config) => Promise<unknown>
  oshaInfo?: OshaInfo
}

export const OshaInfoUI = (props: IProps) => {
  const { className, isLoading, oshaInfo, oshaInfoMutate } = props

  return (
    <div className={`osha ${className ?? ''}`} data-testid="osha">
      <LoadingContent isLoading={isLoading}>
        <div className={`osha__card ${className ?? ''}`}>
          <p className="osha__card-title">OSHA Card</p>
          <div className="osha__card-visible-content">
            <img src={imgEye} alt="eye" />
            <p className="osha__card-visible-content-description">
              Visible only to you and Skillit until an offer is made. This is
              only used for employment verification.
            </p>
          </div>
          <UploadFiles
            className={`osha__card ${className ?? ''}`}
            accept={workWithTypeData.resumeSupportTypes().toString()}
            updatedFile={oshaInfo?.osha_card ?? undefined}
            description=""
            buttonName="Upload Card"
            typeData={TypeResume}
            uploadButtonText="Upload New Card"
            modalTitleTxt="Card"
            modalUploadTxt="Card"
            modalRemoveTxt="Current Card"
            formDataName="osha_card"
            updateAction={oshaInfoMutate}
          />
        </div>
      </LoadingContent>
    </div>
  )
}
