import React from 'react'
import { Button } from '../../../../../../../components/atoms'
import {
  ConfirmationUpdateModal,
  ModalBox,
  RadioBtnElement,
  RadioButtonsList,
} from '../../../../../../../components/molecules'
import { DrivingInfo } from '../../../../../../../models'
import {
  getSelectedYesNoAnswerId,
  handleRadioBtnProfile,
} from '../../../handleProfileInputs'
import './drivingModal.scss'

interface Props {
  handleSubmit: () => void
  drivingInfoForm: DrivingInfo
  setDrivingInfoForm: React.Dispatch<React.SetStateAction<DrivingInfo>>
  isConfirmationModalVisible: boolean
  setIsConfirmationModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  drivingInfoState: DrivingInfo
  isDrivingInfoChanged: boolean
  vehicleData: RadioBtnElement[]
  licenseData: RadioBtnElement[]
}

export const DrivingModalUI = (props: Props) => {
  const {
    handleSubmit,
    drivingInfoForm,
    setDrivingInfoForm,
    isConfirmationModalVisible,
    setIsConfirmationModalVisible,
    setIsModalVisible,
    drivingInfoState,
    isDrivingInfoChanged,
    vehicleData,
    licenseData,
  } = props

  return (
    <div className="driving-modal" data-testid="driving-modal">
      {isConfirmationModalVisible && (
        <ModalBox className="upload-file__modal-box">
          <ConfirmationUpdateModal
            title="Discard Changes"
            uploadText="Save Changes"
            removeText="Discard Changes"
            updateData={() => {
              handleSubmit()
              setIsConfirmationModalVisible(false)
            }}
            remove={() => {
              setDrivingInfoForm(drivingInfoState)
              setIsConfirmationModalVisible(false)
              setIsModalVisible(false)
            }}
          />
        </ModalBox>
      )}
      <h3 className="driving-modal__modal-title">Edit Driving Status</h3>
      <form
        className="driving-modal__form"
        onSubmit={e => {
          e.preventDefault()
          isDrivingInfoChanged ? handleSubmit() : setIsModalVisible(false)
        }}
      >
        <p className="driving-modal__form-question-title">
          Do you own your own vehicle?
        </p>
        <RadioButtonsList
          className="driving-modal__radio-btn"
          list={vehicleData}
          selected={getSelectedYesNoAnswerId(
            vehicleData,
            drivingInfoForm.vehicle
          )}
          onChangeBtn={(id: number) =>
            handleRadioBtnProfile(
              id,
              vehicleData,
              setDrivingInfoForm,
              'vehicle',
              true
            )
          }
        />

        <p className="driving-modal__form-question-title">
          Do you have a valid driver’s license?
        </p>
        <RadioButtonsList
          className="driving-modal__radio-btn"
          list={licenseData}
          selected={getSelectedYesNoAnswerId(
            licenseData,
            drivingInfoForm.valid_drivers_license
          )}
          onChangeBtn={(id: number) =>
            handleRadioBtnProfile(
              id,
              licenseData,
              setDrivingInfoForm,
              'valid_drivers_license',
              true
            )
          }
        />

        <Button type="submit">SAVE CHANGES</Button>
      </form>
    </div>
  )
}
