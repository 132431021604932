export interface ChallengeModel {
  id: number
  key: string
  title: string
  link: string
  duration: string | null
  level: ChallengeLevel | null
  type: string
  description: string
  visible: boolean
  percentage: string
  image: string | null
}

export class ChallengeModelFormatter {
  static formattedDuration(model: ChallengeModel) {
    if (model.duration === null) {
      return ''
    }
    if (+model.duration === 1) {
      return '1 min'
    }
    return `${model.duration} mins`
  }

  static formattedLevel(model: ChallengeModel) {
    if (model.level === null) {
      return ''
    }
    return model.level.toString()
  }
}

export enum ChallengeLevel {
  Intermediate = 'Intermediate',
}
