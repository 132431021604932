import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMutation } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  analyticsPageTitleOB,
  EWorkerSStoreKeys,
  initialWorkerInfoOB,
  OBAnalyticsName,
} from '../../../utils/constants'
import * as route from '../../../services/route'
import { startAuthenticatedOB } from '../../../services/workerApi'
import {
  useAuthUserByUrl,
  useGetWorkerInfo,
  useStateWithDep,
  useTypedSelector,
} from '../../../utils/hooks'
import {
  getUserInfoFromStorage,
  onEventPage,
  onTrackingActions,
} from '../../../utils/scripts'
import './obName.scss'
import { OBNameUI } from './OBNameUI'
import { LoadingScreen } from '../../InAppFlow'

export const OBName = () => {
  const inputNameRef = useRef<HTMLInputElement>(null)
  const history = useHistory()
  const userObInfo = useTypedSelector(s => s.obInfo.workerInfo)
  const isAuth = useTypedSelector(s => s.auth.isAuth)
  const location = useLocation()
  const userInfoFromStorage = useMemo(() => getUserInfoFromStorage(), [])

  const {
    data: userInfoQuery,
    refetch: refetchWorkerInfo,
    obModelErrorState,
    failureCount,
  } = useGetWorkerInfo()
  const [isOBModelEmpty, setIsOBModelEmpty] = useState(true)

  const startAuthenticatedMutation = useMutation(_ => startAuthenticatedOB(), {
    onError: err => {
      if (err instanceof Error) {
        toast.error(err.message)
      }
    },
  })

  const [tokenFromUrl, setTokenFromUrl] = useState<string | null>(null)
  const [isLoadingScreenShowed, setIsLoadingScreenShowed] =
    useState<boolean>(false)

  const [firstName, setFirstName] = useStateWithDep(
    userInfoFromStorage?.user_data.first_name ??
      userObInfo.user_data.first_name ??
      ''
  )
  const [lastName, setLastName] = useStateWithDep(
    userInfoFromStorage?.user_data.last_name ??
      userObInfo.user_data.last_name ??
      ''
  )

  const authInvitedUser = useAuthUserByUrl(setIsLoadingScreenShowed)

  const onClickNext = useCallback(async () => {
    await onTrackingActions(OBAnalyticsName.name_submitted)
    const userInfoObj =
      userInfoFromStorage ?? userInfoQuery ?? initialWorkerInfoOB
    sessionStorage.setItem(
      EWorkerSStoreKeys.obData,
      JSON.stringify({
        ...userInfoObj,
        user_data: {
          ...userInfoObj.user_data,
          first_name: firstName.trim(),
          last_name: lastName.trim(),
        },
      })
    )
    history.push(route.OBPath.onboardingEmail)
  }, [history, firstName, lastName, userInfoFromStorage, userInfoQuery])

  useEffect(() => {
    !userObInfo.is_completed &&
      onEventPage(
        location.pathname,
        analyticsPageTitleOB[route.OBPath.onboardingName]
      )
  }, [location.pathname, userObInfo.is_completed])

  useEffect(() => {
    //TODO: failureCount only fires after the second 404 error.
    if (obModelErrorState || failureCount > 0) {
      isOBModelEmpty &&
        startAuthenticatedMutation.mutate(undefined, {
          onSuccess: () => {
            refetchWorkerInfo()
          },
          onError: err => {
            if (err instanceof Error) {
              toast.error(err.message)
            }
          },
        })
      setIsOBModelEmpty(false)
    }
  }, [
    obModelErrorState,
    startAuthenticatedMutation,
    isOBModelEmpty,
    refetchWorkerInfo,
    failureCount,
  ])

  useEffect(() => {
    const getTokenFromUrl = new URLSearchParams(location.search).get(
      'invite-token'
    )
    if (tokenFromUrl !== getTokenFromUrl) {
      setTokenFromUrl(getTokenFromUrl)
      getTokenFromUrl && authInvitedUser(getTokenFromUrl)
    }
  }, [authInvitedUser, location.search, tokenFromUrl, isAuth])

  useEffect(() => inputNameRef?.current?.focus(), [])

  if (isLoadingScreenShowed) return <LoadingScreen />

  return (
    <OBNameUI
      firstName={firstName}
      setFirstName={setFirstName}
      lastName={lastName}
      setLastName={setLastName}
      onClickNext={onClickNext}
      ref={inputNameRef}
    />
  )
}
