import moment from 'moment'
import React from 'react'
import Calendar from 'react-calendar'
import { ZipCode } from 'use-places-autocomplete'
import { Input, Button, Checkbox } from '../../../../../../components/atoms'
import { SliderWithArrows } from '../../../../../../components/atoms/sliders/SliderWithArrows'
import {
  ModalBox,
  ConfirmationUpdateModal,
} from '../../../../../../components/molecules'
import { OBPlaceAutocomplete } from '../../../../../../components/molecules/Worker/OBPlaceAutocomplete/OBPlaceAutocomplete'
import { ILocationInfoOB } from '../../../../../../models'
import { defaultTravelDistance } from '../../../../../../utils/constants'
import { formatPhoneNumber } from '../../../../../../utils/scripts'
import {
  handleCheckboxProfile,
  handleInputProfile,
} from '../../handleProfileInputs'
import { IBasicInfoForm } from './BasicInfoForm'
import './basicInfoForm.scss'

interface Props extends IBasicInfoForm {
  isFilledPlace: boolean
  setIsFilledPlace: React.Dispatch<React.SetStateAction<boolean>>
  locationInfo: ILocationInfoOB
  onRemovedAddingPlace: (
    uniqueKey: string,
    initPlacesList: ILocationInfoOB
  ) => void
  onAddPlace: (
    initSuggestion: google.maps.places.AutocompletePrediction,
    initZipCode: ZipCode,
    initLat: number,
    initLng: number
  ) => void
  setLocationInfo: React.Dispatch<React.SetStateAction<ILocationInfoOB>>
  onSelectDate: (initDate: Date) => void
}

export const BasicInfoFormUI = (props: Props) => {
  const {
    handleSubmit,
    isFilledPlace,
    setIsFilledPlace,
    locationInfo,
    onAddPlace,
    onRemovedAddingPlace,
    setLocationInfo,
    onSelectDate,
    setIsConfirmationModalVisible,
    stateInfo,
    setStateForm,
    defaultLocationInfo,
    setIsModalVisible,
    stateForm,
    communicationData,
    minTravelDistance,
  } = props

  return (
    <div className="basic-info-form">
      {props.isConfirmationModalVisible && (
        <ModalBox className="upload-file__modal-box">
          <ConfirmationUpdateModal
            title="Discard Changes"
            uploadText="Save Changes"
            removeText="Discard Changes"
            updateData={e => {
              handleSubmit(e, locationInfo)
              setIsConfirmationModalVisible(false)
            }}
            remove={() => {
              if (stateInfo)
                setStateForm({
                  ...stateInfo,
                  travel_distance: stateInfo.travel_distance
                    ? stateInfo.travel_distance
                    : defaultTravelDistance,
                })
              setLocationInfo(defaultLocationInfo)
              setIsConfirmationModalVisible(false)
              setIsModalVisible(false)
            }}
          />
        </ModalBox>
      )}
      <h3 className="basic-info-form__modal-name">Basic Info</h3>
      <form method="put" onSubmit={e => handleSubmit(e, locationInfo)}>
        <label className="basic-info-form__label" htmlFor="username">
          Email
        </label>
        <Input name="username" readOnly value={stateForm.username} />

        <label className="basic-info-form__label" htmlFor="phone">
          Phone
        </label>
        <Input
          name="phone"
          value={formatPhoneNumber(stateForm.phone?.toString(), false, true)}
          onChange={e =>
            handleInputProfile('phone', e.target.value, setStateForm)
          }
          placeholder="(534) 670-9866"
        />

        <label
          className="basic-info-form__label"
          htmlFor="communication_preference"
        >
          Communication Preference
        </label>
        {communicationData.map(item => {
          const checked = stateForm.communication_preference.find(
            el => el === item
          )
            ? true
            : undefined
          return (
            <Checkbox
              className="basic-info-form__checkbox"
              key={item}
              name={item}
              idCheckbox={communicationData.indexOf(item)}
              checked={checked}
              toggleCheckbox={(_, isChecked: boolean) =>
                handleCheckboxProfile(
                  isChecked,
                  setStateForm,
                  item,
                  'communication_preference'
                )
              }
            />
          )
        })}
        <div className="basic-info-form__relocation-place">
          <label className="basic-info-form__label basic-info-form__label-relocation-title">
            Your location
          </label>
          <OBPlaceAutocomplete
            className="basic-info-form__input-location"
            placeholder="Enter your location"
            locationOB={locationInfo}
            setLocationOB={setLocationInfo}
            homeLocationPage
          />
        </div>

        <div className="basic-info-form__relocation-place">
          <label className="basic-info-form__label basic-info-form__label-relocation-title">
            Planning to move to location
          </label>
          <OBPlaceAutocomplete
            className="basic-info-form__input-location"
            setIsFilledPlace={setIsFilledPlace}
            placeholder="Enter a Town / City"
            locationOB={locationInfo}
            setLocationOB={setLocationInfo}
            relocationPage
          />
        </div>

        {isFilledPlace && (
          <div className="basic-info-form__calendar-wrapper">
            <label className="basic-info-form__label basic-info-form__label-relocation-title">
              Date when planning to move
            </label>
            <Calendar
              className="basic-info-form__calendar schedule__calendar"
              minDate={new Date()}
              showDoubleView={false}
              showFixedNumberOfWeeks={false}
              showNeighboringMonth={false}
              calendarType="US"
              minDetail="year"
              next2Label={null}
              prev2Label={null}
              onClickDay={value => {
                onSelectDate(value)
              }}
              value={
                locationInfo.relocation_location?.date
                  ? moment(locationInfo.relocation_location?.date).toDate()
                  : null
              }
            />
          </div>
        )}

        <label className="basic-info-form__label">Travel Distance</label>
        <SliderWithArrows
          defaultValue={stateForm.travel_distance}
          rangeProcessing={number =>
            props.setStateForm(prev => ({
              ...prev,
              travel_distance: number,
            }))
          }
          valueTitle={`${stateForm.travel_distance} miles`}
          min={minTravelDistance}
        />

        <div className="basic-info-form__relocation-place">
          <label className="basic-info-form__label basic-info-form__label-relocation-title">
            Willing to move to location
          </label>
          <OBPlaceAutocomplete
            className="basic-info-form__input-location"
            placeholder="Which cities would you consider"
            locationOB={locationInfo}
            setLocationOB={setLocationInfo}
            preferredLocationsPage
            onRemovedAddingPlace={onRemovedAddingPlace}
            onAddPlace={onAddPlace}
          />
        </div>

        <hr />
        <div className="basic-info-form__btn-wrapper">
          <Button type="submit">SAVE CHANGES</Button>
        </div>
      </form>
    </div>
  )
}
