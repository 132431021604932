import React, { useEffect, useMemo, useState } from 'react'
import { colorCalculator } from '../../../../../../utils/scripts/colorCalculator'
import { UserCard } from '../../../../../../models'
import { experienceFormatting } from '../../../../../../utils/scripts'
import { getVisiblePreviously } from './scripts'
import zipcodes from 'zipcodes'

interface Props {
  isHeaderCard?: boolean
  userData: UserCard | null
  experience: number | '' | undefined
}

export const ScoresBlocks = React.memo(
  ({ userData, experience, isHeaderCard }: Props) => {
    const previouslyWrapperRef = React.useRef<HTMLDivElement>(null)
    const previouslyRef = React.useRef<HTMLDivElement>(null)
    const [visiblePreviously, setVisiblePreviously] = useState({
      previously: userData?.previously.length
        ? userData?.previously.join(', ')
        : '-',
      remainingWorks: 0,
    })
    const zipCode = userData?.zip_code
    const city = zipCode ? zipcodes.lookup(zipCode)?.city : userData?.city ?? ''
    const state = zipCode
      ? zipcodes.lookup(zipCode)?.state
      : userData?.state ?? ''
    const getLocation = () => {
      if (city && state) return `${city}, ${state}`
      if (city) return city
      if (state) return state
      return '-'
    }
    const location = getLocation()

    const preferredLanguage = useMemo(() => {
      if (!userData?.languages.preferred_language) return '-'
      if (!userData?.languages?.languages.length)
        return `${userData?.languages?.preferred_language}`

      const prefLanguage = `${userData?.languages?.preferred_language}*`

      const languages = userData.languages.languages

      if (languages.length) {
        return [prefLanguage, ...languages].join(', ')
      }

      return `${prefLanguage}`
    }, [userData?.languages])

    useEffect(() => {
      if (userData && previouslyWrapperRef.current && previouslyRef.current) {
        const preparedVisiblePreviously = getVisiblePreviously(
          userData.previously,
          previouslyWrapperRef.current.offsetWidth,
          previouslyRef.current.offsetWidth
        )
        if (
          visiblePreviously.previously !==
            preparedVisiblePreviously.visiblePreviously &&
          visiblePreviously.previously !== '-' &&
          previouslyWrapperRef.current.offsetWidth <
            previouslyRef.current.offsetWidth
        ) {
          setVisiblePreviously({
            previously: preparedVisiblePreviously.visiblePreviously,
            remainingWorks: preparedVisiblePreviously.remainingElems,
          })
        }
      }
    }, [userData, visiblePreviously.previously])

    return (
      <>
        <div className="worker-card__info-block">
          <span>Role</span>
          {!isHeaderCard ? (
            <p>
              {userData?.full_role && userData?.full_role.length > 22
                ? `${userData?.full_role.slice(0, 20)}...`
                : userData?.full_role ?? '-'}
            </p>
          ) : (
            <p>{userData?.full_role ?? '-'}</p>
          )}
          {!isHeaderCard && (
            <>
              <span>Previously</span>
              <div
                className="worker-card__info-block-previously-wrapper"
                ref={previouslyWrapperRef}
              >
                <p ref={previouslyRef}>{visiblePreviously.previously}</p>
                {!!visiblePreviously.remainingWorks && (
                  <div className="worker-card__info-block-previously">
                    <strong className="worker-card__info-block-workplaces">
                      +{visiblePreviously.remainingWorks}
                    </strong>
                  </div>
                )}
              </div>
            </>
          )}
          <span>Location</span>
          <p>{location}</p>
          <span>Desired Pay</span>
          <p>
            {userData?.desired_pay_rate
              ? `$${userData?.desired_pay_rate}`
              : '-'}
          </p>
          <span>Experience</span>
          <p>{experience ? experienceFormatting(experience) : '-'}</p>
          {isHeaderCard && (
            <>
              <span>Languages</span>
              <p>{preferredLanguage}</p>
            </>
          )}
          {!isHeaderCard && (
            <>
              <span>Skillit Interview</span>
              <p
                className={`${
                  userData?.interview_score
                    ? colorCalculator.color(userData?.interview_score, true)
                        .classScore
                    : ''
                }`}
              >
                {userData?.interview_score
                  ? `${userData?.interview_score}%`
                  : '-'}
              </p>
              <span>Assessment Score</span>
              <p
                className={`${
                  userData?.assessment_score
                    ? colorCalculator.color(userData?.assessment_score, true)
                        .classScore
                    : ''
                }`}
              >
                {userData?.assessment_score
                  ? `${userData?.assessment_score}%`
                  : '-'}
              </p>

              <span>Union</span>
              <p>{userData?.union ? `Yes` : 'No'}</p>
            </>
          )}
        </div>
        {isHeaderCard && (
          <div className="worker-card__info-block">
            <span>Skillit Interview</span>
            <p
              className={
                userData?.interview_score
                  ? colorCalculator.color(userData?.interview_score, true)
                      .classScore
                  : ''
              }
            >
              {userData?.interview_score
                ? `${userData?.interview_score}%`
                : '-'}
            </p>
            <span>Assessment Score</span>
            <p
              className={
                userData?.assessment_score
                  ? colorCalculator.color(userData?.assessment_score, true)
                      .classScore
                  : ''
              }
            >
              {userData?.assessment_score
                ? `${userData?.assessment_score}%`
                : '-'}
            </p>
            <span>Previously</span>
            <p>
              {userData?.previously.length
                ? userData?.previously.join(', ')
                : '-'}
            </p>
          </div>
        )}
      </>
    )
  }
)
