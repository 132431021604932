import React, { useEffect, useState } from 'react'
import { updatePublicInfo } from '../../../../../services/workerApi/profileAPI'
import {
  ProfileAnalyticsName,
  WorkerQueries,
} from '../../../../../utils/constants'
import { onTrackingActions } from '../../../../../utils/scripts'
import { toast } from 'react-toastify'
import { useMutation, useQueryClient } from 'react-query'
import { QueryState } from 'react-query/types/core/query'
import { PublicInfo } from '../../../../../models'
import { ERROR_SOMETHING } from '../../../../../services/auth-service'
import { AboutMeBlockUI } from './AboutMeBlockUI'
import './aboutMeBlock.scss'

interface Props extends React.HTMLAttributes<HTMLElement> {
  publicAboutMeText?: string | null
}

export const AboutMeBlock = (props: Props) => {
  const { className, publicAboutMeText } = props
  const maxLengthTextArea = 400
  const queryClient = useQueryClient()
  const [inputValue, setInputValue] = useState<string>('')

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [isEditorVisible, setIsEditorVisible] = useState<boolean>(true)

  const publicInfoQuery: QueryState<PublicInfo, undefined> | undefined =
    queryClient.getQueryState(WorkerQueries.publicInfo)

  const aboutMeMutation = useMutation((data: { about_me: string }) =>
    updatePublicInfo(data)
  )

  const updateInfoAboutMe = (value: string) => {
    if (!value.length) {
      toast.error('Please fill the "About me" block')
      return
    }
    const updatedPublicInfo = { ...publicInfoQuery?.data, about_me: value }
    aboutMeMutation.mutate(
      { about_me: value },
      {
        onSuccess: () => {
          queryClient.setQueryData(WorkerQueries.publicInfo, updatedPublicInfo)
          onTrackingActions(ProfileAnalyticsName.profile_edited)
          setIsEditorVisible(false)
        },
        onError: err => {
          if (err instanceof Error) toast.error(ERROR_SOMETHING)
        },
      }
    )
  }

  useEffect(() => {
    if (publicAboutMeText) {
      setIsEditorVisible(false)
      setInputValue(publicAboutMeText)
    }
  }, [publicAboutMeText])

  return (
    <AboutMeBlockUI
      className={className}
      inputValue={inputValue}
      isModalVisible={isModalVisible}
      isEditorVisible={isEditorVisible}
      maxLengthTextArea={maxLengthTextArea}
      setInputValue={setInputValue}
      setIsModalVisible={setIsModalVisible}
      setIsEditorVisible={setIsEditorVisible}
      updateInfoAboutMe={updateInfoAboutMe}
      savingLoader={aboutMeMutation.isLoading}
    />
  )
}
