import React from 'react'
import { ModalBox } from '../../../../../../../components/molecules'
import { PopupLoader } from '../../../../../../../components/organism'
import { DrivingInfo } from '../../../../../../../models'
import { DrivingModal } from '../DrivingModal'
import edit_pencil from '../../../../../../../assets/icons/other/edit-pencil.svg'
import './drivingStatus.scss'
import { convertBooleanChoiceToString } from '../../../../../../../utils/scripts'

interface Props {
  isSaving: boolean
  isModalVisible: boolean
  isDrivingInfoChanged: boolean
  setIsConfirmationModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  handleSubmit: () => void
  setDrivingInfoForm: React.Dispatch<React.SetStateAction<DrivingInfo>>
  drivingInfoForm: DrivingInfo
  isConfirmationModalVisible: boolean
  drivingInfoState: DrivingInfo
}

export const DrivingStatusUI = (props: Props) => {
  const {
    isSaving,
    isModalVisible,
    isDrivingInfoChanged,
    setIsConfirmationModalVisible,
    setIsModalVisible,
    handleSubmit,
    setDrivingInfoForm,
    drivingInfoForm,
    isConfirmationModalVisible,
    drivingInfoState,
  } = props

  return (
    <div className="driving-status container" data-testid="driving-status">
      {isSaving && <PopupLoader>Saving...</PopupLoader>}
      {isModalVisible && (
        <ModalBox
          className="driving-status__modal-box"
          visibleCloseIcon
          onCloseModal={() =>
            isDrivingInfoChanged
              ? setIsConfirmationModalVisible(true)
              : setIsModalVisible(false)
          }
        >
          <DrivingModal
            handleSubmit={handleSubmit}
            setDrivingInfoForm={setDrivingInfoForm}
            drivingInfoForm={drivingInfoForm}
            isConfirmationModalVisible={isConfirmationModalVisible}
            setIsConfirmationModalVisible={setIsConfirmationModalVisible}
            setIsModalVisible={setIsModalVisible}
            drivingInfoState={drivingInfoState}
            isDrivingInfoChanged={isDrivingInfoChanged}
          />
        </ModalBox>
      )}

      <div className="driving-status__title-block">
        <h3 className="driving-status__title">Driving Status</h3>
        <img
          className="driving-status__edit"
          src={edit_pencil}
          alt="edit pensil"
          onClick={() => setIsModalVisible(true)}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              setIsModalVisible(true)
            }
          }}
        />
      </div>
      <p className="driving-status__question">Do you own your own vehicle?</p>
      <p className="driving-status__answ">
        {convertBooleanChoiceToString(drivingInfoState.vehicle)}
      </p>
      <hr className="driving-status__separator" />

      <h3 className="driving-status__title">Valid Drivers License</h3>
      <p className="driving-status__question">
        Do you have a valid driver’s License?
      </p>
      <p className="driving-status__answ">
        {convertBooleanChoiceToString(drivingInfoState.valid_drivers_license)}
      </p>
    </div>
  )
}
