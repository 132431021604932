import { Helmet } from 'react-helmet'
import { Button, Checkbox } from '../../../components/atoms'
import {
  NavigationFooter,
  ProgressBarLinear,
  RadioBtnElement,
  RadioButtonsList,
} from '../../../components/molecules'
import { EPageTitleOB, listBlocksPBLinear } from '../../../utils/constants'
import './obAdditionalInfo.scss'
import {
  getSelectedYesNoAnswerId,
  handleCheckboxProfile,
  handleRadioBtnProfile,
} from '../../InAppFlow/Worker/ProfileCompletion/handleProfileInputs'
import {
  ICertificationGroup,
  IRolesOB,
  SkillsInfoOnlyAdditionalInfo,
} from '../../../models'
import { useTranslation } from 'react-i18next'

type IProps = {
  onClickNext: () => void
  onClickBack: () => void
  additionalInfo: SkillsInfoOnlyAdditionalInfo
  setAdditionalInfo: React.Dispatch<
    React.SetStateAction<SkillsInfoOnlyAdditionalInfo>
  >
  isWaitingResponse: boolean
  choiceLists: {
    unions: string[] | null
    languages:
      | {
          choice: string
          is_supported: boolean
        }[]
      | null
    training_certifications: ICertificationGroup[] | null
    roles: IRolesOB
    skills: string[] | null
  }
  answerList: RadioBtnElement[]
}

export const OBAdditionalInfoUI = (props: IProps) => {
  const {
    onClickBack,
    onClickNext,
    setAdditionalInfo,
    choiceLists,
    additionalInfo,
    isWaitingResponse,
    answerList,
  } = props
  const { t } = useTranslation()

  return (
    <div className="ob-certification ob-container">
      <Helmet>
        <title>{EPageTitleOB.unions_certif_lang}</title>
      </Helmet>

      <ProgressBarLinear initData={listBlocksPBLinear} currentPage={8} />

      <div className="ob-certification__content">
        <p className="ob-certification__question ob-question">
          {t('additional_Info.Got any memberships or certifications?')}
        </p>
        <p className="ob-certification__description">
          {t(
            'additional_Info.Knowing that you’ve been granted membership or have been certified is always helpful info for future employers.'
          )}
        </p>
      </div>
      <div className="ob-certification__info-block">
        <div className="ob-certification__legally-question">
          <p>
            {t(
              'additional_Info.Are you legally authorized to work in the U.S.?'
            )}
          </p>
          <RadioButtonsList
            className="ob-certification__info-block__radio-btn"
            list={answerList}
            selected={getSelectedYesNoAnswerId(
              answerList,
              additionalInfo.eligible_work_us
            )}
            onChangeBtn={(id: number) =>
              handleRadioBtnProfile(
                id,
                answerList,
                setAdditionalInfo,
                'eligible_work_us',
                true
              )
            }
          />
          <p className="ob-certification__info-block-disclaimer">
            {t(
              'additional_Info.You acknowledge that this information will be used only by potential employers to discover your profile on the Skillit platform and that Skillit is not the Employer of Record.'
            )}
          </p>
        </div>

        <p>{t('additional_Info.Unions')}</p>
        <div className="ob-certification__choice-info-block">
          {choiceLists.unions?.map((union, unionIndex) => {
            const checked = additionalInfo.unions?.includes(union)
            return (
              <Checkbox
                key={union}
                buttonsGrid
                className={`${
                  checked ? 'ob__checkbox_checked' : ''
                } ob-certification__choice-info-block_name`}
                name={t([`additional_Info.${union}`, `${union}`]).toString()}
                idCheckbox={unionIndex}
                checked={checked ? true : undefined}
                toggleCheckbox={(_, isChecked) => {
                  handleCheckboxProfile(
                    isChecked,
                    setAdditionalInfo,
                    union,
                    'unions'
                  )
                }}
              />
            )
          })}
        </div>
      </div>

      {choiceLists.training_certifications?.map(item => (
        <div className="ob-certification__info-block" key={item.group}>
          {/* /// TODO need add translate to Spanish for title */}
          <p>{t([`additional_Info.${item.group}`, `${item.group}`])}</p>
          <div className="ob-certification__choice-info-block">
            {item.certifications?.map((certificate, certificateIndex) => {
              const checked =
                additionalInfo.training_certifications.includes(certificate)
              return (
                <Checkbox
                  key={certificate}
                  buttonsGrid
                  className={`${
                    checked ? 'ob__checkbox_checked' : ''
                  } ob-certification__choice-info-block_name`}
                  name={t([
                    `additional_Info.${certificate}`,
                    `${certificate}`,
                  ]).toString()}
                  idCheckbox={certificateIndex}
                  checked={checked ? true : undefined}
                  toggleCheckbox={(_, isChecked) => {
                    handleCheckboxProfile(
                      isChecked,
                      setAdditionalInfo,
                      certificate,
                      'training_certifications'
                    )
                  }}
                />
              )
            })}
          </div>
        </div>
      ))}
      <NavigationFooter actionBack={onClickBack}>
        <Button onClick={onClickNext} disabled={isWaitingResponse}>
          {t('Shared_Header.Next')}
        </Button>
      </NavigationFooter>
    </div>
  )
}
