import { PublicInfo, ResultWorkExperience } from '../../../../../models'
import { ModalBox } from '../../../../molecules'
import { PopupLoader } from '../../../General'
import { WorkExperienceModal } from './WorkExperienceModal'
import { WorkList } from './WorkList'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  savingLoader: boolean
  isModalVisible: boolean
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  isChanged: boolean
  setIsChanged: React.Dispatch<React.SetStateAction<boolean>>
  isConfirmationModal: boolean
  setIsConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>
  publicInfo: PublicInfo
  handleSubmit: (
    e: React.FormEvent<HTMLFormElement>,
    modalWorkExp: ResultWorkExperience[]
  ) => void
  workExperience: ResultWorkExperience[]
  filteredWorkExperience: ResultWorkExperience[]
}

export const WorkExperienceBlockUI = (props: IProps) => {
  const {
    className,
    savingLoader,
    isModalVisible,
    setIsModalVisible,
    isChanged,
    setIsChanged,
    isConfirmationModal,
    setIsConfirmationModal,
    publicInfo,
    handleSubmit,
    workExperience,
    filteredWorkExperience,
  } = props

  return (
    <div className={`work-experience ${className ?? ''}`}>
      {savingLoader && <PopupLoader>Saving...</PopupLoader>}
      {isModalVisible && (
        <ModalBox
          className="work-experience__modal-box"
          onCloseModal={() => {
            isChanged ? setIsConfirmationModal(true) : setIsModalVisible(false)
          }}
          visibleCloseIcon={true}
        >
          <WorkExperienceModal
            className="container"
            publicInfo={publicInfo}
            handleSubmit={handleSubmit}
            workExperience={workExperience}
            isConfirmationModal={isConfirmationModal}
            setIsConfirmationModal={setIsConfirmationModal}
            setIsChanged={setIsChanged}
            setIsModalVisible={setIsModalVisible}
          />
        </ModalBox>
      )}

      <WorkList
        setIsModalVisible={setIsModalVisible}
        filteredWorkExperience={filteredWorkExperience}
      />
    </div>
  )
}
