import { toast } from 'react-toastify'
import { OBAnalyticsName } from '../../constants'

export const uploadFileHandler = (
  e: React.ChangeEvent<HTMLInputElement>,
  maxFileSize: number,
  typeData: any,
  setFile: (file: File) => void,
  onTrackingActions?: (trackingName: string) => void
) => {
  let updatedFile = e.target.files?.[0]

  if (updatedFile) {
    if (updatedFile.size <= maxFileSize) {
      const isValidType = Object.values(typeData).some(
        (type: any) => type === updatedFile?.type
      )
      if (isValidType) {
        setFile(updatedFile)
      } else {
        toast.error('Sorry this format is not supported')
        onTrackingActions &&
          onTrackingActions(OBAnalyticsName.resume_failed_parsing)
      }
    } else {
      toast.error('Files must be less than 25mb.')
      onTrackingActions &&
        onTrackingActions(OBAnalyticsName.resume_failed_parsing)
    }
  } else {
    toast.error('File not loaded')
    onTrackingActions &&
      onTrackingActions(OBAnalyticsName.resume_failed_parsing)
  }
}
