import { useState, useEffect, useCallback } from 'react'
import { useLocation, useParams } from 'react-router'
import 'react-toastify/dist/ReactToastify.css'
import { onEventPage } from '../../../../utils/scripts'
import './signInEmailPage.scss'
import { SignInEmailPageUI } from './SignInEmailPageUI'
import { useMutation } from 'react-query'
import { sendLoginLink } from '../../../../services/auth-service'
import { LocationState } from '../../../../AppRouter/EmployerRouter'
import { EPageTitle } from '../SignIn/SignInPage'

interface ISendEmail {
  email: string
  fromPath?: string
}

export const SignInEmailPage = () => {
  const location = useLocation()
  const { email } = useParams<{ email: string }>()
  const [decodeEmail, setDecodeEmail] = useState(decodeURIComponent(email)) // = decodeURIComponent(email);
  const [fpModalDisplay, setFpModalDisplay] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)
  const [sendEmail, setSendEmail] = useState({
    isSendEmail: false,
    messageTitle: ``,
    messageDescription: ``,
  })
  const [fromBeta, setFromBeta] = useState<string | null>(null)
  const sessionStorageFrom = sessionStorage.getItem('from') ?? undefined
  const sendLink = useMutation(({ email, fromPath }: ISendEmail) =>
    sendLoginLink(email, fromPath)
  )

  const onConfirmEmailLogin = useCallback(
    async (email: string) => {
      const fromPath = fromBeta ?? (location.state as LocationState)?.from
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      const isEmail = emailRegex.test(email)
      const titleMessage = `Check your ${isEmail ? 'email' : 'messages'}`
      const descriptionMessage = `Check your ${
        isEmail ? 'email' : 'messages'
      } for your magic link to log in instantly to Skillit.`

      sendLink.mutate(
        {
          email,
          fromPath: fromPath ?? sessionStorageFrom,
        },
        {
          onSuccess() {
            fromPath && sessionStorage.setItem('from', fromPath)
            setSendEmail({
              isSendEmail: true,
              messageTitle: titleMessage,
              messageDescription: descriptionMessage,
            })
          },
        }
      )
    },
    [fromBeta, location.state, sendLink, sessionStorageFrom]
  )

  const onCloseModal = () => {
    setFpModalDisplay(false)
  }

  useEffect(() => {
    onEventPage(location.pathname, EPageTitle.sign_in)
  }, [location.pathname])

  useEffect(() => {
    setDecodeEmail(decodeURIComponent(email))
  }, [email])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const from = urlParams.get('from')

    if (from) {
      setFromBeta(new URL(from).pathname)
    }
  }, [])

  return (
    <SignInEmailPageUI
      email={email}
      rememberMe={rememberMe}
      decodeEmail={decodeEmail}
      onCloseModal={onCloseModal}
      setRememberMe={setRememberMe}
      fpModalDisplay={fpModalDisplay}
      setFpModalDisplay={setFpModalDisplay}
      onConfirmEmailLogin={onConfirmEmailLogin}
      sendEmail={sendEmail}
    />
  )
}
