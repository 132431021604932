import React, { useState } from 'react'
import { AdminWorkersUI } from './AdminWorkersUI'
import users from '../../../assets/icons/active-icons/users.svg'
import regions from '../../../assets/icons/active-icons/regions.svg'
import trades from '../../../assets/icons/active-icons/trades.svg'
import skills from '../../../assets/icons/active-icons/skills.svg'
import assessments from '../../../assets/icons/active-icons/assessments.svg'
import kpis from '../../../assets/icons/active-icons/kpis.svg'
import { UsersTab } from '../../../components/organism/AdminWorkers'

export enum EAdminTabsName {
  users = 'Users',
  regions = 'Regions',
  trades = 'Trades',
  skills = 'Skills',
  assessments = 'Assessments',
  kPIs = 'KPIs',
}

export const adminTabs = [
  {
    id: 1,
    name: EAdminTabsName.users,
    img: users,
    component: <UsersTab />,
  },
  {
    id: 2,
    name: EAdminTabsName.regions,
    img: regions,
    component: <UsersTab />,
  },
  {
    id: 3,
    name: EAdminTabsName.trades,
    img: trades,
    component: <UsersTab />,
  },
  {
    id: 4,
    name: EAdminTabsName.skills,
    img: skills,
    component: <UsersTab />,
  },
  {
    id: 5,
    name: EAdminTabsName.assessments,
    img: assessments,
    component: <UsersTab />,
  },
  {
    id: 6,
    name: EAdminTabsName.kPIs,
    img: kpis,
    component: <UsersTab />,
  },
]

export const AdminWorkers = () => {
  const [activeTab, setActiveTab] = useState<string>(EAdminTabsName.users)
  const activeTabContent = adminTabs.find(
    el => el.name === activeTab
  )?.component

  return (
    <AdminWorkersUI
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      activeTabContent={activeTabContent}
    />
  )
}
