import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { useTypedSelector } from '../../../../utils/hooks/UseTypedSelector'
import * as route from '../../../../services/route'

export const LastResultPage = () => {
  const { email } = useParams<{ email: string }>()
  const wasRegistered = useTypedSelector(s => s.app.wasRegistered)
  const isAuth = useTypedSelector(s => s.auth.isAuth)
  const history = useHistory()

  useEffect(() => {
    if (isAuth) {
      history.replace(route.challenges)
    } else {
      const newRoute = wasRegistered
        ? `${route.signIn}/${email ?? ''}`
        : `${route.signUp}/${email ?? ''}`
      history.replace(newRoute)
    }
  }, [history, isAuth, wasRegistered, email])
  return <></>
}
