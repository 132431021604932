import React from 'react'
import { colorCalculator } from '../../../../../utils/scripts/colorCalculator'
import { ProfileProgressCircle } from '../../../../atoms'
import { v4 as uuidv4 } from 'uuid'
import './skillsAssessmentCard.scss'
import { CategoriesScores } from '../../../../../models/Employer'

interface Props extends React.HTMLAttributes<HTMLElement> {
  score?: number
  titleName: string | null
  logo: string | null
  betterThan?: number
  peopleQuantity?: number
  position?: string
  place?: string
  link?: string
  scoreList?: CategoriesScores
  isInterviewCard?: true
}

export const SkillsAssessmentCard = (props: Props) => {
  const {
    score,
    titleName,
    logo,
    betterThan,
    peopleQuantity,
    position,
    place,
    // link,
    scoreList,
    className,
    isInterviewCard,
  } = props

  return (
    <div
      className={`skills-assessment-card ${className ? className : ''}`}
      data-testid="skills-assessment-card"
    >
      <div className="skills-assessment-card__progress-block">
        <ProfileProgressCircle score={score} pathColor="#549B48" />
        <div className="skills-assessment-card__compare-block">
          <h4 className="skills-assessment-card__compare-block-title">
            {logo && (
              <img
                src={logo}
                className="assmnt-block__score-title-logo"
                alt="skillit-logo"
              />
            )}{' '}
            {titleName}
          </h4>
          <p className="skills-assessment-card__compare-block-text">
            Better than {betterThan}% ({peopleQuantity}) of{' '}
            {position ?? 'workers'} {isInterviewCard && 'interviewed'} in{' '}
            {place}
          </p>
          {/* //// TODO it will be need in the next build */}
          {/* <a className="skills-assessment-card__compare-block-link" href={link}>
            Check out our methodology
          </a> */}
        </div>
      </div>
      <div className="skills-assessment-card__results-block">
        <h5 className="skills-assessment-card__results-block-title">
          Scorecard
        </h5>
        <ul className="skills-assessment-card__results-block-list">
          {scoreList &&
            Object.keys(scoreList).map(item => (
              <li
                key={uuidv4()}
                className="skills-assessment-card__results-block-item"
              >
                <span className="skills-assessment-card__results-block-item-name">
                  {item.split('_').includes('score')
                    ? item.split('_')[0]
                    : item.split('_').join(' ')}
                </span>
                <span
                  className={`skills-assessment-card__results-block-item-percent ${
                    scoreList[item] !== undefined
                      ? colorCalculator.color(scoreList[item]!, true).classScore
                      : ''
                  }`}
                >
                  {scoreList[item] ?? '--'}%
                </span>
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}
