import { useCallback, useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { QueryState } from 'react-query/types/core/query'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  ISkills,
  ISkillsGroup,
  IWorkerInfoOB,
  SkillsInfo,
  SkillsInfoOnlyExperience,
} from '../../../models'
import { setWorkerInfo, useAppDispatch } from '../../../redux'
import * as route from '../../../services/route'
import { getSkillsInfo, updateSkillsInfo } from '../../../services/workerApi'
import {
  EWorkerSStoreKeys,
  OBAnalyticsName,
  WorkerQueries,
} from '../../../utils/constants'
import {
  usePageNumUpdateOB,
  useStateWithDep,
  useTypedSelector,
} from '../../../utils/hooks'
import { getLastPageState } from '../../../utils/hooks/obHooks/usePageNumUpdateOB'
import {
  getUserInfoFromStorage,
  onTrackingActions,
} from '../../../utils/scripts'
import { OBExperienceUI } from './OBExperienceUI'

export const OBExperience = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const userInfo = useTypedSelector(state => state.obInfo.workerInfo)
  const pageStateOBMutation = usePageNumUpdateOB()
  const lastPage = userInfo.last_page < 5 ? 5 : userInfo.last_page
  const lastPageState = useMemo(() => getLastPageState(lastPage), [lastPage])
  const userInfoFromStorage = useMemo(() => getUserInfoFromStorage(), [])
  const skillsList = useTypedSelector(
    state => state.variables.choiceListForWorker.rolesWithSkills
  )
  const supportedRoles = useTypedSelector(
    state => state.variables.choiceListForWorker.roles.supported_roles
  )

  const skillsGroups = useTypedSelector(
    state => state.variables.choiceListForWorker.skillsGroups
  )

  const skillsInfoQuery: QueryState<SkillsInfo, undefined> | undefined =
    queryClient.getQueryState(WorkerQueries.skillsInfo)

  const { data: skillsInfo } = useQuery(
    WorkerQueries.skillsInfo,
    () => getSkillsInfo(),
    { enabled: !skillsInfoQuery?.data }
  )

  const skillsInfoOBMutation = useMutation(
    (data: SkillsInfoOnlyExperience) => updateSkillsInfo(data),
    {
      onError: err => {
        if (err instanceof Error) toast.error(err.message)
      },
    }
  )

  const preparedSkillsInfo: SkillsInfoOnlyExperience = useMemo(
    () => ({
      skills: userInfoFromStorage?.user_data.skills ?? skillsInfo?.skills ?? [],
    }),
    [skillsInfo, userInfoFromStorage]
  )

  const roles = useMemo(
    () => userInfo.user_data.role,
    [userInfo.user_data.role]
  )

  const getTitlesList = useCallback(
    (rolesList: string[]) => {
      let titlesList = rolesList
        .filter(el => supportedRoles?.includes(el))
        .flatMap((role: string) => (skillsList ? skillsList[role] : []))
      return Array.from(new Set(titlesList))
    },
    [skillsList, supportedRoles]
  )

  const titlesList = useMemo(() => getTitlesList(roles), [roles, getTitlesList])

  const initialSkillsArr: ISkills[] = useMemo(() => {
    return titlesList.map(title => ({
      choice: title,
      level:
        preparedSkillsInfo.skills.find(skill => skill.choice === title)
          ?.level ?? 0,
    }))
  }, [titlesList, preparedSkillsInfo])

  const [skills, setSkills] = useStateWithDep<ISkills[]>(initialSkillsArr)

  const filledSkills = useMemo(
    () => skills.filter(({ level }) => level),
    [skills]
  )

  const isSkillsChanged = useMemo(() => {
    if (userInfo.user_data.skills.length !== filledSkills.length) {
      return true
    } else {
      return !userInfo.user_data.skills.every(
        (skill, i) =>
          skill.choice === filledSkills[i].choice &&
          skill.level === filledSkills[i].level
      )
    }
  }, [userInfo, filledSkills])

  const sortedSkillsByGroups = useMemo(() => {
    const preparedData: { [name: string]: ISkills[] } = {}

    for (const group in skillsGroups) {
      skillsGroups[group].forEach(el => {
        const skill: ISkills | undefined = skills.find(
          skill => el === skill.choice
        )

        if (skill) {
          if (preparedData[group]) {
            preparedData[group].push(skill)
          } else preparedData[group] = [skill]
        }
      })
    }

    const sortedList: ISkillsGroup[] = Object.entries(preparedData).map(
      ([nameGroup, skills]) => ({
        nameGroup,
        skills,
      })
    )

    return sortedList
  }, [skillsGroups, skills])

  const getNewData = useCallback(
    (data: IWorkerInfoOB) => ({
      ...data,
      last_page: lastPage,
      last_location: lastPageState.last_location,
      user_data: {
        ...data.user_data,
        skills: filledSkills,
      },
    }),
    [filledSkills, lastPage, lastPageState.last_location]
  )

  const saveSessionStorage = useCallback(() => {
    sessionStorage.setItem(
      EWorkerSStoreKeys.obData,
      JSON.stringify(getNewData(userInfoFromStorage ?? userInfo))
    )
  }, [getNewData, userInfoFromStorage, userInfo])

  const onClickBack = useCallback(() => {
    saveSessionStorage()
    history.push(route.OBPath.onboardingTrades)
  }, [history, saveSessionStorage])

  const onClickNext = useCallback(async () => {
    if (isSkillsChanged) {
      const newSkillsInfo: SkillsInfoOnlyExperience = {
        skills: filledSkills,
      }

      await onTrackingActions(OBAnalyticsName.skill_levels_submitted)
      skillsInfoOBMutation.mutate(newSkillsInfo, {
        onSuccess: () => {
          pageStateOBMutation.mutate(lastPageState, {
            onSuccess() {
              queryClient.setQueryData(WorkerQueries.skillsInfo, newSkillsInfo)
              dispatch(setWorkerInfo(getNewData(userInfo)))
              saveSessionStorage()
              history.push(route.OBPath.onboardingLocation)
            },
          })
        },
      })
    } else {
      saveSessionStorage()
      history.push(route.OBPath.onboardingLocation)
    }
  }, [
    filledSkills,
    skillsInfoOBMutation,
    pageStateOBMutation,
    queryClient,
    dispatch,
    getNewData,
    userInfo,
    saveSessionStorage,
    history,
    isSkillsChanged,
    lastPageState,
  ])
  return (
    <OBExperienceUI
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      skills={skills}
      setSkills={setSkills}
      isWaitingResponse={
        skillsInfoOBMutation.isIdle ? false : !skillsInfoOBMutation.error
      }
      skillsGroups={sortedSkillsByGroups}
    />
  )
}
