import { createContext } from 'react'
import { UserData } from '../../models'
import { ResultsModel } from '../../models/ChallengeResultModel'

export const Context = createContext<ResultsModel>({
  challengeResult: null,
  metrics: null,
})

export const userData: UserData = {
  basicInfo: {
    first_name: '',
    last_name: '',
    username: '',
    phone: null,
    communication_preference: [],
    zip_code: null,
    travel_distance: 0,
  },
  publicInfo: {
    first_name: null,
    last_name: null,
    nickname: null,
    profile_picture: null,
    background_image: null,
    about_me: null,
    video: null,
    resume: null,
    score_display: false,
    work_experience: [],
    links: null,
    seeking_status: true,
    profile_notification: null,
  },
  workPreferencesInfo: {
    most_recent_pay_rate: null,
    desired_pay_rate: null,
    work_preferences: [],
    ideal_employer: [],
    roles: [],
    other_role: null,
  },
  skillsInfo: {
    eligible_work_us: null,
    interested_in_upskilling: null,
    email_me: false,
    training_certifications: [],
    skills: [],
    language: [],
    unions: [],
    preferred_language: '',
  },
  drivingInfo: {
    vehicle: null,
    valid_drivers_license: null,
    drivers_license: null,
  },
  oshaInfo: {
    osha_status: [],
    osha_card: null,
  },
}

export const UserDataContext = createContext<UserData>(userData)
