import React, { useState, useEffect, useMemo, useCallback } from 'react'
import {
  Link,
  PublicInfo,
  socialInputErrors,
} from '../../../../../models/ProfileModel'
import { toast } from 'react-toastify'
import { updatePublicInfo } from '../../../../../services/workerApi/profileAPI'
import {
  ProfileAnalyticsName,
  WorkerQueries,
} from '../../../../../utils/constants'
import {
  inputFormatting,
  onTrackingActions,
} from '../../../../../utils/scripts'
import { useMutation, useQueryClient } from 'react-query'
import { QueryState } from 'react-query/types/core/query'
import { ERROR_SOMETHING } from '../../../../../services/auth-service'
import instagram_black from '../../../../../assets/icons/social/instagram_black.svg'
import linkedin_black from '../../../../../assets/icons/social/linkedin_black.svg'
import youtube_black from '../../../../../assets/icons/social/youtube_black.svg'
import { LinksBlockUI } from './LinksBlockUI'

export enum NamesLinks {
  instagram = 'Instagram',
  linkedIn = 'LinkedIn',
  youtube = 'Youtube',
}

export const initialLinks: Link[] = [
  {
    title: NamesLinks.instagram,
    value: '',
  },
  {
    title: NamesLinks.linkedIn,
    value: '',
  },
  {
    title: NamesLinks.youtube,
    value: '',
  },
]

interface Props extends React.HTMLAttributes<HTMLElement> {
  publicLinks?: Link[] | null
}

export const LinksBlock = (props: Props) => {
  const { publicLinks } = props
  const queryClient = useQueryClient()
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false)
  const [links, setLinks] = useState<Link[]>(initialLinks)
  const [filledLinks, setFilledLinks] = useState<Link[]>([])

  const publicInfoQuery: QueryState<PublicInfo, undefined> | undefined =
    queryClient.getQueryState(WorkerQueries.publicInfo)

  const linksMutation = useMutation((data: { links: Link[] }) =>
    updatePublicInfo(data)
  )

  const isLinksChanged = useMemo(
    () =>
      links.every(link => {
        return filledLinks.some(
          filledLink =>
            filledLink.title === link.title && filledLink.value === link.value
        )
      }),
    [filledLinks, links]
  )

  const modalActions = useCallback(() => {
    if (
      isLinksChanged ||
      (filledLinks.every(filledLink => !filledLink.value) &&
        links.every(link => !link.value))
    ) {
      setIsModalVisible(false)
    } else {
      setIsConfirmationModalVisible(true)
    }
  }, [isLinksChanged, filledLinks, links])

  const addLinkIcon = useCallback((linkTitle: string) => {
    if (linkTitle === NamesLinks.instagram) return instagram_black
    if (linkTitle === NamesLinks.linkedIn) return linkedin_black
    return youtube_black
  }, [])

  const saveData = useCallback(
    (initFormatedlinks: Link[]) => {
      if (links.length > 0) {
        const updatedPublicInfo = {
          ...publicInfoQuery?.data,
          links: initFormatedlinks,
        }
        linksMutation.mutate(
          { links: initFormatedlinks },
          {
            onSuccess: () => {
              queryClient.setQueryData(
                WorkerQueries.publicInfo,
                updatedPublicInfo
              )
              onTrackingActions(ProfileAnalyticsName.profile_edited)
              setIsModalVisible(false)
            },
            onError: err => {
              if (err instanceof Error) toast.error(ERROR_SOMETHING)
            },
          }
        )
      }
    },
    [links.length, linksMutation, queryClient, publicInfoQuery?.data]
  )

  const handleSubmit = useCallback(() => {
    const errors: socialInputErrors = {}
    const errorMessage = 'URL is required'
    const formatedLinks = links.map(link => {
      if (!link.value) return link
      const endUrlRegexp: RegExp = /(.+\.com(\/.*)?)$/i
      const protocolRegexp: RegExp = /^https:\/\//i
      if (endUrlRegexp.test(link.value)) {
        return {
          title: link.title,
          value: protocolRegexp.test(link.value)
            ? inputFormatting(link.value, 50)
            : `https://${inputFormatting(link.value, 50)}`,
        }
      } else {
        switch (link.title) {
          case NamesLinks.instagram:
            errors[
              NamesLinks.instagram
            ] = `${NamesLinks.instagram} ${errorMessage}`
            break
          case NamesLinks.linkedIn:
            errors[
              NamesLinks.linkedIn
            ] = `${NamesLinks.linkedIn} ${errorMessage}`
            break
          case NamesLinks.youtube:
            errors[NamesLinks.youtube] = `${NamesLinks.youtube} ${errorMessage}`
            break
        }
      }
      return {
        title: link.title,
        value: inputFormatting(link.value, 50),
      }
    })
    if (Object.keys(errors).length) {
      let err: keyof typeof errors
      for (err in errors) {
        toast.error(errors[err])
      }
    } else {
      setLinks(formatedLinks)
      setFilledLinks(formatedLinks)
      saveData(formatedLinks)
    }
  }, [links, saveData])

  useEffect(() => {
    if (publicLinks && publicLinks?.length > 0) {
      setFilledLinks(publicLinks)
      setLinks(publicLinks)
    }
  }, [publicLinks])

  return (
    <LinksBlockUI
      {...props}
      isModalVisible={isModalVisible}
      modalActions={modalActions}
      links={links}
      setLinks={setLinks}
      setIsModalVisible={setIsModalVisible}
      handleSubmit={handleSubmit}
      filledLinks={filledLinks}
      isConfirmationModalVisible={isConfirmationModalVisible}
      setIsConfirmationModalVisible={setIsConfirmationModalVisible}
      savingLoader={linksMutation.isLoading}
      addLinkIcon={addLinkIcon}
    />
  )
}
