import React, { HTMLAttributes } from 'react'
import { Banner, Button } from '../../../../components/atoms'
import JobCard from '../../../../assets/img/other/job-card.png'
import './jobDetail.scss'
import { useHistory, useParams } from 'react-router'
import { useAsyncJob } from '../JobsPage'
import { AsyncStatus } from '../../../../utils/hooks'
import { LoadingContent } from '../../../../components/molecules/General/LoadingContent'
import * as route from '../../../../services/route'
import { Helmet } from 'react-helmet'

interface Props {}

export const JobDetail = (props: Props) => {
  const { id } = useParams<{ id: string }>()
  const jobAction = useAsyncJob()
  const history = useHistory()

  const isLoading = jobAction.status === AsyncStatus.Executing
  const job = jobAction.value?.find(el => {
    return el.job.id === +id
  })

  const onClickQualify = () => {
    if (job === undefined) {
      return
    }
    history.push(`${route.takeChallenge}/${job.job.key}`)
  }
  return (
    <div className="job-detail">
      <Helmet>
        <title>Skillit - Job detail</title>
      </Helmet>
      <Banner className="job-detail__banner">
        <h2 className="job-detail__header-title">Jobs</h2>
      </Banner>
      <LoadingContent isLoading={isLoading}>
        <div className="job-detail__content-container container">
          <div className="job-detail__header-img" />
          <div className="job-detail__short-description-content">
            <div className="job-detail__logo-img" />
            <p className="job-detail__title">
              <strong>{job?.title}</strong>
            </p>
            <ul className="job-detail__required-qualification-list">
              {job?.requiredQualify.map(el => (
                <li className="job-detail__qualification-item">
                  <strong>{el}</strong>
                </li>
              ))}
            </ul>
            <p className="job-detail__conditions">
              Nashville TN • {`$${job?.salary.min} - $${job?.salary.max} / hr`}{' '}
              • Skills Premium • Generous Benefits • Unlimited Career Growth
            </p>
            <Button
              className="job-detail__qualify-btn"
              onClick={onClickQualify}
            >
              QUALIFY NOW
            </Button>
          </div>
          <hr />
          <div className="job-detail__description-content">
            <div className="job-detail__left-description-content">
              <img
                src={JobCard}
                alt="job-card"
                className="job-detail__job-card"
              ></img>
              <TextContent
                className="job-detail__role-offer-content"
                title="This role offers a Skills Premium"
                description="Companies use your performance on Skillit Challenges to consider you for a Skills Premium."
              />
            </div>
            <div className="job-detail__right-description-content">
              <TextContent
                className="job-detail__about-role-content"
                title="About the Role"
                description="We are currently accepting applications for entry-level and seasoned Carpenters wanting to advance their construction careers. The ideal candidate will have knowledge of commercial construction and remodeling practices as well as a comprehensive understanding of procedures, techniques, tools, equipment, materials, specifications, quality and cost control measures and safety requirements associated with commercial building."
              />
              <ListContent
                className="job-detail__what-will-do-content"
                title="What you’ll do:"
                listText={[
                  'Installation of doors; drill and set door hardware',
                  'Installation of windows',
                  'Layout for stairs and common rafters',
                  'Read blueprints, utilize appropriate math skills and perform layout',
                  'Form and place concrete footings, piers, walls and columns',
                  'Pump, place and finish techniques',
                  'Placement of concrete, slab on grade and elevated decks',
                  'Crane signals and rigging',
                  'Effectively communicate and understand both verbal and written instructions',
                  'Organize, sequence and perform assigned tasks within schedule',
                ]}
              />
              <ListContent
                className="job-detail__what-can-bring-content"
                title="What you can bring to the table:"
                listText={[
                  'High School diploma or equivalent',
                  'OSHA 30 Hour or willing to obtain',
                  'For seasoned candidates, five years’ experience in all phases of commercial construction is preferred',
                  'Candidates must have transportation',
                  'Good communication skills',
                  'Be willing to work within a 50-mile radius',
                  'Be able to work in a variety of seasonal and weather conditions',
                ]}
              />
              <ListContent
                className="job-detail__role-location-content"
                title="Role Location:"
                listText={['Nashville, TN, United States']}
              />
              <ListContent
                className="job-detail__compensation-content"
                title="Compensation:"
                listText={[
                  `Generous base salary of ${`$${job?.salary.min} - $${job?.salary.max} / hr`}`,
                  'Skills Premium applicable',
                  'Unlimited opportunity for personal and professional growth',
                ]}
              />
              <ListContent
                className="job-detail__benefits-content"
                title="Benefits:"
                listText={[
                  'Health Benefits',
                  'Health Savings Account',
                  'Teladoc',
                  'Paid Time Off',
                  'Tuition Reimbursement ',
                  'Retirement Plan 401(k) ',
                  'Employee Assistance Program',
                ]}
              />
              <TextContent
                className="job-detail__about-content"
                title="About Messer"
                description="Messer is a company of builders and champions of craftsmanship specializing in managing, developing and performing complex commercial construction in the communities in which they live and work. Messer is an equal opportunity employer and all qualified applicants will receive consideration without regard to race, color, religion, sex, national origin, disability status, veteran status, sexual orientation or gender identity."
              />
              <TextContent
                className="job-detail__employees-content"
                title="Employees:"
                description="1,000 - 1,500 people"
              />
              <TextContent
                className="job-detail__address-content"
                title="Address:"
                description="230 Great Circle Road, Suite 218, Nashville, TN, 37228-1726, US"
              />
            </div>
          </div>
        </div>
      </LoadingContent>
    </div>
  )
}

interface TextContentProps extends HTMLAttributes<HTMLDivElement> {
  title: string
  description: string
}

const TextContent = (props: TextContentProps) => {
  return (
    <div {...props}>
      <p className="job-detail-title">
        <strong>{props.title}</strong>
      </p>
      <span className="job-detail-description">{props.description}</span>
    </div>
  )
}

interface ListContentProps extends HTMLAttributes<HTMLDivElement> {
  title: string
  listText: string[]
}

const ListContent = (props: ListContentProps) => {
  return (
    <div {...props}>
      <p className="job-detail-title">
        <strong>{props.title}</strong>
      </p>
      <ul>
        {props.listText.map(el => (
          <li className="job-detail-description">{el}</li>
        ))}
      </ul>
    </div>
  )
}
