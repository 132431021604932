import { ButtonBack } from '../../../../../components/atoms'
import { BasicSkills } from './BasicSkills'
import { OshaInfo } from './OshaInfo'
import * as route from '../../../../../services/route'
import './skillsAndQualifications.scss'
import { DrivingInfoBlock } from './DrivingBlock'
import { CertificateGroups } from './CertificateBlock/CertificateGroups'

export const SkillsAndQualificationsUI = () => {
  return (
    <div className="basic-skills-info">
      <ButtonBack route={route.profile} title="Profile" />
      <div className="basic-skills-info__content">
        <section className="basic-skills-info__edit-block">
          <BasicSkills className="container" />
        </section>
        <section className="basic-skills-info__edit-block">
          <DrivingInfoBlock />
        </section>
        <section className="basic-skills-info__edit-block">
          <CertificateGroups className="container" />
        </section>
        <section className="basic-skills-info__edit-block">
          <OshaInfo className="container" />
        </section>
      </div>
    </div>
  )
}
