import React, { useCallback, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { getGeocode, getLatLng } from 'use-places-autocomplete'
import { formatPhoneNumber } from '../../../../../utils/scripts'
import {
  IInvitedWorker,
  IUploadResumeOfInvitedWorker,
  IWorkExpOfInvitedWorker,
} from '../../../../../models/AdminWorkes'
import { inviteNewWorker } from '../../../../../services/adminWorkersAPI'
import { EFormDataKeys } from '../../../../../utils/constants'
import { InviteWorkerUI } from './InviteWorkerUI'
import { useTypedSelector } from '../../../../../utils/hooks'

export const sourceList = [
  { id: 1, element: 'LinkedIn' },
  { id: 2, element: 'Indeed' },
  { id: 3, element: 'ZipRecruiter' },
  { id: 4, element: 'Skillit.com' },
  { id: 5, element: 'Worker Reference' },
  { id: 6, element: 'Employer Reference' },
  { id: 7, element: 'Other' },
]

interface Props {
  setIsInviteOpen: React.Dispatch<React.SetStateAction<boolean>>
  setNewWorker: React.Dispatch<
    React.SetStateAction<IUploadResumeOfInvitedWorker | null>
  >
  newWorker: IUploadResumeOfInvitedWorker | null
  uploadedResume: File | null
  setUploadedResume: React.Dispatch<React.SetStateAction<File | null>>
  setTotalWorkers: React.Dispatch<React.SetStateAction<number | null>>
  handleRefetchListOfWorker: () => Promise<void>
}

export const InviteWorker = (props: Props) => {
  const {
    setIsInviteOpen,
    newWorker,
    setNewWorker,
    uploadedResume,
    setUploadedResume,
    setTotalWorkers,
    handleRefetchListOfWorker,
  } = props
  const defaultInvitedWorkerInfo = {
    first_name: null,
    last_name: null,
    phone: null,
    email: null,
    home_location: null,
    work_experience: null,
    incomplete_work_experience: null,
    language: null,
    source: null,
    roles: [],
  }
  const supportedRoles = useTypedSelector(
    state => state.variables.filterOptionsForAdmin.roles
  )
  const [invitedWorker, setInvitedWorker] = useState<IInvitedWorker>(
    defaultInvitedWorkerInfo
  )

  const isDisable: boolean =
    !invitedWorker.first_name ||
    !invitedWorker.last_name ||
    !invitedWorker.email ||
    !invitedWorker.source ||
    !invitedWorker.roles?.length

  const formData = new FormData()

  const inviteWorkerMutation = useMutation(
    (data: FormData) => inviteNewWorker(data),
    {
      onSuccess() {
        setTotalWorkers(prev => prev && prev + 1)
        setUploadedResume(null)
        setIsInviteOpen(false)
        setNewWorker(null)
        formData.delete(EFormDataKeys.resume)
        formData.delete(EFormDataKeys.data)
        handleRefetchListOfWorker()
      },
      onError(err) {
        if (err instanceof Error) toast.error(err.message)
      },
    }
  )

  const handleSubmit = () => {
    const preparedInvitedWorker = invitedWorker.home_location
      ? {
          ...invitedWorker,
          phone: formatPhoneNumber(
            invitedWorker.phone ?? undefined,
            true,
            true
          ),
          email: invitedWorker.email?.trim(),
        }
      : {
          first_name: invitedWorker.first_name,
          last_name: invitedWorker.last_name,
          phone: formatPhoneNumber(
            invitedWorker.phone ?? undefined,
            true,
            true
          ),
          email: invitedWorker.email?.trim(),
          work_experience: invitedWorker.work_experience,
          incomplete_work_experience: invitedWorker.incomplete_work_experience,
          language: invitedWorker.language,
          source: invitedWorker.source,
          roles: invitedWorker.roles,
        }

    const preparedData = {
      resume: uploadedResume ? formData : null,
      data: uploadedResume
        ? { ...preparedInvitedWorker }
        : {
            first_name: invitedWorker.first_name,
            last_name: invitedWorker.last_name,
            phone: formatPhoneNumber(
              invitedWorker.phone ?? undefined,
              true,
              true
            ),
            email: invitedWorker.email?.trim(),
            source: invitedWorker.source,
            roles: invitedWorker.roles,
          },
    }
    formData.set(EFormDataKeys.resume, uploadedResume ?? '')
    formData.set(EFormDataKeys.data, JSON.stringify(preparedData.data))

    inviteWorkerMutation.mutate(formData)
  }

  const getRoles = useCallback(
    (worker: IUploadResumeOfInvitedWorker): string[] => {
      const workExpRoles = worker.data.work_experience.map(job => job.job_title)
      const incompleteWorkExpRoles = worker.data.incomplete_work_experience.map(
        (job: IWorkExpOfInvitedWorker) => job?.job_title
      )
      const allRoles = [...workExpRoles, ...incompleteWorkExpRoles]
      const findedSupportedRoles: string[] = []

      for (const role of allRoles) {
        role &&
          supportedRoles?.forEach(({ choice }) => {
            if (role.toLowerCase().includes(choice.toLowerCase())) {
              findedSupportedRoles.push(choice)
            }
          })
      }

      return Array.from(new Set(findedSupportedRoles))
    },
    [supportedRoles]
  )

  useEffect(() => {
    if (newWorker) {
      const phoneNumber =
        newWorker.data.phone && newWorker.data.phone?.length > 10
          ? '+' + newWorker.data.phone
          : newWorker.data.phone ?? null

      if (
        newWorker?.data.home_location &&
        newWorker?.data.home_location.Municipality &&
        newWorker?.data.home_location.Regions
      ) {
        const preparedAddress = `${newWorker?.data.home_location.Municipality}, ${newWorker?.data.home_location.Regions[0]}, USA`
        getGeocode({ address: preparedAddress }).then(res => {
          const { lat, lng } = getLatLng(res[0])

          setInvitedWorker({
            ...newWorker.data,
            phone: phoneNumber,
            email: newWorker.data.email?.trim() ?? null,
            home_location: {
              state: newWorker?.data.home_location.Regions[0],
              longitude: lng,
              latitude: lat,
              city: newWorker?.data.home_location.Municipality ?? '',
              user: null,
            },
            source: null,
            roles: getRoles(newWorker),
          })
        })
      } else {
        setInvitedWorker({
          first_name: newWorker.data.first_name,
          last_name: newWorker.data.last_name,
          phone: phoneNumber,
          email: newWorker.data.email?.trim() ?? null,
          home_location: null,
          work_experience: newWorker.data.work_experience,
          incomplete_work_experience: newWorker.data.incomplete_work_experience,
          language: newWorker.data.language,
          source: null,
          roles: getRoles(newWorker),
        })
      }
    }
  }, [getRoles, newWorker])

  return (
    <InviteWorkerUI
      handleSubmit={handleSubmit}
      invitedWorker={invitedWorker}
      setInvitedWorker={setInvitedWorker}
      isDisable={isDisable}
      supportedRoles={supportedRoles}
    />
  )
}
