import React from 'react'
import { InterviewFormat } from '../../../../components/organism'
import { IInterviewUrlData, UserCard } from '../../../../models'
import './inviteInterviewPage.scss'
import { LoadingScreen } from '../../General'

interface IProps {
  isLoading: boolean
  isSuccess: boolean
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>
  workerData?: UserCard
  interviewUrlData: IInterviewUrlData
}

export const InviteInterviewPageUI = (props: IProps) => {
  const { isLoading, isSuccess, setIsSuccess, workerData, interviewUrlData } =
    props

  return (
    <div className="invite-interview-page">
      {isLoading && <LoadingScreen />}

      {isSuccess && (
        <h2 className="invite-interview-page__message">
          Alright! We’ll reach out shortly to schedule this interview
        </h2>
      )}

      <div
        className="invite-interview-page__interview-block"
        hidden={isSuccess}
      >
        {workerData ? (
          <InterviewFormat
            userData={workerData}
            setIsSuccessModal={setIsSuccess}
            interviewUrlData={interviewUrlData}
          />
        ) : (
          <div className="invite-interview-page__message">
            The user is not found
          </div>
        )}
      </div>
    </div>
  )
}
