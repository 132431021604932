import { TokenHolder } from './TokenHolder'
import { LocalStorage, LocalStorageKey } from './localStorage'

export const setAuthToken = (
  token: string,
  keepAlive: boolean = false,
  item: boolean = true,
  key: LocalStorageKey = 'wasRegistred'
) => {
  TokenHolder.set(token, keepAlive)
  LocalStorage.setItemForKey(item, key)
}
