import React from 'react'
import './dashboardHeader.scss'

interface Props {
  name: string
  userPhoto?: string
}

export const DashboardHeader = ({ name, userPhoto }: Props) => {
  return (
    <div className="dashboard-header">
      <div className="dashboard-header__wrapper">
        <div className="dashboard-header__background container">
          {userPhoto && (
            <img
              src={userPhoto}
              alt="user_photo"
              className="dashboard-header__photo"
            />
          )}
          <div className="dashboard-header__greeting-wrapper">
            <p className="dashboard-header__greeting">{`Hi ${name}!`}</p>
            <p className="dashboard-header__text">
              Let’s finish setting up your account
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
