export const getPayMinValueTitle = (
  value: string | null | undefined,
  getContentTitle?: true
) => {
  if (!value || value === '0') {
    return getContentTitle ? 'None provided' : '-'
  }

  return '$' + value
}
