import React, { useState } from 'react'
import 'react-calendar/dist/Calendar.css'
import * as route from '../../../services/route'
import './schedulePage.scss'
import { useLocation, useParams } from 'react-router'
import {
  DaySlot,
  InPerson,
  InterviewerModel,
  Onboarding,
  Phone,
  SpecificJob,
} from '../../../models'
import { Helmet } from 'react-helmet'
import {
  BookedModal,
  BookingCalendar,
  NotBookedModal,
} from '../../../components/organism'
import { useTypedSelector } from '../../../utils/hooks'

export const SchedulePage = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const { uuid } = useParams<{ uuid: string }>()
  const getTypeParam = params.get('type')
  const type: Onboarding | SpecificJob | null =
    getTypeParam === 'onboarding' || getTypeParam === 'specific_job'
      ? getTypeParam
      : null
  const getEventParam = params.get('event_type')
  const event_type: Phone | InPerson | null =
    getEventParam === 'phone' || getEventParam === 'in_person'
      ? getEventParam
      : null
  const isObApproved = useTypedSelector(s => s.auth.isOnboardingApproved)
  const isAuth = useTypedSelector(s => s.auth.isAuth)

  const [date, setDate] = useState(new Date())
  const [slots, setSlots] = useState<DaySlot[]>()
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [isBookingModal, setIsBookingModal] = useState<boolean>(false)
  const [errorBooking, setErrorBooking] = useState<string>()
  const [interviewer, setInterviewer] = useState<InterviewerModel | null>(null)

  const onCloseModal = () => {
    setIsModalVisible(false)
    window.location.replace(
      `${route.signUp}/${uuid}?pre-interview="true"&type=${type}`
    )
  }

  return (
    <div className="preinterview-schedule preinterview-container">
      <Helmet>
        <title>Skillit - Schedule</title>
      </Helmet>
      <BookedModal
        date={date}
        type={type}
        isModalVisible={isModalVisible}
        onCloseModal={onCloseModal}
        interviewer={interviewer}
      />
      <NotBookedModal
        textError={errorBooking ?? ''}
        onboarding
        uuid={uuid}
        isModalBooking={isBookingModal}
        setIsModalBooking={setIsBookingModal}
      />
      <BookingCalendar
        uuid={uuid}
        event_type={event_type}
        date={date}
        setDate={setDate}
        slots={slots}
        setSlots={setSlots}
        type={type}
        isNavFooterShowed={isAuth && isObApproved ? undefined : true}
        setIsModalBooking={setIsBookingModal}
        setIsModalVisible={setIsModalVisible}
        setErrorBooking={setErrorBooking}
        setInterviewer={setInterviewer}
      />
    </div>
  )
}
