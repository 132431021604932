import './workerEmploymentHighlightsBlock.scss'

import { WorkerInfoDetails } from '../../../../../models/Employer'

import { WorkerEmploymentHighlightsBlockUI } from './WorkerEmploymentHighlightsBlockUI'

interface Props {
  workerDataDetails?: WorkerInfoDetails
}

export const WorkerEmploymentHighlightsBlock = (props: Props) => {
  const { workerDataDetails } = props

  const noDescription =
    workerDataDetails?.past_employment_and_experience.experience.every(
      job => !job.description
    ) ?? false

  const hasExperience =
    !!workerDataDetails?.past_employment_and_experience.experience.length
  return (
    <WorkerEmploymentHighlightsBlockUI
      workerDataDetails={workerDataDetails}
      noDescription={noDescription}
      hasExperience={hasExperience}
    />
  )
}
