import { Fragment } from 'react'
import { ConfirmationUpdateModal, ModalBox } from '../../../../../molecules'
import { Button, Input } from '../../../../../atoms'
import { INames } from '../PublicProfileHeader'
import { PopupLoader } from '../../../../General'

import './usernameEditModal.scss'

interface IProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  isConfirmationModalVisible: boolean
  setIsConfirmationModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  names: INames
  handleSubmit: () => void
  isSaving: boolean
  onRemoveChanges: () => void
  onInputChange: (value: string, inputName: string) => void
}

type TInputsValues = {
  [K in keyof INames]: {
    label: string
    placeholder: string
  }
}

export const inputsValues: TInputsValues = {
  first_name: { label: 'First Name', placeholder: 'e.x. Robert' },
  last_name: { label: 'Last Name', placeholder: 'e.x. Smith' },
  nickname: { label: 'Preferred Name / Nickname', placeholder: 'e.x. Bob' },
}

export const UsernameEditModalUI = (props: IProps) => {
  const {
    isConfirmationModalVisible,
    setIsConfirmationModalVisible,
    names,
    handleSubmit,
    isSaving,
    onRemoveChanges,
    onInputChange,
  } = props

  return (
    <div className="username-edit" data-testid="username-edit">
      {isConfirmationModalVisible && (
        <ModalBox className="upload-file__modal-box">
          <ConfirmationUpdateModal
            title="Discard Changes"
            uploadText="Save Changes"
            removeText="Discard Changes"
            updateData={() => {
              handleSubmit()
              setIsConfirmationModalVisible(false)
            }}
            remove={onRemoveChanges}
          />
        </ModalBox>
      )}

      {isSaving && <PopupLoader>Saving...</PopupLoader>}

      <div className="username-edit__content">
        <div className="username-edit__content-wrapper">
          <h4 className="username-edit__title">Name</h4>
          <p className="username-edit__narration">Update your info below</p>
          {Object.keys(names).map(name => {
            return (
              <Fragment key={name}>
                <label className="username-edit__label" htmlFor={name}>
                  {inputsValues[name as keyof INames].label}
                </label>

                <Input
                  id={name}
                  value={names[name as keyof INames]}
                  onChange={e => onInputChange(e.target.value, name)}
                  placeholder={inputsValues[name as keyof INames].placeholder}
                />
              </Fragment>
            )
          })}
        </div>
        <hr />
      </div>

      <div className="username-edit__btn-save-wrapper">
        <Button
          className="username-edit__btn-save"
          type="button"
          onClick={handleSubmit}
        >
          SAVE CHANGES
        </Button>
      </div>
    </div>
  )
}
