export enum EFormatPhoneNumFunc {
  enterFromMarketingSite = 'enter_from_marketing_site',
}

export const formatPhoneNumber = (
  value: string | undefined,
  getNumber?: boolean,
  withCountryCode?: boolean,
  fromMarketingSite?: EFormatPhoneNumFunc.enterFromMarketingSite
) => {
  if (!value) return ''

  let phoneNumber = ''

  if (withCountryCode) {
    phoneNumber =
      value[0] === '+'
        ? value[0] + value.slice(1).replace(/[^\d]/g, '')
        : value.replace(/[^\d]/g, '')
  } else {
    phoneNumber = value.replace(/[^\d]/g, '')
  }

  const phoneNumberLength = phoneNumber.length

  if (
    fromMarketingSite &&
    !phoneNumber.startsWith('+') &&
    phoneNumberLength > 10
  ) {
    phoneNumber = '+' + phoneNumber
  }

  if (getNumber)
    return phoneNumber.includes('+')
      ? phoneNumber.slice(0, 12)
      : phoneNumber.slice(0, 10)

  if (phoneNumber.includes('+')) {
    if (phoneNumberLength < 6) return phoneNumber

    if (phoneNumberLength < 9) {
      return `${phoneNumber.slice(0, 2)} (${phoneNumber.slice(
        2,
        5
      )}) ${phoneNumber.slice(5)}`
    }
    return `${phoneNumber.slice(0, 2)} (${phoneNumber.slice(
      2,
      5
    )}) ${phoneNumber.slice(5, 8)}-${phoneNumber.slice(8, 12)}`
  }

  if (phoneNumberLength < 4) return phoneNumber

  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`
}
