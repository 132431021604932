export enum EWorkerSStoreKeys {
  obData = 'ob-data',
  obLocation = 'ob-location',
  obIncompleteExperience = 'ob-incompleteExperience',
  obFirstEnterFromMarketingSite = 'ob-FirstEnterFromMarketingSite',
  obSourceFromMarketingSite = 'ob-SourceFromMarketingSite',
  placeAutocomplete = 'upa',
  obIsInvited = 'ob-isInvited',
  obIsIndeedEmail = 'ob-isIndeedEmail',
  obPreFilledLanguage = 'ob-PreFilledLanguage',
}

export enum EAdminWorkersSStoreKeys {
  queryParams = 'query-params',
  filterList = 'filter-list',
  isAdmin = 'is-admin',
  transitionFromAdmin = 'transition-from-admin',
}

export enum ELandingPagesSStoreKeys {
  isFeedbackSent = 'is-feedback-sent',
  isFeedbackNotesSent = 'is-feedback-notes-sent',
  isInterviewInvited = 'is-interview-invited',
}
