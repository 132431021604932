import { AxiosResponse, AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { ERROR_SOMETHING } from './auth-service'
import * as route from '../services/route'
import { ErrorType } from '../utils/constants'
import Cookies from 'js-cookie'
import { TokenHolder, FACEBOOK_KEY } from './TokenHolder'

type OptionsFlags<Type> = {
  [Property in keyof Type]: string[]
}

export const responseHandler = <T>(
  request: Promise<AxiosResponse<T>>,
  user?: true
) => {
  return request
    .then(response => response.data)
    .catch(e => {
      const err = e as AxiosError<OptionsFlags<T>>

      if (err.request?.status === 0 || err.request?.status >= 500) {
        toast.error(ERROR_SOMETHING, {
          toastId: ERROR_SOMETHING,
        })
      }

      let allError: string[] = []
      const errData: OptionsFlags<T> | undefined = err.response?.data
      const values: string[] = errData ? Object.values(errData) : []
      const message: string = values[0]
      const token = TokenHolder.get()

      if (user && errData) {
        let key: keyof typeof errData
        for (key in errData) {
          allError.push(`${String(key)}_${errData[key][0]}`)
        }
      } else {
        if (message === ErrorType.invalidToken) {
          const errPromise = Promise.reject(
            (() => {
              if (
                window.location.pathname === route.signIn ||
                window.location.pathname === route.signUp
              ) {
                return console.debug(message)
              } else {
                if (token) {
                  TokenHolder.remove()
                  Cookies.remove(FACEBOOK_KEY)
                  localStorage.clear()
                  sessionStorage.clear()
                }
                toast.error(message)
                setTimeout(() => window.history.go(), 4000)
              }
            })()
          )
          return errPromise
        }
      }
      return Promise.reject(new Error(user ? allError.join('\n') : message))
    })
}
