import React, { useEffect, useState } from 'react'
import { ProfileProgressCircle, Button } from '../../../../components/atoms'
import { OverallCompletion } from '../../../../components/organism'
import { PublicBlock } from '../../../../components/organism/Worker/ProfileComponents/PublicBlock'
import { ProfileInfoBlock } from '../../../../components/organism/Worker/ProfileComponents/ProfileInfoBlock'
import * as route from '../../../../services/route'
import publicProfileIcon from '../../../../assets/icons/other/profile-public-icon.svg'
import './profileCompletion.scss'
import { useTypedSelector } from '../../../../utils/hooks'
import { ModalBox } from '../../../../components/molecules'
import { ChangePasswordPage } from '../../General/ChangePasswordPage'
import { getPublicInfo } from '../../../../services/workerApi/profileAPI'
import { Helmet } from 'react-helmet'
import { profileCompletionInfo } from '../../../../redux/auth-reducer'
import { useAppDispatch } from '../../../../redux/store'
import { useQuery, useQueryClient } from 'react-query'
import { EWorkerPageTitle, WorkerQueries } from '../../../../utils/constants'
import { QueryState } from 'react-query/types/core/query'
import { PublicInfo } from '../../../../models'

export const ProfileCompletion = () => {
  const dispatch = useAppDispatch()
  const completionData = useTypedSelector(s => s.auth.user)
  const queryClient = useQueryClient()

  const publicInfoQuery: QueryState<PublicInfo, undefined> | undefined =
    queryClient.getQueryState(WorkerQueries.publicInfo)

  const { data: publicInfo } = useQuery(
    WorkerQueries.publicInfo,
    () => getPublicInfo(),
    { enabled: !publicInfoQuery?.data }
  )
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  useEffect(() => {
    dispatch(profileCompletionInfo())
  }, [dispatch])

  return (
    <div className="profile-completion">
      {isModalVisible && (
        <ModalBox
          onCloseModal={() => setIsModalVisible(false)}
          visibleCloseIcon={true}
          bgClickDisabled={true}
          className="profile-completion__change-password"
        >
          <ChangePasswordPage closeModal={() => setIsModalVisible(false)} />
        </ModalBox>
      )}
      <Helmet>
        <title>{EWorkerPageTitle.profile}</title>
      </Helmet>

      <section className="profile-completion__block">
        <h2 className="profile-completion__title container">Profile</h2>
      </section>
      <section className="profile-completion__block">
        <OverallCompletion
          title="Overall Profile Completion"
          description="Complete your profile to make your profile visible to employers and
          receive the best offers."
          overallPercentage={completionData?.overall_stats}
          className="profile-completion__block-overall container"
        />
      </section>
      <section className="profile-completion__block">
        {!!publicInfo && (
          <PublicBlock
            name={`${publicInfo.first_name ?? ''} ${
              publicInfo.last_name ?? ''
            }`}
            userPhoto={publicInfo.profile_picture ?? publicProfileIcon}
            publicSeekingStatus={publicInfo.seeking_status}
            progressCircle={
              <ProfileProgressCircle
                score={completionData?.public_profile_stats}
                text={'done'}
              />
            }
          />
        )}
      </section>
      <section className="profile-completion__block">
        <ProfileInfoBlock
          className="container"
          title="Contact and Basic Information"
          description="Info necessary to communicate with you"
          progresCircle={
            <ProfileProgressCircle
              score={completionData?.contact_and_basic_information}
              text={'done'}
            />
          }
          route={route.profileBasicInfo}
        />

        <ProfileInfoBlock
          className="container"
          title="Work Preferences"
          description="Ensure job offers meet your needs"
          progresCircle={
            <ProfileProgressCircle
              score={completionData?.work_preferences}
              text={'done'}
            />
          }
          route={route.workPreferences}
        />

        <ProfileInfoBlock
          className="container"
          title="Skills &amp; Qualifications"
          description="Get better offers based on your credentials"
          progresCircle={
            <ProfileProgressCircle
              score={completionData?.skills_and_qualifications}
              text={'done'}
            />
          }
          route={route.profileSkillsInfo}
        />

        {/* <ProfileInfoBlock //TODO show this block when the logic is agreed by the customer
          className="container"
          title="References &amp; Recommendations"
          description="Get your experience verified"
          progresCircle={<ProfileProgressCircle score={0} text={'done'} />}
          route={'/'}
        /> */}
        <div className="profile-completion__wrapper-password-button container">
          <Button
            className="profile-completion__password-button profile_white-btn"
            onClick={() => setIsModalVisible(true)}
          >
            Change password
          </Button>
        </div>
      </section>
    </div>
  )
}
