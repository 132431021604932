import React from 'react'
import './statusTogglers.scss'
import activeVerifyIcon from '../../../../assets/icons/active-icons/active_yellow-star.svg'
import disabledVerifyIcon from '../../../../assets/icons/active-icons/disabled_star.svg'
import activeApproveIcon from '../../../../assets/icons/active-icons/active_approve-icon.svg'
import disabledApproveIcon from '../../../../assets/icons/active-icons/disabled_approve-icon.svg'
import activeRejectIcon from '../../../../assets/icons/active-icons/active_reject-icon.svg'
import activeWandIcon from '../../../../assets/icons/active-icons/active_wand-icon.svg'
import disabledRejectIcon from '../../../../assets/icons/active-icons/disabled_reject-icon.svg'
import activeHubspotIcon from '../../../../assets/icons/active-icons/active_hubspot-icon.svg'
import disabledHubspotIcon from '../../../../assets/icons/active-icons/disabled_hubspot-icon.svg'
import { ECompletionStatusOB } from '../../../../utils/constants'
import { ModalBox } from '../../General'
import {
  IWorkerInfoAdminPage,
  IWorkerStatus,
} from '../../../../models/AdminWorkes'
import { WorkerVerification } from '../WorkerVerification/WorkerVerification'
import { PopupLoader } from '../../../organism'
import { StatusTogglersModal } from './StatusTogglersModal'

interface IProps extends React.HTMLAttributes<HTMLElement> {
  worker: IWorkerInfoAdminPage
  onClickStatus: (status: ECompletionStatusOB | 'wandButton') => void
  isVerifyActive: boolean
  isApproveActive: boolean
  isRejectModalVisible: boolean
  setIsRejectModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  isVerifyModalVisible: boolean
  setIsVerifyModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  isApproveModalVisible: boolean
  setIsApproveModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  isRejectActive: boolean
  statusHandler: (status: IWorkerStatus) => void
  savingStatus: boolean
  wandLink: string
}

export const StatusTogglersUI = (props: IProps) => {
  const {
    className,
    worker,
    onClickStatus,
    isVerifyActive,
    isApproveActive,
    isRejectModalVisible,
    setIsRejectModalVisible,
    isRejectActive,
    statusHandler,
    isVerifyModalVisible,
    setIsVerifyModalVisible,
    isApproveModalVisible,
    setIsApproveModalVisible,
    savingStatus,
    wandLink,
  } = props

  const hubspotIcon = (
    <img
      src={
        worker.hubspot_profile_link ? activeHubspotIcon : disabledHubspotIcon
      }
      alt="hubspot status button"
      className="status-togglers__item"
    />
  )

  return (
    <div className={`status-togglers ${className ? className : ''}`}>
      {savingStatus && <PopupLoader></PopupLoader>}

      {isApproveModalVisible && (
        <StatusTogglersModal
          {...props}
          closeModal={setIsApproveModalVisible}
          actionTitle="Approve"
          onClickNo={() => setIsApproveModalVisible(false)}
          onClickYes={() =>
            statusHandler({ status: ECompletionStatusOB.approved })
          }
        />
      )}
      {isRejectModalVisible && (
        <StatusTogglersModal
          {...props}
          closeModal={setIsRejectModalVisible}
          actionTitle="reject"
          onClickNo={() => setIsRejectModalVisible(false)}
          onClickYes={() =>
            statusHandler({ status: ECompletionStatusOB.rejected })
          }
        />
      )}
      {isVerifyModalVisible && (
        <ModalBox
          className="status-togglers__modal_verification"
          visibleCloseIcon
          onCloseModal={() => setIsVerifyModalVisible(false)}
        >
          <WorkerVerification statusHandler={statusHandler} worker={worker} />
        </ModalBox>
      )}
      <img
        src={isVerifyActive ? activeVerifyIcon : disabledVerifyIcon}
        alt="verify status button"
        className="status-togglers__item"
        onClick={() => onClickStatus(ECompletionStatusOB.verified)}
      />
      <img
        src={isApproveActive ? activeApproveIcon : disabledApproveIcon}
        alt="approve status button"
        className="status-togglers__item"
        onClick={() => onClickStatus(ECompletionStatusOB.approved)}
      />
      <img
        src={isRejectActive ? activeRejectIcon : disabledRejectIcon}
        alt="reject status button"
        className="status-togglers__item"
        onClick={() => onClickStatus(ECompletionStatusOB.rejected)}
      />
      {worker.hubspot_profile_link ? (
        <a
          className="status-togglers__item"
          href={worker.hubspot_profile_link}
          target="_blank"
          rel="noreferrer"
        >
          {hubspotIcon}
        </a>
      ) : (
        hubspotIcon
      )}
      <a
        href={wandLink}
        onClick={e => {
          e.preventDefault()
          onClickStatus('wandButton')
        }}
        className="status-togglers__item"
      >
        <img src={activeWandIcon} alt="wand status button" />
      </a>
    </div>
  )
}
