import React, { forwardRef } from 'react'
import { Helmet } from 'react-helmet'
import { OBInput, Button } from '../../../components/atoms'
import {
  ProgressBarLinear,
  NavigationFooter,
} from '../../../components/molecules'
import {
  EPageTitleOB,
  listBlocksPBLinear,
} from '../../../utils/constants/obConstants'
import { useTranslation, Trans } from 'react-i18next'
import phoneIcon from '../../../assets/icons/social/black-phone.svg'
import emailIcon from '../../../assets/icons/social/black-email.svg'
import './ob-email.scss'

interface IProps {
  name: string
  isPasswordSet: boolean
  phone: string
  onChangePhone: (e: React.FormEvent<HTMLInputElement>) => void
  email: string
  onChangeEmail: (e: React.FormEvent<HTMLInputElement>) => void
  onClickNext: () => void
  onClickBack: () => void
  isWaitingResponse: boolean
}

export const OBEmailUI = forwardRef<HTMLInputElement, IProps>((props, ref) => {
  const {
    name,
    isPasswordSet,
    phone,
    onChangePhone,
    email,
    onChangeEmail,
    onClickNext,
    onClickBack,
    isWaitingResponse,
  } = props
  const { t, i18n } = useTranslation()

  return (
    <div className="ob-email ob-container">
      <Helmet>
        <title>{EPageTitleOB.email}</title>
      </Helmet>

      <ProgressBarLinear
        className="ob-email__progress-bar"
        initData={listBlocksPBLinear}
        currentPage={2}
      />

      <div className="ob-email__content">
        <p
          className="ob-email__question ob-question"
          data-testid="ob-email__question"
        >
          {i18n.language.slice(0, 2) === 'es'
            ? t('email.Hey name, how can we reach you?', {
                Nombre: `${!name ? 'Nombre' : name}`,
              })
            : t('email.Hey name, how can we reach you?', {
                Name: `${!name ? 'User' : name}`,
              })}
        </p>
        <p className="ob-email__description ob-description">
          {i18n.language.slice(0, 2) === 'es' ? (
            <Trans i18nKey="email.description-for-Trans">
              Continuamente conectamos a trabajadores con nuevas oportunidades
              de trabajo. Por lo tanto, es posible que necesitemos comunicarnos
              contigo para responder ciertas preguntas, oganizar una entrevista
              o darte las últimas noticias sobre Skillit. <code>Nunca</code>{' '}
              venderemos su información a terceros
            </Trans>
          ) : (
            <Trans i18nKey="email.description-for-Trans">
              "We are continuously matching members with job opportunities. So
              we may need to reach you with questions, to set up an interview,
              or to update you on the latest at Skillit. We will{' '}
              <code>never</code> sell your information to third parties (we hate
              SPAM too!)."
            </Trans>
          )}
        </p>
        <div className="ob-email__inputs-wrapper">
          <OBInput
            icon={emailIcon}
            placeholder={t('email.Email').toString()}
            className="ob-email__input ob-email__input_email"
            value={email}
            onChange={onChangeEmail}
            disabled={isPasswordSet}
            ref={ref}
          />

          <OBInput
            icon={phoneIcon}
            placeholder={t('email.Phone Number').toString()}
            className="ob-email__input"
            value={phone}
            onChange={onChangePhone}
          />
        </div>
        <div className="ob-email__terms ob-description">
          Skillit seeks your consent to contact you with automated text messages
          (SMS), autodialed phone calls, and emails under state and federal law.
          Message and data rates may apply. By clicking "Next" and agreeing to
          our{' '}
          <a href="https://skillit.com/terms/" target="_blank" rel="noreferrer">
            terms of service
          </a>
          , you authorize us and our service providers to contact you using your
          email and phone numbers in our system.
        </div>
      </div>

      <NavigationFooter actionBack={onClickBack}>
        <Button onClick={onClickNext} disabled={!email || isWaitingResponse}>
          {t('Shared_Header.Next')}
        </Button>
      </NavigationFooter>
    </div>
  )
})
