import React, { useCallback, useContext, useState } from 'react'
import { toast } from 'react-toastify'
import {
  editNotes,
  saveInterviewRating,
} from '../../../../../services/employerAPI'
import { useStateWithDep, useTypedSelector } from '../../../../../utils/hooks'
import { EmployeeInfoContext } from '../../../../Context/EmployeeInfoContext'
import './companyInterviewBlock.scss'
import { CompanyInterviewBlockUI } from './CompanyInterviewBlockUI'
import { useMutation } from 'react-query'

export interface IRatingInterview {
  overall_score: number
  notes: string
}

export const CompanyInterviewBlock = () => {
  const employer = useTypedSelector(s => s.auth.user)?.employer_info
  const workerContext = useContext(EmployeeInfoContext)
  const worker = workerContext.workerData
  const updateWorkerInfo = workerContext.action
  const interviewDetails = worker?.interview_scores.company_interview.details
  const [rating, setRating] = useState<IRatingInterview>({
    overall_score: interviewDetails ? interviewDetails.overall_score : 0,
    notes:
      interviewDetails && interviewDetails.notes ? interviewDetails.notes : '',
  })
  const [isEdited, setIsEdited] = useStateWithDep<boolean>(!!interviewDetails)

  const saveRatingMutation = useMutation(
    (data: { id: number; rating: IRatingInterview }) =>
      saveInterviewRating(data.id, data.rating),
    {
      onSuccess: () => {
        updateWorkerInfo()
      },
      onError: err => {
        if (err instanceof Error) toast.error(err.message)
      },
    }
  )
  const editNotesMutation = useMutation(
    (data: { id: number; note: string }) => editNotes(data.id, data.note),
    {
      onSuccess: () => {
        updateWorkerInfo()
      },
      onError: err => {
        if (err instanceof Error) toast.error(err.message)
      },
    }
  )

  const getColor = useCallback((): 'red' | 'yellow' | 'green' => {
    if (rating.overall_score < 50) {
      return 'red'
    } else if (rating.overall_score >= 50 && rating.overall_score < 70) {
      return 'yellow'
    } else return 'green'
  }, [rating.overall_score])

  const onSetRating = useCallback(
    (index: number) => {
      !interviewDetails &&
        setRating(prevRating => {
          const starRating = (index + 1) * 20
          const getRating =
            prevRating.overall_score === starRating ? 0 : starRating
          return {
            ...prevRating,
            overall_score: getRating,
          }
        })
    },
    [interviewDetails]
  )

  const onSave = useCallback(() => {
    if (worker) {
      const note = rating.notes.trim()
      const data = {
        overall_score: rating.overall_score,
        notes: note ?? null,
      }
      saveRatingMutation.mutate({
        id: worker.id,
        rating: data,
      })
    } else toast.error('Employee data not found')
  }, [worker, rating.notes, rating.overall_score, saveRatingMutation])

  const onEdit = useCallback(() => {
    if (worker) {
      const note = rating.notes.trim()
      editNotesMutation.mutate({ id: worker.id, note })
    }
  }, [worker, editNotesMutation, rating.notes])

  return (
    <CompanyInterviewBlockUI
      isSaving={saveRatingMutation.isLoading || editNotesMutation.isLoading}
      companyName={employer?.company_info.name}
      rating={rating}
      interviewDetails={interviewDetails}
      setRating={setRating}
      isEdited={isEdited}
      setIsEdited={setIsEdited}
      getColor={getColor}
      onEdit={onEdit}
      onSave={onSave}
      onSetRating={onSetRating}
    />
  )
}
